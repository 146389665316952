import { GLOBAL_DISPATCH, GLOBAL_STATE } from "../types/store";
import * as types from "./types";

const globalReducer = (
  state: GLOBAL_STATE,
  action: GLOBAL_DISPATCH
): GLOBAL_STATE => {
  switch (action.type) {
    case types.UPDATE_ERROR_MESSAGE_FOUND:
      return {
        ...state,
        errorMessageFound: !!action.payload?.errorMessageFound,
      };
    case types.UPDATE_USER:
      if (action.payload?.userDetails) {
        return {
          ...state,
          userDetails: action.payload.userDetails,
        };
      }
      return state;
    case types.UPDATE_IS_LOGGED_IN_USER:
      if (action.payload?.isUserLoggedIn) {
        return {
          ...state,
          isUserLoggedIn: action.payload.isUserLoggedIn,
        };
      }
      return state;
    case types.UPDATE_SELECTED_FUND:
      if (action.payload?.selectedFund) {
        return {
          ...state,
          selectedFund: action.payload?.selectedFund,
        };
      }
      return state;
    case types.UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: !!action.payload?.isLoading,
      };
    case types.UPDATE_DOCUMENT_LIST:
      if (action.payload?.documentList) {
        return {
          ...state,
          documentList: action.payload.documentList,
        };
      }
      return state;
    case types.UPDATE_ARCHIVED_DOCUMENTS:
      if (action.payload?.documentList) {
        return {
          ...state,
          archivedDocumentList: action.payload.documentList,
        };
      }
      return state;
    case types.UPDATE_FUND_LIST:
      if (action.payload?.fundList) {
        return {
          ...state,
          fundList: action.payload.fundList,
        };
      }
      return state;
    case types.UPDATE_DOCUMENT_STATE:
      if (action.payload?.documentState) {
        return {
          ...state,
          documentState: action.payload?.documentState
            ? {
                ...(state?.documentState || {}),
                ...action.payload.documentState,
              }
            : undefined,
          documentDifference: {
            ...(state?.documentDifference || {}),
            ...action.payload?.documentState,
          },
        };
      } else if (action.payload?.documentState === undefined) {
        return {
          ...state,
          documentState: undefined,
          documentDifference: undefined,
        };
      }
      return state;
    case types.RESET_DOCUMENT_DIFFERENCE:
      return {
        ...state,
        documentDifference: undefined,
      };
    case types.UPDATE_STORED_FILTER:
      if (action.payload?.filters) {
        return {
          ...state,
          filters: action.payload.filters,
        };
      }
      return state;
    case types.UPDATE_STORED_FILTER_ARCHIVED:
      if (action.payload?.archivedFilters) {
        return {
          ...state,
          archivedFilters: action.payload.archivedFilters,
        };
      }
      return state;
    case types.UPDATE_USER_PERMISSION:
      return {
        ...state,
        userPermission: action.payload?.userPermission,
      };
    case types.UPDATE_COMMENTED_QUESTION_IDS:
      if (action.payload?.commentedQuestionIds) {
        return {
          ...state,
          commentedQuestionIds: action.payload?.commentedQuestionIds,
        };
      }
      return state;
    case types.UPDATE_DOCUMENTS_COUNT:
      if (typeof action.payload?.totalDocumentsCount === "number") {
        return {
          ...state,
          totalDocumentsCount: action.payload.totalDocumentsCount,
        };
      }
      return state;
    case types.UPDATE_LANGUAGES:
      if (action.payload?.languages?.length) {
        return {
          ...state,
          languages: action.payload.languages,
        };
      }
      return state;
    case types.UPDATE_APPROVER_GROUP:
      if (action.payload?.approverGroup) {
        return {
          ...state,
          approverGroup: action.payload?.approverGroup,
        };
      }
      return state;
    case types.UPDATE_DOCUMENT_ERROR:
      if (action.payload?.documentErrorState) {
        return {
          ...state,
          documentErrorState: action.payload?.documentErrorState,
        };
      }
      return state;
    case types.UPDATE_DOCUMENT_QUESTION:
      if (action.payload?.documentQuestions) {
        return {
          ...state,
          documentQuestions: action.payload?.documentQuestions,
        };
      }
      return state;
    default:
      return state;
  }
};

export default globalReducer;
