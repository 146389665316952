import React from "react";
import { ImageProps } from "../types";

const CheckBox: React.FC<ImageProps> = ({
  color = "#35CB93",
  ...otherProps
}) => {
  return (
    <svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
      <path d="M57 0C25.5705 0 0 25.5705 0 57C0 88.4295 25.5705 114 57 114C88.4295 114 114 88.4295 114 57C114 25.5705 88.4295 0 57 0ZM46.5865 85.6041L24.499 61.0585L31.0184 55.1913L46.3646 72.242L82.7596 28.9001L89.4845 34.5288L46.5865 85.6041Z" fill="#35CB93"/>
    </svg>
  );
};

export default CheckBox;