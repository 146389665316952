import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import { TriggerType, usePopperTooltip } from "react-popper-tooltip";
import SVGIcon from "../../../components/SvgIcons";
import Image from "../../../components/Image";
import { ImageName } from "../../../components/Image/types";
import {
  BulkSelect,
  TableHeader,
  TableRow,
} from "../../../types/listing-table";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { postData } from "../../../services/apis";
import { updateIsLoading } from "../../../store/globalActions";
import {
  ButtonContainer,
  ImageWrapper,
  ListItem,
  NameContainer,
  ToolTipContainer,
} from "./styles";
import { CLIENT_URL_PREFIX } from "../../../lib/constants";
import {
  DocumentActionEnum,
  DocumentRecord,
  DocumentStatusEnum,
  DocumentStatusToKey,
} from "../../../types/document-details";
import PreviewModal from "../../templates/DocumentEditor/PreviewModal";
import { useMemo } from "react";
import {
  getDocumentUserPermission,
  userHasPermission,
  notifySuccess,
  notifyError,
} from "../../../utils/common";
import DownloadModal from "../../templates/CommonComponents/DownloadModal";
import { saveAs } from "file-saver";
import {
  getDownloadableFileName,
  getSortState,
  manageSort,
  updateCheckboxSelection,
} from "../../../services/clientTable";

const ImageComponent: React.FC<{
  name: ImageName;
  onClick?: () => void;
  ref?: React.Dispatch<React.SetStateAction<HTMLElement | null>> | null;
}> = ({ ref, name, onClick }) => {
  return (
    <ImageWrapper onClick={onClick} ref={ref}>
      <Image name={name} />
    </ImageWrapper>
  );
};

const ButtonWrapper: React.FC<{
  setTriggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  imageName: ImageName;
}> = ({ children, setTriggerRef, imageName }) => {
  if (
    imageName !== "moreOptions" &&
    imageName !== "translate" &&
    imageName !== "download"
  ) {
    return <>{children}</>;
  }
  return <ButtonContainer ref={setTriggerRef}>{children}</ButtonContainer>;
};

type Props = {
  tableHeaders: TableHeader[];
  tableRows: TableRow[];
  sortOnColumns?: string[];
  onSortChange: (data: SortState | null) => void;
  getBulkSelect?: (data: BulkSelect | null) => void;
  bulkSelected: BulkSelect | null;
  updateDocList?: () => Promise<void>;
};

export type SortState = { [key: string]: TableHeader };

type ActionContainerProps = {
  imageName: ImageName;
  index: number;
  actionHandler: (
    name: ImageName,
    rowTable: TableRow,
    isDownloadOpen?: (val: boolean) => void
  ) => void;
  row: TableRow;
  onPreviousDocument?: () => void;
  onPdfDownload?: (
    row: TableRow,
    isUnformatted: boolean,
    showPageNumber: boolean
  ) => void;
  tooltipTrigger?: TriggerType;
  tooltipContent?: string;
};

const ActionContainer: React.FC<ActionContainerProps> = ({
  actionHandler,
  imageName,
  row,
  index,
  onPreviousDocument,
  onPdfDownload,
  tooltipTrigger,
  tooltipContent,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    closeOnOutsideClick: true,
    closeOnTriggerHidden: true,
    trigger: tooltipTrigger,
    interactive: true,
  });

  const documentStatus = row.data.status;
  const [isDownloadOpen, setIsDownloadOpen] = useState<boolean>(false);
  const {
    globalState: { userDetails, approverGroup, userPermission },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const userType = useMemo(
    () => userDetails && getDocumentUserPermission(approverGroup, userDetails),
    [approverGroup, userDetails]
  );
  const actionPermission = useMemo(() => {
    if (userPermission && userType && documentStatus) {
      const deletePermission = userHasPermission(
        {
          userType,
          docStatus: DocumentStatusToKey[documentStatus],
          docAction: DocumentActionEnum.delete,
        },
        userPermission
      );
      return {
        deletePermission,
      };
    } else {
      return {
        deletePermission: false,
      };
    }
  }, [userType, documentStatus, userPermission]);
  return (
    <>
      <ButtonWrapper setTriggerRef={setTriggerRef} imageName={imageName}>
        <div id={`${imageName}-${index}`} title={tooltipContent}>
          <ImageComponent
            name={imageName}
            onClick={() => actionHandler(imageName, row, setIsDownloadOpen)}
          />
        </div>
      </ButtonWrapper>

      <DownloadModal
        isOpen={isDownloadOpen}
        setIsDownloadOpen={setIsDownloadOpen}
        onUnformattedPdfDownload={(showPageNumber) =>
          onPdfDownload?.(row, true, showPageNumber)
        }
        onPdfDownload={(showPageNumber) =>
          onPdfDownload?.(row, false, showPageNumber)
        }
      />

      {visible && imageName === "moreOptions" && (
        <ToolTipContainer
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <div
            {...getArrowProps({
              className: "tooltip-arrow",
            })}
            style={{ marginLeft: "44%" }}
          />
          <div>
            <ListItem
              id={`previous-version-button-${index}`}
              onClick={() => onPreviousDocument?.()}
            >
              <Image name="previousVersion" />
              <NameContainer>Previous Versions</NameContainer>
            </ListItem>
          </div>
        </ToolTipContainer>
      )}
    </>
  );
};

const ClientTableArchived: React.FC<Props> = ({
  tableHeaders,
  tableRows,
  sortOnColumns,
  onSortChange,
  getBulkSelect,
  bulkSelected,
}) => {
  const [sortState, setSortState] = useState<SortState | null>(
    sortOnColumns?.length && tableHeaders.length
      ? {
          ...getSortState(tableHeaders, sortOnColumns),
        }
      : null
  );
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const [bulkSelect, setBulkSelect] = useState<BulkSelect>({});
  const [moreActionsOpen, setMoreActionsOpen] = useState(false);
  const [selectedPreviewDoc, setSelectedPreviewDoc] =
    useState<DocumentRecord>();

  const {
    globalState: {
      selectedFund,
      archivedDocumentList: documentList,
      userDetails,
    },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);

  useEffect(() => {
    bulkSelected ? setBulkSelect(bulkSelected) : setBulkSelect({});
  }, [bulkSelected]);
  const handleSorting = async (colName: string) => {
    setBulkSelect({});
    getBulkSelect?.(null);
    let deducedSortString: SortState | null = null;
    setSortState((prevState) => {
      deducedSortString = prevState ? manageSort(prevState, colName) : null;
      onSortChange(deducedSortString);
      return deducedSortString;
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSelectedCheckbox = (e?: { target: any }, value?: string) => {
    if (e?.target) {
      const { target } = e;
      target.previousSibling.checked = !target.previousSibling.checked;
      const updatedState = updateCheckboxSelection(
        target.previousSibling.checked,
        value,
        bulkSelect,
        tableRows
      );
      setBulkSelect({ ...updatedState });
      getBulkSelect?.(updatedState);
    }
  };
  const handleDownloadPDF = async (rowTable: TableRow, unFormatted = false, shoePageNumber: boolean) => {
    let isApproved = false;
    const status = rowTable.data.status;
    if (typeof status === "string") {
      if (
        status === DocumentStatusEnum.approved ||
        status === DocumentStatusEnum.filed
      )
        isApproved = true;
    }
    try {
      globalDispatch(updateIsLoading(true));
      const response = await postData({
        endPoint: "generatePdf/single",
        data: {
          clientId: userDetails?.clientId,
          documentId: rowTable.sfdrId,
          url: `${window.location.origin}${CLIENT_URL_PREFIX}document/view/${
            selectedFund?.client_id
          }/${rowTable.sfdrId}/pdf/${
            !isApproved ? (unFormatted ? "" : "showWaterMark") : ""
          }`,
          unFormatted: unFormatted,
          shoePageNumber: shoePageNumber,
        },
        responseType: "blob",
      });
      const subFundName = rowTable.subFundName;
      const temp = rowTable.data.template;
      const art = rowTable.data.article;
      const language = rowTable.data.language as string;
      const status = rowTable.data.status;
      const version = rowTable.data.approvedVersion
        ? rowTable.data.approvedVersion
        : null;
      // Use file-saver to save the Blob as a file
      const fileName = getDownloadableFileName(
        subFundName,
        art,
        temp,
        status,
        language,
        version
      );
      saveAs(response.data, `${fileName}.pdf`);
      globalDispatch(updateIsLoading(false));
      notifySuccess("Requested document is downloaded successfully");
    } catch (error) {
      console.log(error);
      globalDispatch(updateIsLoading(false));
      notifyError("Error occured while downloading, Please try again");
    }
  };
  const actionHandler = (
    name: ImageName,
    rowTable: TableRow,
    setIsDownloadOpen?: (val: boolean) => void
  ) => {
    switch (name) {
      case "download":
        setIsDownloadOpen?.(true);
        break;
      case "moreOptions":
        setMoreActionsOpen(!moreActionsOpen);
        break;
      default:
        break;
    }
  };
  if (!tableHeaders.length) {
    return null;
  }
  const handlePreviousDocument = (id: string) => {
    const selectedDoc = documentList.find((doc) => doc._id === id);
    setSelectedPreviewDoc(selectedDoc);
  };

  return (
    <div className="page-content">
      <a ref={downloadRef} style={{ display: "none" }} />
      {selectedPreviewDoc && selectedFund && (
        <PreviewModal
          isOpen={!!selectedPreviewDoc}
          onClose={() => setSelectedPreviewDoc(undefined)}
          document={selectedPreviewDoc}
          selectedFund={selectedFund}
        />
      )}
      <div>
        <div className="resp-table full-screen-table container">
          <div className="resp-table-header">
            <div
              className="table-header-cell col-100"
              data-switchgroup="kiids-select"
              data-switchtype="all"
              data-switchstate="unchecked"
            >
              <div className="table-header-shadow"></div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="selectAll"
                  checked={bulkSelect["selectAll"] || false}
                  onChange={() => updateSelectedCheckbox()}
                />
                <label
                  className="custom-control-label"
                  htmlFor="ddd-checkgroup-3-1"
                  onClick={(e) => updateSelectedCheckbox(e, "selectAll")}
                ></label>
              </div>
            </div>
            {tableHeaders?.map((header, index) => {
              return (
                <div
                  style={{
                    cursor:
                      sortState && sortState[header.colName]
                        ? "pointer"
                        : "text",
                  }}
                  key={`key-${index + 1}`}
                  className={`table-header-cell col-200`}
                  onClick={() => {
                    sortState &&
                      sortState[header.colName] &&
                      handleSorting(header.colName);
                  }}
                >
                  <span className="cell__title">{header.dispName}</span>
                  <span style={{ marginLeft: "4.25px" }}>
                    {sortState &&
                    sortState[header.colName] &&
                    sortState[header.colName].sortDir ? (
                      <SVGIcon
                        iconName={
                          sortState[header.colName].sortDir === "ASC"
                            ? "caretUp"
                            : "caretDown"
                        }
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              );
            })}
            <div className="table-header-cell">
              <div>Actions</div>
            </div>
          </div>
          <div className="resp-table-body container">
            {tableRows?.map((row, index) => {
              return (
                <div
                  id={`client-table-row-${index + 1}`}
                  className="resp-table-row"
                  key={`key-${index + 1}`}
                >
                  <div
                    className="table-body-cell col-100"
                    data-switchgroup="kiids-select"
                    data-switchtype="single"
                    data-statusid="5"
                  >
                    <div
                      id={`check-box-${index + 1}`}
                      className="custom-control custom-checkbox"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`ddd-checkgroup-4-${index + 1}`}
                        onChange={() => {
                          updateSelectedCheckbox({ target: null });
                        }}
                        checked={bulkSelect[row.id] || false}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`ddd-checkgroup-4-${index + 1}`}
                        onClick={(e) => {
                          updateSelectedCheckbox(e, row.id);
                        }}
                      ></label>
                    </div>
                  </div>
                  {row.dataField &&
                    row.dataField.length > 0 &&
                    row.dataField.map((cell, cellIndex) => {
                      return (
                        <Fragment key={`${cellIndex + 1}`}>
                          <div className="table-body-cell col-200">
                            <div className="colum-label">
                              {tableHeaders[cellIndex].dispName}
                            </div>
                            <div className="colum-deas">{cell.cellVal}</div>
                          </div>
                          {cellIndex + 1 === row.dataField.length && (
                            <div className="table-body-cell full-colum">
                              <div className="colum-deas">
                                <div className="action-group">
                                  <div className="action">
                                    <ActionContainer
                                      index={index + 1}
                                      tooltipContent="Download"
                                      actionHandler={actionHandler}
                                      imageName="download"
                                      row={row}
                                      tooltipTrigger="click"
                                      onPdfDownload={handleDownloadPDF}
                                    />
                                    <ActionContainer
                                      index={index + 1}
                                      tooltipContent="More Options"
                                      actionHandler={actionHandler}
                                      imageName="moreOptions"
                                      row={row}
                                      tooltipTrigger="click"
                                      onPreviousDocument={() =>
                                        handlePreviousDocument(row.sfdrId)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientTableArchived;
