import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.label<{ marginLeft?: number }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding-bottom: ${spacingIncrement(15)};
  margin-left: ${(props) => spacingIncrement(props.marginLeft || 0)};
  input[type="checkbox"] {
    cursor: pointer;
    appearance: none;
    background-color: white;
    margin: 0;
    color: currentColor;
    width: ${spacingIncrement(18)};
    height: ${spacingIncrement(18)};
    margin-right: ${spacingIncrement(8)};
    border: 1px solid #ccc;
    border-radius: ${spacingIncrement(2)};
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    clip-path: polygon(
      13% 0,
      0 13%,
      37% 50%,
      0 87%,
      13% 100%,
      50% 63%,
      87% 100%,
      100% 87%,
      63% 50%,
      100% 13%,
      87% 0,
      50% 37%
    );
    transform: scale(0);
    transform-origin: middle;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #303245;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:checked {
  }

  input[type="checkbox"]:disabled {
    color: #959495;
    cursor: not-allowed;
  }
`;

export const StyledBox = styled.div<{ disabled: boolean }>`
  width: ${spacingIncrement(18)};
  height: ${spacingIncrement(18)};
  border-radius: ${spacingIncrement(2)};
  transform: translateY(-0.075em);
  background-color: white;
  border: 1px solid #ccc;
  background-color: ${(props) => {
    return props.disabled ? "#eeeded" : "white";
  }};
  cursor: ${(props) => {
    return props.disabled ? "not-allowed" : "pointer";
  }};
  /* appearance: none;
  background-color: white;
  margin: 0;
  color: currentColor;
  width: ${spacingIncrement(18)};
  height: ${spacingIncrement(18)};
  margin-right: ${spacingIncrement(8)};
  border: 1px solid #ccc;
  border-radius: ${spacingIncrement(2)};
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  width: 0.75em;
  height: 0.75em;
  clip-path: polygon(
    13% 0,
    0 13%,
    37% 50%,
    0 87%,
    13% 100%,
    50% 63%,
    87% 100%,
    100% 87%,
    63% 50%,
    100% 13%,
    87% 0,
    50% 37%
  );
  transform: scale(0);
  transform-origin: middle;
  transition: 120ms transform ease-in-out;
  */
`;

export const Label = styled.div<{
  isBold?: boolean;
  fontSize?: number;
  disabled?: boolean;
}>`
  font-size: ${({ fontSize }) => spacingIncrement(fontSize || 14)};
  color: #303245;
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
  font-family: "Calibri", "Arial" !important;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  width: ${spacingIncrement(18)};
  height: ${spacingIncrement(18)};
  margin-right: ${spacingIncrement(8)};
`;

export const CheckedWrapper = styled.div`
  position: absolute;
  top: -2px;
  left: 4px;
  width: ${spacingIncrement(18)};
  height: ${spacingIncrement(18)};
`;
 