import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

const Container = styled.footer`
  border-top: 3px solid #d9dae3;
  background-color: ${({ theme }) => theme.color.backgroundGreyLight};
  padding: ${spacingIncrement(24)} 0 ${spacingIncrement(48)};
  margin: ${spacingIncrement(24)} 0 0 0;
`;

const SubContainer = styled.div`
  max-width: ${spacingIncrement(1276)};
  margin: 0 auto;
  padding: 0 ${spacingIncrement(15)};
`;

const Text = styled.span`
  color: #868e96;
  font-size: ${spacingIncrement(16)};
`;

const Footer: React.FC = () => {
  return (
    <Container>
      <SubContainer>
        <Text>© Zeidler Legal Services 2020. All Rights Reserved</Text>
      </SubContainer>
    </Container>
  );
};

export default Footer;
