import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import Image from "../../../../components/Image";
import { postData, uploadFile } from "../../../../services/apis";
import Context from "../../../../store/context";
import {
  DocumentRecord,
  DocumentState,
  DocumentViewModeEnum,
} from "../../../../types/document-details";
import { GLOBAL_CONTEXT } from "../../../../types/store";
import { notifyError } from "../../../../utils/common";
import {
  AddLogoButton,
  CrossButton,
  ImageContainer,
  ImageSubContainer,
  StyledImage,
  SupportedTextStyle,
  MainContainer,
} from "./styles";
import { useParams } from "react-router-dom";
import { removedDocumentIdFromState } from "../../DocumentEditor/service";

type Props = {
  updateDoc?: (doc: DocumentRecord) => void;
  document?: DocumentRecord;
  localDocumentState: DocumentState | undefined;
  disabled?: boolean;
  mode?: DocumentViewModeEnum;
};

const UploadLogoButton: React.FC<Props> = ({
  updateDoc,
  document,
  localDocumentState,
  disabled,
  mode,
}) => {
  const { id } = useParams<{ id: string; clientId: string }>();
  const {
    globalState: { selectedFund },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    setLogoUrl(
      localDocumentState?.logo
        ? `${process.env.REACT_APP_CLOUDFRONT_URL}/${
            localDocumentState?.logo
          }?${new Date().getTime()}`
        : ""
    );
  }, [localDocumentState]);

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      notifyError(
        fileRejections[0].errors[0].code === "file-too-large"
          ? "File size is too big"
          : "File is not supported"
      );
      return;
    }
    try {
      setLoading(true);
      const resp = await uploadFile({
        endPoint: "upload",
        file: acceptedFiles[0],
        data: {
          type: "logo",
          clientId: selectedFund?.client_id,
          documentId: document?._id,
        },
      });
      if (resp.data.message === "Success") {
        updateDoc?.(resp.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const onDeleteLogo = async () => {
    try {
      setLoading(true);
      const documentStateWithoutId = removedDocumentIdFromState(
        document?.documentState || {}
      );
      const docDetails = {
        ...document,
        documentState: {
          ...documentStateWithoutId,
          logo: "",
        },
      } as DocumentRecord;
      await postData({
        endPoint: "updateDocument",
        data: {
          clientId: selectedFund?.client_id,
          documentId: id,
          documentDetail: docDetails,
        },
      });
      updateDoc?.(docDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const supportedText = useMemo(
    () => (
      <SupportedTextStyle>
        (PNG, JPEG are supported, max size 1MB)
      </SupportedTextStyle>
    ),
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg,image/png",
    maxSize: 1024 * 1024,
  });

  if (loading) {
    return (
      <MainContainer>
        <ImageContainer loading={true}>Uploading... </ImageContainer>
      </MainContainer>
    );
  }
  if (mode === DocumentViewModeEnum.view && !!logoUrl) {
    return (
      <MainContainer maxHeight={120} maxWidth={120}>
        <ImageContainer disabled={disabled} viewMode>
          <StyledImage src={logoUrl} alt="logo" />
        </ImageContainer>
      </MainContainer>
    );
  } else if (mode === DocumentViewModeEnum.edit && logoUrl) {
    return (
      <MainContainer>
        <ImageContainer>
          <ImageSubContainer {...getRootProps()} disabled={disabled}>
            <input {...getInputProps()} disabled={disabled} />
            <StyledImage src={logoUrl} alt="logo" />
          </ImageSubContainer>
          {!disabled && <CrossButton name="cross" onClick={onDeleteLogo} />}
        </ImageContainer>
      </MainContainer>
    );
  } else if (mode === DocumentViewModeEnum.edit) {
    return (
      <MainContainer morePadding>
        <AddLogoButton
          buttonType="solid"
          {...getRootProps()}
          disabled={disabled}
        >
          <input {...getInputProps()} disabled={disabled} />
          <Image name="plus" height={14} width={14} /> Add Logo
        </AddLogoButton>
        {supportedText}
      </MainContainer>
    );
  } else {
    return <div />;
  }
};

export default UploadLogoButton;
