import React from "react";
interface Props {
  pageSize: number | string;
  handleChange: any;
}
export default function PageSize({ pageSize, handleChange }: Props) {
  //   function handleChange(event) {}

  return (
    <div className="col-auto">
      <div className="page-items-count text--base text--medium">
        Show&nbsp;
        <select
          className="control control--inline control--small"
          name="pageSize"
          value={pageSize}
          onChange={handleChange}
        >
          <option value="20">20</option>
          <option value="40">40</option>
          <option value="60">60</option>
          <option value="80">80</option>
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </select>
        &nbsp;rows per page
      </div>
    </div>
  );
}
