import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";

type Props = {
  isOpen: boolean;
  setIsDownloadOpen?: (value: boolean) => void;
  onPdfDownload: (showPageNumber: boolean) => void;
  onUnformattedPdfDownload: (showPageNumber: boolean) => void;
};

const DownloadModal: React.FC<Props> = ({
  isOpen,
  setIsDownloadOpen,
  onPdfDownload,
  onUnformattedPdfDownload,
}) => {
  const [unformatted, setUnFormatted] = useState<boolean>(false);
  const [showPageNumber, setShowPageNumber] = useState<boolean>(true);
  useEffect(() => {
    if (isOpen) {
      setUnFormatted(false);
      setShowPageNumber(true);
    }
  }, [isOpen]);

  const OnDownlod = () => {
    if (!unformatted) {
      onPdfDownload?.(showPageNumber);
      setIsDownloadOpen?.(false);
    } else {
      onUnformattedPdfDownload?.(showPageNumber);
      setIsDownloadOpen?.(false);
    }
  };

  const onClose = () => {
    setIsDownloadOpen?.(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Download Confirmation"
      height={425}
    >
      <div className="modal__body p-30">
        <div>
          <p className="text-18">Select the file format you want to download</p>
        </div>
        <div>
          <div className="form-check-group d-flex">
            <div className="custom-control custom-radio mr-5">
              <input
                className="custom-control-input"
                type="radio"
                id="pdf-radio"
                checked={!unformatted}
              />
              <label
                className="custom-control-label"
                onClick={() => setUnFormatted(false)}
              >
                PDF
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="unformatted-radio"
                type="radio"
                checked={unformatted}
              />
              <label
                className="custom-control-label"
                onClick={() => setUnFormatted(true)}
              >
                Unformatted PDF
              </label>
            </div>
          </div>
        </div>

        <div>
          <p className="text-18">Page Numbers Option</p>
        </div>
        <div>
          <div className="form-check-group d-flex">
            <div className="custom-control custom-radio mr-5">
              <input
                className="custom-control-input"
                type="radio"
                id="pdf-show-page-number-radio"
                checked={showPageNumber}
              />
              <label
                className="custom-control-label"
                onClick={() => setShowPageNumber(true)}
              >
                With Page Numbers
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="pdf-no-page-number-radio"
                type="radio"
                checked={!showPageNumber}
              />
              <label
                className="custom-control-label"
                onClick={() => setShowPageNumber(false)}
              >
                Without Page Numbers
              </label>
            </div>
          </div>
        </div>
        <div className="mb-20">
          <p className="text-12">
            Please note that any changes made outside the system in the
            unformatted PDF/PDF document will not be automatically reflected on
            the system.
          </p>
        </div>
        <div>
          <button
            id="download-button-submit-in"
            className="btn btn-primary"
            onClick={() => OnDownlod()}
          >
            Download
          </button>
          <button className="btn btn-outline-primary" onClick={() => onClose()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
