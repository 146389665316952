import styled, { css } from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const Container = styled.div<{ disabled?: boolean }>`
  &&& {
    .ql-container {
      height: unset;
      padding-bottom: ${spacingIncrement(5)};
      margin-bottom: ${spacingIncrement(20)};
      font-family: "Calibri", "Arial" !important;
      font-size: 16px !important;
    }
    .ql-picker-options {
      z-index: 9999;
    }
    .ql-editor ol,
    .ql-editor ul {
      font-size: 16px !important;
    }
    .ql-disabled {
      border: 0;
      background: #f7f7f7;
      padding-bottom: ${spacingIncrement(5)};
      margin-bottom: ${spacingIncrement(20)};
    }
    ${({ disabled }) =>
      disabled &&
      css`
        .ql-toolbar {
          display: none;
        }
      `}
  }
`;

export const ViewModeEditorComponent = styled.div<{ isEmpty?: boolean }>`
  padding: 10px;
  background: #f2f2f2;
  font-family: "Calibri", "Arial" !important;
  font-size: 16px !important;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      color: #999;
      user-select: none;
    `}

  .btn {
    all: unset;
  }

  ol,
  ul {
    font-size: 16px !important;
  }

  ol {
    list-style-type: number;
    margin-left: 30px;
  }

  ul {
    list-style-type: disc;
    margin-left: 30px;
  }

  ul ul {
    list-style-type: circle;
    margin-left: 30px;
  }

  ul ul ul {
    list-style-type: square;
    margin-left: 30px;
  }

  /* Additional CSS for controlling table row pagination */
  tr,
  td {
    /* Avoid page breaks within a table cell */
    page-break-inside: avoid !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
