import React from "react";
import Logo from "../Logo/index";
import { NAV_BAR_LINKS as navLinks } from "../../lib/constants";
import NavigationGroups from "../NavigationGroups/index";
// import { withRouter } from "react-router";
import SVGIcon from "../SvgIcons/index";
import KeycloakService from "../../keycloak/KeycloakService";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function NavigationPanel({ onSwiftSelect, userDetails }: any) {
  return (
    <section className="sidebar">
      <div className="sidebar__header" onClick={onSwiftSelect}>
        <Logo />
      </div>

      <div className="sidebar__body">
        <ul className="nav nav--main">
          <li className="nav__item">
            <a
              className="nav__link has-icon"
              href={`${process.env.REACT_APP_SWIFT_URL}/admin`}
            >
              <span className="icon">
                <SVGIcon iconName="dashboard" />
              </span>
              Dashboard
            </a>
          </li>

          <NavigationGroups navLinks={navLinks} />
        </ul>
      </div>

      <div className="sidebar__user">
        <div className="active-user">
          <div className="letter-in-circle">
            {userDetails.userName &&
              userDetails.userName.charAt(0).toUpperCase()}
          </div>

          <div className="active-user__name">{userDetails.userName}</div>
        </div>
      </div>
      <div className="sidebar__signout">
        {/* <Icon name="logout-22" /> */}
        <div onClick={KeycloakService.doLogout}>
          <span style={{ color: "white", padding: "5px", cursor: "pointer" }}>
            Sign out
          </span>
        </div>
      </div>
    </section>
  );
}

// const NavigationPanel = withRouter(NavPanel);
export default NavigationPanel;
