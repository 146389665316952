import { BulkSelected } from "../../types/listing-table";
import Image from "../Image";

const ValidationDescription = ({
  documents,
  imageName,
  title,
}: {
  documents?: BulkSelected[];
  imageName: "successCircleCheck" | "failureCircle";
  title: string;
}) => {
  return (
    <div className="bulkstatus-alert-txt">
      <p className="bulkstatus-icon-wrap">
        <span>
          <Image
            name={imageName}
            // name="successCircleCheck"
          />
        </span>
        <span>
          {title}
        </span>
      </p>
      <ul>
        {documents?.map((document) => {
          return (
            <li key={document.id}>
              {document.fundName}/{document?.subFundName}/{document.template}/
              {document?.article}/{document?.language}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const BulkStatusValidationAlert = ({
  validationSuccess,
  validationFailed,
  onCancel,
  onProceed
}: {
  validationSuccess?: BulkSelected[];
  validationFailed?: BulkSelected[];
  onCancel: () => void;
  onProceed: () => void;
}) => {
  if (validationSuccess?.length === 0) {
    return (
      <div className="modal__body p-30">
        <div className="mb-4">
          <ValidationDescription
            documents={validationFailed}
            imageName="failureCircle"
            title="Please note that there are validation errors in the documents listed below. Please review and try again."
          />
        </div>
        <div className="mb-2">
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#SFDR-bulk-status-update-modal"
            onClick={onCancel}
          >
            Ok, Got it
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="modal__body p-30">
      <div className="mb-4">
        <ValidationDescription
          documents={validationSuccess}
          imageName="successCircleCheck"
          title="Requested changes will be applicable for the below documents:"
        />
        <ValidationDescription
          documents={validationFailed}
          imageName="failureCircle"
          title="Please note that requested changes could not be applied to the below documents as few questions are left unanswered."
        />
      </div>
      <div className="mb-2">
        <button
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#SFDR-bulk-status-update-modal"
          onClick={onProceed}
        >
          Skip & Proceed
        </button>
        <button
          className="btn btn-outline-primary"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
