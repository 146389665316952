// user
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_IS_LOGGED_IN_USER = "UPDATE_IS_LOGGED_IN_USER";
export const UPDATE_SELECTED_FUND = "UPDATE_SELECTED_FUND";
export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";
export const UPDATE_DOCUMENT_LIST = "UPDATE_DOCUMENT_LIST";
export const UPDATE_FUND_LIST = "UPDATE_FUND_LIST";
export const UPDATE_DOCUMENT_STATE = "UPDATE_DOCUMENT_STATE";
export const UPDATE_STORED_FILTER = "UPDATE_STORED_FILTER";
export const UPDATE_STORED_FILTER_ARCHIVED = "UPDATE_STORED_FILTER_ARCHIVED";
export const UPDATE_USER_PERMISSION = "UPDATE_USER_PERMISSION";
export const UPDATE_COMMENTED_QUESTION_IDS = "UPDATE_COMMENTED_QUESTION_IDS";
export const UPDATE_DOCUMENTS_COUNT = "UPDATE_DOCUMENTS_COUNT";
export const UPDATE_LANGUAGES = "UPDATE_LANGUAGES";
export const UPDATE_APPROVER_GROUP = "UPDATE_APPROVER_GROUP";
export const UPDATE_DOCUMENT_ERROR = "UPDATE_DOCUMENT_ERROR";
export const UPDATE_DOCUMENT_QUESTION = "UPDATE_DOCUMENT_QUESTION";
export const RESET_DOCUMENT_DIFFERENCE = "RESET_DOCUMENT_DIFFERENCE";
export const UPDATE_ERROR_MESSAGE_FOUND = "UPDATE_ERROR_MESSAGE_FOUND";
export const UPDATE_ARCHIVED_DOCUMENTS = "UPDATE_ARCHIVED_DOCUMENTS";
