import React, { useEffect, useState } from "react";
import { StyledInput } from "./styles";
import { StyledNumberInlineInput } from "../../common.styles";

type Props = {
  value: string | number | undefined | boolean;
  onChange?: (value: string | number) => void;
  maxLimit?: number;
  minLimit?: number;
  disabled?: boolean;
  placeholder?: string;
  width?: number;
  step?: string;
  backgroundColor?: string;
  inline?: boolean;
  id: string;
};

export const TableNumberInput: React.FC<Props> = ({
  value = "",
  onChange,
  maxLimit = undefined,
  disabled = false,
  minLimit = 0,
  placeholder = "",
  width = 100,
  step = 1,
  backgroundColor = "transparent",
  inline = false,
  id,
}) => {
  const [data, setData] = useState(value?.toString() || "");
  useEffect(() => {
    setData(value?.toString() || "");
  }, [value]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "") {
      setData("");
      onChange?.("0");
      return;
    }
    if (value || value === "0") {
      const v =
        maxLimit && parseFloat(value) > maxLimit
          ? maxLimit
          : minLimit > parseFloat(value)
          ? "0"
          : value;
      const preciseVal: string = Number(v).toFixed(2);
      setData(v.toString());
      onChange?.(Number(preciseVal));
    }
  };

  if (inline) {
    return (
      <StyledNumberInlineInput
        id={id}
        value={data}
        onChange={handleChange}
        onBlur={() => {
          if (data === "") return onChange?.("0");
          const fixedValue: string = Number(data).toFixed(2);
          setData(Number(fixedValue).toString());
          onChange?.(fixedValue);
        }}
        disabled={disabled}
        placeholder={placeholder}
        type="number"
        step={step}
        max={maxLimit}
        min={minLimit}
      />
    );
  }
  return (
    <StyledInput
      id={id}
      value={data}
      backgroundColor={backgroundColor}
      onChange={handleChange}
      onBlur={() => {
        if (data === "") return onChange?.("0");
        const fixedValue: string = Number(data).toFixed(2);
        setData(Number(fixedValue).toString());
        onChange?.(fixedValue);
      }}
      disabled={disabled}
      placeholder={placeholder}
      width={width}
      type="number"
      step={step}
      min="0"
    />
  );
};
