import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.div<{ shouldExpendFooter: boolean, isDocumetLocked?: boolean }>`
  ${({shouldExpendFooter}) => {
    return css`
    position: ${shouldExpendFooter ? 'relative' : 'fixed'};
    left: calc(100% - ${spacingIncrement(1216)} / 2))px;
    bottom: ${shouldExpendFooter ? '20px' : '-120px'};
    background: #fff;
    z-index: 10;
    transition: all 0.3s ease;
    padding: ${spacingIncrement(20)};
    width: ${spacingIncrement(1216)};
    margin: auto;
    border: 1px solid #dbd9d9;
    box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &:hover {
      bottom: ${shouldExpendFooter ? '20px' : '0px'};
    }
    &::before {
      content: "";
      background: #fff;
      box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.07);
      position: absolute;
      top: -25px; /* Adjust the top and right values as per your requirements */
      right: 10px;
      width: 50px; /* Set the width and height of the small rectangle */
      height: 25px;
      visibility: ${shouldExpendFooter ? 'hidden' : 'visible'};
      border-top: 1px solid #dbd9d9;
      border-left: 1px solid #dbd9d9;
      border-right: 1px solid #dbd9d9;
    }
    `
  }}
  ${({ isDocumetLocked }) =>
    isDocumetLocked &&
    css`
      z-index: -1;
    `}
`;

export const ButtonContainer = styled.div`
  padding: ${spacingIncrement(16)} 0 0 0;
  display: flex;
  flex-direction: row;
  gap: ${spacingIncrement(10)};
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
