import { CLIENT_URL_PREFIX } from "../lib/constants";
import { DocumentStatusEnum } from "../types/document-details";
import { FundList } from "../types/fund";
import { BulkSelected } from "../types/listing-table";
import { notifyError } from "./common";

export const validateRequestWordDoc = (selectedDocs: BulkSelected[]) => {
  const invalid = selectedDocs.find((docs) => {
    return (
      docs.status &&
      docs?.status !== DocumentStatusEnum.approved &&
      docs?.status !== DocumentStatusEnum.filed
    );
  });
  if (invalid) {
    notifyError(
      "Please select documents with the status, as 'Approved' or 'Filed' to proceed with the request for a Word format."
    );
    return false;
  }
  return true;
};

export const getDownloadBulkData = (
  selectedBulkIds: BulkSelected[],
  unFormatted: boolean,
  selectedFund?: FundList,
  showPageNumber = true
) => {
  return selectedBulkIds.map(
    ({ id, isApproved, subFundName, article, template, status, version }) => ({
      url: `${window.location.origin}${CLIENT_URL_PREFIX}document/view/${
        selectedFund?.client_id
      }/${id}/pdf/${!isApproved ? (unFormatted ? "" : "showWaterMark") : ""}`,
      article,
      template,
      status,
      subFundName,
      documentId: id,
      version,
      unFormatted,
      showPageNumber,
    })
  );
};