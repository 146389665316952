import Image from "../../../components/Image";
import QuillEditor from "../../../components/QuillEditor";
import {
  DocumentRecord,
  DocumentStatusEnum,
  DocumentViewModeEnum,
} from "../../../types/document-details";
import { spacingIncrement } from "../../../utils/common";
import Checkbox from "../CommonComponents/CheckBox";
import CommentButton from "./CommentButton";
import DocDoNotIncludeIcon from "../CommonComponents/DocDoNotIncludeIcon";
import MainIcon from "./MainIcon";
import { NumberInput } from "../CommonComponents/NumberInput";
import Section from "../CommonComponents/Section";
import {
  Container,
  GreyBox,
  Bold,
  OrangeBoxHeader,
  OrangeBox,
  OrangeLeft,
  OrangeRight,
  OrangeLeftHeader,
  OrangeRightHeader,
  Circle,
  OrangeSection,
  QuestionText,
  HorizontalLine,
  GreyDot,
  FlexRow,
  UploadWrapper,
  Highlight,
  GraphContainer,
  GraphSubContainer,
  Flex,
  FlexColumn,
  RelativeContainer,
  InputCheckBoxContainer,
  CheckBoxLabel,
  Block,
  WaterMarkText,
  WaterMarkWrapper,
  UploadLeftSide,
  UploadRightSide,
  Note,
  LegendSquare,
} from "./styles";
import { QuestionContainer } from "../CommonComponents/QuestionContainer";
import SubIcon from "./SubIcon";
import { useDocumentState } from "../../../hooks/useDocumentState";
import { useCallback, useEffect, useMemo } from "react";
import { updateDocumentState } from "../../../store/globalActions";
import { useContext } from "react";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import UploadLogoButton from "../CommonComponents/UploadLogoButton";
import { Preset } from "../CommentSection";
import { CommentType } from "../../../types/comment";
import {
  DisplayQuestion,
  DocumentCommonContent,
  DocumentStaticContent,
  LabelText,
  Language,
} from "../../../types/document-question";
import {
  getCountryListArray,
  getQuestionLabel,
  getStaticContentText,
} from "../DocumentEditor/service";
import TableInput from "../CommonComponents/TableInput/index";
import BarChart from "../CommonComponents/BarChart";
import InfoToolTip from "../../../components/InfoToolTip";
import { TooltipWrapper } from "../../../components/InfoToolTip/styles";
import parse from "html-react-parser";
import {
  AvoidBreakInside,
  GraphFooter,
  Text,
  Title,
  ProductInfo,
} from "../CommonComponents/common.styles";
import Tabular from "../CommonComponents/Tabular";
import { HeaderData } from "../CommonComponents/TableInput/index";
import PreviousAnswers from "../CommonComponents/PreviousAnswers";
import {
  AnnexText,
  Label,
  ErrorMessage,
  QuestionLabel,
} from "../CommonComponents/Typography";
import { RegulatoryExternalLinkComponent } from "../CommonComponents/RegulatoryExternalLinkComponent";
import { REGULATORY_EXTERNAL_LINK_ID } from "../../../lib/constants";
import AssetAllocationGraph from "../../../components/AssetAllocationGraph";
import {
  ProductInfoContainer,
  SubHeading,
} from "../ArticleEightPeriodic/styles";

type Props = {
  document: DocumentRecord;
  mode?: DocumentViewModeEnum;
  updateDoc: (doc: DocumentRecord | undefined) => void;
  toggleQuestionCommentSection?: (preset: Preset) => void;
  togglePreviousAnswersSection?: (val: string) => void;
  showCommentButton?: boolean;
  showPreviousAns?: boolean;
  canEdit?: boolean;
  displayQuestions: DisplayQuestion;
  isLocked?: boolean;
  showWaterMark?: boolean;
  staticContent: DocumentStaticContent;
  commonContent: DocumentCommonContent;
};

const ArticleNinePeriodic: React.FC<Props> = ({
  mode = DocumentViewModeEnum.edit,
  document,
  updateDoc,
  toggleQuestionCommentSection,
  togglePreviousAnswersSection,
  showCommentButton,
  showPreviousAns,
  canEdit,
  displayQuestions,
  isLocked,
  showWaterMark,
  staticContent,
  commonContent,
}) => {
  const {
    globalState: { commentedQuestionIds, documentState },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);
  const { getValue, getQuestionError, updateAnswer, handleFloatInput } =
    useDocumentState();
  const isEditMode = useMemo(() => {
    return mode === DocumentViewModeEnum.edit && canEdit;
  }, [canEdit]);

  const headerData: HeaderData[] = [
    {
      label: getStaticContentText(
        "Rmuz6N7egm",
        staticContent.staticText,
        document.language
      ),
      key: "largeInvestments",
      spacing: 1.4,
    },
    {
      label: getStaticContentText(
        "JCf2p2Wgdz",
        staticContent.staticText,
        document.language
      ),
      key: "sector",
      spacing: 1.2,
    },
    {
      label: getStaticContentText(
        "Ve81eUwV6X",
        staticContent.staticText,
        document.language
      ),
      key: "assets",
      spacing: 1,
    },
    {
      label: getStaticContentText(
        "ScMfI7ew4b",
        staticContent.staticText,
        document.language
      ),
      key: "country",
      spacing: 1.2,
    },
  ];

  useEffect(() => {
    if (documentState?.["rsEnn3lmD3"] !== "Yes") {
      updateAnswer("rsEnn3lmD3", "Yes");
    }
    return () => {
      globalDispatch(updateDocumentState(undefined));
      updateDoc(undefined);
    };
  }, []);

  const onComment = (qId: string) => {
    const preset: Preset = {
      commentType: CommentType.questionLevel,
      documentId: document._id,
      questionId: qId,
    };
    toggleQuestionCommentSection?.(preset);
  };

  const onPreviousAnswersClick = (qId: string) => {
    togglePreviousAnswersSection?.(qId);
  };

  const showInfoIcon = !(mode === DocumentViewModeEnum.view);

  const showDoNotIncludeIcon = !(mode === DocumentViewModeEnum.view);

  const showChartInputPercentage = !(mode === DocumentViewModeEnum.edit);

  const showErrorMessage = !(mode === DocumentViewModeEnum.view);

  const hasCommentsById = useCallback(
    (qId: string) => {
      return commentedQuestionIds.includes(qId);
    },
    [commentedQuestionIds]
  );

  const isNewVersionDocument = () => {
    if (document.status === DocumentStatusEnum.filed) {
      if (getValue("jPjVV4rSN1")) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const clearChartData = (questionIds: string[]) => {
    questionIds.forEach((id: string) => {
      updateAnswer(id, 0);
    });
  };

  const clearTaxnomyIncludingChartData = () => {
    clearChartData([
      "lF90AbRUA1",
      "NpheFtm86D",
      "ylHOgkmLpx",
      "XQ7GlOjhpm",
      "2VIxL6JKVP",
      "eUJfUOJzeK",
      "VXP3xP983z",
      "QDcqPBs1kK",
      "0as0eZZpFc",
      "p61HrjyPr5",
      "uIqG7QbHHX",
      "SGPLaP0VWQ",
    ]);
  };
  const clearTaxnomyExcludingChartData = () => {
    clearChartData([
      "cLMXNErpLx",
      "Vsz4WtWWOq",
      "eCFaoWSGWu",
      "PxtylL4phn",
      "ZqcMr44CWg",
      "nv0uz7vMJ3",
      "Hqu7pAYYbT",
      "vzqEWFs9ja",
      "Xt8rx74lrB",
      "B3tQAj2rDN",
      "jXvToL4j1p",
      "L3Kk6y1z4m",
    ]);
  };

  const barCharLabels = [
    getStaticContentText(
      "TFs1iWMg9P",
      staticContent.staticText,
      document.language
    ),
    getStaticContentText(
      "UeefNSjL5R",
      staticContent.staticText,
      document.language
    ),
    getStaticContentText(
      "jr5sewetYY",
      staticContent.staticText,
      document.language
    ),
  ];

  const subQuestionTopPadding = 20;
  return (
    <Container isLocked={isLocked}>
      <UploadLogoButton
        document={document}
        localDocumentState={documentState}
        updateDoc={(d) => updateAnswer("logo", d.documentState?.logo)}
        disabled={!isEditMode}
        mode={mode}
      />
      {showWaterMark && (
        <WaterMarkWrapper>
          <WaterMarkText>DRAFT</WaterMarkText>
        </WaterMarkWrapper>
      )}
      <Section
        left={<div style={{ minHeight: "140px" }}></div>}
        right={
          <>
            {/* <AnnexText>
              <Label isBold isItalic fontSize={14}>
                {parse(
                  getStaticContentText(
                    "Ovs6YgWZDD",
                    staticContent.staticText,
                    document.language
                  )
                )}
              </Label>
            </AnnexText> */}
            <SubHeading>
              {/* Template periodic disclosure for the financial products referred
                to in Article 9, paragraphs 1 to 4a, of Regulation (EU)
                2019/2088 and Article 5, first paragraph, of Regulation (EU)
                2020/852 */}
              {parse(
                getStaticContentText(
                  "ZhPbabrVCC",
                  staticContent.headingText,
                  document.language
                )
              )}
            </SubHeading>
            <ProductInfoContainer>
              <ProductInfo>
                {parse(
                  getStaticContentText(
                    "vS8nIYaM8c",
                    staticContent.staticText,
                    document.language
                  )
                )}
                : {document?.subFundName}
              </ProductInfo>
              <ProductInfo>
                {parse(
                  getStaticContentText(
                    "9GiGPkYC1D",
                    staticContent.staticText,
                    document.language
                  )
                )}
                : {document?.legalEntityIdentifier}
              </ProductInfo>
            </ProductInfoContainer>
          </>
        }
      />
      <Section
        left={
          <>
            <GreyBox>
              {/* <strong>Sustainable investment </strong>means an investment in an
              economic activity that contributes to an environmental or social
              objective, provided that the investment does not significantly
              harm any environmental or social objective and that the investee
              companies follow good governance practices. */}
              {parse(
                getStaticContentText(
                  "xSDYQ8FkGg",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
            <GreyBox>
              {/* The
              <strong> EU Taxonomy </strong> is a classification system laid
              down in Regulation (EU) 2020/852 establishing a list of
              <strong> environmentally sustainable economic activities</strong>.
              That Regulation does not lay down a list of socially sustainable
              economic activities. Sustainable investments with an environmental
              objective might be aligned with the Taxonomy or not. */}
              {parse(
                getStaticContentText(
                  "YF89tVD7C0",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <Title>
              {/* Sustainable investment objective */}
              {parse(
                getStaticContentText(
                  "kwAu2e4cIZ",
                  staticContent.headingText,
                  document.language
                )
              )}
            </Title>
            <OrangeBoxHeader>
              <Bold>
                <FlexRow>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [tick and fill in as relevant, the percentage figure
                    represents the minimum commitment to sustainable
                    investments] */}
                    {parse(
                      getStaticContentText(
                        "PjwA7hiTCA",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <Label isBold>
                    {getQuestionLabel(
                      "rsEnn3lmD3",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                  </Label>
                  <CommentButton
                    hasComments={hasCommentsById("rsEnn3lmD3")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("rsEnn3lmD3")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["rsEnn3lmD3"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("rsEnn3lmD3")}
                  />
                </FlexRow>
              </Bold>
              <Flex>
                <OrangeLeftHeader>
                  <Circle isHighlighted />
                  <Circle isHighlighted />
                  <Checkbox
                    id="1"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={6}
                    disabled={true}
                    checked={true}
                    mode={mode}
                  />
                </OrangeLeftHeader>
                <OrangeRightHeader>
                  <Circle isHighlighted />
                  <Circle />
                  <Checkbox
                    id="2"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={6}
                    disabled={true}
                    mode={mode}
                  />
                  <HorizontalLine />
                </OrangeRightHeader>
              </Flex>
            </OrangeBoxHeader>
            <OrangeBox>
              <OrangeSection>
                <OrangeLeft>
                  <ErrorMessage
                    questionId="rsEnn3lmD3"
                    isEditMode={showErrorMessage}
                  >
                    {getQuestionError("rsEnn3lmD3")?.message}
                  </ErrorMessage>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="3"
                      fontSize={15}
                      label=""
                      disabled={!isEditMode}
                      checked={!!getValue("DyJP6kgJvr")}
                      onClick={() =>
                        updateAnswer("DyJP6kgJvr", !getValue("DyJP6kgJvr"))
                      }
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "DyJP6kgJvr",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <NumberInput
                        maxLimit={100}
                        value={
                          getValue("DyJP6kgJvr") === true
                            ? 0
                            : getValue("DyJP6kgJvr") || ""
                        }
                        onChange={(e) => handleFloatInput(e, "DyJP6kgJvr", 100)}
                        disabled={!isEditMode}
                        inline
                      />
                      {
                        getQuestionLabel(
                          "DyJP6kgJvr",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <ErrorMessage
                    questionId="DyJP6kgJvr"
                    isEditMode={showErrorMessage}
                    marginLeft={35}
                  >
                    {getQuestionError("DyJP6kgJvr")?.message}
                  </ErrorMessage>
                  <Checkbox
                    id="4"
                    marginLeft={35}
                    bold={false}
                    fontSize={12}
                    label={getQuestionLabel(
                      "MyzbgUOTiu",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode || !getValue("DyJP6kgJvr")}
                    checked={getValue("MyzbgUOTiu")}
                    onClick={() =>
                      updateAnswer("MyzbgUOTiu", !getValue("MyzbgUOTiu"))
                    }
                    mode={mode}
                  />
                  <Checkbox
                    id="5"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "SAkfVvzfm8",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode || !getValue("DyJP6kgJvr")}
                    checked={getValue("SAkfVvzfm8")}
                    onClick={() =>
                      updateAnswer("SAkfVvzfm8", !getValue("SAkfVvzfm8"))
                    }
                    mode={mode}
                  />
                </OrangeLeft>
                <OrangeRight>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="6"
                      fontSize={15}
                      label=""
                      disabled={true}
                      checked={getValue("ftMevBrlfS")}
                      onClick={() =>
                        updateAnswer("ftMevBrlfS", !getValue("ftMevBrlfS"))
                      }
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "ftMevBrlfS",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <NumberInput
                        value={getValue("c9GG1Fna3h") || ""}
                        onChange={(e) => handleFloatInput(e, "c9GG1Fna3h", 100)}
                        disabled={true}
                        inline
                      />
                      {
                        getQuestionLabel(
                          "ftMevBrlfS",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <Checkbox
                    id="7"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "TvDyBDBx59",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={true}
                    checked={getValue("TvDyBDBx59")}
                    onClick={() =>
                      updateAnswer("TvDyBDBx59", !getValue("TvDyBDBx59"))
                    }
                    mode={mode}
                  />
                  <Checkbox
                    id="8"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "nFxgI7sHcj",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={true}
                    checked={getValue("nFxgI7sHcj")}
                    onClick={() =>
                      updateAnswer("nFxgI7sHcj", !getValue("nFxgI7sHcj"))
                    }
                    mode={mode}
                  />
                  <Checkbox
                    id="9"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={
                      getQuestionLabel(
                        "oMyjPpjL44",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={true}
                    checked={getValue("oMyjPpjL44")}
                    onClick={() =>
                      updateAnswer("oMyjPpjL44", !getValue("oMyjPpjL44"))
                    }
                    mode={mode}
                  />
                  <HorizontalLine />
                </OrangeRight>
              </OrangeSection>
              <OrangeSection>
                <OrangeLeft>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="10"
                      fontSize={15}
                      label=""
                      disabled={!isEditMode}
                      checked={!!getValue("xPAw6f7A6l")}
                      onClick={() =>
                        updateAnswer("xPAw6f7A6l", !getValue("xPAw6f7A6l"))
                      }
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "xPAw6f7A6l",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <NumberInput
                        value={
                          getValue("xPAw6f7A6l") === true
                            ? 0
                            : getValue("xPAw6f7A6l") || ""
                        }
                        onChange={(e) => handleFloatInput(e, "xPAw6f7A6l", 100)}
                        maxLimit={100}
                        disabled={!isEditMode}
                        inline
                      />
                      {
                        getQuestionLabel(
                          "xPAw6f7A6l",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <ErrorMessage
                    questionId="xPAw6f7A6l"
                    isEditMode={showErrorMessage}
                  >
                    {getQuestionError("xPAw6f7A6l")?.message}
                  </ErrorMessage>
                </OrangeLeft>
                <OrangeRight>
                  <Checkbox
                    id="11"
                    fontSize={15}
                    bold={false}
                    label={
                      getQuestionLabel(
                        "huGMUuBfiS",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={true}
                    checked={getValue("huGMUuBfiS")}
                    onClick={() =>
                      updateAnswer("huGMUuBfiS", !getValue("huGMUuBfiS"))
                    }
                    mode={mode}
                  />
                  <HorizontalLine heightPercent={87} />
                </OrangeRight>
              </OrangeSection>
            </OrangeBox>
          </>
        }
      />
      {isEditMode && (
        <Section
          paddingTop={20}
          left={<></>}
          right={
            <>
              <QuestionContainer>
                <QuestionText isBold>
                  <Highlight>
                    <QuestionLabel
                      isBold
                      questionId="rbHhKIbpMu"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="12"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("rbHhKIbpMu") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "rbHhKIbpMu",
                        getValue("rbHhKIbpMu") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="13"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("rbHhKIbpMu") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "rbHhKIbpMu",
                        getValue("rbHhKIbpMu") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            </>
          }
        />
      )}
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="globe" />
            <GreyBox>
              {/* <strong>Sustainability indicators </strong> measure how the
              environmental or social characteristics promoted by the financial
              product are attained. */}
              {parse(
                getStaticContentText(
                  "xtNMyzfzlk",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [list the sustainable investment objective of this financial
                    product, and describe how the sustainable investments
                    contributed to the sustainable investment objective. For the
                    financial products referred to in Article 5, first paragraph,
                    of Regulation (EU) 2020/852, in respect of sustainable
                    investments with environmental objectives, indicate to which
                    environmental objectives set out in Article 9 of Regulation
                    (EU) 2020/852 to the investment underlying the financial
                    product contributed to. For the financial products referred to
                    in Article 9(3) of Regulation (EU) 2019/2088, indicate how the
                    objective of a reduction in carbon emissions was aligned with
                    the Paris Agreement] */}
                    {parse(
                      getStaticContentText(
                        "pxPsZwGbJm",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    questionId="B8C3RU7qZM"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("B8C3RU7qZM")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("B8C3RU7qZM")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["B8C3RU7qZM"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("B8C3RU7qZM")}
                  />
                </QuestionText>
                <QuillEditor
                  id="B8C3RU7qZM"
                  disabled={!isEditMode}
                  innerHTML={getValue("B8C3RU7qZM")}
                  onChange={(ans) => updateAnswer("B8C3RU7qZM", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={10}
              >
                <QuestionText fontSize={14} isItalic isBold>
                  <GreyDot />
                  <QuestionLabel
                    isBold
                    questionId="s9jIwZUCFP"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("s9jIwZUCFP")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("s9jIwZUCFP")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["s9jIwZUCFP"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("s9jIwZUCFP")}
                  />
                </QuestionText>
                <QuillEditor
                  id="s9jIwZUCFP"
                  disabled={!isEditMode}
                  innerHTML={getValue("s9jIwZUCFP")}
                  onChange={(ans) => updateAnswer("s9jIwZUCFP", ans)}
                />
              </QuestionContainer>
              {isEditMode && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isBold>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        questionId="MB3c1jSrO7"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("MB3c1jSrO7")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("MB3c1jSrO7")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["MB3c1jSrO7"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("MB3c1jSrO7")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="14"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("MB3c1jSrO7") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "MB3c1jSrO7",
                          getValue("MB3c1jSrO7") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="15"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("MB3c1jSrO7") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "MB3c1jSrO7",
                          getValue("MB3c1jSrO7") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("MB3c1jSrO7") === "Yes" && (
                <>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include for financial products where at least one
                        previous periodic report was provided] */}
                        {parse(
                          getStaticContentText(
                            "EeLu8FGX7b",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        isBold
                        questionId="M1LKXsVqvD"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("M1LKXsVqvD")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("M1LKXsVqvD")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["M1LKXsVqvD"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("M1LKXsVqvD")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="M1LKXsVqvD"
                      disabled={!isEditMode}
                      innerHTML={getValue("M1LKXsVqvD")}
                      onChange={(ans) => updateAnswer("M1LKXsVqvD", ans)}
                    />
                  </QuestionContainer>
                </>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <GreyBox>
              {/* <strong>Principal adverse impacts </strong> are the most
              significant negative impacts of investment decisions on
              sustainability factors relating to environmental, social and
              employee matters, respect for human rights, anti-corruption and
              anti-bribery matters. */}
              {parse(
                getStaticContentText(
                  "uvQTK8TpMN",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={10}>
                <QuestionText fontSize={14} isItalic isBold>
                  <GreyDot />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include where the financial product includes sustainable
                            investments] */}
                    {parse(
                      getStaticContentText(
                        "nf1gwAWzYH",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isItalic
                    isBold
                    questionId="Gu2CMAXrdK"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("Gu2CMAXrdK")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("Gu2CMAXrdK")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["Gu2CMAXrdK"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("Gu2CMAXrdK")}
                  />
                </QuestionText>
                <QuillEditor
                  id="Gu2CMAXrdK"
                  disabled={!isEditMode}
                  innerHTML={getValue("Gu2CMAXrdK")}
                  onChange={(ans) => updateAnswer("Gu2CMAXrdK", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={20}
              >
                <QuestionText fontSize={14} isItalic isBold>
                  <QuestionLabel
                    fontSize={14}
                    isItalic
                    isBold
                    questionId="0t1KB5pNTx"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("0t1KB5pNTx")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("0t1KB5pNTx")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["0t1KB5pNTx"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("0t1KB5pNTx")}
                  />
                </QuestionText>
                <QuillEditor
                  id="0t1KB5pNTx"
                  disabled={!isEditMode}
                  innerHTML={getValue("0t1KB5pNTx")}
                  onChange={(ans) => updateAnswer("0t1KB5pNTx", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={20}
              >
                <QuestionText fontSize={14} isItalic isBold>
                  <QuestionLabel
                    fontSize={14}
                    isItalic
                    isBold
                    questionId="F2CW1Yj7GE"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("F2CW1Yj7GE")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("F2CW1Yj7GE")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["F2CW1Yj7GE"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("F2CW1Yj7GE")}
                  />
                </QuestionText>
                <QuillEditor
                  id="F2CW1Yj7GE"
                  disabled={!isEditMode}
                  innerHTML={getValue("F2CW1Yj7GE")}
                  onChange={(ans) => updateAnswer("F2CW1Yj7GE", ans)}
                />
              </QuestionContainer>
              {isEditMode && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isBold>
                    <Highlight>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        questionId="NbkwBfkgij"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("NbkwBfkgij")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("NbkwBfkgij")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["NbkwBfkgij"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("NbkwBfkgij")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="16"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("NbkwBfkgij") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "NbkwBfkgij",
                          getValue("NbkwBfkgij") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="17"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("NbkwBfkgij") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "NbkwBfkgij",
                          getValue("NbkwBfkgij") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      {getValue("NbkwBfkgij") === "Yes" && (
        <Section
          paddingTop={20}
          left={
            <>
              <MainIcon name="factory" />
            </>
          }
          right={
            <>
              <QuestionContainer>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include section if the financial product considered
                    principal adverse impacts on sustainability factors] */}
                    {parse(
                      getStaticContentText(
                        "8al4fdM1HZ",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isItalic
                    isBold
                    questionId="XNJFFC11Is"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("XNJFFC11Is")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("XNJFFC11Is")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["XNJFFC11Is"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("XNJFFC11Is")}
                  />
                </QuestionText>
                <QuillEditor
                  id="XNJFFC11Is"
                  disabled={!isEditMode}
                  innerHTML={getValue("XNJFFC11Is")}
                  onChange={(ans) => updateAnswer("XNJFFC11Is", ans)}
                />
              </QuestionContainer>
            </>
          }
        />
      )}
      {isEditMode && (
        <Section
          paddingTop={20}
          right={
            <>
              <QuestionContainer>
                <QuestionText fontSize={14} isBold>
                  <Highlight>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="pi6YD9M5r0"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("pi6YD9M5r0")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("pi6YD9M5r0")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["pi6YD9M5r0"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("pi6YD9M5r0")}
                  />
                </QuestionText>
                <QuillEditor
                  id="pi6YD9M5r0"
                  disabled={!isEditMode}
                  innerHTML={getValue("pi6YD9M5r0")}
                  onChange={(ans) => updateAnswer("pi6YD9M5r0", ans)}
                />
              </QuestionContainer>
            </>
          }
        />
      )}
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="moneyCoin" />
            <GreyBox>
              {/* The list includes the investments constituting{" "}
              <strong> the greatest proportion of investments</strong> of the
              financial product during the reference period which is */}
              {parse(
                getStaticContentText(
                  "SZzfGUIwPN",
                  staticContent.greyBox,
                  document.language
                )
              )}{" "}
              {getValue("pi6YD9M5r0")
                ? getValue("pi6YD9M5r0").replace(/(<([^>]+)>)/gi, "")
                : ""}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionText isBold>
                <QuestionLabel
                  isBold
                  questionId="pN1lPvqh0w"
                  getQuestionError={getQuestionError}
                  displayQuestions={displayQuestions}
                  language={document.language}
                  showErrorMessage={showErrorMessage}
                />
                <CommentButton
                  hasComments={hasCommentsById("pN1lPvqh0w")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("pN1lPvqh0w")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["pN1lPvqh0w"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("pN1lPvqh0w")}
                />
              </QuestionText>
              <TableInput
                isEditMode={isEditMode}
                onChange={(data) => updateAnswer("pN1lPvqh0w", data)}
                data={getValue("pN1lPvqh0w")}
                headerData={headerData}
                countryList={getCountryListArray(
                  commonContent.countryList,
                  document.language
                )}
              />
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={40}
        left={
          <>
            <MainIcon name="leafBW" />
            <GreyBox>
              {/* <strong>Asset allocation</strong> describes the share of
              investments in specific assets. */}
              {parse(
                getStaticContentText(
                  "r0KIGKDuGN",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <QuestionLabel
                    isBold
                    questionId="qsMSNUR2ad"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("qsMSNUR2ad")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("qsMSNUR2ad")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["qsMSNUR2ad"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("qsMSNUR2ad")}
                  />
                </QuestionText>
                <QuillEditor
                  id="qsMSNUR2ad"
                  disabled={!isEditMode}
                  innerHTML={getValue("qsMSNUR2ad")}
                  onChange={(ans) => updateAnswer("qsMSNUR2ad", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={10}
              >
                <QuestionContainer paddingLeft={0}>
                  <QuestionText isBold>
                    <GreyDot />
                    <QuestionLabel
                      isBold
                      questionId="mCwfCOoj85"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("mCwfCOoj85")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("mCwfCOoj85")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["mCwfCOoj85"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("mCwfCOoj85")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="mCwfCOoj85"
                    disabled={!isEditMode}
                    innerHTML={getValue("mCwfCOoj85")}
                    onChange={(ans) => updateAnswer("mCwfCOoj85", ans)}
                  />
                </QuestionContainer>
                <OrangeBox>
                  <TooltipWrapper>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [Include only the relevant boxes of this sample image and
                      remove irrelevant ones for the financial product] */}
                      {parse(
                        getStaticContentText(
                          "l1EwijYmKm",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                  </TooltipWrapper>
                  <UploadWrapper>
                    <UploadLeftSide>
                      <AssetAllocationGraph
                        document={document}
                        questionId="7uaan5d4c7"
                        updateDoc={updateDoc}
                        isEditMode={isEditMode}
                        commonContent={commonContent}
                        mode={mode}
                        updateAns={updateAnswer}
                        article="Article 9"
                        template="Periodic"
                        documentState={documentState}
                      />
                      {/* <UploadFooterNote>
                        <Text fontSize={12} isItalic>
                          Note: Update the Digrammatic representaion of
                          Sustainable investments here.
                        </Text>
                      </UploadFooterNote> */}
                    </UploadLeftSide>
                    <UploadRightSide>
                      <Text fontSize={12}>
                        {/* <strong>#1 Sustainable </strong>
                        covers sustainable investments with environmental or
                        social objectives. */}
                        {parse(
                          getStaticContentText(
                            "qXJZrfUqxX",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                      </Text>
                      <Text fontSize={12}>
                        {/* <strong>#2 Not sustainable </strong>
                        includes investments which do not qualify as sustainable
                        investments. */}
                        {parse(
                          getStaticContentText(
                            "PMG9Fq9s2Y",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                      </Text>
                    </UploadRightSide>
                  </UploadWrapper>
                  {/* Asset allocation changes commented */}
                  {/* <UploadFooterNote>
                    <Text fontSize={12}> */}
                  {/* <strong>#1 Sustainable </strong>
                        covers sustainable investments with environmental or
                        social objectives. */}
                  {/* {parse(
                        getStaticContentText(
                          "qXJZrfUqxX",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                    </Text>
                  </UploadFooterNote>
                  <UploadFooterNote>
                    <Text fontSize={12}> */}
                  {/* <strong>#2 Not sustainable </strong>
                        includes investments which do not qualify as sustainable
                        investments. */}
                  {/* {parse(
                        getStaticContentText(
                          "PMG9Fq9s2Y",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                    </Text>
                  </UploadFooterNote> */}
                  {/* Asset allocation changes commented */}
                </OrangeBox>
                <QuestionContainer paddingTop={20} paddingLeft={0}>
                  <QuestionText isBold>
                    <GreyDot />
                    <QuestionLabel
                      isBold
                      questionId="wKwEFlOj1o"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <InfoToolTip disabled={!showInfoIcon}>
                      {parse(
                        getStaticContentText(
                          "M2HDLxymsP",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <CommentButton
                      hasComments={hasCommentsById("wKwEFlOj1o")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("wKwEFlOj1o")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["wKwEFlOj1o"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("wKwEFlOj1o")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="wKwEFlOj1o"
                    disabled={!isEditMode}
                    innerHTML={getValue("wKwEFlOj1o")}
                    onChange={(ans) => updateAnswer("wKwEFlOj1o", ans)}
                  />
                </QuestionContainer>
              </QuestionContainer>
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("mz4rGDlbwf") === "Yes" && (
              <>
                <GreyBox>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include note for the financial products referred to in
                    Article 5 , first paragraph, of Regulation (EU) 2020/852.] */}
                    {parse(
                      getStaticContentText(
                        "aaV8EEHIn6",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <div style={{ paddingBottom: "10px" }}>
                    {parse(
                      getStaticContentText(
                        "tTYOvVgc6O",
                        staticContent.greyBox,
                        document.language
                      )
                    )}
                  </div>
                  <div style={{ paddingBottom: "10px" }}>
                    {/* <strong>Enabling activities </strong>directly enable other
                    activities to make a substantial contribution to an
                    environmental objective */}
                    {parse(
                      getStaticContentText(
                        "SrI4WIPuTU",
                        staticContent.greyBox,
                        document.language
                      )
                    )}
                  </div>
                  <div>
                    {/* <strong>
                      Transitional activities are economic activities{" "}
                    </strong>
                    for which low-carbon alternatives are not yet available and
                    that have greenhouse gas emission levels corresponding to
                    the best performance. */}
                    {parse(
                      getStaticContentText(
                        "ZXHBZtYdmI",
                        staticContent.greyBox,
                        document.language
                      )
                    )}
                  </div>
                </GreyBox>
                <GreyBox>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include note only for the financial products referred to in
                    Article 5, first paragraph, of Regulation (EU) 2020/852] */}
                    {parse(
                      getStaticContentText(
                        "6v0ZqYvfjY",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  {/* Taxonomy-aligned activities are expressed as a share of:
                  <ul>
                    <li>
                      <div>-</div>
                      <span>turnover</span> reflecting the share of revenue from
                      green activities of investee companies
                    </li>
                    <li>
                      <div>-</div>
                      <span>capital expenditure</span>
                      (CapEx) showing the green investments made by investee
                      companies, e.g. for a transition to a green economy.
                    </li>
                    <li>
                      <div>-</div>
                      <span>operational expenditure</span>
                      (OpEx) reflecting green operational activities of investee
                      companies.
                    </li>
                  </ul> */}
                  {parse(
                    getStaticContentText(
                      "XIQFAa1AOD",
                      staticContent.greyBox,
                      document.language
                    )
                  )}
                </GreyBox>
              </>
            )}
          </>
        }
        right={
          <>
            <QuestionContainer>
              {isEditMode && (
                <QuestionContainer paddingLeft={0}>
                  <QuestionText isBold fontSize={14}>
                    <Highlight>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        questionId="mz4rGDlbwf"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("mz4rGDlbwf")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("mz4rGDlbwf")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["mz4rGDlbwf"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("mz4rGDlbwf")}
                    />
                  </QuestionText>
                  <FlexRow paddingTop={5}>
                    <Checkbox
                      id="18"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("mz4rGDlbwf") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "mz4rGDlbwf",
                          getValue("mz4rGDlbwf") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="19"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("mz4rGDlbwf") === "No"}
                      onClick={() => {
                        // clear 'Taxonomy-alignment of investments including sovereign bonds*' chart values
                        clearTaxnomyIncludingChartData();
                        // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' chart values
                        clearTaxnomyExcludingChartData();
                        updateAnswer(
                          "mz4rGDlbwf",
                          getValue("mz4rGDlbwf") === "No" ? "" : "No"
                        );
                      }}
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("mz4rGDlbwf") === "Yes" && (
                <QuestionContainer
                  paddingLeft={10}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionContainer paddingLeft={0}>
                    <QuestionText fontSize={14} isBold>
                      <SubIcon name="book" />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include section for the financial products referred to in
                        Article 5, first paragraph, of Regulation (EU) 2020/852
                        and include information in accordance with Article 59 of
                        this Regulation] */}
                        {parse(
                          getStaticContentText(
                            "dt1lZS4MNY",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        questionId="4ArO8VPpYJ"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("4ArO8VPpYJ")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("4ArO8VPpYJ")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["4ArO8VPpYJ"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("4ArO8VPpYJ")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="4ArO8VPpYJ"
                      disabled={!isEditMode}
                      innerHTML={getValue("4ArO8VPpYJ")}
                      onChange={(ans) => updateAnswer("4ArO8VPpYJ", ans)}
                    />
                  </QuestionContainer>
                  {/* Condition needs to removed in next iteration */}
                  {isNewVersionDocument() && (
                    <QuestionContainer
                      paddingLeft={0}
                      paddingTop={subQuestionTopPadding}
                    >
                      <AvoidBreakInside>
                        <QuestionText fontSize={14} isItalic isBold>
                          <GreyDot />
                          <QuestionLabel
                            isBold
                            isItalic
                            fontSize={14}
                            questionId="jPjVV4rSN1"
                            getQuestionError={getQuestionError}
                            displayQuestions={displayQuestions}
                            language={document.language}
                            showErrorMessage={showErrorMessage}
                          />
                          <CommentButton
                            hasComments={hasCommentsById("jPjVV4rSN1")}
                            disabled={!showCommentButton}
                            onClick={() => onComment("jPjVV4rSN1")}
                          />
                          <PreviousAnswers
                            disabled={!showPreviousAns}
                            hasAnswers={
                              displayQuestions["jPjVV4rSN1"].previousAnswers
                            }
                            onClick={() => onPreviousAnswersClick("jPjVV4rSN1")}
                          />
                        </QuestionText>
                        <FlexColumn paddingTop={5}>
                          <FlexRow align="flex-start">
                            <Checkbox
                              id="20"
                              label={getStaticContentText(
                                "C7uLFog7hl",
                                staticContent.questionAnswerOption,
                                document.language
                              )}
                              marginLeft={60}
                              disabled={!isEditMode}
                              checked={getValue("jPjVV4rSN1") === "Yes"}
                              onClick={() => {
                                // clear 'Taxonomy-alignment of investments including sovereign bonds*' chart values
                                clearTaxnomyIncludingChartData();
                                // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' chart values
                                clearTaxnomyExcludingChartData();
                                updateAnswer(
                                  "jPjVV4rSN1",
                                  getValue("jPjVV4rSN1") === "Yes" ? "" : "Yes"
                                );
                              }}
                            />
                            &nbsp;&nbsp;
                            <InfoToolTip disabled={!showInfoIcon}>
                              {parse(
                                getStaticContentText(
                                  "4S93MYgcsD",
                                  staticContent.infoTooltip,
                                  document.language
                                )
                              )}

                              {/* [specify below, and details in the graphs of the
                              box] */}
                            </InfoToolTip>
                          </FlexRow>
                          <FlexRow>
                            <Checkbox
                              id="21"
                              label={
                                getQuestionLabel(
                                  "I1R4yEDnJu",
                                  displayQuestions,
                                  Language?.[document.language]
                                ) as string
                              }
                              marginLeft={120}
                              disabled={
                                !isEditMode || getValue("jPjVV4rSN1") !== "Yes"
                              }
                              checked={getValue("I1R4yEDnJu")}
                              onClick={() =>
                                updateAnswer(
                                  "I1R4yEDnJu",
                                  !getValue("I1R4yEDnJu")
                                )
                              }
                              mode={mode}
                            />
                            <Checkbox
                              id="22"
                              label={
                                getQuestionLabel(
                                  "34temHiDul",
                                  displayQuestions,
                                  Language?.[document.language]
                                ) as string
                              }
                              marginLeft={60}
                              disabled={
                                !isEditMode || getValue("jPjVV4rSN1") !== "Yes"
                              }
                              checked={getValue("34temHiDul")}
                              onClick={() =>
                                updateAnswer(
                                  "34temHiDul",
                                  !getValue("34temHiDul")
                                )
                              }
                              mode={mode}
                            />
                          </FlexRow>
                          <Checkbox
                            id="23"
                            label={getStaticContentText(
                              "bG9v3QJaqt",
                              staticContent.questionAnswerOption,
                              document.language
                            )}
                            marginLeft={60}
                            disabled={!isEditMode}
                            checked={getValue("jPjVV4rSN1") === "No"}
                            onClick={() => {
                              // clear 'Taxonomy-alignment of investments including sovereign bonds*' chart values
                              clearTaxnomyIncludingChartData();
                              // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' chart values
                              clearTaxnomyExcludingChartData();
                              updateAnswer(
                                "jPjVV4rSN1",
                                getValue("jPjVV4rSN1") === "No" ? "" : "No"
                              );
                            }}
                          />
                        </FlexColumn>
                      </AvoidBreakInside>
                    </QuestionContainer>
                  )}
                  <Note className="no-print">
                    {parse(
                      getStaticContentText(
                        "RvgGOg6xgj",
                        staticContent.staticText,
                        document.language
                      )
                    )}
                    {/* Fossil gas and/or nuclear related activities will only
                        comply with the EU Taxonomy where they contribute to
                        limiting climate change (“climate change mitigation”)
                        and do no significant harm to any EU Taxonomy objective
                        - see explanatory note in the left hand margin. The full
                        criteria for fossil gas and nuclear energy economic
                        activities that comply with the EU Taxonomy are laid
                        down in Commission Delegated Regulation (EU) 2022/1214. */}
                  </Note>
                  <OrangeBox
                    padding={spacingIncrement(10)}
                    margin={`${spacingIncrement(10)} 0 ${spacingIncrement(
                      20
                    )} 0`}
                  >
                    <Text>
                      {/* The graphs below show in green the percentage of
                      investments that were aligned with the EU Taxonomy. As
                      there is no appropriate methodology to determine the
                      taxonomy-alignment of sovereign bonds*, the first graph
                      shows the Taxonomy alignment in relation to all the
                      investments of the financial product including sovereign
                      bonds, while the second graph shows the Taxonomy alignment
                      only in relation to the investments of the financial
                      product other than sovereign bonds. */}
                      <Label isBold isItalic fontSize={12}>
                        {parse(
                          getStaticContentText(
                            "XC8gp9TfhD",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                      </Label>
                    </Text>
                    <Flex>
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [Include information on Taxonomy aligned fossil gas and
                        nuclear energy and the explanatory text in the left hand
                        margin on the previous page only if the financial
                        product invested in fossil gas and/or nuclear energy
                        Taxonomy-aligned economic activities during the
                        reference period] */}
                        {parse(
                          getStaticContentText(
                            "bDj2RHPT7U",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                    </Flex>
                    <GraphContainer>
                      <GraphSubContainer>
                        <FlexColumn>
                          <Text fontSize={12} marginLeft="35px">
                            {parse(
                              getStaticContentText(
                                "OGTiLukArU",
                                staticContent.staticText,
                                document.language
                              )
                            )}
                            {/* 1. Taxonomy-alignment of investments{" "}
                              <Bold>including sovereign bonds*</Bold> */}
                          </Text>
                          <FlexColumn paddingTop={10}>
                            <Flex>
                              <BarChart
                                labels={barCharLabels}
                                dataValue={[
                                  {
                                    label: getStaticContentText(
                                      "chxQtWvzke",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("lF90AbRUA1"),
                                      getValue("NpheFtm86D"),
                                      getValue("ylHOgkmLpx"),
                                    ],
                                    color: "#809980",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "Zffi9KIfT5",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("XQ7GlOjhpm"),
                                      getValue("2VIxL6JKVP"),
                                      getValue("eUJfUOJzeK"),
                                    ],
                                    color: "#4d704d",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "RXtpexllNu",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("VXP3xP983z"),
                                      getValue("QDcqPBs1kK"),
                                      getValue("0as0eZZpFc"),
                                    ],
                                    color: "#0a3300",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "xgVxV8TR5W",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("p61HrjyPr5"),
                                      getValue("uIqG7QbHHX"),
                                      getValue("SGPLaP0VWQ"),
                                    ],
                                    color: "#d9d9d9",
                                  },
                                ]}
                                stepSize={50}
                              />
                            </Flex>
                            <Flex>
                              {isEditMode && (
                                <Tabular
                                  id="left-table"
                                  headers={[
                                    "",
                                    getStaticContentText(
                                      "TFs1iWMg9P",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    getStaticContentText(
                                      "UeefNSjL5R",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    getStaticContentText(
                                      "jr5sewetYY",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                  ]}
                                  data={[
                                    [
                                      getStaticContentText(
                                        "chxQtWvzke",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "lF90AbRUA1",
                                      "NpheFtm86D",
                                      "ylHOgkmLpx",
                                    ],
                                    [
                                      getStaticContentText(
                                        "Zffi9KIfT5",
                                        staticContent.staticText,
                                        document.language
                                      ),

                                      "XQ7GlOjhpm",
                                      "2VIxL6JKVP",
                                      "eUJfUOJzeK",
                                    ],
                                    [
                                      getStaticContentText(
                                        "RXtpexllNu",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "VXP3xP983z",
                                      "QDcqPBs1kK",
                                      "0as0eZZpFc",
                                    ],
                                    [
                                      getStaticContentText(
                                        "xgVxV8TR5W",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "p61HrjyPr5",
                                      "uIqG7QbHHX",
                                      "SGPLaP0VWQ",
                                    ],
                                  ]}
                                  disabled={getValue("jPjVV4rSN1") === "No"}
                                  disabledFieldIndexes={[0, 1]}
                                ></Tabular>
                              )}
                            </Flex>
                            <Flex>
                              <FlexColumn>
                                <FlexRow align="flex-start">
                                  <LegendSquare color="#809980" />
                                  <Flex flex={1}>
                                    <Text fontSize={10}>
                                      {parse(
                                        getStaticContentText(
                                          "chxQtWvzke",
                                          staticContent.staticText,
                                          document.language
                                        )
                                      )}
                                    </Text>
                                  </Flex>
                                </FlexRow>
                                <FlexRow align="flex-start">
                                  <LegendSquare color="#4d704d" />
                                  <Flex flex={1}>
                                    <Text fontSize={10}>
                                      {parse(
                                        getStaticContentText(
                                          "Zffi9KIfT5",
                                          staticContent.staticText,
                                          document.language
                                        )
                                      )}
                                    </Text>
                                  </Flex>
                                </FlexRow>
                                <FlexRow align="flex-start">
                                  <LegendSquare color="#0a3300" />
                                  <Flex flex={1}>
                                    <Text fontSize={10}>
                                      {parse(
                                        getStaticContentText(
                                          "RXtpexllNu",
                                          staticContent.staticText,
                                          document.language
                                        )
                                      )}
                                    </Text>
                                  </Flex>
                                </FlexRow>
                                <FlexRow align="flex-start">
                                  <LegendSquare color="#d9d9d9" />
                                  <Flex flex={1}>
                                    <Text fontSize={10}>
                                      {parse(
                                        getStaticContentText(
                                          "xgVxV8TR5W",
                                          staticContent.staticText,
                                          document.language
                                        )
                                      )}
                                    </Text>
                                  </Flex>
                                </FlexRow>
                              </FlexColumn>
                            </Flex>
                          </FlexColumn>
                        </FlexColumn>
                      </GraphSubContainer>
                      <GraphSubContainer>
                        <FlexColumn>
                          <Text fontSize={12} marginLeft="35px">
                            {/* 1. Taxonomy-alignment of investments{" "}
                              <Bold>excluding sovereign bonds*</Bold> */}
                            {parse(
                              getStaticContentText(
                                "04JWzfL0ZU",
                                staticContent.staticText,
                                document.language
                              )
                            )}
                          </Text>
                          <FlexColumn paddingTop={10}>
                            <Flex>
                              <BarChart
                                labels={barCharLabels}
                                dataValue={[
                                  {
                                    label: getStaticContentText(
                                      "chxQtWvzke",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("cLMXNErpLx"),
                                      getValue("Vsz4WtWWOq"),
                                      getValue("eCFaoWSGWu"),
                                    ],
                                    color: "#809980",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "Zffi9KIfT5",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("PxtylL4phn"),
                                      getValue("ZqcMr44CWg"),
                                      getValue("nv0uz7vMJ3"),
                                    ],
                                    color: "#4d704d",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "RXtpexllNu",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("Hqu7pAYYbT"),
                                      getValue("vzqEWFs9ja"),
                                      getValue("Xt8rx74lrB"),
                                    ],
                                    color: "#0a3300",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "xgVxV8TR5W",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("B3tQAj2rDN"),
                                      getValue("jXvToL4j1p"),
                                      getValue("L3Kk6y1z4m"),
                                    ],
                                    color: "#d9d9d9",
                                  },
                                ]}
                                stepSize={50}
                              />
                            </Flex>

                            <Flex>
                              {isEditMode && (
                                <Tabular
                                  id="right-table"
                                  headers={[
                                    "",

                                    getStaticContentText(
                                      "TFs1iWMg9P",
                                      staticContent.staticText,
                                      document.language
                                    ),

                                    getStaticContentText(
                                      "UeefNSjL5R",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    getStaticContentText(
                                      "jr5sewetYY",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                  ]}
                                  data={[
                                    [
                                      getStaticContentText(
                                        "chxQtWvzke",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "cLMXNErpLx",
                                      "Vsz4WtWWOq",
                                      "eCFaoWSGWu",
                                    ],
                                    [
                                      getStaticContentText(
                                        "Zffi9KIfT5",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "PxtylL4phn",
                                      "ZqcMr44CWg",
                                      "nv0uz7vMJ3",
                                    ],
                                    [
                                      getStaticContentText(
                                        "RXtpexllNu",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "Hqu7pAYYbT",
                                      "vzqEWFs9ja",
                                      "Xt8rx74lrB",
                                    ],
                                    [
                                      getStaticContentText(
                                        "xgVxV8TR5W",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "B3tQAj2rDN",
                                      "jXvToL4j1p",
                                      "L3Kk6y1z4m",
                                    ],
                                  ]}
                                  disabled={getValue("jPjVV4rSN1") === "No"}
                                  disabledFieldIndexes={[0, 1]}
                                ></Tabular>
                              )}
                            </Flex>

                            <Flex>
                              <FlexColumn>
                                <FlexRow align="flex-start">
                                  <LegendSquare color="#809980" />
                                  <Flex flex={1}>
                                    <Text fontSize={10}>
                                      {/* Taxonomy-aligned:Fossil gas */}
                                      {parse(
                                        getStaticContentText(
                                          "chxQtWvzke",
                                          staticContent.staticText,
                                          document.language
                                        )
                                      )}
                                    </Text>
                                  </Flex>
                                </FlexRow>
                                <FlexRow align="flex-start">
                                  <LegendSquare color="#4d704d" />
                                  <Flex flex={1}>
                                    <Text fontSize={10}>
                                      {/* Taxonomy-aligned:Nuclear */}
                                      {parse(
                                        getStaticContentText(
                                          "Zffi9KIfT5",
                                          staticContent.staticText,
                                          document.language
                                        )
                                      )}
                                    </Text>
                                  </Flex>
                                </FlexRow>
                                <FlexRow align="flex-start">
                                  <LegendSquare color="#0a3300" />
                                  <Flex flex={1}>
                                    <Text fontSize={10}>
                                      {/* Taxonomy-aligned (no fossil gas &
                                        nuclear) */}
                                      {parse(
                                        getStaticContentText(
                                          "RXtpexllNu",
                                          staticContent.staticText,
                                          document.language
                                        )
                                      )}
                                    </Text>
                                  </Flex>
                                </FlexRow>
                                <FlexRow align="flex-start">
                                  <LegendSquare color="#d9d9d9" />
                                  <Flex flex={1}>
                                    <Text fontSize={10}>
                                      {parse(
                                        getStaticContentText(
                                          "xgVxV8TR5W",
                                          staticContent.staticText,
                                          document.language
                                        )
                                      )}
                                    </Text>
                                  </Flex>
                                </FlexRow>
                              </FlexColumn>
                            </Flex>
                            <GraphFooter>
                              <Text fontSize={12}>
                                {
                                  getQuestionLabel(
                                    "pBfhwu3hBz",
                                    displayQuestions,
                                    Language?.[document.language]
                                  )?.[0]
                                }
                                &nbsp;
                                <NumberInput
                                  maxLimit={100}
                                  displayStyle={"inline"}
                                  id="pBfhwu3hBz"
                                  value={getValue("pBfhwu3hBz")}
                                  onChange={(val) =>
                                    updateAnswer("pBfhwu3hBz", val)
                                  }
                                />
                                &nbsp;
                                {
                                  getQuestionLabel(
                                    "pBfhwu3hBz",
                                    displayQuestions,
                                    Language?.[document.language]
                                  )?.[1]
                                }
                              </Text>
                            </GraphFooter>
                          </FlexColumn>
                        </FlexColumn>
                      </GraphSubContainer>
                    </GraphContainer>

                    <RegulatoryExternalLinkComponent
                      linkId={REGULATORY_EXTERNAL_LINK_ID}
                      hidden={!isEditMode}
                      document={document}
                      staticContent={commonContent}
                    />

                    <Text>
                      {/* *For the purpose of these graphs, ‘sovereign bonds’
                      consist of all sovereign exposures */}
                      <Label isBold isItalic fontSize={12}>
                        {parse(
                          getStaticContentText(
                            "LYcM0c2NXC",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                      </Label>
                    </Text>
                  </OrangeBox>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={0}
                  >
                    <QuestionText fontSize={14} isBold>
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include a breakdown of the proportions of investments
                        during the reference period] */}
                        {parse(
                          getStaticContentText(
                            "WrR4tt3tiq",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        questionId="teUImd2s0b"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("teUImd2s0b")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("teUImd2s0b")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["teUImd2s0b"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("teUImd2s0b")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="teUImd2s0b"
                      disabled={!isEditMode}
                      innerHTML={getValue("teUImd2s0b")}
                      onChange={(ans) => updateAnswer("teUImd2s0b", ans)}
                    />
                  </QuestionContainer>
                </QuestionContainer>
              )}
              {isEditMode && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText isBold fontSize={14}>
                    <Highlight>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        questionId="G317XHxxqG"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("G317XHxxqG")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("G317XHxxqG")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["G317XHxxqG"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("G317XHxxqG")}
                    />
                  </QuestionText>
                  <FlexRow paddingTop={5}>
                    <Checkbox
                      id="24"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("G317XHxxqG") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "G317XHxxqG",
                          getValue("G317XHxxqG") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="25"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("G317XHxxqG") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "G317XHxxqG",
                          getValue("G317XHxxqG") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("G317XHxxqG") === "Yes" && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText fontSize={14} isBold>
                    <GreyDot />
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [include where at least one previous periodic report was
                      provided] */}
                      {parse(
                        getStaticContentText(
                          "jcSdiEaxDv",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="27uIRiohQW"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("27uIRiohQW")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("27uIRiohQW")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["27uIRiohQW"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("27uIRiohQW")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="27uIRiohQW"
                    disabled={!isEditMode}
                    innerHTML={getValue("27uIRiohQW")}
                    onChange={(ans) => updateAnswer("27uIRiohQW", ans)}
                  />
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("hNaFbEsrTP") === "Yes" && (
              <GreyBox>
                <InfoToolTip disabled={!showInfoIcon}>
                  {/* [include note for the financial products referred to in
                  Article 5, first paragraph, of Regulation (EU) 2020/852 that
                  invest in environmental economic activities that are not
                  environmentally sustainable economic activities] */}
                  {parse(
                    getStaticContentText(
                      "WpM3EXZVC2",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                </InfoToolTip>
                <RelativeContainer>
                  <Block>
                    <Image
                      name="noBook"
                      height={40}
                      width={40}
                      style={{ float: "left", paddingRight: 10 }}
                    />
                    {/* are sustainable investments with an environmental objective
                    that
                    <strong> do not take into account the criteria </strong>
                    for environmentally sustainable economic activities under
                    the EU Taxonomy. */}
                    {parse(
                      getStaticContentText(
                        "QGXSalYyXv",
                        staticContent.greyBox,
                        document.language
                      )
                    )}
                  </Block>
                </RelativeContainer>
              </GreyBox>
            )}
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            {isEditMode && (
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold fontSize={14}>
                  <Highlight>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="hNaFbEsrTP"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("hNaFbEsrTP")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("hNaFbEsrTP")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["hNaFbEsrTP"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("hNaFbEsrTP")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="26"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("hNaFbEsrTP") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "hNaFbEsrTP",
                        getValue("hNaFbEsrTP") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="27"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("hNaFbEsrTP") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "hNaFbEsrTP",
                        getValue("hNaFbEsrTP") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("hNaFbEsrTP") === "Yes" && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <SubIcon name="noBook" />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include only for the financial products referred to in
                    Article 5, first paragraph, of Regulation (EU) 2020/852
                    where the financial product included investments with an
                    environmental objective that invested in economic activities
                    that are not environmentally sustainable economic activities
                    and explain why the financial product invested in economic
                    activities that were not taxonomy-aligned] */}
                    {parse(
                      getStaticContentText(
                        "40L5UFjGl9",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isBold
                    questionId="cLAPija0ll"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("cLAPija0ll")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("cLAPija0ll")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["cLAPija0ll"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("cLAPija0ll")}
                  />
                </QuestionText>
                <QuillEditor
                  id="cLAPija0ll"
                  disabled={!isEditMode}
                  innerHTML={getValue("cLAPija0ll")}
                  onChange={(ans) => updateAnswer("cLAPija0ll", ans)}
                />
              </QuestionContainer>
            )}
            {isEditMode && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <Highlight>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="jOwOjlrHjy"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("jOwOjlrHjy")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("jOwOjlrHjy")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["jOwOjlrHjy"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("jOwOjlrHjy")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="28"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("jOwOjlrHjy") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "jOwOjlrHjy",
                        getValue("jOwOjlrHjy") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="29"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("jOwOjlrHjy") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "jOwOjlrHjy",
                        getValue("jOwOjlrHjy") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("jOwOjlrHjy") === "Yes" && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <SubIcon name="users" />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include only where the financial product includes
                    sustainable investments with a social objective] */}
                    {parse(
                      getStaticContentText(
                        "gZPcCURfGI",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isBold
                    questionId="RMh4KGbWP1"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("RMh4KGbWP1")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("RMh4KGbWP1")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["RMh4KGbWP1"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("RMh4KGbWP1")}
                  />
                </QuestionText>
                <QuillEditor
                  id="RMh4KGbWP1"
                  disabled={!isEditMode}
                  innerHTML={getValue("RMh4KGbWP1")}
                  onChange={(ans) => updateAnswer("RMh4KGbWP1", ans)}
                />
              </QuestionContainer>
            )}
            <QuestionContainer
              paddingLeft={0}
              paddingTop={subQuestionTopPadding}
            >
              <QuestionText isBold fontSize={14}>
                <SubIcon name="noMoney" />
                <QuestionLabel
                  fontSize={14}
                  isBold
                  questionId="66mRaN4Aft"
                  getQuestionError={getQuestionError}
                  displayQuestions={displayQuestions}
                  language={document.language}
                  showErrorMessage={showErrorMessage}
                />
                <CommentButton
                  hasComments={hasCommentsById("66mRaN4Aft")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("66mRaN4Aft")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["66mRaN4Aft"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("66mRaN4Aft")}
                />
              </QuestionText>
              <QuillEditor
                id="66mRaN4Aft"
                disabled={!isEditMode}
                innerHTML={getValue("66mRaN4Aft")}
                onChange={(ans) => updateAnswer("66mRaN4Aft", ans)}
              />
            </QuestionContainer>
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="humanArrow" size={65} />
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [list the actions taken within the period covered by the
                    periodic report to attain the sustainable investment objective
                    of the financial product, including shareholder engagement as
                    referred to in Article 3g of Directive 2007/36/EC and any
                    other engagement relating to the sustainable investment
                    objective] */}
                    {parse(
                      getStaticContentText(
                        "af6v9zhGok",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    questionId="FH0P2QWqNX"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("FH0P2QWqNX")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("FH0P2QWqNX")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["FH0P2QWqNX"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("FH0P2QWqNX")}
                  />
                </QuestionText>
                <QuillEditor
                  id="FH0P2QWqNX"
                  disabled={!isEditMode}
                  innerHTML={getValue("FH0P2QWqNX")}
                  onChange={(ans) => updateAnswer("FH0P2QWqNX", ans)}
                />
              </QuestionContainer>
              {isEditMode && getValue("mz4rGDlbwf") === "Yes" && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText isBold>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        questionId="BLZynq1hjx"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <CommentButton
                      hasComments={hasCommentsById("BLZynq1hjx")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("BLZynq1hjx")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["BLZynq1hjx"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("BLZynq1hjx")}
                    />
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  </QuestionText>
                  <FlexRow paddingTop={5}>
                    <Checkbox
                      id="30"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("BLZynq1hjx") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "BLZynq1hjx",
                          getValue("BLZynq1hjx") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="31"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("BLZynq1hjx") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "BLZynq1hjx",
                          getValue("BLZynq1hjx") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("BLZynq1hjx") === "Yes" &&
              getValue("mz4rGDlbwf") === "Yes" && (
                <>
                  <MainIcon name="graphs" />
                  <GreyBox>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [include note for the financial products referred to in
                      Article 9(1) of Regulation (EU) 2019/2088] */}
                      {parse(
                        getStaticContentText(
                          "fd5cGYokZh",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    {/* <strong>Reference benchmarks </strong>
                    are indexes to measure whether the financial product attains
                    the sustainable objective. */}
                    {parse(
                      getStaticContentText(
                        "QqGAU3SBHd",
                        staticContent.greyBox,
                        document.language
                      )
                    )}
                  </GreyBox>
                </>
              )}
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            {getValue("BLZynq1hjx") === "Yes" &&
              getValue("mz4rGDlbwf") === "Yes" && (
                <>
                  <QuestionContainer paddingLeft={0}>
                    <QuestionText isBold>
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include section only for the financial products referred
                        to in Article 9(1) of Regulation (EU) 2019/2088 and
                        indicate where the methodology used for the calculation of
                        the designated index can be found] */}
                        {parse(
                          getStaticContentText(
                            "9Eweli7XJ0",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        isBold
                        questionId="ciTbIQ7zXO"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("ciTbIQ7zXO")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("ciTbIQ7zXO")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["ciTbIQ7zXO"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("ciTbIQ7zXO")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="ciTbIQ7zXO"
                      disabled={!isEditMode}
                      innerHTML={getValue("ciTbIQ7zXO")}
                      onChange={(ans) => updateAnswer("ciTbIQ7zXO", ans)}
                    />
                  </QuestionContainer>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <QuestionLabel
                        fontSize={14}
                        isItalic
                        isBold
                        questionId="FR6lBZBeey"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("FR6lBZBeey")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("FR6lBZBeey")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["FR6lBZBeey"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("FR6lBZBeey")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="FR6lBZBeey"
                      disabled={!isEditMode}
                      innerHTML={getValue("FR6lBZBeey")}
                      onChange={(ans) => updateAnswer("FR6lBZBeey", ans)}
                    />
                  </QuestionContainer>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <QuestionLabel
                        fontSize={14}
                        isItalic
                        isBold
                        questionId="CZUdpEBVyZ"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("CZUdpEBVyZ")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("CZUdpEBVyZ")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["CZUdpEBVyZ"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("CZUdpEBVyZ")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="CZUdpEBVyZ"
                      disabled={!isEditMode}
                      innerHTML={getValue("CZUdpEBVyZ")}
                      onChange={(ans) => updateAnswer("CZUdpEBVyZ", ans)}
                    />
                  </QuestionContainer>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <QuestionLabel
                        fontSize={14}
                        isItalic
                        isBold
                        questionId="tLDiZaaMUN"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />

                      <CommentButton
                        hasComments={hasCommentsById("tLDiZaaMUN")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("tLDiZaaMUN")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["tLDiZaaMUN"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("tLDiZaaMUN")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="tLDiZaaMUN"
                      disabled={!isEditMode}
                      innerHTML={getValue("tLDiZaaMUN")}
                      onChange={(ans) => updateAnswer("tLDiZaaMUN", ans)}
                    />
                  </QuestionContainer>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <QuestionLabel
                        fontSize={14}
                        isItalic
                        isBold
                        questionId="EBCGX0IoaT"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("EBCGX0IoaT")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("EBCGX0IoaT")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["EBCGX0IoaT"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("EBCGX0IoaT")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="EBCGX0IoaT"
                      disabled={!isEditMode}
                      innerHTML={getValue("EBCGX0IoaT")}
                      onChange={(ans) => updateAnswer("EBCGX0IoaT", ans)}
                    />
                  </QuestionContainer>
                </>
              )}
          </QuestionContainer>
        }
      />
    </Container>
  );
};

export default ArticleNinePeriodic;
