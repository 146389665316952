import React from "react";
import { ImageProps } from "../types";

const Graphs: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="138"
      height="135"
      viewBox="0 0 138 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="67.5" cy="67.5" r="67.5" fill="#9D9D9C" />
      <path
        d="M15 66L31.5146 48.0182L53.534 60.5273L96 23"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 77.8182L34.2605 67L54.8403 95L77.4118 77.8182L96 85.4545"
        stroke="#3C3C3B"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="102"
        y1="32"
        x2="102"
        y2="78"
        stroke="#3C3C3B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeDasharray="2 8"
      />
      <path
        d="M115.472 67.188L112.517 66.6893C112.699 65.6742 112.929 64.8579 113.208 64.2403C113.499 63.614 113.891 63.0647 114.385 62.5924C114.878 62.1201 115.517 61.596 116.3 61.0201C116.872 60.6045 117.395 60.2135 117.871 59.8472C118.359 59.472 118.773 59.0516 119.113 58.5861C119.455 58.11 119.687 57.5118 119.808 56.7915C119.932 56.0606 119.906 55.408 119.73 54.8337C119.565 54.2612 119.253 53.789 118.793 53.4172C118.343 53.0472 117.748 52.7997 117.006 52.6745C116.392 52.5708 115.79 52.5838 115.202 52.7132C114.614 52.8427 114.111 53.1119 113.693 53.5208C113.277 53.919 113.002 54.488 112.866 55.2277L109.926 54.7317C110.15 53.5383 110.62 52.5609 111.336 51.7994C112.064 51.0397 112.958 50.5153 114.02 50.2261C115.082 49.9368 116.222 49.895 117.441 50.1006C118.786 50.3276 119.889 50.7643 120.749 51.4106C121.62 52.0587 122.228 52.8803 122.572 53.8753C122.918 54.8596 122.986 55.9715 122.777 57.2108C122.616 58.1641 122.272 59.0102 121.744 59.7491C121.229 60.4792 120.61 61.1428 119.888 61.74C119.167 62.3372 118.413 62.8964 117.628 63.4175C116.954 63.8594 116.46 64.4024 116.144 65.0463C115.828 65.6903 115.604 66.4042 115.472 67.188ZM111.54 71.7045C111.62 71.2279 111.836 70.8504 112.188 70.5721C112.54 70.2937 112.997 70.202 113.558 70.2967C114.13 70.3932 114.537 70.6307 114.778 71.0091C115.019 71.3875 115.1 71.8151 115.019 72.2917C114.942 72.7472 114.728 73.1141 114.376 73.3924C114.024 73.6707 113.562 73.7616 112.99 73.6651C112.429 73.5703 112.027 73.3338 111.786 72.9553C111.545 72.5769 111.463 72.16 111.54 71.7045Z"
        fill="white"
      />
    </svg>
  );
};

export default Graphs;
