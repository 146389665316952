import Image from "../../../components/Image";
import QuillEditor from "../../../components/QuillEditor";
import {
  DocumentRecord,
  DocumentStatusEnum,
  DocumentViewModeEnum,
} from "../../../types/document-details";
import { spacingIncrement } from "../../../utils/common";
import Checkbox from "../CommonComponents/CheckBox";
import CommentButton from "./CommentButton";
import DocDoNotIncludeIcon from "../CommonComponents/DocDoNotIncludeIcon";
import MainIcon from "./MainIcon";
import { NumberInput } from "../CommonComponents/NumberInput";
import Section from "../CommonComponents/Section";
import {
  Container,
  GreyBox,
  Bold,
  ProductInfoContainer,
  SubHeading,
  OrangeBoxHeader,
  OrangeBox,
  OrangeLeft,
  OrangeRight,
  OrangeLeftHeader,
  OrangeRightHeader,
  Circle,
  OrangeSection,
  QuestionText,
  HorizontalLine,
  GreyDot,
  FlexRow,
  UploadWrapper,
  UploadFooterNote,
  Highlight,
  GraphContainer,
  GraphSubContainer,
  Flex,
  FlexColumn,
  RelativeContainer,
  InputCheckBoxContainer,
  CheckBoxLabel,
  Block,
  WaterMarkText,
  WaterMarkWrapper,
  Note,
} from "./styles";
import { QuestionContainer } from "../CommonComponents/QuestionContainer";
import SubIcon from "./SubIcon";
import { useDocumentState } from "../../../hooks/useDocumentState";
import { useCallback, useEffect, useMemo } from "react";
import { updateDocumentState } from "../../../store/globalActions";
import { useContext } from "react";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import UploadLogoButton from "../CommonComponents/UploadLogoButton";
import { Preset } from "../CommentSection";
import { CommentType } from "../../../types/comment";
import {
  DisplayQuestion,
  DocumentCommonContent,
  LabelText,
  Language,
} from "../../../types/document-question";
import {
  getCountryListArray,
  getQuestionLabel,
} from "../DocumentEditor/service";
import TableInput from "../CommonComponents/TableInput/index";
import BarChart from "../CommonComponents/BarChart";
import InfoToolTip from "../../../components/InfoToolTip";
import { TooltipWrapper } from "../../../components/InfoToolTip/styles";
import { DocumentStaticContent } from "../../../types/document-question";
import { getStaticContentText } from "../DocumentEditor/service";
import parse from "html-react-parser";
import {
  AvoidBreakInside,
  Title,
  GraphFooter,
  Text,
  ProductInfo,
} from "../CommonComponents/common.styles";
import Tabular from "../CommonComponents/Tabular";
import { HeaderData } from "../CommonComponents/TableInput/index";
import { LegendSquare } from "../ArticleEightPreContractual/styles";
import PreviousAnswers from "../CommonComponents/PreviousAnswers";
import {
  AnnexText,
  Label,
  ErrorMessage,
  QuestionLabel,
} from "../CommonComponents/Typography";
import { RegulatoryExternalLinkComponent } from "../CommonComponents/RegulatoryExternalLinkComponent";
import { REGULATORY_EXTERNAL_LINK_ID } from "../../../lib/constants";
import AssetAllocationGraph from "../../../components/AssetAllocationGraph";
// import InlineNumberInput from "../../../components/InlineNumberInput";

type Props = {
  document: DocumentRecord;
  mode?: DocumentViewModeEnum;
  updateDoc: (doc: DocumentRecord | undefined) => void;
  toggleQuestionCommentSection?: (preset: Preset) => void;
  togglePreviousAnswersSection?: (val: string) => void;
  showCommentButton?: boolean;
  showPreviousAns?: boolean;
  canEdit?: boolean;
  displayQuestions: DisplayQuestion;
  isLocked?: boolean;
  showWaterMark?: boolean;
  staticContent: DocumentStaticContent;
  commonContent: DocumentCommonContent;
};
const ArticleEightPeriodic: React.FC<Props> = ({
  mode = DocumentViewModeEnum.edit,
  document,
  updateDoc,
  toggleQuestionCommentSection,
  togglePreviousAnswersSection,
  showCommentButton,
  showPreviousAns,
  canEdit,
  displayQuestions,
  isLocked,
  showWaterMark,
  staticContent,
  commonContent,
}) => {
  const {
    globalState: { commentedQuestionIds, documentState },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);
  const { getValue, getQuestionError, updateAnswer, handleFloatInput } =
    useDocumentState();
  const isEditMode = useMemo(() => {
    return mode === DocumentViewModeEnum.edit && canEdit;
  }, [canEdit]);
  useEffect(() => {
    if (documentState?.["FGxiFAEKFY"] !== "No") {
      updateAnswer("FGxiFAEKFY", "No");
    }
    return () => {
      globalDispatch(updateDocumentState(undefined));
      updateDoc(undefined);
    };
  }, []);
  const onComment = (qId: string) => {
    const preset: Preset = {
      commentType: CommentType.questionLevel,
      documentId: document._id,
      questionId: qId,
    };
    toggleQuestionCommentSection?.(preset);
  };
  const showInfoIcon = !(mode === DocumentViewModeEnum.view);
  const showDoNotIncludeIcon = !(mode === DocumentViewModeEnum.view);
  const showErrorMessage =
    mode === DocumentViewModeEnum.edit &&
    document.status !== DocumentStatusEnum.filed;

  const hasCommentsById = useCallback(
    (qId: string) => {
      return commentedQuestionIds.includes(qId);
    },
    [commentedQuestionIds]
  );

  const onPreviousAnswersClick = (qId: string) => {
    togglePreviousAnswersSection?.(qId);
  };

  const headerData: HeaderData[] = [
    {
      label: getStaticContentText(
        "Rmuz6N7egm",
        staticContent.staticText,
        document.language
      ),
      key: "largeInvestments",
      spacing: 1.4,
    },
    {
      label: getStaticContentText(
        "JCf2p2Wgdz",
        staticContent.staticText,
        document.language
      ),
      key: "sector",
      spacing: 1.2,
    },
    {
      label: getStaticContentText(
        "Ve81eUwV6X",
        staticContent.staticText,
        document.language
      ),
      key: "assets",
      spacing: 1,
    },
    {
      label: getStaticContentText(
        "ScMfI7ew4b",
        staticContent.staticText,
        document.language
      ),
      key: "country",
      spacing: 1.2,
    },
  ];

  const subQuestionTopPadding = 20;
  const isNewVersionDocument = () => {
    if (document.status === DocumentStatusEnum.filed) {
      if (getValue("jPjVV4rSN1")) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const clearChartData = (questionIds: string[]) => {
    questionIds.forEach((id: string) => {
      updateAnswer(id, 0);
    });
  };

  const clearTaxnomyIncludingChartData = () => {
    clearChartData([
      "lF90AbRUA1",
      "NpheFtm86D",
      "ylHOgkmLpx",
      "XQ7GlOjhpm",
      "2VIxL6JKVP",
      "eUJfUOJzeK",
      "VypukvUtWV",
      "WeyMiXztAj",
      "tYrtWqAkLE",
      "p61HrjyPr5",
      "uIqG7QbHHX",
      "SGPLaP0VWQ",
    ]);
  };
  const clearTaxnomyExcludingChartData = () => {
    clearChartData([
      "cLMXNErpLx",
      "Vsz4WtWWOq",
      "eCFaoWSGWu",
      "PxtylL4phn",
      "ZqcMr44CWg",
      "nv0uz7vMJ3",
      "jWHjk4CbbX",
      "qTMA2fKcvn",
      "yVQ7E2g8Yl",
      "B3tQAj2rDN",
      "jXvToL4j1p",
      "L3Kk6y1z4m",
    ]);
  };

  const barCharLabels = [
    getStaticContentText(
      "TFs1iWMg9P",
      staticContent.staticText,
      document.language
    ),
    getStaticContentText(
      "UeefNSjL5R",
      staticContent.staticText,
      document.language
    ),
    getStaticContentText(
      "jr5sewetYY",
      staticContent.staticText,
      document.language
    ),
  ];

  return (
    <Container isLocked={isLocked}>
      <UploadLogoButton
        document={document}
        localDocumentState={documentState}
        updateDoc={(d) => updateAnswer("logo", d.documentState?.logo)}
        disabled={!isEditMode}
        mode={mode}
      />
      {showWaterMark && (
        <WaterMarkWrapper>
          <WaterMarkText>DRAFT</WaterMarkText>
        </WaterMarkWrapper>
      )}
      <Section
        left={<div style={{ minHeight: "140px" }}></div>}
        right={
          <>
            <AnnexText>
              <Label isBold isItalic fontSize={14}>
                {/* {parse(
                  getStaticContentText(
                    "Ovs6YgWZDD",
                    staticContent.staticText,
                    document.language
                  )
                )} */}
              </Label>
            </AnnexText>
            <SubHeading>
              {/* Template periodic disclosure for the financial products referred
                to in Article 8, paragraphs 1, 2 and 2a, of Regulation (EU)
                2019/2088 and Article 6, first paragraph, of Regulation (EU)
                2020/852 */}
              {parse(
                getStaticContentText(
                  "sRLkftsPXn",
                  staticContent.headingText,
                  document.language
                )
              )}
            </SubHeading>
            <ProductInfoContainer>
              <ProductInfo>
                {parse(
                  getStaticContentText(
                    "14Hdw22lsb",
                    staticContent.staticText,
                    document.language
                  )
                )}
                : {document?.subFundName}
              </ProductInfo>
              <ProductInfo>
                {parse(
                  getStaticContentText(
                    "QAsGJr0Ozs",
                    staticContent.staticText,
                    document.language
                  )
                )}
                : {document?.legalEntityIdentifier}
              </ProductInfo>
            </ProductInfoContainer>
          </>
        }
      />
      <Section
        left={
          <>
            <GreyBox>
              {/* <Bold>Sustainable investment </Bold>means an investment in an
              economic activity that contributes to an environmental or social
              objective, provided that the investment does not significantly
              harm any environmental or social objective and that the investee
              companies follow good governance practices. */}
              {parse(
                getStaticContentText(
                  "ZWdd8JUnSk",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
            <GreyBox>
              {/* The
              <Bold> EU Taxonomy </Bold> is a classification system laid down in
              Regulation (EU) 2020/852, establishing a list of
              <Bold> environmentally sustainable economic activities</Bold>.
              That Regulation does not include a list of socially sustainable
              economic activities. Sustainable investments with an environmental
              objective might be aligned with the Taxonomy or not. */}
              {parse(
                getStaticContentText(
                  "f4eJ91QTAt",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <Title>
              {/* Environmental and/or social characteristics */}
              {parse(
                getStaticContentText(
                  "dSRTnizdqQ",
                  staticContent.staticText,
                  document.language
                )
              )}
            </Title>
            <OrangeBoxHeader>
              <Bold>
                <FlexRow>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [tick and fill in as relevant, the percentage figure
                    represents the minimum commitment to sustainable
                    investments] */}
                    {parse(
                      getStaticContentText(
                        "ZjdomSB4ry",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <Label isBold>
                    {getQuestionLabel(
                      "FGxiFAEKFY",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                  </Label>
                  <CommentButton
                    hasComments={hasCommentsById("FGxiFAEKFY")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("FGxiFAEKFY")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["FGxiFAEKFY"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("FGxiFAEKFY")}
                  />
                </FlexRow>
              </Bold>
              <Flex>
                <OrangeLeftHeader>
                  <Circle isHighlighted /> <Circle isHighlighted />
                  <Checkbox
                    id="1"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={6}
                    disabled={true}
                    mode={mode}
                  />
                </OrangeLeftHeader>
                <OrangeRightHeader>
                  <Circle isHighlighted /> <Circle />
                  <Checkbox
                    id="2"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={6}
                    disabled={true}
                    checked={true}
                    mode={mode}
                  />
                  <HorizontalLine />
                </OrangeRightHeader>
              </Flex>
            </OrangeBoxHeader>
            <OrangeBox>
              <OrangeSection>
                <OrangeLeft>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="3"
                      fontSize={15}
                      label=""
                      disabled={true}
                      checked={false}
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "jScjEOUufS",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}
                      <NumberInput
                        value={getValue("ffHoGbXma9") || ""}
                        disabled={true}
                        inline
                      />
                      {
                        getQuestionLabel(
                          "jScjEOUufS",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <Checkbox
                    id="4"
                    marginLeft={35}
                    bold={false}
                    fontSize={12}
                    label={getQuestionLabel(
                      "QcLtReC5vZ",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={true}
                    checked={false}
                    mode={mode}
                  />
                  <Checkbox
                    id="5"
                    bold={false}
                    marginLeft={35}
                    fontSize={12}
                    label={getQuestionLabel(
                      "eBCVFXcv00",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={true}
                    checked={false}
                    mode={mode}
                  />
                </OrangeLeft>
                <OrangeRight>
                  <ErrorMessage
                    questionId="FGxiFAEKFY"
                    isEditMode={showErrorMessage}
                  >
                    {getQuestionError("FGxiFAEKFY")?.message}
                  </ErrorMessage>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="6"
                      fontSize={15}
                      label=""
                      disabled={!isEditMode}
                      checked={!!getValue("3UyXhAFGxP")}
                      onClick={() => {
                        updateAnswer("3UyXhAFGxP", !getValue("3UyXhAFGxP"));
                        if (!getValue("3UyXhAFGxP") === true)
                          updateAnswer("eEEtrD6TJO", false);
                      }}
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "3UyXhAFGxP",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}
                      <NumberInput
                        maxLimit={100}
                        value={
                          getValue("3UyXhAFGxP") === true
                            ? 0
                            : getValue("3UyXhAFGxP") || ""
                        }
                        inline
                        onChange={(e) => handleFloatInput(e, "3UyXhAFGxP", 100)}
                        disabled={!isEditMode}
                      />
                      {
                        getQuestionLabel(
                          "3UyXhAFGxP",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <ErrorMessage
                    questionId="3UyXhAFGxP"
                    isEditMode={showErrorMessage}
                    marginLeft={35}
                  >
                    {getQuestionError("3UyXhAFGxP")?.message}
                  </ErrorMessage>
                  <Checkbox
                    id="7"
                    marginLeft={35}
                    bold={false}
                    fontSize={12}
                    label={getQuestionLabel(
                      "QofqZOb0pU",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode || !getValue("3UyXhAFGxP")}
                    checked={getValue("QofqZOb0pU")}
                    onClick={() =>
                      updateAnswer("QofqZOb0pU", !getValue("QofqZOb0pU"))
                    }
                    mode={mode}
                  />
                  <Checkbox
                    id="8"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "zFGpAr2nZW",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode || !getValue("3UyXhAFGxP")}
                    checked={getValue("zFGpAr2nZW")}
                    onClick={() =>
                      updateAnswer("zFGpAr2nZW", !getValue("zFGpAr2nZW"))
                    }
                    mode={mode}
                  />
                  <Checkbox
                    id="9"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "F1J4Jobh39",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode || !getValue("3UyXhAFGxP")}
                    checked={getValue("F1J4Jobh39")}
                    onClick={() =>
                      updateAnswer("F1J4Jobh39", !getValue("F1J4Jobh39"))
                    }
                    mode={mode}
                  />
                  <HorizontalLine />
                </OrangeRight>
              </OrangeSection>
              <OrangeSection>
                <OrangeLeft>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="10"
                      fontSize={15}
                      label=""
                      disabled={true}
                      checked={false}
                      onClick={() =>
                        updateAnswer("DdDPk6jdRq", !getValue("DdDPk6jdRq"))
                      }
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "DdDPk6jdRq",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}
                      <NumberInput
                        maxLimit={100}
                        value={
                          getValue("3PLLWo4Pbq") >= 0
                            ? getValue("3PLLWo4Pbq")
                            : ""
                        }
                        inline
                        onChange={(e) =>
                          handleFloatInput(e as number, "3PLLWo4Pbq", 100)
                        }
                        disabled={true}
                      />
                      {
                        getQuestionLabel(
                          "DdDPk6jdRq",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                </OrangeLeft>
                <OrangeRight>
                  <Checkbox
                    id="11"
                    fontSize={15}
                    bold={false}
                    label={getQuestionLabel(
                      "eEEtrD6TJO",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode}
                    checked={getValue("eEEtrD6TJO")}
                    onClick={() => {
                      updateAnswer("eEEtrD6TJO", !getValue("eEEtrD6TJO"));
                      if (!getValue("eEEtrD6TJO") === true)
                        updateAnswer("3UyXhAFGxP", false);
                    }}
                    mode={mode}
                  />
                  <HorizontalLine heightPercent={87} />
                </OrangeRight>
              </OrangeSection>
            </OrangeBox>
          </>
        }
      />
      {isEditMode && (
        <Section
          paddingTop={20}
          left={<></>}
          right={
            <>
              <QuestionContainer>
                <QuestionText isBold>
                  <Highlight>
                    <QuestionLabel
                      isBold
                      displayQuestions={displayQuestions}
                      questionId={"fEf3EzCGjX"}
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["fEf3EzCGjX"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("fEf3EzCGjX")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="12"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("fEf3EzCGjX") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "fEf3EzCGjX",
                        getValue("fEf3EzCGjX") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="13"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("fEf3EzCGjX") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "fEf3EzCGjX",
                        getValue("fEf3EzCGjX") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            </>
          }
        />
      )}
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="globe" />
            <GreyBox>
              {/* <Bold>Sustainability indicators </Bold> measure how the
              environmental or social characteristics promoted by the financial
              product are attained. */}
              {parse(
                getStaticContentText(
                  "zl1eYSnRxq",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {parse(
                      getStaticContentText(
                        "HTSOZlQTmR",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                    {/* [list the environmental and/or social characteristics promoted
                    by the financial product. For the financial products referred
                    to in Article 6, first paragraph, of Regulation (EU) 2020/852,
                    in respect of sustainable investments with environmental
                    objectives, list the environmental objectives set out in
                    Article 9 of that Regulation to which the sustainable
                    investment underlying the financial product contributed. For
                    financial products that made sustainable investments with
                    social objectives, list the social objectives] */}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    displayQuestions={displayQuestions}
                    questionId={"UDMZ1f1K3R"}
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("UDMZ1f1K3R")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("UDMZ1f1K3R")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["UDMZ1f1K3R"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("UDMZ1f1K3R")}
                  />
                </QuestionText>
                <QuillEditor
                  id="UDMZ1f1K3R"
                  disabled={!isEditMode}
                  innerHTML={getValue("UDMZ1f1K3R")}
                  onChange={(ans) => updateAnswer("UDMZ1f1K3R", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={10}
              >
                <QuestionText fontSize={14} isItalic isBold>
                  <GreyDot />{" "}
                  <QuestionLabel
                    isItalic
                    isBold
                    fontSize={14}
                    displayQuestions={displayQuestions}
                    questionId={"VNTH1GU2mV"}
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("VNTH1GU2mV")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("VNTH1GU2mV")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["VNTH1GU2mV"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("VNTH1GU2mV")}
                  />
                </QuestionText>
                <QuillEditor
                  id="VNTH1GU2mV"
                  disabled={!isEditMode}
                  innerHTML={getValue("VNTH1GU2mV")}
                  onChange={(ans) => updateAnswer("VNTH1GU2mV", ans)}
                />
              </QuestionContainer>
              {isEditMode && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={0}
                >
                  <QuestionText isBold fontSize={14}>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        fontSize={14}
                        displayQuestions={displayQuestions}
                        questionId={"gwViTw9O2d"}
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("gwViTw9O2d")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("gwViTw9O2d")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["gwViTw9O2d"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("gwViTw9O2d")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="14"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("gwViTw9O2d") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "gwViTw9O2d",
                          getValue("gwViTw9O2d") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="15"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("gwViTw9O2d") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "gwViTw9O2d",
                          getValue("gwViTw9O2d") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("gwViTw9O2d") === "Yes" && (
                <>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include for financial products where at least one
                        previous periodic report was provided] */}
                        {parse(
                          getStaticContentText(
                            "0J7TK8LpFO",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        isBold
                        isItalic
                        displayQuestions={displayQuestions}
                        questionId={"E3mMfO1lfm"}
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />

                      <CommentButton
                        hasComments={hasCommentsById("E3mMfO1lfm")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("E3mMfO1lfm")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["E3mMfO1lfm"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("E3mMfO1lfm")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="E3mMfO1lfm"
                      disabled={!isEditMode}
                      innerHTML={getValue("E3mMfO1lfm")}
                      onChange={(ans) => updateAnswer("E3mMfO1lfm", ans)}
                    />
                  </QuestionContainer>
                </>
              )}
              {isEditMode && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isBold>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        displayQuestions={displayQuestions}
                        questionId={"8rRkRAcfse"}
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("8rRkRAcfse")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("8rRkRAcfse")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["8rRkRAcfse"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("8rRkRAcfse")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="16"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("8rRkRAcfse") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "8rRkRAcfse",
                          getValue("8rRkRAcfse") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="17"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("8rRkRAcfse") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "8rRkRAcfse",
                          getValue("8rRkRAcfse") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("8rRkRAcfse") === "Yes" && (
                <>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include for financial products that made sustainable
                        investments, where not included in the reply to the
                        above question, describe the objectives. Describe how
                        the sustainable investments contributed to the
                        sustainable investment objective. For the financial
                        products referred to in Article 6, first paragraph, of
                        Regulation (EU) 2020/852, list the environmental
                        objectives set out in Article 9 of that Regulation to
                        which the sustainable investment underlying the
                        financial product contributed] */}
                        {parse(
                          getStaticContentText(
                            "l1l1SuI1Zj",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        isBold
                        isItalic
                        displayQuestions={displayQuestions}
                        questionId={"NcJUukoi3D"}
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("NcJUukoi3D")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("NcJUukoi3D")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["NcJUukoi3D"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("NcJUukoi3D")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="NcJUukoi3D"
                      disabled={!isEditMode}
                      innerHTML={getValue("NcJUukoi3D")}
                      onChange={(ans) => updateAnswer("NcJUukoi3D", ans)}
                    />
                  </QuestionContainer>
                </>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        left={
          <>
            {getValue("8rRkRAcfse") === "Yes" && (
              <GreyBox>
                {/* <Bold>Principal adverse impacts </Bold> are the most significant
              negative impacts of investment decisions on sustainability factors
              relating to environmental, social and employee matters, respect
              for human rights, anti-corruption and anti-bribery matters. */}
                {parse(
                  getStaticContentText(
                    "96Y4V3DMoi",
                    staticContent.greyBox,
                    document.language
                  )
                )}
              </GreyBox>
            )}
          </>
        }
        right={
          <>
            <QuestionContainer>
              {getValue("8rRkRAcfse") === "Yes" && (
                <>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionContainer paddingLeft={0}>
                      <QuestionText fontSize={14} isItalic isBold>
                        <GreyDot />
                        <InfoToolTip disabled={!showInfoIcon}>
                          {/* [include where the financial product includes
                          sustainable investments] */}
                          {parse(
                            getStaticContentText(
                              "rSa8ReIIDN",
                              staticContent.infoTooltip,
                              document.language
                            )
                          )}
                        </InfoToolTip>
                        <QuestionLabel
                          isBold
                          isItalic
                          displayQuestions={displayQuestions}
                          questionId={"oZ6pxWKXQi"}
                          language={document.language}
                          getQuestionError={getQuestionError}
                          showErrorMessage={showErrorMessage}
                        />
                        <CommentButton
                          hasComments={hasCommentsById("oZ6pxWKXQi")}
                          disabled={!showCommentButton}
                          onClick={() => onComment("oZ6pxWKXQi")}
                        />
                        <PreviousAnswers
                          disabled={!showPreviousAns}
                          hasAnswers={
                            displayQuestions["oZ6pxWKXQi"].previousAnswers
                          }
                          onClick={() => onPreviousAnswersClick("oZ6pxWKXQi")}
                        />
                      </QuestionText>
                      <QuillEditor
                        id="oZ6pxWKXQi"
                        disabled={!isEditMode}
                        innerHTML={getValue("oZ6pxWKXQi")}
                        onChange={(ans) => updateAnswer("oZ6pxWKXQi", ans)}
                      />
                    </QuestionContainer>
                    <QuestionContainer
                      paddingTop={subQuestionTopPadding}
                      paddingLeft={20}
                    >
                      <QuestionText fontSize={14} isItalic isBold>
                        <QuestionLabel
                          isBold
                          isItalic
                          displayQuestions={displayQuestions}
                          questionId={"yR5quHgDrX"}
                          language={document.language}
                          getQuestionError={getQuestionError}
                          showErrorMessage={showErrorMessage}
                        />
                        <CommentButton
                          hasComments={hasCommentsById("yR5quHgDrX")}
                          disabled={!showCommentButton}
                          onClick={() => onComment("yR5quHgDrX")}
                        />
                        <PreviousAnswers
                          disabled={!showPreviousAns}
                          hasAnswers={
                            displayQuestions["yR5quHgDrX"].previousAnswers
                          }
                          onClick={() => onPreviousAnswersClick("yR5quHgDrX")}
                        />
                      </QuestionText>
                      <QuillEditor
                        id="yR5quHgDrX"
                        disabled={!isEditMode}
                        innerHTML={getValue("yR5quHgDrX")}
                        onChange={(ans) => updateAnswer("yR5quHgDrX", ans)}
                      />
                    </QuestionContainer>
                    <QuestionContainer
                      paddingTop={subQuestionTopPadding}
                      paddingLeft={20}
                    >
                      <QuestionText fontSize={14} isItalic isBold>
                        <QuestionLabel
                          isBold
                          isItalic
                          displayQuestions={displayQuestions}
                          questionId={"HxguMlWIDi"}
                          language={document.language}
                          getQuestionError={getQuestionError}
                          showErrorMessage={showErrorMessage}
                        />
                        <CommentButton
                          hasComments={hasCommentsById("HxguMlWIDi")}
                          disabled={!showCommentButton}
                          onClick={() => onComment("HxguMlWIDi")}
                        />
                        <PreviousAnswers
                          disabled={!showPreviousAns}
                          hasAnswers={
                            displayQuestions["yR5quHgDrX"].previousAnswers
                          }
                          onClick={() => onPreviousAnswersClick("yR5quHgDrX")}
                        />
                      </QuestionText>
                      <QuillEditor
                        id="HxguMlWIDi"
                        disabled={!isEditMode}
                        innerHTML={getValue("HxguMlWIDi")}
                        onChange={(ans) => updateAnswer("HxguMlWIDi", ans)}
                      />
                    </QuestionContainer>
                  </QuestionContainer>
                  <OrangeBox padding="10px" margin="10px">
                    <div style={{ paddingBottom: "15px" }}>
                      <QuestionText isItalic>
                        <InfoToolTip width={320} disabled={!showInfoIcon}>
                          {parse(
                            getStaticContentText(
                              "rSa8ReIIDN",
                              staticContent.infoTooltip,
                              document.language
                            )
                          )}
                          {/* [Include statement for financial products referred to
                          in Article 6, first paragraph, of Regulation (EU)
                        2020/852] */}
                        </InfoToolTip>
                        {parse(
                          getStaticContentText(
                            "B1qJZXPPmi",
                            staticContent.questionText,
                            document.language
                          )
                        )}
                        {/* The EU Taxonomy sets out a “do not significant harm”
                        principle by which Taxonomy-aligned investments should
                        not significantly harm EU Taxonomy objectives and is
                      accompanied by specific Union criteria. */}
                      </QuestionText>
                    </div>
                    <div style={{ paddingBottom: "15px" }}>
                      <Text>
                        {parse(
                          getStaticContentText(
                            "qOsmxFNuOA",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                        {/* The “do no significant harm” principle applies only to
                        those investments underlying the financial product that
                        take into account the EU criteria for environmentally
                        sustainable economic activities. The investments
                        underlying the remaining portion of this financial
                        product do not take into account the Union criteria for
                      environmentally sustainable economic activities. */}
                      </Text>
                    </div>
                    <div style={{ paddingBottom: "15px" }}>
                      <Text isItalic>
                        {parse(
                          getStaticContentText(
                            "b2OKp0qVhD",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                        {/* Any other sustainable investments must also not
                        significantly harm any environmental or social
                        objectives. */}
                      </Text>
                    </div>
                  </OrangeBox>
                </>
              )}
              {isEditMode && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isBold>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        displayQuestions={displayQuestions}
                        questionId={"OGOwZwSiO3"}
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("OGOwZwSiO3")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("OGOwZwSiO3")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["OGOwZwSiO3"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("OGOwZwSiO3")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="18"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("OGOwZwSiO3") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "OGOwZwSiO3",
                          getValue("OGOwZwSiO3") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="19"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("OGOwZwSiO3") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "OGOwZwSiO3",
                          getValue("OGOwZwSiO3") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      {getValue("OGOwZwSiO3") === "Yes" && (
        <Section
          paddingTop={20}
          left={
            <>
              <MainIcon name="factory" />
            </>
          }
          right={
            <>
              <QuestionContainer>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include section if the financial product considered
                    principal adverse impacts on sustainability factors] */}
                    {parse(
                      getStaticContentText(
                        "CXtUtNpnak",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    displayQuestions={displayQuestions}
                    questionId={"qv2iFzoFLx"}
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("qv2iFzoFLx")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("qv2iFzoFLx")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["qv2iFzoFLx"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("qv2iFzoFLx")}
                  />
                </QuestionText>
                <QuillEditor
                  id="qv2iFzoFLx"
                  disabled={!isEditMode}
                  innerHTML={getValue("qv2iFzoFLx")}
                  onChange={(ans) => updateAnswer("qv2iFzoFLx", ans)}
                />
              </QuestionContainer>
            </>
          }
        />
      )}
      {isEditMode && (
        <Section
          paddingTop={20}
          right={
            <>
              <QuestionContainer>
                <QuestionText fontSize={14} isBold>
                  <Highlight>
                    <QuestionLabel
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="R1exWvbeKS"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("R1exWvbeKS")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("R1exWvbeKS")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["R1exWvbeKS"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("R1exWvbeKS")}
                  />
                </QuestionText>
                <QuillEditor
                  id="R1exWvbeKS"
                  disabled={!isEditMode}
                  innerHTML={getValue("R1exWvbeKS")}
                  onChange={(ans) => updateAnswer("R1exWvbeKS", ans)}
                />
              </QuestionContainer>
            </>
          }
        />
      )}
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="moneyCoin" />
            <GreyBox>
              {/* The list includes the investments constituting
              <Bold> the greatest proportion of investments</Bold> of the
              financial product during the reference period which is: */}
              {parse(
                getStaticContentText(
                  "OR9WUCyjlU",
                  staticContent.greyBox,
                  document.language
                )
              )}
              {getValue("R1exWvbeKS")
                ? getValue("R1exWvbeKS").replace(/(<([^>]+)>)/gi, "")
                : ""}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionText isBold>
                <QuestionLabel
                  isBold
                  displayQuestions={displayQuestions}
                  questionId="mztNrmnUMO"
                  language={document.language}
                  getQuestionError={getQuestionError}
                  showErrorMessage={showErrorMessage}
                />

                <CommentButton
                  hasComments={hasCommentsById("mztNrmnUMO")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("mztNrmnUMO")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["mztNrmnUMO"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("mztNrmnUMO")}
                />
              </QuestionText>
              <TableInput
                isEditMode={isEditMode}
                onChange={(data) => updateAnswer("mztNrmnUMO", data)}
                data={getValue("mztNrmnUMO")}
                headerData={headerData}
                countryList={getCountryListArray(
                  commonContent.countryList,
                  document.language
                )}
              />
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={40}
        left={
          <>
            <MainIcon name="leafBW" />
            <GreyBox>
              {/* <Bold>Asset allocation</Bold> describes the share of investments
              in specific assets. */}
              {parse(
                getStaticContentText(
                  "pkA0shaSKu",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <QuestionLabel
                    isBold
                    displayQuestions={displayQuestions}
                    questionId="0rYjZizOLm"
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("0rYjZizOLm")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("0rYjZizOLm")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["0rYjZizOLm"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("0rYjZizOLm")}
                  />
                </QuestionText>
                <QuillEditor
                  id="0rYjZizOLm"
                  disabled={!isEditMode}
                  innerHTML={getValue("0rYjZizOLm")}
                  onChange={(ans) => updateAnswer("0rYjZizOLm", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={20}
              >
                <QuestionText isBold>
                  <GreyDot />{" "}
                  <QuestionLabel
                    isBold
                    displayQuestions={displayQuestions}
                    questionId="clCQkUvHzb"
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("clCQkUvHzb")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("clCQkUvHzb")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["clCQkUvHzb"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("clCQkUvHzb")}
                  />
                </QuestionText>
                <QuillEditor
                  id="clCQkUvHzb"
                  disabled={!isEditMode}
                  innerHTML={getValue("clCQkUvHzb")}
                  onChange={(ans) => updateAnswer("clCQkUvHzb", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={0}
              >
                <OrangeBox>
                  <TooltipWrapper>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [Include only the relevant boxes of this sample image and
                      remove irrelevant ones for the financial product] */}
                      {parse(
                        getStaticContentText(
                          "jEHL7kkAtL",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                  </TooltipWrapper>
                  <UploadWrapper>
                    <AssetAllocationGraph
                      document={document}
                      questionId="BYFG1N53Io"
                      updateDoc={updateDoc}
                      commonContent={commonContent}
                      isEditMode={isEditMode}
                      mode={mode}
                      updateAns={updateAnswer}
                      article="Article 8"
                      template="Periodic"
                      documentState={documentState}
                    />
                  </UploadWrapper>
                  <UploadFooterNote>
                    <Text fontSize={12}>
                      {/* <Bold>#1 Aligned with E/S characteristics </Bold>                  includes the investments of the financial product used to
                      attain the environmental or social characteristics promoted by
                      the financial product. */}
                      {parse(
                        getStaticContentText(
                          "F8k25SJu0g",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                    </Text>
                  </UploadFooterNote>
                  <UploadFooterNote>
                    <Text fontSize={12}>
                      {/* <Bold>#2 Other </Bold>                  includes the remaining investments of the financial product
                      which are neither aligned with the environmental or social
                    characteristics, nor are qualified as sustainable investments. */}
                      {parse(
                        getStaticContentText(
                          "XBZzGFfWfI",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                    </Text>
                  </UploadFooterNote>
                  {isEditMode && (
                    <QuestionContainer
                      paddingLeft={20}
                      paddingTop={subQuestionTopPadding}
                    >
                      <QuestionText fontSize={14} isItalic isBold>
                        <InfoToolTip disabled={!showInfoIcon}>
                          {/* [include the note below where the financial product
                          commits to making sustainable investments] */}
                          {parse(
                            getStaticContentText(
                              "Ap6Xx878Ay",
                              staticContent.infoTooltip,
                              document.language
                            )
                          )}
                        </InfoToolTip>
                        <Highlight>
                          <QuestionLabel
                            isBold
                            isItalic
                            displayQuestions={displayQuestions}
                            questionId="c9UQLBzsAb"
                            language={document.language}
                            getQuestionError={getQuestionError}
                            showErrorMessage={showErrorMessage}
                          />
                        </Highlight>
                        <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                        <CommentButton
                          hasComments={hasCommentsById("c9UQLBzsAb")}
                          disabled={!showCommentButton}
                          onClick={() => onComment("c9UQLBzsAb")}
                        />
                        <PreviousAnswers
                          disabled={!showPreviousAns}
                          hasAnswers={
                            displayQuestions["c9UQLBzsAb"].previousAnswers
                          }
                          onClick={() => onPreviousAnswersClick("c9UQLBzsAb")}
                        />
                      </QuestionText>
                      <FlexRow>
                        <Checkbox
                          id="20"
                          label={getStaticContentText(
                            "C7uLFog7hl",
                            staticContent.questionAnswerOption,
                            document.language
                          )}
                          disabled={!isEditMode}
                          checked={getValue("c9UQLBzsAb") === "Yes"}
                          onClick={() =>
                            updateAnswer(
                              "c9UQLBzsAb",
                              getValue("c9UQLBzsAb") === "Yes" ? "" : "Yes"
                            )
                          }
                        />
                        <Checkbox
                          id="21"
                          label={getStaticContentText(
                            "bG9v3QJaqt",
                            staticContent.questionAnswerOption,
                            document.language
                          )}
                          marginLeft={60}
                          disabled={!isEditMode}
                          checked={getValue("c9UQLBzsAb") === "No"}
                          onClick={() =>
                            updateAnswer(
                              "c9UQLBzsAb",
                              getValue("c9UQLBzsAb") === "No" ? "" : "No"
                            )
                          }
                        />
                      </FlexRow>
                    </QuestionContainer>
                  )}
                  {getValue("c9UQLBzsAb") === "Yes" && (
                    <>
                      <UploadFooterNote>
                        <Text fontSize={12}>
                          {parse(
                            getStaticContentText(
                              "hh7oXo1KPO",
                              staticContent.staticText,
                              document.language
                            )
                          )}
                          {/* The category
                          <Bold>#1 Aligned with E/S characteristics </Bold> covers: */}
                        </Text>
                      </UploadFooterNote>
                      <UploadFooterNote>
                        <Text fontSize={12}>
                          {parse(
                            getStaticContentText(
                              "nwEsx3zSKY",
                              staticContent.staticText,
                              document.language
                            )
                          )}
                          {/* - The sub-category <Bold>#1A Sustainable</Bold> covers
                          sustainable investments with environmental or social
                        objectives. */}
                        </Text>
                      </UploadFooterNote>
                      <UploadFooterNote>
                        <Text fontSize={12}>
                          {parse(
                            getStaticContentText(
                              "IJXVq0Gatv",
                              staticContent.staticText,
                              document.language
                            )
                          )}
                          {/* - The sub-category #1B Other E/S characteristics covers
                          investments aligned with the environmental or social
                          characteristics that do not qualify as sustainable
                        investments. */}
                        </Text>
                      </UploadFooterNote>
                    </>
                  )}
                </OrangeBox>
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={20}
              >
                <QuestionText>
                  <GreyDot />{" "}
                  <QuestionLabel
                    isBold
                    displayQuestions={displayQuestions}
                    questionId="wqVAE35fqK"
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {parse(
                      getStaticContentText(
                        "M2HDLxymsP",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                    {/* [include information referred to in Article 54 of this
                    Regulation] */}
                  </InfoToolTip>
                  <CommentButton
                    hasComments={hasCommentsById("wqVAE35fqK")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("wqVAE35fqK")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["wqVAE35fqK"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("wqVAE35fqK")}
                  />
                </QuestionText>
                <QuillEditor
                  id="wqVAE35fqK"
                  disabled={!isEditMode}
                  innerHTML={getValue("wqVAE35fqK")}
                  onChange={(ans) => updateAnswer("wqVAE35fqK", ans)}
                />
              </QuestionContainer>
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("6aUxDfFuzD") === "Yes" && (
              <>
                <GreyBox>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include note only for the financial products referred to in
                    Article 6, first paragraph, of Regulation (EU) 2020/852] */}
                    {parse(
                      getStaticContentText(
                        "pfKuU7FrGR",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  {parse(
                    getStaticContentText(
                      "FPcReMCe0F",
                      staticContent.greyBox,
                      document.language
                    )
                  )}
                  {/* To comply with the EU Taxonomy, the criteria for
                  <strong> fossil gas </strong> include limitations on emissions
                  and switching to fully renewable power or low-carbon fuels by
                  the end of 2035. For &nbsp;<strong> nuclear energy</strong>,
                  the criteria include comprehensive safety and waste management
                  rules.
                  <br /> <br /> <Bold>Enabling activities </Bold> directly
                  enable other activities to make a substantial contribution to
                  an environmental objective.
                  <br /> <br /> <Bold> Transitional activities are </Bold>
                  activities for which low-carbon alternatives are not yet
                  available and among others have greenhouse gas emission levels
                  corresponding to the best performance. */}
                </GreyBox>
                <GreyBox>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include note for the financial products referred to in
                    Article 6, first paragraph, of Regulation (EU) 2020/852] */}
                    {parse(
                      getStaticContentText(
                        "pfKuU7FrGR",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  {parse(
                    getStaticContentText(
                      "px61TNTWy1",
                      staticContent.greyBox,
                      document.language
                    )
                  )}
                  {/* Taxonomy-aligned activities are expressed as a share of:
                  <ul>
                    <li>
                      <div>- </div> <span>turnover</span> reflecting the share
                      of revenue from green activities of investee companies.
                    </li>
                    <li>
                      <div>- </div> <span>capital expenditure</span> (CapEx)
                      showing the green investments made by investee companies,
                      e.g. for a transition to a green economy.
                    </li>
                    <li>
                      <div>- </div> <span>operational expenditure</span> (OpEx)
                      reflecting the green operational activities of investee
                      companies.
                    </li>
                  </ul> */}
                </GreyBox>
              </>
            )}
          </>
        }
        right={
          <>
            <QuestionContainer>
              {isEditMode && (
                <QuestionContainer paddingLeft={0}>
                  <QuestionText fontSize={14}>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        displayQuestions={displayQuestions}
                        questionId="6aUxDfFuzD"
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("6aUxDfFuzD")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("6aUxDfFuzD")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["6aUxDfFuzD"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("6aUxDfFuzD")}
                    />
                  </QuestionText>
                  <FlexRow paddingTop={5}>
                    <Checkbox
                      id="22"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("6aUxDfFuzD") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "6aUxDfFuzD",
                          getValue("6aUxDfFuzD") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="23"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("6aUxDfFuzD") === "No"}
                      onClick={() => {
                        // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                        clearTaxnomyIncludingChartData();
                        // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                        clearTaxnomyExcludingChartData();
                        updateAnswer(
                          "6aUxDfFuzD",
                          getValue("6aUxDfFuzD") === "No" ? "" : "No"
                        );
                      }}
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("6aUxDfFuzD") === "Yes" && (
                <QuestionContainer
                  paddingLeft={10}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionContainer paddingLeft={0}>
                    <QuestionText fontSize={14}>
                      <SubIcon name="book" />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {parse(
                          getStaticContentText(
                            "65gUH1ZjZt",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                        {/* [include section for the financial products referred to in
                        Article 6 , first paragraph, of Regulation (EU) 2020/852
                        and include information in accordance with Article 51 of
                        this Regulation] */}
                      </InfoToolTip>
                      <QuestionLabel
                        isBold
                        displayQuestions={displayQuestions}
                        questionId="BXZ5PKw7QZ"
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("BXZ5PKw7QZ")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("BXZ5PKw7QZ")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["BXZ5PKw7QZ"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("BXZ5PKw7QZ")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="BXZ5PKw7QZ"
                      disabled={!isEditMode}
                      innerHTML={getValue("BXZ5PKw7QZ")}
                      onChange={(ans) => updateAnswer("BXZ5PKw7QZ", ans)}
                    />
                  </QuestionContainer>
                  {/* Condition needs to removed in next iteration */}
                  {isNewVersionDocument() && (
                    <QuestionContainer
                      paddingLeft={0}
                      paddingTop={subQuestionTopPadding}
                    >
                      <AvoidBreakInside>
                        <QuestionText fontSize={14}>
                          <GreyDot />
                          <QuestionLabel
                            isBold
                            isItalic
                            displayQuestions={displayQuestions}
                            questionId="jPjVV4rSN1"
                            language={document.language}
                            getQuestionError={getQuestionError}
                            showErrorMessage={showErrorMessage}
                          />
                          <CommentButton
                            hasComments={hasCommentsById("jPjVV4rSN1")}
                            disabled={!showCommentButton}
                            onClick={() => onComment("jPjVV4rSN1")}
                          />
                          <PreviousAnswers
                            disabled={!showPreviousAns}
                            hasAnswers={
                              displayQuestions["jPjVV4rSN1"].previousAnswers
                            }
                            onClick={() => onPreviousAnswersClick("jPjVV4rSN1")}
                          />
                        </QuestionText>
                        <FlexColumn paddingTop={5}>
                          <FlexRow align="flex-start">
                            <Checkbox
                              id="24"
                              label={getStaticContentText(
                                "C7uLFog7hl",
                                staticContent.questionAnswerOption,
                                document.language
                              )}
                              marginLeft={60}
                              disabled={!isEditMode}
                              checked={getValue("jPjVV4rSN1") === "Yes"}
                              onClick={() => {
                                // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                                clearTaxnomyIncludingChartData();
                                // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                                clearTaxnomyExcludingChartData();
                                updateAnswer(
                                  "jPjVV4rSN1",
                                  getValue("jPjVV4rSN1") === "Yes" ? "" : "Yes"
                                );
                              }}
                            />
                            &nbsp;&nbsp;
                            <InfoToolTip disabled={!showInfoIcon}>
                              {/* [specify below, and details in the graphs of the
                              box] */}
                              {parse(
                                getStaticContentText(
                                  "FKk3t3dxxf",
                                  staticContent.infoTooltip,
                                  document.language
                                )
                              )}
                            </InfoToolTip>
                          </FlexRow>
                          <FlexRow>
                            <Checkbox
                              id="25"
                              label={getQuestionLabel(
                                "I1R4yEDnJu",
                                displayQuestions,
                                Language?.[document.language]
                              )}
                              marginLeft={120}
                              disabled={
                                !isEditMode || getValue("jPjVV4rSN1") !== "Yes"
                              }
                              checked={getValue("I1R4yEDnJu")}
                              onClick={() =>
                                updateAnswer(
                                  "I1R4yEDnJu",
                                  !getValue("I1R4yEDnJu")
                                )
                              }
                              mode={mode}
                            />
                            <Checkbox
                              id="26"
                              label={getQuestionLabel(
                                "34temHiDul",
                                displayQuestions,
                                Language?.[document.language]
                              )}
                              marginLeft={60}
                              disabled={
                                !isEditMode || getValue("jPjVV4rSN1") !== "Yes"
                              }
                              checked={getValue("34temHiDul")}
                              onClick={() =>
                                updateAnswer(
                                  "34temHiDul",
                                  !getValue("34temHiDul")
                                )
                              }
                              mode={mode}
                            />
                          </FlexRow>
                          <Checkbox
                            id="27"
                            label={getStaticContentText(
                              "bG9v3QJaqt",
                              staticContent.questionAnswerOption,
                              document.language
                            )}
                            marginLeft={60}
                            disabled={!isEditMode}
                            checked={getValue("jPjVV4rSN1") === "No"}
                            onClick={() => {
                              // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                              clearTaxnomyIncludingChartData();
                              // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                              clearTaxnomyExcludingChartData();
                              updateAnswer(
                                "jPjVV4rSN1",
                                getValue("jPjVV4rSN1") === "No" ? "" : "No"
                              );
                            }}
                          />
                        </FlexColumn>
                      </AvoidBreakInside>
                    </QuestionContainer>
                  )}
                  <Note className="no-print">
                    {parse(
                      getStaticContentText(
                        "RvgGOg6xgj",
                        staticContent.staticText,
                        document.language
                      )
                    )}
                    {/* Fossil gas and/or nuclear related activities will only
                        comply with the EU Taxonomy where they contribute to
                        limiting climate change (“climate change mitigation”)
                        and do no significant harm to any EU Taxonomy objective
                        - see explanatory note in the left hand margin. The full
                        criteria for fossil gas and nuclear energy economic
                        activities that comply with the EU Taxonomy are laid
                        down in Commission Delegated Regulation (EU) 2022/1214. */}
                  </Note>
                  <OrangeBox
                    padding={spacingIncrement(10)}
                    margin={`${spacingIncrement(10)} 0 ${spacingIncrement(
                      20
                    )} 0`}
                  >
                    <Label>
                      <Text isItalic isBold fontSize={12}>
                        {parse(
                          getStaticContentText(
                            "6RKcjYgn5J",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                        {/* The graphs below show in green the percentage of
                        investments that were aligned with the EU Taxonomy. As
                        there is no appropriate methodology to determine the
                        taxonomy-alignment of sovereign bonds*, the first graph
                        shows the Taxonomy alignment in relation to all the
                        investments of the financial product including sovereign
                        bonds, while the second graph shows the Taxonomy alignment
                        only in relation to the investments of the financial
                      product other than sovereign bonds. */}
                      </Text>
                    </Label>
                    <Flex>
                      <InfoToolTip disabled={!showInfoIcon}>
                        {parse(
                          getStaticContentText(
                            "bDj2RHPT7U",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                        {/* [Include information on Taxonomy aligned fossil gas and
                        nuclear energy and the explanatory text in the left hand
                        margin on the previous page only if the financial
                        product invested in fossil gas and/or nuclear energy
                        Taxonomy-aligned economic activities during the
                      reference period] */}
                      </InfoToolTip>
                    </Flex>
                    <GraphContainer>
                      <GraphSubContainer>
                        <FlexColumn>
                          <Text fontSize={12} marginLeft="35px">
                            {parse(
                              getStaticContentText(
                                "Ewi0OE6NnT",
                                staticContent.staticText,
                                document.language
                              )
                            )}
                            {/* 1. Taxonomy-alignment of investments
                              <Bold>including sovereign bonds*</Bold> */}
                          </Text>
                          <FlexColumn paddingTop={10}>
                            <Flex>
                              <BarChart
                                labels={barCharLabels}
                                dataValue={[
                                  {
                                    label: getStaticContentText(
                                      "chxQtWvzke",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("lF90AbRUA1"),
                                      getValue("NpheFtm86D"),
                                      getValue("ylHOgkmLpx"),
                                    ],
                                    color: "#809980",
                                  },
                                  {
                                    // label: "Taxonomy-aligned: Nuclear",
                                    label: getStaticContentText(
                                      "Zffi9KIfT5",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("XQ7GlOjhpm"),
                                      getValue("2VIxL6JKVP"),
                                      getValue("eUJfUOJzeK"),
                                    ],
                                    color: "#4d704d",
                                  },
                                  {
                                    // label:
                                    //   "Taxonomy-aligned (no gas and nuclear)",
                                    label: getStaticContentText(
                                      "RXtpexllNu",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("VypukvUtWV"),
                                      getValue("WeyMiXztAj"),
                                      getValue("tYrtWqAkLE"),
                                    ],
                                    color: "#0a3300",
                                  },
                                  {
                                    // label: "Non Taxonomy-aligned",
                                    label: getStaticContentText(
                                      "xgVxV8TR5W",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("p61HrjyPr5"),
                                      getValue("uIqG7QbHHX"),
                                      getValue("SGPLaP0VWQ"),
                                    ],
                                    color: "#d9d9d9",
                                  },
                                ]}
                                stepSize={50}
                              />
                            </Flex>
                            <Flex>
                              {isEditMode && (
                                <Tabular
                                  id="left-table"
                                  headers={[
                                    "",

                                    getStaticContentText(
                                      "TFs1iWMg9P",
                                      staticContent.staticText,
                                      document.language
                                    ),

                                    getStaticContentText(
                                      "UeefNSjL5R",
                                      staticContent.staticText,
                                      document.language
                                    ),

                                    getStaticContentText(
                                      "jr5sewetYY",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                  ]}
                                  data={[
                                    [
                                      getStaticContentText(
                                        "chxQtWvzke",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "lF90AbRUA1",
                                      "NpheFtm86D",
                                      "ylHOgkmLpx",
                                    ],
                                    [
                                      // "Taxonomy-aligned: Nuclear",

                                      getStaticContentText(
                                        "Zffi9KIfT5",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "XQ7GlOjhpm",
                                      "2VIxL6JKVP",
                                      "eUJfUOJzeK",
                                    ],
                                    [
                                      // "Taxonomy-aligned (no gas and nuclear)",

                                      getStaticContentText(
                                        "RXtpexllNu",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "VypukvUtWV",
                                      "WeyMiXztAj",
                                      "tYrtWqAkLE",
                                    ],
                                    [
                                      // "Non Taxonomy-aligned",

                                      getStaticContentText(
                                        "xgVxV8TR5W",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "p61HrjyPr5",
                                      "uIqG7QbHHX",
                                      "SGPLaP0VWQ",
                                    ],
                                  ]}
                                  disabled={getValue("jPjVV4rSN1") === "No"}
                                  disabledFieldIndexes={[0, 1]}
                                ></Tabular>
                              )}
                            </Flex>

                            <FlexRow align="flex-start">
                              <LegendSquare color="#809980" />
                              <Flex flex={1}>
                                <Text fontSize={10}>
                                  {parse(
                                    getStaticContentText(
                                      "chxQtWvzke",
                                      staticContent.staticText,
                                      document.language
                                    )
                                  )}
                                </Text>
                              </Flex>
                              <br />
                            </FlexRow>
                            <FlexRow align="flex-start">
                              <LegendSquare color="#4d704d" />
                              <Flex flex={1}>
                                <Text fontSize={10}>
                                  {/* Taxonomy aligned:Nuclear */}
                                  {parse(
                                    getStaticContentText(
                                      "Zffi9KIfT5",
                                      staticContent.staticText,
                                      document.language
                                    )
                                  )}
                                </Text>
                              </Flex>
                            </FlexRow>
                            <FlexRow align="flex-start">
                              <LegendSquare color="#0a3300" />
                              <Flex flex={1}>
                                <Text fontSize={10}>
                                  {/* Taxonomy-aligned (no fossil gas & nuclear) */}
                                  {parse(
                                    getStaticContentText(
                                      "RXtpexllNu",
                                      staticContent.staticText,
                                      document.language
                                    )
                                  )}
                                </Text>
                              </Flex>
                            </FlexRow>
                            <FlexRow align="flex-start">
                              <LegendSquare color="#d9d9d9" />
                              <Flex flex={1}>
                                <Text fontSize={10}>
                                  {/* Non Taxonomy-aligned */}
                                  {parse(
                                    getStaticContentText(
                                      "xgVxV8TR5W",
                                      staticContent.staticText,
                                      document.language
                                    )
                                  )}
                                </Text>
                              </Flex>
                            </FlexRow>
                          </FlexColumn>
                        </FlexColumn>
                      </GraphSubContainer>
                      <GraphSubContainer>
                        <FlexColumn>
                          <Text center fontSize={12}>
                            {parse(
                              getStaticContentText(
                                "zv5Zt2eoeC",
                                staticContent.staticText,
                                document.language
                              )
                            )}
                            {/* 1. Taxonomy-alignment of investments
                              <Bold>excluding sovereign bonds*</Bold> */}
                          </Text>
                          <FlexColumn paddingTop={10}>
                            <Flex>
                              <BarChart
                                labels={barCharLabels}
                                dataValue={[
                                  {
                                    // label: "Taxonomy-aligned: Fossil gas",
                                    label: getStaticContentText(
                                      "chxQtWvzke",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("cLMXNErpLx"),
                                      getValue("Vsz4WtWWOq"),
                                      getValue("eCFaoWSGWu"),
                                    ],
                                    color: "#809980",
                                  },
                                  {
                                    // label: "Taxonomy-aligned: Nuclear",
                                    label: getStaticContentText(
                                      "Zffi9KIfT5",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("PxtylL4phn"),
                                      getValue("ZqcMr44CWg"),
                                      getValue("nv0uz7vMJ3"),
                                    ],
                                    color: "#4d704d",
                                  },
                                  {
                                    // label:
                                    //   "Taxonomy-aligned (no gas and nuclear)",
                                    label: getStaticContentText(
                                      "RXtpexllNu",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("jWHjk4CbbX"),
                                      getValue("qTMA2fKcvn"),
                                      getValue("yVQ7E2g8Yl"),
                                    ],
                                    color: "#0a3300",
                                  },
                                  {
                                    // label: "Non Taxonomy-aligned",
                                    label: getStaticContentText(
                                      "xgVxV8TR5W",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    values: [
                                      getValue("B3tQAj2rDN"),
                                      getValue("jXvToL4j1p"),
                                      getValue("L3Kk6y1z4m"),
                                    ],
                                    color: "#d9d9d9",
                                  },
                                ]}
                                stepSize={50}
                              />
                            </Flex>
                            <Flex>
                              {isEditMode && (
                                <Tabular
                                  id="right-table"
                                  // headers={["", "Turnover", "CapEx", "OpEx"]}
                                  headers={[
                                    "",

                                    getStaticContentText(
                                      "TFs1iWMg9P",
                                      staticContent.staticText,
                                      document.language
                                    ),

                                    getStaticContentText(
                                      "UeefNSjL5R",
                                      staticContent.staticText,
                                      document.language
                                    ),

                                    getStaticContentText(
                                      "jr5sewetYY",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                  ]}
                                  data={[
                                    [
                                      getStaticContentText(
                                        "chxQtWvzke",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "cLMXNErpLx",
                                      "Vsz4WtWWOq",
                                      "eCFaoWSGWu",
                                    ],
                                    [
                                      getStaticContentText(
                                        "Zffi9KIfT5",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "PxtylL4phn",
                                      "ZqcMr44CWg",
                                      "nv0uz7vMJ3",
                                    ],
                                    [
                                      // "Taxonomy-aligned (no gas and nuclear)",

                                      getStaticContentText(
                                        "RXtpexllNu",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "jWHjk4CbbX",
                                      "qTMA2fKcvn",
                                      "yVQ7E2g8Yl",
                                    ],
                                    [
                                      // "Non Taxonomy-aligned",

                                      getStaticContentText(
                                        "xgVxV8TR5W",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      "B3tQAj2rDN",
                                      "jXvToL4j1p",
                                      "L3Kk6y1z4m",
                                    ],
                                  ]}
                                  disabled={getValue("jPjVV4rSN1") === "No"}
                                  disabledFieldIndexes={[0, 1]}
                                ></Tabular>
                              )}
                            </Flex>
                            <FlexRow align="flex-start">
                              <LegendSquare color="#809980" />
                              <Flex flex={1}>
                                <Text wordBreak="normal" fontSize={10}>
                                  {/* Taxonomy-aligned:Fossil gas */}
                                  {parse(
                                    getStaticContentText(
                                      "chxQtWvzke",
                                      staticContent.staticText,
                                      document.language
                                    )
                                  )}
                                </Text>
                              </Flex>
                              <br />
                            </FlexRow>
                            <FlexRow align="flex-start">
                              <LegendSquare color="#4d704d" />
                              <Flex flex={1}>
                                <Text wordBreak="normal" fontSize={10}>
                                  {/* Taxonomy aligned:Nuclear */}
                                  {parse(
                                    getStaticContentText(
                                      "Zffi9KIfT5",
                                      staticContent.staticText,
                                      document.language
                                    )
                                  )}
                                </Text>
                              </Flex>
                            </FlexRow>
                            <FlexRow align="flex-start">
                              <LegendSquare color="#0a3300" />
                              <Flex flex={1}>
                                <Text wordBreak="normal" fontSize={10}>
                                  {/* Taxonomy-aligned (no fossil gas & nuclear) */}
                                  {parse(
                                    getStaticContentText(
                                      "RXtpexllNu",
                                      staticContent.staticText,
                                      document.language
                                    )
                                  )}
                                </Text>
                              </Flex>
                            </FlexRow>
                            <FlexRow align="flex-start">
                              <LegendSquare color="#d9d9d9" />
                              <Flex flex={1}>
                                <Text wordBreak="normal" fontSize={10}>
                                  {/* Non Taxonomy-aligned */}
                                  {parse(
                                    getStaticContentText(
                                      "xgVxV8TR5W",
                                      staticContent.staticText,
                                      document.language
                                    )
                                  )}
                                </Text>
                              </Flex>
                            </FlexRow>
                            <GraphFooter>
                              <Text wordBreak="normal" fontSize={12}>
                                {
                                  getQuestionLabel(
                                    "pBfhwu3hBz",
                                    displayQuestions,
                                    Language?.[document.language]
                                  )?.[0]
                                }
                                &nbsp;
                                <NumberInput
                                  displayStyle={"inline"}
                                  value={getValue("pBfhwu3hBz")}
                                  onChange={(val) =>
                                    updateAnswer("pBfhwu3hBz", val)
                                  }
                                  id="pBfhwu3hBz"
                                  maxLimit={100}
                                />
                                &nbsp;
                                {
                                  getQuestionLabel(
                                    "pBfhwu3hBz",
                                    displayQuestions,
                                    Language?.[document.language]
                                  )?.[1]
                                }
                              </Text>
                            </GraphFooter>
                          </FlexColumn>
                        </FlexColumn>
                      </GraphSubContainer>
                    </GraphContainer>
                    <RegulatoryExternalLinkComponent
                      linkId={REGULATORY_EXTERNAL_LINK_ID}
                      hidden={!isEditMode}
                      document={document}
                      staticContent={commonContent}
                    />
                    <Label isItalic isBold fontSize={12}>
                      {parse(
                        getStaticContentText(
                          "nb795yPfcb",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                      {/* *For the purpose of these graphs, ‘sovereign bonds’
                      consist of all sovereign exposures */}
                    </Label>
                  </OrangeBox>
                  <QuestionContainer paddingLeft={0}>
                    <QuestionText fontSize={14}>
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {parse(
                          getStaticContentText(
                            "Wahkv7sEjF",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                        {/* [include a breakdown of the proportions of investments
                        during the reference period] */}
                      </InfoToolTip>
                      <QuestionLabel
                        isBold
                        displayQuestions={displayQuestions}
                        questionId="vlP32eife7"
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("vlP32eife7")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("vlP32eife7")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["vlP32eife7"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("vlP32eife7")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="vlP32eife7"
                      disabled={!isEditMode}
                      innerHTML={getValue("vlP32eife7")}
                      onChange={(ans) => updateAnswer("vlP32eife7", ans)}
                    />
                  </QuestionContainer>
                </QuestionContainer>
              )}
              {isEditMode && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={0}
                >
                  <QuestionText fontSize={14}>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        displayQuestions={displayQuestions}
                        questionId="vLCJ7eJtl7"
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("vLCJ7eJtl7")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("vLCJ7eJtl7")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["vLCJ7eJtl7"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("vLCJ7eJtl7")}
                    />
                  </QuestionText>
                  <FlexRow paddingTop={5}>
                    <Checkbox
                      id="28"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("vLCJ7eJtl7") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "vLCJ7eJtl7",
                          getValue("vLCJ7eJtl7") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="29"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("vLCJ7eJtl7") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "vLCJ7eJtl7",
                          getValue("vLCJ7eJtl7") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("vLCJ7eJtl7") === "Yes" && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={0}
                >
                  <QuestionText fontSize={14} isBold>
                    <GreyDot />
                    <InfoToolTip disabled={!showInfoIcon}>
                      {parse(
                        getStaticContentText(
                          "8Y1hvTlVDJ",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                      {/* [include where at least one previous periodic
                      report was provided] */}
                    </InfoToolTip>
                    <QuestionLabel
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="Eg2lY7JpLa"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("Eg2lY7JpLa")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("Eg2lY7JpLa")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["Eg2lY7JpLa"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("Eg2lY7JpLa")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="Eg2lY7JpLa"
                    disabled={!isEditMode}
                    innerHTML={getValue("Eg2lY7JpLa")}
                    onChange={(ans) => updateAnswer("Eg2lY7JpLa", ans)}
                  />
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("G57HNyencT") === "Yes" && (
              <GreyBox>
                <InfoToolTip disabled={!showInfoIcon}>
                  {parse(
                    getStaticContentText(
                      "SSQfQiuILs",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                  {/* [include note for the financial products referred to in
                  Article 6, first paragraph, of Regulation (EU) 2020/852 that
                  invest in environmental economic activities that are not
                  environmentally sustainable economic activities] */}
                  {/* {parse(
                    getStaticContentText(
                      "65gUH1ZjZt",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )} */}
                </InfoToolTip>
                <RelativeContainer>
                  <Block>
                    <Image
                      name="noBook"
                      height={40}
                      width={40}
                      style={{ float: "left", paddingRight: 10 }}
                    />
                    {parse(
                      getStaticContentText(
                        "2Kk9d9LK9r",
                        staticContent.staticText,
                        document.language
                      )
                    )}
                    {/* are sustainable investments with an environmental objective
                    that <Bold> do not take into account the criteria </Bold>                    for environmentally sustainable economic activities under
                    the EU Taxonomy. */}
                  </Block>
                </RelativeContainer>
              </GreyBox>
            )}
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            {isEditMode && (
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold fontSize={14}>
                  <Highlight>
                    <QuestionLabel
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="G57HNyencT"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("G57HNyencT")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("G57HNyencT")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["G57HNyencT"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("G57HNyencT")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="30"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("G57HNyencT") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "G57HNyencT",
                        getValue("G57HNyencT") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="31"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("G57HNyencT") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "G57HNyencT",
                        getValue("G57HNyencT") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("G57HNyencT") === "Yes" && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <SubIcon name="noBook" />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {parse(
                      getStaticContentText(
                        "1eP98tUVi1",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                    {/* [include section only for the financial products referred to
                    in Article 6, first subparagraph, of Regulation (EU)
                    2020/852 where the financial product included sustainable
                    investments with an environmental objective that invested in
                    economic activities that are not environmentally sustainable
                    economic activities, and explain why the financial product
                    invested in economic activities that were not
                    Taxonomy-aligned] */}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    displayQuestions={displayQuestions}
                    questionId="FRHvRQAHCD"
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("FRHvRQAHCD")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("FRHvRQAHCD")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["FRHvRQAHCD"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("FRHvRQAHCD")}
                  />
                </QuestionText>
                <QuillEditor
                  id="FRHvRQAHCD"
                  disabled={!isEditMode}
                  innerHTML={getValue("FRHvRQAHCD")}
                  onChange={(ans) => updateAnswer("FRHvRQAHCD", ans)}
                />
              </QuestionContainer>
            )}
            {isEditMode && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <Highlight>
                    <QuestionLabel
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="U9ZfHVYLDs"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("U9ZfHVYLDs")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("U9ZfHVYLDs")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["U9ZfHVYLDs"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("U9ZfHVYLDs")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="32"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("U9ZfHVYLDs") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "U9ZfHVYLDs",
                        getValue("U9ZfHVYLDs") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="33"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("U9ZfHVYLDs") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "U9ZfHVYLDs",
                        getValue("U9ZfHVYLDs") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("U9ZfHVYLDs") === "Yes" && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <SubIcon name="users" />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include only where the financial product included
                    sustainable investments with a social objective] */}
                    {parse(
                      getStaticContentText(
                        "I9mTKYKGWT",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    displayQuestions={displayQuestions}
                    questionId="rWnBSIzgmN"
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("rWnBSIzgmN")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("rWnBSIzgmN")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["rWnBSIzgmN"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("rWnBSIzgmN")}
                  />
                </QuestionText>
                <QuillEditor
                  id="rWnBSIzgmN"
                  disabled={!isEditMode}
                  innerHTML={getValue("rWnBSIzgmN")}
                  onChange={(ans) => updateAnswer("rWnBSIzgmN", ans)}
                />
              </QuestionContainer>
            )}
            <QuestionContainer
              paddingLeft={0}
              paddingTop={subQuestionTopPadding}
            >
              <QuestionText isBold fontSize={14}>
                <SubIcon name="globeNo" />
                <InfoToolTip disabled={!showInfoIcon}>
                  {parse(
                    getStaticContentText(
                      "1KwEZHEZko",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                  {/* [provide a description of the above and explain how the
                  proportion and use of such investments does not affect the
                  delivery of the sustainable investment objective] */}
                </InfoToolTip>
                <QuestionLabel
                  isBold
                  displayQuestions={displayQuestions}
                  questionId="zmCNfQWEIO"
                  language={document.language}
                  getQuestionError={getQuestionError}
                  showErrorMessage={showErrorMessage}
                />
                <CommentButton
                  hasComments={hasCommentsById("zmCNfQWEIO")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("zmCNfQWEIO")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["zmCNfQWEIO"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("zmCNfQWEIO")}
                />
              </QuestionText>
              <QuillEditor
                id="zmCNfQWEIO"
                disabled={!isEditMode}
                innerHTML={getValue("zmCNfQWEIO")}
                onChange={(ans) => updateAnswer("zmCNfQWEIO", ans)}
              />
            </QuestionContainer>
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="humanArrow" size={65} />
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {parse(
                      getStaticContentText(
                        "N1Nfk6Zh2C",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                    {/* [list the actions taken within in the period covered by the
                    periodic report to meet the environmental or social
                    characteristics promoted by the financial product, including
                    shareholder engagement as referred to in Article 3g of
                    Directive 2007/36/EC and any other engagement relating to the
                    environmental or social characteristics promoted by the
                    financial product] */}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    displayQuestions={displayQuestions}
                    questionId="r8K4E67xgR"
                    language={document.language}
                    getQuestionError={getQuestionError}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("r8K4E67xgR")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("r8K4E67xgR")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["r8K4E67xgR"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("r8K4E67xgR")}
                  />
                </QuestionText>
                <QuillEditor
                  id="r8K4E67xgR"
                  disabled={!isEditMode}
                  innerHTML={getValue("r8K4E67xgR")}
                  onChange={(ans) => updateAnswer("r8K4E67xgR", ans)}
                />
              </QuestionContainer>
              {isEditMode && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText isBold>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        displayQuestions={displayQuestions}
                        questionId="RzSEOCJFpz"
                        language={document.language}
                        getQuestionError={getQuestionError}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <CommentButton
                      hasComments={hasCommentsById("RzSEOCJFpz")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("RzSEOCJFpz")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["RzSEOCJFpz"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("RzSEOCJFpz")}
                    />
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  </QuestionText>
                  <FlexRow paddingTop={5}>
                    <Checkbox
                      id="34"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("RzSEOCJFpz") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "RzSEOCJFpz",
                          getValue("RzSEOCJFpz") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="35"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("RzSEOCJFpz") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "RzSEOCJFpz",
                          getValue("RzSEOCJFpz") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("RzSEOCJFpz") === "Yes" && (
              <>
                <MainIcon name="graphs" />
                <GreyBox>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {parse(
                      getStaticContentText(
                        "OaDdKBgvnV",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                    {/* [include note for financial products where an index has been
                    designated as a reference benchmark for the purpose of
                    attaining the environmental or social characteristics
                  promoted by the financial product] */}
                  </InfoToolTip>
                  {parse(
                    getStaticContentText(
                      "YrKx7hj3ag",
                      staticContent.greyBox,
                      document.language
                    )
                  )}
                  {/* <Bold>Reference benchmarks </Bold>                  are indexes to measure whether the financial product attains
                  the environmental or social characteristics that they promote. */}
                </GreyBox>
              </>
            )}
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            {getValue("RzSEOCJFpz") === "Yes" && (
              <>
                <QuestionContainer paddingLeft={0}>
                  <QuestionText isBold>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {parse(
                        getStaticContentText(
                          "1e7OBqBoaA",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                      {/* [include section where an index has been designated as a
                      reference benchmark for the purpose of attaining the
                      environmental or social characteristics promoted by the
                      financial product and indicate where the methodology used
                      for the calculation of the designated index can be found] */}
                    </InfoToolTip>
                    <QuestionLabel
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="IAywFVLM8A"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("IAywFVLM8A")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("IAywFVLM8A")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["IAywFVLM8A"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("IAywFVLM8A")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="IAywFVLM8A"
                    disabled={!isEditMode}
                    innerHTML={getValue("IAywFVLM8A")}
                    onChange={(ans) => updateAnswer("IAywFVLM8A", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="eKkmecA8Gc"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("eKkmecA8Gc")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("eKkmecA8Gc")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["eKkmecA8Gc"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("eKkmecA8Gc")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="eKkmecA8Gc"
                    disabled={!isEditMode}
                    innerHTML={getValue("eKkmecA8Gc")}
                    onChange={(ans) => updateAnswer("eKkmecA8Gc", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="a2BnTIV4oq"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("a2BnTIV4oq")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("a2BnTIV4oq")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["a2BnTIV4oq"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("a2BnTIV4oq")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="a2BnTIV4oq"
                    disabled={!isEditMode}
                    innerHTML={getValue("a2BnTIV4oq")}
                    onChange={(ans) => updateAnswer("a2BnTIV4oq", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="lQBZz8B6Ie"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("lQBZz8B6Ie")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("lQBZz8B6Ie")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["lQBZz8B6Ie"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("lQBZz8B6Ie")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="lQBZz8B6Ie"
                    disabled={!isEditMode}
                    innerHTML={getValue("lQBZz8B6Ie")}
                    onChange={(ans) => updateAnswer("lQBZz8B6Ie", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      displayQuestions={displayQuestions}
                      questionId="luQeqqNOM1"
                      language={document.language}
                      getQuestionError={getQuestionError}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("luQeqqNOM1")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("luQeqqNOM1")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["luQeqqNOM1"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("luQeqqNOM1")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="luQeqqNOM1"
                    disabled={!isEditMode}
                    innerHTML={getValue("luQeqqNOM1")}
                    onChange={(ans) => updateAnswer("luQeqqNOM1", ans)}
                  />
                </QuestionContainer>
              </>
            )}
          </QuestionContainer>
        }
      />
    </Container>
  );
};
export default ArticleEightPeriodic;
