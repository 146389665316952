import React from "react";
import Modal from "../../../../../components/Modal";
import { ButtonGroup, MainHeading, ScrollContainer } from "./styles";
import Image from "../../../../../components/Image";
import { ModalContentWrapper } from "../../../ActionButtons/styles";
import Button from "../../../../../components/Button";
import { BulkSelected } from "../../../../../types/listing-table";
import ListComponent from "./ListComponent";

type Props = {
  isOpen: boolean;
  selectedDocument: BulkSelected[];
  onClose?: () => void;
  onConfirm?: () => void;
};

const AmendmentConfirmationModal: React.FC<Props> = ({
  isOpen,
  selectedDocument,
  onClose,
  onConfirm,
}) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Amendment confirmation"
        width={650}
      >
        <ModalContentWrapper>
          <ScrollContainer>
            <MainHeading>
              <Image name="successCircleCheck" />
              <span>
                Below are the documents where changes will be applied:
              </span>
            </MainHeading>
            <ListComponent docs={selectedDocument} />
            <p>
              Please note that, changes once made cannot be undone, please check
              before submitting.
            </p>
            <p>Are you sure you want to apply the changes?</p>
          </ScrollContainer>
          <ButtonGroup>
            <Button buttonType="solid" onClick={onConfirm}>
              Confirm
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ButtonGroup>
        </ModalContentWrapper>
      </Modal>
    </div>
  );
};

export default AmendmentConfirmationModal;
