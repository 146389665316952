import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import PageSize from "../../../components/PageSize";
import SearchBox from "../../../components/Search";
import Table from "../../../components/Table";
import {
  ADMIN_URL_PREFIX,
  CLIENT_TABLE_HEADER,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../lib/constants";
import { fetchData } from "../../../services/apis";
import { getRowsForClientData } from "./service";
import Pagination from "../../../components/Pagination";
import NoDataFound from "../../../components/NoDataFoundComponent";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../../utils/common";
export type ClientList = {
  totalCount: number;
  filterCount: number;
  records: ClientDetail[];
};
type ClientDetail = {
  id: number;
  client_name: string;
  module_name: string;
  created_at: Date;
  updated_at: Date;
};
const Clients: React.FC = () => {
  const navigate = useNavigate();
  const initialState = {
    totalCount: 0,
    filterCount: 0,
    records: [],
  };
  const [clientList, setClientList] = useState<ClientList>(initialState);

  // const [showMessage, setShowMessage] = useState(false);
  // const [popUpMessage, setPopUpMessage] = useState("");
  const [filters, setFilters] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
    search: "",
    sort: {},
    filters: {},
  });

  useEffect(() => {
    (async function () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const clientListDetails: any = await fetchData({
        endPoint: "getClientsList",
        queryParam: filters,
      })
        .catch((e: Error) => {
          console.log(e);
          setClientList(initialState);
          notifyError("Error while fetching client list");
        })
        .finally(() => {
          // Loader.hideLoader();
        });
      setClientList({ ...clientListDetails.data.data });
    })();
  }, [filters]);
  function onBreadCrumbLinkChange(linkName: string) {
    // console.log("linkName", linkName);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSearchChange({ target }: { target: any }) {
    setFilters({
      ...filters,
      search: target.value,
      pageNumber: DEFAULT_PAGE_NUMBER,
    });
  }
  function onPageSizeChange({ target }: { target: any }) {
    setFilters({
      ...filters,
      pageSize: target.value,
      pageNumber: DEFAULT_PAGE_NUMBER,
    });
  }
  function onPaginationChange({ target }: { target: any }) {
    setFilters({ ...filters, pageNumber: target.value });
  }
  function handleSortChange(data: any) {
    setFilters({ ...filters, sort: data });
  }

  // function AddEMTApproverConfig(data: any) {
  //   // props.history.push("emtManager/emtApproverConfig", {
  //   //   clientName: data.clientName,
  //   //   clientId: data.id,
  //   //   selectedTab: "EMT Approvers",
  //   // });
  // }

  // function viewEmtList(viewRecord: any) {
  //   // appData.resetEMTListConfig();
  //   // props.history.push("/moduleManager", {
  //   //   clientName: viewRecord.clientName,
  //   //   clientId: viewRecord.id,
  //   // });
  // }
  function AddApproverConfig(data: any) {
    navigate(`${ADMIN_URL_PREFIX}clientsManager/approvers`, {
      state: data,
    });
  }

  return (
    <>
      <PageHeader
        count={`SFDR clients : ${(clientList && clientList.totalCount) || 0}`}
        title={"SFDR Manager"}
        breadCrumbDetails={{
          historyLinks: [
            { linkName: "Dashboard", url: "", isCurrent: false },
            { linkName: "SFDR Manager", url: "", isCurrent: true },
          ],
          onLinkClick: onBreadCrumbLinkChange,
        }}
      />
      <div className="content__body">
        <div className="container">
          <div className="tab-content">
            <div
              className="tab-content__panel active"
              data-assign="tabpanel"
              id="approved-kiids-panel"
              role="tabpanel"
            >
              <div className="page-actions">
                <div className="row">
                  <div className="col-auto col-fix-right">
                    <SearchBox handleChange={onSearchChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {clientList && clientList.records && clientList.records.length > 0 ? (
            <>
              <div className="page-body">
                <Table
                  tableHeaders={CLIENT_TABLE_HEADER}
                  tableRows={getRowsForClientData(clientList.records)}
                  sortOnColumns={["Client Name"]}
                  onSortChange={handleSortChange}
                  //getBulkSelect={handleBulkSelect}
                  onEdit={AddApproverConfig}
                />
              </div>

              <div className="page-footer">
                <div className="row row--space-between">
                  <div className="col-auto">
                    <PageSize
                      pageSize={filters.pageSize.toString()}
                      handleChange={onPageSizeChange}
                    />
                  </div>
                  <div className="col-auto">
                    <nav aria-label="Page navigation">
                      <Pagination
                        pageSize={filters.pageSize}
                        totalRecords={clientList.filterCount}
                        handleChange={onPaginationChange}
                      />
                    </nav>
                  </div>
                </div>
              </div>
            </>
          ) : (
            clientList && <NoDataFound />
          )}
        </div>
      </div>
      {/* {showMessage && (
        <InformationBox
          header={<h3>Not Allowed</h3>}
          onModalClose={(e) => {
            setShowMessage(false);
            setPopUpMessage("");
          }}
          showModal={showMessage}
          config={{ style: { height: "50vh", width: "50%" } }}
          body={<div>{popUpMessage || ""}</div>}
        />
      )} */}
    </>
  );
};

export default Clients;
