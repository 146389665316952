import React from "react";
import ReactSelect, { GroupBase, Props, StylesConfig } from "react-select";
import { spacingIncrement } from "../../utils/common";
import Image from "../Image";
import { DropDownContainer, Label, SelectWrapper } from "./styles";

export type Option = {
  label: string;
  value: string;
};

const customStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  control: (provided) => ({
    ...provided,
    border: "1px solid #d9dae3",
    boxSizing: "border-box",
    borderRadius: spacingIncrement(5),
    height: spacingIncrement(42),
  }),
  multiValue: (provided) => ({
    ...provided,
    background: "rgba(246, 246, 246, 0.51)",
    border: "1px solid #DEE2E6",
    borderRadius: "2px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#303245",
    fontSize: spacingIncrement(16),
    fontWeight: 400,
    padding: spacingIncrement(2),
  }),
};

const Select: React.FC<Props<Option> & { label?: string }> = ({
  label,
  ...otherProps
}) => {
  const id = label?.replaceAll(" ", "-").toLowerCase();
  return (
    <SelectWrapper>
      {label && <Label>{label}</Label>}
      <ReactSelect
        maxMenuHeight={200}
        id={`${id}-content`}
        inputId={`${id}-input`}
        instanceId={label}
        closeMenuOnSelect={!otherProps.isMulti}
        components={{
          DropdownIndicator: () => (
            <DropDownContainer>
              <Image name="downArrow" />
            </DropDownContainer>
          ),
        }}
        {...otherProps}
        styles={{
          ...customStyles,
          ...otherProps?.styles,
        }}
      />
    </SelectWrapper>
  );
};

export default Select;
