import React, { useMemo } from "react";
import AnimatedPieChart from "../../../../components/AnimatedPieChart";

type Props = {
  chartData: ChartDataset[];
};

type ChartDataset = {
  label: string;
  value: number;
  colour: string;
};

const PieChart: React.FC<Props> = ({ chartData }) => {
  const values = chartData.map(({ value }) => (value ? Number(value) : 0));
  const colours = chartData.map(({ colour }) => colour);
  const t = values.reduce((a,b) => a + b, 0);
  
  const data = useMemo(() => {
    return values.map((value, i) => ({ value, group: i < values.length - 1 ? "A" : "B" }))
  }, [...values])
  return (
    <>
      <AnimatedPieChart
        hideChart={!t}
        height={250}
        width={250}
        data={data}
        groupConfig={{
          A: {
            showPercentage: true,
            suffix: "%",
            textColor: "black",
            groupTextColor: "#0a3300",
            showIndividualPercentageOutside : true,
            showArc: true
          },
          B: {
            showPercentage: false,
            showIndividualPercentageOutside: false,
            showArc: false,
            suffix: "%"
          }
        }}
        groupColors={["#0a3300", "#D9D9D9"]}
        colors={colours}
      />
    </>
  );
};

export default PieChart;
