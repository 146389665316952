import React from "react";
import { ImageProps } from "../types";

const ArrowRight: React.FC<ImageProps> = ({
  color = "#0ABAEE",
  ...otherProps
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M6 1.5L12 7.5L6 13.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowRight;
