import { FC, useContext, useEffect, useState } from "react";
import { ExtendedBulkSelect } from "../../../types/listing-table";
import _ from "lodash";
import { getApprovalLevelStatus } from "../../../services/listing";
import { getNextApprovalGroupLevel } from "../../../utils/bulkApproval";
import { GLOBAL_CONTEXT } from "../../../types/store";
import Context from "../../../store/context";
import { postData } from "../../../services/apis";
import {
  ApprovalDetails,
  ApprovalGroup,
} from "../../../types/document-details";
import { toast } from "react-toastify";
import { notifyError } from "../../../utils/common";

type Props = {
  documents: ExtendedBulkSelect[];
  onClose: () => void;
  updateDocList: () => void;
};

type FinalData = {
  documentIds: string[];
  approvalGroup: (ApprovalGroup | undefined)[];
  comment: string;
  clientId: number | undefined;
};

export const ApprovalLevels: FC<Props> = ({
  documents,
  updateDocList,
  onClose,
}) => {
  // Important:
  // Currently we have same approvalGroups for documents across a client
  // This will be changed when we will implement different approvalGroups for documents
  const {
    globalState: { userDetails, selectedFund },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [actionDone, setActionDone] = useState(false);
  const [{ approvalGroups }] = documents;
  function attachNextApprovalGroupLevel(d: ExtendedBulkSelect) {
    return {
      ...d,
      nextApprovalGroupLevel: getNextApprovalGroupLevel(
        approvalGroups,
        d.approvalDetails
      ),
    };
  }
  const [selectedDocuments, setSelectedDocuments] = useState(
    documents.map(attachNextApprovalGroupLevel)
  );
  useEffect(() => {
    updateGroupByApprovalDetails();
  }, [selectedDocuments]);
  const [groupByApprovalDetails, setGroupByApprovalDetails] = useState<{
    [key: string]: ExtendedBulkSelect[];
  }>({});
  const [selectedDocs, setSelectedDocs] = useState<{ [key: string]: string[] }>(
    {}
  );
  const updateGroupByApprovalDetails = () => {
    let update: { [key: string]: ExtendedBulkSelect[] } = {};
    approvalGroups.forEach((ad) => {
      update = {
        ...update,
        [ad.groupId]: selectedDocuments.filter(
          (d) =>
            (d as any).nextApprovalGroupLevel === ad.approvalLevel &&
            !d.approvalDetails.find((a) => a.groupId === ad.groupId)
        ),
      };
    });
    setGroupByApprovalDetails(update);
    let updateSelectedDocs = {};
    _.mapKeys(update, (value, key) => {
      updateSelectedDocs = {
        ...updateSelectedDocs,
        [key]: value.map((d) => d.id),
      };
    });
    setSelectedDocs(updateSelectedDocs);
  };
  const [comments, setComments] = useState<{ [key: string]: string }>({});
  function onCommentChange(comment: string, key: string) {
    setComments({
      ...comments,
      [key]: comment,
    });
  }
  const [confirm, setConfirm] = useState<{ [key: string]: boolean }>({});
  const onCheckBoxChange = (e: any, key: string, id: string) => {
    const checked = e.target.checked;
    setSelectedDocs({
      ...selectedDocs,
      [key]: checked
        ? [...(selectedDocs?.[key] || []), id]
        : selectedDocs?.[key].filter((d) => d !== id),
    });
  };
  function onSelectAllClick(checked: boolean, key: string) {
    setSelectedDocs({
      ...selectedDocs,
      [key]: checked ? groupByApprovalDetails?.[key].map((d) => d.id) : [],
    });
  }
  function onConfirmCheckboxClick(checked: boolean, key: string) {
    setConfirm({
      ...confirm,
      [key]: checked,
    });
  }
  function handleNextStep(
    documentIds: string[],
    data: FinalData,
    genericComment: string,
    headerKey: string
  ) {
    const [approvalGroup] = data.approvalGroup;
    let updated: ExtendedBulkSelect[] = selectedDocuments.map((s) => {
      if (documentIds.includes(s.id)) {
        return {
          ...s,
          approvalDetails: [
            ...s.approvalDetails,
            {
              groupId: approvalGroup?.groupId,
              approvalLevel: approvalGroup?.approvalLevel,
              comment: genericComment,
            } as unknown as ApprovalDetails,
          ],
        };
      }
      return s;
    });
    updated = updated.map(attachNextApprovalGroupLevel);
    setSelectedDocuments(updated as []);
    toggleDropdown(headerKey);
    toast.success(
      `Documents approved by '${approvalGroup?.approvalGroupTitle}'`,
      { toastId: "success" }
    );
  }
  function onApproveClick(key: string, headerKey: string) {
    const documentIds = selectedDocs?.[key];
    const approvalGroup = approvalGroups.find((ag) => ag.groupId === key);
    const genericComment = comments?.[key];

    const data: FinalData = {
      documentIds,
      approvalGroup: [approvalGroup],
      comment: genericComment,
      clientId: selectedFund?.client_id,
    };
    postData({ endPoint: "bulkStatusUpdate/approved", data })
      .then((res) => {
        if (res.status !== 200 || !res.data?.success) {
          notifyError(res.data?.message || "Unknown Error");
          return;
        }
        handleNextStep(documentIds, data, genericComment, headerKey);
        updateDocList();
        if (!actionDone) setActionDone(true);
      })
      .catch((err) => {
        toast.error(err?.message || "Something went wrong", {
          autoClose: 3000,
        });
      });
    setComments({ ...comments, [key]: "" });
    setConfirm({ ...confirm, [key]: false });
  }
  function toggleDropdown(headerKey: string) {
    document.getElementById(headerKey)?.click();
  }
  const ApprovalGroupLists = Object.keys(groupByApprovalDetails)
    .map((key, index) => {
      const docs = groupByApprovalDetails[key];
      if (docs.length === 0) return null;
      const nextApprovalGroup = approvalGroups.find((ag) => ag.groupId === key);
      // If user is not an admin user
      if (userDetails?.userType !== "admin") {
        // and if user is not part of next approval group
        // then don't show this approval group
        if (
          !nextApprovalGroup?.approvalUsers.find(
            (user) => user.value === userDetails?.userId
          )
        ) {
          return null;
        }
      }
      const id = `level-${key}`;
      const headerKey = `header-${key}`;
      const disableApproveButton =
        !selectedDocs?.[key] ||
        selectedDocs[key]?.length === 0 ||
        !confirm?.[key];
      return (
        <div
          key={key}
          className="card card--accordion-list sfdr-accordian__card"
        >
          <div className="card__accordion-header" role="tab">
            <div className="card__header sfdr-accordian-card__header">
              <a
                className="card-onclick__target collapsed"
                data-toggle="collapse"
                href={`#${id}`}
                id={headerKey}
                aria-expanded="false"
                aria-controls={id}
              >
                <div className="card__title sfdr-accordian__card__title">
                  {nextApprovalGroup?.approvalGroupTitle}{" "}
                  <span>({docs.length} Documents)</span>
                </div>
                <h6 className="sub-detail">
                  Level {nextApprovalGroup?.approvalLevel}
                </h6>
                <div className="status-right">
                  <span>{getApprovalLevelStatus(docs[0])}</span>
                </div>
              </a>
            </div>
          </div>
          <div id={id} className="collapse" role="tabpanel">
            <div className="sfdr-card-body max-scroll-full">
              <div
                className="is-switch mb-1"
                data-switchgroup="sfdr-select"
                data-switchtype="all"
                data-switchstate="unchecked"
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`select-all-checkbox-${index + 1}`}
                    checked={selectedDocs?.[key]?.length === docs.length}
                    onChange={(e) => {
                      onSelectAllClick(e.target.checked, key);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`select-all-checkbox-${index + 1}`}
                  >
                    Select All
                  </label>
                </div>
              </div>
              {docs.map((d) => {
                const checkboxId = `sfdr-checkgroup-4-${key}-${d.id}`;
                return (
                  <div
                    key={d.id}
                    className="is-switch"
                    data-switchgroup="sfdr-select"
                    data-switchtype="single"
                    data-statusid="5"
                  >
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        checked={selectedDocs?.[key]?.includes(d.id)}
                        onChange={(e) => {
                          onCheckBoxChange(e, key, d.id);
                        }}
                        className="custom-control-input"
                        id={checkboxId}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={checkboxId}
                      >
                        {d.fundName}/{d.subFundName}/{d.template}/{d.article}/
                        {d.language}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="sfdr-card-bottom">
              <div className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`sfdr-confirm-check-${index + 1}`}
                  checked={confirm?.[key]}
                  onChange={(e) => {
                    onConfirmCheckboxClick(e.target.checked, key);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`sfdr-confirm-check-${index + 1}`}
                >
                  Check to confirm that you want to approve this document.
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="counterparty">
                  General Comment{" "}
                  <span className="optional-text">(Optional)</span>
                </label>
                <textarea
                  className="form-control form-control--bordered"
                  cols={30}
                  rows={3}
                  value={comments?.[key]}
                  onChange={(e) => {
                    onCommentChange(e.target.value, key);
                  }}
                  placeholder="Enter Text"
                ></textarea>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  disabled={disableApproveButton}
                  onClick={() => {
                    onApproveClick(key, headerKey);
                  }}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    })
    .filter((d) => d !== null);

  if (ApprovalGroupLists.length === 0 && actionDone) {
    onClose();
    toast.success("Documents approved successfully", { toastId: "success" });
  }
  return (
    <>
      {ApprovalGroupLists.length > 0 ? (
        <div className="row level-mt">
          <div className="col-md-12 pl-0 pr-0">
            {ApprovalGroupLists.map((d) => d)}
          </div>
        </div>
      ) : (
        <div className="text-center mt-4">
          <h5>No documents available for approval</h5>
        </div>
      )}
      <div className="mt-4">
        <button onClick={onClose} className="btn btn-outline-primary">
          Cancel
        </button>
      </div>
    </>
  );
};
