import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const QuestionContainer = styled.div<{
    paddingLeft?: number;
    paddingTop?: number;
  }>`
    /* Flex property removed for page break issue */
    /* display: flex;
    flex-direction: column; */
    display: block;
    padding-left: ${({ paddingLeft }) =>
      spacingIncrement(typeof paddingLeft === "number" ? paddingLeft : 75)};
    padding-top: ${({ paddingTop }) =>
      spacingIncrement(typeof paddingTop === "number" ? paddingTop : 0)};
  `;