import React from "react";
import { ImageProps } from "../types";

const WritingPad: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="67.5" cy="67.5" r="67.5" fill="#9D9D9C" />
      <path
        d="M38 33.2258H53.352V39.0524H67.199H81.648V33.2258H97V110H38V33.2258Z"
        fill="#3C3C3B"
      />
      <path
        d="M54.8571 28.9947H61.6411V25H73.3589V28.9947H80.1428V37.65H54.8571V28.9947Z"
        fill="#3C3C3B"
      />
      <line
        x1="59.3674"
        y1="50.2339"
        x2="88.2755"
        y2="50.2339"
        stroke="#8D8D8C"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="59.3674"
        y1="55.7177"
        x2="88.2755"
        y2="55.7177"
        stroke="#8D8D8C"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <rect
        x="45.8265"
        y="48.9919"
        width="7.22449"
        height="8.22581"
        fill="#8D8D8C"
      />
      <ellipse
        cx="49.4388"
        cy="71.6129"
        rx="3.61224"
        ry="4.1129"
        fill="white"
      />
      <path
        d="M72.3163 100.375L75.1495 103.831L81.949 96.2903"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.8571 74.0121V74.0121C63.1212 78.7168 69.9067 85.6364 74.449 93.9908L75.3265 95.6048"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2 3"
      />
      <path
        d="M55.1066 94.8302L59.3887 91.6584M55.8548 90.8065L58.6404 95.6821"
        stroke="#9C9C9B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.949 78.3785L86.2311 75.2068M82.6972 74.3548L85.4829 79.2305"
        stroke="#9C9C9B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WritingPad;
