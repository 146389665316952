export type NodeConfig = {
  nodeId: string;
  nodeLabel: string;
  nodeLabelKey: string;
  percentage?: number;
  nonConditional?: boolean;
  nodeStyles?: { [key: string]: any };
  children?: NodeConfig[];
  isExpanded?: boolean;
  answer?: string;
};

type ChartConfig = {
  [key: string]: {
    [key: string]: NodeConfig;
  };
};

export const chartConfig: ChartConfig = {
  "Article 8": {
    Periodic: {
      nodeId: "FGxiFAEKFY",
      answer: "No",
      nodeLabel: "Investments",
      nodeLabelKey: "investments",
      nodeStyles: { bgColor: "#D9DAD8" },
      children: [
        {
          nodeId: "FGxiFAEKFY",
          nodeLabel: "#1 Aligned with E/S characteristics",
          nodeLabelKey: "es_characteristics",
          nodeStyles: {
            bgColor: "#009900",
            fontColor: "#000",
          },
          children: [
            {
              nodeId: "3UyXhAFGxP",
              nodeLabel: "#1A Sustainable",
              nodeLabelKey: "sustainable",
              nodeStyles: { bgColor: "#25563A", fontColor: "#fff" },
              children: [
                {
                  nodeId: "QofqZOb0pU",
                  nodeLabel: "Taxonomy-aligned",
                  nodeLabelKey: "taxonomy_aligned",
                  nodeStyles: { bgColor: "#003300", fontColor: "#fff" },
                },
                {
                  nodeId: "zFGpAr2nZW",
                  nodeLabel: "Other environmental",
                  nodeLabelKey: "other_environmental",
                  nodeStyles: { bgColor: "#008000" },
                },
                {
                  nodeId: "F1J4Jobh39",
                  nodeLabel: "Social",
                  nodeLabelKey: "social",
                  nodeStyles: { bgColor: "#5B9BD5" },
                },
              ],
            },
            {
              nodeId: "eEEtrD6TJO",
              nonConditional: true,
              nodeLabel: "#1B Other E/S characteristics",
              nodeLabelKey: "other_es_characteristics",
              nodeStyles: { bgColor: "#C4E0B4" },
            },
          ],
        },
        {
          nodeId: "fEf3EzCGjX",
          answer: "Yes",
          nodeLabel: "#2 Other",
          nodeLabelKey: "other",
          nodeStyles: { bgColor: "#D9DAD8" },
        },
      ],
    },
    "Pre-contractual": {
      nodeId: "Aeaa3tKRrK",
      answer: "No",
      nodeLabel: "Investments",
      nodeLabelKey: "investments",
      nodeStyles: { bgColor: "#D9DAD8" },
      children: [
        {
          nodeId: "Aeaa3tKRrK",
          nodeLabel: "#1 Aligned with E/S characteristics",
          nodeLabelKey: "es_characteristics",
          nodeStyles: {
            bgColor: "#009900",
            fontColor: "#000",
          },
          children: [
            {
              nodeId: "nORKI569La",
              nodeLabel: "#1A Sustainable",
              nodeLabelKey: "sustainable",
              nodeStyles: { bgColor: "#25563A", fontColor: "#fff" },
              children: [
                {
                  nodeId: "OcdWvsdW2r",
                  nodeLabel: "Taxonomy-aligned",
                  nodeLabelKey: "taxonomy_aligned",
                  nodeStyles: { bgColor: "#003300", fontColor: "#fff" },
                },
                {
                  nodeId: "U8b8KKCTvG",
                  nodeLabel: "Other environmental",
                  nodeLabelKey: "other_environmental",
                  nodeStyles: { bgColor: "#008000" },
                },
                {
                  nodeId: "hnJpcPqq4l",
                  nodeLabel: "Social",
                  nodeLabelKey: "social",
                  nodeStyles: { bgColor: "#5B9BD5" },
                },
              ],
            },
            {
              nodeId: "LMlKBsTv48",
              nonConditional: true,
              nodeLabel: "#1B Other E/S characteristics",
              nodeLabelKey: "other_es_characteristics",
              nodeStyles: { bgColor: "#C4E0B4" },
            },
          ],
        },
        {
          nodeId: "abyhBSRw26",
          answer: "Yes",
          nodeLabel: "#2 Other",
          nodeLabelKey: "other",
          nodeStyles: { bgColor: "#D9DAD8" },
        },
      ],
    },
  },
  "Article 9": {
    Periodic: {
      nodeId: "rsEnn3lmD3",
      answer: "Yes",
      nodeLabel: "Investments",
      nodeLabelKey: "investments",
      nodeStyles: { bgColor: "#D9DAD8" },
      children: [
        {
          nodeId: "rsEnn3lmD3",
          answer: "Yes",
          nodeLabel: "#1 Sustainable",
          nodeLabelKey: "sustainable",
          nodeStyles: { bgColor: "#25563A", fontColor: "#fff" },
          children: [
            {
              nodeId: "DyJP6kgJvr",
              nodeLabel: "Environmental",
              nodeLabelKey: "environmental",
              nodeStyles: {
                bgColor: "#008000",
                fontColor: "#000",
              },
              children: [
                {
                  nodeId: "MyzbgUOTiu",
                  nodeLabel: "Taxonomy-aligned",
                  nodeLabelKey: "taxonomy_aligned",
                  nodeStyles: { bgColor: "#003300", fontColor: "#fff" },
                },
                {
                  nodeId: "SAkfVvzfm8",
                  nodeLabel: "Other",
                  nodeLabelKey: "other_environmental",
                  nodeStyles: { bgColor: "#088404" },
                },
              ],
            },
            {
              nodeId: "xPAw6f7A6l",
              nodeLabel: "Social",
              nodeLabelKey: "social",
              nodeStyles: { bgColor: "#5B9BD5" },
            },
          ],
        },
        {
          nodeId: "rbHhKIbpMu",
          answer: "Yes",
          nodeLabel: "#2 Not Sustainable",
          nodeLabelKey: "not_sustainable",
          nodeStyles: { bgColor: "#E0DCDC" },
        },
      ],
    },
    "Pre-contractual": {
      nodeId: "idFUxSpwa3",
      answer: "Yes",
      nodeLabel: "Investments",
      nodeLabelKey: "investments",
      nodeStyles: { bgColor: "#D9DAD8" },
      children: [
        {
          nodeId: "idFUxSpwa3",
          answer: "Yes",
          nodeLabel: "#1 Sustainable",
          nodeLabelKey: "sustainable",
          nodeStyles: { bgColor: "#25563A", fontColor: "#fff" },
          children: [
            {
              nodeId: "FKIXe3Q4Aq",
              nodeLabel: "Environmental",
              nodeLabelKey: "environmental",
              nodeStyles: {
                bgColor: "#008000",
                fontColor: "#000",
              },
              children: [
                {
                  nodeId: "g00MmYpUrZ",
                  nodeLabel: "Taxonomy-aligned",
                  nodeLabelKey: "taxonomy_aligned",
                  nodeStyles: { bgColor: "#003300", fontColor: "#fff" },
                },
                {
                  nodeId: "N5BtGG6Nb6",
                  nodeLabel: "Other",
                  nodeLabelKey: "other_environmental",
                  nodeStyles: { bgColor: "#088404" },
                },
              ],
            },
            {
              nodeId: "ywPFIQdKqf",
              nodeLabel: "Social",
              nodeLabelKey: "social",
              nodeStyles: { bgColor: "#5B9BD5" },
            },
          ],
        },
        {
          nodeId: "IOffmvozDd",
          answer: "Yes",
          nodeLabel: "#2 Not Sustainable",
          nodeLabelKey: "not_sustainable",
          nodeStyles: { bgColor: "#E0DCDC" },
        },
      ],
    },
  },
};
