import React from "react";
import { StyledInput } from "./styles";

type Props = {
  value: string;
  onChange?: (value: string) => void;
  maxLimit?: number;
  disabled?: boolean;
  placeholder?: string;
  id: string;
};

const Input: React.FC<Props> = ({
  value,
  onChange,
  maxLimit = undefined,
  disabled = false,
  placeholder = "",
  id,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };
  return (
    <StyledInput
      id={id}
      value={value || ""}
      onChange={handleChange}
      disabled={disabled}
      placeholder={placeholder}
      maxLength={maxLimit}
    />
  );
};

export default Input;
