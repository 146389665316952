import React from "react";
import { ImageProps } from "../types";

const DownloadBox: React.FC<ImageProps> = ({
  color = "#ffffff",
  ...otherProps
}) => {
  return (
    <svg width="111" height="116" viewBox="0 0 111 116" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
      <path d="M109.06 77.1492C107.368 77.1519 105.747 77.8252 104.55 79.0215C103.354 80.2178 102.681 81.8396 102.678 83.5314V98.8487C102.675 99.8635 102.271 100.836 101.553 101.553C100.836 102.271 99.8635 102.675 98.8487 102.678H17.1563C16.1415 102.675 15.1691 102.271 14.4515 101.553C13.734 100.836 13.3296 99.8635 13.3269 98.8487V83.5314C13.3269 81.8387 12.6545 80.2154 11.4576 79.0185C10.2607 77.8216 8.63739 77.1492 6.94472 77.1492C5.25205 77.1492 3.62871 77.8216 2.43181 79.0185C1.23491 80.2154 0.5625 81.8387 0.5625 83.5314V98.8487C0.567905 103.248 2.31791 107.466 5.42867 110.576C8.53944 113.687 12.757 115.437 17.1563 115.443H98.8487C103.248 115.437 107.466 113.687 110.576 110.576C113.687 107.466 115.437 103.248 115.442 98.8487V83.5314C115.44 81.8396 114.767 80.2178 113.57 79.0215C112.374 77.8252 110.752 77.1519 109.06 77.1492Z" fill="#0ABAEE"/>
      <path d="M58.0025 0.5625C56.3107 0.5652 54.6889 1.23848 53.4926 2.43479C52.2963 3.63111 51.623 5.25288 51.6203 6.94472V64.7983L31.4525 47.9952C30.1517 46.9136 28.4751 46.3922 26.7904 46.5454C25.1058 46.6986 23.5506 47.5137 22.4663 48.8121C21.3847 50.1128 20.8634 51.7894 21.0165 53.4741C21.1697 55.1588 21.9848 56.7139 23.2832 57.7983L53.9179 83.3272C54.2395 83.5552 54.5813 83.7535 54.939 83.9194C55.1723 84.0759 55.4163 84.2158 55.6692 84.3381C56.4054 84.648 57.1961 84.8077 57.9948 84.8077C58.7936 84.8077 59.5843 84.648 60.3205 84.3381C60.5671 84.2152 60.8059 84.0771 61.0353 83.9245C61.3929 83.7583 61.7347 83.5601 62.0565 83.3323L92.6912 57.8034C93.6981 56.9658 94.423 55.8387 94.7674 54.575C95.1119 53.3113 95.0592 51.9723 94.6165 50.7396C94.1738 49.5069 93.3626 48.4402 92.2929 47.6844C91.2233 46.9285 89.9469 46.5201 88.6372 46.5145C87.1447 46.5173 85.7001 47.041 84.5525 47.9952L64.3847 64.7983V6.94472C64.382 5.25288 63.7087 3.63111 62.5124 2.43479C61.3161 1.23848 59.6943 0.5652 58.0025 0.5625Z" fill="#0ABAEE"/>
    </svg>

  );
};

export default DownloadBox;