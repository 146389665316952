import { ChangeEvent, useContext, useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import { Label } from "../../../components/Select/styles";
import { uploadFile } from "../../../services/apis";
import Context from "../../../store/context";
import {
  DocumentArticle,
  DocumentArticleList,
  DocumentArticleListKeys,
  DocumentTemplate,
  DocumentTemplateList,
  DocumentTemplateListKeys,
} from "../../../types/document-details";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { ALLOWED_EXCEL_LANGUAGES, notifyError, notifySuccess } from "../../../utils/common";
import {
  ButtonWrapper,
  ContentContainer,
  DisabledContainer,
  ModalContentWrapper,
  RowContainer,
} from "./styles";
import UploadFile from "../../../components/UploadFile";
import { MainHeading } from "../BulkAmendmentSidePanel/AnswerReplace/Modals/styles";
import ListComponent from "../BulkAmendmentSidePanel/AnswerReplace/Modals/ListComponent";
import Image from "../../../components/Image";

const ValidFileTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];
const ImportExcelModal: React.FC<{
  isOpen: boolean;
  onCloseModal: () => void;
}> = ({ isOpen, onCloseModal }) => {
  const MAX_LENGTH = 10;
  const {
    globalState: { selectedFund, languages },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [article, setArticle] = useState<DocumentArticleListKeys>();
  const [template, setTemplate] = useState<DocumentTemplateListKeys>();
  const [selectedLanguage, setSelectedLanguages] = useState<string>();
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [showLockedDocumentError, setShowLockedDocumentError] = useState(false);
  const [lockedDocuments, setLockedDocuments] = useState([]);
  const handleCloseLockedDocumentError = () => {
    setShowLockedDocumentError(false);
    setLockedDocuments([]);
  };

  const articleOptions = DocumentArticleList.map((article) => ({
    label: DocumentArticle[article as DocumentArticleListKeys],
    value: article as DocumentArticleListKeys,
  }));
  const templateOptions = DocumentTemplateList.map((template) => ({
    label: DocumentTemplate[template as DocumentTemplateListKeys],
    value: template as DocumentTemplateListKeys,
  }));

  const languageOptions = languages.filter((languages) => {
    return ALLOWED_EXCEL_LANGUAGES.includes(languages.value);
  });
  const handleImport = async () => {
    if (!article || !template || !files || !selectedFund) {
      return;
    }
    setLoading(true);
    const reqBody = {
      article: DocumentArticle[article],
      template: DocumentTemplate[template],
      language: selectedLanguage,
      fundId: selectedFund.fund_level_one_id,
      fundName: selectedFund.name,
      clientId: selectedFund.client_id,
    };
    try {
      const resp = await uploadFile({
        endPoint: "excelImport",
        data: reqBody,
        file: [...files],
      });
      if (resp && resp.data?.success) {
        closeModal();
        if (resp.data?.lockedDocuments?.length) {
          setShowLockedDocumentError(true);
          setLockedDocuments(resp.data.lockedDocuments);
        } else {
          notifySuccess(
            <>
              The files have been successfully uploaded.
              <br />
              We are currently processing them. If the data is not imported
              shortly, please check your registered e-mail for any errors.
            </>,
            {
              width: "auto",
            }
          );
        }
      } else {
        throw new Error(resp.data.message);
      }
    } catch (e: any) {
      notifyError(e?.message);
    } finally {
      setLoading(false);
    }
  };
  const removeFile = (file: File) => {
    setFiles((currentFiles) =>
      [...currentFiles].filter(
        (f) => f.size !== file.size || f.name !== file.name
      )
    );
  };
  function clearAllFields() {
    setArticle(undefined);
    setTemplate(undefined);
    setSelectedLanguages(undefined);
    setFiles([]);
  }
  const closeModal = () => {
    onCloseModal();
    clearAllFields();
  };
  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      if (filesArray.length > MAX_LENGTH) {
        notifyError(`Cannot import files more than ${MAX_LENGTH}`);
        e.target.value = "";
        setFiles([]);
        return;
      }
      for (const file of filesArray) {
        if (!ValidFileTypes.includes(file.type)) {
          notifyError("Only Excel files are allowed");
          e.target.value = "";
          setFiles([]);
          return;
        }
      }
      setFiles(e.target.files as unknown as File[]);
    }
  };
  return (
    <>
      <Modal
        isOpen={showLockedDocumentError}
        onClose={handleCloseLockedDocumentError}
        header="Import action not applicable to locked documents."
      >
        <ModalContentWrapper>
          <MainHeading>
            <div>
              <Image name="failureCircle" />
            </div>
            <span>
              The following documents cannot be imported at the moment as they
              are locked by another user: Kindly unlock these documents and
              import again.
            </span>
          </MainHeading>
          <ListComponent docs={lockedDocuments} />
          <MainHeading>
            <span>
              Please note : The processing of files for unlocked documents is in
              progress. Changes will be reflected shortly.
            </span>
          </MainHeading>
          <div className="px-2 mt-3">
            <Button buttonType="solid" onClick={handleCloseLockedDocumentError}>
              Ok
            </Button>
          </div>
        </ModalContentWrapper>
      </Modal>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        header="Import SFDR data from excel"
        width={773}
      >
        <ModalContentWrapper>
          <ContentContainer>
            <div>
              <Label>Fund Name</Label>
              <DisabledContainer>{selectedFund?.name}</DisabledContainer>
            </div>
            <Select
              label="Template"
              options={templateOptions}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => setTemplate(e.value)}
            />
            <Select
              label="Article"
              options={articleOptions}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => setArticle(e.value)}
            />
            <Select
              label="Language"
              options={languageOptions}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => setSelectedLanguages(e.value)}
            />
          </ContentContainer>
          <RowContainer>
            <UploadFile
              onChange={onFileSelect}
              files={files}
              accept={ValidFileTypes.join(",")}
              onRemove={removeFile}
            />
            <ButtonWrapper>
              <Button
                className="btn btn-primary"
                disabled={
                  !files.length || !article || !template || !selectedLanguage
                }
                onClick={handleImport}
                buttonType="solid"
                loading={loading}
              >
                Import
              </Button>
            </ButtonWrapper>
          </RowContainer>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

export default ImportExcelModal;
