import moment from "moment";
import React, { useContext, useState } from "react";
import Button from "../../../components/Button";
import { postData } from "../../../services/apis";
import Context from "../../../store/context";
import { CommentType, Generic } from "../../../types/comment";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { notifyError } from "../../../utils/common";
import MoreOptionsButton from "./MoreOptionsButton";
import {
  ButtonWrapper,
  CommentContainer,
  CommentContent,
  CommentDate,
  CommentFooter,
  CommentHeader,
  CommentHeaderSubWrapper,
  CommentHeaderWrapper,
  CommentInput,
} from "./styles";

type Props = {
  comment: Generic;
  commentType: CommentType;
  documentId: string;
  questionId?: string;
  maxCommentLen?: number;
  updateCommentsList?: () => void;
};

const CommentOrText: React.FC<Props> = ({
  comment,
  commentType,
  documentId,
  questionId,
  maxCommentLen = 240,
  updateCommentsList,
}) => {
  const {
    globalState: { selectedFund, userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [commentText, setCommentText] = useState(comment.comment);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteComment = async () => {
    setLoading(true);
    try {
      await postData({
        endPoint: "deleteComment",
        data: {
          clientId: selectedFund?.client_id,
          commentDetail: {
            type: commentType,
            documentId: documentId,
            commentId: comment._id,
            comment: comment.comment,
            ...(commentType === CommentType.questionLevel
              ? { questionId: questionId }
              : {}),
          },
        },
      });
      updateCommentsList?.();
    } catch (error: any) {
      notifyError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const onCancel = () => {
    setCommentText(comment.comment);
    setIsEditing(false);
  };

  const onEdit = async () => {
    setIsEditing(false);
    setLoading(true);
    try {
      await postData({
        endPoint: "editComment",
        data: {
          clientId: selectedFund?.client_id,
          commentDetail: {
            type: commentType,
            documentId: documentId,
            commentId: comment._id,
            comment: commentText,
            ...(commentType === CommentType.questionLevel
              ? { questionId: questionId }
              : {}),
          },
        },
      });
      updateCommentsList?.();
    } catch (error: any) {
      notifyError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommentContainer key={comment._id}>
      {isEditing ? (
        <>
          <CommentInput
            placeholder="Comment here"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            maxLength={maxCommentLen}
          />
          <CommentFooter>
            {maxCommentLen - commentText.length} characters remaining
          </CommentFooter>
          <ButtonWrapper>
            <Button onClick={onCancel} disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={onEdit}
              disabled={loading || commentText?.length === 0}
              buttonType="solid"
            >
              Submit
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <>
          <CommentHeaderWrapper>
            <CommentHeaderSubWrapper>
              <CommentHeader>{comment.commentedBy.userName}</CommentHeader>
              <CommentDate>
                {moment(comment.updatedAt).format("MMM Do, YYYY: hh:mma")}
              </CommentDate>
            </CommentHeaderSubWrapper>
            {comment.commentedBy.userId === userDetails?.userId && (
              <MoreOptionsButton
                onDelete={deleteComment}
                toggleEdit={toggleEdit}
              />
            )}
          </CommentHeaderWrapper>
          <CommentContent>{comment.comment}</CommentContent>
        </>
      )}
    </CommentContainer>
  );
};

export default CommentOrText;
