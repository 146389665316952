import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${spacingIncrement(140)};
  gap: ${spacingIncrement(5)};
`;

export const Text = styled.span`
  text-align: center;
  font-size: ${spacingIncrement(11)};
`;
