import React, { useContext, useEffect, useState } from "react";
import SidePanel from "../../../../components/SidePanel";
import Select from "../../../../components/Select";
import { Label } from "../../../../components/Select/styles";
import "flatpickr/dist/themes/material_green.css";
import parse from "html-react-parser";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import {
  Box,
  CustomTooltip,
  DisabledContainer,
  BoxFooter,
  RightText,
  ResentAnswerTitle,
  RecentAnsWrap,
  CopyWrap,
  ViewMore,
  AnswerText,
  ClickToCopy,
} from "./styles";
import { fetchData } from "../../../../services/apis";
import {
  DocumentRecord,
  DocumentArticle,
  DocumentTemplate,
} from "../../../../types/document-details";
import Context from "../../../../store/context";
import { GLOBAL_CONTEXT } from "../../../../types/store";

import { getDocHeadingText } from "../../DocumentEditor/service";
import Image from "../../../../components/Image";
import { Question } from "../../../../types/document-question";
import Tooltip from "../../../../components/Tooltip";
import { Text } from "../common.styles";
import PreviousAnswer from "./AnswerRow";

type Props = {
  document: DocumentRecord | undefined;
  questionDetails: Question;
  isOpen: boolean;
  onClose?: () => void;
};

const AutoFillComponent: React.FC<Props> = ({
  document,
  questionDetails,
  isOpen = false,
  onClose,
}) => {
  const {
    globalState: { selectedFund },
  } = useContext<GLOBAL_CONTEXT>(Context);

  const [previousAnswerFilterOptions, setPreviousAnswerFilterOptions] =
    useState({ subFundName: [], template: [], article: [] });
  const [previousAnswers, setPreviousAnswers] = useState([]);
  const [article, setArticle] = useState<DocumentArticle | undefined>(
    !questionDetails.autofillFilterPolicy.article && document?.article
      ? document.article
      : undefined
  );
  const [template, setTemplate] = useState<DocumentTemplate | undefined>(
    !questionDetails.autofillFilterPolicy.template && document?.template
      ? document.template
      : undefined
  );
  const [subFundId, setSubFundId] = useState<number>();
  const [date, setDate] = useState<Date[] | undefined>();

  useEffect(() => {
    (async function () {
      try {
        if (selectedFund?.id) {
          const filterResponse = await fetchData({
            endPoint: "getPreviousAnswerFilterOptions",
            queryParam: {
              fundId: selectedFund?.id,
              clientId: selectedFund?.client_id,
            },
          });
          if (filterResponse.data && filterResponse.data.length) {
            setPreviousAnswerFilterOptions(filterResponse.data[0]);
          }
        }
        await fetchAndSearch();
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const fetchAndSearch = async () => {
    try {
      const answersResponse = await fetchData({
        endPoint: "getQuestionPreviousAnswers",
        queryParam: {
          documentId: document?._id,
          fundId: selectedFund?.id,
          clientId: selectedFund?.client_id,
          subFundId: subFundId,
          questionId: questionDetails.id,
          template: template,
          language: document?.language,
          article: article,
          toDate:
            date && date[1] && moment(date[1]).add(1, "d").format("YYYY/MM/DD"),
          fromDate: date && date[0] && moment(date[0]).format("YYYY/MM/DD"),
        },
      });
      if (answersResponse && answersResponse.data) {
        setPreviousAnswers(answersResponse.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subFundOptions = previousAnswerFilterOptions?.subFundName.map(
    (subFund: any) => ({
      label: subFund?.subFundName,
      value: subFund?.subFundId,
    })
  );

  const templateOptions = previousAnswerFilterOptions?.template.map(
    (template: any) => ({
      label: template,
      value: template,
    })
  );

  const articleOptions = previousAnswerFilterOptions?.article.map(
    (article: any) => ({
      label: article,
      value: article,
    })
  );

  // const copyToClipBoard = async (prevAns: any) => {
  //   const blob = new Blob([prevAns.answer], { type: "text/html" });
  //   const richTextInput = new ClipboardItem({ "text/html": blob });
  //   await navigator.clipboard.write([richTextInput]);
  //   setIsCopied({ [prevAns._id]: true });
  // };

  return (
    <SidePanel
      isOpen={isOpen}
      onClose={() => {
        onClose?.();
        setPreviousAnswers([]);
        setSubFundId(undefined);
        setArticle(undefined);
        setDate(undefined);
        setTemplate(undefined);
        setSubFundId(undefined);
        return;
      }}
      header="Filter the answers"
      content={
        <div style={{ marginTop: "2rem" }}>
          <div className="autofill_form">
            <form className="row">
              <div className="col-sm-6 pb-2">
                <div>
                  <Label>Fund Name</Label>
                  <DisabledContainer>{selectedFund?.name}</DisabledContainer>
                </div>
              </div>
              <div className="col-sm-6 pb-2">
                <Select
                  label="Sub Fund Name"
                  options={subFundOptions}
                  onChange={(e: any) => {
                    setSubFundId(e.value);
                  }}
                  value={subFundOptions.find(
                    (option) => option.value === subFundId
                  )}
                  isDisabled={!questionDetails.autofillFilterPolicy.subFund}
                />
              </div>
              <div className="col-sm-6 pb-2">
                <Select
                  label="Template"
                  options={templateOptions}
                  onChange={(e: any) => setTemplate(e.value)}
                  isDisabled={!questionDetails.autofillFilterPolicy.template}
                  value={templateOptions.find(
                    (option) => option.value === template
                  )}
                />
              </div>
              <div className="col-sm-6 pb-2">
                <Select
                  label="Article"
                  options={articleOptions}
                  onChange={(e: any) => setArticle(e.value)}
                  isDisabled={!questionDetails.autofillFilterPolicy.article}
                  value={articleOptions.find(
                    (option) => option.value === article
                  )}
                />
              </div>
              <div className="col-sm-6">
                <label className="pb-2">Date Range</label>
                <Flatpickr
                  className="form-control form-control--bordered flatpickr-input date--icon"
                  options={{
                    mode: "range",
                    dateFormat: "Y/m/d",
                  }}
                  title="Date Range"
                  placeholder="yy/mm/dd - yy/mm/dd"
                  value={date}
                  onChange={(date) => {
                    setDate(date);
                  }}
                />
              </div>
              <div className="col-sm-12 ">
                <button
                  className="btn btn-primary float-right"
                  type="button"
                  onClick={fetchAndSearch}
                >
                  Filter &amp; Search
                </button>
              </div>
            </form>
          </div>
          <div className="recent-ans-wrap">
            <ResentAnswerTitle>
              <div>Recent Answers</div>
            </ResentAnswerTitle>
            <RecentAnsWrap>
              {previousAnswers &&
                previousAnswers.map((prevAns: any, index: number) => {
                  return (
                    <>
                      <PreviousAnswer
                        index={`copyAnswer-${index}`}
                        prevAns={prevAns}
                        documentDetailsString={getDocHeadingText(
                          prevAns,
                          document?.language as string
                        )}
                      />
                    </>
                  );
                })}
            </RecentAnsWrap>
            <div className="recent-ans-box-wrap"></div>
            <br />
          </div>
        </div>
      }
    />
  );
};

export default AutoFillComponent;
