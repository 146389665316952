import React from "react";
import Modal from "../../../../../components/Modal";
import { MainHeading, ScrollContainer } from "./styles";
import Image from "../../../../../components/Image";
import { ModalContentWrapper } from "../../../ActionButtons/styles";
import Button from "../../../../../components/Button";
import { BulkSelected } from "../../../../../types/listing-table";
import ListComponent from "./ListComponent";
import styled from "styled-components";
import { spacingIncrement } from "../../../../../utils/common";

type Props = {
  isOpen: boolean;
  noListDocs?: BulkSelected[];
  yesListDocs?: BulkSelected[];
  onClose?: () => void;
  onConfirm?: () => void;
  onSkipAndProceed?: () => void;
};

const AmendmentAlertModal: React.FC<Props> = ({
  isOpen,
  yesListDocs,
  noListDocs,
  onClose,
  onConfirm,
  onSkipAndProceed,
}) => {
  const [checked, setChecked] = React.useState(false);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Amendment Alert"
        width={650}
      >
        <ModalContentWrapper>
          <ScrollContainer>
            {yesListDocs && yesListDocs?.length > 0 && (
              <>
                <MainHeading>
                  <Image name="successCircleCheck" />
                  <span>
                    Below are the documents where changes will be applied:
                  </span>
                </MainHeading>
                <ListComponent docs={yesListDocs} />
              </>
            )}
            {noListDocs && noListDocs?.length > 0 && (
              <>
                <MainHeading>
                  <div>
                    <Image name="failureCircle" />
                  </div>
                  <span>
                    Please note that requested changes could not be applied to
                    the below documents as the answer to the corresponding
                    internal question is marked as ‘No’.
                  </span>
                </MainHeading>
                <ListComponent docs={noListDocs} />
              </>
            )}
          </ScrollContainer>
          <div className="custom-control custom-checkbox mt-4">
            <input
              type="checkbox"
              className="custom-control-input"
              id="ddd-checkgroup-1-2-1"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label
              className="custom-control-label text-16 font-weight-normal"
              htmlFor="ddd-checkgroup-1-2-1"
            >
              Click here, if you wish to update the answer to the corresponding
              internal question to ‘Yes’ and continue with the bulk amendment
            </label>
          </div>
          <ButtonContainer>
            <div>
              <Button
                disabled={!checked}
                buttonType="solid"
                onClick={onConfirm}
              >
                Confirm
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </div>
            {yesListDocs && yesListDocs?.length > 0 && (
              <Button disabled={checked} onClick={onSkipAndProceed}>
                Skip and proceed
              </Button>
            )}
          </ButtonContainer>
        </ModalContentWrapper>
      </Modal>
    </div>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacingIncrement(25)};
  margin-bottom: ${spacingIncrement(25)};
  button + button {
    margin-left: 10px;
  }
`;

export default AmendmentAlertModal;
