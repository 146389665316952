type Props = {
  pageSize: number;
  handleChange: (pageSize: number) => void;
};

const ClientPageSize: React.FC<Props> = ({ pageSize, handleChange }) => {
  return (
    <div className="pagination__limit d-flex align-items-center">
      <span>Show</span>
      <div
        className="page-action dropdown mb-0"
        onClick={(e) => {
          const input = e.target as HTMLElement;
          input &&
            input.textContent &&
            handleChange(parseInt(input.textContent));
        }}
      >
        <button
          className="btn btn-outline-secondary btn-sm dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {pageSize || 20}
        </button>
        <div className="dropdown-menu">
          <button className="dropdown-item">20</button>
          <button className="dropdown-item">40</button>
          <button className="dropdown-item">60</button>
          <button className="dropdown-item">80</button>
        </div>
      </div>
      <span>rows per page</span>
    </div>
  );
};

export default ClientPageSize;
