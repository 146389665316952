import { useEffect, useState } from "react";

const useBottomMargin = () => {
  const [shouldShowFooterBar, setShouldShowFooterBar] = useState(false);
  const [shouldExpandFooterBar, setShouldExpandFooterBar] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setShouldShowFooterBar(window.pageYOffset > 300);
      const scrollableHeight = Math.max(
        document.body.scrollHeight - (window.innerHeight + window.scrollY),
        document.documentElement.scrollHeight - (window.innerHeight + window.scrollY)
      );
      setShouldExpandFooterBar(scrollableHeight < 200)
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return { shouldShowFooterBar, shouldExpandFooterBar };
};

export default useBottomMargin;
