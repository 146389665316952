import React, { useContext, useEffect } from "react";
import Button from "../../../components/Button";
import { postData } from "../../../services/apis";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { notifySuccess } from "../../../utils/common";
import {
  ButtonContainer,
  Container,
  QuestionContainer,
  QuestionTitle,
  TextArea,
} from "./styles";
import plausibleInstance from "../../../services/plausible";

const Help: React.FC = () => {
  const [answer, setAnswer] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const {
    globalState: { selectedFund, userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);
  useEffect(() => {
    plausibleInstance.trackPageview(
      {},
      {
        props: {
          client_id: userDetails?.clientId || "",
          fund_id: selectedFund?.fund_level_one_id || "",
          fund_slug: selectedFund?.fund_slug || "",
        },
      }
    );
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(event.target.value);
  };
  const onSubmit = async () => {
    setLoading(true);
    const response = await postData({
      endPoint: "submitQuery",
      data: {
        query: answer,
        clientId: selectedFund?.client_id,
        clientName: selectedFund?.client_name,
      },
    });
    if (response.data?.success) {
      notifySuccess(
        "Query has been successfully submitted and the Zeidler team will be in touch shortly."
      );
      onReset();
    } else {
      notifySuccess("Query not submitted, Please try again");
    }
    setLoading(false);
  };

  const onReset = () => {
    setAnswer("");
  };

  return (
    <Container>
      <QuestionContainer>
        <QuestionTitle>
          Do you have a question? Submit your query and we shall get back to
          you.
        </QuestionTitle>
        <TextArea
          value={answer}
          placeholder="Your query"
          onChange={handleChange}
        />
        <ButtonContainer>
          <Button disabled={loading} onClick={onReset}>
            Reset
          </Button>
          <Button
            loading={loading}
            buttonType="solid"
            onClick={onSubmit}
            disabled={answer?.length === 0}
          >
            Submit
          </Button>
        </ButtonContainer>
      </QuestionContainer>
    </Container>
  );
};

export default Help;
