import { DocumentFilter } from "../../types/fund";

export const assignParentPriority = (
  existingFilters: DocumentFilter[],
  filterValues: {
    value: string;
    label: string;
  }[],
  filterName: string,
  filterId: string
) => {
  const updatedExistingFilters =
    existingFilters && existingFilters.length ? [...existingFilters] : [];
  const foundFilter = updatedExistingFilters.find((filter) => {
    return filter.filterName === filterName;
  });
  if (foundFilter) {
    foundFilter.filterValues = filterValues;
    if (filterValues && filterValues.length) {
      if (!foundFilter.parentPriority) {
        foundFilter.parentPriority = findNextPriority(existingFilters);
      }
    }
  } else {
    updatedExistingFilters.push({
      filterName,
      filterId,
      filterValues,
      parentPriority:
        filterValues &&
        filterValues.length &&
        findNextPriority(existingFilters),
    });
  }
  return updatedExistingFilters;
};

const findNextPriority = (existingFilters: DocumentFilter[]) => {
  let nextParentPriority = 0;
  existingFilters &&
    existingFilters.length &&
    existingFilters.forEach((filter) => {
      if (filter.parentPriority && filter.parentPriority > nextParentPriority) {
        nextParentPriority = filter.parentPriority;
      }
    });
  return nextParentPriority + 1;
};

export const resetDependentFilters = (
  existingFilters: DocumentFilter[],
  selectedFilterName: string,
  isClient?: boolean
) => {
  const selectedPriority = existingFilters.find((selected) => {
    return selected.filterName === selectedFilterName;
  })?.parentPriority;

  existingFilters.forEach((filter) => {
    if (
      !filter.parentPriority ||
      (selectedPriority && filter.parentPriority > selectedPriority) ||
      !filter.filterValues ||
      (filter.filterValues && !filter.filterValues.length)
    ) {
      filter.filterValues = [];
      filter.parentPriority = null;
      if (isClient) {
        const elements = document.getElementsByName(
          `checkList-${filter.filterName}`
        ) as unknown as HTMLInputElement[];

        if (elements) {
          for (let i = 0; i < elements.length; i++) {
            elements[i].checked = false;
          }
        }
      }
    }
  });
  return existingFilters;
};
