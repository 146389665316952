import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const StyledHeader = styled.th`
color: #495057;  
background-color: #f6f6f6;  
border: 1px solid #dee2e6;  
border-bottom: 4px solid #dee2e6;  
width:70px;
font-size: 14px;  
font-weight: 400; 
padding: 12px;  
text-align: left;  
text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);  
font-family: "Calibri","Arial" !important
vertical-align: middle; 
`;
export const StyledTableData = styled.td`
  font-size: 14px;
  font-family: "Calibri", "Arial" !important;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  height: ${spacingIncrement(150)};
  width: ${spacingIncrement(150)};
  margin-right: ${spacingIncrement(10)};
  overflow: auto;
`;
