/* Old logic for find occurrences with partial string */

// export function findOccurrencesWithPartialString(
//   findWord: string,
//   inputString: string,
//   numCharsAround: number
// ) {
//   const escapedFindWord = escapeRegExp(findWord);
//   const pattern = new RegExp(
//     `(?:(\\b[^<]*\\b)|<[^>]*>|\\b)(.*?)\\b(${findWord})(.{0,${numCharsAround}}\\b)`,
//     "g"
//   );
//   const occurrencesArray = [];
//   let match;
//   while ((match = pattern.exec(inputString)) !== null) {
//     const partialString = match[0].replace(
//       new RegExp(escapedFindWord, "gi"),
//       `<span class="text-heighlight-bg">$&</span>`
//     );
//     occurrencesArray.push(partialString);
//   }

//   return occurrencesArray;
// }

export function findOccurrencesWithPartialString(
  findWord: string,
  html: string,
  numCharsAround: number
) {
  const escapedFindWord = escapeRegExp(findWord);
  const pattern = new RegExp(`(.*?${escapedFindWord}.*?)`, "g");
  const doc = new DOMParser().parseFromString(html, "text/html");
  const occurrencesArray: string[] = [];

  // If the text node is the last child of its parent, we need to handle it differently to get the partial string from input html
  /* 
    input html: <p>Some text <strong>some text</strong> some text</p>
    findWord: text
    output: <strong>some <span class="text-heighlight-bg">text</span></strong>
  */
  function traverseAndFindLastChild(node: Node) {
    try {
      const lastChild = node.childNodes[0];
      const textContent = lastChild.textContent || "";
      let match;
      while ((match = pattern.exec(textContent)) !== null) {
        // match[1] is the partial string that contains the findWord
        if (match[1]) {
          const { contextBefore, partialString, contextAfter } =
            getPartialStringAndContext(
              match,
              numCharsAround,
              textContent,
              escapedFindWord
            );
          // Creating a new element with parent node tag name and appending partial string
          const occurrenceElement = `<${node.nodeName.toLowerCase()}>${contextBefore}${partialString}${contextAfter}`;
          occurrencesArray.push(occurrenceElement);
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  function traverseAndFind(node: Node) {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent || "";
      let match;
      while ((match = pattern.exec(textContent)) !== null) {
        if (match[1]) {
          /* This will be removed in future */

          // const startIndex = Math.max(match.index - numCharsAround, 0);
          // const endIndex = Math.min(
          //   match.index + match[1].length + numCharsAround,
          //   textContent.length
          // );
          // const contextBefore = textContent.slice(startIndex, match.index);
          // const contextAfter = textContent.slice(
          //   match.index + match[1].length,
          //   endIndex
          // );
          // const partialString = match[1].replace(
          //   new RegExp(escapedFindWord, "g"),
          //   `<span class="text-heighlight-bg">$&</span>`
          // );

          const { contextBefore, partialString, contextAfter } =
            getPartialStringAndContext(
              match,
              numCharsAround,
              textContent,
              escapedFindWord
            );
          const occurrenceElement = `${contextBefore}${partialString}${contextAfter}`;
          occurrencesArray.push(occurrenceElement);
        }
      }
    } else if (node instanceof Element && node.childNodes) {
      if (
        node.childNodes.length === 1 &&
        node.childNodes[0].nodeType === Node.TEXT_NODE
      ) {
        traverseAndFindLastChild(node);
      } else {
        for (const childNode of Array.from(node.childNodes)) {
          traverseAndFind(childNode);
        }
      }
    }
  }

  traverseAndFind(doc.body);
  return occurrencesArray;
}
function getPartialStringAndContext(
  match: RegExpExecArray,
  numCharsAround: number,
  textContent: string,
  escapedFindWord: string
) {
  try {
    const startIndex = Math.max(match.index - numCharsAround, 0);
    const endIndex = Math.min(
      match.index + match[1].length + numCharsAround,
      textContent.length
    );

    const contextBefore = textContent.slice(startIndex, match.index);
    const contextAfter = textContent.slice(
      match.index + match[1].length,
      endIndex
    );
    // From partial string, Replace method will replace only find word by find word wrapped in highlight span
    const partialString = match[1].replace(
      new RegExp(escapedFindWord, "g"),
      `<span class="text-heighlight-bg">$&</span>`
    );
    return { contextBefore, partialString, contextAfter };
  } catch (error) {
    console.log("error", error);
    return { contextBefore: "", partialString: "", contextAfter: "" };
  }
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
