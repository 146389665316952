import React from "react";
import { ImageProps } from "../types";

const Logo: React.FC<ImageProps> = ({ color, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73"
      height="38"
      viewBox="0 0 73 38"
      {...otherProps}
    >
      <g fill={color} fillRule="evenodd">
        <path d="M19.6940012,5.35211268 C22.8337118,5.35211268 25.0597015,7.98695245 25.0597015,11.134514 C25.0597015,11.4373906 25.0360708,11.7406169 24.9893972,11.9970944 L17.1167251,11.9970944 C17.2804941,13.5827081 18.5693673,14.6784499 20.1861314,14.6784499 C21.4746519,14.6784499 22.4068306,13.6133688 22.9455168,13.0303489 L24.6794939,14.7557429 C23.5784908,16.0613298 21.8730821,17.1267606 20.1158271,17.1267606 C16.7181772,17.1267606 14.1641791,14.794914 14.1641791,11.2508615 C14.1641791,8.10341654 16.3200996,5.35211268 19.6940012,5.35211268 Z M17.2572161,9.78206164 L21.9433865,9.78206164 C21.8494514,8.77946786 20.9358481,7.80030679 19.6940012,7.80030679 C18.4288763,7.80030679 17.3977072,8.75615172 17.2572161,9.78206164 Z" />
        <polygon points="27.239 17.127 30.507 17.127 30.507 5.887 27.239 5.887" />
        <path d="M29.1454661 0C28.0914005 0 27.238806.812464922 27.238806 1.87318302 27.238806 2.93390111 28.0914005 3.74647887 29.1454661 3.74647887 30.1998694 3.74647887 31.0522388 2.93390111 31.0522388 1.87318302 31.0522388.812464922 30.1998694 0 29.1454661 0M42.1311972 6.52648885C41.229411 5.8351743 40.1374556 5.48963224 38.879861 5.48963224 35.7471277 5.48963224 33.2313433 8.02433705 33.2313433 11.3196031 33.2313433 12.8404951 33.8010178 14.2231242 34.703042 15.2370522 35.7235501 16.3894736 37.337072 17.1267606 39.0459764 17.1267606 40.208903 17.1267606 41.3005013 16.7808728 42.2498 15.9976135L42.2498 16.8960919 45.2164179 16.8960919 45.2164179.535211268 42.1311972.535211268 42.1311972 6.52648885zM42.1311972 12.978758C41.5380641 13.8084507 40.5171988 14.3613871 39.3545104 14.3613871 37.6221474 14.3613871 36.316564 13.0710485 36.316564 11.3196031 36.316564 9.7065358 37.4794907 8.25477523 39.2833011 8.25477523 40.4700435 8.25477523 41.4904324 8.8079421 42.1311972 9.63751956L42.1311972 12.978758z" />
        <polygon points="47.396 16.592 50.664 16.592 50.664 .535 47.396 .535" />
        <path d="M72.3077974 5.35211268C70.890126 5.35211268 69.6174519 5.87424087 68.9206692 6.6916627L68.9206692 5.57906076 65.9179104 5.57906076 65.9179104 16.5915493 69.0408374 16.5915493 69.0408374 9.66616991C69.4252069 9.00780475 70.5059975 8.07685211 71.875216 8.07685211 72.3292517 8.07685211 72.7825643 8.12169678 73 8.18913408L73 5.39389201C72.8012464 5.36970995 72.5916451 5.35211268 72.3077974 5.35211268M58.3731057 5.35211268C54.9992041 5.35211268 52.8432836 8.10341654 52.8432836 11.2508615 52.8432836 14.794914 55.3972817 17.1267606 58.7949315 17.1267606 60.5521866 17.1267606 62.2575952 16.0613298 63.3585984 14.7557429L61.6246213 13.0303489C61.0859351 13.6133688 60.1536388 14.6784499 58.8652358 14.6784499 57.2484718 14.6784499 55.9595986 13.5827081 55.7958295 11.9970944L63.6685017 11.9970944C63.7150577 11.7406169 63.738806 11.4373906 63.738806 11.134514 63.738806 7.98695245 61.5128163 5.35211268 58.3731057 5.35211268zM55.9363206 9.78206164C56.0768117 8.75615172 57.1079807 7.80030679 58.3731057 7.80030679 59.6149526 7.80030679 60.5285559 8.77946786 60.6224909 9.78206164L55.9363206 9.78206164z" />
        <polygon points="4.824 14.313 7.472 9.634 4.241 9.634 0 17.127 12.53 17.127 12.53 14.313" />
        <polygon points="7.706 3.884 5.058 8.563 8.289 8.563 12.53 1.07 0 1.07 0 3.884" />
        <path d="M2.05462615,33.4604199 C2.98854713,34.474362 4.41277662,35.2347034 5.90705019,35.2347034 C7.30781492,35.2347034 8.28843194,34.3821855 8.28843194,33.2069344 C8.28843194,31.1329619 5.25318876,30.8795916 3.0585912,29.612164 C1.77444986,28.8746364 0.747136783,27.7686329 0.747136783,25.948146 C0.747136783,23.0677439 3.4321596,21.4084507 6.14053043,21.4084507 C7.79812343,21.4084507 9.33909305,21.8003163 10.8800627,22.9294791 L9.24581769,25.2337777 C8.59195626,24.6346301 7.49471585,24.1737473 6.42058999,24.1737473 C5.22984074,24.1737473 3.89912009,24.6807184 3.89912009,25.9251019 C3.89912009,28.5289743 11.4402985,27.7225447 11.4402985,33.1608461 C11.4402985,36.0182041 8.84878453,38 5.90705019,38 C3.71233589,38 1.68105776,37.1013938 0,35.5803655 L2.05462615,33.4604199 Z" />
        <polygon points="18.915 37.465 16.794 37.465 11.985 26.225 15.073 26.225 17.854 32.784 20.73 26.225 22.852 26.225 25.704 32.784 28.509 26.225 31.597 26.225 26.788 37.465 24.667 37.465 21.791 30.93" />
        <path d="M33.6799824 38L36.5960248 38 36.5960248 26.7751083 33.6799824 26.7751083 33.6799824 38zM35.1380597 20.8732394C36.1923617 20.8732394 37.0447761 21.7064445 37.0447761 22.7942399 37.0447761 23.8820353 36.1923617 24.7152403 35.1380597 24.7152403 34.0837577 24.7152403 33.2313433 23.8820353 33.2313433 22.7942399 33.2313433 21.7064445 34.0837577 20.8732394 35.1380597 20.8732394zM47.4829499 23.6385361C47.2314082 23.5692884 46.8655294 23.5233154 46.5911203 23.5233154 45.8593626 23.5233154 45.2876769 23.7307126 44.921798 24.1685512 44.6702563 24.491054 44.5101843 24.9059637 44.5101843 25.9429499L44.5101843 26.288612 47.9402985 26.288612 47.9402985 28.823352 44.5101843 28.823352 44.5101843 37.4647887 41.537533 37.4647887 41.537533 28.823352 38.6791045 28.823352 38.6791045 26.288612 41.537533 26.288612 41.537533 25.8277292C41.537533 24.0301712 41.9491467 22.9472119 42.7265249 22.1867553 43.5040175 21.4262988 44.6702563 20.8732394 46.1795065 20.8732394 46.7740597 20.8732394 47.3228779 20.9193277 47.9402985 21.0806367L47.4829499 23.6385361zM59.2652313 37.7706201C58.8729879 37.908248 57.9731353 38 57.3963068 38 55.5966017 38 54.2814325 37.4036123 53.5200188 36.3943407 52.9431903 35.637387 52.6895011 34.6511682 52.6895011 33.0684469L52.6895011 29.1689886 49.5746269 29.1689886 49.5746269 26.6459244 52.6895011 26.6459244 52.6895011 23.5492958 55.6888942 23.5492958 55.6888942 26.6459244 59.380597 26.6459244 59.380597 29.1689886 55.6888942 29.1689886 55.6888942 33.4125167C55.6888942 34.1005417 55.8042599 34.5135402 56.0580645 34.8116194 56.3580154 35.1556893 56.8425514 35.3621312 57.5807765 35.3621312 58.0192816 35.3621312 58.5961102 35.2933172 58.8037685 35.2474412L59.2652313 37.7706201z" />
      </g>
    </svg>
  );
};

export default Logo;
