import React, { useEffect, useState } from "react";
import { DocumentFilter, FilterData } from "../../types/fund";
import FilterOptions from "../FilterOptions";
import { assignParentPriority, resetDependentFilters } from "./services";
import { FilterContainer } from "./styles";

type Props = {
  filterData: FilterData[];
  appliedFilter: (value: DocumentFilter[]) => void;
  preSelectedFilters: DocumentFilter[];
};

const ClientFilters: React.FC<Props> = ({
  filterData,
  appliedFilter,
  preSelectedFilters,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<DocumentFilter[]>([]);

  useEffect(() => {
    setSelectedFilters(preSelectedFilters);
  }, [preSelectedFilters]);

  const setFilters = (name: string, value: string | null, id: string) => {
    let filterValue: {
      value: string;
      label: string;
    }[] = [];
    if (
      name &&
      value &&
      name.toLowerCase() !== value.toString().toLowerCase()
    ) {
      const arrayValues = value.split(",");
      filterValue = arrayValues.map((val) => {
        return { value: val.trim(), label: val.trim() };
      });
    }
    const updatedWithParentPriority: DocumentFilter[] = [
      ...resetDependentFilters(
        assignParentPriority(selectedFilters, filterValue, name, id),
        name,
        true
      ),
    ];

    setSelectedFilters([...updatedWithParentPriority]);
    appliedFilter(updatedWithParentPriority);
  };

  const clearFilter = (filterName: string, filterId: string) => {
    setFilters(filterName, null, filterId);
  };

  return (
    <FilterContainer>
      {filterData.map((filter, index) => (
        <FilterOptions
          filterData={filter}
          key={index.toString()}
          selectedFilters={selectedFilters}
          onChange={(value) => {
            setFilters(filter.name, value, filter.id);
          }}
          clearFilter={clearFilter}
        />
      ))}
    </FilterContainer>
  );
};

export default ClientFilters;
