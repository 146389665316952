import React from "react";
import { ImageProps } from "../types";

const Users: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="67.5" cy="67.5" r="67.5" fill="#9D9D9C" />
      <path
        d="M50.6585 98.704C45.1177 100.3 37.1973 103.147 27.5 108.175V71.5H77.0386L59.313 96.7734C59.2182 96.7853 59.0946 96.8017 58.9427 96.8234C58.5521 96.8793 57.9747 96.9706 57.2207 97.1129C55.7127 97.3975 53.4983 97.8862 50.6585 98.704Z"
        fill="white"
        stroke="#9D9D9C"
      />
      <ellipse
        cx="53.5086"
        cy="70.8009"
        rx="25.4267"
        ry="17.7513"
        fill="white"
      />
      <circle cx="54.0497" cy="38.9838" r="12.9838" fill="white" />
      <mask id="path-5-inside-1_19_279" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.0748 74.473C58.0847 67.3772 69.194 58 82.75 58C96.306 58 107.415 67.3772 108.425 74.473H108.7V98.4931C88.5093 96.2962 77.1891 96.2978 57 98.4931V74.473H57.0748Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.0748 74.473C58.0847 67.3772 69.194 58 82.75 58C96.306 58 107.415 67.3772 108.425 74.473H108.7V98.4931C88.5093 96.2962 77.1891 96.2978 57 98.4931V74.473H57.0748Z"
        fill="#3C3C3B"
      />
      <path
        d="M57.0748 74.473V76.473H58.8103L59.0549 74.7548L57.0748 74.473ZM108.425 74.473L106.445 74.7548L106.69 76.473H108.425V74.473ZM108.7 74.473H110.7V72.473H108.7V74.473ZM108.7 98.4931L108.484 100.481L110.7 100.723V98.4931H108.7ZM57 98.4931H55V100.722L57.2162 100.481L57 98.4931ZM57 74.473V72.473H55V74.473H57ZM59.0549 74.7548C59.4492 71.9841 61.977 68.2904 66.3576 65.2119C70.6567 62.1907 76.4275 60 82.75 60V56C75.5165 56 68.9547 58.4979 64.0577 61.9392C59.2421 65.3233 55.7103 69.8661 55.0948 74.1912L59.0549 74.7548ZM82.75 60C89.0725 60 94.8433 62.1907 99.1425 65.2119C103.523 68.2904 106.051 71.9841 106.445 74.7548L110.405 74.1912C109.79 69.8661 106.258 65.3233 101.442 61.9392C96.5453 58.4979 89.9835 56 82.75 56V60ZM108.425 76.473H108.7V72.473H108.425V76.473ZM106.7 74.473V98.4931H110.7V74.473H106.7ZM108.916 96.5048C88.5818 94.2923 77.1165 94.2939 56.7838 96.5048L57.2162 100.481C77.2617 98.3017 88.4369 98.3002 108.484 100.481L108.916 96.5048ZM59 98.4931V74.473H55V98.4931H59ZM57 76.473H57.0748V72.473H57V76.473Z"
        fill="#9D9D9C"
        mask="url(#path-5-inside-1_19_279)"
      />
      <circle
        cx="83.2109"
        cy="46.5577"
        r="12.4838"
        fill="#3C3C3B"
        stroke="#9D9D9C"
      />
    </svg>
  );
};

export default Users;
