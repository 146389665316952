import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import { TriggerType, usePopperTooltip } from "react-popper-tooltip";
import {
  getDownloadableFileName,
  getSortState,
  manageSort,
  updateCheckboxSelection,
} from "../../../services/clientTable";
import SVGIcon from "../../../components/SvgIcons";
import Image from "../../../components/Image";
import { ImageName } from "../../../components/Image/types";
import {
  BulkSelect,
  TableHeader,
  TableRow,
} from "../../../types/listing-table";
import TranslationRequestModal, {
  OnSubmitData,
} from "../TranslationRequestModal";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { fetchData, postData } from "../../../services/apis";
import { updateIsLoading } from "../../../store/globalActions";
import {
  ButtonContainer,
  CloneMessage,
  ConfirmationButtonContainer,
  DeleteMessage,
  ImageWrapper,
  ListItem,
  NameContainer,
  ToolTipContainer,
  TranslationTooltipContainer,
} from "./styles";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { CLIENT_URL_PREFIX } from "../../../lib/constants";
import {
  DocumentActionEnum,
  DocumentRecord,
  DocumentStatusEnum,
  DocumentStatusToKey,
} from "../../../types/document-details";
import PreviewModal from "../../templates/DocumentEditor/PreviewModal";
import { useMemo } from "react";
import {
  getDocumentUserPermission,
  userHasPermission,
  notifySuccess,
  notifyError,
  filterAllLockedDocuments,
  ALLOWED_EXCEL_LANGUAGES,
  getExcelExportFileName,
} from "../../../utils/common";
import DownloadModal from "../../templates/CommonComponents/DownloadModal";
import { saveAs } from "file-saver";
import { WordRequestModal } from "../ActionButtons/wordRequestModal";
import { validateRequestWordDoc } from "../../../utils/wordRequest";
import { DeleteConfirmationModal } from "../ActionButtons/DeleteConfirmationModal";
import { SortState } from "../../../services/clientTable";
import { DocumentArchiveConfirmationModal } from "../ActionButtons/ConfirmationModal";

const ImageComponent: React.FC<{
  name: ImageName;
  onClick?: () => void;
  ref?: React.Dispatch<React.SetStateAction<HTMLElement | null>> | null;
}> = ({ ref, name, onClick }) => {
  return (
    <ImageWrapper onClick={onClick} ref={ref}>
      <Image name={name} />
    </ImageWrapper>
  );
};

const ButtonWrapper: React.FC<{
  setTriggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  imageName: ImageName;
}> = ({ children, setTriggerRef, imageName }) => {
  if (
    imageName !== "moreOptions" &&
    imageName !== "translate" &&
    imageName !== "download"
  ) {
    return <>{children}</>;
  }
  return <ButtonContainer ref={setTriggerRef}>{children}</ButtonContainer>;
};

type Props = {
  tableHeaders: TableHeader[];
  tableRows: TableRow[];
  sortOnColumns?: string[];
  onSortChange: (data: SortState | null) => void;
  getBulkSelect?: (data: BulkSelect | null) => void;
  bulkSelected: BulkSelect | null;
  updateDocList: () => Promise<void>;
  archived?: boolean;
};

type ActionContainerProps = {
  imageName: ImageName;
  index: number;
  actionHandler: (
    name: ImageName,
    rowTable: TableRow,
    isDownloadOpen?: (val: boolean) => void
  ) => void;
  row: TableRow;
  onDelete?: () => void;
  onArchive?: (row: TableRow) => void;
  onPreviousDocument?: () => void;
  onWordFormatRequest?: () => void;
  onPublicUrlClick?: () => void;
  onClone?: () => void;
  onPdfDownload?: (
    row: TableRow,
    isUnformatted?: boolean,
    showPageNumber?: boolean
  ) => void;
  onExportExcel?: (documentId: string) => void;
  tooltipTrigger?: TriggerType;
  tooltipContent?: string;
};

const ActionContainer: React.FC<ActionContainerProps> = ({
  actionHandler,
  imageName,
  row,
  onDelete,
  onArchive,
  index,
  onPreviousDocument,
  onClone,
  onWordFormatRequest,
  onPublicUrlClick,
  onExportExcel,
  onPdfDownload,
  tooltipTrigger,
  tooltipContent,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    closeOnOutsideClick: true,
    closeOnTriggerHidden: true,
    trigger: tooltipTrigger,
    interactive: true,
  });

  const documentStatus = row.data.status;
  const [isDownloadOpen, setIsDownloadOpen] = useState<boolean>(false);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const {
    globalState: { userDetails, approverGroup, userPermission },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const canCloneDocument = documentStatus === DocumentStatusEnum.filed;
  const canGetPublicUrl = documentStatus === DocumentStatusEnum.filed;
  const userType = useMemo(
    () => userDetails && getDocumentUserPermission(approverGroup, userDetails),
    [approverGroup, userDetails]
  );
  const actionPermission = useMemo(() => {
    if (userPermission && userType && documentStatus) {
      const deletePermission = userHasPermission(
        {
          userType,
          docStatus: DocumentStatusToKey[documentStatus],
          docAction: DocumentActionEnum.delete,
        },
        userPermission
      );
      return {
        deletePermission,
      };
    } else {
      return {
        deletePermission: false,
      };
    }
  }, [userType, documentStatus, userPermission]);
  const handleOnArchiveClick = () => {
    setShowArchiveConfirmation(true);
  };
  const handleCloseArchiveModal = () => {
    setShowArchiveConfirmation(false);
  };
  return (
    <>
      <ButtonWrapper setTriggerRef={setTriggerRef} imageName={imageName}>
        <div id={`${imageName}-${index}`} title={tooltipContent}>
          <ImageComponent
            name={imageName}
            onClick={() => actionHandler(imageName, row, setIsDownloadOpen)}
          />
        </div>
      </ButtonWrapper>
      <DownloadModal
        isOpen={isDownloadOpen}
        setIsDownloadOpen={setIsDownloadOpen}
        onUnformattedPdfDownload={(showPageNumber) =>
          onPdfDownload?.(row, true, showPageNumber)
        }
        onPdfDownload={(showPageNumber) =>
          onPdfDownload?.(row, false, showPageNumber)
        }
      />
      <Modal
        isOpen={showArchiveConfirmation}
        onClose={handleCloseArchiveModal}
        header="Archive Confirmation"
      >
        <DocumentArchiveConfirmationModal
          onCancel={handleCloseArchiveModal}
          onConfirm={() => {
            onArchive?.(row);
            handleCloseArchiveModal();
          }}
          message={"single"}
        />
      </Modal>
      {visible && imageName === "moreOptions" && (
        <ToolTipContainer
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <div
            {...getArrowProps({
              className: "tooltip-arrow",
            })}
            style={{ marginLeft: "44%" }}
          />
          <div>
            <ListItem
              id={`previous-version-button-${index}`}
              onClick={() => onPreviousDocument?.()}
            >
              <Image name="previousVersion" />
              <NameContainer>Previous Versions</NameContainer>
            </ListItem>
            <ListItem
              id={`word-request-button-${index}`}
              onClick={() => onWordFormatRequest?.()}
            >
              <Image name="requestDoc" />
              <NameContainer>Request Word Doc</NameContainer>
            </ListItem>
            {ALLOWED_EXCEL_LANGUAGES.includes(row.data.language) ? (
              <ListItem
                id={`excel-export-button-${index}`}
                onClick={() => onExportExcel && onExportExcel(row.data._id)}
              >
                <Image
                  style={{ width: "16px", height: "16px" }}
                  stroke="20"
                  name="downloadIcon"
                />
                <NameContainer>Export Excel</NameContainer>
              </ListItem>
            ) : null}
            {userDetails?.userType === "admin" && (
              <ListItem
                id={`archive-doc-button-${index}`}
                onClick={() => handleOnArchiveClick()}
              >
                <Image color="currentColor" fill="#ADB5BD" name="archiveIcon" />
                <NameContainer>Archive</NameContainer>
              </ListItem>
            )}
            {actionPermission.deletePermission && (
              <ListItem
                id={`delete-doc-button-${index}`}
                onClick={() => onDelete?.()}
              >
                <Image name="delete" />
                <NameContainer>Delete</NameContainer>
              </ListItem>
            )}
            {canCloneDocument && (
              <ListItem
                id={`clone-doc-button-${index}`}
                onClick={() => onClone?.()}
              >
                <Image name="cloneDocument" />
                <NameContainer>Clone Document</NameContainer>
              </ListItem>
            )}
            {canGetPublicUrl && (
              <ListItem
                id={`public-url-button-${index}`}
                onClick={() => onPublicUrlClick?.()}
              >
                <Image name="urlLink" />
                <NameContainer>Get Public URL</NameContainer>
              </ListItem>
            )}
          </div>
        </ToolTipContainer>
      )}
      {visible && imageName === "translate" && (
        <ToolTipContainer
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <div
            {...getArrowProps({
              className: "tooltip-arrow",
            })}
            style={{ marginLeft: "47%" }}
          />
          <TranslationTooltipContainer>
            Please contact the Zeidler Team if you wish for the document to be
            translated.
          </TranslationTooltipContainer>
        </ToolTipContainer>
      )}
    </>
  );
};

const ClientTable: React.FC<Props> = ({
  tableHeaders,
  tableRows,
  sortOnColumns,
  onSortChange,
  getBulkSelect,
  bulkSelected,
  updateDocList,
}) => {
  const navigate = useNavigate();
  const [sortState, setSortState] = useState<SortState | null>(
    sortOnColumns?.length && tableHeaders.length
      ? {
          ...getSortState(tableHeaders, sortOnColumns),
        }
      : null
  );
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const [bulkSelect, setBulkSelect] = useState<BulkSelect>({});
  const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<TableRow>();
  const [moreActionsOpen, setMoreActionsOpen] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [requestWordDoc, setRequestWordDoc] = useState(false);
  const [cloneConfirmModal, setCloneConfirmModal] = useState(false);
  const [selectedPreviewDoc, setSelectedPreviewDoc] =
    useState<DocumentRecord>();

  const { tooltipRef } = usePopperTooltip();

  const {
    globalState: { selectedFund, documentList, userDetails },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);

  useEffect(() => {
    bulkSelected ? setBulkSelect(bulkSelected) : setBulkSelect({});
  }, [bulkSelected]);
  const handleSorting = async (colName: string) => {
    setBulkSelect({});
    getBulkSelect?.(null);
    let deducedSortString: SortState | null = null;
    setSortState((prevState) => {
      deducedSortString = prevState ? manageSort(prevState, colName) : null;
      onSortChange(deducedSortString);
      return deducedSortString;
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSelectedCheckbox = (e?: { target: any }, value?: string) => {
    if (e?.target) {
      const { target } = e;
      target.previousSibling.checked = !target.previousSibling.checked;
      const updatedState = updateCheckboxSelection(
        target.previousSibling.checked,
        value,
        bulkSelect,
        tableRows
      );
      setBulkSelect({ ...updatedState });
      getBulkSelect?.(updatedState);
    }
  };
  const handleDownloadPDF = async (
    rowTable: TableRow,
    unFormatted = false,
    showPageNumber = true
  ) => {
    let isApproved = false;
    const status = rowTable.data.status;
    if (typeof status === "string") {
      if (
        status === DocumentStatusEnum.approved ||
        status === DocumentStatusEnum.filed
      )
        isApproved = true;
    }
    try {
      globalDispatch(updateIsLoading(true));
      const response = await postData({
        endPoint: "generatePdf/single",
        data: {
          clientId: userDetails?.clientId,
          documentId: rowTable.sfdrId,
          url: `${window.location.origin}${CLIENT_URL_PREFIX}document/view/${
            selectedFund?.client_id
          }/${rowTable.sfdrId}/pdf/${
            !isApproved ? (unFormatted ? "" : "showWaterMark") : ""
          }`,
          unFormatted: unFormatted,
          showPageNumber: showPageNumber,
        },
        responseType: "blob",
      });
      const subFundName = rowTable.subFundName;
      const temp = rowTable.data.template;
      const art = rowTable.data.article;
      const language = rowTable.data.language as string;
      const status = rowTable.data.status;
      const version = rowTable.data.approvedVersion
        ? rowTable.data.approvedVersion
        : null;
      // Use file-saver to save the Blob as a file
      const fileName = getDownloadableFileName(
        subFundName,
        art,
        temp,
        status,
        language,
        version
      );
      saveAs(response.data, `${fileName}.pdf`);
      /* OLD IMPLEMENTATION START */

      // if (downloadRef.current)
      //   downloadRef.current.href = `data:application/pdf;base64,${response.data.data}`;
      // if (downloadRef.current)
      // downloadRef.current.download = `${getDownloadableFileName(
      //   subFundName,
      //   art,
      //   temp,
      //   status,
      //   version
      // )}.pdf`;
      // downloadRef.current?.click();

      /* OLD IMPLEMENTATION END */

      globalDispatch(updateIsLoading(false));
      notifySuccess("Requested document is downloaded successfully");
    } catch (error) {
      console.log(error);
      globalDispatch(updateIsLoading(false));
      notifyError("Error occured while downloading, Please try again");
    }
  };
  const actionHandler = (
    name: ImageName,
    rowTable: TableRow,
    setIsDownloadOpen?: (val: boolean) => void
  ) => {
    switch (name) {
      case "edit":
        navigate(
          CLIENT_URL_PREFIX +
            "document/edit/" +
            selectedFund?.client_id +
            "/" +
            rowTable.id
        );
        break;
      case "download":
        setIsDownloadOpen?.(true);
        break;
      case "translate":
        // setSelectedRow(rowTable);
        // setIsTranslationModalOpen(!isTranslationModalOpen);
        break;
      case "moreOptions":
        setMoreActionsOpen(!moreActionsOpen);
        break;
      default:
        break;
    }
  };
  if (!tableHeaders.length) {
    return null;
  }
  const handleLanguageRequest = async (data: OnSubmitData) => {
    try {
      globalDispatch(updateIsLoading(true));
      const selectDoc = documentList.find((doc) => doc._id === selectedRow?.id);
      const allResps = await Promise.all(
        data.selectedLanguages.map(
          async (lang) =>
            await postData({
              endPoint: "createNewDocument",
              data: {
                fundId: selectedFund?.id,
                fundName: selectedFund?.name,
                clientId: selectedFund?.client_id,
                subFundId: selectDoc?.subFundId,
                subFundName: selectDoc?.subFundName,
                article: selectDoc?.article,
                template: selectDoc?.template,
                language: lang,
                isTranslationRequested: data.shouldHandleTranslation,
              },
            })
        )
      );
      const isSuccess = allResps.every((resp) => resp.data?.success);
      if (isSuccess) {
        updateDocList?.();
      }
    } finally {
      globalDispatch(updateIsLoading(false));
      // setIsTranslationModalOpen(!isTranslationModalOpen);
    }
  };
  const handleCloseTranslationModal = () => {
    setSelectedRow(undefined);
    setIsTranslationModalOpen(false);
  };
  const handleCloneDocument = async () => {
    if (selectedRow?.id) {
      const selectDoc = documentList.find((doc) => doc._id === selectedRow?.id);
      try {
        if (tooltipRef) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          tooltipRef!.style.display = "none";
        }
        if (selectDoc) {
          globalDispatch(updateIsLoading(true));
          const resp = await postData({
            endPoint: "createNewDocument",
            data: {
              fundId: selectedFund?.id,
              fundName: selectedFund?.name,
              clientId: selectedFund?.client_id,
              subFundId: selectDoc.subFundId,
              subFundName: selectDoc.subFundName,
              article: selectDoc.article,
              template: selectDoc.template,
              language: selectDoc.language,
              documentState: selectDoc.documentState,
            },
          });
          if (resp.data && resp.data?.success) {
            updateDocList?.();
            navigate(
              CLIENT_URL_PREFIX +
                "document/edit/" +
                selectedFund?.client_id +
                "/" +
                resp.data.data._id,
              {
                replace: true,
              }
            );
          }
        }
      } finally {
        globalDispatch(updateIsLoading(false));
        setCloneConfirmModal(false);
        setSelectedRow(undefined);
      }
    }
  };
  const handleDeleteDocument = async () => {
    if (selectedRow?.id) {
      try {
        const lockedDoc = filterAllLockedDocuments([
          {
            ...selectedRow.data,
            id: selectedRow.data._id,
            lockStatus: false,
            isApproved: false,
          },
        ]);
        if (lockedDoc.length) {
          notifyError(
            "Please note that locked document is not applicable for bulk deletion."
          );
          return;
        }
        if (tooltipRef) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          tooltipRef!.style.display = "none";
        }
        globalDispatch(updateIsLoading(true));
        const res = await postData({
          endPoint: "deleteDocuments",
          data: {
            documentIds: [selectedRow.id],
            clientId: selectedFund?.client_id,
          },
        });
        if (res.data.data === "success") {
          updateDocList?.();
          notifySuccess("Selected Document deleted successfully");
        }
      } finally {
        globalDispatch(updateIsLoading(false));
        setDeleteConfirmModal(false);
        setSelectedRow(undefined);
      }
    }
  };
  const handlePreviousDocument = (id: string) => {
    const selectedDoc = documentList.find((doc) => doc._id === id);
    setSelectedPreviewDoc(selectedDoc);
  };
  const handlePublicUrlClick = async (row: TableRow) => {
    const {
      data: { article, template, subFundSlug, language },
    } = row;

    const publicUrl = `${
      process.env.REACT_APP_REDIRECT_URL
    }/sfdrDocument/${subFundSlug}/${article
      .split(" ")
      .join("")
      .toLowerCase()}/${template?.toLowerCase()}/${language?.toLowerCase()}`;
    await navigator.clipboard.writeText(publicUrl);

    notifySuccess("Public Url Copied Successfully.");
  };
  const handleCloseDeleteModal = () => {
    setDeleteConfirmModal(false);
    setSelectedRow(undefined);
  };
  const handleCloseRequestModal = () => {
    setRequestWordDoc(false);
    setSelectedRow(undefined);
  };
  const handleCloseCloneModal = () => {
    setCloneConfirmModal(false);
    setSelectedRow(undefined);
  };
  const handleWordFormatRequest = async (row: TableRow) => {
    setSelectedRow(row);
    if (row.id) {
      validateRequestWordDoc([
        {
          ...row.data,
          id: row.id,
          isApproved: row?.data.status === DocumentStatusEnum.approved,
          lockStatus: false,
        },
      ]) && setRequestWordDoc(true);
    }
  };
  const handleExportExcel = async (documentId: string) => {
    if (
      !selectedFund?.client_id ||
      !selectedFund.client_name ||
      !selectedFund.name
    ) {
      return;
    }
    try {
      globalDispatch(updateIsLoading(true));
      const response = await fetchData({
        endPoint: "exportExcel",
        queryParam: {
          clientId: selectedFund.client_id,
          documentIds: [documentId],
        },
        responseType: "blob",
      });
      if (!response.data) {
        notifyError("Excel export failed");
        return;
      }
      if (response.headers["content-type"].includes("application/json")) {
        const res = JSON.parse(await response.data.text());
        if (res.success === false) {
          notifyError(res.message || "Excel export failed");
        }
        return;
      }
      saveAs(response.data, getExcelExportFileName(selectedFund));
      notifySuccess("Excel exported successfully");
    } catch (error: any) {
      notifyError(error.message || "Unknown Error");
    } finally {
      globalDispatch(updateIsLoading(false));
    }
  };
  const handleArchive = async (row: TableRow) => {
    if (row.lockStatus) {
      notifyError("Cannot archive locked document.");
      return;
    }
    try {
      globalDispatch(updateIsLoading(true));
      const res = await postData({
        endPoint: "archiveByIds",
        data: {
          documentIds: [row.id],
          clientId: selectedFund?.client_id,
        },
      });
      if (res.data.success) {
        updateDocList();
        notifySuccess("Document archived successfully");
      } else {
        notifyError("Error while archiving, Please try again");
      }
    } catch (error) {
      notifyError("Error while archiving, Please try again");
    } finally {
      globalDispatch(updateIsLoading(false));
    }
  };
  return (
    <div className="page-content">
      <a ref={downloadRef} style={{ display: "none" }} />
      <TranslationRequestModal
        isOpen={isTranslationModalOpen}
        onCloseModal={handleCloseTranslationModal}
        onSubmit={handleLanguageRequest}
      />
      {selectedPreviewDoc && selectedFund && (
        <PreviewModal
          isOpen={!!selectedPreviewDoc}
          onClose={() => setSelectedPreviewDoc(undefined)}
          document={selectedPreviewDoc}
          selectedFund={selectedFund}
        />
      )}
      <Modal
        isOpen={cloneConfirmModal}
        onClose={handleCloseCloneModal}
        header="Clone Confirmation"
        height={283}
      >
        <CloneMessage>
          Please note that, cloning is done with the latest version of the
          document available. You cannot edit the older version once it is
          cloned. Are you sure you want to clone?
        </CloneMessage>
        <ConfirmationButtonContainer>
          <Button buttonType="solid" onClick={handleCloneDocument}>
            Yes
          </Button>
          <Button onClick={handleCloseCloneModal}>Cancel</Button>
        </ConfirmationButtonContainer>
      </Modal>
      <DeleteConfirmationModal
        isOpen={deleteConfirmModal}
        onCloseDeleteModal={handleCloseDeleteModal}
        onDeleteDocument={handleDeleteDocument}
        numberOfSelectedRows={1}
      />
      {selectedRow && requestWordDoc && selectedFund && userDetails && (
        <WordRequestModal
          isOpen={requestWordDoc}
          onCloseRequestModal={handleCloseRequestModal}
          userDetails={userDetails}
          selectedDocs={[
            {
              ...selectedRow.data,
              id: selectedRow.id,
              isApproved:
                selectedRow?.data.status === DocumentStatusEnum.approved,
              lockStatus: false,
            },
          ]}
          selectedFund={selectedFund}
          setRequestWordDoc={setRequestWordDoc}
        />
      )}
      <div>
        <div className="resp-table full-screen-table container">
          <div className="resp-table-header">
            <div
              className="table-header-cell col-100"
              data-switchgroup="kiids-select"
              data-switchtype="all"
              data-switchstate="unchecked"
            >
              <div className="table-header-shadow"></div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="selectAll"
                  checked={bulkSelect["selectAll"] || false}
                  onChange={() => updateSelectedCheckbox()}
                />
                <label
                  className="custom-control-label"
                  htmlFor="ddd-checkgroup-3-1"
                  onClick={(e) => updateSelectedCheckbox(e, "selectAll")}
                ></label>
              </div>
            </div>
            {tableHeaders?.map((header, index) => {
              return (
                <div
                  style={{
                    cursor:
                      sortState && sortState[header.colName]
                        ? "pointer"
                        : "text",
                  }}
                  key={`key-${index + 1}`}
                  className={`table-header-cell col-200`}
                  onClick={() => {
                    sortState &&
                      sortState[header.colName] &&
                      handleSorting(header.colName);
                  }}
                >
                  <span className="cell__title">{header.dispName}</span>
                  <span style={{ marginLeft: "4.25px" }}>
                    {sortState &&
                    sortState[header.colName] &&
                    sortState[header.colName].sortDir ? (
                      <SVGIcon
                        iconName={
                          sortState[header.colName].sortDir === "ASC"
                            ? "caretUp"
                            : "caretDown"
                        }
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              );
            })}
            <div className="table-header-cell">
              <div>Actions</div>
            </div>
          </div>
          <div className="resp-table-body container">
            {tableRows?.map((row, index) => {
              return (
                <div
                  id={`client-table-row-${index + 1}`}
                  className="resp-table-row"
                  key={`key-${index + 1}`}
                >
                  <div
                    className="table-body-cell col-100"
                    data-switchgroup="kiids-select"
                    data-switchtype="single"
                    data-statusid="5"
                  >
                    <div
                      id={`check-box-${index + 1}`}
                      className="custom-control custom-checkbox"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`ddd-checkgroup-4-${index + 1}`}
                        onChange={() => {
                          updateSelectedCheckbox({ target: null });
                        }}
                        checked={bulkSelect[row.id] || false}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`ddd-checkgroup-4-${index + 1}`}
                        onClick={(e) => {
                          updateSelectedCheckbox(e, row.id);
                        }}
                      ></label>
                    </div>
                  </div>
                  {row.dataField &&
                    row.dataField.length > 0 &&
                    row.dataField.map((cell, cellIndex) => {
                      return (
                        <Fragment key={`${cellIndex + 1}`}>
                          <div className="table-body-cell col-200">
                            <div className="colum-label">
                              {tableHeaders[cellIndex].dispName}
                            </div>
                            <div className="colum-deas">{cell.cellVal}</div>
                          </div>
                          {cellIndex + 1 === row.dataField.length && (
                            <div className="table-body-cell full-colum">
                              <div className="colum-deas">
                                <div className="action-group">
                                  <div className="action">
                                    <ActionContainer
                                      index={index + 1}
                                      tooltipContent="Edit"
                                      actionHandler={actionHandler}
                                      imageName="edit"
                                      row={row}
                                    />

                                    {row.data.language === "English" && (
                                      <ActionContainer
                                        index={index + 1}
                                        tooltipContent="Translate"
                                        actionHandler={actionHandler}
                                        imageName="translate"
                                        row={row}
                                      />
                                    )}
                                    <ActionContainer
                                      index={index + 1}
                                      tooltipContent="Download"
                                      actionHandler={actionHandler}
                                      imageName="download"
                                      row={row}
                                      tooltipTrigger="click"
                                      onPdfDownload={handleDownloadPDF}
                                    />
                                    <ActionContainer
                                      index={index + 1}
                                      tooltipContent="More Options"
                                      actionHandler={actionHandler}
                                      imageName="moreOptions"
                                      row={row}
                                      tooltipTrigger="click"
                                      onPreviousDocument={() =>
                                        handlePreviousDocument(row.sfdrId)
                                      }
                                      onArchive={handleArchive}
                                      onExportExcel={handleExportExcel}
                                      onPublicUrlClick={() =>
                                        handlePublicUrlClick(row)
                                      }
                                      onWordFormatRequest={() =>
                                        handleWordFormatRequest(row)
                                      }
                                      onDelete={() => {
                                        setDeleteConfirmModal(true);
                                        setSelectedRow(row);
                                      }}
                                      onClone={() => {
                                        setCloneConfirmModal(true);
                                        setSelectedRow(row);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientTable;
