import React from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import {
  ConfirmationButtonContainer,
  DeleteMessage,
} from "../ClientTable/styles";

type Props = {
  isOpen: boolean;
  onCloseDeleteModal: () => void;
  onDeleteDocument: () => void;
  numberOfSelectedRows: number;
};

export const DeleteConfirmationModal: React.FC<Props> = ({
  isOpen,
  onCloseDeleteModal,
  onDeleteDocument,
  numberOfSelectedRows: n,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onCloseDeleteModal}
    header="Delete Confirmation"
    height={330}
  >
    <DeleteMessage>
      <p>
        You are about to delete the latest version of the selected document
        {n > 1 ? "/s" : ""}. Please note that this action cannot be reverted.
      </p>
      <p>Are you sure you want to apply the changes?</p>
    </DeleteMessage>
    <ConfirmationButtonContainer>
      <Button
        id="delete-document-confirm"
        buttonType="solid"
        onClick={onDeleteDocument}
      >
        Delete
      </Button>
      <Button id="delete-document-cancel" onClick={onCloseDeleteModal}>
        Cancel
      </Button>
    </ConfirmationButtonContainer>
  </Modal>
);
