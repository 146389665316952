import React from "react";
import Image from "../../../../components/Image";
import { Container, LockContainer, LockText } from "./styles";

type Props = {
  isDocumetLocked: boolean;
  lockingUser: string;
};

const DocumentLockWrapper: React.FC<Props> = ({
  isDocumetLocked,
  lockingUser = "",
  children,
}) => {
  if (isDocumetLocked) {
    return (
      <>
        <Container>{children}</Container>
        <LockContainer>
          <Image name="lock" color="white" width="85" height="113" />
          <LockText>
            Document is being edited by {lockingUser}. Lock will be released
            once the user finishes editing
          </LockText>
        </LockContainer>
      </>
    );
  }
  return <>{children}</>;
};

export default DocumentLockWrapper;
