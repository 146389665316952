import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchData, postData } from "../../../services/apis";
import Context from "../../../store/context";
import {
  updateDocumentErroState,
  updateDocumentQuestion,
  updateDocumentState,
  updateIsLoading,
  updateSelectedFund,
} from "../../../store/globalActions";
import { CommentType } from "../../../types/comment";
import {
  Approval,
  ApprovalGroup,
  DocLockStatus,
  DocumentActionEnum,
  DocumentArticle,
  DocumentRecord,
  DocumentState,
  DocumentStatusEnum,
  DocumentStatusToKey,
  DocumentTemplate,
  DocumentViewModeEnum,
} from "../../../types/document-details";
import {
  QuestionsCollection,
  DisplayQuestion,
  Question,
  DocumentStaticContent,
  DocumentCommonContent,
} from "../../../types/document-question";
import { GLOBAL_CONTEXT } from "../../../types/store";
import {
  getDocumentUserPermission,
  notifyError,
  notifySuccess,
  userHasPermission,
} from "../../../utils/common";
import ArticleNinePreContractual from "../ArticleNinePreContractual";
import CommentSection, { Preset, Questions } from "../CommentSection";
import DocumentFooter from "./DocumentFooter";
import DocumentHeader from "./DocumentHeader";
import {
  Container,
  DocumentWraper,
  ExternalUrlContainer,
  LinkText,
  LoadingContainer,
} from "./styles";
import ApprovalModal from "../ApprovalModal";
import TranslationRequestModal, {
  OnSubmitData,
} from "../../listing/TranslationRequestModal";
import {
  CLIENT_URL_PREFIX,
  DOCUMENT_ADDITIONAL_INFO_URL,
  VALIDATION_ERROR_COMPONENT_CLASS_NAME,
} from "../../../lib/constants";
import Image from "../../../components/Image";
import { io } from "socket.io-client";
import DocumentLockWrapper from "./DocumentLockWrapper";
import { useIdleTimer } from "react-idle-timer";
import ArticleEightPreContractual from "../ArticleEightPreContractual";
import ArticleEightPeriodic from "../ArticleEightPeriodic";
import ArticleNinePeriodic from "../ArticleNinePeriodic";
import {
  getSelectedFundId,
  setSelectedFundId,
} from "../../../components/AppHeader/services";
import { FundList } from "../../../types/fund";
import DocumentConfigurationModal from "../DocumentConfigurationModal";
import {
  getDocumentError,
  getStaticContentText,
  removedDocumentIdFromState,
} from "./service";
import { Language } from "../../../types/document-question";
import AutoFillComponent from "../CommonComponents/AutofillComponent/index";
import Modal from "../../../components/Modal";
import { ExternalRedirectModal } from "../ExternalRedirectModal";
import parse from "html-react-parser";
import { updateLockStatus } from "../../../services/document";
import { RESET_DOCUMENT_DIFFERENCE } from "../../../store/types";
import _ from "lodash";
import plausibleInstance from "../../../services/plausible";

type Props = {
  mode: DocumentViewModeEnum;
  isPDF?: boolean;
  showWaterMark?: boolean;
};

const DocumentEditor: React.FC<Props> = ({
  mode,
  isPDF = false,
  showWaterMark,
}) => {
  const [queryParams] = useSearchParams();
  const { clientId, id } = useParams<{ id: string; clientId: string }>();
  const [subFundId, setSubFundId] = useState<string>();
  const {
    globalState: {
      selectedFund,
      documentState,
      userDetails,
      userPermission,
      fundList,
      approverGroup,
      documentErrorState,
      documentDifference,
      errorMessageFound,
    },
    globalDispatch: dispatchEvent,
  } = useContext<GLOBAL_CONTEXT>(Context);
  const navigate = useNavigate();
  const [document, setDocument] = useState<DocumentRecord>();
  const [questions, setQuestions] = useState<Question[]>();
  const [article, setArticle] = useState<DocumentArticle>();
  const [template, setTemplate] = useState<DocumentTemplate>();
  const [approvalGroup, setApprovalGroup] = useState<Approval[]>(approverGroup);
  const [selectedApprovalGroup, setSelectedApprovalGroup] = useState<any>(
    approverGroup?.[0]?.approvalGroup?.[0]
  );
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [isApprovalGroupModal, setIsApprovalGroupModal] = useState(false);
  const [isConfigurationModal, setIsConfigurationModal] = useState(false);
  const { globalDispatch } = useContext<GLOBAL_CONTEXT>(Context);
  const [loading, setLoading] = useState(false);
  const [displayQuestions, setDisplayQuestions] = useState<DisplayQuestion>();
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [isPreviousAnswersOpen, setIsPreviousAnswersOpen] = useState(false);
  const [questionDetails, setQuestionDetails] = useState<Question>();
  const [isTranslationOpen, setIsTranslationOpen] = useState(false);
  const [commentPreset, setCommentPreset] = useState<Preset>();
  const [questionCommentArray, setQuestionCommentArray] = useState<Questions[]>(
    []
  );
  const [isDocumetLocked, setIsDocumetLocked] = useState(false);
  const [isDocumetLockedUserName, setIsDocumetLockedUserName] = useState("");
  const [autoSaveLoading, setAutoSaveLoading] = useState(false);
  const [isIdle, setIsIdle] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [staticContent, setStaticContent] = useState<DocumentStaticContent>();
  const [commonContent, setCommonContent] = useState<DocumentCommonContent>();
  const [externalRedirectModalOpen, setExternalRedirectModalOpen] =
    useState(false);
  useEffect(() => {
    if (userDetails?.clientId && selectedFund?.fund_level_one_id && subFundId) {
      plausibleInstance.trackPageview(
        {},
        {
          props: {
            client_id: userDetails.clientId,
            fund_id: selectedFund.fund_level_one_id,
            fund_slug: selectedFund.fund_slug,
            sub_fund_id: subFundId,
          },
        }
      );
    }
  }, [selectedFund, userDetails, subFundId]);
  useEffect(() => {
    if (approverGroup?.length) {
      setApprovalGroup(approverGroup);
      if (approverGroup?.[0]?.approvalGroup?.[0]) {
        setSelectedApprovalGroup(approverGroup?.[0]?.approvalGroup?.[0]);
      }
    }
  }, [approverGroup]);

  useEffect(() => {
    validateDocumentState();
  }, [documentState, questions]);

  const validateDocumentState = () => {
    if (questions?.length && documentState) {
      const errorState = getDocumentError(questions, documentState);
      const documentError = {
        isFailed: !!errorState.find((state) => state.error),
        errors: errorState,
      };
      globalDispatch(updateDocumentErroState(documentError));
    }
  };

  useEffect(() => {
    if (
      !isDocumetLocked &&
      documentErrorState.errors?.length &&
      !errorMessageFound &&
      !loading
    ) {
      setTimeout(() => {
        const comp = window.document.getElementsByClassName(
          VALIDATION_ERROR_COMPONENT_CLASS_NAME
        );
        for (let i = 0; i < comp.length; i++) {
          if (comp[i].innerHTML) {
            globalDispatch({
              type: "UPDATE_ERROR_MESSAGE_FOUND",
              payload: {
                errorMessageFound: true,
              },
            });
            comp[i].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            break;
          }
        }
      }, 1000);
    }
  }, [isDocumetLocked, documentErrorState, errorMessageFound, loading]);

  const onAutoSave = async (documentState: DocumentState | undefined) => {
    if (documentState) {
      setSavingInProgress(true);
      globalDispatch({ type: RESET_DOCUMENT_DIFFERENCE });
      validateDocumentState();
      if (id !== documentState?._id) {
        return;
      }
      try {
        setAutoSaveLoading(true);
        if (isDocumetLocked) {
          return;
        }
        setIsIdle(false);
        // delete document id from documentState object
        const documentStateWithoutId =
          removedDocumentIdFromState(documentState);
        const saveResp = await postData({
          endPoint: "updateDocument",
          data: {
            clientId: clientId,
            documentId: id,
            documentDetail: {
              ...document,
              documentState: documentStateWithoutId.documentState,
            },
          },
        });
        if (saveResp.data.message !== "Success") {
          console.error("Error in saving document");
        }
      } catch (error) {
        notifyError("Error in saving document");
      } finally {
        setSavingInProgress(false);
        setTimeout(() => {
          setAutoSaveLoading(false);
        }, 1500);
      }
    }
  };
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (
      (documentDifference && Object.keys(documentDifference).length) ||
      savingInProgress
    ) {
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    }
  };
  const debounceFunc = useMemo(
    () =>
      _.debounce(() => {
        if (documentDifference && Object.keys(documentDifference).length) {
          onAutoSave({ ...document, documentState: documentDifference });
        }
      }, 2500),
    [documentDifference, onAutoSave, document]
  );

  useEffect(() => {
    // add page leave warning message if documentDifference is not empty
    window.addEventListener("beforeunload", handleBeforeUnload);
    debounceFunc();
    return () => {
      debounceFunc.cancel();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [documentDifference, savingInProgress, debounceFunc]);

  const userType = useMemo(
    () => userDetails && getDocumentUserPermission(approvalGroup, userDetails),
    [approvalGroup, userDetails]
  );

  const fetchDocument = async () => {
    try {
      setLoading(true);
      const response = await fetchData({
        endPoint: "getDocumentById",
        queryParam: {
          _id: id,
          clientId: clientId,
        },
      });
      const doc = {
        ...response.data.data,
        documentState:
          { id: response.data.data._id, ...response.data.data.documentState } ||
          {},
      };
      setDocument(doc);
      setTemplate(doc.template);
      setArticle(doc.article);
      setSubFundId(doc.subFundId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchQuestions = async () => {
    try {
      if (article && template) {
        const questionResp = await fetchData({
          endPoint: "getTemplateDetails",
          queryParam: { article, template },
        });
        const ques = questionResp.data.data as QuestionsCollection;
        setQuestions(ques.questions);
        globalDispatch(updateDocumentQuestion(ques.questions));
        if (ques) {
          const disQues = [...ques.questions].reduce(
            (a, v) => ({ ...a, [v.id]: v }),
            {} as DisplayQuestion
          );
          const language: string =
            (document && Language?.[document.language]) || "english";
          const commentableQuestion = ques.commentableQuestionIds.map(
            (q) =>
              ({
                id: q,
                label: disQues[q]?.label[language] || "",
              } as Questions)
          );
          setStaticContent(ques.staticContent);
          setCommonContent(ques.commonContent);
          setQuestionCommentArray(commentableQuestion);
          disQues && setDisplayQuestions(disQues);
        } else {
          setDisplayQuestions(undefined);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (clientId && id) {
      fetchDocument();
    }
  }, [clientId, id, isDocumetLocked]);

  useEffect(() => {
    if (article && template) {
      fetchQuestions();
    }
  }, [article && template]);

  useEffect(() => {
    globalDispatch(updateDocumentState(document?.documentState));
    globalDispatch({ type: RESET_DOCUMENT_DIFFERENCE });
  }, [document]);

  useEffect(() => {
    const selectedSessionFundId = getSelectedFundId();
    if (document?.fundId && selectedSessionFundId != document?.fundId) {
      setSelectedFundId(document?.fundId);
      const selectedFund = (fundList as FundList[]).find(
        (fund) => fund.id === Number(document.fundId)
      );
      selectedFund ? globalDispatch(updateSelectedFund(selectedFund)) : null;
    }
  }, [fundList, document]);

  const toggleQuestionCommentSection = (preset?: Preset) => {
    setIsCommentSectionOpen(!isCommentSectionOpen);
    setCommentPreset(preset);
  };
  const togglePreviousAnswersSection = (qId: string) => {
    setIsPreviousAnswersOpen(!isPreviousAnswersOpen);
    const questionDetails = displayQuestions && displayQuestions[qId];
    questionDetails && setQuestionDetails(questionDetails);
  };

  const actionPermission = useMemo(() => {
    if (userPermission && userType && document?.status) {
      const commentPermission = userHasPermission(
        {
          userType,
          docStatus: DocumentStatusToKey[document.status],
          docAction: DocumentActionEnum.comment,
        },
        userPermission
      );
      const editPermission = userHasPermission(
        {
          userType,
          docStatus: DocumentStatusToKey[document.status],
          docAction: DocumentActionEnum.edit,
        },
        userPermission
      );
      const previousAnsPermission = userHasPermission(
        {
          userType,
          docStatus: DocumentStatusToKey[document.status],
          docAction: DocumentActionEnum.previous_answers,
        },
        userPermission
      );
      return {
        commentPermission,
        editPermission,
        previousAnsPermission,
      };
    } else {
      return {
        commentPermission: false,
        editPermission: false,
        previousAnsPermission: false,
      };
    }
  }, [userType, document, userPermission]);

  useEffect(() => {
    if (id) {
      const isInCondition =
        mode === DocumentViewModeEnum.edit && actionPermission.editPermission;
      if (isInCondition) {
        updateLockStatus(id, "lock");
      }
      const onUnload = async () => {
        if (isInCondition) {
          await updateLockStatus(id, "unlock");
        }
      };
      return () => {
        onUnload();
      };
    }
  }, [mode, actionPermission.editPermission]);

  useEffect(() => {
    if (
      id &&
      userDetails &&
      mode === DocumentViewModeEnum.edit &&
      actionPermission.editPermission
    ) {
      const socket = io(
        `${process.env.REACT_APP_SOCKET_ENDPOINT}/documentsLockStatus`,
        {
          withCredentials: true,
        }
      );
      socket.emit("initialise", {
        documentIds: [id],
        userDetails,
      });
      socket.on("lock_status", (dataList: DocLockStatus[]) => {
        const data = dataList[0];
        const isLock = !(
          (data?.userDetails?.userId &&
            data?.userDetails?.userId === userDetails?.userId) ||
          !data?.userDetails?.userId
        );
        setIsDocumetLocked(isLock);
        if (!isLock && !isIdle) {
          updateLockStatus(id, "lock", userDetails?.clientId);
        }
        setIsDocumetLockedUserName(data?.userDetails?.userName || "");
      });
      socket.on("document_fetching", () => {
        fetchDocument();
      });
      return () => {
        updateLockStatus(id, "unlock");
        socket.disconnect();
      };
    }
  }, [id, userDetails, mode, actionPermission.editPermission, isIdle]);

  const handleOnIdle = useCallback(() => {
    setIsIdle(true);
    setTimeout(() => {
      if (
        mode === DocumentViewModeEnum.edit &&
        actionPermission.editPermission &&
        id
      ) {
        updateLockStatus(id, "unlock", userDetails?.clientId);
      }
    }, 500);
  }, [mode, actionPermission.editPermission]);

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 mins
    onIdle: handleOnIdle,
    debounce: 500,
    events: ["mousedown", "mousemove", "keypress", "scroll", "touchstart"],
    stopOnIdle: true,
  });

  const renderDocument = useMemo(() => {
    if (loading) {
      return <LoadingContainer />;
    }
    if (document?.archivedAt && mode === DocumentViewModeEnum.edit) {
      navigate(CLIENT_URL_PREFIX + "listing", {
        replace: true,
        state: {
          message:
            "You have been redirected to the listing page because the document is archived.",
        },
      });
    }
    switch (true) {
      case document?.article === DocumentArticle.Article8 &&
        document?.template === DocumentTemplate.Periodic:
        return document &&
          displayQuestions &&
          staticContent &&
          commonContent ? (
          <ArticleEightPeriodic
            document={document}
            mode={mode}
            updateDoc={setDocument}
            toggleQuestionCommentSection={toggleQuestionCommentSection}
            togglePreviousAnswersSection={togglePreviousAnswersSection}
            showCommentButton={
              mode === DocumentViewModeEnum.edit &&
              actionPermission.commentPermission
            }
            showPreviousAns={
              mode === DocumentViewModeEnum.edit &&
              actionPermission.previousAnsPermission
            }
            canEdit={actionPermission.editPermission}
            displayQuestions={displayQuestions}
            isLocked={isDocumetLocked}
            showWaterMark={showWaterMark}
            staticContent={staticContent}
            commonContent={commonContent}
          />
        ) : null;
      case document?.article === DocumentArticle.Article8 &&
        document?.template === DocumentTemplate.PreContractual:
        return document &&
          displayQuestions &&
          staticContent &&
          commonContent ? (
          <ArticleEightPreContractual
            document={document}
            mode={mode}
            updateDoc={setDocument}
            toggleQuestionCommentSection={toggleQuestionCommentSection}
            togglePreviousAnswersSection={togglePreviousAnswersSection}
            showCommentButton={
              mode === DocumentViewModeEnum.edit &&
              actionPermission.commentPermission
            }
            showPreviousAns={
              mode === DocumentViewModeEnum.edit &&
              actionPermission.previousAnsPermission
            }
            canEdit={actionPermission.editPermission}
            displayQuestions={displayQuestions}
            isLocked={isDocumetLocked}
            commonContent={commonContent}
            showWaterMark={showWaterMark}
            staticContent={staticContent}
          />
        ) : null;
      case document?.article === DocumentArticle.Article9 &&
        document?.template === DocumentTemplate.Periodic:
        return document &&
          displayQuestions &&
          staticContent &&
          commonContent ? (
          <ArticleNinePeriodic
            document={document}
            mode={mode}
            updateDoc={setDocument}
            toggleQuestionCommentSection={toggleQuestionCommentSection}
            togglePreviousAnswersSection={togglePreviousAnswersSection}
            showCommentButton={
              mode === DocumentViewModeEnum.edit &&
              actionPermission.commentPermission
            }
            showPreviousAns={
              mode === DocumentViewModeEnum.edit &&
              actionPermission.previousAnsPermission
            }
            canEdit={actionPermission.editPermission}
            displayQuestions={displayQuestions}
            isLocked={isDocumetLocked}
            showWaterMark={showWaterMark}
            staticContent={staticContent}
            commonContent={commonContent}
          />
        ) : null;
      case document?.article === DocumentArticle.Article9 &&
        document?.template === DocumentTemplate.PreContractual:
        return document &&
          displayQuestions &&
          staticContent &&
          commonContent ? (
          <ArticleNinePreContractual
            document={document}
            mode={mode}
            updateDoc={setDocument}
            toggleQuestionCommentSection={toggleQuestionCommentSection}
            togglePreviousAnswersSection={togglePreviousAnswersSection}
            showCommentButton={
              mode === DocumentViewModeEnum.edit &&
              actionPermission.commentPermission
            }
            showPreviousAns={
              mode === DocumentViewModeEnum.edit &&
              actionPermission.previousAnsPermission
            }
            canEdit={actionPermission.editPermission}
            displayQuestions={displayQuestions}
            isLocked={isDocumetLocked}
            showWaterMark={showWaterMark}
            staticContent={staticContent}
            commonContent={commonContent}
          />
        ) : null;
      default:
        return null;
    }
  }, [
    document,
    loading,
    actionPermission,
    displayQuestions,
    isDocumetLocked,
    showWaterMark,
  ]);

  const onSave = async (showNotification = true) => {
    try {
      if (isDocumetLocked || !documentState || !documentDifference) {
        return;
      }
      dispatchEvent({ type: RESET_DOCUMENT_DIFFERENCE });
      setSavingInProgress(true);
      globalDispatch(updateIsLoading(true));
      setIsIdle(false);
      validateDocumentState();
      const documentStateWithoutId =
        removedDocumentIdFromState(documentDifference);
      const saveResp = await postData({
        endPoint: "updateDocument",
        data: {
          clientId: clientId,
          documentId: id,
          documentDetail: {
            ...document,
            documentState: documentStateWithoutId,
          },
        },
      });
      if (saveResp.data.message === "Success") {
        showNotification && notifySuccess("Document saved successfully");
      } else {
        notifyError("Error in saving document");
      }
    } catch (error) {
      notifyError("Error in saving document");
    } finally {
      setSavingInProgress(false);
      globalDispatch(updateIsLoading(false));
    }
  };

  const onFiled = async () => {
    if (userDetails && document && userType && userPermission) {
      const filedPermission = userHasPermission(
        {
          userType,
          docStatus: DocumentStatusToKey[document.status],
          docAction: DocumentActionEnum.filed,
        },
        userPermission
      );
      if (filedPermission) {
        try {
          globalDispatch(updateIsLoading(true));
          const resp = await postData({
            endPoint: "changeStatus",
            data: {
              clientId: clientId,
              documentId: id,
              status: DocumentStatusEnum.filed,
            },
          });
          if (resp.status === 200) {
            notifySuccess("Document has been filed successfully");
          } else {
            notifyError("Error in updating");
          }
          fetchDocument();
        } catch (error) {
          notifyError("Error in updating the document status");
        } finally {
          globalDispatch(updateIsLoading(false));
        }
      } else {
        notifyError("Don't have required permission");
      }
    }
  };

  const onSubmitForApproval = async () => {
    // Document translate feature is not available for this June 2022 release
    // if (document?.language === "English") toggleTranslateModal();
    try {
      globalDispatch(updateIsLoading(true));
      await onSave(false);
      const saveResp = await postData({
        endPoint: "changeStatus",
        data: {
          clientId: clientId,
          documentId: document?._id,
          status: DocumentStatusEnum.awaiting_approval,
        },
      });
      if (saveResp.status === 200) {
        notifySuccess("Document submitted for approval");
      } else {
        notifyError("Error in submitting document");
      }
      fetchDocument();
    } catch (error) {
      notifyError("Error in submitting document");
    } finally {
      globalDispatch(updateIsLoading(false));
    }
  };

  const onSendBackToEditor = async () => {
    try {
      globalDispatch(updateIsLoading(true));
      if (id) await updateLockStatus(id, "unlock", userDetails?.clientId);
      const saveResp = await postData({
        endPoint: "changeStatus",
        data: {
          clientId: clientId,
          documentId: document?._id,
          status: DocumentStatusEnum.awaiting_comments_review,
        },
      });
      if (saveResp.status === 200) {
        notifySuccess("Document changed to Awaiting Comments Review");
      } else {
        notifyError("Error in updating");
      }
      fetchDocument();
    } catch (error) {
      notifyError("Error in updating");
    } finally {
      globalDispatch(updateIsLoading(false));
    }
  };

  const onApprove = async () => {
    if (!approvalGroup.length) {
      notifyError("No approval group assigned, Please contact Sfdr admin");
      return;
    }
    setIsApprovalGroupModal(true);
  };

  const toggleCommentSection = () => {
    setIsCommentSectionOpen(!isCommentSectionOpen);
    if (id) {
      const preset: Preset = {
        commentType: CommentType.generic,
        documentId: id,
      };
      setCommentPreset(preset);
    }
  };
  const togglePreviousAnswers = () => {
    setIsPreviousAnswersOpen(!isPreviousAnswersOpen);
  };

  const toggleIsApprovalGroupModal = () => {
    if (!approvalGroup.length) {
      notifyError("No approval group assigned, Please contact Sfdr admin");
      return;
    }
    setIsApprovalGroupModal(!isApprovalGroupModal);
  };

  const toggleConfigurationModal = () => {
    setIsConfigurationModal(!isConfigurationModal);
  };

  const redirectToListing = () => {
    navigate(CLIENT_URL_PREFIX + "listing", { replace: true });
  };

  const onFinalApprove = async (selectedApprovalGroups: ApprovalGroup[]) => {
    try {
      globalDispatch(updateIsLoading(true));
      const groups = selectedApprovalGroups.map((groupDetails) => {
        return {
          ...groupDetails,
          approvalUsers: [
            { label: userDetails?.userName, value: userDetails?.userId },
          ],
        };
      });
      if (document?.status === DocumentStatusEnum.edited_by_approver) {
        await onSave(false);
      }
      const response = await postData({
        endPoint: "updateClientApprovalDetails",
        data: {
          clientId: clientId,
          approvalGroup: groups,
          documentId: document?._id,
        },
      });
      if (response.data.message === "Success") {
        notifySuccess("Document has been approved");
      } else {
        notifyError("Error in Approving document");
      }
      fetchDocument();
      toggleIsApprovalGroupModal();
      // Document translate feature is not available for this June 2022 release
      // if (document?.language === "English") toggleTranslateModal();
    } catch (error) {
      notifyError("Error in Approving document");
    } finally {
      globalDispatch(updateIsLoading(false));
    }
  };

  const onTranslationSubmit = async (data: OnSubmitData) => {
    try {
      globalDispatch(updateIsLoading(true));
      const allResps = await Promise.all(
        data.selectedLanguages.map(
          async (lang) =>
            await postData({
              endPoint: "createNewDocument",
              data: {
                fundId: selectedFund?.id,
                fundName: selectedFund?.name,
                clientId: clientId,
                subFundId: document?.subFundId,
                subFundName: document?.subFundName,
                article: document?.article,
                template: document?.template,
                language: lang,
                isTranslationRequested: data.shouldHandleTranslation,
              },
            })
        )
      );
      const isSuccess = allResps.every((resp) => resp.data?.success);
      if (isSuccess) {
        notifySuccess("Document Requested For Translation");
      }
    } finally {
      globalDispatch(updateIsLoading(false));
      redirectToListing();
    }
  };

  const showApprovalButton =
    document?.status === DocumentStatusEnum.approved ||
    document?.status === DocumentStatusEnum.awaiting_approval;

  useEffect(() => {
    if (queryParams.has("commentType")) {
      if (queryParams.has("questionId")) {
        const questionId = queryParams.get("questionId");
        if (id && questionId) {
          const preset: Preset = {
            commentType: CommentType.questionLevel,
            documentId: id,
            questionId: questionId.toString(),
          };
          toggleQuestionCommentSection(preset);
        }
      } else {
        toggleCommentSection();
      }
    }
  }, [queryParams]);

  const handleExternalRedirection = () => {
    setExternalRedirectModalOpen(true);
  };

  return (
    <>
      {clientId && id && (
        <DocumentLockWrapper
          isDocumetLocked={isDocumetLocked}
          lockingUser={isDocumetLockedUserName}
        >
          <style>{`@page { margin: 40px 40px 80px 40px !important; } @media print {.no-print{display: none}}`}</style>
          <Container isPreview={isPreviewOpen}>
            {isTranslationOpen && (
              <TranslationRequestModal
                isOpen={isTranslationOpen}
                onCloseModal={redirectToListing}
                onSubmit={onTranslationSubmit}
              />
            )}
            {isPreviousAnswersOpen && questionDetails && (
              <AutoFillComponent
                document={document}
                questionDetails={questionDetails}
                isOpen={isPreviousAnswersOpen}
                onClose={togglePreviousAnswers}
              />
            )}
            {isCommentSectionOpen && (
              <CommentSection
                isOpen={isCommentSectionOpen}
                onClose={toggleCommentSection}
                preset={commentPreset}
                questions={questionCommentArray}
              />
            )}
            {document && isApprovalGroupModal && (
              <ApprovalModal
                isOpen={isApprovalGroupModal}
                onClose={toggleIsApprovalGroupModal}
                approvalGroup={approvalGroup}
                isApproved={document?.status === DocumentStatusEnum.approved}
                onApprove={onFinalApprove}
                document={document}
                userType={userType}
              />
            )}
            {document && isConfigurationModal && (
              <DocumentConfigurationModal
                isOpen={isConfigurationModal}
                onClose={toggleConfigurationModal}
                documentId={document._id}
                clientId={clientId}
              />
            )}
            {mode === DocumentViewModeEnum.edit && (
              <>
                <DocumentHeader
                  document={document}
                  onComment={toggleCommentSection}
                  showComment={actionPermission.commentPermission}
                  onApproval={toggleIsApprovalGroupModal}
                  showApproval={showApprovalButton}
                  isSaving={autoSaveLoading}
                  onPreviewToggle={(val) => setIsPreviewOpen(val)}
                  onConfiguration={toggleConfigurationModal}
                />
                {commonContent?.commonLinks && document && (
                  <ExternalUrlContainer isDocumetLocked={isDocumetLocked}>
                    <Image name="linkInfo" />
                    <LinkText onClick={handleExternalRedirection}>
                      {parse(
                        getStaticContentText(
                          "RKTbEThucx",
                          commonContent?.commonLinks,
                          document?.language
                        )
                      )}
                    </LinkText>
                  </ExternalUrlContainer>
                )}
              </>
            )}
            <DocumentWraper
              id="document-container"
              isPDF={isPDF}
              isDocumetLocked={isDocumetLocked}
            >
              {renderDocument}
            </DocumentWraper>
            {mode === DocumentViewModeEnum.edit && (
              <DocumentFooter
                document={document}
                onSave={onSave}
                onApprove={onApprove}
                onSendBackToEditor={onSendBackToEditor}
                onSubmitForApproval={onSubmitForApproval}
                userType={userType}
                isDocumetLocked={isDocumetLocked}
                lockedUser={isDocumetLockedUserName}
                isFailed={documentErrorState.isFailed}
                onFiled={onFiled}
              />
            )}
            <Modal
              header="Redirection Alert"
              isOpen={externalRedirectModalOpen}
              onClose={() => setExternalRedirectModalOpen(false)}
            >
              <ExternalRedirectModal
                url={DOCUMENT_ADDITIONAL_INFO_URL}
                closeModal={() => setExternalRedirectModalOpen(false)}
              />
            </Modal>
          </Container>
        </DocumentLockWrapper>
      )}
    </>
  );
};

export default DocumentEditor;
