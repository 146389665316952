import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";

export const ImageWrapper = styled.span`
  padding: 0 ${spacingIncrement(10)} 0 0;
`;

export const ButtonContainer = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  :active,
  :focus {
    outline: none;
  }
`;

export const ToolTipContainer = styled.div`
  background: white;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.div<{ minWidth?: string }>`
  min-width: ${(props) =>
    props.minWidth ? props.minWidth : spacingIncrement(200)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacingIncrement(10)} ${spacingIncrement(15)};
  :not(:last-child) {
    border-bottom: 1px solid #f6f6f6;
  }
  :hover {
    background: #f6f6f6;
  }
`;

export const NameContainer = styled.span`
  padding-left: ${spacingIncrement(10)};
`;

export const DeleteMessage = styled.div`
  padding: ${spacingIncrement(20)} ${spacingIncrement(32)};
  font-size: ${spacingIncrement(18)};
  font-weight: 400;
`;

export const CloneMessage = styled.div`
  padding: ${spacingIncrement(20)} ${spacingIncrement(32)};
  font-size: ${spacingIncrement(18)};
  font-weight: 400;
`;

export const ConfirmationButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacingIncrement(20)};
  padding: ${spacingIncrement(20)} ${spacingIncrement(32)} 0
    ${spacingIncrement(32)};
`;
export const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacingIncrement(20)};
`;
export const TranslationTooltipContainer = styled.div`
  padding: ${spacingIncrement(15)};
  width: ${spacingIncrement(400)};
  word-break: break-word;
`;
