import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import Modal, { defaultStyle } from "../../../../components/Modal";
import Select from "../../../../components/Select";
import { CLIENT_URL_PREFIX } from "../../../../lib/constants";
import { fetchData, postData } from "../../../../services/apis";
import Context from "../../../../store/context";
import {
  DocumentArticle,
  DocumentRecord,
  DocumentStatusEnum,
  DocumentTemplate,
} from "../../../../types/document-details";
import { FundList } from "../../../../types/fund";
import { GLOBAL_CONTEXT, GLOBAL_DISPATCH } from "../../../../types/store";
import {
  spacingIncrement,
  notifySuccess,
  notifyError,
} from "../../../../utils/common";
import { getDownloadableFileName } from "../../../../services/clientTable";
import { getDocHeadingText, getDocumentApprovedDate } from "../service";
import { DownloadButton, PdfWrapper } from "./styles";
import DownloadModal from "../../CommonComponents/DownloadModal";
import { saveAs } from "file-saver";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  document: DocumentRecord;
  selectedFund: FundList;
};

const PreviewModal: React.FC<Props> = ({ isOpen, onClose, document }) => {
  const [pdfData, setPdfData] = useState<string>();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<DocumentRecord>(document);
  const [previousVersion, setPreviousVersion] = useState<DocumentRecord[]>([]);
  const [selectedPreviousVersion, setSelectedPreviousVersion] =
    useState<DocumentRecord>();
  const [documentLoading, setDocumentLoading] = useState(false);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const {
    globalState: { selectedFund, userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);

  const getPreviousVersions = async () => {
    if (selectedFund?.client_id) {
      try {
        setDocumentLoading(true);
        const response = await fetchData({
          endPoint: "getPreviousVersions",
          queryParam: {
            clientId: selectedDoc?.clientId,
            template: document.template,
            article: document.article,
            subFundId: document.subFundId,
            fundId: selectedFund?.id,
            language: document.language,
            isPreviousVersion: true,
          },
        });
        setPreviousVersion(response.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setDocumentLoading(false);
      }
    }
  };

  useEffect(() => {
    getPreviousVersions();
  }, [selectedFund]);

  const onDownloadPDF = async (
    document: DocumentRecord,
    isApproved = false,
    unFormatted = false,
    showPageNumber = true
  ) => {
    try {
      setDownloadLoading(true);
      const response = await postData({
        endPoint: "generatePdf/single",
        data: {
          clientId: userDetails?.clientId,
          documentId: document._id,
          url: `${window.location.origin}${CLIENT_URL_PREFIX}document/view/${
            selectedFund?.client_id
          }/${document._id}/pdf/${
            !isApproved ? (unFormatted ? "" : "showWaterMark") : ""
          }`,
          showPageNumber: showPageNumber,
          unFormatted: unFormatted,
        },
        responseType: "blob",
      });
      const subFundName = document.subFundName;
      const temp = document.template as DocumentTemplate;
      const art = document.article as DocumentArticle;
      const status = document.status as DocumentStatusEnum;
      const language = document.language;
      const version = document.approvedVersion
        ? document.approvedVersion
        : null;
      saveAs(
        response.data,
        `${getDownloadableFileName(
          subFundName,
          art,
          temp,
          status,
          language,
          version
        )}.pdf`
      );
      setDownloadLoading(false);
      notifySuccess("Requested document is downloaded successfully");
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
      notifyError("Error occured while downloading, Please try again");
    }
  };

  const isApproved =
    selectedDoc?.status === DocumentStatusEnum.approved ||
    selectedDoc?.status === DocumentStatusEnum.filed;
  const onUnformattedPdfDownload = (showPageNumber: boolean) => {
    onDownloadPDF(selectedDoc, isApproved, true, showPageNumber);
  };

  const onPdfDownload = (showPageNumber: boolean) => {
    onDownloadPDF(selectedDoc, isApproved, false, showPageNumber);
  };

  const onPreview = () => {
    selectedPreviousVersion && setSelectedDoc(selectedPreviousVersion);
  };

  const onSelectPreviousVersion = (id: string) => {
    const doc = previousVersion.find((doc) => doc._id === id);
    setSelectedPreviousVersion(doc);
    setIsDownloadOpen(false);
  };

  if (!selectedFund) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={getDocHeadingText(selectedDoc)}
      style={{
        ...defaultStyle,
        content: {
          ...defaultStyle.content,
          width: 1200,
          height: 800,
          transform: "translate(-50%, -38%)",
        },
        overlay: {
          ...defaultStyle.overlay,
        },
      }}
      leftHeaderContainer={
        <>
          <DownloadModal
            isOpen={isDownloadOpen}
            setIsDownloadOpen={setIsDownloadOpen}
            onPdfDownload={onPdfDownload}
            onUnformattedPdfDownload={onUnformattedPdfDownload}
          />
          <Select
            id="previous-version-select"
            options={previousVersion.map((doc, index) => ({
              label: `version${index + 1}-${getDocumentApprovedDate(
                doc
              )?.format("DD/MM/YYYY")}`,
              value: doc._id,
            }))}
            noOptionsMessage={() => "No previous version found"}
            placeholder="Select Previous Version"
            isLoading={documentLoading}
            styles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: spacingIncrement(16),
                color: "#303246",
              }),
              container: (provided) => ({
                ...provided,
                width: spacingIncrement(230),
              }),
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => onSelectPreviousVersion(e.value)}
          />
          <Button
            id="preview-button-on-listing-page"
            disabled={
              downloadLoading ||
              selectedDoc._id === selectedPreviousVersion?._id ||
              !previousVersion.length
            }
            buttonType="solid"
            onClick={onPreview}
          >
            Preview
          </Button>
          <DownloadButton
            id="download-button-in"
            loading={downloadLoading}
            buttonType="solid"
            onClick={() => setIsDownloadOpen(true)}
          >
            Download
          </DownloadButton>
          <a
            ref={downloadRef}
            style={{ display: "none" }}
            href={pdfData}
            download={getDownloadableFileName(
              selectedDoc.subFundName,
              selectedDoc.article,
              selectedDoc.template,
              selectedDoc?.status,
              selectedDoc?.language,
              selectedDoc?.approvedVersion
            )}
          />
        </>
      }
      containerStyles={{
        height: "85%",
      }}
    >
      <div id="preview-modal" />
      <PdfWrapper>
        <iframe
          src={`${window.location.origin}${CLIENT_URL_PREFIX}document/view/${selectedDoc.clientId}/${selectedDoc._id}/pdf`}
          frameBorder="0"
          width="100%"
          height="100%"
        />
        {/* {pdfLoading ? (
          <div>Loading PDF...</div>
        ) : pdfData ? (
          <Document
            loading="Loading PDF..."
            noData="No PDF found"
            file={pdfData}
            onLoadSuccess={onSuccess}
          >
            <Page pageNumber={1} width={1000} />
          </Document>
        ) : null} */}
      </PdfWrapper>
    </Modal>
  );
};

export default PreviewModal;
