export enum CommentType {
  questionLevel = "questionLevel",
  generic = "generic",
}

export type AddCommentsReqBody =
  | {
      documentId: string;
      type: CommentType.questionLevel;
      questionId: string;
      comment: string;
    }
  | {
      documentId: string;
      type: CommentType.generic;
      comment: string;
    };

export interface CommentResponse {
  _id: string;
  documentId: string;
  generic: Generic[];
  questionLevel: QuestionLevel;
}

export interface Generic {
  _id: string;
  comment: string;
  commentedBy: CommentedBy;
  createdAt: string;
  updatedAt: string;
}

export interface CommentedBy {
  userId: number;
  userName: string;
}

export interface QuestionLevel {
  [key: string]: Generic[];
}
