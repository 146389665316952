import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { ImageName } from "../../components/Image/types";
import { Container, StyledImage, Text, TextWrapper } from "./styles";

type Props = {
  width?: number;
  paddingRight?: number;
  paddingLeft?: number;
  disabled?: boolean;
  iconName?: ImageName;
  iconWidth?: number;
  iconHeight?: number;
  color?: string;
};

const InfoToolTip: React.FC<Props> = ({
  children,
  width,
  paddingRight = 10,
  paddingLeft,
  disabled,
  iconName = "info",
  iconWidth = 20,
  iconHeight = 20,
  color = "#c00052",
}) => {
  const { triggerRef, setTooltipRef, setTriggerRef, getTooltipProps, visible } =
    usePopperTooltip({
      trigger: "hover",
    });

  if (disabled) {
    return null;
  }
  return (
    <>
      <Container
        ref={setTriggerRef}
        onMouseEnter={() => triggerRef?.click()}
        paddingRight={paddingRight}
        paddingLeft={paddingLeft}
      >
        <StyledImage name={iconName} height={iconHeight} width={iconWidth} />
      </Container>
      {visible && (
        <TextWrapper
          width={width || 500}
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <Text color={color}>{children}</Text>
        </TextWrapper>
      )}
    </>
  );
};

export default InfoToolTip;
