import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Label = styled.span`
  font-size: ${spacingIncrement(16)};
  font-weight: 600;
  color: #303246;
  padding-bottom: ${spacingIncrement(8)};
`;

export const DropDownContainer = styled.div`
  padding-right: ${spacingIncrement(10)};
`;
