import styled, { css } from "styled-components";
import Image from "../../components/Image";
import { spacingIncrement } from "../../utils/common";

export const Container = styled.div<{
  paddingRight?: number;
  paddingLeft?: number;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: ${({ paddingRight }) => spacingIncrement(paddingRight || 0)};
  ${(props) =>
    props.paddingLeft &&
    css`
      padding-left: ${spacingIncrement(props.paddingLeft)};
    `}
`;

export const StyledImage = styled(Image)``;

export const Text = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: ${spacingIncrement(12)};
  font-style: italic;
`;

export const TextWrapper = styled.div<{ width?: number }>`
  width: ${(props) => (props.width ? spacingIncrement(props.width) : "100%")};
`;

export const TooltipWrapper = styled.div`
  display: flex;
  margin: ${spacingIncrement(10)};
`;
