import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";

export const StyledNumberInlineInput = styled.input`
  width: ${spacingIncrement(60)};
  border: unset;
  line-height: ${spacingIncrement(14)};
  font-size: ${spacingIncrement(14)};
  background: transparent;
  border-bottom: 2px solid #303245;
  text-align: center;
  display: inline-block;
  :focus {
    outline: none;
  }
  :disabled {
    cursor: not-allowed;
  }
`;

export const Text = styled.span<{
  fontSize?: number;
  isItalic?: boolean;
  isBold?: boolean;
  width?: string;
  marginLeft?: string;
  center?: boolean;
  wordBreak?:
    | "break-all"
    | "break-word"
    | "keep-all"
    | "normal"
    | "initial"
    | "inherit";
  marginBottom?: string;
  marginTop?: string;
  whiteSpace?:
    | "normal"
    | "nowrap"
    | "pre"
    | "pre-wrap"
    | "pre-line"
    | "initial"
    | "inherit";
}>`
  font-size: ${(props) =>
    spacingIncrement(props.fontSize ? props.fontSize : 16)};
  font-style: ${(props) => (props.isItalic ? "italic" : "normal")};
  font-family: sans-serif, arial !important;
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "none")};
  text-align: ${(props) => (props.center ? "center" : "left")};
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : "normal")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "normal")};
  word-wrap: normal;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "none"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "none")};
`;

export const Flex = styled.div<{
  flex?: number;
  flexWrap?: string;
  alignItems?: string;
}>`
  display: flex;
  flex-wrap: ${(props) => props.flexWrap || ""};
  align-items: ${(props) => props.alignItems || ""};
  flex: ${(props) => props.flex || "unset"} !important;
`;

export const ProductInfo = styled.div`
  font-weight: bold;
  font-family: sans-serif, arial !important;
  font-size: ${spacingIncrement(14)};
`;

export const Title = styled.span`
  font-size: ${spacingIncrement(30)};
  color: #49ab74;
  text-align: center;
  padding-bottom: ${spacingIncrement(10)};
  font-weight: bold;
  display: block;
  text-align: center;
`;

export const AvoidBreakInside = styled.div`
  display: block;
  break-inside: avoid;
`;

export const GraphFooter = styled.div`
  top: 10px;
  position: relative;
`;

export const ExternalLinkContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px;
  font-size: 12px;
  span {
    color: #0abaee;
    &:hover {
      color: #0abaff;
      cursor: pointer;
    }
  }
`;
