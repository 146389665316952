import React from "react";
import { ImageProps } from "../types";

const ArrowBottom: React.FC<ImageProps> = ({
  color = "#0ABAEE",
  ...otherProps
}) => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M13 1L7 7L1 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowBottom;
