import React from "react";
import { ImageProps } from "../types";

const Comment: React.FC<ImageProps> = ({
  color = "#0ABAEE",
  ...otherProps
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M16 31.25C24.4223 31.25 31.25 24.4223 31.25 16C31.25 7.57766 24.4223 0.75 16 0.75C7.57766 0.75 0.75 7.57766 0.75 16C0.75 24.4223 7.57766 31.25 16 31.25Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.869 20.889L13.034 22.994L13.6 23.544L14.166 22.994L16.331 20.889H22.402C23.286 20.889 24 20.191 24 19.331V11.557C24 10.703 23.287 10 22.405 10H9.595C8.712 10 8 10.699 8 11.557V19.331C8 20.187 8.716 20.889 9.6 20.889H10.869Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 15.5H18.667M16 12.833V18.167V12.833Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Comment;
