import { useContext, useState } from "react";
import Image from "../../../components/Image";
import { CLIENT_URL_PREFIX } from "../../../lib/constants";
import { postData } from "../../../services/apis";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { notifyError, notifySuccess } from "../../../utils/common";
import { Container } from "./styles";
import { saveAs } from "file-saver";
import { updateIsLoading } from "../../../store/globalActions";
import { DocumentStatusEnum } from "../../../types/document-details";
import { ListItem, NameContainer } from "../ClientTableArchived/styles";
import DownloadModal from "../../templates/CommonComponents/DownloadModal";
import { BulkActions, BulkSelected } from "../../../types/listing-table";
import { getDownloadBulkData } from "../../../utils/wordRequest";
import { getDownloadableFileName } from "../../../services/clientTable";

type Props = {
  onCreateDocSuccess: () => void;
  selectedBulkIds?: BulkSelected[];
  clearSelectedBulkIds?: () => void;
  updateDocList: () => void;
  filteredSubFundNames: string[];
};

const ActionButtons: React.FC<Props> = ({
  selectedBulkIds = [],
  clearSelectedBulkIds,
}) => {
  const {
    globalState: { selectedFund, userDetails },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const handleDownloadPDF = async (
    unFormatted = false,
    showPageNumber = true
  ) => {
    try {
      globalDispatch(updateIsLoading(true));
      let reqBody;
      if (selectedBulkIds.length === 1) {
        const [doc] = selectedBulkIds;
        const isApproved =
          doc?.status &&
          [DocumentStatusEnum?.approved, DocumentStatusEnum?.filed].includes(
            doc?.status
          );
        reqBody = {
          clientId: userDetails?.clientId,
          documentId: doc.id,
          url: `${window.location.origin}${CLIENT_URL_PREFIX}document/view/${
            selectedFund?.client_id
          }/${doc.id}/pdf/${
            !isApproved ? (unFormatted ? "" : "showWaterMark") : ""
          }`,
          unFormatted: unFormatted,
          showPageNumber: showPageNumber,
        };
      } else {
        reqBody = {
          clientId: userDetails?.clientId,
          downloadData: getDownloadBulkData(
            selectedBulkIds,
            unFormatted,
            selectedFund,
            showPageNumber
          ),
        };
      }
      const response = await postData({
        endPoint: reqBody.url ? "generatePdf/single" : "generatePdf/multiple",
        responseType: "blob",
        data: reqBody,
      });
      if (selectedBulkIds.length === 1) {
        const { subFundName, article, template, status, version, language } =
          selectedBulkIds[0];
        subFundName &&
          article &&
          template &&
          status &&
          saveAs(
            response.data,
            `${getDownloadableFileName(
              subFundName,
              article,
              template,
              status,
              language,
              version
            )}.pdf`
          );
        notifySuccess("Requested document is downloaded successfully");
      } else {
        if (response?.status === 200) {
          notifySuccess(
            <div style={{ textAlign: "center" }}>
              <div>We are generating the requested PDF file for you.</div>
              <div>
                You will shortly receive an e-mail with a download link.
              </div>
            </div>,
            { width: "500px", left: "calc(50% - 250px)", textAlign: "center" }
          );
        } else {
          notifyError(
            'Oops! Something went wrong, "Please try after sometime."'
          );
        }
      }

      clearSelectedBulkIds?.();
      globalDispatch(updateIsLoading(false));
    } catch (error) {
      console.log(error);
      globalDispatch(updateIsLoading(false));
      clearSelectedBulkIds?.();
      notifyError("Error occured while downloading, Please try again");
    }
  };
  const handleUnformattedPdfDownload = (showPageNumber: boolean) => {
    handleDownloadPDF(true, showPageNumber);
  };
  const handlePdfDownload = (showPageNumber: boolean) => {
    handleDownloadPDF(false, showPageNumber);
  };
  const bulkActionsListItems: BulkActions[] = [
    {
      name: "Download",
      icon: "download",
      id: "bulk-action-download-button",
      classnames: "",
      onClick: () => setIsDownloadOpen(true),
    },
  ];

  return (
    <Container>
      <DownloadModal
        isOpen={isDownloadOpen}
        setIsDownloadOpen={setIsDownloadOpen}
        onPdfDownload={handlePdfDownload}
        onUnformattedPdfDownload={handleUnformattedPdfDownload}
      />
      <div className="d-flex px-0 col-12 col-md-auto align-items-baseline justify-content-end">
        <div className="dropdown page-action">
          <button
            className={`btn btn-primary dropdown-toggle`}
            data-toggle="dropdown"
            disabled={!selectedBulkIds.length}
          >
            Bulk Actions
          </button>
          <div className="dropdown-menu">
            {bulkActionsListItems.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  style={{ cursor: "pointer" }}
                  minWidth="16rem"
                  id={item.id}
                  className={item.classnames}
                  onClick={() => item?.onClick?.()}
                >
                  <Image name={item.icon} />
                  <NameContainer>{item.name}</NameContainer>
                  {item.childItems?.length ? (
                    <div className="dropdown-menu">
                      {item.childItems.map((child) => {
                        return (
                          <ListItem
                            key={child.id}
                            style={{ cursor: "pointer" }}
                            minWidth="16rem"
                            id={child.id}
                            onClick={() => child?.onClick?.()}
                            className={child.classnames}
                          >
                            <Image name={child.icon} />
                            <NameContainer>{child.name}</NameContainer>
                          </ListItem>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </ListItem>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ActionButtons;
