import React from "react";
import { ImageProps } from "../types";

const DocNotIncluded: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M15.9579 6.64175C16.0607 6.74176 16.1196 6.87932 16.1196 7.02545V18.6859C16.1196 20.0639 14.9939 21.1896 13.6163 21.1896H3.38422C2.00639 21.1896 0.880859 20.0639 0.880859 18.6859V2.51346C0.880859 1.13563 2.00622 0.00976562 3.38422 0.00976562L9.11794 0.0100893C9.25711 0.0112221 9.39078 0.0741752 9.4882 0.172083L15.9577 6.64198L15.9579 6.64175ZM3.73693 17.9534C3.03103 17.9534 3.03103 16.8811 3.73693 16.8811H13.2634C13.9695 16.8811 13.9695 17.9534 13.2634 17.9534H3.73693ZM3.73693 7.72676C3.03103 7.72676 3.03103 6.65447 3.73693 6.65447H6.78405C7.48995 6.65447 7.48995 7.72676 6.78405 7.72676H3.73693ZM3.73693 11.1359C3.03103 11.1359 3.03103 10.0634 3.73693 10.0634H13.2634C13.9695 10.0634 13.9695 11.1359 13.2634 11.1359H3.73693ZM3.73693 14.5447C3.03103 14.5447 3.03103 13.4722 3.73693 13.4722H13.2634C13.9695 13.4722 13.9695 14.5447 13.2634 14.5447H3.73693ZM3.73693 4.31802C3.03103 4.31802 3.03103 3.24572 3.73693 3.24572H6.78405C7.48995 3.24572 7.48995 4.31802 6.78405 4.31802H3.73693ZM8.56792 1.08224H3.38437C2.59818 1.08224 1.95314 1.72761 1.95314 2.51347V18.6859C1.95314 19.4721 2.59852 20.1172 3.38437 20.1172H13.6164C14.4023 20.1172 15.0476 19.4721 15.0476 18.6859V7.56154L11.0719 7.56187C9.69395 7.56187 8.56825 6.43584 8.56825 5.05817V1.08248L8.56792 1.08224ZM14.2892 6.48908L9.6405 1.84038V5.05789C9.6405 5.84375 10.2855 6.48912 11.0717 6.48912L14.2892 6.48908Z"
        fill="#F05959"
      />
      <circle cx="17.0302" cy="16.0302" r="6.9697" fill="white" />
      <circle cx="17.0305" cy="16.0305" r="5.77273" stroke="#F05959" />
      <g clipPath="url(#clip0_2_4083)">
        <path
          d="M19.6443 13.416L14.417 18.6433M14.417 13.416L19.6443 18.6433"
          stroke="#F05959"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_4083">
          <rect
            width="6.9697"
            height="6.9697"
            fill="white"
            transform="translate(13.5459 12.5449)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocNotIncluded;
