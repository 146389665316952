import moment from "moment";
export type SortState = { [key: string]: TableHeader };
import {
  DocumentArticle,
  DocumentStatusEnum,
  DocumentTemplate,
} from "../types/document-details";
import { BulkSelect, TableHeader, TableRow } from "../types/listing-table";

export const getSortState = (colList: TableHeader[], allowSortOn: string[]) => {
  const state: { [key: string]: TableHeader } = {};
  colList.forEach((col) => {
    if (
      allowSortOn.find((colName) => {
        return colName === col.colName;
      })
    ) {
      state[col.colName] = { ...col };
    }
  });
  return state;
};

export const manageSort = (
  prevState: SortState,
  colName: string
): SortState => {
  const newState = prevState;
  newState[colName].sortDir
    ? newState[colName].sortDir === "ASC"
      ? (newState[colName].sortDir = "DESC") &&
        !newState[colName].sortSequence &&
        (newState[colName].sortSequence = getSortSequence(newState))
      : (newState[colName].sortDir = "") ||
        (newState[colName].sortSequence = null)
    : (newState[colName].sortDir = "ASC") &&
      !newState[colName].sortSequence &&
      (newState[colName].sortSequence = getSortSequence(newState));
  return { ...newState };
};

const getSortSequence = (newState: SortState) => {
  let avalilableSequence = 0;
  Object.values(newState).forEach((value) => {
    avalilableSequence =
      value.sortSequence && value.sortSequence > avalilableSequence
        ? value.sortSequence
        : avalilableSequence;
  });
  return avalilableSequence + 1;
};

export const updateCheckboxSelection = (
  isChecked: boolean,
  checkBoxId: string | undefined,
  dataSet: BulkSelect,
  totalRecords: TableRow[]
) => {
  if (checkBoxId === "selectAll") {
    const updatedSelect: BulkSelect = { ...dataSet, selectAll: isChecked };
    totalRecords.forEach((value) => {
      updatedSelect[value.id] = isChecked;
    });
    return updatedSelect;
  } else {
    const updatedSelect = { ...dataSet };
    if (checkBoxId) updatedSelect[checkBoxId] = isChecked;
    updatedSelect["selectAll"] = isSelectAll(
      totalRecords.length,
      updatedSelect
    );
    return updatedSelect;
  }
};

const isSelectAll = (
  totalRecords: number,
  dataSet: {
    [x: string]: boolean;
  }
) => {
  if (Object.keys(dataSet).length - 1 < totalRecords) {
    return false;
  } else {
    for (const key of Object.keys(dataSet)) {
      if (key !== "selectAll" && !dataSet[key]) {
        return false;
      }
    }
    return true;
  }
};
const languageMapper = (language?: string) => {
  if (!language) return "";
  switch (language.toLowerCase().trim()) {
    case "english":
    case "english (default)":
      return "_EN";
    case "french":
      return "_FR";
    case "german":
      return "_DE";
    case "italian":
      return "_IT";
    case "spanish":
      return "_ES";
    default:
      return "";
  }
};

export const getDownloadableFileName = (
  subFundName: string,
  article: DocumentArticle,
  template: DocumentTemplate,
  status: DocumentStatusEnum,
  language?: string | null,
  version?: string | null
) => {
  const date = moment().format("YYYYMMDDhhmmss");
  let temp = "",
    art = "";
  if (article === DocumentArticle.Article8) {
    art =
      template === DocumentTemplate.PreContractual
        ? String.raw`[8]`
        : String.raw`_Article[8]`;
  } else if (article === DocumentArticle.Article9) {
    art =
      template === DocumentTemplate.PreContractual
        ? String.raw`[9]`
        : String.raw`_Article[9]`;
  }
  if (template === DocumentTemplate.PreContractual) {
    temp = "_Pre-Contrac";
  } else if (template === DocumentTemplate.Periodic) {
    temp = "_Periodic";
  }
  const lan = languageMapper(language || "");
  return version
    ? `${date}_${subFundName}${temp}${art}${lan}_${version}_${status}`
    : `${date}_${subFundName}${temp}${art}${lan}_${status}`;
};
