import React, { useMemo } from "react";
import { AnimatedBarChart } from "../../../../components/AnimatedBarChart";
import { getStaticContentText } from "../../DocumentEditor/service";

type Props = {
  dataValue: { label: string; values: number[]; color: string }[];
  labels: string[];
  stepSize?: number;
  oldGraph?: boolean;
};

const BarChart: React.FC<Props> = ({ dataValue, labels }) => {
  const data = useMemo(
    () => labels.map((label, index) => {
      const vals =  dataValue.map((data, i) => {
        const val = parseFloat(data.values[index] as unknown as string)
        return {
          subgroup: data.label,
          group: label,
          value: val >= 0 ? parseFloat(val.toFixed(2)) : 0,
        };
      });
      return vals;
    }).flat(),
    [dataValue, labels]
  );

  return (
    <>
      <AnimatedBarChart
        width={350}
        height={250}
        data={data}
        colors={dataValue.map((data) => data.color)}
        maxTotalValue={100}
      />
    </>
  );
};

export default BarChart;

