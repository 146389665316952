import { SELECTED_FUND_SESSION } from "../../lib/constants";
import { TabListType } from "./Container";

export const getDefaultRoute = (path: string): TabListType => {
  const nestedPath = path.substring(path.lastIndexOf("/") + 1, path.length);
  switch (nestedPath) {
    case "notifications":
      return "notifications";
    case "help":
      return "help";
    case "languageTranslation":
      return "languageTranslation";
    case "archived":
      return "archived";
    case "listing":
    default:
      return "listing";
  }
};

export const getDefaultTab = (nestedRoute: string): TabListType => {
  switch (nestedRoute) {
    case "notifications":
      return "notifications";
    case "help":
      return "help";
    case "languageTranslation":
      return "languageTranslation";
    case "archived":
      return "archived";
    case "listing":
    default:
      return "listing";
  }
};

export const setSelectedFundId = (selectedFundId: string) =>
  sessionStorage.setItem(SELECTED_FUND_SESSION, selectedFundId);

export const getSelectedFundId = () =>
  sessionStorage.getItem(SELECTED_FUND_SESSION);
