import React from "react";
import { Container, Label } from "./styles";

type Props = {
  label?: string;
  checked?: boolean;
  onChange?: () => void;
  labelStyle?: React.CSSProperties;
  className?: string;
  isDisabled?: boolean;
  id: string;
};

const Checkbox: React.FC<Props> = ({
  checked,
  label,
  onChange,
  className,
  labelStyle,
  isDisabled,
  id,
}) => {
  return (
    <Container>
      <div id={`check-box-${id}`} className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className={`custom-control-input ${className}`}
          id={`checkbox-${id}`}
          onChange={onChange}
          checked={checked}
          disabled={isDisabled}
        />
        {label && (
          <label
            className="custom-control-label"
            htmlFor={`check-box-${id}`}
            onClick={() => {
              if (!isDisabled) {
                onChange?.();
              }
            }}
            style={labelStyle}
          >
            {label}
          </label>
        )}
      </div>
    </Container>
  );
};

export default Checkbox;
