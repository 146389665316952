import React, { useCallback, useContext, useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import Image from "../../../../components/Image";
import Tooltip from "../../../../components/Tooltip";
import { ToolTipContainer } from "../../../../components/Tooltip/styles";
import Context from "../../../../store/context";
import { DocumentRecord } from "../../../../types/document-details";
import { GLOBAL_CONTEXT } from "../../../../types/store";
import PreviewModal from "../PreviewModal";
import { getDocHeadingText } from "../service";
import {
  Container,
  InnerContainer,
  BackButtonContainer,
  BackButton,
  TitleContainer,
  Title,
  RightContainer,
  SavingMessage,
  SavingContainer,
  Link,
} from "./styles";

type Props = {
  document: DocumentRecord | undefined;
  onComment?: () => void;
  onApproval?: () => void;
  showComment?: boolean;
  showApproval?: boolean;
  isSaving?: boolean;
  onPreviewToggle?: (val: boolean) => void;
  onConfiguration: () => void;
};

const DocumentHeader: React.FC<Props> = ({
  document,
  onComment,
  onApproval,
  showComment,
  showApproval,
  isSaving,
  onPreviewToggle,
  onConfiguration,
}) => {
  const {
    setTooltipRef,
    setTriggerRef,
    getArrowProps,
    getTooltipProps,
    triggerRef,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    interactive: true,
  });
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate(-1);
  };
  const {
    globalState: { selectedFund },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const togglePreviewModal = useCallback(() => {
    onPreviewToggle?.(!isPreviewOpen);
    setIsPreviewOpen(!isPreviewOpen);
  }, [isPreviewOpen]);
  return (
    <>
      <Container>
        <InnerContainer>
          <BackButtonContainer>
            <BackButton id="back-button" onClick={onClickBack}>
              <Image name="arrowLeft" /> Back
            </BackButton>
          </BackButtonContainer>
          <TitleContainer>
            <Title title={getDocHeadingText(document)}>
              {getDocHeadingText(document)}
            </Title>
            <RightContainer>
              <Tooltip
                triggerElement={
                  <span style={{ cursor: "pointer" }} onClick={onConfiguration}>
                    <Image name="configuration" />
                  </span>
                }
                trigger={"hover"}
                placement={"bottom"}
              >
                <span>Template Customisation</span>
              </Tooltip>
              {showApproval && (
                <Tooltip
                  triggerElement={
                    <span style={{ cursor: "pointer" }} onClick={onApproval}>
                      <Image name="approval" />
                    </span>
                  }
                  trigger={"hover"}
                  placement={"bottom"}
                >
                  <span>Approval Status</span>
                </Tooltip>
              )}
              {showComment && (
                <Tooltip
                  triggerElement={
                    <span style={{ cursor: "pointer" }} onClick={onComment}>
                      <Image name="comment" />
                    </span>
                  }
                  trigger={"hover"}
                  placement={"bottom"}
                >
                  <span>Comments</span>
                </Tooltip>
              )}
              <span
                ref={setTriggerRef}
                onMouseEnter={() => triggerRef?.click()}
              >
                <Image name="question" />
              </span>
              {visible && (
                <ToolTipContainer
                  ref={setTooltipRef}
                  {...getTooltipProps({
                    className: "tooltip-container",
                  })}
                  width={360}
                >
                  <div
                    {...getArrowProps({
                      className: "tooltip-arrow",
                    })}
                  />
                  <span>
                    Please contact zeidler team if any help needed in filling up
                    the document.{" "}
                    <Link href={`mailto:${process.env.REACT_APP_HELP_EMAIL}`}>
                      {process.env.REACT_APP_HELP_EMAIL}
                    </Link>
                  </span>
                </ToolTipContainer>
              )}
              <Button
                id="preview-button-in"
                onClick={togglePreviewModal}
                buttonType="solid"
              >
                Preview
              </Button>
              {document && selectedFund && (
                <PreviewModal
                  isOpen={isPreviewOpen}
                  onClose={togglePreviewModal}
                  document={document}
                  selectedFund={selectedFund}
                />
              )}
            </RightContainer>
          </TitleContainer>
        </InnerContainer>
      </Container>
      {isSaving && (
        <SavingContainer>
          <SavingMessage>
            <Image name="save" />
            Your document is being saved...
          </SavingMessage>
        </SavingContainer>
      )}
    </>
  );
};

export default DocumentHeader;
