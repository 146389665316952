import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  color: {
    primary: "#303245",
    secondary: "#39BAEE",
    background: "#ffffff",
    backgroundGreyLight: "#F6F6F6",
    backgroundGreyDark: "#dee2e6",
    textGray: "#adb5bd",
    blue: "#0abaee",
  },
};

export default theme;
