export const pageButtons: any = {
  first: { ariaLabel: "Prexvious", displayValue: "«", value: "First" },
  previous: { ariaLabel: "Prexvious", displayValue: "‹", value: "Previous" },
  next: { ariaLabel: "Next", displayValue: "›", value: "Next" },
  last: { ariaLabel: "Next", displayValue: "»", value: "Last" },
};
export function calculatePageNumber(totalRecords: number, pageSize: number) {
  if (totalRecords > 0) {
    return Math.ceil(totalRecords / pageSize);
  } else {
    return 0;
  }
}
export function updatePagination(
  activePage: number,
  direction: string,
  totalRecords: number,
  pageSize: number,
  maxPageSelectSize: number
) {
  let paginationArray = [];
  const pageNumbers = calculatePageNumber(totalRecords, pageSize);
  //This case is when user selects first page
  if (activePage === 1) {
    paginationArray = getArrayOfNumbers(
      activePage,
      pageNumbers,
      //We are doing +2 here because we just have 2 buttons and space for 2 buttons can be utilized by pageNumbers
      maxPageSelectSize + 2
    );
    //append only the last two buttons here
    paginationArray.push(getButton("next"));
    paginationArray.push(getButton("last"));
    //This case is when user selects last page
  } else if (activePage === pageNumbers) {
    paginationArray = getArrayOfNumbers(
      activePage,
      pageNumbers,
      //We are doing +2 here because we just have 2 buttons and space for 2 buttons can be utilized by pageNumbers
      maxPageSelectSize + 2,
      "Reverse"
    );
    //prepend only the first two buttons here

    paginationArray.unshift(getButton("previous"));
    paginationArray.unshift(getButton("first"));
  }
  //for other middle number cases
  else {
    paginationArray = getArrayOfNumbers(
      activePage,
      pageNumbers,
      maxPageSelectSize,
      direction
    );

    paginationArray.unshift(getButton("previous"));
    paginationArray.unshift(getButton("first"));
    paginationArray.push(getButton("next"));
    paginationArray.push(getButton("last"));
  }
  return [...paginationArray];
}
//This function is used to calculate the number of pages to show on pagination bar excluding the buttons
function getArrayOfNumbers(
  activePage: number,
  totalPages: number,
  size: number,
  direction?: string
) {
  const pageArray = [];
  let pageValue = activePage;
  //This condition is used to show array when last page is selected
  if (direction === "Reverse") {
    for (let counter = size; counter > 0; counter--) {
      if (pageValue > 0) {
        let active = "";
        if (pageValue === activePage) {
          active = "active";
        }
        pageArray.unshift({
          ariaLabel: pageValue,
          displayValue: pageValue,
          value: pageValue,
          active: active,
        });
      }
      pageValue--;
    }
  } else {
    //This condition is used to show 1 number before the active page
    pageValue = activePage === 1 ? activePage : activePage - 1;
    for (let counter = 0; counter < size; counter++) {
      if (pageValue <= totalPages) {
        let active = "";
        if (pageValue === activePage) {
          active = "active";
        }
        pageArray.push({
          ariaLabel: pageValue,
          displayValue: pageValue,
          value: pageValue,
          active: active,
        });
      }
      pageValue++;
    }
  }
  return [...pageArray];
}
//This function is used to get the buttons object for specified button name from the const file
function getButton(buttonName: string) {
  return pageButtons[buttonName];
}
