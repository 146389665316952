import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const Container = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  input[type="checkbox"] {
    appearance: none;
    background-color: #dee2e6;
    margin: 0;
    color: currentColor;
    width: ${spacingIncrement(16)};
    height: ${spacingIncrement(16)};
    margin-right: ${spacingIncrement(8)};
    border: 1px solid #dee2e6;
    border-radius: ${spacingIncrement(5)};
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: middle;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em white;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:checked {
    border: 1px solid #0abaee;
    background-color: #0abaee;
  }

  input[type="checkbox"]:disabled {
    color: #959495;
    cursor: not-allowed;
  }
`;

export const Label = styled.div`
  font-size: ${spacingIncrement(18)};
  font-weight: 400;
  font-family: "Calibri", "Arial" !important;
  color: #000000;
`;
