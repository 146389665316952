import React from "react";
import Image from "../../../../components/Image";
import { Container, GreyLine, ImageWrapper } from "./styles";

type Props = {
  name:
    | "globe"
    | "factory"
    | "book"
    | "templateComment"
    | "computer"
    | "docNotIncluded"
    | "graphs"
    | "info"
    | "money"
    | "noBook"
    | "noMoney"
    | "users"
    | "writingPad"
    | "moneyCoin"
    | "humanArrow"
    | "leafBW";
  size?: number;
};

const MainIcon: React.FC<Props> = ({ name, size = 64 }) => {
  return (
    <Container>
      <GreyLine />
      <ImageWrapper>
        <Image name={name} height={size} width={size} />
      </ImageWrapper>
    </Container>
  );
};

export default MainIcon;
