import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../store/context";
import { GLOBAL_CONTEXT } from "../../types/store";
import Image from "../Image";
import SVGIcon from "../SvgIcons";
import KeycloakService from "../../keycloak/KeycloakService";

const Header = () => {
  const navigate = useNavigate();
  const [showMenu] = useState(false);
  const {
    globalState: { userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);
  return (
    <header className="site-header">
      <nav className="navbar navbar-expand-lg py-3">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-brand" href={process.env.REACT_APP_SWIFT_URL}>
              <div className="navbar-logo">
                <Image name="logo" width="85" height="45" />
              </div>
            </a>
          </div>
          <button
            type="button"
            className="navbar-toggle d-lg-none"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span
              className="icon-bar close-cross"
              style={{ backgroundColor: "black" }}
            ></span>
            <span
              className="icon-bar close-cross"
              style={{ backgroundColor: "black" }}
            ></span>
            <span className="icon-bar"></span>
          </button>
          <div className="navbar-content navbar-collapse">
            <div className="navbar-nav ml-auto">
              <ul
                className={`navbar-user-item dropdown-menu-right dropdown ${
                  showMenu ? "show" : ""
                }`}
              >
                <button
                  className="buttonAsLink dropdown-toggle dropdown-toggle--no-caret"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  //onClick={(e) => toggle()}
                >
                  <span className="user-group my-3">
                    <span className="user-group__user-name">
                      {userDetails?.userName}
                    </span>
                  </span>
                  <SVGIcon iconName="user" />
                </button>
                <ul className="dropdown-menu dropdown-menu-right dropdown-menu-user">
                  <li className="dropdown-toggle dropdown-toggle--no-caret pr-3">
                    <span className="user-group m-3">
                      <span className="user-group__user-name">
                        {userDetails?.userName}
                      </span>
                      <span className="user-group__company-name">
                        {userDetails?.clientName}
                      </span>
                    </span>
                    <div className="avatar avatar--no-image ml-auto">
                      <SVGIcon iconName="user" />
                    </div>
                  </li>
                  <div className="px-3 pb-4 pb-lg-3 pt-0 border-bottom">
                    <a
                      href={`${process.env.REACT_APP_GATEWAY_URL}/users/profile?referrer=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`}
                      className="btn btn-primary"
                    >
                      Settings
                    </a>
                    <div
                      onClick={KeycloakService.doLogout}
                      className="btn btn-outline-primary ml-2"
                    >
                      Log out
                    </div>
                  </div>
                  {userDetails?.userType === "admin" && (
                    <button
                      className="buttonAsLink dropdown-item d-flex justify-content-between align-items-center text-secondary px-3"
                      onClick={() => {
                        // navigate("../admin", { replace: true });
                        navigate("/sfdr/admin");
                      }}
                    >
                      Admin panel
                      <SVGIcon iconName="user" />
                    </button>
                  )}
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
