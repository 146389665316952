import React from "react";
import { ImageProps } from "../types";

const Book: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="67.5" cy="67.5" r="67.5" fill="#9D9D9C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.0004 6L65.2444 14.4831L69.4857 15.8611L68.0004 6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.0003 6L70.7563 14.4831L66.515 15.8611L68.0003 6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4828 12.1636H67.5633V16.6234L76.4828 12.1636Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4828 12.1636L69.2663 17.4065L66.6453 13.7977L76.4828 12.1636Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.2428 22.1352L70.4869 13.653L66.245 15.031L73.2428 22.1352Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.2428 22.1352L66.0272 16.8923L68.6482 13.2844L73.2428 22.1352Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5179 12.1636H68.4374V16.6234L59.5179 12.1636Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5179 12.1636L66.7345 17.4065L69.3555 13.7977L59.5179 12.1636Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.7579 22.1352L65.5138 13.653L69.7557 15.031L62.7579 22.1352Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.7579 22.1352L69.9736 16.8923L67.3525 13.2844L62.7579 22.1352Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.0004 113.034L65.2444 121.518L69.4857 122.896L68.0004 113.034Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.0003 113.034L70.7563 121.518L66.515 122.896L68.0003 113.034Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4828 119.198H67.5633V123.658L76.4828 119.198Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4828 119.198L69.2663 124.441L66.6453 120.832L76.4828 119.198Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.2428 129.17L70.4869 120.688L66.245 122.066L73.2428 129.17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.2428 129.17L66.0272 123.927L68.6482 120.319L73.2428 129.17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5179 119.198H68.4374V123.658L59.5179 119.198Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5179 119.198L66.7345 124.441L69.3555 120.832L59.5179 119.198Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.7579 129.17L65.5138 120.688L69.7557 122.066L62.7579 129.17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.7579 129.17L69.9736 123.927L67.3525 120.319L62.7579 129.17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4831 59.5172L11.7272 68.0003L15.9685 69.3783L14.4831 59.5172Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4832 59.5172L17.2391 68.0003L12.9979 69.3783L14.4832 59.5172Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9657 65.6806H14.0461V70.1404L22.9657 65.6806Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9657 65.6806L15.7491 70.9235L13.1281 67.3146L22.9657 65.6806Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7256 75.6526L16.9697 67.1704L12.7278 68.5484L19.7256 75.6526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7256 75.6526L12.5099 70.4097L15.1309 66.8018L19.7256 75.6526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 65.6806H14.9195V70.1404L6 65.6806Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 65.6806L13.2166 70.9235L15.8376 67.3146L6 65.6806Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24023 75.6526L11.9961 67.1704L16.2381 68.5484L9.24023 75.6526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24023 75.6526L16.4559 70.4097L13.8349 66.8018L9.24023 75.6526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9993 29.3067L43.215 24.0638L40.594 20.4559L35.9993 29.3067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9993 29.3067L38.7552 20.8236L42.9971 22.2016L35.9993 29.3067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7592 19.3331L39.9758 24.576L42.5968 20.9681L32.7592 19.3331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7592 19.3331H41.6787V23.7929L32.7592 19.3331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2417 13.1695L38.4858 21.6527L42.727 23.0307L41.2417 13.1695Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2417 13.1695L43.9976 21.6527L39.7564 23.0307L41.2417 13.1695Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.4842 29.3067L39.2685 24.0638L41.8895 20.4559L46.4842 29.3067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.4842 29.3067L43.7283 20.8236L39.4864 22.2016L46.4842 29.3067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7242 19.3331L42.5077 24.576L39.8867 20.9681L49.7242 19.3331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7242 19.3331H40.8047V23.7929L49.7242 19.3331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8947 48.894L24.1388 40.4118L19.8975 41.7898L26.8947 48.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8946 48.894L19.679 43.6511L22.3 40.0432L26.8946 48.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4097 48.894L23.6263 43.6511L21.0044 40.0432L16.4097 48.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4097 48.894L19.1665 40.4118L23.4078 41.7898L16.4097 48.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1697 38.922L20.3854 44.1649L23.0064 40.556L13.1697 38.922Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1697 38.922H22.0893V43.3818L13.1697 38.922Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1369 38.922L22.9212 44.1649L20.2993 40.556L30.1369 38.922Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1369 38.922H21.2173V43.3818L30.1369 38.922Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6522 32.7586L24.409 41.2417L20.1671 42.6197L21.6522 32.7586Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6522 32.7586L18.8963 41.2417L23.1375 42.6197L21.6522 32.7586Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1369 92.4392H21.2173V96.899L30.1369 92.4392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1369 92.4392L22.9212 97.6821L20.2993 94.0732L30.1369 92.4392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8947 102.411L24.1388 93.929L19.8975 95.307L26.8947 102.411Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8946 102.411L19.679 97.1683L22.3 93.5604L26.8946 102.411Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4097 102.411L23.6263 97.1683L21.0044 93.5604L16.4097 102.411Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4097 102.411L19.1665 93.929L23.4078 95.307L16.4097 102.411Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6522 86.2758L24.409 94.7589L20.1671 96.1369L21.6522 86.2758Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6522 86.2758L18.8963 94.7589L23.1375 96.1369L21.6522 86.2758Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1697 92.4392H22.0893V96.899L13.1697 92.4392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1697 92.4392L20.3854 97.6821L23.0064 94.0732L13.1697 92.4392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7242 112.026H40.8047V116.486L49.7242 112.026Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7242 112.026L42.5077 117.269L39.8867 113.661L49.7242 112.026Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.4842 122.001L43.7283 113.518L39.4864 114.896L46.4842 122.001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.4842 122.001L39.2685 116.758L41.8895 113.15L46.4842 122.001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9993 122.001L43.215 116.758L40.594 113.15L35.9993 122.001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9993 122.001L38.7552 113.518L42.9971 114.896L35.9993 122.001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2417 105.865L43.9976 114.348L39.7564 115.726L41.2417 105.865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2417 105.865L38.4858 114.348L42.727 115.726L41.2417 105.865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7592 112.026H41.6787V116.486L32.7592 112.026Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7592 112.026L39.9758 117.269L42.5968 113.661L32.7592 112.026Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.518 59.5172L124.273 68.0003L120.032 69.3783L121.518 59.5172Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.518 59.5172L118.762 68.0003L123.003 69.3783L121.518 59.5172Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.035 65.6806H121.955V70.1404L113.035 65.6806Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.035 65.6806L120.252 70.9235L122.873 67.3146L113.035 65.6806Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.275 75.6526L119.031 67.1704L123.273 68.5484L116.275 75.6526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.275 75.6526L123.491 70.4097L120.87 66.8018L116.275 75.6526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130 65.6806H121.081V70.1404L130 65.6806Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130 65.6806L122.783 70.9235L120.162 67.3146L130 65.6806Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.76 75.6526L124.004 67.1704L119.762 68.5484L126.76 75.6526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.76 75.6526L119.544 70.4097L122.165 66.8018L126.76 75.6526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.001 29.3067L92.7857 24.0638L95.4067 20.4559L100.001 29.3067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.001 29.3067L97.2455 20.8236L93.0036 22.2016L100.001 29.3067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.241 19.3331L96.0248 24.576L93.4038 20.9681L103.241 19.3331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.241 19.3331H94.3218V23.7929L103.241 19.3331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.7589 13.1695L97.5148 21.6527L93.2736 23.0307L94.7589 13.1695Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.7589 13.1695L92.003 21.6527L96.2442 23.0307L94.7589 13.1695Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5164 29.3067L96.7321 24.0638L94.1111 20.4559L89.5164 29.3067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5164 29.3067L92.2723 20.8236L96.5142 22.2016L89.5164 29.3067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.2765 19.3331L93.4931 24.576L96.1141 20.9681L86.2765 19.3331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.2765 19.3331H95.196V23.7929L86.2765 19.3331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.106 48.894L111.862 40.4118L116.103 41.7898L109.106 48.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.106 48.894L116.322 43.6511L113.701 40.0432L109.106 48.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.591 48.894L112.374 43.6511L114.996 40.0432L119.591 48.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.591 48.894L116.834 40.4118L112.593 41.7898L119.591 48.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.831 38.922L115.615 44.1649L112.994 40.556L122.831 38.922Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.831 38.922H113.911V43.3818L122.831 38.922Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.864 38.922L113.079 44.1649L115.701 40.556L105.864 38.922Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.864 38.922H114.783V43.3818L105.864 38.922Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.348 32.7586L111.592 41.2417L115.834 42.6197L114.348 32.7586Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.348 32.7586L117.104 41.2417L112.863 42.6197L114.348 32.7586Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.864 92.4392H114.783V96.899L105.864 92.4392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.864 92.4392L113.079 97.6821L115.701 94.0732L105.864 92.4392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.106 102.411L111.862 93.929L116.103 95.307L109.106 102.411Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.106 102.411L116.322 97.1683L113.701 93.5604L109.106 102.411Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.591 102.411L112.374 97.1683L114.996 93.5604L119.591 102.411Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.591 102.411L116.834 93.929L112.593 95.307L119.591 102.411Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.348 86.2758L111.592 94.7589L115.834 96.1369L114.348 86.2758Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.348 86.2758L117.104 94.7589L112.863 96.1369L114.348 86.2758Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.831 92.4392H113.911V96.899L122.831 92.4392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.831 92.4392L115.615 97.6821L112.994 94.0732L122.831 92.4392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.2765 112.026H95.196V116.486L86.2765 112.026Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.2765 112.026L93.4931 117.269L96.1141 113.661L86.2765 112.026Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5164 122.001L92.2723 113.518L96.5142 114.896L89.5164 122.001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5164 122.001L96.7321 116.758L94.1111 113.15L89.5164 122.001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.001 122.001L92.7857 116.758L95.4067 113.15L100.001 122.001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.001 122.001L97.2455 113.518L93.0036 114.896L100.001 122.001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.7589 105.865L92.003 114.348L96.2442 115.726L94.7589 105.865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.7589 105.865L97.5148 114.348L93.2736 115.726L94.7589 105.865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.241 112.026H94.3218V116.486L103.241 112.026Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.241 112.026L96.0248 117.269L93.4038 113.661L103.241 112.026Z"
        fill="white"
      />
      <path
        d="M100.782 80.4167C100.769 80.3874 100.749 80.34 100.728 80.2949C100.755 80.3544 100.772 80.3937 100.782 80.4167Z"
        fill="#3C3C3B"
      />
      <path
        d="M100.782 80.4167C100.799 80.4565 100.796 80.4484 100.782 80.4167V80.4167Z"
        fill="#3C3C3B"
      />
      <path
        d="M98.8539 75.9751C99.0964 75.9765 99.3393 75.9774 99.5869 75.9774C100.369 75.9774 100.867 74.9604 100.482 74.172L98.701 70.56C98.8914 70.5591 99.0814 70.5577 99.2737 70.5577C100.023 70.5577 100.537 69.6052 100.2 68.8178L94.6548 53.6688C94.4814 53.2647 94.1188 53 93.7315 53C86.6319 53 79.9458 54.5433 74.9031 57.3471C71.47 59.2553 69.0407 61.6185 67.7575 64.233C66.4747 61.6185 64.045 59.2559 60.6124 57.3471C55.5703 54.5438 48.884 53 41.785 53C41.402 53 41.0316 53.2625 40.8629 53.6652L35.279 68.8142C34.9384 69.6012 35.4539 70.5578 36.2039 70.5578C36.5004 70.5578 36.7943 70.5591 37.0862 70.5614L35.306 74.172C34.9318 74.9626 35.4099 75.9774 36.2011 75.9774C36.4483 75.9774 36.6908 75.9765 36.9333 75.9756L35.1075 80.1829C34.7399 81.0416 35.3682 82.0532 36.1876 81.9174L61.7591 79.22C62.3028 82.133 64.5842 81.9991 67.7774 81.9991C70.971 81.9991 73.2521 82.133 73.7958 79.22L99.3692 81.9174C100.481 82.1217 100.787 80.4305 100.679 80.1829L98.8539 75.9751ZM39.0788 68.1842C38.9498 68.1526 38.8185 68.1499 38.6915 68.1756C38.4165 68.1697 38.1408 68.1648 37.8628 68.1607L42.4171 55.4167C48.9765 55.5046 55.1025 56.9515 59.7241 59.5213C64.0039 61.9007 66.465 64.9297 66.7213 68.1029L66.7279 77.0732C62.1808 73.1562 51.8212 68.5025 39.0784 68.184L39.0788 68.1842ZM97.0597 68.1702C96.9566 68.1535 96.8511 68.1557 96.7469 68.176C83.0255 68.4755 73.0102 72.631 68.8088 77.0251L68.791 68.1031C69.0478 64.9294 71.5084 61.9009 75.789 59.5215C80.411 56.9521 86.5358 55.5049 93.095 55.4165L97.6173 68.1605C97.4304 68.1636 97.2451 68.1672 97.0593 68.1704L97.0597 68.1702Z"
        fill="#3C3C3B"
      />
      <path
        d="M100.68 80.1823C100.686 80.1968 100.691 80.2082 100.698 80.2214C100.687 80.1982 100.68 80.1823 100.68 80.1823Z"
        fill="#3C3C3B"
      />
    </svg>
  );
};

export default Book;
