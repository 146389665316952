import React, { useContext, useEffect, useState } from "react";
import SidePanel from "../../../../components/SidePanel";
import Select from "../../../../components/Select";
import { Label } from "../../../../components/Select/styles";
import "flatpickr/dist/themes/material_green.css";
import parse from "html-react-parser";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import {
  Box,
  CustomTooltip,
  DisabledContainer,
  BoxFooter,
  RightText,
  ResentAnswerTitle,
  RecentAnsWrap,
  CopyWrap,
  ViewMore,
  AnswerText,
  ClickToCopy,
} from "./styles";
import { fetchData } from "../../../../services/apis";
import {
  DocumentRecord,
  DocumentArticle,
  DocumentTemplate,
} from "../../../../types/document-details";
import Context from "../../../../store/context";
import { GLOBAL_CONTEXT } from "../../../../types/store";

import { getDocHeadingText } from "../../DocumentEditor/service";
import Image from "../../../../components/Image";
import { Question } from "../../../../types/document-question";
import Tooltip from "../../../../components/Tooltip";
import { Text } from "../common.styles";
type Props = {
  index: string;
  prevAns: any;
  documentDetailsString: string;
};
const PreviousAnswer: React.FC<Props> = ({
  index,
  prevAns,
  documentDetailsString,
}) => {
  const [sliceLength, setSliceLength] = useState<{ [key: string]: number }>();
  const [isCopied, setIsCopied] = useState<{ [key: string]: boolean }>();
  const copyToClipBoard = async (prevAns: any) => {
    const blob = new Blob([prevAns.answer], { type: "text/html" });
    const richTextInput = new ClipboardItem({ "text/html": blob });
    await navigator.clipboard.write([richTextInput]);
    setIsCopied({ [prevAns._id]: true });
  };
  return (
    <Box key={index}>
      <CopyWrap onClick={() => copyToClipBoard(prevAns)}>
        {!isCopied && (
          <Tooltip
            triggerElement={<Image name="copy" />}
            placement="bottom-start"
            width={100}
          >
            <ClickToCopy>Click to copy</ClickToCopy>
          </Tooltip>
        )}
        {isCopied?.[prevAns._id] && (
          <CustomTooltip width={10}>copied!</CustomTooltip>
        )}
      </CopyWrap>
      <AnswerText>
        {parse(prevAns.answer.slice(0, sliceLength?.[prevAns._id] || 300))}
      </AnswerText>
      {prevAns.answer.length > 300 &&
        sliceLength?.[prevAns._id] !== prevAns.answer.length && (
          <ViewMore
            onClick={() =>
              setSliceLength({
                [prevAns._id]: prevAns.answer.length,
              })
            }
          >
            view more
          </ViewMore>
        )}
      {sliceLength?.[prevAns._id] === prevAns.answer.length && (
        <ViewMore onClick={() => setSliceLength({ [prevAns._id]: 300 })}>
          view less
        </ViewMore>
      )}
      <RightText>
        {moment(prevAns.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
      </RightText>
      <BoxFooter>{documentDetailsString}</BoxFooter>
    </Box>
  );
};
export default PreviousAnswer;
