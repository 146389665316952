import styled from "styled-components";

export const StyledStatus = styled.div<{
  color: string;
  backgroundColor: string;
}>`
  position: relative;
  padding: 0 4px 1px 15px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  &:before {
    width: 6px;
    height: 6px;
    margin-y: auto;
    background-color: ${({ color }) => color};
    border-radius: 100%;
    content: "";
    position: absolute;
    display: block;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
