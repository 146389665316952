import { PaginationProps } from ".";

type PageButtonsKeys = "previous" | "next";

type PageDirections = "Forward" | "Reverse" | null;

type PageButtons = {
  [key in PageButtonsKeys]: {
    ariaLabel: string;
    displayValue: string;
    value: string;
  };
};

const getArrayOfNumbers = (
  activePage: number,
  totalPages: number,
  size: number,
  direction?: PageDirections
): PaginationProps[] => {
  const pageArray: PaginationProps[] = [];
  let pageValue = activePage;
  if (direction === "Reverse") {
    for (let counter = size; counter > 0; counter--) {
      if (pageValue > 0) {
        let active = "";
        if (pageValue === activePage) {
          active = "active";
        }
        pageArray.unshift({
          ariaLabel: `${pageValue}`,
          displayValue: `${pageValue}`,
          value: `${pageValue}`,
          active: active,
        });
      }
      pageValue--;
    }
  } else {
    pageValue = activePage === 1 ? activePage : activePage - 1;
    for (let counter = 0; counter < size; counter++) {
      if (pageValue <= totalPages) {
        let active = "";
        if (pageValue === activePage) {
          active = "active";
        }
        pageArray.push({
          ariaLabel: `${pageValue}`,
          displayValue: `${pageValue}`,
          value: `${pageValue}`,
          active: active,
        });
      }
      pageValue++;
    }
  }
  return [...pageArray];
};

const getButton = (buttonName: PageButtonsKeys): PaginationProps => {
  return pageButtons[buttonName];
};

export const pageButtons: PageButtons = {
  previous: { ariaLabel: "Prexvious", displayValue: "‹", value: "Previous" },
  next: { ariaLabel: "Next", displayValue: "›", value: "Next" },
};

export const calculatePageNumber = (totalRecords: number, pageSize: number) => {
  if (totalRecords > 0) {
    return Math.ceil(totalRecords / pageSize);
  } else {
    return 0;
  }
};
export const updatePagination = (
  activePage: number,
  direction: PageDirections,
  totalRecords: number,
  pageSize: number,
  maxPageSelectSize: number
) => {
  let paginationArray: PaginationProps[] = [];
  const pageNumbers = calculatePageNumber(totalRecords, pageSize);
  if (activePage === 1) {
    paginationArray = getArrayOfNumbers(
      activePage,
      pageNumbers,
      maxPageSelectSize + 2
    );
    paginationArray.push(getButton("next"));
  } else if (activePage === pageNumbers) {
    paginationArray = getArrayOfNumbers(
      activePage,
      pageNumbers,
      maxPageSelectSize + 2,
      "Reverse"
    );

    paginationArray.unshift(getButton("previous"));
  } else {
    paginationArray = getArrayOfNumbers(
      activePage,
      pageNumbers,
      maxPageSelectSize,
      direction
    );

    paginationArray.unshift(getButton("previous"));
    paginationArray.push(getButton("next"));
  }
  return [...paginationArray];
};
