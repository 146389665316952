import React from "react";
import { ImageProps } from "../types";

const CommentFilled: React.FC<ImageProps> = ({
  color = "#0ABAEE",
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...otherProps}
    >
      <g fill="none" fillRule="evenodd">
        <circle
          cx="16"
          cy="16"
          r="15.25"
          stroke={color}
          strokeWidth="1.5"
        ></circle>
        <g transform="translate(8 9)">
          <path
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="2"
            d="M2.86862915,10.8888889 L5.03431458,12.9944164 L5.6,13.5443884 L6.16568542,12.9944164 L8.33137088,10.8888889 L14.4019643,10.8888889 C15.2856175,10.8888889 16,10.1907717 16,9.33140966 L16,1.55747923 C16,0.703235104 15.2866314,0 14.4052818,0 L1.59471814,0 C0.712464248,0 0,0.698910917 0,1.55747923 L0,9.33140966 C0,10.1866133 0.716381664,10.8888889 1.59916525,10.8888889 L2.86862915,10.8888889 Z"
          ></path>
          <rect
            width="9.6"
            height="2"
            x="3.2"
            y="2.9"
            fill={color}
            fillRule="nonzero"
            rx=".8"
          ></rect>
          <rect
            width="9.6"
            height="2"
            x="3.2"
            y="6"
            fill={color}
            fillRule="nonzero"
            rx=".8"
          ></rect>
        </g>
      </g>
    </svg>
  );
};

export default CommentFilled;
