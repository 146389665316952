import { useMemo } from "react";
import * as d3 from "d3";
import { animated, useSpring } from "react-spring";

const MARGIN = { top: 0, right: 0, bottom: 30, left: 60 };
const BAR_PADDING = 0.6;

interface DataInterface {
  group: string;
  value: number;
  subgroup: string;
}

export const AnimatedBarChart = ({
  width,
  height,
  data = [],
  colors = ["#e85252", "#6689c6", "#9a6fb0"],
  remainingConfig = {
    suffix: "%",
    textColor: "black",
  },
  clusterConfig = {
    suffix: "%",
    textColor: "black",
    strokeColor: "black",
  },
  gridConfig = {
    showGrid: true,
    gridColor: "black",
    textColor: "black",
    textOpacity: 0.8,
    gridOpacity: 0.2,
    suffix: "%",
  },
  groupConfig = {
    textColor: "white",
    suffix: "%",
  },
  labelConfig = {
    textColor: "black",
  },
  maxTotalValue,
}: {
  width: number;
  height: number;
  data: DataInterface[];
  colors?: string[];
  remainingConfig?: {
    suffix?: string;
    textColor?: string;
    fillColor?: string;
    textOpacity?: number;
    fontSize?: number;
  };
  clusterConfig?: {
    suffix?: string;
    textColor?: string;
    strokeColor?: string;
    fillColor?: string;
    strokeWidth?: number;
    textOpacity?: number;
    fontSize?: number;
  };
  gridConfig?: {
    showGrid?: boolean;
    gridColor?: string;
    textColor?: string;
    textOpacity?: number;
    gridOpacity?: number;
    suffix?: string;
    fontSize?: number;
  };
  groupConfig?: {
    textColor?: string;
    suffix?: string;
    textOpacity?: number;
    fontSize?: number;
  };
  labelConfig?: {
    textColor?: string;
    fontSize?: number;
    textOpacity?: number;
  };
  maxTotalValue?: number;
}) => {
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;
  const uniqueSubgroups = data
    .filter((d, i, a) => a.findIndex((t) => t.subgroup === d.subgroup) === i)
    .map((d) => d.subgroup);
  const uniqueGroups = data
    .filter((d, i, a) => a.findIndex((t) => t.group === d.group) === i)
    .map((d) => d.group);
  const stackGenerator = d3
    .stack<string>()
    .keys(uniqueSubgroups)
    .value((d, k) => {
      return data.filter((i) => i.group === d && i.subgroup === k)[0].value;
    });
  const stack = stackGenerator(uniqueGroups);
  const groupTotals = uniqueGroups.map((g) => {
    return data
      .filter((d) => d.group === g)
      .reduce((acc, curr, i, a) => {
        const addition = a.length - 1 > i ? acc + curr.value : acc;
        return addition;
      }, 0);
  });
  const maxGroupTotal = maxTotalValue ?? Math.max(...groupTotals);

  const yScale = useMemo(() => {
    return d3
      .scaleBand()
      .domain(uniqueGroups)
      .range([0, boundsHeight])
      .padding(BAR_PADDING);
  }, [data, height]);
  const xScale = useMemo(() => {
    return d3.scaleLinear().domain([0, maxGroupTotal]).range([0, boundsWidth]);
  }, [data, width]);

  const colorScale = d3.scaleOrdinal().domain(uniqueSubgroups).range(colors);

  const rectangles = stack.map((s, i) => {
    return (
      <g key={i}>
        {s.map((g, j) => {
          const rectX = xScale(g[0]);
          const rectWidth = xScale(g[1]) - rectX;
          const rectY = yScale(g.data);
          const rectHeight = yScale.bandwidth();
          const rectFill = colorScale(s.key);
          return (
            <Rect
              coordinates={[rectX, rectY as number]}
              width={rectWidth}
              height={rectHeight}
              bgColor={rectFill as string}
              strokeWidth={0}
              key={j}
            />
          );
        })}
      </g>
    );
  });

  const bracketRectangles = uniqueGroups.map((g, i) => {
    const groupTotalWidth = xScale(groupTotals[i]);
    const rectX = xScale(0);
    const rectWidth = groupTotalWidth - 1;
    const rectY = (yScale(g) as number) - height / 15;
    const rectHeight = yScale.bandwidth();
    return (
      <Rect
        coordinates={[rectX, rectY]}
        width={rectWidth}
        height={rectHeight}
        bgColor={clusterConfig?.fillColor ?? "transparent"}
        strokeWidth={clusterConfig?.strokeWidth ?? 1}
        strokeColor={clusterConfig?.strokeColor ?? "black"}
        key={i}
      />
    );
  });

  const rectangleTexts = stack.map((s, i) => {
    return (
      <g key={i}>
        {s.map((g, j) => {
          const rectX = xScale(g[0]);
          const rectWidth = xScale(g[1]) - rectX;
          const rectY = yScale(g.data);
          const rectHeight = yScale.bandwidth();
          const labelText = g[1] - g[0];
          return (
            labelText > 0 && <Text
              alignmentBaseline="central"
              textAnchor="middle"
              opacity={groupConfig?.textOpacity ?? 1}
              content={`${labelText.toFixed(2)}${groupConfig.suffix}`}
              fontSize={groupConfig?.fontSize ?? 10}
              coordinates={[
                rectX + rectWidth / 2,
                (rectY as number) + rectHeight / 2,
              ]}
              textColor={stack.length - 1 > i ? groupConfig?.textColor ?? "#fff" : 'black' }
              key={j}
            />
          );
        })}
      </g>
    );
  });

  const bracketRectangleTexts = uniqueGroups.map((g, i) => {
    const groupTotalWidth = xScale(groupTotals[i]);
    const rectX = xScale(0);
    const rectWidth = groupTotalWidth - 1;
    const rectY = (yScale(g) as number) - height / 15;
    const labelText = groupTotals[i];
    return (
      labelText > 0 && <Text
        alignmentBaseline="central"
        textAnchor="middle"
        opacity={clusterConfig?.textOpacity ?? 1}
        content={`${labelText.toFixed(2)}${clusterConfig?.suffix}`}
        fontSize={clusterConfig?.fontSize ?? 10}
        coordinates={[rectX + rectWidth / 2, rectY - 8]}
        textColor={clusterConfig?.textColor ?? "black"}
        key={i}
      />
    );
  });

  const groupTexts = uniqueGroups.map((g, i) => {
    const rectY = yScale(g) as number;
    const rectHeight = yScale.bandwidth();
    return (
      <Text
        alignmentBaseline="central"
        textAnchor="end"
        opacity={labelConfig?.textOpacity ?? 1}
        content={g}
        fontSize={labelConfig?.fontSize ?? 10}
        coordinates={[xScale(0) - 10, rectY + rectHeight / 2]}
        textColor={labelConfig?.textColor ?? "black"}
        key={i}
      />
    );
  });

  const grid = xScale
    .ticks(3)
    .slice(0)
    .map((value, i) => (
      <g key={i}>
        <line
          x1={xScale(value)}
          x2={xScale(value)}
          y1={0}
          y2={boundsHeight}
          stroke={gridConfig?.gridColor ?? "#808080"}
          opacity={gridConfig?.gridOpacity ?? 0.2}
        />
        <Text
          alignmentBaseline="central"
          textAnchor="end"
          opacity={gridConfig?.textOpacity ?? 0.8}
          textColor={gridConfig?.textColor ?? "#808080"}
          content={`${value}${gridConfig?.suffix}`}
          fontSize={gridConfig?.fontSize ?? 9}
          coordinates={[xScale(value), boundsHeight + 10]}
        />
      </g>
    ));

  return (
    <div>
      <svg width={width} height={height}>
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          {grid}
          {bracketRectangles}
          {rectangles}
          {rectangleTexts}
          {bracketRectangleTexts}
          {groupTexts}
        </g>
      </svg>
    </div>
  );
};

const Text = ({
  coordinates,
  textAnchor,
  alignmentBaseline,
  textColor,
  fontSize,
  content,
  opacity,
}: {
  coordinates: [number, number];
  textAnchor: string;
  alignmentBaseline:
    | "auto"
    | "baseline"
    | "before-edge"
    | "text-before-edge"
    | "middle"
    | "central"
    | "after-edge"
    | "text-after-edge"
    | "ideographic"
    | "alphabetic"
    | "hanging"
    | "mathematical"
    | "inherit"
    | undefined;
  textColor: string;
  fontSize: number;
  content: string;
  opacity: number;
}) => {
  const springProps = useSpring({
    to: {
      pos: [coordinates[0], coordinates[1]],
    },
  });
  return (
    <animated.text
      alignmentBaseline={alignmentBaseline}
      textAnchor={textAnchor}
      fill={textColor}
      fontSize={fontSize}
      opacity={opacity}
      x={springProps.pos.to((x) => x)}
      y={springProps.pos.to((_, y) => y)}
    >
      {content}
    </animated.text>
  );
};

const Rect = ({
  coordinates,
  width,
  height,
  bgColor,
  strokeColor,
  strokeWidth,
}: {
  coordinates: number[];
  width: number;
  height: number;
  bgColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
}) => {
  const springProps = useSpring({
    to: {
      pos: [width, coordinates[0]],
    },
  });
  return (
    <animated.rect
      x={springProps.pos.to((_, x) => x)}
      y={coordinates[1]}
      width={springProps.pos.to((x) => x)}
      height={height}
      fill={bgColor ?? "transparent"}
      stroke={strokeColor ?? "black"}
      strokeWidth={strokeWidth ?? 1}
    />
  );
};
