/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import SVGIcon from "../SvgIcons/index";
export interface Props {
  handleChange?: any;
  onKeyPress?: any;
}
const SearchBox: React.FC<Props> = ({ handleChange, onKeyPress }) => {
  return (
    <div className="custom-control custom-control--search">
      <input
        className="control"
        placeholder="Search"
        onChange={handleChange}
        onKeyPress={onKeyPress}
      ></input>
      <button className="custom-control__search-submit" type="submit">
        <SVGIcon iconName="search" />
      </button>
    </div>
  );
};
export default SearchBox;
