import React from "react";
import SVGIcon from "../SvgIcons/index";
interface Props {
  name: string;
  icon: string;
  onButtonClick: (e: React.SyntheticEvent) => void;
}
const AdminButton: React.FC<Props> = ({ name, icon, onButtonClick }) => {
  return (
    <span
      className={`btn btn--primary ${icon ? "has-icon" : ""}`}
      data-toggle="modal"
      data-modal-target="#modal-file-upload--multiple-approved-kiids"
      onClick={onButtonClick}
    >
      {icon && (
        <span className="icon">
          <SVGIcon iconName={icon} />
        </span>
      )}
      {name}
    </span>
  );
};
export default AdminButton;
