import React, { useState, useEffect } from "react";
import SfdrClientApproverDetails from "../SfdrClientApproverDetails/index";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { HistoryLinks } from "../../../types/admin/breadCrumb";
import PageHeader from "../../../components/PageHeader";
import { ADMIN_URL_PREFIX } from "../../../lib/constants";
interface LocationState {
  clientName: string;
  id: number;
  selectedTab: string;
}
function ApproverGroupConfig(props: any) {
  const location = useLocation();
  const state = location.state as LocationState;
  const [selectedTab, setSelectedTab] = useState<string>("SFDR Approvers");

  const naviation = useNavigate();

  useEffect(() => {
    (async function () {
      setSelectedTab(state.selectedTab);
    })();
  }, [state]);

  function onBreadCrumbLinkChange(data: HistoryLinks) {
    if (data.url) {
      naviation(data.url);
    }
  }

  return location && location.state && state.clientName ? (
    <>
      <PageHeader
        activeTab={selectedTab}
        title={state.clientName}
        breadCrumbDetails={{
          historyLinks: [
            {
              linkName: "SFDR Manager",
              url: ADMIN_URL_PREFIX,
              isCurrent: false,
            },
            // {
            //   linkName: state.clientName,
            //   url: ADMIN_URL_PREFIX,
            //   isCurrent: false,
            // },
            {
              linkName: "Approver",
              url: "",
              isCurrent: true,
            },
          ],
          onLinkClick: onBreadCrumbLinkChange,
        }}
      />
      <div className="content__body">
        <div className="container">
          <div className="tab-content">
            <div className="page-body">
              <SfdrClientApproverDetails
                clientName={state.clientName}
                clientId={state.id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" replace />
  );
}

export default ApproverGroupConfig;
