import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavigationPanel from "../NavigationPanel";
import Context from "../../store/context";
import { GLOBAL_CONTEXT } from "../../types/store";

const AdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const {
    globalState: { userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);
  if (!userDetails?.userType || userDetails?.userType !== "admin") {
    return <div>Unauthorized Access</div>;
  }
  return (
    <main className="kiid-generator-main">
      {userDetails && userDetails.userType === "admin" && (
        <>
          <NavigationPanel
            onSwiftSelect={() => {
              navigate("/sfdr", { replace: true });
            }}
            userDetails={userDetails}
          />
          <section className="content">
            <Outlet />
          </section>
        </>
      )}
    </main>
  );
};

export default AdminLayout;
