import React from "react";
import { ImageProps } from "../types";

const RequestDocCircle: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.5C7.99187 1.5 1.5 7.99187 1.5 16C1.5 24.0081 7.99187 30.5 16 30.5C24.0081 30.5 30.5 24.0081 30.5 16C30.5 7.99187 24.0081 1.5 16 1.5ZM0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#ADB5BD"
      />
      <path
        d="M21.5644 16.3624C21.3078 16.3624 21.0999 16.5702 21.0999 16.8268V21.0999H10.8038V10.8038H15.077C15.3336 10.8038 15.5414 10.596 15.5414 10.3394C15.5414 10.0828 15.3336 9.875 15.077 9.875H10.3394C10.0828 9.875 9.875 10.0828 9.875 10.3394V21.5644C9.875 21.6874 9.92376 21.8059 10.0108 21.8929C10.0979 21.98 10.2163 22.0288 10.3394 22.0288H21.5644C21.6874 22.0288 21.8059 21.98 21.8929 21.8929C21.98 21.8059 22.0288 21.6874 22.0288 21.5644V16.8268C22.0288 16.7037 21.98 16.5853 21.8929 16.4982C21.8058 16.4112 21.6874 16.3624 21.5644 16.3624Z"
        fill="#ADB5BD"
        stroke="#ADB5BD"
        strokeWidth="0.8"
      />
      <path
        d="M22.0194 10.2488C22.0194 10.2348 22.0194 10.2209 22.0078 10.2093C22.0037 10.1936 21.9991 10.1779 21.9939 10.1628C21.9869 10.1472 21.9794 10.1315 21.9706 10.1164C21.9706 10.1164 21.9706 10.0932 21.9521 10.0816C21.9184 10.0311 21.8749 9.98753 21.8244 9.95386L21.7895 9.93528L21.7431 9.91206L21.6966 9.89813L21.6548 9.88652C21.6252 9.88361 21.5962 9.88361 21.5666 9.88652H18.1205C17.8639 9.88652 17.6561 10.0943 17.6561 10.3509C17.6561 10.6075 17.8639 10.8154 18.1205 10.8154H20.4426L15.6243 15.6243C15.4426 15.8061 15.4426 16.0998 15.6243 16.2815C15.806 16.4632 16.0997 16.4632 16.2814 16.2815L21.0998 11.4608V13.7829C21.0998 14.0395 21.3076 14.2473 21.5642 14.2473C21.8208 14.2473 22.0286 14.0395 22.0286 13.7829V10.3392C22.0286 10.309 22.0251 10.2782 22.0193 10.2486L22.0194 10.2488Z"
        fill="#ADB5BD"
        stroke="#ADB5BD"
        strokeWidth="0.8"
      />
    </svg>
  );
};

export default RequestDocCircle;
