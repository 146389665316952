import React, { useState } from "react";
import SVGIcon from "../SvgIcons";
import Select from "react-select";
interface Props {
  header?: any;
  onModalClose?: any;
  showModal?: any;
  onSave?: any;
  config?: any;
  onapproverTitleChangeHandler?: any;
  onapproverLevelChangeHandler?: any;
  approverTitle?: string;
  approverLevel?: number;
  isEditable?: boolean;
  selectedApproveUsers?: any;
  setSelectedApproveUsers?: any;
  usersList?: [];
}
type ApproverModalState = {
  title: string;
  level: number;
};
const ApproverAddBox: React.FC<Props> = (props: any) => {
  const [approverModalState, setApproverModalState] =
    useState<ApproverModalState>();
  return (
    <>
      <div
        className={"modal fade" + (props.showModal ? " in show" : "")}
        id="modal-questionnaire-add-question"
        role="dialog"
        aria-labelledby="modal-questionnaire-add-question"
        aria-hidden="true"
        data-init="modal"
        style={props.showModal ? { display: "block" } : { display: "none" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={
              props.config
                ? props.config.style
                : { height: "100vh", width: "100%" }
            }
          >
            <div
              className="modal-content__header"
              style={{
                position: "absolute",
                width: "100%",
                backgroundColor: "white",
                /* padding: 32px; */
                height: "72px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  color: "white",
                  top: "20px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={props.onModalClose}
              >
                <SVGIcon iconName="cross" />
              </div>
              {props.header}
            </div>
            <div
              className="modal-content__body"
              style={{
                position: "absolute",
                top: "72px",
                bottom: "80px",
                width: "100%",
                overflow: "auto",
              }}
            >
              <label htmlFor="approver-title">Group Title</label>
              <input
                id="approver-title"
                type="text"
                className="control"
                onChange={props.onapproverTitleChangeHandler}
                placeholder="Group Name"
                disabled={props.isEditable}
                value={props.approverTitle}
                required
              />
              <label htmlFor="approver-level" style={{ marginTop: "0.6rem" }}>
                Level
              </label>
              <input
                className="control"
                id="approver-level"
                type="number"
                onChange={props.onapproverLevelChangeHandler}
                placeholder="Level"
                disabled={props.isEditable}
                value={props.approverLevel}
                required
              />
              <label htmlFor="user-select" style={{ marginTop: "0.6rem" }}>
                Users
              </label>
              <Select
                id="user-select"
                value={props.selectedApproveUsers}
                onChange={props.setSelectedApproveUsers}
                isMulti={true}
                placeholder={"Select Users"}
                options={props.usersList.map((user: any) => {
                  return {
                    value: user.id,
                    label: user.name,
                  };
                })}
                isClearable={true}
              />
            </div>
            <div
              className="modal-content__footer"
              style={{
                position: "absolute",
                width: "100%",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <button
                type="button"
                className="btn btn--secondary"
                onClick={props.onModalClose}
              >
                Cancel
              </button>
              {props.onSave && (
                <button
                  type="button"
                  className="btn btn--primary"
                  onClick={props.onSave}
                  disabled={
                    !props.selectedApproveUsers ||
                    !props.approverLevel ||
                    !props.approverTitle
                  }
                >
                  Save
                </button>
              )}
              {props.buttons}
            </div>
          </div>
        </div>
      </div>
      {props.showModal && (
        <div
          className={"modal-backdrop fade" + (props.showModal ? " in" : "")}
        />
      )}
    </>
  );
};
export default ApproverAddBox;
