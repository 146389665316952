import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../../components/Button";
import InfoToolTip from "../../../components/InfoToolTip";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import { Label } from "../../../components/Select/styles";
import { fetchData, postData } from "../../../services/apis";
import { notifyError, notifySuccess } from "../../../utils/common";
import {
  ContentContainer,
  DisabledContainer,
  ModalContentWrapper,
} from "../../listing/ActionButtons/styles";

import {
  Box,
  FlexAlignCentered,
  HeaderBox,
  SpacingWrapper,
  Title,
} from "./styles";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  documentId: string;
  clientId: string;
};
type PdfConfiguration = {
  versionPlacement?: string;
  pageNumberPlacement?: string;
};
type PdfConfigsKey = keyof PdfConfiguration;
type Option = { value: string; label: string };

const DocumentConfigurationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  documentId,
  clientId,
}) => {
  const [config, setConfig] = useState<PdfConfiguration | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const positionOptions: Option[] = useMemo(() => {
    return [
      { value: "topRight", label: "Top right" },
      { value: "topLeft", label: "Top left" },
      { value: "bottomLeft", label: "Bottom left" },
      { value: "bottomRight", label: "Bottom right" },
    ];
  }, []);
  useEffect(() => {
    getConfiguration();
  }, [documentId]);
  const onDocumentPdfConfigSave = async () => {
    try {
      const saveResp = await postData({
        endPoint: "updatePdfConfiguration",
        data: {
          clientId: clientId,
          documentId: documentId,
          pdfConfiguration: config,
        },
      });
      if (saveResp.status === 200) {
        notifySuccess("Document customization saved successfully");
        onClose?.();
      } else {
        notifyError("Error while saving customization");
      }
    } catch (error) {
      notifyError("Error while saving customization");
    }
  };
  const getConfiguration = async () => {
    if (clientId && documentId) {
      try {
        setLoading(true);
        const response = await fetchData({
          endPoint: "getDocumentConfiguration",
          queryParam: {
            clientId: clientId,
            documentId: documentId,
          },
        });
        if (response.data.data) {
          setConfig(response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const getPositionOptions = useCallback(
    (element: PdfConfigsKey) => {
      const selectedOptions: any[] = [];
      for (const key in config) {
        if (key !== element && config) {
          if (config[key as PdfConfigsKey]) {
            config?.[key as PdfConfigsKey] &&
              selectedOptions.push(config[key as PdfConfigsKey]);
          }
        }
      }
      return positionOptions.filter(
        (posOpt) => !selectedOptions.includes(posOpt.value)
      );
    },
    [config]
  );
  const VersionNumber = () => {
    return (
      <Box>
        <HeaderBox>
          <Title alignItems="center">Version Number</Title>
        </HeaderBox>
        <SpacingWrapper>
          <ContentContainer>
            <div>
              <FlexAlignCentered>
                <Label>Number</Label>
                <InfoToolTip
                  iconName="blueInfo"
                  width={247}
                  color={"#595B6B"}
                  iconHeight={16}
                  iconWidth={16}
                  paddingLeft={5}
                >
                  Please note that the version number is system generated once
                  the document is approved
                </InfoToolTip>
              </FlexAlignCentered>
              <DisabledContainer>System generated</DisabledContainer>
            </div>
            <Select
              label="Placement"
              options={getPositionOptions("versionPlacement")}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                setConfig((prevSate) => {
                  return { ...prevSate, versionPlacement: e.value };
                });
              }}
              value={positionOptions.find(
                (option) => option.value === config?.versionPlacement
              )}
              isLoading={loading}
            />
          </ContentContainer>
        </SpacingWrapper>
      </Box>
    );
  };
  const PageNumber = () => {
    return (
      <Box>
        <HeaderBox>
          <Title alignItems="center">Page Numbering</Title>
        </HeaderBox>
        <SpacingWrapper>
          <ContentContainer>
            <div>
              <FlexAlignCentered>
                <Label>Number</Label>
                <InfoToolTip
                  iconName="blueInfo"
                  width={247}
                  color={"#595B6B"}
                  iconHeight={16}
                  iconWidth={16}
                  paddingLeft={5}
                >
                  Please note that the Page number is system generated
                </InfoToolTip>
              </FlexAlignCentered>
              <DisabledContainer>System generated</DisabledContainer>
            </div>
            <Select
              label="Placement"
              options={getPositionOptions("pageNumberPlacement")}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                setConfig((prevSate) => {
                  return { ...prevSate, pageNumberPlacement: e.value };
                });
              }}
              value={positionOptions.find(
                (option) => option.value === config?.pageNumberPlacement
              )}
              isLoading={loading}
            />
          </ContentContainer>
        </SpacingWrapper>
      </Box>
    );
  };
  const isButtonEnabled = useMemo(
    () => !!(config?.versionPlacement || config?.pageNumberPlacement),
    [config]
  );
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Template Customisation"
        width={773}
      >
        <ModalContentWrapper>
          <PageNumber />
          <VersionNumber />
          <Button
            buttonType="solid"
            onClick={onDocumentPdfConfigSave}
            disabled={!isButtonEnabled}
          >
            Customise
          </Button>
        </ModalContentWrapper>
      </Modal>
    </div>
  );
};

export default DocumentConfigurationModal;
