import React from "react";
import { ImageProps } from "../types";

const RequestDoc: React.FC<ImageProps> = ({
  ...otherProps
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M14.3593 8.41417C14.066 8.41417 13.8285 8.65168 13.8285 8.94493V13.8285H2.06153V2.06153H6.9451C7.23835 2.06153 7.47586 1.82402 7.47586 1.53076C7.47586 1.23751 7.23835 1 6.9451 1H1.53076C1.23751 1 1 1.23751 1 1.53076V14.3593C1 14.4999 1.05573 14.6353 1.15525 14.7348C1.25477 14.8343 1.39011 14.89 1.53076 14.89H14.3593C14.4999 14.89 14.6353 14.8343 14.7348 14.7348C14.8343 14.6353 14.89 14.4999 14.89 14.3593V8.94493C14.89 8.80428 14.8343 8.66894 14.7348 8.56942C14.6353 8.4699 14.4999 8.41417 14.3593 8.41417Z"
        fill="#ADB5BD"
        stroke="#ADB5BD"
        strokeWidth="0.8"
      />
      <path
        d="M14.8793 1.42717C14.8793 1.41124 14.8793 1.39532 14.8661 1.38205C14.8614 1.36414 14.8561 1.34622 14.8501 1.32897C14.8422 1.31106 14.8335 1.29315 14.8236 1.2759C14.8236 1.2759 14.8236 1.24936 14.8024 1.23609C14.7639 1.17837 14.7141 1.12861 14.6564 1.09013L14.6166 1.0689L14.5635 1.04236L14.5104 1.02644L14.4627 1.01317C14.4288 1.00985 14.3957 1.00985 14.3618 1.01317H10.4235C10.1302 1.01317 9.89272 1.25068 9.89272 1.54393C9.89272 1.83719 10.1302 2.0747 10.4235 2.0747H13.0773L7.57061 7.57069C7.36294 7.77835 7.36294 8.11405 7.57061 8.32172C7.77828 8.52939 8.11397 8.52939 8.32164 8.32172L13.8283 2.81231V5.46613C13.8283 5.75938 14.0658 5.99689 14.3591 5.99689C14.6523 5.99689 14.8899 5.75938 14.8899 5.46613V1.5305C14.8899 1.496 14.8859 1.46083 14.8792 1.427L14.8793 1.42717Z"
        fill="#ADB5BD"
        stroke="#ADB5BD"
        strokeWidth="0.8"
      />
    </svg>
  );
};

export default RequestDoc;
