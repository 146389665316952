import { Language as LanguageList } from "./document-question";
import { TUserDetails } from "./user-details";

export type DocumentRecord = {
  _id: string;
  clientId: string;
  fundName: string;
  fundId: string;
  subFundId: string;
  subFundName: string;
  subFundSlug: string;
  article: DocumentArticle;
  template: DocumentTemplate;
  language: keyof typeof LanguageList;
  createdAt: string;
  createdBy: DocumentUserMeta;
  deletedAt: string | null;
  deletedBy: DocumentUserMeta;
  updatedAt: string;
  updatedBy: DocumentUserMeta;
  status: DocumentStatusEnum;
  legalEntityIdentifier: string;
  isPreviousVersion: boolean;
  statusUpdateHistory?: StatusUpdateHistory[];
  documentState?: DocumentState;
  approvedVersion?: string;
  id?: string;
  version?: string;
  approvalDetails: ApprovalDetails[];
  approvalGroups: ApprovalGroup[];
  archivedAt: string | null;
  archivedBy: DocumentUserMeta;
};

export interface StatusUpdateHistory {
  statusChangedTo: DocumentStatusEnum;
  updatedBy: string;
  updatedById: number;
  updatedAt: string;
}

export type DocumentUserMeta = {
  userName: string;
  userId: string;
};

export enum DocumentArticle {
  Article8 = "Article 8",
  Article9 = "Article 9",
}

export const DocumentArticleList = Object.keys(DocumentArticle);

export type DocumentArticleListKeys = keyof typeof DocumentArticle;

export enum DocumentTemplate {
  Periodic = "Periodic",
  PreContractual = "Pre-contractual",
}

export const DocumentTemplateList = Object.keys(DocumentTemplate);

export type DocumentTemplateListKeys = keyof typeof DocumentTemplate;

export enum DocumentViewModeEnum {
  view = "view",
  edit = "edit",
}

export type DocumentViewModeKeys = keyof typeof DocumentViewModeEnum;

export type DocumentState = {
  [key: string]: DocumentAnswer;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DocumentAnswer = any;

export enum DocumentStatusEnum {
  draft = "Draft",
  traslation_requested = "Translation Requested",
  awaiting_approval = "Awaiting Approval",
  awaiting_comments_review = "Awaiting Comments Review",
  edited_by_approver = "Edited By Approver",
  approved = "Approved",
  filed = "Filed",
}
export const DocumentStatusToKey: {
  [key in DocumentStatusEnum]: KeyOfDocumentStatus;
} = {
  Draft: "draft",
  "Translation Requested": "traslation_requested",
  "Awaiting Approval": "awaiting_approval",
  "Awaiting Comments Review": "awaiting_comments_review",
  "Edited By Approver": "edited_by_approver",
  Approved: "approved",
  Filed: "filed",
};
export type KeyOfDocumentStatus = keyof typeof DocumentStatusEnum;

export enum DocumentUserEnum {
  editor = "editor",
  approver = "approver",
  client_admin = "client_admin",
  super_admin = "super_admin",
}

export type DocumentUser = keyof typeof DocumentUserEnum;

export enum DocumentActionEnum {
  edit = "edit",
  preview = "preview",
  add_traslation = "add_traslation",
  download = "download",
  previous_version = "previous_version",
  delete = "delete",
  approve = "approve",
  comment = "comment",
  change_status = "change_status",
  filed = "filed",
  previous_answers = "previous_answers",
}

export type DocumentAction = keyof typeof DocumentActionEnum;

export type DocumentUserPermission = {
  [key in KeyOfDocumentStatus]: {
    [key in DocumentAction]: boolean;
  };
};
export type DocumentActionPermissions = {
  [key in DocumentUser]: DocumentUserPermission;
};
export interface Approval {
  _id: string;
  clientName: string;
  clientId: number;
  updatedAt: string;
  approvalGroup: ApprovalGroup[];
}
export interface ApprovalGroup {
  approvalGroupTitle: string;
  approvalLevel: string;
  approvalUsers: ApprovalUser[];
  deletedAt: null;
  groupId: string;
}
export interface ApprovalUser {
  value: number;
  label: string;
}

export type DocLockStatus = {
  documentId: string;
  userDetails: TUserDetails | null;
  shouldFetchDocumentState?: boolean;
};

export type LanguageData = {
  name: string;
  iso_alpha_2_code: string;
  iso_alpha_code: string;
  id: number;
};

export type Language = {
  label: string;
  value: string;
};

export interface ApprovalDetails {
  _id: string;
  clientId: string;
  documentId: string;
  groupId: string;
  updatedAt: string;
  approvedAt: string;
  approvalGroupTitle: string;
  approvalLevel: string;
  approvalUsers: {
    label: string;
    value: number;
  }[];
  deletedAt: string | null;
}

export enum PageType {
  active = "active",
  archived = "archived",
}
