import React from "react";
import Button from "../../../../components/Button";
import Input from "./Input";
import { TableNumberInput } from "./NumberInput";
import {
  ActionWrapper,
  HeaderText,
  HeaderTextWrapper,
  StyledSelect,
  TableContentContainer,
  TableRowWrapper,
  TableHeaderContainer,
  TableItemWrapper,
  TableWrapper,
} from "./styles";
import Image from "../../../../components/Image";
import { notifySuccess, spacingIncrement } from "../../../../utils/common";

const MAX_TABLE_ROWS = 15;

export type HeaderData = {
  label: string;
  key: string;
  spacing: number;
};

export type ContentData = {
  largeInvestments: string;
  sector: string;
  assets: string;
  country: string;
};

type Props = {
  onChange?: (data: ContentData[]) => void;
  isEditMode?: boolean;
  data?: ContentData[];
  headerData?: HeaderData[];
  countryList: string[];
};

const TableInput: React.FC<Props> = ({
  onChange,
  isEditMode,
  data: contentData = [],
  headerData = [],
  countryList,
}) => {
  const onChangeHandler = (key: string, value: string, index: number) => {
    const updatedData = [
      ...contentData.slice(0, index),
      {
        ...contentData[index],
        [key]: value,
      },
      ...contentData.slice(index + 1),
    ];
    onChange?.(updatedData);
  };
  const addNewEntry = () => {
    if (contentData.length + 1 >= MAX_TABLE_ROWS) {
      notifySuccess("You have reached maximum limit of adding Investments");
    }
    if (contentData.length >= MAX_TABLE_ROWS) {
      return;
    }
    const newD = [...contentData];
    onChange?.([
      ...newD,
      {
        largeInvestments: "",
        sector: "",
        assets: "",
        country: "",
      },
    ]);
  };

  const onDeleteEntry = (index: number) => {
    const newD = [...contentData];
    newD.splice(index, 1);
    onChange?.(newD);
  };

  return (
    <div>
      {isEditMode && (
        <ActionWrapper>
          <Button
            buttonType="solid"
            id="add-top-investment-btn"
            onClick={addNewEntry}
            disabled={contentData.length >= MAX_TABLE_ROWS}
          >
            Add
          </Button>
        </ActionWrapper>
      )}
      <TableWrapper>
        <TableHeaderContainer>
          {headerData.map((item, index) => (
            <TableItemWrapper key={index} spacing={item.spacing}>
              <HeaderTextWrapper>
                <HeaderText>{item.label}</HeaderText>
              </HeaderTextWrapper>
            </TableItemWrapper>
          ))}
          {isEditMode && <TableItemWrapper spacing={0.25} />}
        </TableHeaderContainer>
        <TableContentContainer>
          {contentData?.map((item, index) => {
            return (
              <TableRowWrapper key={index}>
                <TableItemWrapper
                  spacing={headerData[0].spacing}
                  title={item.largeInvestments}
                >
                  {isEditMode ? (
                    <Input
                      id={`large-investments-${index}`}
                      disabled={!isEditMode}
                      maxLimit={100}
                      placeholder="Enter Investment Name"
                      value={item.largeInvestments}
                      onChange={(val) =>
                        onChangeHandler(
                          "largeInvestments",
                          val.toString(),
                          index
                        )
                      }
                    />
                  ) : (
                    item.largeInvestments
                  )}
                </TableItemWrapper>
                <TableItemWrapper
                  spacing={headerData[1].spacing}
                  title={item.sector}
                >
                  {isEditMode ? (
                    <Input
                      id={`sector-${index}`}
                      disabled={!isEditMode}
                      maxLimit={100}
                      placeholder="Enter Sector"
                      value={item.sector}
                      onChange={(val) =>
                        onChangeHandler("sector", val.toString(), index)
                      }
                    />
                  ) : (
                    item.sector
                  )}
                </TableItemWrapper>
                <TableItemWrapper spacing={headerData[2].spacing}>
                  {isEditMode ? (
                    <TableNumberInput
                      id={`assets-${index}`}
                      disabled={!isEditMode}
                      maxLimit={100}
                      placeholder="Enter Assets percentage"
                      value={item.assets || 0}
                      onChange={(val) =>
                        onChangeHandler("assets", val.toString(), index)
                      }
                    />
                  ) : (
                    `${item.assets}%`
                  )}
                </TableItemWrapper>
                <TableItemWrapper spacing={headerData[3].spacing}>
                  {isEditMode ? (
                    <StyledSelect
                      inputId={`country-${index}`}
                      defaultValue={{
                        value: item.country,
                        label: item.country,
                      }}
                      isDisabled={!isEditMode}
                      isSearchable={true}
                      name="country"
                      value={{
                        value: item.country,
                        label: item.country,
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "transparent",
                          border: "1px solid #d9dae3",
                          fontSize: spacingIncrement(13),
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontSize: spacingIncrement(13),
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 4,
                        }),
                      }}
                      components={{
                        DropdownIndicator: () => (
                          <div style={{ padding: "0 10px" }}>
                            <Image name="downArrow" />
                          </div>
                        ),
                        IndicatorSeparator: () => <div />,
                      }}
                      options={
                        countryList.map((item) => ({
                          value: item,
                          label: item,
                        })) as any
                      }
                      onChange={(d: any) => {
                        onChangeHandler("country", d.value, index);
                      }}
                    />
                  ) : (
                    item.country
                  )}
                </TableItemWrapper>
                {isEditMode ? (
                  <TableItemWrapper
                    spacing={0.25}
                    style={{ cursor: "pointer", justifyContent: "center" }}
                    onClick={() => onDeleteEntry(index)}
                  >
                    <Image name="deleteCircle" height={32} width={32} />
                  </TableItemWrapper>
                ) : null}
              </TableRowWrapper>
            );
          })}
        </TableContentContainer>
      </TableWrapper>
    </div>
  );
};

export default TableInput;
