import React from "react";
import { ImageProps } from "../types";

const Previous: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M6.36843 2.8349C5.97131 2.8349 5.65625 3.14987 5.65625 3.54708V6.77916C5.65625 7.0394 5.8069 7.28586 6.03972 7.40919L8.06659 8.49107C8.17617 8.54581 8.28574 8.57323 8.3953 8.57323C8.65554 8.57323 8.902 8.43624 9.02533 8.18976C9.21706 7.84738 9.08007 7.40908 8.72404 7.21735L7.08063 6.34085V3.54702C7.09429 3.1499 6.76566 2.83484 6.36845 2.83484L6.36843 2.8349Z"
        fill="#0ABAEE"
      />
      <path
        d="M11.148 1.87623C9.92912 0.671117 8.32677 0 6.61486 0C4.91663 0 3.328 0.643678 2.12282 1.83514L1.46547 1.17779C1.219 0.931327 0.794375 1.05456 0.712203 1.39694L0.0136957 4.42359C-0.0684613 4.75231 0.232842 5.03986 0.547811 4.95771L3.57447 4.25928C3.91685 4.17712 4.04008 3.75257 3.79361 3.50601L3.14993 2.86233C3.21843 2.79384 3.28693 2.72534 3.35532 2.6706C5.20423 1.0272 8.01172 1.04084 9.87427 2.6706C10.9699 3.64302 11.5862 5.03986 11.5588 6.50528C11.5314 7.9707 10.8603 9.3402 9.72362 10.2715C7.95698 11.7095 5.36853 11.7369 3.57445 10.34C2.95819 9.86059 2.4788 9.27174 2.15019 8.58698C2.02695 8.34051 1.76672 8.1761 1.49284 8.1761C1.4381 8.1761 1.36961 8.18976 1.31477 8.20352C1.10938 8.25826 0.931306 8.39524 0.83539 8.60063C0.739476 8.80602 0.739476 9.02516 0.83539 9.23066C1.17777 9.94284 1.64339 10.5728 2.23233 11.1206C4.629 13.353 8.42259 13.3941 10.874 11.2028C12.1888 10.0113 12.9558 8.38156 12.9968 6.61487C13.0516 4.80705 12.3942 3.13628 11.1479 1.87631L11.148 1.87623Z"
        fill="#0ABAEE"
      />
    </svg>
  );
};

export default Previous;
