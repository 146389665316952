import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container, { tabList, TabListType } from "./Container";
import { CLIENT_URL_PREFIX } from "../../lib/constants";
import Context from "../../store/context";
import { GLOBAL_CONTEXT } from "../../types/store";
import { getDefaultRoute, getDefaultTab } from "./services";

const AppHeader: React.FC = () => {
  const {
    globalState: { totalDocumentsCount },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const location = useLocation();
  const navigation = useNavigate();
  const defaultRoute = getDefaultRoute(location?.pathname);
  const defaultTab = getDefaultTab(defaultRoute);
  const [activeTab, setActiveTab] = useState<TabListType>(defaultTab);

  useEffect(() => onTabChange(activeTab), []);

  const onTabChange = (selectedTabName: TabListType) => {
    let index = 0;
    if (selectedTabName) index = tabList.indexOf(selectedTabName);
    if (index > -1) {
      navigation(`${CLIENT_URL_PREFIX}${tabList[index]}`, { replace: true });
      setActiveTab(selectedTabName);
    }
  };

  return (
    <div className="page-header">
      <Container
        details={`Total Documents ${totalDocumentsCount}`}
        selectedTab={activeTab}
        getSelectedTab={onTabChange}
      />
    </div>
  );
};

export default AppHeader;
