import React from "react";
import Image from "../../../../components/Image";
import { Container } from "./styles";

type Props = {
  hasComments?: boolean;
  paddingLeft?: number;
  onClick?: () => void;
  disabled?: boolean;
};

const CommentButton: React.FC<Props> = ({
  hasComments,
  paddingLeft = 10,
  onClick,
  disabled,
}) => {
  if (disabled) {
    return null;
  }
  return (
    <Container paddingLeft={paddingLeft} onClick={onClick}>
      <Image name={hasComments ? "templateCommentFilled" : "templateComment"} />
    </Container>
  );
};

export default CommentButton;
