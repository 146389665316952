import React from "react";
import BreadCrumbComponent from "../Breadcrumbs/index";
import { BreadCrumbDetail } from "../../types/admin/breadCrumb";
interface Props {
  title: string;
  count?: string;
  info?: string;
  tabList?: [];
  onTabChange?: (tab: string) => void;
  breadCrumbDetails: BreadCrumbDetail;
  activeTab?: string;
}
const PageHeader: React.FC<Props> = ({
  title,
  count,
  info,
  tabList,
  onTabChange,
  breadCrumbDetails,
  activeTab,
}) => {
  //   const [activeTab, setActiveTab] = useState(
  //     tabList && tabList.length ? tabList[0] : ""
  //   );
  return (
    <header className="content__header">
      <div className="container">
        {breadCrumbDetails && (
          <BreadCrumbComponent
            historyLinks={breadCrumbDetails.historyLinks}
            onLinkClick={breadCrumbDetails.onLinkClick}
          />
        )}
        <div className="page-details">
          <h1 className="page-details__title">{title}</h1>
          <ul className="page-details__tags">
            <li>{count}</li>
            {info && <li>{info}</li>}
          </ul>
        </div>
        {tabList && tabList.length > 0 && (
          <div className="page-tabs">
            <ul
              className="nav nav--tabs"
              id="tabs"
              role="tablist"
              data-init="tabs"
            >
              {tabList.map((value, index) => {
                return (
                  <li
                    style={{ cursor: "pointer" }}
                    className="nav__item"
                    key={index}
                    onClick={() => {
                      //setActiveTab(value);
                      onTabChange?.(value);
                    }}
                  >
                    <button
                      className={`buttonAsLink nav__link ${
                        activeTab === value ? "active" : ""
                      }`}
                      data-toggle="tab"
                      //href={`#${value}`}
                      role="tab"
                      aria-controls={value}
                      aria-selected="true"
                    >
                      {value}
                    </button>
                  </li>
                );
              })}

              {/* <li className="nav__item">
                <a
                  className="nav__link"
                  data-toggle="tab"
                  href="#kiids-for-approval-panel"
                  role="tab"
                  aria-controls="kiids-for-approval-panel"
                  aria-selected="true"
                >
                  KIIDs for approval
                </a>
              </li> */}
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};
export default PageHeader;
