import React from "react";
import { ImageProps } from "../types";

const LeafBW: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="145"
      height="145"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="72.5" cy="72.5" r="72.5" fill="#9D9D9C" />
      <circle cx="73" cy="73" r="54" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.6991 55.7932C64.1857 54.3682 64.6528 52.2705 65.1241 49.252C66.0868 45.1517 65.9415 33.7566 63.4677 39.9358C63.0548 40.9673 62.5829 42.0306 61.645 42.6263C59.8066 43.7937 57.0848 44.2333 54.5311 44.6458C52.0921 45.0397 49.8065 45.4089 48.5903 46.3637C44.7006 47.8442 41.6855 51.5006 41.3772 55.7578C41.1835 58.4317 42.0113 60.9451 43.5315 62.92C43.7686 63.7876 44.3873 64.5146 45.2306 64.8807L45.8921 65.1679C47.4063 66.2197 49.2164 66.892 51.192 67.035C56.9731 67.4537 63.165 61.5429 63.6991 55.7932Z"
        fill="#3C3C3B"
      />
      <path
        d="M33.2231 63.4688L33.9348 63.4369C41.1629 63.1129 48.1766 60.8818 54.2635 56.9703L56.5285 55.5148"
        stroke="#3C3C3B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.2887 52.8579L55.4438 58.1671C53.1957 61.2713 50.4496 63.9824 47.3167 66.1903V66.1903C47.3167 66.1903 45.5741 65.9962 44.873 65.4634C44.172 64.9305 43.1936 64.0702 43.1936 64.0702L45.4863 63.0683C49.4438 61.3388 53.0577 58.9113 56.1555 55.9018L59.2887 52.8579Z"
        fill="white"
      />
      <path
        d="M48 123.555L66.9633 71.5594L112 36C119 38.5 133.245 63.7519 128 86.5C114.685 122.247 81.0849 133.304 48 123.555Z"
        fill="#3C3C3B"
      />
      <path
        d="M66.9633 71.5594L48 123.555C81.0849 133.304 114.685 122.247 128 86.5M66.9633 71.5594L128 86.5M66.9633 71.5594L112 36C119 38.5 133.245 63.7519 128 86.5"
        stroke="#9D9D9C"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeafBW;
