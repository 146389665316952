import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const StyledInput = styled.input<{ width?: string }>`
  border: solid 1px #ccc;
  width:${(props) => props.width || spacingIncrement(72)}; ;
  border-radius: 4px;
  padding: 4px 9px;
  font-size: ${spacingIncrement(10)};
  margin: 0;
  :focus {
    outline: none;
  }
`;
