import React from "react";
import ReactSelect, { GroupBase, Props, StylesConfig } from "react-select";
import { spacingIncrement } from "../../utils/common";
import Image from "../Image";
import { DropDownContainer, Label, SelectWrapper } from "../Select/styles";
import { Status } from "../Status";

const customStyles: StylesConfig<
  OptionDataProps,
  boolean,
  GroupBase<OptionDataProps>
> = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    border: "1px solid #d9dae3",
    boxSizing: "border-box",
    borderRadius: spacingIncrement(5),
    height: spacingIncrement(42),
  }),
  multiValue: (provided) => ({
    ...provided,
    background: "rgba(246, 246, 246, 0.51)",
    border: "1px solid #DEE2E6",
    borderRadius: "2px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#303245",
    fontSize: spacingIncrement(16),
    fontWeight: 400,
    padding: spacingIncrement(2),
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
  }),
};

export interface OptionDataProps {
  subFundName: string;
  fundName: string;
  subFundId: number;
  fundId: number;
  status: string;
  label: string;
  value: string;
  archived: boolean;
}

const CustomSelect: React.FC<
  Props<OptionDataProps> & { label?: string | JSX.Element; labelHtml: boolean }
> = ({ label, labelHtml, ...otherProps }) => {
  const FormatOptionLabel = ({
    fundName,
    subFundName,
    status,
    archived,
  }: OptionDataProps) => {
    return (
      <div>
        <strong>{fundName}</strong> {` - `} {subFundName} {' - '}
        <Status type={status} name={status} /> {" "}
        {archived ? ' (Archived)' : null}
      </div>
    );
  };
  return (
    <SelectWrapper>
      {label || !labelHtml ? <Label>{label}</Label> : { label }}
      <ReactSelect
        maxMenuHeight={200}
        components={{
          DropdownIndicator: () => (
            <DropDownContainer>
              <Image name="downArrow" />
            </DropDownContainer>
          ),
        }}
        {...otherProps}
        styles={{
          ...customStyles,
          ...otherProps?.styles,
        }}
        formatOptionLabel={FormatOptionLabel}
      />
    </SelectWrapper>
  );
};

export default CustomSelect;
