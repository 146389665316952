import React from "react";
import { ButtonContainer } from "./styles";

type Props = JSX.IntrinsicElements["button"] & {
  loading?: boolean;
  buttonType?: "solid" | "outline";
};

const Button: React.FC<Props> = ({
  children,
  loading,
  buttonType = "outline",
  ...otherProps
}) => {
  return (
    <ButtonContainer
      {...otherProps}
      buttonType={buttonType}
      disabled={loading || otherProps.disabled}
      ref={null}
    >
      {loading ? <span>Loading...</span> : <span>{children}</span>}
    </ButtonContainer>
  );
};

export default Button;
