import React from "react";
import RModal from "react-modal";
import { spacingIncrement } from "../../utils/common";
import { HeaderContainer, LeftContainer, StyledImage, Title } from "./styles";

export type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  height?: number;
  width?: number;
  header?: JSX.Element | string;
  style?: RModal.Styles;
  leftHeaderContainer?: JSX.Element | string;
  containerStyles?: React.CSSProperties;
};

export const defaultStyle: RModal.Styles = {
  content: {
    minHeight: spacingIncrement(250),
    minWidth: spacingIncrement(600),
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    border: "none",
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -40%)",
    padding: 0,
    overflow: "visible",
  },
  overlay: {
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  height,
  width = 600,
  header,
  children,
  style,
  leftHeaderContainer: leftContainer,
  containerStyles,
}) => {
  return (
    <RModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Translation Confirmation"
      style={{
        ...defaultStyle,
        content: {
          ...defaultStyle.content,
          width: spacingIncrement(width),
          height: height ? spacingIncrement(height) : "auto",
        },
        ...style,
      }}
    >
      <HeaderContainer>
        <Title>{header}</Title>
        <LeftContainer>
          {leftContainer}
          <StyledImage name="cross" onClick={onClose} />
        </LeftContainer>
      </HeaderContainer>
      <div style={containerStyles}>{children}</div>
    </RModal>
  );
};

export default Modal;
