import styled from "styled-components";
import Image from "../../../../components/Image";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.div<{ paddingLeft?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ paddingLeft }) => spacingIncrement(paddingLeft || 0)};
`;

export const StyledImage = styled(Image)`
  cursor: pointer;
`;

export const Text = styled.div`
  color: #303245;
  font-size: ${spacingIncrement(12)};
  font-style: normal;
  text-align: center;
`;

export const TextWrapper = styled.div<{ width?: number }>`
  width: ${spacingIncrement(200)};
`;
