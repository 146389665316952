import React from "react";
import parse from "html-react-parser";
import {
  Flex,
  FlexColumn,
  FlexRow,
  LegendSquare,
} from "../../ArticleEightPreContractual/styles";
import { getStaticContentText } from "../../DocumentEditor/service";
import { Text } from "../common.styles";
import { NumberInput } from "../NumberInput";
import styled from "styled-components";
import { DocumentStaticContent } from "../../../../types/document-question";
import { DocumentRecord } from "../../../../types/document-details";

type Props = {
  staticContent: DocumentStaticContent;
  document: DocumentRecord;
  showChartInputPercentage: boolean;
  isEditMode: boolean;
  getValue: (id: string) => any;
  updateAnswer: (id: string, value: any) => void;
  textId: string;
  isNumberDisabled?: boolean;
  answerId: string;
  booletColor?: string;
  paddingTop?: number;
};

// Created Component for Graph input with boollet color and text
const GraphInput: React.FC<Props> = ({
  staticContent,
  document,
  showChartInputPercentage,
  isEditMode,
  getValue,
  updateAnswer,
  textId,
  answerId,
  booletColor = "#999",
  isNumberDisabled = false,
  paddingTop = 5,
}) => {
  return (
    <FlexColumn paddingTop={paddingTop}>
      <Flex flex={1}>
        <FlexRow align="flex-start">
          <LegendSquare color={booletColor} />
          <Flex flex={1}>
            <Text wordBreak="normal" fontSize={10} width={"92px"}>
              {parse(
                getStaticContentText(
                  textId,
                  staticContent.staticText,
                  document.language
                )
              )}
              {/* Taxonomy-aligned: Fossil gas */}
            </Text>
          </Flex>
        </FlexRow>
      </Flex>
      <Flex style={{ marginLeft: "0.8rem" }} flex={1}>
        {!showChartInputPercentage ? (
          <NumberInput
            id={`graph-${answerId}`}
            disabled={!isEditMode || isNumberDisabled}
            maxLimit={100}
            width="4.5rem"
            value={getValue(answerId)}
            onChange={(val) => updateAnswer(answerId, val)}
          />
        ) : (
          <HiddenDiv />
        )}
      </Flex>
    </FlexColumn>
  );
};

const HiddenDiv = styled.div`
  display: none;
`;

export default GraphInput;
