import { useContext, useState } from "react";
import { ButtonContainer, CountContainer, ErrorMsg } from "./styles";
import { Text } from "../../../templates/CommonComponents/common.styles";
import { TextArea } from "../../../help/Help/styles";
import Button from "../../../../components/Button";
import TinyEditor, {
  paste_preprocess_remove_list_tags,
} from "../../../../components/QuillEditor";
import FindAndReplaceOccurences from "./FindAndReplaceOccurences";
import {
  BulkFindResponses,
  ReplaceRequest,
  ReqPayloadDocument,
  SelectedDocument,
} from "../../../../types/bulkFindAndReplace";
import { BulkSelected } from "../../../../types/listing-table";
import { postData } from "../../../../services/apis";
import { AxiosResponse } from "axios";
import {
  getHTMLContentLength,
  hasOnlySpaces,
  notifyError,
  notifySuccess,
} from "../../../../utils/common";
import { GLOBAL_CONTEXT } from "../../../../types/store";
import Context from "../../../../store/context";
import ConfirmationModal from "./ConfirmationModal";

type Props = {
  selectedBulkIds: BulkSelected[];
  onClose: () => void;
};

const FindAndReplace: React.FC<Props> = ({ onClose, selectedBulkIds }) => {
  const limit = 150;
  const [findText, setFindText] = useState("");
  const [replaceText, setReplaceText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [foundResults, setFoundResults] = useState<BulkFindResponses>([]);
  const [selectedOccurences, setSelectedOccurences] = useState<
    SelectedDocument[]
  >([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [amendmentConfirmationModal, setAmendmentConfirmationModal] =
    useState<boolean>(false);
  const [disableFindInput, setDisableFindInput] = useState<boolean>(false);
  const {
    globalState: { userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);

  function updateDocumentslockStatus(lockStatus: "lock" | "unlock") {
    selectedBulkIds.map((doc) => {
      updateLockStatus(doc.id, lockStatus);
    });
  }
  const updateLockStatus = async (
    id: string,
    lockStatus: "lock" | "unlock"
  ) => {
    try {
      await postData({
        endPoint: "updateDocumentLockStatus",
        data: {
          documentId: id,
          updateType: lockStatus,
          clientId: userDetails?.clientId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const searchText = async () => {
    if (!findText.trim()) {
      notifyError("Please enter a find string.");
      return;
    }
    setIsLoading(true);
    const findResponse: AxiosResponse<BulkFindResponses> = await postData({
      endPoint: "bulkFind",
      data: {
        findString: findText,
        language: selectedBulkIds[0].language.toLowerCase() as string,
        documentIds: selectedBulkIds.map((doc) => doc.id),
        clientId: userDetails?.clientId as string,
      },
    }).finally(() => setIsLoading(false));
    if (findResponse.data.length !== 0) {
      setDisableFindInput(true);
      setFoundResults(findResponse.data);
    } else {
      notifyError(
        "No results found. Please try again with a different search string."
      );
    }
  };
  const replaceHandler = async () => {
    if (!replaceText.trim() || hasOnlySpaces(replaceText)) {
      notifyError(
        'Please include a text in the "Replace" box. This cannot be empty.'
      );
      return;
    }
    if (getHTMLContentLength(replaceText) > limit) {
      notifyError(`Replace string cannot be more than ${limit} characters.`);
      return;
    }
    if (!selectedOccurences.length) {
      notifyError("Please select atleast one result to apply the amendment.");
      return;
    }
    setAmendmentConfirmationModal(true);
  };
  async function onConfirm() {
    try {
      setAmendmentConfirmationModal(false);
      const requestDocBody = getReplaceDocumentRequestBody(selectedOccurences);
      const replaceRequest: ReplaceRequest = {
        replaceString: replaceText.replace(/<p>(.*?)<\/p>/, "$1"),
        findString: findText,
        documents: requestDocBody,
        selectAll: isAllSelected,
        clientId: userDetails?.clientId as string,
      };
      setIsLoading(true);
      const replaceRes: AxiosResponse<{ success: boolean }> = await postData({
        endPoint: "bulkReplace",
        data: replaceRequest,
      }).finally(() => setIsLoading(false));
      if (replaceRes.data.success) {
        clearData();
        notifySuccess(
          `Bulk changes have been applied successfully at “${selectedOccurences.length}” occurrences.`
        );
        onCloseHandler();
      }
    } catch (error) {
      notifyError("Something went wrong");
    }
  }
  const getReplaceDocumentRequestBody = (
    selectedOccurences: SelectedDocument[]
  ): ReqPayloadDocument[] => {
    return selectedOccurences.reduce(
      (acc: ReqPayloadDocument[], curr: SelectedDocument) => {
        const docId = curr.docId;
        const questionId = curr.question;
        const occurence = curr.occurence;
        const documentFound = acc.find(
          (doc: ReqPayloadDocument) => doc?.docId === docId
        );
        if (documentFound) {
          const questionFound = documentFound?.questions?.find(
            (question) => question.id === questionId
          );
          if (questionFound) {
            questionFound.positions.push(occurence);
          } else {
            documentFound.questions?.push({
              id: questionId,
              positions: [occurence],
            });
          }
        } else {
          acc.push({
            docId,
            questions: [
              {
                id: questionId,
                positions: [occurence],
              },
            ],
          });
        }
        return acc;
      },
      []
    );
  };
  const clearData = () => {
    setFindText("");
    setReplaceText("");
    setFoundResults([]);
    setIsAllSelected(false);
    setSelectedOccurences([]);
    setAmendmentConfirmationModal(false);
    setDisableFindInput(false);
  };
  const onCloseHandler = () => {
    updateDocumentslockStatus("unlock");
    onClose?.();
    clearData();
  };
  function ButtonsHandler() {
    return (
      <ButtonContainer>
        <Button
          onClick={() => {
            setFindText("");
            setReplaceText("");
            setFoundResults([]);
            setDisableFindInput(false);
          }}
          disabled={isLoading}
        >
          Clear
        </Button>
        {foundResults.length === 0 ? (
          <Button
            disabled={!findText.trim()}
            onClick={searchText}
            buttonType="solid"
            loading={isLoading}
          >
            Find
          </Button>
        ) : (
          <Button
            disabled={!replaceText.trim()}
            onClick={replaceHandler}
            buttonType="solid"
            loading={isLoading}
          >
            Replace
          </Button>
        )}
      </ButtonContainer>
    );
  }
  function getCountComponent(count = 0) {
    return (
      <CountContainer
        justifyContent={count > limit ? "space-between" : "flex-end"}
      >
        {count > limit && (
          <ErrorMsg>
            {`Replace string cannot be more than ${limit} characters.`}
          </ErrorMsg>
        )}
        <Text width="max-content" fontSize={13} marginBottom="10px">
          {count} / {limit}
        </Text>
      </CountContainer>
    );
  }
  return (
    <>
      <Text marginBottom="5px">Find Text/String</Text>
      <TextArea
        value={findText}
        onChange={(e) => {
          setFindText(e.target.value);
        }}
        disabled={disableFindInput}
        margin="0px"
        maxLength={limit}
      />
      {getCountComponent(findText.length)}
      {foundResults.length > 0 && (
        <>
          <Text marginTop="10px" marginBottom="5px">
            Replace With
          </Text>
          <TinyEditor
            id="replace-editor"
            innerHTML={replaceText}
            onChange={(string) => {
              setReplaceText(string);
            }}
            toolbar="undo redo | bold italic underline | removeformat"
            menubar=""
            plugins={[]}
            paste_preprocess={paste_preprocess_remove_list_tags}
            height={185}
          />
          {getCountComponent(getHTMLContentLength(replaceText))}
          <Text fontSize={13} marginBottom="10px">
            Note: Please note that bulk changes cannot be applied to graphs,
            including the Top 15 Investments Table.
          </Text>
        </>
      )}
      <ButtonsHandler />
      {!!foundResults.length && (
        <FindAndReplaceOccurences
          selectedBulkIdsCount={selectedBulkIds.length}
          foundResults={foundResults}
          findText={findText}
          getSelectedOccurrences={(result) => {
            setSelectedOccurences(result);
          }}
          isAllSelected={isAllSelected}
          setIsAllSelected={(allSelected) => {
            setIsAllSelected(allSelected);
          }}
        />
      )}
      {amendmentConfirmationModal && (
        <ConfirmationModal
          isOpen={amendmentConfirmationModal}
          onClose={() => setAmendmentConfirmationModal(false)}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default FindAndReplace;
