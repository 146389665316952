import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledH1 = styled.h1`
  font-size: ${spacingIncrement(50)};
  font-weight: 600;
  color: #303245;
`;
