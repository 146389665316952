import { Sizes } from "./types";
import { ToastContent, ToastOptions, toast } from "react-toastify";
import {
  Approval,
  ApprovalUser,
  DocumentActionEnum,
  DocumentActionPermissions,
  DocumentStatusEnum,
  DocumentUserEnum,
  KeyOfDocumentStatus,
} from "../types/document-details";
import { TUserDetails } from "../types/user-details";
import { Rule } from "../types/document-question";
import { BulkSelected } from "../types/listing-table";
import moment from "moment";
import { FundList } from "../types/fund";

export enum HeaderTabs {
  listing = "listing",
  archived = "archived",
  notifications = "notifications",
  help = "help",
  languageTranslation = "languageTranslation",
}

export const ONE_REM_PX = 16;

export const ALLOWED_EXCEL_LANGUAGES = ["English", "German", "French"];

export const spacingIncrement = (pixels: number): string => {
  const value = pixels / ONE_REM_PX;
  return `${value}rem`;
};

export const pixelSizes = {
  largeDesktop: `${Sizes.largeDesktop}px`,
  desktop: `${Sizes.desktop}px`,
  tablet: `${Sizes.tablet}px`,
  phone: `${Sizes.phone}px`,
};

export const removeRepeatedValues = (arr: string[]): string[] => {
  return Array.from(new Set<string>(arr));
};

export const validStatusUpdates = (
  status: string
): { value: string; label: string }[] => {
  switch (status) {
    case DocumentStatusEnum.draft:
      return [
        {
          value: "awaitingApproval",
          label: DocumentStatusEnum.awaiting_approval,
        },
      ];
    case DocumentStatusEnum.approved:
      return [
        {
          value: "filed",
          label: DocumentStatusEnum.filed,
        },
        {
          value: "awaitingCommentsReview",
          label: DocumentStatusEnum.awaiting_comments_review,
        },
      ];
    case DocumentStatusEnum.awaiting_approval:
      return [
        {
          value: "awaitingCommentsReview",
          label: DocumentStatusEnum.awaiting_comments_review,
        },
        {
          value: "approved",
          label: DocumentStatusEnum.approved,
        },
      ];
    case DocumentStatusEnum.awaiting_comments_review:
      return [
        {
          value: "awaitingApproval",
          label: DocumentStatusEnum.awaiting_approval,
        },
      ];
    default:
      return [];
  }
};

export function formatDate(
  date: Date,
  excludeTime?: boolean,
  format?: "DD/MM/YYYY" | "YYYY-MM-DD"
) {
  const dateObj = new Date(date);
  if (date && dateObj instanceof Date) {
    const year = dateObj.getFullYear();
    const month = `${dateObj.getMonth() + 1 < 10 ? 0 : ""}${
      dateObj.getMonth() + 1
    }`;
    const day = `${dateObj.getDate() < 10 ? 0 : ""}${dateObj.getDate()}`;
    const hour = `${dateObj.getHours() < 10 ? 0 : ""}${dateObj.getHours()}`;
    const minute = `${
      dateObj.getMinutes() < 10 ? 0 : ""
    }${dateObj.getMinutes()}`;
    const second = `${
      dateObj.getSeconds() < 10 ? 0 : ""
    }${dateObj.getSeconds()}`;
    switch (format) {
      case "DD/MM/YYYY":
        return excludeTime
          ? `${day}/${month}/${year}`
          : `${day}/${month}/${year} ${hour}:${minute}:${second}`;
      case "YYYY-MM-DD":
      default:
        return excludeTime
          ? `${year}-${month}-${day}`
          : `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  } else {
    return "";
  }
}

export const notifySuccess = (
  message: ToastContent | string,
  style?: React.CSSProperties
) => {
  toast.success(message, {
    pauseOnHover: true,
    style: {
      ...style,
      width: style?.width || "max-content",
      right: style?.right || "none",
    },
  });
};

export const notifyError = (message: ToastContent, config?: ToastOptions) => {
  toast.error(message, config);
};

export const userHasPermission = (
  details: {
    userType: DocumentUserEnum;
    docStatus: KeyOfDocumentStatus;
    docAction: DocumentActionEnum;
  },
  userPermission: DocumentActionPermissions
) => {
  return userPermission?.[details.userType]?.[details.docStatus]?.[
    details.docAction
  ];
};

export const getDocumentUserPermission = (
  approvalArr: Approval[] | undefined,
  user: TUserDetails | undefined
): DocumentUserEnum | undefined => {
  if (user?.userType === "admin") {
    return DocumentUserEnum.client_admin;
  }
  if (approvalArr && approvalArr.length) {
    const hasApprover = Boolean(
      (
        approvalArr[0].approvalGroup
          .map((approvalGroup) => {
            const isApprovalUser = approvalGroup.approvalUsers.find(
              (approvalUser) => {
                if (`${approvalUser.value}` === `${user?.userId}`) {
                  return approvalUser.label;
                }
              }
            );
            return isApprovalUser;
          })
          .filter((isApprovalUser) => !!isApprovalUser) as ApprovalUser[]
      ).length
    );
    return hasApprover ? DocumentUserEnum.approver : DocumentUserEnum.editor;
  } else {
    return DocumentUserEnum.editor;
  }
};

export const filterAllLockedDocuments = (selectedBulkIds: BulkSelected[]) =>
  selectedBulkIds.filter((document) => {
    return document?.lockStatus === true;
  });

export const staticContentTypes = [
  {
    id: "staticText",
    name: "Static Text",
  },
  {
    id: "questionAnswerOption",
    name: "Question Answer Option",
  },
  {
    id: "greyBox",
    name: "Grey Box",
  },
  {
    id: "infoTooltip",
    name: "Info Tooltip",
  },
  {
    id: "headingText",
    name: "Heading Text",
  },
];

export const commonContentTypes = [
  {
    id: "countryList",
    name: "Country List",
  },
  {
    id: "commonLinks",
    name: "Links",
  },
  {
    id: "assetAllocationBlocks",
    name: "Asset Allocation Blocks",
  },
];

export const countryList = [
  "United States",
  "Canada",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia (Hrvatska)",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecudaor",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Islas Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France, Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of Iran)",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (Democratic People's Republic of Korea)",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, (Republic of Moldova)",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfork Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Sudan",
  "Suriname",
  "Svalbarn and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States minor outlying islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State",
  "Venezuela",
  "Vietnam",
  "British Virgin Islands",
  "United States Virgin Islands",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zaire",
  "Zambia",
  "Zimbabwe",
];

export const HEADER_TABS_LIST = [
  {
    name: "Document Listing",
    key: HeaderTabs.listing,
  },
  {
    name: "Archived Documents",
    key: HeaderTabs.archived,
  },
  // {
  //   name: "Language Translation",
  //   key: HeaderTabs.languageTranslation,
  //   adminAccess: true,
  // },
  {
    name: "Help",
    key: HeaderTabs.help,
  },
];

export const BASE_TRANSLATION_LANGUAGE = "english";

/*
  Returns only error mesage if rule fails, Do not return the null or any nullable 
 */

// This function checks if a string consists only of whitespace characters in a html string
export function hasOnlySpaces(htmlString: string) {
  // Create a temporary element to parse the HTML string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Extract the text content from the element
  const textContent = tempElement.textContent || tempElement.innerText;

  // chech if the tempElement has image tag if yes than return false
  if (tempElement.getElementsByTagName("img")?.length) return false;

  // Remove all spaces from the text content
  const stringWithoutSpaces = textContent.replace(/\s/g, "") || "";

  // Check if the resulting string is empty or consists only of whitespace characters
  return stringWithoutSpaces.length === 0;
}
export function getHTMLContentLength(htmlString: string) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  const textContent = tempElement.textContent || tempElement.innerText;
  return textContent.length;
}
export const validateField = (
  formData: { [key: string]: string | any },
  questionId: string,
  rules: Rule[]
): string | null | undefined => {
  for (const rule of rules) {
    switch (rule.type) {
      case "required":
        if (
          !formData[questionId] ||
          hasOnlySpaces(formData[questionId].toString())
        ) {
          return rule.errorMessage;
        }
        break;
      case "greaterThan":
        if (rule.value != null || rule.value != undefined) {
          if (
            !formData[questionId] ||
            !(
              parseInt(formData[questionId] as string) >
              (typeof rule?.value === "string"
                ? parseInt(rule.value)
                : rule.value)
            )
          ) {
            return rule.errorMessage;
          }
        }
        break;
      case "atLeastOne":
        if (!rule.fields?.filter((questionId) => formData[questionId]).length) {
          return rule.errorMessage;
        }
        break;
      case "onlyOne":
        if (
          rule.fields?.filter((questionId) => formData[questionId])?.length !==
          1
        ) {
          return rule.errorMessage;
        }
        break;
      case "hasValue":
        // boolean false value will invalid this rule
        if (
          rule.validations?.length &&
          ((typeof formData[questionId] === "boolean" &&
            formData[questionId]) ||
            (typeof formData[questionId] === "string" &&
              formData[questionId] === "Yes"))
        ) {
          const validateRes = validateField(
            formData,
            questionId,
            rule.validations
          );
          if (validateRes) {
            return validateRes;
          }
        }
        break;
      case "dependancy":
        // Return the error message if rule fails
        if (rule.dependantFieldId) {
          const value =
            formData[rule.dependantFieldId] === "No"
              ? false
              : formData[rule.dependantFieldId];
          if (value && rule.validations) {
            const validateRes = validateField(
              formData,
              questionId,
              rule.validations
            );
            if (validateRes) {
              return validateRes;
            }
          }
        }
        break;
      case "regex":
        if (rule.regex) {
          const pattern = new RegExp(rule.regex);
          if (!pattern.test(formData[questionId] as string)) {
            return rule.errorMessage;
          }
        }
        break;
      case "typeof":
        if (rule.typeof) {
          if (typeof formData[questionId] !== rule.typeof) {
            return rule.errorMessage;
          }
        }
        break;
      case "tableInput":
        if (formData[questionId].length) {
          for (const row of formData[questionId]) {
            for (const key in row) {
              if (!row[key] || row[key].toString().trim() === "") {
                return rule.errorMessage;
              }
            }
          }
        }
        break;
      case "greaterThanHundred":
        if (rule.fields) {
          let total = 0;
          rule.fields.map((id: string) => {
            const value = formData[id];
            value && !isNaN(value) ? (total += Number(value)) : (total += 0);
          });
          if (total !== 100) {
            return rule.errorMessage;
          }
        }
        break;

      default:
        break;
    }
  }
};

export const getExcelExportFileName = (selectedFund: FundList) =>
  `${selectedFund?.client_name}_${selectedFund?.name}_SFDR_Documents_${moment()
    .utc()
    .format("YYYYMMDDhhmmss")}.zip`;
