import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import Collapsible from "react-collapsible";
import { usePopperTooltip } from "react-popper-tooltip";
import { Questions } from ".";
import Button from "../../../components/Button";
import Image from "../../../components/Image";
import { CommentResponse, CommentType } from "../../../types/comment";
import { DocumentUserEnum } from "../../../types/document-details";
import { spacingIncrement } from "../../../utils/common";
import CommentOrText from "./CommentOrText";
import {
  ButtonWrapper,
  CollapsibleItem,
  CollapsibleItemContent,
  CommentBut,
  CommentButtonContainer,
  CommentFooter,
  CommentInput,
  CommentMoreButton,
  ContentContainer,
  MoreOptionsItem,
  MoreOptionsWrapper,
} from "./styles";

const QuestionLevelCommentContainer: React.FC<{
  onAdd?: (
    text: string,
    qId: string,
    updateStatusToAwaitingComments: boolean
  ) => void;
  question: Questions;
  selectedQuestionId?: string;
  comments?: CommentResponse;
  addLoading?: boolean;
  userType?: DocumentUserEnum;
  maxCommentLen?: number;
  updateComments?: () => void;
}> = ({
  onAdd,
  question,
  selectedQuestionId,
  addLoading,
  comments,
  userType,
  maxCommentLen = 240,
  updateComments,
}) => {
  const [comment, setComment] = useState("");
  const [highLight, setHighLight] = useState(false);
  const commentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const inputRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>;
  const reset = () => {
    setComment("");
  };
  let timeOut1: NodeJS.Timeout;
  let timeOut2: NodeJS.Timeout;
  useEffect(() => {
    if (commentRef?.current && commentRef.current !== null) {
      if (selectedQuestionId == question.id) {
        timeOut1 = setTimeout(() => {
          setHighLight(true);
          commentRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }, 300);
        timeOut2 = setTimeout(() => {
          setHighLight(false);
          inputRef.current.focus();
        }, 1200);
      }
    }
    () => {
      setHighLight(false);
      clearTimeout(timeOut1);
      clearTimeout(timeOut2);
    };
  }, [selectedQuestionId]);
  const {
    tooltipRef,
    setTooltipRef,
    setTriggerRef,
    getArrowProps,
    getTooltipProps,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    interactive: true,
    placement: "bottom-start",
  });

  return (
    <ContentContainer key={question.id} ref={commentRef} highlight={highLight}>
      <CollapsibleItem
        style={{
          cursor: "pointer",
        }}
        triggerStyle={{
          display: "flex",
          cursor: "pointer",
          padding: spacingIncrement(16),
        }}
        trigger={question.label}
        transitionTime={250}
        open={selectedQuestionId === question.id}
      >
        <CollapsibleItemContent>
          <CommentInput
            ref={inputRef}
            placeholder="Comment here"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            maxLength={maxCommentLen}
          />
          <CommentFooter>
            {maxCommentLen - comment.length} characters remaining
          </CommentFooter>
          <ButtonWrapper>
            <Button onClick={reset} disabled={addLoading}>
              Reset
            </Button>
            <CommentButtonContainer disabled={!comment || addLoading}>
              <CommentBut
                onClick={() => {
                  onAdd?.(comment, question.id, false);
                  reset();
                }}
                disabled={!comment || addLoading}
              >
                Comment
              </CommentBut>
              {userType === DocumentUserEnum.approver && (
                <CommentMoreButton
                  disabled={!comment || addLoading}
                  ref={setTriggerRef}
                >
                  <Image name="downArrow" color="white" />
                </CommentMoreButton>
              )}
            </CommentButtonContainer>
            {visible && !(!comment || addLoading) && (
              <MoreOptionsWrapper
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: "tooltip-container",
                })}
              >
                <div
                  {...getArrowProps({
                    className: "tooltip-arrow",
                  })}
                />
                <MoreOptionsItem
                  onClick={() => {
                    onAdd?.(comment, question.id, true);
                    reset();
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    tooltipRef!.style.display = "none";
                  }}
                >
                  Save & Send back to Editor
                </MoreOptionsItem>
              </MoreOptionsWrapper>
            )}
          </ButtonWrapper>
          {comments?.questionLevel &&
            comments?.questionLevel[question.id]?.map((comment, index) => (
              <CommentOrText
                key={index.toString()}
                comment={comment}
                commentType={CommentType.questionLevel}
                documentId={comments.documentId}
                questionId={question.id}
                updateCommentsList={updateComments}
              />
            ))}
        </CollapsibleItemContent>
      </CollapsibleItem>
    </ContentContainer>
  );
};

export default QuestionLevelCommentContainer;
