import Keycloak from "keycloak-js";
import keycloakConfig from "./keycloakConfig";
const kc = new Keycloak(keycloakConfig);
const initKeyCloak = (onAuthenticatedCb: any) => {
  kc.init({
    onLoad: "check-sso",
    silentCheckSsoRedirectUri:
      window.location.origin + "/silent-check-sso.html",
  })
    .then(() => {
      onAuthenticatedCb();
    })
    .catch((err: any) => {
      console.error(err);
      if (err.error === "access_denied") {
        doLogin();
      }
    });
};
const doLogin = kc.login;
const doLogout: () => void = kc.logout;
const getToken = () => kc.token;
const isLoggedIn = () => !!kc.token;
const updateToken = kc.updateToken;
const KeycloakService = {
  initKeyCloak,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  updateToken,
};
export default KeycloakService;
