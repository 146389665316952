import React from "react";
import { ImageProps } from "../types";

const BulkUrlLink: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#clip0_3_1168)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 1.5C7.99187 1.5 1.5 7.99187 1.5 16C1.5 24.0081 7.99187 30.5 16 30.5C24.0081 30.5 30.5 24.0081 30.5 16C30.5 7.99187 24.0081 1.5 16 1.5ZM0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
          fill="#ADB5BD"
        />
        <path
          d="M16.6725 9.41421L14.8318 11.2549L15.6989 12.122L17.5396 10.2813C18.8383 8.98263 20.8304 8.86393 21.984 10.0154C23.1376 11.1669 23.0066 13.1611 21.7181 14.4598L19.8774 16.3005L20.7445 17.1676L22.5852 15.327C24.3625 13.5496 24.4811 10.7784 22.8511 9.14807C21.2212 7.5181 18.4498 7.63881 16.6726 9.41414L16.6725 9.41421Z"
          fill="#ADB5BD"
        />
        <path
          d="M15.4512 20.7246C14.839 21.3485 14.0167 21.7224 13.1441 21.7738C12.3519 21.839 11.5711 21.5525 11.009 20.9904C10.4468 20.4283 10.1604 19.6475 10.2256 18.8553C10.2769 17.9827 10.6509 17.1604 11.2748 16.5482L13.1154 14.7075L12.2483 13.8404L10.4077 15.6811V15.6809C9.56848 16.508 9.06702 17.6176 9.00055 18.7939V19.0209C8.98214 20.1219 9.42713 21.1797 10.2268 21.9367C11.0264 22.6936 12.1072 23.0798 13.2055 23.001C14.3818 22.9345 15.4915 22.4329 16.3185 21.5939L18.1591 19.7532L17.292 18.8861L15.4512 20.7246Z"
          fill="#ADB5BD"
        />
        <path
          d="M12.9894 18.1423L19.1329 11.9988L20.0006 12.8665L13.8571 19.01L12.9894 18.1423Z"
          fill="#ADB5BD"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1168">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BulkUrlLink;
