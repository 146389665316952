import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../../components/Button";
import Image from "../../../components/Image";
import Modal from "../../../components/Modal";
import { fetchData } from "../../../services/apis";
import Context from "../../../store/context";
import {
  Approval,
  ApprovalGroup,
  DocumentRecord,
  DocumentStatusEnum,
  DocumentUserEnum,
} from "../../../types/document-details";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { spacingIncrement } from "../../../utils/common";
import {
  ApprovalGroupItem,
  ApprovalGroupItemWrapper,
  ApprovalStatusTag,
  ApproverRow,
  ApproverUsersWrapper,
  CheckboxWrapper,
  ConfirmationCheckboxWrapper,
  Container,
  ItemName,
  ItemSubDate,
  ItemSubName,
  TitleLeftWrapper,
  TitleRightWrapper,
  TitleWrapper,
  UserGroupItem,
  UserGroupTitle,
} from "./styles";
import moment from "moment";
import { UserType } from "../../../types/user-details";
import Checkbox from "../../../components/Checkbox";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  approvalGroup: Approval[];
  isApproved?: boolean;
  onApprove?: (selectedApprovalGroups: ApprovalGroup[]) => void;
  document: DocumentRecord;
  userType?: DocumentUserEnum;
};

type StatusTag = "Approved" | "Awaiting Approval";

interface ApprovedDetails {
  clientId: string;
  documentId: string;
  groupId: string;
  updatedAt: string;
  approvalGroupTitle: string;
  approvalLevel: string;
  approvalUsers: {
    value: number;
    label: string;
  }[];
  approvedAt: string;
  deletedAt: string | null;
}

const SingleItemWrapper: React.FC<{
  item: ApprovalGroup;
  statusTag: StatusTag;
  onCheckboxChange: (item: ApprovalGroup) => void;
  disabled: boolean;
  approvedDetails: ApprovedDetails | null;
  index: number;
  checked?: boolean;
}> = ({
  item,
  statusTag,
  onCheckboxChange,
  disabled,
  approvedDetails,
  index,
  checked,
}) => {
  const [isOpened, setOpened] = useState(false);
  const triggerIsOpened = () => {
    setOpened(!isOpened);
  };
  return (
    <ApproverRow id={`approval-group-row-${index}`}>
      <CheckboxWrapper>
        <Checkbox
          id={`approval-group-row-checkbox`}
          onChange={() => onCheckboxChange(item)}
          isDisabled={disabled}
          className="checkInput"
          checked={checked}
          label=" "
        />
      </CheckboxWrapper>
      <ApprovalGroupItemWrapper>
        <ApprovalGroupItem
          trigger={
            <TitleWrapper>
              <TitleLeftWrapper>
                <ItemName>{item.approvalGroupTitle}</ItemName>
                {approvedDetails ? (
                  <>
                    <ItemSubName>
                      Last approved: {approvedDetails?.approvalUsers[0].label}{" "}
                      <ItemSubDate>
                        {moment(approvedDetails?.approvedAt).format(
                          "MMM DD, YYYY h:mma"
                        )}
                      </ItemSubDate>
                    </ItemSubName>
                    <ItemSubName></ItemSubName>
                  </>
                ) : (
                  <ItemSubName>Last approved: none</ItemSubName>
                )}
              </TitleLeftWrapper>
              <TitleRightWrapper>
                <ApprovalStatusTag isApproved={statusTag === "Approved"}>
                  {statusTag}
                </ApprovalStatusTag>
                <Image name={isOpened ? "arrowBottom" : "arrowRight"} />
              </TitleRightWrapper>
            </TitleWrapper>
          }
          transitionTime={250}
          style={{
            cursor: "pointer",
          }}
          triggerStyle={{
            display: "flex",
            cursor: "pointer",
            padding: spacingIncrement(16),
          }}
          onClose={triggerIsOpened}
          onOpen={triggerIsOpened}
        >
          <ApproverUsersWrapper>
            <UserGroupTitle>Approver Group Users</UserGroupTitle>
            {item.approvalUsers.map((user, index) => (
              <UserGroupItem key={index.toString()}>{user.label}</UserGroupItem>
            ))}
          </ApproverUsersWrapper>
        </ApprovalGroupItem>
      </ApprovalGroupItemWrapper>
    </ApproverRow>
  );
};

const ApprovalModal: React.FC<Props> = ({
  isOpen,
  onClose,
  approvalGroup,
  isApproved,
  onApprove,
  document,
  userType,
}) => {
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [activeGroupId, setActiveGroupId] = useState<string[]>([]);
  const [approvalDetails, setApprovalDetails] = useState<ApprovedDetails[]>([]);
  const [selectedApprovalGroups, setSelectedApprovalGroups] = useState<
    ApprovalGroup[]
  >([]);
  const [activeLevel, setActiveLevel] = useState<string>();
  const approvalGroupData: Approval | undefined =
    approvalGroup?.[0] || undefined;
  const {
    globalState: { userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);
  useEffect(() => {
    fetchClientApprovalDetails();
  }, []);
  useEffect(() => {
    getCurrentActiveLevel();
  }, [approvalDetails, approvalGroupData]);
  // const statusTag: StatusTag = useMemo(() => {
  //   return isApproved ? "Approved" : "Awaiting Approval";
  // }, [isApproved]);
  const showApprovalButton = useMemo(() => {
    return (
      document?.status === DocumentStatusEnum.awaiting_approval ||
      document?.status === DocumentStatusEnum.awaiting_comments_review ||
      (document?.status === DocumentStatusEnum.edited_by_approver &&
        userType === DocumentUserEnum.approver)
    );
  }, [document]);
  const isUserAllowed = (item: ApprovalGroup) => {
    if (userDetails?.userType === UserType.admin) {
      return true;
    }
    return !!item.approvalUsers.find(
      (user) => user.value === userDetails?.userId
    );
  };

  const canUserApproveDocument = useMemo(() => {
    const approvalGroup = selectedApprovalGroups;
    if (approvalGroup?.length) {
      approvalGroup.forEach((group) => {
        if (isUserAllowed(group)) {
          return false;
        }
      });
      return true;
    }
    return false;
  }, [selectedApprovalGroups]);

  const fetchClientApprovalDetails = async () => {
    try {
      const response = await fetchData({
        endPoint: "getClientApprovalDetails",
        queryParam: {
          clientId: document.clientId,
          documentId: document._id,
        },
      });
      if (response.data.data) {
        setApprovalDetails(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentActiveLevel = () => {
    const approverGroups = approvalGroupData?.approvalGroup || [];
    const activeLevels: ApprovalGroup[] = approverGroups.filter((group) => {
      if (approvalDetails.length) {
        return !approvalDetails.some((detail) => {
          return detail.groupId === group.groupId;
        });
      } else {
        return group;
      }
    });
    activeLevels.sort((a, b) => {
      return Number(a.approvalLevel) - Number(b.approvalLevel);
    });
    setActiveLevel(activeLevels[0]?.approvalLevel);
    const activeGroupIds = activeLevels
      .filter((activeGroup) => {
        if (activeGroup.approvalLevel === activeLevels[0].approvalLevel) {
          return activeGroup;
        }
      })
      .map((group) => group.groupId);
    setActiveGroupId(activeGroupIds);
  };

  const selectedApprovalGroupHandler = (item: ApprovalGroup) => {
    const found = selectedApprovalGroups.find(
      (selectedGroup) => selectedGroup.groupId === item.groupId
    );
    if (!found) {
      setSelectedApprovalGroups([...selectedApprovalGroups, item]);
    } else {
      setSelectedApprovalGroups(
        selectedApprovalGroups.filter(
          (selectedGroup) => selectedGroup.groupId !== item.groupId
        )
      );
    }
  };

  const getStatusTag = (group: ApprovalGroup) => {
    if (isApproved) {
      return "Approved";
    } else {
      const alreadyApproved = approvalDetails.find((approvedgroup) => {
        return approvedgroup.groupId === group.groupId;
      });
      if (alreadyApproved) {
        return "Approved";
      } else {
        return "Awaiting Approval";
      }
    }
  };

  useEffect(() => {
    if (!selectedApprovalGroups.length) {
      setConfirmation(false);
    }
  }, [selectedApprovalGroups]);

  const customStyles = {
    content: {
      maxHeight: "75%",
      width: "40.625rem",
      inset: "40% auto auto 50%",
      boxShadow: "rgb(0 0 0 / 20%) 0px 1px 3px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
    },
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Approval Status"
        width={650}
        style={customStyles}
      >
        <Container>
          {approvalGroupData &&
            approvalGroupData.approvalGroup.map((item, index) => (
              <>
                <SingleItemWrapper
                  item={item}
                  index={index + 1}
                  statusTag={getStatusTag(item)}
                  key={item.groupId}
                  onCheckboxChange={selectedApprovalGroupHandler}
                  disabled={
                    !activeGroupId.includes(item.groupId) ||
                    activeLevel !== item.approvalLevel ||
                    !isUserAllowed(item)
                  }
                  approvedDetails={
                    approvalDetails.find(
                      (approvedGroup) => item.groupId === approvedGroup.groupId
                    ) || null
                  }
                  checked={selectedApprovalGroups.includes(item)}
                />
              </>
            ))}
          <ConfirmationCheckboxWrapper>
            <Checkbox
              onChange={() => setConfirmation(!confirmation)}
              checked={confirmation}
              isDisabled={!selectedApprovalGroups?.length}
              label="Check, to confirm that you want to approve this document"
              id="approvalConfirmationCheckbox"
            />
          </ConfirmationCheckboxWrapper>
          <Button
            buttonType="solid"
            onClick={() => {
              onApprove?.(selectedApprovalGroups);
              onClose?.();
            }}
            disabled={
              !showApprovalButton || !canUserApproveDocument || !confirmation
            }
          >
            Approve
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default ApprovalModal;
