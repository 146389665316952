import { BulkSelected } from "../../../../../types/listing-table";
import { List, ListContainer } from "./styles";

export const ListComponent: React.FC<{
  docs: (BulkSelected & { _id?: string })[];
}> = ({ docs }) => {
  return (
    <ListContainer>
      <List>
        {docs.length > 0 &&
          docs?.map((doc) => {
            return (
              <li key={`${doc?._id || doc.id}`}>
                {doc.fundName}/{doc.subFundName}/{doc.template}/{doc.article}/
                {doc.language}
              </li>
            );
          })}
      </List>
    </ListContainer>
  );
};

export default ListComponent;
