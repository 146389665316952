if (
  !process.env.REACT_APP_KEYCLOAK_URL ||
  !process.env.REACT_APP_KEYCLOAK_REALM ||
  !process.env.REACT_APP_KEYCLOAK_CLIENT_ID
) {
  throw new Error("Keycloak config is missing");
}
const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};
export default keycloakConfig;
