import React from "react";
import { ImageProps } from "../types";

const Lock: React.FC<ImageProps> = ({ color = "#595B6B", ...otherProps }) => {
  return (
    <svg
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M4.12066 12.362V6.92447C4.12066 3.10021 7.50201 0 11.675 0C15.8472 0 19.2293 3.10315 19.2293 6.92447V12.362H20.7619C22.1913 12.362 23.35 13.5941 23.35 15.1069V28.847C23.35 30.363 22.1922 31.5918 20.7619 31.5918H2.58807C1.15873 31.5918 0 30.3598 0 28.847V15.1069C0 13.5909 1.15779 12.362 2.58807 12.362H4.12066ZM2.74695 15.109V28.8449H20.6035V15.109H2.74695ZM16.4829 12.362V6.92447C16.4829 4.67512 14.38 2.74701 11.6754 2.74701C8.96885 2.74701 6.86784 4.67306 6.86784 6.92447V12.362H16.4829ZM10.3019 20.3072C10.3019 19.5489 10.9114 18.934 11.6754 18.934C12.434 18.934 13.0488 19.5434 13.0488 20.3072V24.4284C13.0488 25.187 12.4393 25.8016 11.6754 25.8016C10.9167 25.8016 10.3019 25.1922 10.3019 24.4284V20.3072Z"
        fill={color}
      />
    </svg>
  );
};

export default Lock;
