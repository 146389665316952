import React from "react";
import { ImageProps } from "../types";

const Save: React.FC<ImageProps> = ({ color = "#0ABAEE", ...otherProps }) => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5001 29.2044C14.2369 29.2044 14.0236 28.9744 14.0236 28.6907V14.3098C14.0236 14.026 14.2369 13.7961 14.5001 13.7961H15.9296V17.3914C15.9296 18.2423 16.5694 18.9323 17.359 18.9323H23.0766C23.866 18.9323 24.506 18.2423 24.506 17.3914V13.7961H25.0643C25.1906 13.7961 25.3118 13.8502 25.401 13.9466L28.1783 16.94C28.2675 17.0363 28.3178 17.167 28.3178 17.3032V28.6909C28.3178 28.9747 28.1045 29.2046 27.8413 29.2046H26.4118V24.0683C26.4118 23.2174 25.7719 22.5275 24.9824 22.5275H17.3591C16.5696 22.5275 15.9297 23.2174 15.9297 24.0683V29.2046L14.5001 29.2044ZM16.8824 29.2044V24.0681C16.8824 23.7845 17.0957 23.5546 17.359 23.5546H24.9822C25.2455 23.5546 25.4588 23.7845 25.4588 24.0681V29.2044H16.8824ZM23.5529 13.7959V17.3913C23.5529 17.6748 23.3396 17.9048 23.0765 17.9048H21.6471V13.7959H23.5529ZM20.6941 13.7959V17.9048H17.3589C17.0957 17.9048 16.8824 17.6748 16.8824 17.3913V13.7959H20.6941ZM13.0708 28.6906C13.0708 29.5417 13.7107 30.2314 14.5002 30.2314H27.8412C28.6307 30.2314 29.2706 29.5417 29.2706 28.6906V17.3029C29.2706 16.8943 29.1201 16.5023 28.852 16.2134L26.0748 13.2198C25.8068 12.9309 25.4432 12.7686 25.0642 12.7686H14.5C13.7104 12.7686 13.0706 13.4583 13.0706 14.3094L13.0708 28.6906Z"
        fill={color}
      />
      <path
        d="M41.1569 21.5C41.1569 32.8406 32.1489 42 21.0784 42C10.008 42 1 32.8406 1 21.5C1 10.1594 10.008 1 21.0784 1C32.1489 1 41.1569 10.1594 41.1569 21.5Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default Save;
