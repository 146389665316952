import { useEffect, useState } from "react";
import {
  BulkFindResponses,
  HighlightedResult,
  SelectedDocument,
} from "../../../../types/bulkFindAndReplace";
import parse from "html-react-parser";
import { findOccurrencesWithPartialString } from "../../../../utils/findAndReplace";

type Props = {
  selectedBulkIdsCount: number;
  foundResults: BulkFindResponses;
  findText: string;
  isAllSelected: boolean;
  getSelectedOccurrences?: (result: SelectedDocument[]) => void;
  setIsAllSelected: (isAllSelected: boolean) => void;
};

const FindAndReplaceOccurences: React.FC<Props> = ({
  selectedBulkIdsCount,
  foundResults,
  findText,
  isAllSelected,
  getSelectedOccurrences,
  setIsAllSelected,
}) => {
  const [highlightedResults, setHighlightedResults] =
    useState<HighlightedResult[]>();
  const [selectedOccurences, setSelectedOccurences] = useState<
    SelectedDocument[]
  >([]);
  const [occurencesCount, setOccurencesCount] = useState<number>(0);

  useEffect(() => {
    const highlightedResults = highlightTheResults(foundResults);
    const occurrencesCount = highlightedResults.reduce((acc, result) => {
      const occurrenceCount = result.occurrences?.length || 0;
      return acc + occurrenceCount;
    }, 0);
    setOccurencesCount(occurrencesCount);
    setHighlightedResults(highlightedResults);
  }, [foundResults]);

  function highlightTheResults(foundResults: BulkFindResponses) {
    return foundResults.map((result) => {
      const numCharsAround = 100;
      const stringWithoutImageTags = result.answer.replace(/<img[^>]*>/g, "");
      const highlightedText = findOccurrencesWithPartialString(
        findText,
        stringWithoutImageTags,
        numCharsAround
      );
      return {
        ...result,
        occurrences: highlightedText.length ? highlightedText : null,
      };
    });
  }
  const handleCheckboxChecked = (
    documentId: string,
    questionId: string,
    occurence: number
  ): void => {
    // finding if the occurence is already present
    const isSelectedOccurencePresent = selectedOccurences.find(
      (doc) =>
        doc.docId === documentId &&
        doc.question === questionId &&
        doc.occurence === occurence
    );
    // remove the occurence if already present else add it
    if (isSelectedOccurencePresent) {
      const selectedOccurencesUpdate = selectedOccurences.filter(
        (doc) =>
          doc.docId !== documentId ||
          doc.question !== questionId ||
          doc.occurence !== occurence
      );
      setIsAllSelected(false);
      setSelectedOccurences(selectedOccurencesUpdate);
    } else {
      if (selectedOccurences.length + 1 === occurencesCount) {
        setIsAllSelected(true);
      }
      setSelectedOccurences([
        ...selectedOccurences,
        {
          docId: documentId,
          question: questionId,
          occurence: occurence,
        },
      ]);
    }
  };
  const isOccurenceSelected = (
    documentId: string,
    questionId: string,
    occurence: number
  ): boolean => {
    const isSelectedOccurencePresent = selectedOccurences.find(
      (doc) =>
        doc.docId === documentId &&
        doc.question === questionId &&
        doc.occurence === occurence
    );
    return !!isSelectedOccurencePresent;
  };
  const handleSelectAll = () => {
    setIsAllSelected(!isAllSelected);
    if (!isAllSelected) {
      const selectAllOccurrences: SelectedDocument[] = [];
      highlightedResults?.forEach((result) => {
        result.occurrences?.forEach((occurence, index) => {
          selectAllOccurrences.push({
            docId: result.docId,
            question: result.questionId,
            occurence: index + 1,
          });
        });
      });
      setSelectedOccurences(selectAllOccurrences);
    } else {
      setSelectedOccurences([]);
    }
  };
  useEffect(() => {
    if (selectedOccurences) {
      getSelectedOccurrences?.(selectedOccurences);
    }
  }, [selectedOccurences]);
  function NestedOccurences({
    occurences,
    document,
  }: {
    occurences: string[];
    document: HighlightedResult;
  }) {
    return (
      <div className="pl-70 line-loop">
        {occurences.map((occurence, index) => {
          return (
            <div
              className="d-flex align-items-start mb-3 loop-arrow-in"
              key={`${document.docId}-${document.questionId}-otherOccurence-${index}`}
            >
              <div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`check-${document.docId}-${document.questionId}-otherOccurence-${index}`}
                    onChange={() =>
                      handleCheckboxChecked(
                        document.docId,
                        document.questionId,
                        index + 2
                      )
                    }
                    checked={isOccurenceSelected(
                      document.docId,
                      document.questionId,
                      index + 2
                    )}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`check-${document.docId}-${document.questionId}-otherOccurence-${index}`}
                  >
                    <span className="font-22 text-white">.</span>
                  </label>
                </div>
              </div>
              <div className="find-text-list w-100 p-3">
                <div className="mb-2">{parse(occurence)}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  function FirstOccurence({ document }: { document: HighlightedResult }) {
    return (
      <div className="d-flex align-items-start mb-3">
        <div>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`check-${document.docId}-${document.questionId}-1`}
              onChange={() =>
                handleCheckboxChecked(document.docId, document.questionId, 1)
              }
              checked={isOccurenceSelected(
                document.docId,
                document.questionId,
                1
              )}
            />
            <label
              className="custom-control-label"
              htmlFor={`check-${document.docId}-${document.questionId}-1`}
            >
              <span className="font-22 text-white">.</span>
            </label>
          </div>
        </div>
        <div className="find-text-list p-3 w-100">
          <div className="font-weight-700 mb-2">{document.questionText}</div>
          <div className="mb-2">
            {document?.occurrences?.[0] && parse(document?.occurrences[0])}
          </div>
          <div className="font-12 text-grey font-italic">
            {document.fundName}/{document.subFundName}/{document.article}/
            {document.template}
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="result-checkbox">
        <div>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="select-all"
              onChange={() => {
                handleSelectAll();
              }}
              checked={isAllSelected}
            />
            <label
              className="custom-control-label text-white"
              htmlFor="select-all"
            >
              .
            </label>
          </div>
        </div>
        <div>
          <span className="font-22 font-weight-700">Results </span>
          <span className="font-12">
            {`${occurencesCount} results found from ${selectedBulkIdsCount}
              selected documents from all the sub-funds under the same umbrella`}
          </span>
        </div>
      </div>
      <div className="mt-3 pt-1">
        {highlightedResults?.map((result) => {
          if (!result.occurrences?.length) {
            return null;
          }
          return (
            <div className="mb-3" key={`${result.docId}-${result.questionId}`}>
              <FirstOccurence document={result} />
              {result.occurrences?.length && (
                <NestedOccurences
                  occurences={result.occurrences?.slice(1)}
                  document={result}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FindAndReplaceOccurences;
