import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";

export const Conatiner = styled.div`
  display: flex;
`;
export const ModalContentWrapper = styled.div`
  padding: ${spacingIncrement(25)} ${spacingIncrement(25)}
    ${spacingIncrement(18)} ${spacingIncrement(25)};
`;
export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${spacingIncrement(15)};
  row-gap: ${spacingIncrement(25)};
`;
export const RowContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  row-gap: ${spacingIncrement(25)};
  margin-bottom: ${spacingIncrement(25)};
  margin-top: ${spacingIncrement(25)};
`;

export const DisabledContainer = styled.div`
  background: #f6f6f6;
  display: flex;
  align-items: center;
  border: 1px solid #d9dae3;
  box-sizing: border-box;
  border-radius: 5px;
  height: ${spacingIncrement(42)};
  padding-left: ${spacingIncrement(15)};
  margin-top: ${spacingIncrement(8)};
`;

export const NoteContainer = styled.div`
  font-size: ${spacingIncrement(13)};
  font-weight: 400;
  color: #000000;
  padding: 0 ${spacingIncrement(30)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const CustomCheckboxLabel = styled.label`
  font-size: ${spacingIncrement(16)};
  font-weight: 600;
  line-height: ${spacingIncrement(22)};
`;

export const CustomCheckboxSubLabel = styled.span`
  font-size: ${spacingIncrement(12)};
  font-weight: 400;
  line-height: ${spacingIncrement(16)};
  font-style: italic;
  color: #868e96;
`;
