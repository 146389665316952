import React from "react";
import { useDocumentState } from "../../../../hooks/useDocumentState";
import { StyledHeader, StyledTableData } from "./styles";
import { TableNumberInput } from "../TableInput/NumberInput";
import _ from "lodash";

type Props = {
  id: string;
  headers: string[];
  data: string[][];
  disabled: boolean;
  disabledFieldIndexes: number[];
};

const Tabular: React.FC<Props> = ({
  id,
  headers,
  data,
  disabled,
  disabledFieldIndexes,
}) => {
  const { getValue, updateAnswer } = useDocumentState();
  return (
    <table style={{ width: "350px" }}>
      <tr>
        {headers.map((header: string | Element, index: number) => {
          return <StyledHeader key={index}>{header}</StyledHeader>;
        })}
      </tr>
      {data.map((items: string[], index: number) => {
        return (
          <tr key={index}>
            <StyledTableData key={index}>{items[0]}</StyledTableData>
            {_.times(3, (i) => {
              return (
                <StyledTableData>
                  <TableNumberInput
                    id={`${id}-r${index}-c${i}`}
                    width={40}
                    disabled={disabled && disabledFieldIndexes.includes(index)}
                    placeholder="%"
                    maxLimit={100}
                    value={getValue(items[i + 1]) || 0}
                    onChange={(val) => updateAnswer(items[i + 1], val)}
                    step={".01"}
                  />
                </StyledTableData>
              );
            })}
          </tr>
        );
        // })
      })}
    </table>
  );
};

export default Tabular;
