import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const ToastWrapper = styled.div`
  .Toastify__toast-container {
    z-index: 999999;
  }
  .Toastify__toast-container--top-center {
    .Toastify__toast--success {
      background: #35cb93;
      color: #fff;
      font-size: ${spacingIncrement(16)};
      font-weight: bold;
    }
    .Toastify__toast--error {
      background: #f44336;
      color: #fff;
      font-size: ${spacingIncrement(16)};
      font-weight: bold;
    }
  }
`;
