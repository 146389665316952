import React from "react";
import { ImageProps } from "../types";

const Factory: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="145"
      height="145"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="72.5" cy="72.5" r="72.5" fill="#9D9D9C" />
      <path
        d="M25.205 77.0093C25.205 76.7332 25.4288 76.5093 25.705 76.5093H49.8074C49.9429 76.5093 50.0725 76.5643 50.1667 76.6616L71.8887 99.1108C72.0809 99.3095 72.3979 99.3145 72.5962 99.1219L95.559 76.8374C95.7489 76.6531 96.0495 76.6487 96.2447 76.8273L120.837 99.322C120.941 99.4167 121 99.5506 121 99.6909V118.183C121 118.459 120.776 118.683 120.5 118.683H25.705C25.4288 118.683 25.205 118.459 25.205 118.183V77.0093Z"
        fill="#3C3C3B"
      />
      <rect
        x="27.0124"
        y="53.6149"
        width="18.0745"
        height="21.6894"
        fill="#3C3C3B"
      />
      <rect x="24" y="50" width="24.0994" height="3.61491" fill="#3C3C3B" />
      <path
        d="M51.7143 66.2912H69.7888V95.4876L51.7143 76.5093V66.2912Z"
        fill="#3C3C3B"
      />
      <rect
        x="48.7019"
        y="63.2547"
        width="24.0994"
        height="3.03652"
        fill="#3C3C3B"
      />
      <path
        d="M76.4161 60.8447H94.4907V76.5093L76.4161 93.9814V60.8447Z"
        fill="#3C3C3B"
      />
      <rect
        x="73.4037"
        y="57.2298"
        width="24.0994"
        height="3.61491"
        fill="#3C3C3B"
      />
      <path
        d="M59.5955 26.9865H59.101C58.3596 24.5146 56.0853 22.6855 53.3168 22.6855C51.9325 22.6855 50.6473 23.1801 49.6583 23.9708C48.4717 19.9665 44.7145 17 40.3145 17C34.9259 17 30.5752 21.3506 30.5752 26.7393V27.1841C26.818 27.9752 24 31.2875 24 35.2426C24 39.3953 27.0651 42.8559 31.0694 43.3997C32.503 45.7233 35.1235 47.2558 38.0896 47.2558C40.265 47.2558 42.2423 46.4154 43.7255 45.0312C44.8132 45.5751 46.0491 45.8716 47.384 45.8716C48.4224 45.8716 49.4111 45.674 50.3505 45.3278C51.784 46.5144 53.6135 47.2559 55.6405 47.2559C58.8046 47.2559 61.573 45.4761 62.9572 42.8559C65.9237 41.5706 68 38.6535 68 35.2426C67.901 30.6943 64.1932 26.9865 59.5956 26.9865L59.5955 26.9865Z"
        fill="white"
      />
      <path
        d="M95.5955 32.9865H95.101C94.3596 30.5146 92.0853 28.6855 89.3168 28.6855C87.9325 28.6855 86.6473 29.1801 85.6583 29.9708C84.4717 25.9665 80.7145 23 76.3145 23C70.9259 23 66.5752 27.3506 66.5752 32.7393V33.1841C62.818 33.9752 60 37.2875 60 41.2426C60 45.3953 63.0651 48.8559 67.0694 49.3997C68.503 51.7233 71.1235 53.2558 74.0896 53.2558C76.265 53.2558 78.2423 52.4154 79.7255 51.0312C80.8132 51.5751 82.0491 51.8716 83.384 51.8716C84.4224 51.8716 85.4111 51.674 86.3505 51.3278C87.784 52.5144 89.6135 53.2559 91.6405 53.2559C94.8046 53.2559 97.573 51.4761 98.9572 48.8559C101.924 47.5706 104 44.6535 104 41.2426C103.901 36.6943 100.193 32.9865 95.5956 32.9865L95.5955 32.9865Z"
        fill="white"
      />
    </svg>
  );
};

export default Factory;
