import React from "react";
import { ImageProps } from "../types";

const NoMoney: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="67.5" cy="67.5" r="67.5" fill="#9D9D9C" />
      <mask id="path-2-inside-1_106_855" fill="white">
        <rect x="26" y="53.5294" width="84" height="39.5294" rx="0.5" />
      </mask>
      <rect
        x="26"
        y="53.5294"
        width="84"
        height="39.5294"
        rx="0.5"
        stroke="#3C3C3B"
        strokeWidth="4"
        mask="url(#path-2-inside-1_106_855)"
      />
      <path
        d="M26 63.4118V63.4118C30.3312 62.8385 33.6421 59.2655 33.8845 54.9033L33.9059 54.5176"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <path
        d="M34.5351 92.0704L34.3614 90.4432C33.9437 86.5311 30.7747 83.4907 26.8485 83.2352V83.2352"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <path
        d="M101.551 54.5176L101.709 56.1471C102.089 60.0644 105.228 63.1466 109.151 63.454V63.454"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <path
        d="M109.705 83.822V83.822C105.516 84.3649 102.308 87.8061 102.059 92.0236L102.021 92.66"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <line
        x1="31.1177"
        y1="49.2353"
        x2="104.059"
        y2="49.2353"
        stroke="#3C3C3B"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="35.2354"
        y1="45.1176"
        x2="99.9412"
        y2="45.1176"
        stroke="#3C3C3B"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="39.3529"
        y1="41"
        x2="95.8235"
        y2="41"
        stroke="#3C3C3B"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="68"
        cy="73.2941"
        r="13.8235"
        fill="#9D9D9C"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <path
        d="M74.9918 66.6863C72.74 68.9686 69.255 69.4769 66.0765 69.8973C65.5195 69.971 65.2637 70.036 65.2637 70.036C59.9405 71.4315 60.0553 77.6391 60.0553 77.6391C61.8425 74.3463 67.807 74.1211 67.807 74.1211C69.3533 73.9432 71.5839 72.9035 71.5839 72.9035C69.9723 74.5173 68.426 74.6951 68.426 74.6951C63.1942 75.9309 60.3975 78.5792 59.2532 79.9452C58.9427 80.3159 59.2023 80.8257 59.6853 80.8477V80.8477C59.9101 80.8579 60.1148 80.7276 60.2304 80.5345C60.8042 79.5758 61.6692 79.2508 61.6692 79.2508C66.4206 81.6997 70.7264 78.67 70.7264 78.67C75.1516 75.7362 75.6017 69.4686 75.5974 66.9867C75.5969 66.7058 75.1891 66.4862 74.9918 66.6863V66.6863Z"
        fill="white"
      />
      <circle cx="68" cy="68" r="58.5" stroke="#D2D2D2" strokeWidth="9" />
      <line
        x1="23.818"
        y1="105.818"
        x2="105.818"
        y2="23.818"
        stroke="#D2D2D2"
        strokeWidth="9"
      />
    </svg>
  );
};

export default NoMoney;
