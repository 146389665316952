import moment from "moment";
import styled from "styled-components";
import {
  ApprovalDetails,
  ApprovalGroup,
  DocLockStatus,
  DocumentRecord,
  DocumentStatusEnum,
} from "../types/document-details";
import { FilterData } from "../types/fund";
import { FilterField, TableHeader, TableRow } from "../types/listing-table";
import { FlexRow } from "../features/templates/ArticleEightPeriodic/styles";
import Image from "../components/Image";
import { Text } from "../features/templates/CommonComponents/common.styles";
import Tooltip from "../components/Tooltip";
import { Status } from "../components/Status";
import {
  FILTER_SESSION_NAME,
  FILTER_SESSION_NAME_ARCHIVED,
} from "../lib/constants";
import { FilterType, initialFilterState } from "../features/listing";

export function elipses(string: string, maxChar = 20) {
  return `${string.substring(0, maxChar)}${
    string.length > maxChar ? "..." : ""
  }`;
}

const LastUpdatedByWrapper = styled.span`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LastUpdatedBy = styled.div``;

const LastUpdatedTime = styled.div`
  color: #595b6b;
  font-size: 12px;
`;

export const getFilterPrepDetails = (filterData?: FilterField[]) => {
  if (!filterData) {
    return [];
  }
  const filterPrep: FilterData[] = [];
  filterData.forEach((value) => {
    filterPrep.push({
      id: value.id,
      label: value.entityDisplayName,
      name: value.field,
      fieldType: value.filterFieldType,
      placeholder: value.entityDisplayName,
      isMultiSelect: value.isMultiselect,
      listValues: value.values.map((val) => ({ value: val, label: val })),
      selectedValues: [],
    });
  });
  return filterPrep;
};

export const prepareHeadersForTable = (headers?: TableHeader[]) => {
  if (!headers) {
    return [];
  }
  const data: TableHeader[] = [];
  headers.forEach((header) => {
    header.sortOrder && data.push(header);
  });
  return data.sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });
};

export const getApprovalLevelStatus = (document: {
  status: DocumentStatusEnum;
  approvalDetails: ApprovalDetails[];
  approvalGroups: ApprovalGroup[];
}) => {
  if (document.status !== DocumentStatusEnum.awaiting_approval) {
    return document.status;
  }
  return document?.approvalDetails?.length
    ? `${DocumentStatusEnum.awaiting_approval} (${document.approvalDetails.length}/${document.approvalGroups.length})`
    : DocumentStatusEnum.awaiting_approval;
};

const getEntityValue = (
  keyVal: keyof DocumentRecord,
  entities: DocumentRecord
): string => {
  const val = entities[keyVal];
  if (
    keyVal === "updatedBy" ||
    keyVal === "createdBy" ||
    keyVal === "deletedBy" ||
    keyVal === "archivedBy"
  ) {
    return entities[keyVal]?.userName;
  }
  if (keyVal === "language" && val === "English") {
    return "English (default)";
  }
  if (keyVal === "status" && val === DocumentStatusEnum.awaiting_approval) {
    return getApprovalLevelStatus(entities);
  }
  return typeof val === "string" ? val : "";
};

export const getRowsForList = (
  data: DocumentRecord[],
  lockedDocuments: DocLockStatus[],
  archivedList: boolean
): TableRow[] => {
  const rows: TableRow[] = data.map((field) => {
    const lockedDoc = lockedDocuments.find((ld) => ld.documentId === field._id);
    return {
      id: field._id,
      sfdrId: field._id,
      subFundName: field.subFundName,
      lockStatus: lockedDoc ? true : false,
      data: field,
      dataField: [
        {
          cellVal: (
            <FlexRow>
              {lockedDoc && (
                <Tooltip
                  triggerElement={
                    <Image
                      style={{ marginRight: "7px" }}
                      name="lock"
                      color="#0ABAEE"
                      width={14}
                      height={19}
                    />
                  }
                  placement="bottom"
                >
                  <Text whiteSpace="nowrap">
                    Locked by {lockedDoc.userDetails?.userName}
                  </Text>
                </Tooltip>
              )}
              <Text>{getEntityValue("subFundName", field)}</Text>
            </FlexRow>
          ),
        },
        {
          cellVal: <Text>{getEntityValue("template", field)}</Text>,
        },
        {
          cellVal: <Text>{getEntityValue("article", field)}</Text>,
        },
        {
          cellVal: <Text>{getEntityValue("language", field)}</Text>,
        },
        {
          cellVal: (
            <Status
              type={field.status}
              name={getEntityValue("status", field)}
            />
          ),
        },
        {
          cellVal: (
            <LastUpdatedByWrapper>
              <LastUpdatedBy>
                {getEntityValue(
                  archivedList ? "archivedBy" : "updatedBy",
                  field
                )}
              </LastUpdatedBy>
              <LastUpdatedTime>
                {moment(
                  getEntityValue(
                    archivedList ? "archivedAt" : "updatedAt",
                    field
                  )
                ).format("MMM Do, YYYY; h:mma")}
              </LastUpdatedTime>
            </LastUpdatedByWrapper>
          ),
        },
      ],
    };
  });
  return rows;
};

export const getCachedFilter = (archived: boolean): FilterType => {
  const KEY = archived ? FILTER_SESSION_NAME_ARCHIVED : FILTER_SESSION_NAME;
  const isExist = Boolean(
    sessionStorage.getItem(KEY) && JSON.parse(sessionStorage.getItem(KEY) || "")
  );
  if (isExist) {
    return JSON.parse(sessionStorage.getItem(KEY) || "");
  } else {
    return initialFilterState;
  }
};

export const setCachedFilters = (filter: FilterType, archived: boolean) => {
  const KEY = archived ? FILTER_SESSION_NAME_ARCHIVED : FILTER_SESSION_NAME;
  sessionStorage.setItem(KEY, JSON.stringify(filter));
};

export const clearCachedFilters = () => {
  sessionStorage.removeItem(FILTER_SESSION_NAME_ARCHIVED);
  sessionStorage.removeItem(FILTER_SESSION_NAME);
};
