import styled from "styled-components";
import { StyledStatus } from "./styles";
import { DocumentStatusEnum } from "../../types/document-details";

export const Status = ({
  type,
  name,
}: {
  type: keyof typeof DocumentStatusEnum | any;
  name: string;
}) => {
  const getColor = (
    type: keyof typeof DocumentStatusEnum
  ): {
    backgroundColor: string;
    color: string;
  } => {
    switch (type.toLocaleLowerCase().replaceAll(" ", "_")) {
      case "filed":
        return {
          backgroundColor: "#F7EFFF",
          color: "#48117E",
        };
      case "approved":
        return {
          backgroundColor: "#eafff6",
          color: "#18a668",
        };
      case "awaiting_approval":
        return {
          backgroundColor: "#FFFCED",
          color: "#A0870C",
        };
      case "draft":
        return {
          backgroundColor: "#EAF9FF",
          color: "#0070A0",
        };
      case "edited_by_approver":
        return {
          backgroundColor: "#ffeffa",
          color: "#a60770",
        };
      case "traslation_requested":
        return {
          backgroundColor: "#fffced",
          color: "#a0870c",
        };
      case "awaiting_comments_review":
        return {
          backgroundColor: "#FFEFFA",
          color: "#A60770",
        };
      default:
        return {
          backgroundColor: "#eaf9ff",
          color: "#0070a0",
        };
    }
  };
  const value = getColor(type);
  return <StyledStatus {...value}>{name}</StyledStatus>;
};
