import { useContext, useState } from "react";
import { GroupBase, StylesConfig } from "react-select";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Image from "../../../components/Image";
import Modal from "../../../components/Modal";
import Select, { Option } from "../../../components/Select";
import { DropDownContainer } from "../../../components/Select/styles";
import { fetchData } from "../../../services/apis";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { spacingIncrement } from "../../../utils/common";
import {
  ButtonWrapper,
  ContentContainer,
  Label,
  NoteContainer,
  OptionContainer,
} from "./styles";

export type OnSubmitData = {
  selectedLanguages: string[];
  shouldHandleTranslation: boolean;
};

type Props = {
  isOpen: boolean;
  onCloseModal?: () => void;
  loading?: boolean;
  onSubmit?: (data: OnSubmitData) => void;
};

type LanguageData = {
  name: string;
  iso_alpha_2_code: string;
  iso_alpha_code: string;
  id: number;
};

const customStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
  multiValue: (provided) => ({
    ...provided,
    background: "rgba(246, 246, 246, 0.51)",
    border: "1px solid #DEE2E6",
    borderRadius: "2px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#303245",
    fontSize: spacingIncrement(16),
    fontWeight: 400,
    padding: spacingIncrement(2),
  }),
};

const TranslationRequestModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  loading,
  onSubmit,
}) => {
  const {
    globalState: { languages },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [requestZeidlerToTranslate, setRequestZeidlerToTranslate] =
    useState(false);

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  const onProceed = () => {
    if (selectedLanguages.length) {
      setSelectedLanguages([]);
      setRequestZeidlerToTranslate(false);
      onSubmit?.({
        selectedLanguages,
        shouldHandleTranslation: requestZeidlerToTranslate,
      });
    }
  };

  const onClose = () => {
    setSelectedLanguages([]);
    setRequestZeidlerToTranslate(false);
    onCloseModal?.();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Translation Confirmation"
      height={442}
      width={648}
    >
      <ContentContainer>
        <NoteContainer>
          Would you like to translate this document to other languages? If yes,
          choose from below.
        </NoteContainer>
        <Select
          label="Language"
          options={languages}
          isMulti
          closeMenuOnSelect={false}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => {
            setSelectedLanguages(
              e?.map((data: { value: string }) => data.value)
            );
          }}
          styles={customStyles}
          hideSelectedOptions={false}
          components={{
            Option: ({ data, selectOption, isSelected }) => (
              <OptionContainer onClick={() => selectOption(data)}>
                <input type="checkbox" checked={isSelected} />
                <Label>{data.label}</Label>
              </OptionContainer>
            ),
            DropdownIndicator: () => (
              <DropDownContainer>
                <Image name="downArrow" />
              </DropDownContainer>
            ),
          }}
        />
        <Checkbox
          id="request-zeidler-to-translate"
          checked={requestZeidlerToTranslate}
          label="Check, if you want Zeidler Group to arrange Language translation"
          onChange={() =>
            setRequestZeidlerToTranslate(!requestZeidlerToTranslate)
          }
        />
      </ContentContainer>
      <ButtonWrapper>
        <Button
          buttonType="solid"
          onClick={onProceed}
          loading={loading}
          disabled={!selectedLanguages.length}
        >
          {requestZeidlerToTranslate ? "Request Translation" : "Proceed"}
        </Button>
        <Button onClick={onClose} loading={loading}>
          No, Thanks
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default TranslationRequestModal;
