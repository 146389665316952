import React from "react";
import { ImageProps } from "../types";

const Money: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="67.5" cy="67.5" r="67.5" fill="#9D9D9C" />
      <mask id="path-2-inside-1_19_181" fill="white">
        <rect x="16" y="51" width="102" height="48" rx="0.5" />
      </mask>
      <rect
        x="16"
        y="51"
        width="102"
        height="48"
        rx="0.5"
        stroke="#3C3C3B"
        strokeWidth="4"
        mask="url(#path-2-inside-1_19_181)"
      />
      <path
        d="M16 63V63C21.2593 62.3039 25.2797 57.9653 25.574 52.6684L25.6 52.2"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <path
        d="M26.364 97.7998L26.1531 95.8239C25.6459 91.0735 21.7978 87.3816 17.0303 87.0713V87.0713"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <path
        d="M107.741 52.2L107.933 54.1786C108.394 58.9353 112.205 62.6779 116.97 63.0513V63.0513"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <path
        d="M117.642 87.7838V87.7838C112.556 88.4431 108.66 92.6216 108.357 97.7429L108.312 98.5158"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="46"
        x2="111"
        y2="46"
        stroke="#3C3C3B"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="27"
        y1="41"
        x2="106"
        y2="41"
        stroke="#3C3C3B"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="32"
        y1="36"
        x2="101"
        y2="36"
        stroke="#3C3C3B"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="67"
        cy="75"
        r="17"
        fill="#9D9D9C"
        stroke="#3C3C3B"
        strokeWidth="2"
      />
      <path
        d="M74.9909 67.448C72.3571 69.7967 68.7284 70.4383 65.2191 70.8096C64.156 70.9221 63.6774 71.0437 63.6774 71.0437C57.2135 72.7382 57.353 80.276 57.353 80.276C59.5231 76.2776 66.7657 76.0042 66.7657 76.0042C68.6434 75.7882 71.3519 74.5256 71.3519 74.5256C69.395 76.4853 67.5173 76.7013 67.5173 76.7013C61.1644 78.2018 57.7684 81.4176 56.379 83.0764C56.0019 83.5264 56.3171 84.1455 56.9036 84.1722C57.1766 84.1846 57.4252 84.0264 57.5656 83.7919C58.2623 82.6278 59.3126 82.2331 59.3126 82.2331C65.0822 85.2067 70.3106 81.5278 70.3106 81.5278C75.7563 77.9175 76.2445 70.1495 76.2249 67.2221C76.2229 66.9128 75.7015 66.7623 75.4841 66.9822C75.3347 67.1333 75.1687 67.2894 74.9909 67.448Z"
        fill="white"
      />
    </svg>
  );
};

export default Money;
