import React from "react";
import { ImageProps } from "../types";

const Copy: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-toggle="tooltip"
      data-placement="bottom"
      data-original-title="Copy Answer"
      aria-describedby="tooltip"
      {...otherProps}
    >
      <path
        d="M14.286 3.56947e-05H4.68596C4.23157 0.00059373 3.79589 0.181257 3.47461 0.502679C3.15332 0.823968 2.9725 1.25964 2.97196 1.71404V2.97604H1.714C1.25961 2.97659 0.823929 3.15726 0.502643 3.47868C0.181214 3.79997 0.000535714 4.23564 0 4.69004V14.29C0.00153461 14.7437 0.182757 15.1783 0.504036 15.4988C0.825186 15.8193 1.26032 15.9995 1.714 16H11.314C11.7685 16 12.2045 15.8195 12.5259 15.498C12.8474 15.1765 13.028 14.7405 13.028 14.286V13.024H14.29C14.7437 13.0224 15.1783 12.8412 15.4988 12.5199C15.8193 12.1988 15.9995 11.7636 16 11.31V1.70996C15.9979 1.25643 15.8165 0.822143 15.4954 0.501822C15.1742 0.181368 14.7395 0.00110714 14.286 0L14.286 3.56947e-05ZM12.262 14.2861C12.262 14.5386 12.1617 14.7808 11.9831 14.9593C11.8047 15.1378 11.5625 15.2381 11.3099 15.2381H1.70994C1.45771 15.2376 1.2158 15.1371 1.03737 14.9587C0.858943 14.7803 0.758493 14.5385 0.757936 14.2861V4.68612C0.757936 4.43361 0.858243 4.19155 1.03681 4.01298C1.21539 3.83441 1.45742 3.7341 1.70996 3.7341H11.31C11.5625 3.7341 11.8047 3.83441 11.9831 4.01298C12.1617 4.19155 12.262 4.43359 12.262 4.68612L12.262 14.2861ZM15.238 11.3101C15.238 11.5626 15.1377 11.8048 14.9591 11.9833C14.7807 12.1618 14.5385 12.2621 14.2859 12.2621H13.0239V4.68999C13.0239 4.23534 12.8434 3.79949 12.522 3.47791C12.2004 3.15649 11.7646 2.97595 11.3099 2.97595H3.73776V1.71395C3.73776 1.46144 3.83807 1.21924 4.01664 1.04081C4.19508 0.862236 4.43725 0.761929 4.68979 0.761929H14.2898C14.542 0.762487 14.7839 0.862932 14.9624 1.04136C15.1408 1.2198 15.2412 1.46158 15.2418 1.71394L15.238 11.3101Z"
        fill="black"
      ></path>
    </svg>
  );
};

export default Copy;
