import styled, { css } from "styled-components";
import Button from "../../../../components/Button";
import Image from "../../../../components/Image";
import { spacingIncrement } from "../../../../utils/common";

export const MainContainer = styled.div<{
  morePadding?: boolean;
  maxHeight?: number;
  maxWidth?: number;
}>`
  position: absolute;
  left: ${spacingIncrement(5)};
  top: ${spacingIncrement(5)};
  ${({ morePadding }) =>
    morePadding &&
    css`
      left: ${spacingIncrement(10)};
      top: ${spacingIncrement(18)};
    `}

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${spacingIncrement(maxHeight)};
    `}

    ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-height: ${spacingIncrement(maxWidth)};
    `}
`;

export const AddLogoButton = styled(Button)<{ disabled?: boolean }>`
  padding: 0;
  margin: auto;
  display: block;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spacingIncrement(5)};
    padding: ${spacingIncrement(10)} ${spacingIncrement(15)};
  }
`;

export const ImageContainer = styled.div<{
  disabled?: boolean;
  loading?: boolean;
  viewMode?: boolean;
}>`
  max-height: ${spacingIncrement(120)};
  max-width: ${spacingIncrement(120)};
  border: 1px dashed #d9dae3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ loading, disabled }) =>
    (loading || disabled) &&
    css`
      border: unset;
    `}
`;

export const ImageSubContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 100%;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  display: block;
`;

export const CrossButton = styled(Image)`
  position: absolute;
  top: -9px;
  right: -9px;
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #d9dae3;
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 4px;
`;

export const SupportedTextStyle = styled.div`
  font-size: ${spacingIncrement(12)};
  color: #8c8c8c;
  width: ${spacingIncrement(168)};
  text-align: center;
`;
