import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { fetchData } from "./services/apis";
// import { getTokenFromURL } from "./services/utils";
import Context from "./store/context";
import { GLOBAL_CONTEXT } from "./types/store";
import {
  updateApproverGroup,
  updateFundList,
  updateIsUserLoggedIn,
  updateLanguages,
  updateSelectedFund,
  updateStoredFilters,
  updateStoredFiltersForArchived,
  updateUser,
  updateUserPermissions,
} from "./store/globalActions";
import { ADMIN_URL_PREFIX } from "./lib/constants";
import { getCachedFilter } from "./services/listing";
import { Language, LanguageData } from "./types/document-details";
import { TUserDetails } from "./types/user-details";
import "react-toastify/dist/ReactToastify.css";
import "react-popper-tooltip/dist/styles.css";
import { getSelectedFundId } from "./components/AppHeader/services";
import { FundList } from "./types/fund";

const ProtectedRoutes: React.FC = () => {
  const [queryParams] = useSearchParams();
  const token = queryParams.get("jwt_token");
  const [adminView, setAdminView] = useState<boolean>(false);
  const location = useLocation();
  const {
    globalDispatch,
    globalState: {
      isUserLoggedIn,
      isLoading,
      userDetails,
      languages,
      selectedFund,
    },
  } = useContext<GLOBAL_CONTEXT>(Context);
  useEffect(() => {
    if (userDetails?.clientId && !languages.length) getLanguages();
  }, [userDetails?.clientId]);
  const getLanguages = async () => {
    try {
      const response = await fetchData({
        endPoint: "getDocumentTranslationLanguages",
        queryParam: {
          clientId: userDetails?.clientId,
        },
      });
      if (response.data.data) {
        const languageArray: Language[] = (
          response.data.data as LanguageData[]
        ).map((data) => ({
          label: data.name,
          value: data.name,
        }));
        globalDispatch(updateLanguages(languageArray));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFunds = async (userDetails: TUserDetails) => {
    globalDispatch(updateIsUserLoggedIn(true));
    globalDispatch(updateUser(userDetails));
    const fundListResp = await fetchData({
      endPoint: "getFundList",
      queryParam: {
        clientId: userDetails?.clientId,
      },
    });
    if (fundListResp && fundListResp.data && fundListResp.data.data) {
      const storedSelectedFundId = getSelectedFundId();
      const selectedFund = (fundListResp.data.data as FundList[]).find(
        (fund) => fund.id === Number(storedSelectedFundId)
      );
      globalDispatch(updateFundList(fundListResp.data.data));
      const f = selectedFund ? selectedFund : fundListResp.data.data[0];
      globalDispatch(updateSelectedFund(f));
      fetchUserPermissions(f);
    } else {
      globalDispatch(updateFundList([]));
    }
  };

  const fetchUserPermissions = async (selectedFund: FundList) => {
    const userPermissionResp = await fetchData({
      endPoint: "getUserActionPermissions",
      queryParam: {
        clientId: selectedFund?.client_id,
      },
    });
    if (userPermissionResp?.data?.data) {
      globalDispatch(updateUserPermissions(userPermissionResp.data.data));
    } else {
      globalDispatch(updateUserPermissions(undefined));
    }
  };
  useEffect(() => {
    if (selectedFund?.client_id) fetchApprovalGroup();
  }, [selectedFund?.client_id]);

  const fetchApprovalGroup = async () => {
    if (selectedFund?.client_id) {
      try {
        const response = await fetchData({
          endPoint: "getCLientApprovalStatus",
          queryParam: {
            clientId: selectedFund.client_id,
          },
        });
        globalDispatch(updateApproverGroup(response.data.data));
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    (async function () {
      try {
        const getStoredFilter = getCachedFilter(false);
        if (getStoredFilter) {
          globalDispatch(updateStoredFilters(getStoredFilter));
        }
        const getStoredFilterArchived = getCachedFilter(true);
        if (getStoredFilterArchived) {
          globalDispatch(
            updateStoredFiltersForArchived(getStoredFilterArchived)
          );
        }
        const userDetailsObj = await fetchData({
          endPoint: "authenticateUser",
          queryParam: {
            token: token,
          },
        });
        if (userDetailsObj && userDetailsObj.data) {
          fetchFunds(userDetailsObj.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (adminView !== location.pathname.includes(ADMIN_URL_PREFIX)) {
      setAdminView(location.pathname.includes(ADMIN_URL_PREFIX));
    }
  }, [location]);
  return <>{isUserLoggedIn ? <Outlet /> : null}</>;
};

export default ProtectedRoutes;
