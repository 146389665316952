import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../utils/common";
import Collapsible from "react-collapsible";
import Image from "../../../components/Image";

export const NavigationContainer = styled.div<{ active?: boolean }>`
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  ul {
    padding: 0 ${spacingIncrement(20)};
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const NavigationItem = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${spacingIncrement(15)} 0;
  border-bottom: ${({ active }) => (active ? "solid 2px #0abaee" : "unset")};
  color: ${({ active }) => (active ? "#303246" : "#ADB5BD")};
  font-size: ${spacingIncrement(16)};
  font-weight: bold;
  cursor: pointer;
  :first-child {
    margin-right: ${spacingIncrement(60)};
  }
`;

export const ContentContainer = styled.div<{ highlight?: boolean }>`
  margin: ${spacingIncrement(24)};
  box-shadow: 0px 0px 4px rgb(0 0 0 / 20%) !important;
  border-radius: 2px !important;
  background: ${({ highlight }) => (highlight ? "#43baee21" : "#fff")};
  border: ${({ highlight }) =>
    highlight ? "solid 0.2px #43baee" : "1px solid #fff"};
  transition: all 0.8s ease-in-out;
`;

export const CollapsibleItem = styled(Collapsible)`
  span {
    display: flex;
    padding: ${spacingIncrement(16)};
    cursor: pointer;
  }
`;

export const CollapsibleItemContent = styled.div`
  border-top: dashed 1px #e8e9ee;
  padding: ${spacingIncrement(16)};
`;

export const CommentInput = styled.textarea`
  min-height: ${spacingIncrement(100)};
  padding: ${spacingIncrement(8)} ${spacingIncrement(16)};
  border: 1px solid #d9dae3;
  border-radius: 5px;
  width: 100%;
  background: transparent;
  font-size: ${spacingIncrement(16)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacingIncrement(16)};
  padding-bottom: ${spacingIncrement(10)};
  border-bottom: dashed 1px #e8e9ee;
  margin-bottom: ${spacingIncrement(20)};
`;

export const GenericContentContainer = styled.div`
  margin: ${spacingIncrement(24)} ${spacingIncrement(40)}
    ${spacingIncrement(24)} ${spacingIncrement(24)};
`;

export const CommentContainer = styled.div`
  :not(:last-child) {
    border-bottom: solid 1px #e8e9ee;
    padding-bottom: ${spacingIncrement(15)};
    margin-bottom: ${spacingIncrement(15)};
  }
`;

export const CommentHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CommentHeaderSubWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CommentHeader = styled.div`
  font-size: ${spacingIncrement(15)};
  font-weight: 400;
  float: left;
  padding-right: ${spacingIncrement(5)};
`;

export const CommentDate = styled.div`
  color: #adb5bd;
  font-size: ${spacingIncrement(11)};
  vertical-align: middle;
`;

export const CommentContent = styled.div`
  color: #303246;
  font-size: ${spacingIncrement(14)};
  font-weight: 300;
`;

export const CommentFooter = styled.div`
  margin-top: -${spacingIncrement(10)};
  font-size: ${spacingIncrement(11)};
  font-style: italic;
  color: #adb5bd;
`;

export const CommentButtonContainer = styled.div<{ disabled?: boolean }>`
  height: ${spacingIncrement(42)};
  background: #0abaee;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #c4c4c4;
    `}
`;

export const CommentBut = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${spacingIncrement(16)};
  height: 100%;
  padding-left: ${spacingIncrement(16)};
  padding-right: ${spacingIncrement(16)};
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border: none;
    `}
`;

export const CommentMoreButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${spacingIncrement(16)};
  height: 100%;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border: none;
    `}
`;

export const MoreOptionsWrapper = styled.div`
  border-radius: ${spacingIncrement(5)};
  padding: 0;
`;

export const MoreOptionsItem = styled.div`
  color: black;
  font-size: ${spacingIncrement(16)};
  padding: ${spacingIncrement(10)} ${spacingIncrement(20)};
  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  :not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }
`;

export const MoreOptionImageWrapper = styled.div`
  cursor: pointer;
`;

export const ToolTipContainer = styled.div`
  border-radius: ${spacingIncrement(5)};
  padding: 0;
  width: ${spacingIncrement(120)};
  max-height: ${spacingIncrement(280)};
`;

export const MoreOptionText = styled.div`
  color: #303246;
  font-size: ${spacingIncrement(16)};
  padding: ${spacingIncrement(10)} ${spacingIncrement(20)}
    ${spacingIncrement(10)} ${spacingIncrement(10)};
  gap: ${spacingIncrement(10)};
  display: flex;
  align-items: center;
  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  :not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }
`;
