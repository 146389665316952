import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import ClientPageSize from "./ClientPageSize";

import { calculatePageNumber, updatePagination } from "./service";
import SVGIcon from "../SvgIcons";

const Button = styled.button<{ tabindex?: string }>``;

type Props = {
  pageSize: number;
  handlePageSize: (pageSize: number) => void;
  totalRecords: number;
  handleChange: (pageNumber: number) => void;
  maxPageSelectSize?: number;
  activePageNumber: number;
};

export type PaginationProps = {
  ariaLabel: string;
  displayValue: string;
  value: string;
  active?: string;
};

const ClientPagination: React.FC<Props> = ({
  pageSize,
  handlePageSize,
  totalRecords,
  handleChange,
  maxPageSelectSize = 3,
  activePageNumber = 1,
}) => {
  const [paginationBar, setPaginationBar] = useState<PaginationProps[]>(
    updatePagination(
      activePageNumber,
      null,
      totalRecords,
      pageSize,
      maxPageSelectSize
    )
  );

  useEffect(() => {
    setPaginationBar(
      updatePagination(
        activePageNumber,
        null,
        totalRecords,
        pageSize,
        maxPageSelectSize
      )
    );
  }, [pageSize, totalRecords, maxPageSelectSize]);

  const getActivePage = () => {
    {
      const length = paginationBar.length;
      for (let counter = 0; counter < length; counter++) {
        if (paginationBar[counter].active) {
          return paginationBar[counter].value;
        }
      }
      return "1";
    }
  };

  const handlePagination = (buttonValue: string | number) => {
    let activePage: string | number = activePageNumber;
    switch (buttonValue) {
      case "First":
        setPaginationBar(
          updatePagination(
            1,
            "Forward",
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );
        activePage = 1;
        break;
      case "Last":
        activePage = calculatePageNumber(totalRecords, pageSize);
        setPaginationBar(
          updatePagination(
            activePage,
            "Reverse",
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );
        break;
      case "Previous":
        activePage = parseInt(getActivePage()) - 1;
        setPaginationBar(
          updatePagination(
            activePage,
            "Reverse",
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );

        break;
      case "Next":
        activePage = parseInt(getActivePage()) + 1;
        setPaginationBar(
          updatePagination(
            activePage,
            null,
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );
        break;
      default:
        activePage = buttonValue;
        if (buttonValue) {
          setPaginationBar(
            updatePagination(
              typeof buttonValue === "number"
                ? buttonValue
                : parseInt(buttonValue),
              null,
              totalRecords,
              pageSize,
              maxPageSelectSize
            )
          );
        }
    }
    if (typeof activePage === "number") {
      handleChange(activePage);
    } else {
      handleChange(parseInt(activePage));
    }
  };
  const getPaginationButtons = (buttonValue: PaginationProps) => {
    if (buttonValue.value === "Next") {
      return (
        <button className="page-link buttonAsLink">
          <SVGIcon iconName="chevron-right-12" />
          <span className="sr-only">Next</span>
        </button>
      );
    } else if (buttonValue.value === "Previous") {
      return (
        <Button className="page-link buttonAsLink" tabindex="-1">
          <SVGIcon iconName="chevron-left-12" />
          <span className="sr-only">Previous</span>
        </Button>
      );
    } else {
      return (
        <button
          className="page-link buttonAsLink"
          style={{ color: buttonValue.active ? "#0abaee" : "inherit" }}
        >
          {buttonValue.value}
        </button>
      );
    }
  };
  return (
    <div className="page-pagination">
      <div className="container">
        <div className="row mb-2">
          <nav className="col-12" aria-label="Pagination">
            <div className="pagination align-items-center justify-content-between">
              <ClientPageSize
                pageSize={pageSize}
                handleChange={handlePageSize}
              />
              {calculatePageNumber(totalRecords, pageSize) > 1 && (
                <ul className="d-flex justify-content-center">
                  {paginationBar.map((value, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handlePagination(value.value)}
                        className={
                          "page__item" + (value.active ? value.active : "")
                        }
                      >
                        {getPaginationButtons(value)}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ClientPagination;
