import React, { useContext, useEffect, useMemo, useState } from "react";
import "flatpickr/dist/themes/material_green.css";
import Context from "../../../../store/context";
import { GLOBAL_CONTEXT } from "../../../../types/store";
import { ButtonContainer } from "../styles";
import { Text } from "../../../templates/CommonComponents/common.styles";
import { BulkSelected } from "../../../../types/listing-table";
import Select from "../../../../components/Select";
import { Question } from "../../../../types/document-question";
import { fetchData, postData } from "../../../../services/apis";
import TinyEditor from "../../../../components/QuillEditor";
import Button from "../../../../components/Button";
import { notifyError, notifySuccess } from "../../../../utils/common";
import AmendmentConfirmationModal from "./Modals/AmendmentConfirmationModal";
import AmendmentAlertModal from "./Modals/AmendmentAlertModal";
import { AxiosResponse } from "axios";

type Props = {
  selectedBulkIds: BulkSelected[];
  onClose: () => void;
};
type ValidationResponse = {
  dependentQuestion: {
    id: string;
    text: string;
  };
  noList: {
    _id: string;
    article: string;
    template: string;
    fundName: string;
    subFundName: string;
  }[];
  yesList: {
    _id: string;
    article: string;
    template: string;
    fundName: string;
    subFundName: string;
  }[];
};
type ValidationResponseState = {
  dependentQuestion: {
    id: string;
    text: string;
  };
  noList: BulkSelected[];
  yesList: BulkSelected[];
};

const AnswerReplace: React.FC<Props> = ({ selectedBulkIds, onClose }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");
  const [updatedAnswer, setUpdatedAnswer] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [amendmentConfirmationModal, setAmendmentConfirmationModal] =
    useState<boolean>(false);
  const [amendmentAlertModal, setAmendmentAlertModal] =
    useState<boolean>(false);
  const [validationResponse, setValidationResponse] =
    useState<ValidationResponseState>();
  const [amendmentConfirmedDocs, setAmendmentConfirmedDocs] = useState<
    BulkSelected[]
  >([]);
  const {
    globalState: { userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);

  useEffect(() => {
    getTemplateQuestions();
  }, []);
  useEffect(() => setUpdatedAnswer(""), [selectedQuestion]);

  const getTemplateQuestions = async () => {
    const questionResp = await fetchData({
      endPoint: "getTemplateDetails",
      queryParam: {
        article: selectedBulkIds[0].article,
        template: selectedBulkIds[0].template,
      },
    });
    const questions = questionResp.data.data.questions;
    setQuestions(
      questions.filter((question: Question) => {
        return question.type === "input";
      })
    );
  };
  const questionOptions = useMemo(
    () =>
      questions.map((question) => ({
        label: question.label[
          selectedBulkIds[0].language.toLowerCase()
        ] as string,
        value: `${question.id}`,
      })),
    [questions]
  );
  const replaceHandler = async () => {
    if (!updatedAnswer) {
      notifyError("Please enter the answer to replace");
      return;
    }
    // check if the question has a dependency validation
    const questionDetails = questions.find(
      (ques) => ques.id === selectedQuestion
    );
    const isDependencyValidation = questionDetails?.validations?.find(
      (val) => val.type === "dependancy" && val?.dependantFieldId
    );
    if (isDependencyValidation) {
      // if question has dependency validation, api call to get yesAnswer document list and noAnswer document list
      setIsLoading(true);
      const dependencyCheckValidationRes: AxiosResponse<{
        data: ValidationResponse;
      }> = await postData({
        endPoint: "validateDependentQuestion",
        data: {
          documentIds: selectedBulkIds.map((doc) => doc.id),
          questionId: selectedQuestion,
          clientId: userDetails?.clientId,
        },
      }).finally(() => setIsLoading(false));
      const valResponseState: ValidationResponseState = {
        yesList: dependencyCheckValidationRes.data.data.yesList.map(
          (doc) =>
            ({
              ...doc,
              id: doc._id,
            } as unknown as BulkSelected & {
              _id: string;
            })
        ),
        noList: dependencyCheckValidationRes.data.data.noList.map(
          (doc) =>
            ({
              ...doc,
              id: doc._id,
            } as unknown as BulkSelected & {
              _id: string;
            })
        ),
        dependentQuestion:
          dependencyCheckValidationRes.data.data.dependentQuestion,
      };
      setValidationResponse(valResponseState);
      // Show alert modal
      if (valResponseState.noList.length) {
        setAmendmentAlertModal(true);
        return;
      }
      // Show confirmation modal
      if (valResponseState.yesList.length) {
        openConfirmationModal(valResponseState.yesList);
        return;
      }
    } else {
      // Show confirmation modal
      openConfirmationModal(selectedBulkIds);
    }
  };
  const openConfirmationModal = (confirmedDocs: BulkSelected[]) => {
    setAmendmentConfirmedDocs(confirmedDocs);
    setAmendmentConfirmationModal(true);
  };
  const onAlertConfirm = () => {
    // call bulk amendment api to update the answer for no and yes list documents
    if (
      validationResponse?.noList.length ||
      validationResponse?.yesList.length
    ) {
      setAmendmentAlertModal(false);
      const allDocuments = [
        ...validationResponse.noList,
        ...validationResponse.yesList,
      ];
      openConfirmationModal(allDocuments);
    }
  };
  const onSkipAndProceed = () => {
    setAmendmentAlertModal(false);
    if (validationResponse?.yesList?.length) {
      openConfirmationModal(validationResponse?.yesList);
    }
  };
  const onReplacmentConfirm = async () => {
    // call bulk amendment api to update the answer for no and yes list documents
    if (amendmentConfirmedDocs?.length) {
      setAmendmentConfirmationModal(false);
      bulkAmendmentApiCall({
        documentIds: amendmentConfirmedDocs.map((doc) => doc.id),
        questionId: selectedQuestion,
        updateDependantQuestion: true,
        dependentQuestionId: validationResponse?.dependentQuestion.id,
      });
    }
  };
  const bulkAmendmentApiCall = async ({
    documentIds,
    questionId,
    updateDependantQuestion,
    dependentQuestionId,
  }: {
    documentIds: string[]; // All documents which needs to be uodated
    questionId: string; // target question
    updateDependantQuestion: boolean; // If this is true will update the dependent question value to 'Yes'
    dependentQuestionId?: string; // dependent question id
  }) => {
    setIsLoading(true);
    const bulkAmendmentRes: AxiosResponse<{
      success: boolean;
      message: string;
      data: any;
    }> = await postData({
      endPoint: "bulkAmendment",
      data: {
        documentIds,
        questionId,
        answer: updatedAnswer,
        clientId: userDetails?.clientId,
        updateDependantQuestion,
        dependentQuestionId,
      },
    }).finally(() => setIsLoading(false));
    if (bulkAmendmentRes.data.success) {
      setSelectedQuestion("");
      setSelectedQuestion("");
      setUpdatedAnswer("");
      setAmendmentConfirmationModal(false);
      setAmendmentAlertModal(false);
      onClose();
      notifySuccess("Bulk changes have been applied successfully.");
    }
  };

  function ButtonsHandler() {
    return (
      <ButtonContainer>
        <Button
          onClick={() => setUpdatedAnswer("")}
          disabled={isLoading || !updatedAnswer}
        >
          Clear
        </Button>
        <Button onClick={replaceHandler} buttonType="solid" loading={isLoading}>
          Replace
        </Button>
      </ButtonContainer>
    );
  }
  return (
    <>
      <Select
        label="Find Question"
        options={questionOptions}
        onChange={(e: any) => setSelectedQuestion(e.value)}
        styles={{ control: (base) => ({ ...base, marginBottom: "20px" }) }}
        placeholder="Search/Select"
      />
      {selectedQuestion && (
        <>
          <Text marginTop="20px">Replace existing answer with</Text>
          <TinyEditor
            id="answer-replace"
            innerHTML={updatedAnswer}
            placeHolder="Enter Text"
            onChange={(string) => setUpdatedAnswer(string)}
          />
          <Text fontSize={13} marginBottom="10px">
            {`Note: Please note that bulk amendments cannot be applied to internal
            questions which you have previously answered with 'no'. The answer to
            the internal question needs to be amended to 'Yes' for all the selected
            documents before applying the changes.`}
          </Text>
          <ButtonsHandler />
        </>
      )}
      {amendmentConfirmationModal && (
        <AmendmentConfirmationModal
          selectedDocument={amendmentConfirmedDocs}
          isOpen={amendmentConfirmationModal}
          onClose={() => setAmendmentConfirmationModal(false)}
          onConfirm={onReplacmentConfirm}
        />
      )}
      {amendmentAlertModal && (
        <AmendmentAlertModal
          yesListDocs={validationResponse?.yesList}
          noListDocs={validationResponse?.noList}
          isOpen={amendmentAlertModal}
          onClose={() => setAmendmentAlertModal(false)}
          onConfirm={onAlertConfirm}
          onSkipAndProceed={onSkipAndProceed}
        />
      )}
    </>
  );
};

export default AnswerReplace;
