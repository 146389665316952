import { useCallback, useContext, useMemo, useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Select, { Option } from "../../../components/Select";
import { Label } from "../../../components/Select/styles";
import { postData } from "../../../services/apis";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { notifyError, notifySuccess } from "../../../utils/common";
import {
  ButtonWrapper,
  DisabledContainer,
  ModalContentWrapper,
  RowContainer,
} from "./styles";
import { SubFund } from "../../../types/fund";
import { DocumentArchiveConfirmationModal } from "./ConfirmationModal";

const ArchiveDocumentsModal: React.FC<{
  isOpen: boolean;
  subFundList: SubFund[];
  onCloseModal: () => void;
  onArchiveSuccess: () => void;
}> = ({ isOpen, onCloseModal, subFundList, onArchiveSuccess }) => {
  const {
    globalState: { selectedFund },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [subFunds, setSubFunds] = useState<Option[]>([]);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const subFundOptions: Option[] = useMemo(
    () =>
      subFundList.map((subFund) => ({
        label: subFund.name,
        value: `${subFund.id}`,
      })),
    [subFundList]
  );
  const handleArchive = async () => {
    if (!subFunds || !selectedFund) {
      return;
    }
    setLoading(true);
    const reqBody = {
      subFundIds: subFunds.map((sf) => parseInt(sf.value)),
      fundId: selectedFund.fund_level_one_id,
      fundName: selectedFund.name,
      clientId: selectedFund.client_id,
    };
    try {
      const resp = await postData({
        endPoint: "archiveDocuments",
        data: reqBody,
      });
      if (resp && resp.data?.success) {
        closeModal();
        onArchiveSuccess();
        if (resp.data) {
          notifySuccess(<>Documents archived successfully.</>, {
            width: "auto",
          });
        }
      }
    } catch (e: any) {
      notifyError(e?.message);
    } finally {
      setLoading(false);
    }
  };
  function clearAllFields() {
    setSubFunds([]);
  }
  const closeModal = () => {
    clearAllFields();
    handleConfirmationClose();
    onCloseModal();
  };
  const handleConfirmationClose = () => setConfirmationModal(false);

  const ConfirmationModal = useCallback(() => {
    return (
      <ModalContentWrapper>
        <div>
          <div className="mb-2">
            <Label>Fund Name</Label>
            <DisabledContainer>{selectedFund?.name}</DisabledContainer>
          </div>
          <Select
            label="Select Sub funds"
            isMulti
            styles={{
              control: (provided) => ({
                ...provided,
                height: "auto",
              }),
            }}
            value={subFunds}
            options={subFundOptions}
            onChange={(e: any) => setSubFunds(e)}
          />
        </div>
        <RowContainer>
          <ButtonWrapper>
            <Button
              className="btn btn-primary"
              disabled={!subFunds?.length}
              onClick={() => setConfirmationModal(true)}
              buttonType="solid"
              loading={loading}
            >
              Archive
            </Button>
          </ButtonWrapper>
        </RowContainer>
      </ModalContentWrapper>
    );
  }, [selectedFund, subFunds, subFundOptions, loading]);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        header="Document Archival by Sub-Funds."
        width={773}
      >
        <ConfirmationModal />
      </Modal>
      <Modal
        isOpen={confirmationModal}
        header="Archive Confirmation"
        onClose={handleConfirmationClose}
      >
        <DocumentArchiveConfirmationModal
          onConfirm={handleArchive}
          onCancel={handleConfirmationClose}
        />
      </Modal>
    </>
  );
};

export default ArchiveDocumentsModal;
