import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../utils/common";

export const Container = styled.div`
  padding-left: ${spacingIncrement(72)};
  padding-top: ${spacingIncrement(20)};
`;

export const QuestionContainer = styled.div`
  width: ${spacingIncrement(779)};
  min-height: ${spacingIncrement(227)};
  border: 1px solid #e5e5e5;
  padding: ${spacingIncrement(20)};
`;

export const QuestionTitle = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: ${spacingIncrement(16)};
  padding-bottom: ${spacingIncrement(20)};
`;

export const TextArea = styled.textarea<{ margin?: string }>`
  background: #ffffff;
  border: 1px solid #d9dae3;
  border-radius: 4px;
  min-height: ${spacingIncrement(100)};
  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacingIncrement(12)};
`;
