import Image from "../../../components/Image";
import QuillEditor from "../../../components/QuillEditor";
import {
  DocumentRecord,
  DocumentStatusEnum,
  DocumentViewModeEnum,
} from "../../../types/document-details";
import { spacingIncrement } from "../../../utils/common";
import Checkbox from "../CommonComponents/CheckBox";
import CommentButton from "./CommentButton";
import DocDoNotIncludeIcon from "../CommonComponents/DocDoNotIncludeIcon";
import MainIcon from "./MainIcon";
import { NumberInput } from "../CommonComponents/NumberInput";
import Section from "../CommonComponents/Section";
import PreviousAnswers from "../CommonComponents/PreviousAnswers";
import {
  Container,
  GreyBox,
  Bold,
  OrangeBoxHeader,
  OrangeBox,
  OrangeLeft,
  OrangeRight,
  OrangeLeftHeader,
  OrangeRightHeader,
  Circle,
  OrangeSection,
  QuestionText,
  HorizontalLine,
  GreyDot,
  FlexRow,
  UploadWrapper,
  UploadFooterNote,
  Highlight,
  GraphContainer,
  GraphSubContainer,
  Flex,
  FlexColumn,
  RelativeContainer,
  InputCheckBoxContainer,
  CheckBoxLabel,
  Block,
  WaterMarkText,
  WaterMarkWrapper,
} from "./styles";
import { QuestionContainer } from "../CommonComponents/QuestionContainer";
import SubIcon from "./SubIcon";
import PieChart from "../CommonComponents/PieChart";
import {
  AvoidBreakInside,
  Text,
  Title,
  ProductInfo,
} from "../CommonComponents/common.styles";
import { useDocumentState } from "../../../hooks/useDocumentState";
import { useCallback, useEffect, useMemo, useState } from "react";
import { updateDocumentState } from "../../../store/globalActions";
import { useContext } from "react";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import UploadLogoButton from "../CommonComponents/UploadLogoButton";
import { Preset } from "../CommentSection";
import { CommentType } from "../../../types/comment";
import {
  DisplayQuestion,
  DocumentCommonContent,
  DocumentStaticContent,
  LabelText,
  Language,
} from "../../../types/document-question";
import {
  getQuestionLabel,
  getStaticContentText,
} from "../DocumentEditor/service";
import InfoToolTip from "../../../components/InfoToolTip";
import { TooltipWrapper } from "../../../components/InfoToolTip/styles";
import {
  Note,
  ProductInfoContainer,
  SubHeading,
} from "../ArticleEightPeriodic/styles";
import parse from "html-react-parser";
import GraphInput from "../CommonComponents/GraphInput";
import {
  AnnexText,
  Label,
  QuestionLabel,
  ErrorMessage,
} from "../CommonComponents/Typography";
import { RegulatoryExternalLinkComponent } from "../CommonComponents/RegulatoryExternalLinkComponent";
import { REGULATORY_EXTERNAL_LINK_ID } from "../../../lib/constants";
import AssetAllocationGraph from "../../../components/AssetAllocationGraph";
// import InlineNumberInput from "../../../components/InlineNumberInput";

type Props = {
  document: DocumentRecord;
  mode?: DocumentViewModeEnum;
  updateDoc: (doc: DocumentRecord | undefined) => void;
  toggleQuestionCommentSection?: (preset: Preset) => void;
  togglePreviousAnswersSection?: (val: string) => void;
  showCommentButton?: boolean;
  showPreviousAns?: boolean;
  canEdit?: boolean;
  displayQuestions: DisplayQuestion;
  isLocked?: boolean;
  showWaterMark?: boolean;
  staticContent: DocumentStaticContent;
  commonContent: DocumentCommonContent;
};

const ArticleEightPreContractual: React.FC<Props> = ({
  mode = DocumentViewModeEnum.edit,
  document,
  updateDoc,
  toggleQuestionCommentSection,
  togglePreviousAnswersSection,
  showCommentButton,
  showPreviousAns,
  canEdit,
  displayQuestions,
  isLocked,
  showWaterMark,
  staticContent,
  commonContent,
}) => {
  const {
    globalState: { commentedQuestionIds, documentState },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);
  const { getValue, updateAnswer, handleFloatInput, getQuestionError } =
    useDocumentState();
  const isEditMode = useMemo(() => {
    return mode === DocumentViewModeEnum.edit && canEdit;
  }, [canEdit]);
  useEffect(() => {
    if (documentState?.["Aeaa3tKRrK"] !== "No") {
      updateAnswer("Aeaa3tKRrK", "No");
    }
    return () => {
      globalDispatch(updateDocumentState(undefined));
      updateDoc(undefined);
    };
  }, []);

  const subQuestionTopPadding = 20;

  const onComment = (qId: string) => {
    const preset: Preset = {
      commentType: CommentType.questionLevel,
      documentId: document._id,
      questionId: qId,
    };
    toggleQuestionCommentSection?.(preset);
  };

  const onPreviousAnswersClick = (qId: string) => {
    togglePreviousAnswersSection?.(qId);
  };

  const showInfoIcon = !(mode === DocumentViewModeEnum.view);
  const showDoNotIncludeIcon = !(mode === DocumentViewModeEnum.view);

  const showChartInputPercentage = !(mode === DocumentViewModeEnum.edit);

  const showErrorMessage =
    mode === DocumentViewModeEnum.edit &&
    document.status !== DocumentStatusEnum.filed;

  const hasCommentsById = useCallback(
    (qId: string) => {
      return commentedQuestionIds.includes(qId);
    },
    [commentedQuestionIds]
  );

  const isNewVersionDocument = () => {
    if (document.status === DocumentStatusEnum.filed) {
      if (getValue("uchuH43MmW")) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const clearChartData = (questionIds: string[]) => {
    questionIds.forEach((id: string) => {
      updateAnswer(id, 0);
    });
  };

  const clearTaxnomyIncludingChartData = () => {
    clearChartData(["kdssXcLDwc", "IRi8H6XpGo", "bBomkodkGD", "SumRuJgTcD"]);
  };
  const clearTaxnomyExcludingChartData = () => {
    clearChartData(["e5TGU2xAct", "vckL7B8rpr", "KMmwSpnToD", "YjIPNWGFjJ"]);
  };

  return (
    <Container isLocked={isLocked}>
      <UploadLogoButton
        document={document}
        localDocumentState={documentState}
        updateDoc={(d) => updateAnswer("logo", d.documentState?.logo)}
        disabled={!isEditMode}
        mode={mode}
      />
      {showWaterMark && (
        <WaterMarkWrapper>
          <WaterMarkText>DRAFT</WaterMarkText>
        </WaterMarkWrapper>
      )}
      <Section
        left={<div style={{ minHeight: "140px" }}></div>}
        right={
          <>
            {/* <AnnexText>
              <Label isBold isItalic fontSize={14}>
                {parse(
                  getStaticContentText(
                    "Ovs6YgWZDD",
                    staticContent.staticText,
                    document.language
                  )
                )}
              </Label>
            </AnnexText> */}
            <SubHeading>
              {/* Template pre-contractual disclosure for the financial products
                referred to in Article 8, paragraphs 1, 2 and 2a, of Regulation
                (EU) 2019/2088 and Article 6, first paragraph, of Regulation
                (EU) 2020/852 */}
              {parse(
                getStaticContentText(
                  "ZhPbabrVCC",
                  staticContent.headingText,
                  document.language
                )
              )}
            </SubHeading>
            <ProductInfoContainer>
              <ProductInfo>
                {parse(
                  getStaticContentText(
                    "vS8nIYaM8c",
                    staticContent.staticText,
                    document.language
                  )
                )}
                : {document?.subFundName}
              </ProductInfo>
              <ProductInfo>
                {parse(
                  getStaticContentText(
                    "9GiGPkYC1D",
                    staticContent.staticText,
                    document.language
                  )
                )}
                : {document?.legalEntityIdentifier}
              </ProductInfo>
            </ProductInfoContainer>
          </>
        }
      />
      <Section
        left={
          <>
            <GreyBox>
              {/* <Bold>Sustainable investment </Bold>means an investment in an
              economic activity that contributes to an environmental or social
              objective, provided that the investment does not significantly
              harm any environmental or social objective and that the investee
              companies follow good governance practices. */}
              {parse(
                getStaticContentText(
                  "ciUEjpwOBP",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
            <GreyBox>
              {/* The
              <Bold> EU Taxonomy </Bold> is a classification system laid down in
              Regulation (EU) 2020/852, establishing a list of
              <Bold> environmentally sustainable economic activities</Bold>.
              That Regulation does not include a list of socially sustainable
              economic activities. Sustainable investments with an environmental
              objective might be aligned with the Taxonomy or not. */}
              {parse(
                getStaticContentText(
                  "puWsyqpI6d",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <Title>
              {parse(
                getStaticContentText(
                  "dSRTnizdqQ",
                  staticContent.staticText,
                  document.language
                )
              )}
            </Title>
            <OrangeBoxHeader>
              <Bold>
                <FlexRow>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {parse(
                      getStaticContentText(
                        "sxgv4W6QN8",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}

                    {/* [tick and fill in as relevant, the percentage figure
                    represents the minimum commitment to sustainable
                    investments] */}
                  </InfoToolTip>
                  <Label isBold>
                    {parse(
                      getQuestionLabel(
                        "Aeaa3tKRrK",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    )}
                  </Label>
                  <CommentButton
                    hasComments={hasCommentsById("Aeaa3tKRrK")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("Aeaa3tKRrK")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["Aeaa3tKRrK"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("Aeaa3tKRrK")}
                  />
                </FlexRow>
              </Bold>
              <Flex>
                <OrangeLeftHeader>
                  <Circle isHighlighted />
                  <Circle isHighlighted />
                  <Checkbox
                    id="1"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={6}
                    disabled={true}
                    mode={mode}
                  />
                </OrangeLeftHeader>
                <OrangeRightHeader>
                  <Circle isHighlighted />
                  <Circle />
                  <Checkbox
                    id="2"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={6}
                    disabled={true}
                    checked={true}
                    mode={mode}
                  />
                  <HorizontalLine />
                </OrangeRightHeader>
              </Flex>
            </OrangeBoxHeader>
            <OrangeBox>
              <OrangeSection>
                <OrangeLeft>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="3"
                      fontSize={15}
                      label=""
                      disabled={true}
                      checked={false}
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "8q1a45kuW5",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <NumberInput
                        inline
                        value={getValue("IZZTwWVuVs") || ""}
                        disabled={true}
                      />
                      {parse(
                        getQuestionLabel(
                          "8q1a45kuW5",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1] || ""
                      )}
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <Checkbox
                    id="4"
                    marginLeft={35}
                    bold={false}
                    fontSize={12}
                    label={getQuestionLabel(
                      "6bbtZIkXJ9",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={true}
                    checked={false}
                    mode={mode}
                  />
                  <Checkbox
                    id="5"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "O4h7WjvGC9",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={true}
                    checked={false}
                    mode={mode}
                  />
                </OrangeLeft>
                <OrangeRight>
                  <ErrorMessage
                    questionId="Aeaa3tKRrK"
                    isEditMode={showErrorMessage}
                  >
                    {getQuestionError("Aeaa3tKRrK")?.message}
                  </ErrorMessage>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="6"
                      fontSize={15}
                      label=""
                      disabled={!isEditMode}
                      checked={!!getValue("nORKI569La")}
                      onClick={() => {
                        updateAnswer("nORKI569La", !getValue("nORKI569La"));
                        if (!getValue("nORKI569La") === true)
                          updateAnswer("LMlKBsTv48", false);
                      }}
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "nORKI569La",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <NumberInput
                        maxLimit={100}
                        value={
                          getValue("nORKI569La") === true
                            ? 0
                            : getValue("nORKI569La") || ""
                        }
                        onChange={(e) => handleFloatInput(e, "nORKI569La", 100)}
                        disabled={!isEditMode}
                        inline
                      />
                      {parse(
                        getQuestionLabel(
                          "nORKI569La",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1] || ""
                      )}
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <ErrorMessage
                    questionId="nORKI569La"
                    isEditMode={showErrorMessage}
                  >
                    {getQuestionError("nORKI569La")?.message}
                  </ErrorMessage>
                  <Checkbox
                    id="7"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "OcdWvsdW2r",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode || !getValue("nORKI569La")}
                    checked={getValue("OcdWvsdW2r")}
                    onClick={() =>
                      updateAnswer("OcdWvsdW2r", !getValue("OcdWvsdW2r"))
                    }
                    mode={mode}
                  />
                  <Checkbox
                    id="8"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "U8b8KKCTvG",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode || !getValue("nORKI569La")}
                    checked={getValue("U8b8KKCTvG")}
                    onClick={() =>
                      updateAnswer("U8b8KKCTvG", !getValue("U8b8KKCTvG"))
                    }
                    mode={mode}
                  />
                  <Checkbox
                    id="9"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={getQuestionLabel(
                      "hnJpcPqq4l",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    disabled={!isEditMode || !getValue("nORKI569La")}
                    checked={getValue("hnJpcPqq4l")}
                    onClick={() =>
                      updateAnswer("hnJpcPqq4l", !getValue("hnJpcPqq4l"))
                    }
                    mode={mode}
                  />
                  <HorizontalLine />
                </OrangeRight>
              </OrangeSection>
              <OrangeSection>
                <OrangeLeft>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="10"
                      fontSize={15}
                      label=""
                      disabled={true}
                      checked={false}
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "YpQqEJ6SDH",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <NumberInput
                        value={getValue("v65h6R81Rl") || ""}
                        disabled={true}
                        inline
                      />
                      {parse(
                        getQuestionLabel(
                          "YpQqEJ6SDH",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1] || ""
                      )}
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                </OrangeLeft>
                <OrangeRight>
                  <Checkbox
                    id="11"
                    fontSize={15}
                    bold={false}
                    label={
                      getQuestionLabel(
                        "LMlKBsTv48",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={!isEditMode}
                    checked={getValue("LMlKBsTv48")}
                    onClick={() => {
                      updateAnswer("LMlKBsTv48", !getValue("LMlKBsTv48"));
                      if (!getValue("LMlKBsTv48") === true)
                        updateAnswer("nORKI569La", false);
                    }}
                    mode={mode}
                  />
                  <HorizontalLine heightPercent={87} />
                </OrangeRight>
              </OrangeSection>
            </OrangeBox>
          </>
        }
      />
      {isEditMode && (
        <Section
          paddingTop={20}
          left={<></>}
          right={
            <>
              <QuestionContainer>
                <QuestionText isBold>
                  <Highlight>
                    <QuestionLabel
                      isBold
                      questionId="abyhBSRw26"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="12"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("abyhBSRw26") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "abyhBSRw26",
                        getValue("abyhBSRw26") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="13"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("abyhBSRw26") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "abyhBSRw26",
                        getValue("abyhBSRw26") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            </>
          }
        />
      )}
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="globe" />
            <GreyBox>
              {/* <Bold> Sustainability indicators </Bold> measure how the
              environmental or social characteristics promoted by the financial
              product are attained. */}
              {parse(
                getStaticContentText(
                  "5KVCFMdUkd",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <QuestionContainer>
            <QuestionContainer paddingLeft={0}>
              <QuestionText isBold>
                <InfoToolTip disabled={!showInfoIcon}>
                  {/* [indicate the environmental and/or social characteristics
                  promoted by the financial product and whether a reference
                  benchmark has been designated for the purpose of attaining the
                  environmental or social characteristics promoted by the
                  financial product] */}
                  {parse(
                    getStaticContentText(
                      "9Tz5EJQWHm",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                </InfoToolTip>

                <QuestionLabel
                  isBold
                  questionId="tBA9Jkx2rF"
                  getQuestionError={getQuestionError}
                  displayQuestions={displayQuestions}
                  language={document.language}
                  showErrorMessage={showErrorMessage}
                />
                <CommentButton
                  hasComments={hasCommentsById("tBA9Jkx2rF")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("tBA9Jkx2rF")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["tBA9Jkx2rF"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("tBA9Jkx2rF")}
                />
              </QuestionText>
              <QuillEditor
                id="tBA9Jkx2rF"
                disabled={!isEditMode}
                innerHTML={getValue("tBA9Jkx2rF")}
                onChange={(ans) => updateAnswer("tBA9Jkx2rF", ans)}
              />
            </QuestionContainer>
            <QuestionContainer
              paddingTop={subQuestionTopPadding}
              paddingLeft={0}
            >
              <QuestionText fontSize={14} isItalic isBold>
                <GreyDot />
                <QuestionLabel
                  isBold
                  isItalic
                  fontSize={14}
                  questionId="TroFFikysA"
                  getQuestionError={getQuestionError}
                  displayQuestions={displayQuestions}
                  language={document.language}
                  showErrorMessage={showErrorMessage}
                />
                <CommentButton
                  hasComments={hasCommentsById("TroFFikysA")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("TroFFikysA")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["TroFFikysA"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("TroFFikysA")}
                />
              </QuestionText>
              <QuillEditor
                id="TroFFikysA"
                disabled={!isEditMode}
                innerHTML={getValue("TroFFikysA")}
                onChange={(ans) => updateAnswer("TroFFikysA", ans)}
              />
            </QuestionContainer>
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        right={
          <QuestionContainer>
            {isEditMode && (
              <QuestionContainer paddingLeft={10}>
                <QuestionText fontSize={14} isBold>
                  <Highlight>
                    <QuestionLabel
                      isBold
                      fontSize={14}
                      questionId="FxwwfzhaRz"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("FxwwfzhaRz")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("FxwwfzhaRz")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["FxwwfzhaRz"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("FxwwfzhaRz")}
                  />
                </QuestionText>
                <FlexRow>
                  <Checkbox
                    id="14"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("FxwwfzhaRz") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "FxwwfzhaRz",
                        getValue("FxwwfzhaRz") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="15"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("FxwwfzhaRz") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "FxwwfzhaRz",
                        getValue("FxwwfzhaRz") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("FxwwfzhaRz") === "Yes" && (
              <>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [include, for financial products that make sustainable
                      investments, a description of the objectives and how the
                      sustainable investments contribute to the sustainable
                      investment objective. For the financial products referred
                      to in Article 6, first paragraph, of Regulation (EU)
                      2020/852, list the environmental objectives set out in
                      Article 9 of that Regulation to which the sustainable
                      investment underlying the financial product contributes] */}
                      {parse(
                        getStaticContentText(
                          "XKxpjPzM8D",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <QuestionLabel
                      isBold
                      isItalic
                      fontSize={14}
                      questionId="hUlg0HOaDF"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("hUlg0HOaDF")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("hUlg0HOaDF")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["hUlg0HOaDF"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("hUlg0HOaDF")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="hUlg0HOaDF"
                    disabled={!isEditMode}
                    innerHTML={getValue("hUlg0HOaDF")}
                    onChange={(ans) => updateAnswer("hUlg0HOaDF", ans)}
                  />
                </QuestionContainer>
              </>
            )}
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <GreyBox>
            {parse(
              getStaticContentText(
                "ktQwKJvIO2",
                staticContent.greyBox,
                document.language
              )
            )}
            {/* <Bold> Principal adverse impacts </Bold>are the most significant
              negative impacts of investment decisions on sustainability factors
              relating to environmental, social and employee matters, respect
              for human rights, anti-corruption and anti-bribery matters. */}
          </GreyBox>
        }
        right={
          <QuestionContainer>
            {getValue("FxwwfzhaRz") === "Yes" && (
              <>
                <QuestionContainer paddingLeft={10}>
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [include a description for the financial product that
                      partially intends to make sustainable investments] */}
                      {parse(
                        getStaticContentText(
                          "EKK1xH9pwr",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <QuestionLabel
                      isBold
                      isItalic
                      fontSize={14}
                      questionId="MiHRssGilt"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("MiHRssGilt")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("MiHRssGilt")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["MiHRssGilt"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("MiHRssGilt")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="MiHRssGilt"
                    disabled={!isEditMode}
                    innerHTML={getValue("MiHRssGilt")}
                    onChange={(ans) => updateAnswer("MiHRssGilt", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionContainer paddingLeft={10}>
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include an explanation of how the indicators for
                        adverse impacts in Table 1 of Annex I and any relevant
                        indicators in Tables 2 and 3 of Annex I , are taken into
                        account] */}
                        {parse(
                          getStaticContentText(
                            "UFPBpIswCO",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        isBold
                        isItalic
                        fontSize={14}
                        questionId="tka5YIZk1n"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("tka5YIZk1n")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("tka5YIZk1n")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["tka5YIZk1n"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("tka5YIZk1n")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="tka5YIZk1n"
                      disabled={!isEditMode}
                      innerHTML={getValue("tka5YIZk1n")}
                      onChange={(ans) => updateAnswer("tka5YIZk1n", ans)}
                    />
                  </QuestionContainer>
                  <QuestionContainer
                    paddingTop={subQuestionTopPadding}
                    paddingLeft={10}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {parse(
                          getStaticContentText(
                            "r3okXKDEYF",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                        {/* [include an explanation on the alignment with the OECD
                        Guidelines for Multinational Enterprises and the UN
                        Guiding Principles on Business and Human Rights,
                        including the principles and rights set out in the eight
                        fundamental conventions identified in the Declaration of
                        the International Labour Organisation on Fundamental
                        Principles and Rights at Work and the International Bill
                        of Human Rights] */}
                      </InfoToolTip>
                      <QuestionLabel
                        isBold
                        isItalic
                        fontSize={14}
                        questionId="EG8qIPBXrC"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("EG8qIPBXrC")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("EG8qIPBXrC")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["EG8qIPBXrC"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("EG8qIPBXrC")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="EG8qIPBXrC"
                      disabled={!isEditMode}
                      innerHTML={getValue("EG8qIPBXrC")}
                      onChange={(ans) => updateAnswer("EG8qIPBXrC", ans)}
                    />
                  </QuestionContainer>
                </QuestionContainer>
                <QuestionContainer paddingLeft={0}>
                  <OrangeBox padding="10px" margin="10px">
                    <div style={{ paddingBottom: "15px" }}>
                      <QuestionText isItalic>
                        <InfoToolTip width={320} disabled={!showInfoIcon}>
                          {/* [Include statement for financial products referred to in
                          Article 6, first paragraph, of Regulation (EU) 2020/852] */}
                          {parse(
                            getStaticContentText(
                              "Q2EbOir9jU",
                              staticContent.infoTooltip,
                              document.language
                            )
                          )}
                        </InfoToolTip>
                        {/* The EU Taxonomy sets out a “do not significant harm”
                        principle by which Taxonomy-aligned investments should not
                        significantly harm EU Taxonomy objectives and is
                        accompanied by specific EU criteria. */}
                        {parse(
                          getStaticContentText(
                            "5kxY1qi2nK",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                      </QuestionText>
                    </div>
                    <div style={{ paddingBottom: "15px" }}>
                      <Text>
                        {/* The “do no significant harm” principle applies only to
                        those investments underlying the financial product that
                        take into account the EU criteria for environmentally
                        sustainable economic activities. The investments
                        underlying the remaining portion of this financial product
                        do not take into account the EU criteria for
                        environmentally sustainable economic activities. */}
                        {parse(
                          getStaticContentText(
                            "YIIzTDHUgQ",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                      </Text>
                    </div>
                    <div style={{ paddingBottom: "15px" }}>
                      <Text isItalic>
                        {parse(
                          getStaticContentText(
                            "Eg517Q60HG",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                        {/* Any other sustainable investments must also not
                        significantly harm any environmental or social objectives. */}
                      </Text>
                    </div>
                  </OrangeBox>
                </QuestionContainer>
              </>
            )}
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="factory" />
          </>
        }
        right={
          <>
            <QuestionContainer>
              <AvoidBreakInside>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [if the financial product considers principal adverse impacts
                  on sustainability factors, include a clear and reasoned
                  explanation of how it considers principal adverse impacts on
                  sustainability factors. Indicate where, in the information to
                  be disclosed pursuant to Article 11(2) of Regulation (EU)
                  2019/2088, the information on principal adverse impacts on
                  sustainability factors is available] */}
                    {parse(
                      getStaticContentText(
                        "1bUYOqp5Ew",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <Label
                    id={`question-label-${"17Bh8P7OG2"}`}
                    fontSize={14}
                    isBold
                  >
                    {parse(
                      getQuestionLabel(
                        "17Bh8P7OG2",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    )}
                    <ErrorMessage
                      questionId={"17Bh8P7OG2"}
                      isEditMode={showErrorMessage}
                    >
                      {getQuestionError("17Bh8P7OG2")?.message ||
                        getQuestionError("5J1eisRvZy")?.message}
                    </ErrorMessage>
                  </Label>
                  <CommentButton
                    hasComments={hasCommentsById("17Bh8P7OG2")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("17Bh8P7OG2")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["17Bh8P7OG2"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("5J1eisRvZy")}
                  />
                </QuestionText>
                <FlexRow>
                  <Checkbox
                    id="16"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("17Bh8P7OG2") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "17Bh8P7OG2",
                        getValue("17Bh8P7OG2") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="17"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("17Bh8P7OG2") === "No"}
                    onClick={() => {
                      updateAnswer(
                        "17Bh8P7OG2",
                        getValue("17Bh8P7OG2") === "No" ? "" : "No"
                      );
                      updateAnswer("5J1eisRvZy", "");
                    }}
                  />
                </FlexRow>
              </AvoidBreakInside>
              {getValue("17Bh8P7OG2") === "Yes" && (
                <QuestionContainer paddingLeft={0} paddingTop={10}>
                  <QuillEditor
                    id="5J1eisRvZy"
                    disabled={!isEditMode}
                    innerHTML={getValue("5J1eisRvZy")}
                    onChange={(ans) => updateAnswer("5J1eisRvZy", ans)}
                  />
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="writingPad" />
            <GreyBox>
              {parse(
                getStaticContentText(
                  "ugugadm8LB",
                  staticContent.greyBox,
                  document.language
                )
              )}
              {/* <Bold>The investment strategy </Bold>
              guides investment decisions based on factors such as investment
              objectives and risk tolerance. */}
            </GreyBox>
            <GreyBox>
              {parse(
                getStaticContentText(
                  "AtnedMAsaQ",
                  staticContent.greyBox,
                  document.language
                )
              )}
              {/* <Bold>Good governance </Bold> practices include sound management
              structures, employee relations, remuneration of staff and tax
              compliance. */}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [provide a description of the investment strategy and indicate
                    how the strategy is implemented in the investment process on a
                    continuous basis] */}
                    {parse(
                      getStaticContentText(
                        "CyTjJlP7Sa",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    questionId="nU99J9dXD0"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("nU99J9dXD0")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("nU99J9dXD0")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["nU99J9dXD0"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("nU99J9dXD0")}
                  />
                </QuestionText>
                <QuillEditor
                  id="nU99J9dXD0"
                  disabled={!isEditMode}
                  innerHTML={getValue("nU99J9dXD0")}
                  onChange={(ans) => updateAnswer("nU99J9dXD0", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={10}
              >
                <QuestionText fontSize={14} isItalic isBold>
                  <GreyDot />
                  <QuestionLabel
                    isBold
                    isItalic
                    fontSize={14}
                    questionId="CmvlJ8kt5v"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("CmvlJ8kt5v")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("CmvlJ8kt5v")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["CmvlJ8kt5v"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("CmvlJ8kt5v")}
                  />
                </QuestionText>
                <QuillEditor
                  id="CmvlJ8kt5v"
                  disabled={!isEditMode}
                  innerHTML={getValue("CmvlJ8kt5v")}
                  onChange={(ans) => updateAnswer("CmvlJ8kt5v", ans)}
                />
              </QuestionContainer>
              {isEditMode && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isBold>
                    <Highlight>
                      <QuestionLabel
                        isBold
                        fontSize={14}
                        questionId="rIyAhOwo3N"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("rIyAhOwo3N")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("rIyAhOwo3N")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["rIyAhOwo3N"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("rIyAhOwo3N")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="18"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("rIyAhOwo3N") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "rIyAhOwo3N",
                          getValue("rIyAhOwo3N") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="19"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("rIyAhOwo3N") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "rIyAhOwo3N",
                          getValue("rIyAhOwo3N") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("rIyAhOwo3N") === "Yes" && (
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [include an indication of the rate, where there is a
                      commitment to reduce the scope of investments by a minimum
                      rate] */}
                      {parse(
                        getStaticContentText(
                          "ypOr6RDTNh",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <QuestionLabel
                      isBold
                      isItalic
                      fontSize={14}
                      questionId="XUbdM3ppTb"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("XUbdM3ppTb")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("XUbdM3ppTb")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["XUbdM3ppTb"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("XUbdM3ppTb")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="XUbdM3ppTb"
                    disabled={!isEditMode}
                    innerHTML={getValue("XUbdM3ppTb")}
                    onChange={(ans) => updateAnswer("XUbdM3ppTb", ans)}
                  />
                </QuestionContainer>
              )}
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={10}
              >
                <QuestionText fontSize={14} isItalic isBold>
                  <GreyDot />
                  <QuestionLabel
                    isBold
                    isItalic
                    fontSize={14}
                    questionId="XR98ulb45Y"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("XR98ulb45Y")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("XR98ulb45Y")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["XR98ulb45Y"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("XR98ulb45Y")}
                  />
                </QuestionText>
                <QuillEditor
                  id="XR98ulb45Y"
                  disabled={!isEditMode}
                  innerHTML={getValue("XR98ulb45Y")}
                  onChange={(ans) => updateAnswer("XR98ulb45Y", ans)}
                />
              </QuestionContainer>
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="money" />
            <GreyBox>
              {/* <Bold>Asset allocation </Bold>
              describes the share of investments in specific assets. */}
              {parse(
                getStaticContentText(
                  "DAkUL7AGO4",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
            {(getValue("FxwwfzhaRz") === "Yes" ||
              getValue("WIAKEaYnwK") === "Yes") && (
              <GreyBox>
                <InfoToolTip disabled={!showInfoIcon}>
                  {/* [include note only for financial products referred to in
                  Article 6 of Regulation (EU) 2020/852] */}
                  {parse(
                    getStaticContentText(
                      "JlIEUad8hH",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                </InfoToolTip>
                {parse(
                  getStaticContentText(
                    "S6MCrdycwt",
                    staticContent.greyBox,
                    document.language
                  )
                )}
                {/* Taxonomy-aligned activities are expressed as a share of:
                <ul>
                  <li>
                    <div>-</div>
                    <span>turnover</span> reflecting the share of revenue from
                    green activities of investee companies
                  </li>
                  <li>
                    <div>-</div>
                    <span>capital expenditure</span>
                    (CapEx) showing the green investments made by investee
                    companies, e.g. for a transition to a green economy.
                  </li>
                  <li>
                    <div>-</div>
                    <span>operational expenditure</span>
                    (OpEx) reflecting green operational activities of investee
                    companies.
                  </li>
                </ul> */}
              </GreyBox>
            )}
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include a narrative explanation of the investments of the
                    financial product, including the minimum proportion of the
                    investments of the financial product used to meet the
                    environmental or social characteristics promoted by the
                    financial product in accordance with the binding elements of
                    the investment strategy, including the minimum proportion of
                    sustainable investments of the financial product where that
                    financial products commits to making sustainable investments,
                    and the purpose of the remaining proportion of the
                    investments, including a description of any minimum
                    environmental or social safeguards] */}
                    {parse(
                      getStaticContentText(
                        "W09bOvPPaO",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    isBold
                    questionId="5XSVDyoJSj"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("5XSVDyoJSj")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("5XSVDyoJSj")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["5XSVDyoJSj"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("5XSVDyoJSj")}
                  />
                </QuestionText>
                <QuillEditor
                  id="5XSVDyoJSj"
                  disabled={!isEditMode}
                  innerHTML={getValue("5XSVDyoJSj")}
                  onChange={(ans) => updateAnswer("5XSVDyoJSj", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <OrangeBox>
                  <TooltipWrapper>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [Include only the relevant boxes of this sample image and
                      remove irrelevant ones for the financial product] */}
                      {parse(
                        getStaticContentText(
                          "wuQ8oym0ZT",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                  </TooltipWrapper>
                  <UploadWrapper>
                    <AssetAllocationGraph
                      document={document}
                      questionId="DXwIyfNiwj"
                      updateDoc={updateDoc}
                      isEditMode={isEditMode}
                      commonContent={commonContent}
                      mode={mode}
                      updateAns={updateAnswer}
                      article="Article 8"
                      template="Pre-contractual"
                      documentState={documentState}
                    />
                  </UploadWrapper>
                  <UploadFooterNote>
                    <Text fontSize={12}>
                      {/* <Bold>#1 Aligned with E/S characteristics </Bold>
                      includes the investments of the financial product used to
                      attain the environmental or social characteristics promoted
                      by the financial product. */}
                      {parse(
                        getStaticContentText(
                          "2OdRAMO3cJ",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                    </Text>
                  </UploadFooterNote>
                  <UploadFooterNote>
                    <Text fontSize={12}>
                      {/* <Bold>#2 Other </Bold>
                      includes the remaining investments of the financial product
                      which are neither aligned with the environmental or social
                      characteristics, nor are qualified as sustainable
                      investments. */}
                      {parse(
                        getStaticContentText(
                          "LKffYhI5r5",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                    </Text>
                  </UploadFooterNote>
                  {isEditMode && (
                    <QuestionContainer paddingLeft={20} paddingTop={20}>
                      <QuestionText fontSize={14} isItalic isBold>
                        <InfoToolTip disabled={!showInfoIcon}>
                          {/* [include the note below where the financial product
                          commits to making sustainable investments] */}
                          {parse(
                            getStaticContentText(
                              "RLq5DFuF8I",
                              staticContent.infoTooltip,
                              document.language
                            )
                          )}
                        </InfoToolTip>
                        <Highlight>
                          <QuestionLabel
                            fontSize={14}
                            isBold
                            isItalic
                            questionId="WIAKEaYnwK"
                            getQuestionError={getQuestionError}
                            displayQuestions={displayQuestions}
                            language={document.language}
                            showErrorMessage={showErrorMessage}
                          />
                        </Highlight>
                        <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                        <CommentButton
                          hasComments={hasCommentsById("WIAKEaYnwK")}
                          disabled={!showCommentButton}
                          onClick={() => onComment("WIAKEaYnwK")}
                        />
                        <PreviousAnswers
                          disabled={!showPreviousAns}
                          hasAnswers={
                            displayQuestions["WIAKEaYnwK"].previousAnswers
                          }
                          onClick={() => onPreviousAnswersClick("WIAKEaYnwK")}
                        />
                      </QuestionText>
                      <FlexRow>
                        <Checkbox
                          id="20"
                          label={getStaticContentText(
                            "C7uLFog7hl",
                            staticContent.questionAnswerOption,
                            document.language
                          )}
                          disabled={!isEditMode}
                          checked={getValue("WIAKEaYnwK") === "Yes"}
                          onClick={() =>
                            updateAnswer(
                              "WIAKEaYnwK",
                              getValue("WIAKEaYnwK") === "Yes" ? "" : "Yes"
                            )
                          }
                        />
                        <Checkbox
                          id="21"
                          label={getStaticContentText(
                            "bG9v3QJaqt",
                            staticContent.questionAnswerOption,
                            document.language
                          )}
                          marginLeft={60}
                          disabled={!isEditMode}
                          checked={getValue("WIAKEaYnwK") === "No"}
                          onClick={() =>
                            updateAnswer(
                              "WIAKEaYnwK",
                              getValue("WIAKEaYnwK") === "No" ? "" : "No"
                            )
                          }
                        />
                      </FlexRow>
                    </QuestionContainer>
                  )}
                  {getValue("WIAKEaYnwK") === "Yes" && (
                    <>
                      <UploadFooterNote>
                        <Text fontSize={12}>
                          {/* The category{" "}
                          <Bold>#1 Aligned with E/S characteristics </Bold>{" "}
                          covers: */}
                          {parse(
                            getStaticContentText(
                              "rMvl8jkHZQ",
                              staticContent.staticText,
                              document.language
                            )
                          )}
                        </Text>
                      </UploadFooterNote>
                      <UploadFooterNote>
                        <Text fontSize={12}>
                          {/* - The sub-category <Bold>#1A Sustainable</Bold> covers
                          sustainable investments with environmental or social
                          objectives. */}
                          {parse(
                            getStaticContentText(
                              "sAkkEAYGFl",
                              staticContent.staticText,
                              document.language
                            )
                          )}
                        </Text>
                      </UploadFooterNote>
                      <UploadFooterNote>
                        <Text fontSize={12}>
                          {/* - The sub-category #1B Other E/S characteristics covers
                          investments aligned with the environmental or social
                          characteristics that do not qualify as sustainable
                          investments. */}
                          {parse(
                            getStaticContentText(
                              "zuDoNxB6rc",
                              staticContent.staticText,
                              document.language
                            )
                          )}
                        </Text>
                      </UploadFooterNote>
                    </>
                  )}
                </OrangeBox>
              </QuestionContainer>
              {isEditMode && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <Highlight>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        isItalic
                        questionId="3sD4nyprsP"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("3sD4nyprsP")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("3sD4nyprsP")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["3sD4nyprsP"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("3sD4nyprsP")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="22"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("3sD4nyprsP") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "3sD4nyprsP",
                          getValue("3sD4nyprsP") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="23"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("3sD4nyprsP") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "3sD4nyprsP",
                          getValue("3sD4nyprsP") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("3sD4nyprsP") === "Yes" && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [for financial products that use derivatives as defined in
                      Article 2(1), point (29), of Regulation (EU) No 600/2014
                      to attain the environmental or social characteristics they
                      promote, describe how the use of those derivatives meets
                      those characteristics] */}
                      {parse(
                        getStaticContentText(
                          "lBZL6A6Bb8",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      isItalic
                      questionId="cyRXYKJy05"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("cyRXYKJy05")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("cyRXYKJy05")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["cyRXYKJy05"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("cyRXYKJy05")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="cyRXYKJy05"
                    disabled={!isEditMode}
                    innerHTML={getValue("cyRXYKJy05")}
                    onChange={(ans) => updateAnswer("cyRXYKJy05", ans)}
                  />
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("Nxbmqozeah") === "Yes" && (
              <GreyBox style={{ width: "172px" }}>
                <div>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include note only for the financial products referred to in
                    Article 6, first paragraph, of Regulation (EU) 2020/852] */}
                    {parse(
                      getStaticContentText(
                        "dSd5wqOFif",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  {parse(
                    getStaticContentText(
                      "m42RGDs9fh",
                      staticContent.greyBox,
                      document.language
                    )
                  )}

                  {/* To comply with the EU Taxonomy, the criteria for{" "}
                    <Bold> fossil gas </Bold> include limitations on emissions
                    and switching to renewable power or low-carbon fuels by the
                    end of 2035. For <Bold> nuclear energy</Bold>, the criteria
                    include comprehensive safety and waste management rules */}
                </div>
                <br />
                <div>
                  {/* <Bold>Enabling activities </Bold> directly enable other
                  activities to make a substantial contribution to an
                  environmental objective. */}
                  {parse(
                    getStaticContentText(
                      "xpOJgXIrEk",
                      staticContent.greyBox,
                      document.language
                    )
                  )}
                </div>
                <br />
                <div>
                  {parse(
                    getStaticContentText(
                      "H4vt2q5Yg7",
                      staticContent.greyBox,
                      document.language
                    )
                  )}
                  {/* <Bold>Transitional activities are </Bold> activities for which
                  low-carbon alternatives are not yet available and among others
                  have greenhouse gas emission levels corresponding to the best
                  performance. */}
                </div>
              </GreyBox>
            )}
          </>
        }
        right={
          <>
            <QuestionContainer>
              {isEditMode && (
                <>
                  <QuestionText isBold fontSize={14}>
                    <Highlight>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        questionId="Nxbmqozeah"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("Nxbmqozeah")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("Nxbmqozeah")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["Nxbmqozeah"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("Nxbmqozeah")}
                    />
                  </QuestionText>
                  <FlexRow paddingTop={5}>
                    <Checkbox
                      id="24"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("Nxbmqozeah") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "Nxbmqozeah",
                          getValue("Nxbmqozeah") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="25"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("Nxbmqozeah") === "No"}
                      onClick={() => {
                        // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                        clearTaxnomyIncludingChartData();
                        // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                        clearTaxnomyExcludingChartData();
                        updateAnswer(
                          "Nxbmqozeah",
                          getValue("Nxbmqozeah") === "No" ? "" : "No"
                        );
                      }}
                    />
                  </FlexRow>
                </>
              )}
              {getValue("Nxbmqozeah") === "Yes" && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionContainer paddingLeft={0}>
                    <QuestionText fontSize={14} isBold>
                      <SubIcon name="book" />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include a section for the financial products referred to
                        in Article 6, first paragraph, of Regulation (EU) 2020/852
                        and include the graphical representation referred to in
                        Article 15(1), point (a), of this Regulation, the
                        description referred to in Article 15(1), point (b), of
                        this Regulation, a clear explanation as referred to in
                        Article 15(1), point (c), of this Regulation, a narrative
                        explanation as referred to in Article 15(1), point (d), of
                        this Regulation and the information referred to in Article
                        15(2) and (3) of this Regulation] */}
                        {parse(
                          getStaticContentText(
                            "l9cXOPZxTr",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        questionId="UZINb2KcLM"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("UZINb2KcLM")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("UZINb2KcLM")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["UZINb2KcLM"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("UZINb2KcLM")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="UZINb2KcLM"
                      disabled={!isEditMode}
                      innerHTML={getValue("UZINb2KcLM")}
                      onChange={(ans) => updateAnswer("UZINb2KcLM", ans)}
                    />
                  </QuestionContainer>
                  {/* Condition needs to removed in next iteration */}
                  {isNewVersionDocument() && (
                    <QuestionContainer
                      paddingLeft={0}
                      paddingTop={subQuestionTopPadding}
                    >
                      <AvoidBreakInside>
                        <QuestionText fontSize={14} isItalic isBold>
                          <GreyDot />
                          <InfoToolTip disabled={!showInfoIcon}>
                            {/* [include, for financial products that make sustainable
                          investments, a description of the objectives and how
                          the sustainable investments contribute to the
                          sustainable investment objective. For the financial
                          products referred to in Article 6, first paragraph, of
                          Regulation (EU) 2020/852, list the environmental
                          objectives set out in Article 9 of that Regulation to
                          which the sustainable investment underlying the
                          financial product contributes] */}
                            {parse(
                              getStaticContentText(
                                "XKxpjPzM8D",
                                staticContent.infoTooltip,
                                document.language
                              )
                            )}
                          </InfoToolTip>
                          <QuestionLabel
                            fontSize={14}
                            isBold
                            isItalic
                            questionId="uchuH43MmW"
                            getQuestionError={getQuestionError}
                            displayQuestions={displayQuestions}
                            language={document.language}
                            showErrorMessage={showErrorMessage}
                          />
                          <CommentButton
                            hasComments={hasCommentsById("uchuH43MmW")}
                            disabled={!showCommentButton}
                            onClick={() => onComment("uchuH43MmW")}
                          />
                          <PreviousAnswers
                            disabled={!showPreviousAns}
                            hasAnswers={
                              displayQuestions["uchuH43MmW"].previousAnswers
                            }
                            onClick={() => onPreviousAnswersClick("uchuH43MmW")}
                          />
                        </QuestionText>
                        <FlexColumn paddingTop={5}>
                          <FlexRow align="flex-start">
                            <Checkbox
                              id="26"
                              label={getStaticContentText(
                                "C7uLFog7hl",
                                staticContent.questionAnswerOption,
                                document.language
                              )}
                              marginLeft={60}
                              disabled={!isEditMode}
                              checked={getValue("uchuH43MmW") === "Yes"}
                              onClick={() => {
                                // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                                clearTaxnomyIncludingChartData();
                                // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                                clearTaxnomyExcludingChartData();

                                updateAnswer(
                                  "uchuH43MmW",
                                  getValue("uchuH43MmW") === "Yes" ? "" : "Yes"
                                );
                              }}
                            />
                            &nbsp;&nbsp;
                            <InfoToolTip disabled={!showInfoIcon}>
                              {/* [specify below, and details in the graphs of the
                            box] */}
                              {parse(
                                getStaticContentText(
                                  "CoY4NfJSeC",
                                  staticContent.infoTooltip,
                                  document.language
                                )
                              )}
                            </InfoToolTip>
                          </FlexRow>
                          <FlexRow>
                            <Checkbox
                              id="27"
                              label={getQuestionLabel(
                                "RbIqU9Qhdf",
                                displayQuestions,
                                Language?.[document.language]
                              )}
                              marginLeft={120}
                              disabled={
                                !isEditMode || getValue("uchuH43MmW") !== "Yes"
                              }
                              checked={getValue("RbIqU9Qhdf")}
                              onClick={() =>
                                updateAnswer(
                                  "RbIqU9Qhdf",
                                  !getValue("RbIqU9Qhdf")
                                )
                              }
                              mode={mode}
                            />
                            <Checkbox
                              id="28"
                              label={getQuestionLabel(
                                "PbiIANidep",
                                displayQuestions,
                                Language?.[document.language]
                              )}
                              marginLeft={60}
                              disabled={
                                !isEditMode || getValue("uchuH43MmW") !== "Yes"
                              }
                              checked={getValue("PbiIANidep")}
                              onClick={() =>
                                updateAnswer(
                                  "PbiIANidep",
                                  !getValue("PbiIANidep")
                                )
                              }
                              mode={mode}
                            />
                          </FlexRow>
                          <Checkbox
                            id="29"
                            label={getStaticContentText(
                              "bG9v3QJaqt",
                              staticContent.questionAnswerOption,
                              document.language
                            )}
                            marginLeft={60}
                            disabled={!isEditMode}
                            checked={getValue("uchuH43MmW") === "No"}
                            onClick={() => {
                              // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                              clearTaxnomyIncludingChartData();
                              // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                              clearTaxnomyExcludingChartData();

                              updateAnswer(
                                "uchuH43MmW",
                                getValue("uchuH43MmW") === "No" ? "" : "No"
                              );
                            }}
                          />
                        </FlexColumn>
                      </AvoidBreakInside>
                    </QuestionContainer>
                  )}
                  <Note className="no-print">
                    {/* Fossil gas and/or nuclear related activities will only
                        comply with the EU Taxonomy where they contribute to
                        limiting climate change (“climate change mitigation”)
                        and do not significantly harm any EU Taxonomy objective
                        - see explanatory note in the left hand margin. The full
                        criteria for fossil gas and nuclear energy economic
                        activities that comply with the EU Taxonomy are laid
                        down in Commission Delegated Regulation (EU) 2022/1214. */}
                    {parse(
                      getStaticContentText(
                        "c6SZEuPrCC",
                        staticContent.staticText,
                        document.language
                      )
                    )}
                  </Note>
                  <>
                    <OrangeBox
                      padding={spacingIncrement(10)}
                      margin={`${spacingIncrement(10)} 0 ${spacingIncrement(
                        20
                      )} 0`}
                    >
                      {/* NEW FOSSIL FUEL CHART */}
                      <Text wordBreak="normal">
                        {/* The two graphs below show in green the minimum
                          percentage of investments that are aligned with the EU
                          Taxonomy. As there is no appropriate methodology to
                          determine the Taxonomy-alignment of sovereign bonds*,
                          the first graph shows the Taxonomy alignment in
                          relation to all the investments of the financial
                          product including sovereign bonds, while the second
                          graph shows the Taxonomy alignment only in relation to
                          the investments of the financial product other than
                          sovereign bonds. */}
                        <Label isBold isItalic fontSize={12}>
                          {parse(
                            getStaticContentText(
                              "myA5vArbzF",
                              staticContent.staticText,
                              document.language
                            )
                          )}
                        </Label>
                      </Text>
                      <TooltipWrapper>
                        <InfoToolTip disabled={!showInfoIcon}>
                          {/* [only include in the graphs the figures for Taxonomy
                            aligned fossil gas and/or nuclear energy as well as
                            the corresponding legend and the explanatory text in
                            the left hand margin if the financial product makes
                            investments in fossil gas and/or nuclear energy] */}
                          {parse(
                            getStaticContentText(
                              "yqDqLvlJKe",
                              staticContent.infoTooltip,
                              document.language
                            )
                          )}
                        </InfoToolTip>
                      </TooltipWrapper>
                      <GraphContainer>
                        <GraphSubContainer>
                          <FlexColumn>
                            <Text center={true} fontSize={12}>
                              {/* 1. Taxonomy-alignment of investments{" "}
                                <Bold>including sovereign bonds*</Bold> */}
                              {parse(
                                getStaticContentText(
                                  "Ewi0OE6NnT",
                                  staticContent.staticText,
                                  document.language
                                )
                              )}
                            </Text>
                            <FlexRow paddingTop={10}>
                              <Flex flex={1.5}>
                                <FlexColumn>
                                  <GraphInput
                                    staticContent={staticContent}
                                    isEditMode={isEditMode || false}
                                    showChartInputPercentage={
                                      showChartInputPercentage
                                    }
                                    updateAnswer={updateAnswer}
                                    document={document}
                                    booletColor="#809980"
                                    answerId="kdssXcLDwc"
                                    getValue={getValue}
                                    isNumberDisabled={
                                      getValue("uchuH43MmW") === "No"
                                    }
                                    textId="chxQtWvzke"
                                  />

                                  <GraphInput
                                    staticContent={staticContent}
                                    isEditMode={isEditMode || false}
                                    showChartInputPercentage={
                                      showChartInputPercentage
                                    }
                                    updateAnswer={updateAnswer}
                                    document={document}
                                    answerId="IRi8H6XpGo"
                                    booletColor="#4d704d"
                                    getValue={getValue}
                                    isNumberDisabled={
                                      getValue("uchuH43MmW") === "No"
                                    }
                                    textId="Zffi9KIfT5"
                                  />

                                  <GraphInput
                                    staticContent={staticContent}
                                    isEditMode={isEditMode || false}
                                    showChartInputPercentage={
                                      showChartInputPercentage
                                    }
                                    updateAnswer={updateAnswer}
                                    document={document}
                                    booletColor="#0a3300"
                                    answerId="bBomkodkGD"
                                    getValue={getValue}
                                    textId="RXtpexllNu"
                                  />

                                  <GraphInput
                                    staticContent={staticContent}
                                    isEditMode={isEditMode || false}
                                    showChartInputPercentage={
                                      showChartInputPercentage
                                    }
                                    updateAnswer={updateAnswer}
                                    document={document}
                                    booletColor="#D9D9D9"
                                    answerId="SumRuJgTcD"
                                    getValue={getValue}
                                    textId="xgVxV8TR5W"
                                  />
                                </FlexColumn>
                              </Flex>
                              <Flex flex={1}>
                                <PieChart
                                  chartData={[
                                    {
                                      label: getStaticContentText(
                                        "chxQtWvzke",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      value: getValue("kdssXcLDwc"),
                                      colour: "#809980",
                                    },
                                    {
                                      label: getStaticContentText(
                                        "Zffi9KIfT5",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      value: getValue("IRi8H6XpGo"),
                                      colour: "#4d704d",
                                    },
                                    {
                                      label: getStaticContentText(
                                        "RXtpexllNu",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      value: getValue("bBomkodkGD"),
                                      colour: "#0a3300",
                                    },
                                    {
                                      label: getStaticContentText(
                                        "e3PwWJdxbI",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      value: getValue("SumRuJgTcD"),
                                      colour: "#D9D9D9",
                                    },
                                  ]}
                                />
                              </Flex>
                            </FlexRow>
                            <ErrorMessage
                              questionId="kdssXcLDwc"
                              isEditMode={showErrorMessage}
                            >
                              {getQuestionError("kdssXcLDwc")?.message}
                            </ErrorMessage>
                          </FlexColumn>
                        </GraphSubContainer>
                        <GraphSubContainer>
                          <FlexColumn>
                            <Text center={true} fontSize={12}>
                              {/* 1. Taxonomy-alignment of investments{" "}
                                <Bold>excluding sovereign bonds*</Bold> */}
                              {parse(
                                getStaticContentText(
                                  "zv5Zt2eoeC",
                                  staticContent.staticText,
                                  document.language
                                )
                              )}
                            </Text>
                            <FlexRow paddingTop={10}>
                              <Flex flex={1.5}>
                                <FlexColumn>
                                  <GraphInput
                                    staticContent={staticContent}
                                    isEditMode={isEditMode || false}
                                    showChartInputPercentage={
                                      showChartInputPercentage
                                    }
                                    updateAnswer={updateAnswer}
                                    document={document}
                                    booletColor="#809980"
                                    isNumberDisabled={
                                      getValue("uchuH43MmW") === "No"
                                    }
                                    answerId="e5TGU2xAct"
                                    getValue={getValue}
                                    textId="chxQtWvzke"
                                  />
                                  <GraphInput
                                    staticContent={staticContent}
                                    isEditMode={isEditMode || false}
                                    showChartInputPercentage={
                                      showChartInputPercentage
                                    }
                                    updateAnswer={updateAnswer}
                                    document={document}
                                    booletColor="#4d704d"
                                    isNumberDisabled={
                                      getValue("uchuH43MmW") === "No"
                                    }
                                    answerId="vckL7B8rpr"
                                    getValue={getValue}
                                    textId="Zffi9KIfT5"
                                  />
                                  <GraphInput
                                    staticContent={staticContent}
                                    isEditMode={isEditMode || false}
                                    showChartInputPercentage={
                                      showChartInputPercentage
                                    }
                                    updateAnswer={updateAnswer}
                                    document={document}
                                    booletColor="#0a3300"
                                    answerId="KMmwSpnToD"
                                    getValue={getValue}
                                    textId="RXtpexllNu"
                                  />
                                  <GraphInput
                                    staticContent={staticContent}
                                    isEditMode={isEditMode || false}
                                    showChartInputPercentage={
                                      showChartInputPercentage
                                    }
                                    updateAnswer={updateAnswer}
                                    document={document}
                                    booletColor="#D9D9D9"
                                    answerId="YjIPNWGFjJ"
                                    getValue={getValue}
                                    textId="xgVxV8TR5W"
                                  />
                                </FlexColumn>
                              </Flex>
                              <Flex flex={1}>
                                <PieChart
                                  chartData={[
                                    {
                                      label: getStaticContentText(
                                        "chxQtWvzke",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      value: getValue("e5TGU2xAct"),
                                      colour: "#809980",
                                    },
                                    {
                                      label: getStaticContentText(
                                        "Zffi9KIfT5",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      value: getValue("vckL7B8rpr"),
                                      colour: "#4d704d",
                                    },
                                    {
                                      label: getStaticContentText(
                                        "RXtpexllNu",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      value: getValue("KMmwSpnToD"),
                                      colour: "#0a3300",
                                    },
                                    {
                                      label: getStaticContentText(
                                        "e3PwWJdxbI",
                                        staticContent.staticText,
                                        document.language
                                      ),
                                      value: getValue("YjIPNWGFjJ"),
                                      colour: "#D9D9D9",
                                    },
                                  ]}
                                />
                              </Flex>
                            </FlexRow>
                            <Text fontSize={12}>
                              {/* This graph represents{" "} */}
                              {parse(
                                getQuestionLabel(
                                  "pBfhwu3hBz",
                                  displayQuestions,
                                  Language?.[document.language]
                                )?.[0] || ""
                              )}
                              &nbsp;
                              <NumberInput
                                displayStyle={"inline"}
                                value={getValue("pBfhwu3hBz")}
                                id="pBfhwu3hBz"
                                onChange={(val) =>
                                  updateAnswer("pBfhwu3hBz", val)
                                }
                                maxLimit={100}
                              />
                              &nbsp;
                              {/* {Number(getValue("e5TGU2xAct") || 0) +
                                Number(getValue("vckL7B8rpr") || 0) +
                                Number(getValue("KMmwSpnToD") || 0)}
                              % of the total investments. */}
                              {parse(
                                getQuestionLabel(
                                  "pBfhwu3hBz",
                                  displayQuestions,
                                  Language?.[document.language]
                                )?.[1] || ""
                              )}
                            </Text>
                            <ErrorMessage
                              questionId="KMmwSpnToD"
                              isEditMode={showErrorMessage}
                            >
                              {getQuestionError("KMmwSpnToD")?.message}
                            </ErrorMessage>
                          </FlexColumn>
                        </GraphSubContainer>
                      </GraphContainer>
                      <RegulatoryExternalLinkComponent
                        linkId={REGULATORY_EXTERNAL_LINK_ID}
                        hidden={!isEditMode}
                        document={document}
                        staticContent={commonContent}
                      />
                      <Text isItalic isBold fontSize={12}>
                        {/* *For the purpose of these graphs, ‘sovereign bonds’
                          consist of all sovereign exposures */}
                        <Label isItalic isBold fontSize={12}>
                          {parse(
                            getStaticContentText(
                              "nb795yPfcb",
                              staticContent.staticText,
                              document.language
                            )
                          )}
                        </Label>
                      </Text>
                      {/* NEW FOSSIL FUEL CHART */}
                    </OrangeBox>
                    <QuestionContainer
                      paddingLeft={10}
                      paddingTop={subQuestionTopPadding}
                    >
                      <QuestionText fontSize={14} isBold>
                        <InfoToolTip disabled={!showInfoIcon}>
                          {/* [Include section for the financial products referred
                          to in Article 6, first paragraph, of Regulation (EU)
                          2020/852] */}
                          {parse(
                            getStaticContentText(
                              "rlWKclfyKZ",
                              staticContent.infoTooltip,
                              document.language
                            )
                          )}
                        </InfoToolTip>
                        <QuestionLabel
                          fontSize={14}
                          isBold
                          questionId="2qcpr4sTho"
                          getQuestionError={getQuestionError}
                          displayQuestions={displayQuestions}
                          language={document.language}
                          showErrorMessage={showErrorMessage}
                        />
                        <CommentButton
                          hasComments={hasCommentsById("2qcpr4sTho")}
                          disabled={!showCommentButton}
                          onClick={() => onComment("2qcpr4sTho")}
                        />
                        <PreviousAnswers
                          disabled={!showPreviousAns}
                          hasAnswers={
                            displayQuestions["2qcpr4sTho"].previousAnswers
                          }
                          onClick={() => onPreviousAnswersClick("2qcpr4sTho")}
                        />
                      </QuestionText>
                      <QuillEditor
                        id="2qcpr4sTho"
                        disabled={!isEditMode}
                        innerHTML={getValue("2qcpr4sTho")}
                        onChange={(ans) => updateAnswer("2qcpr4sTho", ans)}
                      />
                    </QuestionContainer>
                  </>
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("IChvD8Nvi3") === "Yes" && (
              <GreyBox>
                <InfoToolTip disabled={!showInfoIcon}>
                  {/* [include note for financial products referred to in Article 6,
                  first paragraph, of Regulation (EU) 2020/852 that invest in
                  environmental economic activities that are not environmentally
                  sustainable economic activities] */}
                  {parse(
                    getStaticContentText(
                      "Bo1gmDUk4k",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                </InfoToolTip>
                <RelativeContainer>
                  <Block>
                    <Image
                      name="noBook"
                      height={40}
                      width={40}
                      style={{ float: "left", paddingRight: 10 }}
                    />
                    {/* are sustainable investments with an environmental objective
                    that <Bold> do not take into account the criteria </Bold>
                    for environmentally sustainable economic activities under
                    the EU Taxonomy. */}
                    {parse(
                      getStaticContentText(
                        "A4OgZPcqQH",
                        staticContent.greyBox,
                        document.language
                      )
                    )}
                  </Block>
                </RelativeContainer>
              </GreyBox>
            )}
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            {isEditMode && (
              <>
                <QuestionText isBold fontSize={14}>
                  <Highlight>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="IChvD8Nvi3"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("IChvD8Nvi3")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("IChvD8Nvi3")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["IChvD8Nvi3"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("IChvD8Nvi3")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="30"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("IChvD8Nvi3") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "IChvD8Nvi3",
                        getValue("IChvD8Nvi3") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="31"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("IChvD8Nvi3") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "IChvD8Nvi3",
                        getValue("IChvD8Nvi3") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </>
            )}
            {getValue("IChvD8Nvi3") === "Yes" && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <SubIcon name="noBook" />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include section only for the financial products referred to
                    in Article 6, first paragraph, of Regulation (EU) 2020/852
                    where the financial product invests in economic activities
                    that are not environmentally sustainable economic activities
                    and explain why the financial product invests in sustainable
                    investments with an environmental objective in economic
                    activities that are not Taxonomy-aligned] */}
                    {parse(
                      getStaticContentText(
                        "Uiz3Y4Obcz",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isBold
                    questionId="k1pkKwN18q"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("k1pkKwN18q")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("k1pkKwN18q")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["k1pkKwN18q"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("k1pkKwN18q")}
                  />
                </QuestionText>
                <QuillEditor
                  id="k1pkKwN18q"
                  disabled={!isEditMode}
                  innerHTML={getValue("k1pkKwN18q")}
                  onChange={(ans) => updateAnswer("k1pkKwN18q", ans)}
                />
              </QuestionContainer>
            )}
            {isEditMode && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <Highlight>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="6vIoH2f3ND"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("6vIoH2f3ND")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("6vIoH2f3ND")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["6vIoH2f3ND"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("6vIoH2f3ND")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="32"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("6vIoH2f3ND") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "6vIoH2f3ND",
                        getValue("6vIoH2f3ND") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="33"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("6vIoH2f3ND") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "6vIoH2f3ND",
                        getValue("6vIoH2f3ND") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("6vIoH2f3ND") === "Yes" && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <SubIcon name="users" />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include section only where the financial product includes
                    sustainable investments with a social objective] */}
                    {parse(
                      getStaticContentText(
                        "ZSvNS7nMIk",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isBold
                    questionId="SGOloZPpB2"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("SGOloZPpB2")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("SGOloZPpB2")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["SGOloZPpB2"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("SGOloZPpB2")}
                  />
                </QuestionText>
                <QuillEditor
                  id="SGOloZPpB2"
                  disabled={!isEditMode}
                  innerHTML={getValue("SGOloZPpB2")}
                  onChange={(ans) => updateAnswer("SGOloZPpB2", ans)}
                />
              </QuestionContainer>
            )}
            <QuestionContainer
              paddingLeft={0}
              paddingTop={subQuestionTopPadding}
            >
              <QuestionText isBold fontSize={14}>
                <SubIcon name="noMoney" />
                <InfoToolTip disabled={!showInfoIcon}>
                  {/* [provide a description of the above and explain how the
                  proportion and use of such investments does not affect the
                  delivery of the sustainable investment objective] */}
                  {parse(
                    getStaticContentText(
                      "sfLVQHirIS",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                </InfoToolTip>
                <QuestionLabel
                  fontSize={14}
                  isBold
                  questionId="jKR9331tZq"
                  getQuestionError={getQuestionError}
                  displayQuestions={displayQuestions}
                  language={document.language}
                  showErrorMessage={showErrorMessage}
                />
                <CommentButton
                  hasComments={hasCommentsById("jKR9331tZq")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("jKR9331tZq")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["jKR9331tZq"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("jKR9331tZq")}
                />
              </QuestionText>
              <QuillEditor
                id="jKR9331tZq"
                disabled={!isEditMode}
                innerHTML={getValue("jKR9331tZq")}
                onChange={(ans) => updateAnswer("jKR9331tZq", ans)}
              />
            </QuestionContainer>
            {isEditMode && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold>
                  <Highlight>
                    <QuestionLabel
                      isBold
                      questionId="Bl2rvUjBFZ"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <CommentButton
                    hasComments={hasCommentsById("Bl2rvUjBFZ")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("Bl2rvUjBFZ")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["Bl2rvUjBFZ"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("Bl2rvUjBFZ")}
                  />
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="34"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("Bl2rvUjBFZ") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "Bl2rvUjBFZ",
                        getValue("Bl2rvUjBFZ") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="35"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("Bl2rvUjBFZ") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "Bl2rvUjBFZ",
                        getValue("Bl2rvUjBFZ") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("Bl2rvUjBFZ") === "Yes" && (
              <>
                <MainIcon name="graphs" />
                <GreyBox>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include note for financial products where an index has been
                    designated as a reference benchmark for the purpose of
                    attaining the environmental or social characteristics
                    promoted by the financial product] */}
                    {parse(
                      getStaticContentText(
                        "l8uT1FAjl8",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  {/* <Bold>Reference benchmarks </Bold>
                  are indexes to measure whether the financial product attains
                  the environmental or social characteristics that they promote. */}
                  {parse(
                    getStaticContentText(
                      "FDBFIszTTu",
                      staticContent.greyBox,
                      document.language
                    )
                  )}
                </GreyBox>
              </>
            )}
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            {getValue("Bl2rvUjBFZ") === "Yes" && (
              <>
                <QuestionContainer paddingLeft={0}>
                  <QuestionText isBold>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [include section where an index has been designated as a
                      reference benchmark for the purpose of attaining the
                      environmental or social characteristics promoted by the
                      financial product and indicate where the methodology used
                      for the calculation of the designated index can be found] */}
                      {parse(
                        getStaticContentText(
                          "8P7e0ykfmY",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <QuestionLabel
                      isBold
                      questionId="zXD5cGDY7J"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("zXD5cGDY7J")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("zXD5cGDY7J")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["zXD5cGDY7J"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("zXD5cGDY7J")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="zXD5cGDY7J"
                    disabled={!isEditMode}
                    innerHTML={getValue("zXD5cGDY7J")}
                    onChange={(ans) => updateAnswer("zXD5cGDY7J", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      questionId="spXNwcoOAY"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("spXNwcoOAY")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("spXNwcoOAY")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["spXNwcoOAY"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("spXNwcoOAY")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="spXNwcoOAY"
                    disabled={!isEditMode}
                    innerHTML={getValue("spXNwcoOAY")}
                    onChange={(ans) => updateAnswer("spXNwcoOAY", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      questionId="XZpsrji3lb"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("XZpsrji3lb")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("XZpsrji3lb")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["XZpsrji3lb"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("XZpsrji3lb")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="XZpsrji3lb"
                    disabled={!isEditMode}
                    innerHTML={getValue("XZpsrji3lb")}
                    onChange={(ans) => updateAnswer("XZpsrji3lb", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      questionId="7vE3YFBsBJ"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("7vE3YFBsBJ")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("7vE3YFBsBJ")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["7vE3YFBsBJ"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("7vE3YFBsBJ")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="7vE3YFBsBJ"
                    disabled={!isEditMode}
                    innerHTML={getValue("7vE3YFBsBJ")}
                    onChange={(ans) => updateAnswer("7vE3YFBsBJ", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      questionId="AvnTkmodQS"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("AvnTkmodQS")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("AvnTkmodQS")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["AvnTkmodQS"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("AvnTkmodQS")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="AvnTkmodQS"
                    disabled={!isEditMode}
                    innerHTML={getValue("AvnTkmodQS")}
                    onChange={(ans) => updateAnswer("AvnTkmodQS", ans)}
                  />
                </QuestionContainer>
              </>
            )}
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="computer" />
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            <QuestionText isBold>
              <InfoToolTip disabled={!showInfoIcon}>
                {/* [include a hyperlink to the website referred to in Article 23 of
                this Regulation] */}
                {parse(
                  getStaticContentText(
                    "K4DlpoX11R",
                    staticContent.infoTooltip,
                    document.language
                  )
                )}
              </InfoToolTip>
              <QuestionLabel
                isBold
                questionId="rD59vfp1yw"
                getQuestionError={getQuestionError}
                displayQuestions={displayQuestions}
                language={document.language}
                showErrorMessage={showErrorMessage}
              />
              <CommentButton
                hasComments={hasCommentsById("rD59vfp1yw")}
                disabled={!showCommentButton}
                onClick={() => onComment("rD59vfp1yw")}
              />
              <PreviousAnswers
                disabled={!showPreviousAns}
                hasAnswers={displayQuestions["rD59vfp1yw"].previousAnswers}
                onClick={() => onPreviousAnswersClick("rD59vfp1yw")}
              />
            </QuestionText>
            <QuillEditor
              id="rD59vfp1yw"
              disabled={!isEditMode}
              innerHTML={getValue("rD59vfp1yw")}
              onChange={(ans) => updateAnswer("rD59vfp1yw", ans)}
            />
          </QuestionContainer>
        }
      />
    </Container>
  );
};

export default ArticleEightPreContractual;
