import { TableNumberInput } from "../../features/templates/CommonComponents/TableInput/NumberInput";
import { Flex } from "../../features/templates/CommonComponents/common.styles";
import { getStaticContentText } from "../../features/templates/DocumentEditor/service";
import { ASSET_ALLOCATION_PERCENTAGE_KEY } from "../../lib/constants";
import { DocumentState } from "../../types/document-details";
import { DocumentCommonContent } from "../../types/document-question";

const AssetAllocationPercentageInput = ({
  documentState,
  updateAns,
  allQuestions,
  article,
  language,
  commonContent,
}: {
  documentState: DocumentState;
  updateAns: (key: string, value: string) => void;
  allQuestions: { id: string; label: string; labelKey: string }[];
  article: string;
  language: string;
  commonContent: DocumentCommonContent;
}) => {
  return (
    <div
      style={{
        width: article === "Article 9" ? "calc(100% + 170px)" : "100%",
        zIndex: 0,
      }}
    >
      <div style={{ paddingLeft: "15px" }}>
        <div style={{ background: "#fae8d5" }}>
          <div style={{ borderTop: "1px solid #dbd9d9", paddingTop: "25px" }}>
            <Flex flexWrap="wrap" alignItems="end">
              {allQuestions.map((question) => {
                return (
                  <div
                    style={{ width: "20%", padding: "10px" }}
                    key={question.id}
                    className="column"
                  >
                    <label htmlFor="">
                      {getStaticContentText(
                        question.labelKey,
                        commonContent.assetAllocationBlocks,
                        language
                      )}
                    </label>
                    <TableNumberInput
                      id={`assets-${question.id}`}
                      placeholder="00.00"
                      backgroundColor="#FFF"
                      maxLimit={100}
                      onChange={(v) => {
                        const value = {
                          ...documentState?.[ASSET_ALLOCATION_PERCENTAGE_KEY],
                          [question.id]: v === "0.00" ? undefined : v,
                        };
                        updateAns(ASSET_ALLOCATION_PERCENTAGE_KEY, value);
                      }}
                      value={
                        documentState?.[ASSET_ALLOCATION_PERCENTAGE_KEY]?.[
                          question.id
                        ]
                      }
                    />
                  </div>
                );
              })}
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetAllocationPercentageInput;
