import React from "react";
import { ImageProps } from "../types";

const NoBook: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="67.5" cy="67.5" r="67.5" fill="#9D9D9C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5003 21L66.3889 27.4992L69.6383 28.5549L68.5003 21Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5003 21L70.6116 27.4992L67.3623 28.5549L68.5003 21Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9989 25.7221H68.1654V29.1389L74.9989 25.7221Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9989 25.7221L69.4701 29.7389L67.4621 26.974L74.9989 25.7221Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5167 33.3616L70.4053 26.8631L67.1555 27.9189L72.5167 33.3616Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5167 33.3616L66.9885 29.3449L68.9966 26.5807L72.5167 33.3616Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.0016 25.7221H68.8351V29.1389L62.0016 25.7221Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.0016 25.7221L67.5304 29.7389L69.5384 26.974L62.0016 25.7221Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.4838 33.3616L66.5952 26.8631L69.8451 27.9189L64.4838 33.3616Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.4838 33.3616L70.012 29.3449L68.0039 26.5807L64.4838 33.3616Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5003 103.002L66.3889 109.501L69.6383 110.557L68.5003 103.002Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5003 103.002L70.6116 109.501L67.3623 110.557L68.5003 103.002Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9989 107.724H68.1654V111.141L74.9989 107.724Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9989 107.724L69.4701 111.741L67.4621 108.976L74.9989 107.724Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5167 115.364L70.4053 108.865L67.1555 109.921L72.5167 115.364Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5167 115.364L66.9885 111.347L68.9966 108.583L72.5167 115.364Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.0016 107.724H68.8351V111.141L62.0016 107.724Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.0016 107.724L67.5304 111.741L69.5384 108.976L62.0016 107.724Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.4838 115.364L66.5952 108.865L69.8451 109.921L64.4838 115.364Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.4838 115.364L70.012 111.347L68.0039 108.583L64.4838 115.364Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4992 62.0011L25.3878 68.5002L28.6372 69.556L27.4992 62.0011Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4992 62.0011L29.6106 68.5002L26.3613 69.556L27.4992 62.0011Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9979 66.723H27.1644V70.1398L33.9979 66.723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9979 66.723L28.4691 70.7398L26.4611 67.9749L33.9979 66.723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5156 74.3629L29.4042 67.8644L26.1544 68.9201L31.5156 74.3629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5156 74.3629L25.9874 70.3461L27.9955 67.582L31.5156 74.3629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 66.723H27.8335V70.1398L21 66.723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 66.723L26.5288 70.7398L28.5368 67.9749L21 66.723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4824 74.3629L25.5938 67.8644L28.8437 68.9201L23.4824 74.3629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4824 74.3629L29.0106 70.3461L27.0025 67.582L23.4824 74.3629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9834 38.8559L49.5115 34.8392L47.5035 32.0751L43.9834 38.8559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9834 38.8559L46.0948 32.3568L49.3446 33.4125L43.9834 38.8559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.501 31.2149L47.0299 35.2316L49.0379 32.4675L41.501 31.2149Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.501 31.2149H48.3345V34.6317L41.501 31.2149Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9996 26.4928L45.8882 32.992L49.1376 34.0477L47.9996 26.4928Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9997 26.4928L50.1111 32.992L46.8618 34.0477L47.9997 26.4928Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0161 38.8559L46.488 34.8392L48.496 32.0751L52.0161 38.8559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0161 38.8559L49.9048 32.3568L46.6549 33.4125L52.0161 38.8559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4984 31.2149L48.9696 35.2316L46.9615 32.4675L54.4984 31.2149Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4984 31.2149H47.6649V34.6317L54.4984 31.2149Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.008 53.8623L34.8966 47.3638L31.6473 48.4196L37.008 53.8623Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.008 53.8623L31.4799 49.8456L33.4879 47.0815L37.008 53.8623Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9752 53.8623L34.504 49.8456L32.4953 47.0815L28.9752 53.8623Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9752 53.8623L31.0873 47.3638L34.3366 48.4196L28.9752 53.8623Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4929 46.2225L32.0211 50.2392L34.0291 47.4744L26.4929 46.2225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4929 46.2225H33.3264V49.6392L26.4929 46.2225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.492 46.2225L33.9639 50.2392L31.9551 47.4744L39.492 46.2225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.492 46.2225H32.6584V49.6392L39.492 46.2225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9916 41.5005L35.1037 47.9997L31.8538 49.0554L32.9916 41.5005Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9916 41.5005L30.8802 47.9997L34.1296 49.0554L32.9916 41.5005Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.492 87.2235H32.6584V90.6403L39.492 87.2235Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.492 87.2235L33.9639 91.2403L31.9551 88.4754L39.492 87.2235Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.008 94.8634L34.8966 88.3649L31.6473 89.4206L37.008 94.8634Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.008 94.8634L31.4799 90.8467L33.4879 88.0825L37.008 94.8634Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9752 94.8634L34.504 90.8467L32.4953 88.0825L28.9752 94.8634Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9752 94.8634L31.0873 88.3649L34.3366 89.4206L28.9752 94.8634Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9916 82.5016L35.1037 89.0008L31.8538 90.0565L32.9916 82.5016Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9916 82.5016L30.8802 89.0008L34.1296 90.0565L32.9916 82.5016Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4929 87.2235H33.3264V90.6403L26.4929 87.2235Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4929 87.2235L32.0211 91.2403L34.0291 88.4754L26.4929 87.2235Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4984 102.23H47.6649V105.647L54.4984 102.23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4984 102.23L48.9696 106.247L46.9615 103.483L54.4984 102.23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0161 109.871L49.9048 103.372L46.6549 104.428L52.0161 109.871Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0161 109.872L46.488 105.855L48.496 103.091L52.0161 109.872Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9834 109.872L49.5115 105.855L47.5035 103.091L43.9834 109.872Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9834 109.871L46.0948 103.372L49.3446 104.428L43.9834 109.871Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9997 97.5097L50.1111 104.008L46.8618 105.065L47.9997 97.5097Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9996 97.5097L45.8882 104.008L49.1376 105.065L47.9996 97.5097Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.501 102.23H48.3345V105.647L41.501 102.23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.501 102.23L47.0299 106.247L49.0379 103.483L41.501 102.23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.501 62.0011L111.613 68.5002L108.363 69.556L109.501 62.0011Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.501 62.0011L107.39 68.5002L110.639 69.556L109.501 62.0011Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.003 66.723H109.836V70.1398L103.003 66.723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.003 66.723L108.531 70.7398L110.539 67.9749L103.003 66.723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.485 74.3629L107.596 67.8644L110.846 68.9201L105.485 74.3629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.485 74.3629L111.013 70.3461L109.005 67.582L105.485 74.3629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116 66.723H109.167V70.1398L116 66.723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116 66.723L110.471 70.7398L108.463 67.9749L116 66.723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.518 74.3629L111.406 67.8644L108.156 68.9201L113.518 74.3629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.518 74.3629L107.99 70.3461L109.998 67.582L113.518 74.3629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.0172 38.8559L87.489 34.8392L89.497 32.0751L93.0172 38.8559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.0172 38.8559L90.9058 32.3568L87.6559 33.4125L93.0172 38.8559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.4994 31.2149L89.9706 35.2316L87.9626 32.4675L95.4994 31.2149Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.4995 31.2149H88.666V34.6317L95.4995 31.2149Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.0007 26.4928L91.1121 32.992L87.8628 34.0477L89.0007 26.4928Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.0008 26.4928L86.8894 32.992L90.1387 34.0477L89.0008 26.4928Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.9843 38.8559L90.5125 34.8392L88.5044 32.0751L84.9843 38.8559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.9843 38.8559L87.0957 32.3568L90.3455 33.4125L84.9843 38.8559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.5021 31.2149L88.031 35.2316L90.039 32.4675L82.5021 31.2149Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.5021 31.2149H89.3356V34.6317L82.5021 31.2149Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.9924 53.8623L102.104 47.3638L105.353 48.4196L99.9924 53.8623Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.9924 53.8623L105.521 49.8456L103.513 47.0815L99.9924 53.8623Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.025 53.8623L102.496 49.8456L104.505 47.0815L108.025 53.8623Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.025 53.8623L105.913 47.3638L102.664 48.4196L108.025 53.8623Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.508 46.2225L104.979 50.2392L102.971 47.4744L110.508 46.2225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.508 46.2225H103.674V49.6392L110.508 46.2225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.5085 46.2225L103.037 50.2392L105.045 47.4744L97.5085 46.2225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.5085 46.2225H104.342V49.6392L97.5085 46.2225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.009 41.5005L101.897 47.9997L105.147 49.0554L104.009 41.5005Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.009 41.5005L106.12 47.9997L102.871 49.0554L104.009 41.5005Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.5085 87.2235H104.342V90.6403L97.5085 87.2235Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.5085 87.2235L103.037 91.2403L105.045 88.4754L97.5085 87.2235Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.9924 94.8634L102.104 88.3649L105.353 89.4206L99.9924 94.8634Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.9924 94.8634L105.521 90.8467L103.513 88.0825L99.9924 94.8634Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.025 94.8634L102.496 90.8467L104.505 88.0825L108.025 94.8634Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.025 94.8634L105.913 88.3649L102.664 89.4206L108.025 94.8634Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.009 82.5016L101.897 89.0008L105.147 90.0565L104.009 82.5016Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.009 82.5016L106.12 89.0008L102.871 90.0565L104.009 82.5016Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.508 87.2235H103.674V90.6403L110.508 87.2235Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.508 87.2235L104.979 91.2403L102.971 88.4754L110.508 87.2235Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.5021 102.23H89.3356V105.647L82.5021 102.23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.5021 102.23L88.031 106.247L90.039 103.483L82.5021 102.23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.9843 109.871L87.0957 103.372L90.3455 104.428L84.9843 109.871Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.9843 109.872L90.5125 105.855L88.5044 103.091L84.9843 109.872Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.0172 109.872L87.489 105.855L89.497 103.091L93.0172 109.872Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.0172 109.871L90.9058 103.372L87.6559 104.428L93.0172 109.871Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.0008 97.5097L86.8894 104.008L90.1387 105.065L89.0008 97.5097Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.0007 97.5097L91.1121 104.008L87.8628 105.065L89.0007 97.5097Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.4995 102.23H88.666V105.647L95.4995 102.23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.4994 102.23L89.9706 106.247L87.9626 103.483L95.4994 102.23Z"
        fill="white"
      />
      <path
        d="M93.6149 78.0127C93.6052 77.9902 93.5898 77.954 93.5742 77.9195C93.5943 77.965 93.6073 77.9951 93.6149 78.0127Z"
        fill="#3C3C3B"
      />
      <path
        d="M93.6149 78.0128C93.6279 78.0429 93.6255 78.0368 93.6149 78.0128V78.0128Z"
        fill="#3C3C3B"
      />
      <path
        d="M92.138 74.61C92.3238 74.611 92.5099 74.6117 92.6996 74.6117C93.299 74.6117 93.6806 73.8325 93.3854 73.2285L92.0209 70.4612C92.1667 70.4606 92.3123 70.4595 92.4596 70.4595C93.0339 70.4595 93.4273 69.7298 93.1691 69.1265L88.9209 57.5205C88.7881 57.2109 88.5103 57.0081 88.2136 57.0081C82.7744 57.0081 77.652 58.1904 73.7886 60.3385C71.1585 61.8004 69.2973 63.611 68.3142 65.614C67.3314 63.611 65.4699 61.8008 62.8401 60.3385C58.9772 58.1908 53.8546 57.0081 48.4159 57.0081C48.1224 57.0081 47.8387 57.2091 47.7094 57.5177L43.4315 69.1237C43.1705 69.7267 43.5655 70.4596 44.14 70.4596C44.3672 70.4596 44.5924 70.4606 44.816 70.4623L43.4522 73.2286C43.1655 73.8343 43.5318 74.6117 44.1379 74.6117C44.3273 74.6117 44.5131 74.611 44.6989 74.6103L43.3001 77.8337C43.0184 78.4916 43.4998 79.2665 44.1276 79.1625L63.7186 77.096C64.1352 79.3277 65.883 79.2251 68.3294 79.2251C70.7761 79.2251 72.5237 79.3277 72.9403 77.096L92.5328 79.1625C93.3849 79.319 93.6192 78.0233 93.5366 77.8337L92.138 74.61ZM46.3426 68.6411C46.2438 68.6169 46.1432 68.6148 46.0458 68.6345C45.8352 68.63 45.624 68.6262 45.411 68.6231L48.9002 58.8596C53.9255 58.9269 58.6188 60.0354 62.1596 62.0042C65.4385 63.8271 67.3239 66.1477 67.5203 68.5788L67.5254 75.4512C64.0417 72.4503 56.1049 68.8849 46.3423 68.641L46.3426 68.6411ZM90.7634 68.6304C90.6844 68.6176 90.6036 68.6193 90.5238 68.6349C80.0114 68.8643 72.3384 72.0479 69.1196 75.4144L69.106 68.579C69.3027 66.1475 71.1878 63.8273 74.4674 62.0044C78.0084 60.0359 82.7008 58.9271 87.726 58.8594L91.1907 68.6229C91.0475 68.6253 90.9055 68.6281 90.7632 68.6305L90.7634 68.6304Z"
        fill="#3C3C3B"
      />
      <path
        d="M93.5369 77.8332C93.5418 77.8445 93.5455 77.8534 93.5508 77.8636C93.5428 77.8456 93.5369 77.8332 93.5369 77.8332Z"
        fill="#3C3C3B"
      />
      <circle cx="68" cy="68" r="58.5" stroke="#D2D2D2" strokeWidth="9" />
      <line
        x1="23.818"
        y1="105.818"
        x2="105.818"
        y2="23.818"
        stroke="#D2D2D2"
        strokeWidth="9"
      />
    </svg>
  );
};

export default NoBook;
