function loadScript(src: string, id?: string) {
  const tag = document.createElement("script");
  tag.async = false;
  id && tag.setAttribute("id", id);
  tag.src = src;
  document.getElementsByTagName("body")[0].appendChild(tag);
}

function injectScripts() {
  loadScript(
    "https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js"
  );
  !window &&
    loadScript(`/js/vendor/jquery.min.js?t=${new Date().getTime().toString()}`);
  loadScript(
    `/js/vendor/vendor.min.js?t=${new Date().getTime().toString()}`,
    "vendorJs"
  );
  loadScript(`/js/load.min.js?t=${new Date().getTime().toString()}`);
}

export default injectScripts;
