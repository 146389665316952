import React from "react";
import { ImageProps } from "../types";

const UrlLink: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M7.67245 1.41421L5.83179 3.25488L6.69891 4.122L8.53957 2.28133C9.83828 0.982627 11.8304 0.863928 12.984 2.01541C14.1376 3.16685 14.0066 5.16106 12.7181 6.45984L10.8774 8.30051L11.7445 9.16763L13.5852 7.32696C15.3625 5.54962 15.4811 2.77844 13.8511 1.14807C12.2212 -0.4819 9.44979 -0.361193 7.6726 1.41414L7.67245 1.41421Z"
        fill="#ADB5BD"
      />
      <path
        d="M6.45117 12.7246C5.83898 13.3485 5.01667 13.7224 4.14411 13.7738C3.3519 13.839 2.57108 13.5525 2.00896 12.9904C1.44684 12.4283 1.1604 11.6475 1.22559 10.8553C1.27695 9.98274 1.6509 9.16043 2.27478 8.54824L4.11544 6.70757L3.24832 5.84045L1.40766 7.68112V7.68098C0.568476 8.50803 0.0670202 9.61766 0.000549058 10.7939V11.0209C-0.017855 12.1219 0.427133 13.1797 1.22676 13.9367C2.02642 14.6936 3.10723 15.0798 4.20551 15.001C5.38179 14.9346 6.49146 14.4329 7.31847 13.5939L9.15914 11.7533L8.29202 10.8862L6.45117 12.7246Z"
        fill="#ADB5BD"
      />
      <path
        d="M3.98938 10.1423L10.1328 3.99884L11.0005 4.86654L4.85708 11.01L3.98938 10.1423Z"
        fill="#ADB5BD"
      />
    </svg>
  );
};

export default UrlLink;
