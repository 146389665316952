import React, { ReactElement, useState } from "react";
import { Config, usePopperTooltip } from "react-popper-tooltip";
import { ToolTipContainer } from "./styles";

interface Props {
  triggerElement: ReactElement;
  width?: number;
}

const Tooltip: React.FC<Props & Config> = ({
  children,
  triggerElement,
  width,
  ...config
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: "hover",
    placement: "auto",
    closeOnOutsideClick: false,
    visible: isVisible,
    onVisibleChange: setIsVisible,
    ...config,
  });
  return (
    <>
      <div className="field-info" ref={setTriggerRef}>
        {triggerElement}
      </div>
      {visible && (
        <ToolTipContainer
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
          width={width}
        >
          <div
            {...getArrowProps({
              className: "tooltip-arrow",
            })}
          />
          {children}
        </ToolTipContainer>
      )}
    </>
  );
};
export default Tooltip;
