import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";
import { getQuestionLabel } from "../../DocumentEditor/service";
import {
  DisplayQuestion,
  Language,
  QuestionError,
} from "../../../../types/document-question";
import { ErrorMessageStyled } from "../../DocumentEditor/styles";
import parse from "html-react-parser";
import { VALIDATION_ERROR_COMPONENT_CLASS_NAME } from "../../../../lib/constants";

const QuestionLabelStyled = styled.div<{
  fontSize?: number;
  isBold?: boolean;
  isItalic?: boolean;
}>`
  display: inline-block;
  font-family: "Calibri", "Arial" !important;
  font-size: ${({ fontSize }) => spacingIncrement(fontSize ? fontSize : 16)};
`;

type LabelProps = {
  fontSize?: number;
  isBold?: boolean;
  isItalic?: boolean;
};

export const Label: React.FC<LabelProps & { id?: string }> = ({
  fontSize,
  id,
  isBold,
  isItalic,
  children,
}) => {
  if (!isBold && !isItalic) {
    return (
      <QuestionLabelStyled id={id} fontSize={fontSize}>
        {children}
      </QuestionLabelStyled>
    );
  }

  if (isBold && !isItalic) {
    return (
      <QuestionLabelStyled id={id} fontSize={fontSize} isBold>
        <strong>{children}</strong>
      </QuestionLabelStyled>
    );
  }

  if (!isBold && isItalic) {
    return (
      <QuestionLabelStyled id={id} fontSize={fontSize} isItalic>
        <em>{children}</em>
      </QuestionLabelStyled>
    );
  }

  if (isBold && isItalic) {
    return (
      <QuestionLabelStyled id={id} fontSize={fontSize} isBold isItalic>
        <strong>
          <em>{children}</em>
        </strong>
      </QuestionLabelStyled>
    );
  }

  return (
    <QuestionLabelStyled id={id} fontSize={fontSize}>
      {children}
    </QuestionLabelStyled>
  );
};

type Props = LabelProps & {
  questionId: string;
  displayQuestions: DisplayQuestion;
  language: keyof typeof Language;
  showErrorMessage: boolean;
  getQuestionError: (questionId: string) => QuestionError | undefined;
};

export const ErrorMessage: React.FC<{
  marginLeft?: number;
  questionId: string | string[];
  isEditMode?: boolean;
}> = ({ marginLeft, children, isEditMode }) => {
  return (
    <ErrorMessageStyled
      className={VALIDATION_ERROR_COMPONENT_CLASS_NAME}
      marginLeft={marginLeft}
      isEditMode={isEditMode}
    >
      {children}
    </ErrorMessageStyled>
  );
};

export const QuestionLabel: React.FC<Props> = ({
  fontSize,
  isBold,
  isItalic,
  questionId,
  displayQuestions,
  language,
  showErrorMessage,
  getQuestionError,
}) => {
  const question = getQuestionLabel(
    questionId,
    displayQuestions,
    Language?.[language]
  );
  return (
    <Label
      id={`question-label-${questionId}`}
      fontSize={fontSize}
      isBold={isBold}
      isItalic={isItalic}
    >
      {parse(question as string)}
      <ErrorMessage questionId={questionId} isEditMode={showErrorMessage}>
        {getQuestionError(questionId)?.message}
      </ErrorMessage>
    </Label>
  );
};

export const AnnexText = styled.span`
  padding-top: ${spacingIncrement(30)};
  padding-bottom: ${spacingIncrement(40)};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  line-height: ${spacingIncrement(14)};
  justify-content: center;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: ${spacingIncrement(14)};
  gap: ${spacingIncrement(5)};
`;
