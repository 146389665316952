import React from "react";
import { BreadCrumbDetail } from "../../types/admin/breadCrumb";
const BreadCrumbComponent: React.FC<BreadCrumbDetail> = ({
  historyLinks,
  onLinkClick,
}) => {
  return (
    <>
      {historyLinks && historyLinks.length > 0 && (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {historyLinks.map((value, index) => {
              return (
                <li
                  key={index}
                  className={`breadcrumb__item ${
                    value.isCurrent ? "active" : ""
                  }`}
                  onClick={() => {
                    onLinkClick(value);
                  }}
                >
                  <button
                    className="buttonAsLink"
                    style={{ cursor: "pointer" }}
                    disabled={value.isCurrent || !value.url ? true : false}
                  >
                    {value.linkName}
                  </button>
                </li>
              );
            })}
            {/* <li className="breadcrumb__item active" aria-current="page">
            {currentPage}
          </li> */}
          </ol>
        </nav>
      )}
    </>
  );
};
export default BreadCrumbComponent;
