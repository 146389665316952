import { Header } from "./types";

export function getSortState(colList: Header[], allowSortOn: string[]) {
  const state: any = {};
  colList.forEach((col, index) => {
    if (
      allowSortOn.find((colName) => {
        return colName === col.colName;
      })
    ) {
      state[col.colName] = { ...col };
    }
  });
  return state;
}
export function manageSort(prevState: any, colName: string) {
  const newState = prevState;
  newState[colName].sortDir
    ? newState[colName].sortDir === "ASC"
      ? (newState[colName].sortDir = "DESC") &&
        !newState[colName].sortSequence &&
        (newState[colName].sortSequence = getSortSequence(newState))
      : (newState[colName].sortDir = "") ||
        (newState[colName].sortSequence = null)
    : (newState[colName].sortDir = "ASC") &&
      !newState[colName].sortSequence &&
      (newState[colName].sortSequence = getSortSequence(newState));
  return { ...newState };
}
function getSortSequence(newState: { value: { sortSequence: number } }) {
  let avalilableSequence = 0;
  Object.values(newState).forEach((value) => {
    avalilableSequence =
      value.sortSequence && value.sortSequence > avalilableSequence
        ? value.sortSequence
        : avalilableSequence;
  });
  return avalilableSequence + 1;
}
// export function updateCheckboxSelection(
//   isChecked: boolean,
//   checkBoxId: string,
//   dataSet,
//   totalRecords
// ) {
//   if (checkBoxId === "selectAll") {
//     let updatedSelect = { ...dataSet, selectAll: isChecked };
//     totalRecords.forEach((value, index) => {
//       updatedSelect[value.id] = isChecked;
//     });

//     return updatedSelect;
//   } else {
//     let updatedSelect = { ...dataSet };

//     updatedSelect[checkBoxId] = isChecked;
//     updatedSelect["selectAll"] = isSelectAll(
//       totalRecords.length,
//       updatedSelect
//     );

//     return updatedSelect;
//   }
// }
// export function isSelectAll(totalRecords, dataSet) {
//   // -1 because we also have select all as an extra key
//   if (Object.keys(dataSet).length - 1 < totalRecords) {
//     return false;
//   } else {
//     for (const key of Object.keys(dataSet)) {
//       if (key !== "selectAll" && !dataSet[key]) {
//         return false;
//       }
//     }
//     return true;
//   }
// }
