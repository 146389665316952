import { useState } from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import { getStaticContentText } from "../../DocumentEditor/service";
import { ExternalLinkContainer, Flex } from "../common.styles";
import parse from "html-react-parser";
import { DocumentRecord } from "../../../../types/document-details";
import { DocumentCommonContent } from "../../../../types/document-question";
import { ExternalRedirectModal } from "../../ExternalRedirectModal";

export const RegulatoryExternalLinkComponent = ({
  document,
  staticContent,
  linkId,
  hidden,
}: {
  document: DocumentRecord;
  hidden: boolean;
  staticContent: DocumentCommonContent;
  linkId: string;
}) => {
  const [showModal, setShowModal] = useState(false);
  if (hidden) return <div style={{ display: "none", visibility: "hidden" }} />;
  return (
    <>
      <ExternalLinkContainer
        onClick={() => {
          setShowModal(true);
        }}
      >
        {parse(
          getStaticContentText(linkId, staticContent.commonLinks, document.language)
        )}
      </ExternalLinkContainer>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        header="Redirection Alert"
      >
        <ExternalRedirectModal
          url={
            staticContent.commonLinks.find((l) => l.id === "gDd8q4cHPX")
              ?.link as string
          }
          closeModal={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
};
