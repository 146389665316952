import React, { useState, useEffect } from "react";
import SVGIcon from "../SvgIcons/index";
import { getSortState, manageSort } from "./service";
import parse from "html-react-parser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Row, TableHeaders, TableRows, TableSort } from "./types";
import { ApproverTableRow } from "../../types/admin/approverDetails";
type Props = {
  tableHeaders: TableHeaders;
  tableRows: TableRows;
  sortOnColumns?: string[];
  onSortChange?: (data: any) => void;
  getBulkSelect?: any;
  bulkSelected?: any;
  onView?: (viewRecord: ApproverTableRow) => void;
  onEdit?: (editRecord: ApproverTableRow) => void;
  onDelete?: (deleteRecord: ApproverTableRow) => void;
  actionTitle?: string;
};
const Table: React.FC<Props> = ({
  tableHeaders,
  tableRows,
  sortOnColumns,
  onSortChange,
  getBulkSelect,
  bulkSelected,
  onView,
  onEdit,
  onDelete,
  actionTitle = "Actions",
}) => {
  const [sortState, setSortState] = useState<TableSort>(
    sortOnColumns
      ? {
          ...getSortState(tableHeaders, sortOnColumns),
        }
      : null
  );
  const [, setBulkSelect] = useState<any>({});
  useEffect(() => {
    bulkSelected ? setBulkSelect(bulkSelected) : setBulkSelect({});
  }, [bulkSelected]);
  async function handleSorting(colName: string) {
    setBulkSelect({});
    getBulkSelect && getBulkSelect(null);
    let deducedSortString;
    await setSortState((prevState) => {
      deducedSortString = manageSort(prevState, colName);
      return deducedSortString;
    });
    onSortChange && onSortChange(deducedSortString);
  }
  return (
    <table
      id="kiids-for-approval"
      className="table table--rounded table--striped table--bordered-edge"
    >
      <thead>
        <tr>
          {tableHeaders &&
            tableHeaders.length > 0 &&
            tableHeaders.map((header, index) => {
              return (
                <th
                  style={{
                    cursor:
                      sortState && sortState[header.colName]
                        ? "pointer"
                        : "text",
                  }}
                  key={index}
                  onClick={(e) => {
                    sortState &&
                      sortState[header.colName] &&
                      handleSorting(header.colName);
                  }}
                >
                  {header.dispName}
                  <span
                    style={{
                      marginLeft: "4.25px",
                    }}
                  >
                    {sortState &&
                    sortState[header.colName] &&
                    sortState[header.colName].sortDir ? (
                      <SVGIcon
                        iconName={
                          sortState[header.colName].sortDir === "ASC"
                            ? "caretUp"
                            : "caretDown"
                        }
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              );
            })}
          {(onView || onEdit || onDelete) && (
            <th
              scope="col"
              style={{ width: "192.39999999999998px", textAlign: "end" }}
            >
              <span>{actionTitle}</span>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {tableRows &&
          tableRows.length > 0 &&
          tableRows.map((row: Row, index) => {
            return (
              <tr className="table__row" key={index}>
                {row.dataField &&
                  row.dataField.length > 0 &&
                  row.dataField.map((cell, cellIndex) => {
                    return (
                      <td key={cellIndex} className="breakWordWithoutSpace">
                        {cell.isHtml ? parse(cell.cellVal) : cell.cellVal}
                      </td>
                    );
                  })}

                {(onView || onEdit || onDelete) && (
                  <td
                    className="table__actions"
                    style={{ width: "192.39999999999998px" }}
                  >
                    <ul className="actions">
                      {onEdit && (
                        <li
                          className="actions__item"
                          onClick={() => onEdit(row)}
                        >
                          <span
                            data-toggle="modal"
                            data-modal-target="#modal-edit-kiid-for-approval"
                          >
                            <span className="icon">
                              <SVGIcon iconName={"edit"} />
                            </span>
                          </span>
                        </li>
                      )}
                      {onDelete && (
                        <li
                          className="actions__item"
                          data-init="delete"
                          onClick={(e) =>
                            confirmAlert({
                              title: "Confirm DELETE",
                              message: "Are you sure ?",
                              closeOnEscape: true,
                              buttons: [
                                {
                                  label: "Yes",
                                  onClick: () => {
                                    onDelete(row);
                                  },
                                },
                                {
                                  label: "No",
                                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                                  onClick: () => {},
                                },
                              ],
                            })
                          }
                        >
                          <span className="icon">
                            <SVGIcon iconName={"delete"} />
                          </span>
                        </li>
                      )}
                    </ul>
                  </td>
                )}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
export default Table;
