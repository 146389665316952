import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.div`
  height: ${spacingIncrement(210)};
  background-color: ${({ theme }) => theme.color.primary};
`;

export const InnerContainer = styled.div`
  padding: ${spacingIncrement(48)} 0;
  width: ${spacingIncrement(1216)};
  margin: auto;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BackButton = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacingIncrement(9)};
  color: ${({ theme }) => theme.color.blue};
  font-size: ${spacingIncrement(16)};
  font-weight: 600;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: ${spacingIncrement(10)};
`;

export const Title = styled.span`
  font-size: ${spacingIncrement(40)};
  font-weight: 600;
  color: white;
  padding-right: ${spacingIncrement(20)};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const RightContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacingIncrement(15)};
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.color.blue};
`;

export const SavingContainer = styled.div<{ isDocumetLocked?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 10;
  width: ${spacingIncrement(1216)};
  margin: auto;
  ${({ isDocumetLocked }) =>
    isDocumetLocked &&
    css`
      z-index: -1;
    `}
`;

export const SavingMessage = styled.div`
  transition: all 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  padding: ${spacingIncrement(20)};
  background: #fff;
  border: 1px solid rgba(10, 186, 238, 0.2);
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacingIncrement(20)};
`;
