import styled from "styled-components";
import Button from "../../../../components/Button";
import { spacingIncrement } from "../../../../utils/common";

export const DownloadButton = styled(Button)`
  margin-right: ${spacingIncrement(30)};
`;

export const PdfWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${spacingIncrement(10)} ${spacingIncrement(20)};
`;
