import React from "react";
import { ImageProps } from "../types";

const GlobeNo: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="145"
      height="145"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="72.5" cy="72.5" r="72.5" fill="#9D9D9C" />
      <path
        d="M40.5949 61.5379H61.7491C59.631 72.715 55.3895 80.3234 51.172 80.3234C47.0822 80.3234 42.97 73.1691 40.7932 62.544L40.5949 61.5379H61.7491H40.5949ZM20.6468 61.5384L35.3577 61.5376C36.604 68.652 38.7165 74.7046 41.481 78.9403C32.7247 76.3542 25.4347 70.3587 21.1468 62.4913L20.6468 61.5384ZM66.9862 61.5376L81.6971 61.5384C77.4901 69.8736 69.9695 76.2508 60.8664 78.939C63.4444 74.9838 65.4558 69.4488 66.7255 62.9457L66.9862 61.5376L81.6971 61.5384L66.9862 61.5376ZM68.0211 39.3294L84.6604 39.3274C85.1086 41.5356 85.3439 43.8212 85.3439 46.1617C85.3439 49.7332 84.796 53.1768 83.7797 56.413H67.7123C68.0721 53.1412 68.2606 49.7038 68.2606 46.1617C68.2606 44.6094 68.2244 43.0772 68.1534 41.5703L68.0211 39.3294L84.6604 39.3274L68.0211 39.3294ZM17.6835 39.3274L34.3228 39.3294C34.1648 41.5501 34.0833 43.8333 34.0833 46.1617C34.0833 48.9953 34.204 51.762 34.4364 54.4305L34.6316 56.413H18.5643C17.5479 53.1768 17 49.7332 17 46.1617C17 43.8212 17.2353 41.5356 17.6835 39.3274ZM39.4716 39.3275H62.8724C63.0441 41.5334 63.1356 43.8186 63.1356 46.1617C63.1356 49.0236 62.999 51.7993 62.7466 54.4496L62.5359 56.4132H39.808C39.4217 53.1817 39.2083 49.7391 39.2083 46.1617C39.2083 44.4044 39.2598 42.6796 39.358 40.9965L39.4716 39.3275H62.8724H39.4716ZM61.2316 13.965L60.863 13.3834C71.1755 16.4275 79.4576 24.2059 83.1927 34.2022L67.5072 34.2032C66.4291 25.9454 64.2392 18.8535 61.2316 13.965L60.863 13.3834L61.2316 13.965ZM41.0647 13.5091L41.4808 13.3835C38.4715 17.9943 36.2349 24.758 35.0471 32.7004L34.8367 34.2032L19.1512 34.2022C22.836 24.3408 30.9458 16.6378 41.0647 13.5091L41.4808 13.3835L41.0647 13.5091ZM51.172 12C55.6778 12 60.2111 20.6843 62.1531 33.1268L62.3131 34.2024H40.0308C41.8738 21.1877 46.5373 12 51.172 12Z"
        fill="#3C3C3B"
      />
      <path
        d="M44.672 69.8891H82.5253V95.5687C80.8823 95.2906 79.4657 95.0381 78.2055 94.8134C74.008 94.0651 71.5456 93.6261 68.2276 93.5825C63.8696 93.5253 58.0112 94.1476 44.672 95.6024V69.8891Z"
        fill="white"
        stroke="#9D9D9C"
      />
      <ellipse
        cx="64.4253"
        cy="69.3891"
        rx="19.4267"
        ry="13.5625"
        fill="white"
      />
      <circle cx="64.8387" cy="45.0799" r="9.92" fill="white" />
      <mask id="path-6-inside-1_202_4" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.132 72.9799V90.5466C90.9584 88.8681 82.4511 88.8693 67.2787 90.5466V72.9799V72.1946H67.3382C68.1195 66.9178 76.492 59.9599 86.7054 59.9599C96.9187 59.9599 105.291 66.9178 106.072 72.1946H106.132V72.9799Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.132 72.9799V90.5466C90.9584 88.8681 82.4511 88.8693 67.2787 90.5466V72.9799V72.1946H67.3382C68.1195 66.9178 76.492 59.9599 86.7054 59.9599C96.9187 59.9599 105.291 66.9178 106.072 72.1946H106.132V72.9799Z"
        fill="white"
      />
      <path
        d="M106.132 90.5466L106.022 91.5405L107.132 91.6633V90.5466H106.132ZM67.2787 90.5466H66.2787V91.6632L67.3886 91.5405L67.2787 90.5466ZM67.2787 72.1946V71.1946H66.2787V72.1946H67.2787ZM67.3382 72.1946V73.1946H68.2011L68.3274 72.341L67.3382 72.1946ZM106.072 72.1946L105.083 72.341L105.21 73.1946H106.072V72.1946ZM106.132 72.1946H107.132V71.1946H106.132V72.1946ZM105.132 72.9799V90.5466H107.132V72.9799H105.132ZM106.242 89.5526C90.9953 87.8661 82.4142 87.8673 67.1688 89.5526L67.3886 91.5405C82.488 89.8713 90.9216 89.8701 106.022 91.5405L106.242 89.5526ZM68.2787 90.5466V72.9799H66.2787V90.5466H68.2787ZM68.2787 72.9799V72.1946H66.2787V72.9799H68.2787ZM67.2787 73.1946H67.3382V71.1946H67.2787V73.1946ZM68.3274 72.341C68.6602 70.0935 70.6936 67.2456 74.0537 64.9208C77.3719 62.625 81.8227 60.9599 86.7054 60.9599V58.9599C81.3746 58.9599 76.5325 60.7737 72.9158 63.2761C69.341 65.7494 66.7975 69.0189 66.349 72.0481L68.3274 72.341ZM86.7054 60.9599C91.588 60.9599 96.0388 62.625 99.357 64.9208C102.717 67.2456 104.751 70.0935 105.083 72.341L107.062 72.0481C106.613 69.0189 104.07 65.7494 100.495 63.2761C96.8782 60.7737 92.0361 58.9599 86.7054 58.9599V60.9599ZM106.072 73.1946H106.132V71.1946H106.072V73.1946ZM105.132 72.1946V72.9799H107.132V72.1946H105.132Z"
        fill="#9D9D9C"
        mask="url(#path-6-inside-1_202_4)"
      />
      <circle
        cx="87.1187"
        cy="50.8666"
        r="9.42"
        fill="white"
        stroke="#9D9D9C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.0404 122.435H53.4207V107.036H64.3283H91.9181V107.062L112.179 87.4913C115.506 84.2779 120.818 84.4018 123.991 87.767C124.23 88.021 124.233 88.417 123.996 88.6735L93.4651 121.744C92.8291 122.433 91.8858 122.669 91.0404 122.435Z"
        fill="white"
      />
      <path
        d="M42.3671 102.508C44.4676 97.4006 50.2586 95.0849 55.3018 97.3361L69.2439 103.559L57.6259 131.812C57.4158 132.323 56.8367 132.555 56.3324 132.329L35.0851 122.845C34.5808 122.62 34.3422 122.023 34.5523 121.513L42.3671 102.508Z"
        fill="white"
      />
      <path
        d="M45.7212 103.339C45.7212 99.512 48.8237 96.4096 52.6507 96.4096H85.119C86.0392 96.4096 86.7851 97.1555 86.7851 98.0757V98.0757C86.7851 101.162 84.6668 103.844 81.665 104.559L67.3938 107.959L66.2532 103.339H45.7212V103.339Z"
        fill="white"
      />
      <path
        d="M114.679 65.7037C111.509 67.2544 107.919 67.5744 104.425 67.0802C97.6071 66.116 94.6197 66.3727 94.6197 66.3727C80.0638 67.7359 77.6886 84.0581 77.6886 84.0581C83.793 76.1969 99.5256 78.1779 99.5256 78.1779C103.656 78.3783 109.951 76.6143 109.951 76.6143C105.031 80.15 100.901 79.9496 100.901 79.9496C86.6533 80.9336 78.1803 86.67 74.5917 89.7577C73.618 90.5955 74.0785 92.0437 75.3352 92.3095V92.3095C75.9203 92.4333 76.5131 92.1801 76.8994 91.7236C78.8167 89.4579 81.2244 88.9787 81.2244 88.9787C92.6238 97.4467 105.217 91.3609 105.217 91.3609C119.34 85.0127 122.637 66.0679 123.258 61.2236C123.298 60.9169 122.844 60.732 122.616 60.9405C120.939 62.4701 117.555 64.2971 114.679 65.7037Z"
        fill="#3C3C3B"
      />
      <circle cx="72.5" cy="72.5" r="62" stroke="#D2D2D2" strokeWidth="9" />
      <line
        x1="25.818"
        y1="112.818"
        x2="112.374"
        y2="26.2625"
        stroke="#D2D2D2"
        strokeWidth="9"
      />
    </svg>
  );
};

export default GlobeNo;
