import React, { useEffect, useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import {
  Container,
  Title,
  StyledImage,
  ToolTipContainer,
  ToolTipArrow,
  SelectText,
  SearchInput,
  ListContainer,
  InnerContainer,
} from "./styles";

export type Option = {
  label: string;
  value: string | number;
};

type Props = {
  options: Option[];
  onChange?: (value: Option) => void;
  value?: string;
};

const FundSelect: React.FC<Props> = ({ options, onChange, value }) => {
  const [searchText, setSearchText] = useState("");
  const {
    tooltipRef,
    setTooltipRef,
    setTriggerRef,
    getArrowProps,
    getTooltipProps,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    interactive: true,
    offset: [0, 10],
    placement: "bottom-start",
  });

  const onClickOption = (opt: Option) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tooltipRef!.style.display = "none";
    onChange?.(opt);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (!visible) {
      setSearchText("");
    }
  }, [visible]);

  return (
    <>
      <Container>
        <InnerContainer ref={setTriggerRef}>
          <Title>{value}</Title>
          <StyledImage name="downArrow" color="white" />
        </InnerContainer>
      </Container>
      {visible && (
        <ToolTipContainer
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <ToolTipArrow
            {...getArrowProps({
              className: "tooltip-arrow",
            })}
          />
          <SearchInput placeholder="Search" onChange={onSearch} />
          <ListContainer>
            {options
              ?.filter((opt) => {
                if (searchText === "") return true;
                return opt.label
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              })
              .map((opt) => (
                <SelectText key={opt.value} onClick={() => onClickOption(opt)}>
                  {opt.label}
                </SelectText>
              ))}
          </ListContainer>
        </ToolTipContainer>
      )}
    </>
  );
};

export default FundSelect;
