import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

type ButtonContainerProps = {
  buttonType?: "solid" | "outline";
};

export const ButtonContainer = styled.button<ButtonContainerProps>`
  height: ${spacingIncrement(42)};
  background: ${({ buttonType }) =>
    buttonType === "outline" ? "transparent" : "#0abaee"};
  border: ${({ buttonType }) =>
    buttonType === "outline" ? "2px solid #0abaee" : "none"};
  padding: 0 ${spacingIncrement(16)};
  border-radius: 5px;
  cursor: pointer;
  :focus,
  :focus-visible,
  :active {
    outline: none;
  }
  span {
    font-size: ${spacingIncrement(16)};
    color: ${({ buttonType }) =>
      buttonType === "outline" ? "#0abaee" : "#ffffff"};
    font-weight: 600;
  }
  :disabled {
    background: #c4c4c4;
    cursor: not-allowed;
    border: none;
    span {
      color: unset;
    }
  }
`;
