import Button from "../../../components/Button";
import { Option } from "../../../components/Select";
import {
  ConfirmationButtonContainer,
  DeleteMessage,
} from "../ClientTable/styles";

export const BulkStatusUpdateConfirmationModal = ({
  fromStatus,
  toStatus,
  onConfirm,
  onCancel,
}: {
  fromStatus: string;
  toStatus: string;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <div className="py-30">
      <DeleteMessage>
        {`You are going to change the status from "${fromStatus}
  " to “${toStatus}" for the selected documents in bulk.
  Please note that, the changes cannot be reverted.`}
      </DeleteMessage>
      <DeleteMessage>Are you sure, you want to do the changes?</DeleteMessage>
      <ConfirmationButtonContainer>
        <Button buttonType="solid" onClick={onConfirm}>
          Confirm
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </ConfirmationButtonContainer>
    </div>
  );
};

const ArchiveConfirmationMessages = {
  single:
    "Are you sure you want to archive the document? as a result, the document will no longer be publicly available.",
  multiple:
    "Are you sure you want to archive the selected documents? as a result, the documents will no longer be publicly available.",
  subFund:
    "Are you sure you want to archive the documents for this fund? As a result, all documents will no longer be publicly available. You will also not be able to create a new document for this(these) fund(s).",
};

export const DocumentArchiveConfirmationModal = ({
  onConfirm,
  onCancel,
  message = "subFund",
}: {
  onConfirm: () => void;
  onCancel: () => void;
  message?: keyof typeof ArchiveConfirmationMessages;
}) => {
  return (
    <div className="py-30">
      <DeleteMessage>{ArchiveConfirmationMessages[message]}</DeleteMessage>
      <ConfirmationButtonContainer>
        <Button onClick={onConfirm} buttonType="solid">
          Yes, Proceed
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </ConfirmationButtonContainer>
    </div>
  );
};
