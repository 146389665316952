import styled, { css } from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const Container = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const Button = styled.button<{ isSelected?: boolean }>`
  width: 100%;
  z-index: 1;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 2px solid transparent;
  padding: ${spacingIncrement(8)} ${spacingIncrement(12)};
  font-size: ${spacingIncrement(16)};
  line-height: 1.45;
  border-radius: ${spacingIncrement(5)};
  transition: all 0.15s ease-in-out;
  color: #fff;
  background-color: #15151a;
  border-color: #15151a;
  box-shadow: none;
  cursor: pointer;
  max-width: ${spacingIncrement(300)};
  :hover {
    background-color: #15151a;
    border-color: #67676a;
  }
  :focus {
    border-color: #15151a;
    box-shadow: 0 0 0 0.2rem rgb(21 21 26 / 50%);
    outline: none;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      padding-right: ${spacingIncrement(30)};
      background-color: #303245;
      border-color: #303245;
      :hover {
        background-color: #303245;
        border-color: #303245;
      }
    `}
`;

export const ImageWrapper = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  right: ${spacingIncrement(5)};
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding 0;
  cursor: pointer;
  z-index: 2;
  :hover, :focus {
    outline: none;
    border: none;
  }

`;

export const SearchInput = styled.input`
  padding: ${spacingIncrement(8)} ${spacingIncrement(16)};
  border: 2px solid #d9dae3;
  border-radius: ${spacingIncrement(40)};
  margin: ${spacingIncrement(12)} auto;
  width: calc(100% - 2rem) !important;
  font-size: ${spacingIncrement(16)};
  line-height: 1.45;
  color: #495057;
  :focus {
    outline: none;
  }
`;

export const ToolTipContainer = styled.div`
  border-radius: ${spacingIncrement(5)};
  padding: 0;
  width: ${spacingIncrement(320)};
  max-height: ${spacingIncrement(280)};
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
`;

export const ListContainer = styled.div`
  box-shadow: inset 0 3px 6px -4px rgb(0 0 0 / 20%),
    inset 0 -3px 6px -4px rgb(0 0 0 / 20%);
  border-top: 1px solid #dee2e6;
  max-height: 170px;
  overflow-y: auto;
`;

export const ToolTipArrow = styled.div`
  transform: translate(10px, 0px) !important;
`;

export const CheckboxWrapper = styled.div`
  color: black;
  background-color: #ffffff;
  font-size: ${spacingIncrement(16)};
  padding: ${spacingIncrement(12)} ${spacingIncrement(16)};
  margin: ${spacingIncrement(5)} auto;
  :hover {
    cursor: pointer;
    color: #16181b;
    text-decoration: none;
    background-color: #fcfcfc;
  }
`;

export const SubmitButton = styled.span`
  text-align: center;
  background-color: #f6f6f6;
  font-weight: 600;
  color: #16181b;
  border-bottom: none;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacingIncrement(12)} ${spacingIncrement(16)};
  :hover {
    cursor: pointer;
    background-color: #fcfcfc;
  }
`;
