import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../utils/common";
import Button from "../../../components/Button";
import { Label } from "../CommonComponents/Typography";

export const Container = styled.div<{ isLocked?: boolean }>`
  ${({ isLocked }) =>
    isLocked &&
    css`
      z-index: -1;
    `}
  min-height: ${spacingIncrement(300)};
  position: relative;
  padding-right: ${spacingIncrement(30)};
`;

export const WaterMarkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const WaterMarkText = styled.div`
  font-weight: bold;
  opacity: 0.1;
  transform: rotate(-35deg);
  color: red;
  font-size: ${spacingIncrement(154)};
  line-height: ${spacingIncrement(154)};
  position: fixed;
  top: 50%;
  z-index: 10000;
`;

export const AddLogoButton = styled(Button)`
  position: absolute;
  left: ${spacingIncrement(18)};
  top: ${spacingIncrement(18)};
  padding: 0;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spacingIncrement(5)};
    padding: ${spacingIncrement(10)} ${spacingIncrement(15)};
  }
`;

export const AnnexTextMode = styled.span`
  font-size: ${spacingIncrement(10)};
  color: red;
`;

// export const SubHeading = styled.span`
//   text-align: center;
//   font-size: ${spacingIncrement(16)};
//   font-weight: bold;
//   padding-bottom: ${spacingIncrement(15)};
// `;

// export const ProductInfoContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   padding-bottom: ${spacingIncrement(10)};
// `;

export const GreyBox = styled.div`
  font-size: ${spacingIncrement(12)};
  background: #f2f2f2;
  padding: ${spacingIncrement(10)};
  padding-bottom: ${spacingIncrement(15)};
  margin-bottom: ${spacingIncrement(15)};
  ul {
    li {
      padding-left: ${spacingIncrement(10)};
      position: relative;
      div {
        position: absolute;
        left: 0;
      }
      span {
        font-weight: bold;
      }
    }
  }
`;

export const Block = styled.div`
  display: block;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const InputCheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: ${spacingIncrement(10)};
`;

export const CheckBoxLabel = styled(Label)`
  float: left;
  input,
  div {
    display: inline-block;
  }
`;

export const Highlight = styled.span`
  background: rgba(218, 244, 252, 0.5);
`;

export const OrangeBoxHeader = styled.div`
  background-color: #f6d1ac;
  padding: ${spacingIncrement(10)};
  padding-bottom: 0;
`;

export const OrangeLeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: ${spacingIncrement(10)};
  padding-right: ${spacingIncrement(10)};
`;

export const OrangeRightHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: ${spacingIncrement(10)};
  padding-left: ${spacingIncrement(10)};
  position: relative;
`;

export const Circle = styled.div<{ isHighlighted?: boolean }>`
  width: ${spacingIncrement(12)};
  height: ${spacingIncrement(12)};
  border-radius: 100%;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? "#5ab05d" : "#ffffff"};
  margin-top: ${spacingIncrement(3)};
  margin-right: ${spacingIncrement(4)};
`;

export const OrangeBox = styled.div<{ padding?: string; margin?: string }>`
  break-inside: avoid;
  display: flex;
  flex-direction: column;
  background-color: #fae8d5;
  padding-left: ${spacingIncrement(8)};
  padding-right: ${spacingIncrement(8)};
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export const OrangeSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const OrangeLeft = styled.div`
  padding: ${spacingIncrement(10)};
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacingIncrement(10)};
  flex: 1;
`;

export const OrangeRight = styled.div`
  padding: ${spacingIncrement(10)};
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

export const HorizontalLine = styled.div<{ heightPercent?: number }>`
  height: ${({ heightPercent }) => heightPercent || 100}%;
  left: 0;
  background-color: #ccc;
  width: 1px;
  position: absolute;
`;

export const FlexRow = styled.div<{
  gap?: number;
  paddingTop?: number;
  align?: "center" | "flex-start" | "flex-end";
}>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.align ? props.align : "center")};
  gap: ${({ gap }) => spacingIncrement(gap || 0)};
  padding-top: ${({ paddingTop }) => spacingIncrement(paddingTop || 0)};
`;

export const FlexColumn = styled.div<{ gap?: number; paddingTop?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ gap }) => spacingIncrement(gap || 0)};
  padding-top: ${({ paddingTop }) => spacingIncrement(paddingTop || 0)};
`;

export const Flex = styled.div<{ flex?: number }>`
  display: flex;
  flex: ${(props) => props.flex || "unset"};
`;

export const GreyDot = styled.div<{ isHighlighted?: boolean }>`
  min-width: ${spacingIncrement(15)};
  min-height: ${spacingIncrement(15)};
  max-width: ${spacingIncrement(15)};
  max-height: ${spacingIncrement(15)};
  margin-right: ${spacingIncrement(10)};
  margin-top: ${spacingIncrement(2)};
  border-radius: 100%;
  background: #ccc;
`;

export const QuestionText = styled.span<{
  fontSize?: number;
  isItalic?: boolean;
  isBold?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: sans-serif, arial !important;
  font-size: ${({ fontSize }) => spacingIncrement(fontSize ? fontSize : 16)};
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
  margin-bottom: ${spacingIncrement(10)};
  font-style: ${({ isItalic }) => (isItalic ? "italic" : "normal")};
`;

export const UploadFooterNote = styled.div`
  margin: 10px 0 10px 20px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UploadLeftSide = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
`;

export const UploadRightSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ${spacingIncrement(10)};
  padding-right: ${spacingIncrement(10)};
  gap: ${spacingIncrement(10)};
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: ${spacingIncrement(10)};
  padding-top: ${spacingIncrement(10)};
  margin: ${spacingIncrement(10)};
`;

export const GraphSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #fff;
  padding: ${spacingIncrement(8)};
  border: solid 1px #ccc;
  padding-bottom: ${spacingIncrement(20)};
`;

export const LegendSquare = styled.div<{ color: string }>`
  width: ${spacingIncrement(7)};
  height: ${spacingIncrement(7)};
  background: ${({ color }) => color};
  margin-right: ${spacingIncrement(5)};
  margin-top: ${spacingIncrement(5)};
`;

export const RelativeContainer = styled.div`
  position: relative;
`;
