import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";
import SidePanel from "../../../components/SidePanel";
import { fetchData, postData } from "../../../services/apis";
import Context from "../../../store/context";
import { updateCommentedQuestionIds } from "../../../store/globalActions";
import { CommentResponse, CommentType } from "../../../types/comment";
import { DocumentUserEnum } from "../../../types/document-details";
import { GLOBAL_CONTEXT } from "../../../types/store";
import GenericCommentContainer from "./GenericCommentContainer";
import QuestionLevelCommentContainer from "./QuestionLevelCommentContainer";
import { NavigationContainer, NavigationItem } from "./styles";

export type Questions = {
  label: string;
  id: string;
};

const MAX_COMMENT_LENGTH = 240;

export type Preset =
  | {
      commentType: CommentType.generic;
      documentId: string;
    }
  | {
      commentType: CommentType.questionLevel;
      documentId: string;
      questionId: string;
    };

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  preset?: Preset;
  questions?: Questions[];
  userType?: DocumentUserEnum;
};

const CommentSection: React.FC<Props> = ({
  isOpen = false,
  onClose,
  preset,
  questions,
  userType,
}) => {
  const { clientId } = useParams<{ id: string; clientId: string }>();
  const { globalDispatch } = useContext<GLOBAL_CONTEXT>(Context);
  const [commentType, setCommentType] = useState<CommentType>();
  const [documentId, setDocumentId] = useState<string>();
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>();
  const [addLoading, setAddLoading] = useState(false);
  const [comments, setComments] = useState<CommentResponse>();
  useEffect(() => {
    preset?.commentType && setCommentType(preset?.commentType);
    preset?.documentId && setDocumentId(preset?.documentId);
    if (preset?.commentType === CommentType.questionLevel) {
      setSelectedQuestionId(preset?.questionId);
    }
  }, [preset]);

  useEffect(() => {
    if (!isOpen) {
      setCommentType(undefined);
      setDocumentId(undefined);
      setSelectedQuestionId(undefined);
    }
  }, [isOpen]);

  useEffect(() => {
    if (documentId) {
      fetchComments();
    }
  }, [documentId]);

  const onAdd = async (
    text: string,
    qId?: string,
    updateStatusToAwaitingComments?: boolean
  ) => {
    setAddLoading(true);
    try {
      const addResponse = await postData({
        endPoint: "addComment",
        data: {
          clientId: clientId,
          updateStatusToAwaitingComments,
          commentDetail: {
            type: commentType,
            documentId: documentId,
            comment: text,
            ...(commentType === CommentType.questionLevel
              ? { questionId: qId }
              : {}),
          },
        },
      });
      setAddLoading(false);
      if (addResponse.data.message === "Success") {
        fetchComments();
      }
    } catch (error) {
      setAddLoading(false);
      console.log(error);
    }
  };

  const fetchComments = async () => {
    const commentResponse = await fetchData({
      endPoint: "getDocumentComments",
      queryParam: {
        clientId: clientId,
        documentId: documentId,
      },
    });
    const commentObj: CommentResponse = commentResponse.data.data[0];
    if (commentObj) {
      globalDispatch(
        updateCommentedQuestionIds(Object.keys(commentObj.questionLevel))
      );
    }
    setComments(commentResponse.data.data[0]);
  };
  return (
    <SidePanel
      isOpen={isOpen}
      onClose={onClose}
      header="Comments"
      content={
        <>
          <NavigationContainer>
            <ul>
              <NavigationItem
                active={commentType === CommentType.questionLevel}
                onClick={() => setCommentType(CommentType.questionLevel)}
              >
                Question Level
              </NavigationItem>
              <NavigationItem
                active={commentType === CommentType.generic}
                onClick={() => setCommentType(CommentType.generic)}
              >
                Generic
              </NavigationItem>
            </ul>
          </NavigationContainer>
          {commentType === CommentType.questionLevel ? (
            <>
              {questions?.map((question) => (
                <QuestionLevelCommentContainer
                  key={question.id}
                  onAdd={onAdd}
                  question={question}
                  selectedQuestionId={selectedQuestionId}
                  comments={comments}
                  addLoading={addLoading}
                  userType={userType}
                  maxCommentLen={MAX_COMMENT_LENGTH}
                  updateComments={fetchComments}
                />
              ))}
            </>
          ) : commentType === CommentType.generic ? (
            <>
              <GenericCommentContainer
                onAdd={onAdd}
                comments={comments}
                addLoading={addLoading}
                userType={userType}
                maxCommentLen={MAX_COMMENT_LENGTH}
                updateComments={fetchComments}
              />
            </>
          ) : null}
        </>
      }
    />
  );
};

export default CommentSection;
