import { useContext, useEffect, useMemo, useState } from "react";
import Modal, { ModalProps } from "../../../components/Modal";
import { BulkStatusUpdateConfirmationModal } from "../ActionButtons/ConfirmationModal";
import { Option } from "../../../components/FilterOptions";
import {
  DocumentActionEnum,
  DocumentStatusEnum,
  DocumentStatusToKey,
} from "../../../types/document-details";
import { postData } from "../../../services/apis";
import {
  filterAllLockedDocuments,
  getDocumentUserPermission,
  notifyError,
  notifySuccess,
  userHasPermission,
  validStatusUpdates,
} from "../../../utils/common";
import { updateLockStatus } from "../../../services/document";
import { BulkStatusUpdate } from "../../../components/BulkStatusUpdate";
import { AxiosResponse } from "axios";
import { ValidateDocumentApiResData } from "../../../types/validateDocuments";
import { BulkSelected } from "../../../types/listing-table";
import { BulkStatusValidationAlert } from "../../../components/BulkStatusValidationAlert";
import { GLOBAL_CONTEXT } from "../../../types/store";
import Context from "../../../store/context";

const listOfStatusShouldNotBeLocked = ["Draft", "Awaiting Comments Review"];

export const BulkStatusUpdateModal = ({
  selectedBulkIds,
  onClose,
  updateDocList,
}: {
  selectedBulkIds: BulkSelected[];
  onClose: () => void;
  updateDocList: () => void;
}) => {
  const {
    globalState: { userDetails, userPermission, approverGroup, selectedFund },
  } = useContext<GLOBAL_CONTEXT>(Context);

  const userType = useMemo(
    () => userDetails && getDocumentUserPermission(approverGroup, userDetails),
    [approverGroup, userDetails]
  );
  useEffect(() => {
    onBulkChangeStatus();
    return () => {
      onClose();
    };
  }, []);
  const [modal, setModal] = useState<ModalProps & { children: JSX.Element }>({
    isOpen: false,
    onClose: () => {
      setModal({
        ...modal,
        isOpen: false,
      });
      onClose();
    },
    header: "",
    height: 0,
    children: <></>,
  });

  const onCloseConfirm = () => {
    setConfirmModalOpen({
      ...confirmModalOpen,
      isOpen: false,
      fromStatus: undefined,
      toStatus: undefined,
      documentIds: undefined,
    });
  };

  function isAllDocumentsStatusSame(): { result: boolean; status?: string } {
    const status = selectedBulkIds[0]?.status;
    for (const doc of selectedBulkIds) {
      if (doc?.status !== status) {
        return { result: false };
      }
    }
    return { result: true, status: status };
  }

  function updateDocumentsLockStatus(
    lockStatus: "lock" | "unlock",
    documentIds: string[],
    status: string
  ) {
    if (listOfStatusShouldNotBeLocked.includes(status)) {
      documentIds.map((doc) => {
        updateLockStatus(doc, lockStatus, userDetails?.clientId || "");
      });
    }
  }

  // Bulk status update step 4
  const onBulkStatusUpdate = () => {
    if (!confirmModalOpen?.documentIds?.length) {
      notifyError("No documents selected");
      onCloseConfirm();
      return;
    }
    const body = {
      documentIds: confirmModalOpen.documentIds,
      toStatus: confirmModalOpen.toStatus,
      comment: confirmModalOpen.comment,
      clientId: selectedFund?.client_id,
    };
    postData({
      endPoint: `bulkStatusUpdate/${confirmModalOpen.toStatus?.value}`,
      data: body,
    })
      .then((res) => {
        if (res.data.success) {
          notifySuccess("Bulk status update successfully");
          onCloseConfirm();
          updateDocumentsLockStatus(
            "unlock",
            confirmModalOpen.documentIds || [],
            confirmModalOpen.fromStatus?.value || ""
          );
          modal.onClose?.();
          updateDocList();
        } else {
          notifyError(res.data.message);
        }
      })
      .catch((err) => {
        notifyError(err.message);
      });
  };

  // Bulk status update step 3
  const onProceed = (status: string, documentIds: string[]) => {
    setModal({
      ...modal,
      header: "Bulk Status Update",
      isOpen: true,
      width: 900,
      children: (
        <BulkStatusUpdate
          fromStatusOption={{
            value: DocumentStatusToKey[status as DocumentStatusEnum],
            label: status,
          }}
          toStatusOptions={validStatusUpdates(status)}
          selectedDocuments={selectedBulkIds.filter((s) =>
            documentIds.includes(s.id)
          )}
          updateDocList={updateDocList}
          onCancel={() => {
            updateDocumentsLockStatus("unlock", documentIds, status);
            modal.onClose?.();
          }}
          onUpdate={(from, to, comment) => {
            if (to.value === "filed") {
              if (userDetails && document && userType && userPermission) {
                const filedPermission = userHasPermission(
                  {
                    userType,
                    docStatus: from.value as any,
                    docAction: DocumentActionEnum.filed,
                  },
                  userPermission
                );
                if (!filedPermission) {
                  notifyError("Don't have required permission");
                  return;
                }
              }
            }
            setConfirmModalOpen((state) => ({
              ...state,
              isOpen: true,
              fromStatus: from,
              toStatus: to,
              documentIds,
              comment,
            }));
          }}
        />
      ),
    });
  };

  // Bulk status update step 2
  const handleDocumentStatusChange = async (status: string) => {
    const validateDocumentResHandler = (
      res: AxiosResponse<ValidateDocumentApiResData, any>
    ) => {
      const data = res.data;

      const validationFailed = selectedBulkIds.filter((sbi) =>
        data.validationFailed.includes(sbi.id)
      );

      const validationSuccess = selectedBulkIds.filter((sbi) =>
        data.validationSuccess.includes(sbi.id)
      );

      updateDocumentsLockStatus(
        "lock",
        validationSuccess.map((s) => s.id),
        status
      );

      // If all documents are successfully validated show change status modal
      if (
        validationFailed.length === 0 &&
        validationSuccess.length === selectedBulkIds.length
      ) {
        // Validation for all documents is passed so no need to show Bulk Status Validation Alert
        // show change status modal
        onProceed(
          status,
          validationSuccess.map((s) => s.id)
        );
        return;
      }

      setModal({
        ...modal,
        header: "Bulk Status Alert",
        isOpen: true,
        width: 900,
        children: (
          <BulkStatusValidationAlert
            validationFailed={validationFailed}
            validationSuccess={validationSuccess}
            onCancel={() => {
              updateDocumentsLockStatus(
                "unlock",
                validationSuccess.map((s) => s.id),
                status
              );
              modal.onClose?.();
            }}
            onProceed={() => {
              onProceed(
                status,
                validationSuccess.map((s) => s.id)
              );
              return;
            }}
          />
        ),
      });
    };
    try {
      switch (status) {
        case DocumentStatusEnum.draft:
        case DocumentStatusEnum.awaiting_comments_review:
          // lock all documents
          postData({
            endPoint: "validateDocuments",
            data: {
              documentIds: selectedBulkIds.map((document) => document.id),
              clientId: selectedFund?.client_id,
            },
          }).then(validateDocumentResHandler);
          break;
        case DocumentStatusEnum.approved:
        case DocumentStatusEnum.awaiting_approval:
          onProceed(
            status,
            selectedBulkIds.map((s) => s.id)
          );
          break;
        default:
          notifyError("This status is not yet handled");
          onClose();
          break;
      }
    } catch (error) {
      // notifyError(error);
    }
  };

  // Bulk status update step 1
  const onBulkChangeStatus = async () => {
    try {
      // check if all documents are in same status
      const { result, status } = isAllDocumentsStatusSame();

      if (!result || !status) {
        notifyError("Please select documents with same status");
        onClose();
        return;
      }
      // If document status is 'Draft' check if all documents are not locked
      if (listOfStatusShouldNotBeLocked.includes(status)) {
        const isLockedDocumentsSelected =
          filterAllLockedDocuments(selectedBulkIds);

        if (isLockedDocumentsSelected.length > 0) {
          notifyError(
            `Please note that locked documents are not applicable for bulk status updates.`
          );
          onClose();
          return;
        }
      }

      await handleDocumentStatusChange(status);
    } catch (error) {
      // console.log(error);
    }
  };

  const [confirmModalOpen, setConfirmModalOpen] = useState<{
    isOpen: boolean;
    fromStatus?: Option;
    toStatus?: Option;
    documentIds?: string[];
    comment?: string;
  }>({
    isOpen: false,
  });
  return (
    <>
      <Modal {...modal}>{modal.children}</Modal>
      <Modal
        isOpen={confirmModalOpen.isOpen}
        onClose={onCloseConfirm}
        width={850}
        header="Bulk Status Update Confirmation"
      >
        <BulkStatusUpdateConfirmationModal
          fromStatus={confirmModalOpen.fromStatus?.label || ""}
          toStatus={confirmModalOpen.toStatus?.label || ""}
          onConfirm={onBulkStatusUpdate}
          onCancel={onCloseConfirm}
        />
      </Modal>
    </>
  );
};
