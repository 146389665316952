import styled from "styled-components";
import { spacingIncrement } from "../../../../../utils/common";
import { ButtonContainer } from "../../styles";
export const MainHeading = styled.div`
  display: flex;
  align-items: center;
  font-size: ${spacingIncrement(16)};
  font-weight: 400;
  span {
    margin-left: ${spacingIncrement(8)};
  }
`;
export const ListContainer = styled.div`
  margin-top: ${spacingIncrement(20)};
  margin-bottom: ${spacingIncrement(20)};
`;
export const List = styled.ul`
  padding-left: ${spacingIncrement(45)};
  list-style: disc;
`;
export const ButtonGroup = styled(ButtonContainer)`
  justify-content: flex-start;
`;
export const ScrollContainer = styled.div`
  max-height: 238px;
  overflow-y: auto;
`;
