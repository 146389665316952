import React from "react";
import { ImageProps } from "../types";

const Comment: React.FC<ImageProps> = ({
  color = "#ffffff",
  ...otherProps
}) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M30.5854 30.9845L24.7099 28.9368C24.1186 28.7321 23.4535 28.7693 22.8992 29.0857C20.331 30.4819 17.3193 31.152 14.1228 30.7983C7.26802 30.0165 1.762 24.4134 1.07837 17.5072C0.136065 8.12516 7.82232 0.288214 17.0791 1.05143C23.823 1.60988 29.4214 6.84072 30.4746 13.5608C30.9734 16.7067 30.4561 19.7224 29.2551 22.3098C29.0149 22.8125 28.941 23.3895 29.1073 23.9294L30.9919 30.6122C31.0474 30.8542 30.8072 31.0589 30.5854 30.9845Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Comment;
