import { useCallback, useContext } from "react";
import Context from "../store/context";
import { updateDocumentState } from "../store/globalActions";
import { DocumentAnswer, DocumentState } from "../types/document-details";
import { GLOBAL_CONTEXT } from "../types/store";
import { handleDependant } from "../features/templates/DocumentEditor/service";

export const useDocumentState = () => {
  const {
    globalState: { documentState, documentErrorState, documentQuestions },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);
  const setDocumentState = (newState: Partial<DocumentState>) => {
    globalDispatch(updateDocumentState(newState));
  };

  const getValue = useCallback(
    (key: keyof DocumentState) => documentState?.[key],
    [documentState]
  );

  const handlePieChartValues = (
    value: number,
    pieKey1: string,
    pieKey2: string
  ) => {
    setDocumentState({
      [pieKey1]: value,
      [pieKey2]: 100 - value,
    });
  };

  const handleFloatInput = (
    e: number | string,
    id: string,
    maxLimit?: number
  ) => {
    let value = parseFloat(e as string);
    if (isNaN(value)) {
      value = 0;
    }
    if (value >= 0) {
      const v = maxLimit && value > maxLimit ? maxLimit : value;
      updateAnswer(id, v);
    }
  };

  const updateAnswer = (questionId: string, answer: DocumentAnswer) => {
    const newDoc: DocumentAnswer = {};
    newDoc[questionId] = answer;
    let dependantQuestionState = {};
    if (documentState) {
      dependantQuestionState = handleDependant(documentQuestions, {
        ...documentState,
        ...newDoc,
      });
    }
    setDocumentState({ ...newDoc, ...dependantQuestionState });
  };

  const getQuestionError = useCallback(
    (questionId: string) => {
      return documentErrorState.errors?.find(
        (question) => question.id === questionId
      );
    },
    [documentErrorState]
  );

  return {
    getValue,
    handlePieChartValues,
    updateAnswer,
    handleFloatInput,
    getQuestionError,
  };
};
