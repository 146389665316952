import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.div`
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.47);
  min-height: 100vh;
`;

export const LockContainer = styled.div`
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LockText = styled.div`
  padding-top: ${spacingIncrement(35)};
  font-size: ${spacingIncrement(24)};
  font-weight: 700;
  color: white;
  text-align: center;
`;
