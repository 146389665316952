/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useContext } from "react";
import { getRowsForClientApprovers } from "./service";
import {
  APPROVER_TABLE_HEADERS,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../lib/constants";
import { fetchData, postData } from "../../../services/apis";
import {
  ApprovalUsers,
  ApproverTableRow,
} from "../../../types/admin/approverDetails";
import SearchBox from "../../../components/Search";
import Table from "../../../components/Table";
import PageSize from "../../../components/PageSize";
import Pagination from "../../../components/Pagination";
import NoDataFound from "../../../components/NoDataFoundComponent";
import ApproverAddBox from "../../../components/ApproverAddBox";
import AdminButton from "../../../components/AdminButton";
import { notifyError, notifySuccess } from "../../../utils/common";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { updateIsLoading } from "../../../store/globalActions";

const SfdrClientApproverDetails: React.FC<any> = (props) => {
  const [approverList, setApproverList] = useState<ApproverTableRow[]>([]);
  const [showAddApproverPopup, setAddApproverPopup] = useState<boolean>(false);
  const [selectedApproveUsers, setSelectedApproveUsers] =
    useState<ApprovalUsers[]>();
  const [usersList, setUserList] = useState<[]>([]);
  const [approverTitle, setApproverTitle] = useState<string>("");
  const [approverLevel, setApproverLevel] = useState<number>();
  const [isApproverEdit, setIsApproverEdit] = useState(false);
  const [groupId, setGroupId] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isEditOrDelete, setIsEditOrDelete] = useState(false);
  // const [bulkSelect, setBulkSelect] = useState<object | null>({});
  // const [bulkAction, setBulkAction] = useState(null);

  const { globalDispatch } = useContext<GLOBAL_CONTEXT>(Context);

  const [filters, setFilters] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
    search: "",
    sort: {},
    filters: {},
  });
  useEffect(() => {
    (async function () {
      if (props.clientName) {
        globalDispatch(updateIsLoading(true));
        const status = await fetchData({
          endPoint: "getCLientApprovalStatus",
          queryParam: {
            clientName: props.clientName,
            clientId: props.clientId,
            search: filters,
          },
        })
          .catch((e) => {
            console.log(e);
            setApproverList([]);
          })
          .finally(() => {
            globalDispatch(updateIsLoading(false));
          });
        if (status && status.data.data.length) {
          setTotalCount(status.data.data[0].count);
          setApproverList(getRowsForClientApprovers(status.data.data));
        } else {
          setApproverList([]);
        }
        const records = await postData({
          endPoint: "checkForEditAndDeleteGroup",
          data: {
            clientId: props.clientId,
          },
        })
          .catch((e) => {
            console.log(e);
            setIsEditOrDelete(true);
          })
          .finally(() => {
            globalDispatch(updateIsLoading(false));
          });
        if (records && records.data.data.length > 0) {
          setIsEditOrDelete(true);
        } else {
          setIsEditOrDelete(false);
        }
      }

      const usersLists: any = await fetchData({
        endPoint: "getClientUserList",
        queryParam: {
          clientId: props.clientId,
        },
      })
        .catch((e) => {
          console.log(e);
          setUserList([]);
        })
        .finally(() => {
          globalDispatch(updateIsLoading(false));
        });
      if (usersLists.data && usersLists.data.data.length) {
        setUserList(usersLists.data.data);
      }
    })();
  }, [props.clientName, props.clientId, filters]);

  const saveApprovalStatus = async () => {
    if (
      !/^[ A-Za-z0-9_]*$/.test(approverTitle) ||
      /^[0-9]*$/.test(approverTitle)
    ) {
      notifyError("Enter only alphanumeric characters in Group Title field");
      return;
    }
    globalDispatch(updateIsLoading(true));
    const AddApproverObj = {
      approvalGroupTitle: approverTitle,
      approvalLevel: approverLevel,
      approvalUsers: selectedApproveUsers,
      deletedAt: null,
      groupId: groupId,
    };

    const result = await postData({
      endPoint: "saveClientApprovalStatus",
      data: {
        clientName: props.clientName,
        clientId: props.clientId,
        approvalGroup: AddApproverObj || [],
        isEditable: isApproverEdit,
      },
    })
      .catch((e) => {
        console.log(e);
        notifyError("Error while saving client approval group");
      })
      .finally(() => {
        globalDispatch(updateIsLoading(false));
      });
    if (result && result.data.success) {
      setAddApproverPopup(false);
      if (result.data.data === "Success") {
        notifySuccess("Approval Group saved successfully");
      } else {
        notifyError(result.data.data);
      }
    }
    getApporvalList();
  };

  const onSearchChange = (event: any) => {
    if (event.key === "Enter") {
      setFilters({
        ...filters,
        search: event.target.value,
        pageNumber: DEFAULT_PAGE_NUMBER,
      });
    }
  };

  const onPageSizeChange = ({ target }: any) => {
    setFilters({
      ...filters,
      pageSize: target.value,
      pageNumber: DEFAULT_PAGE_NUMBER,
    });
  };

  const handlerShowAddApprover = () => {
    setAddApproverPopup(true);
    setApproverTitle("");
    setApproverLevel(undefined);
    setIsApproverEdit(false);
    setSelectedApproveUsers(undefined);
    setGroupId("");
  };

  const editApproverHandler = (editRecord: ApproverTableRow) => {
    setIsApproverEdit(true);
    setApproverStatusDetails(editRecord);
    setAddApproverPopup(true);
  };

  const setApproverStatusDetails = (approver: ApproverTableRow) => {
    // setApproverStatusDetail({ groupId: approver.groupId, users: approver.users,  });
    if (approver?.groupId && approver?.users) {
      setGroupId(approver.groupId);
      setSelectedApproveUsers(approver.users);
      setApproverTitle(approver.dataField[0].cellVal as string);
      setApproverLevel(approver.dataField[1].cellVal as number);
    }
  };

  const getApporvalList = async () => {
    globalDispatch(updateIsLoading(true));
    const status: any = await fetchData({
      endPoint: "getCLientApprovalStatus",
      queryParam: {
        clientName: props.clientName,
        clientId: props.clientId,
        search: filters,
      },
    })
      .catch((e) => {
        console.log(e);
        setApproverList([]);
        notifyError("Error while fetching client approval groups");
      })
      .finally(() => {
        // Loader.hideLoader();
        globalDispatch(updateIsLoading(false));
      });

    if (status.data.data && status.data.data.length) {
      setTotalCount(status.data.data[0].count);
      setApproverList(getRowsForClientApprovers(status.data.data));
    } else {
      setApproverList([]);
    }
  };

  const onPaginationChange = ({ target }: any) => {
    setFilters({ ...filters, pageNumber: target.value });
  };

  const onapproverTitleChangeHandler = ({ target }: any) => {
    setApproverTitle(target.value.toString());
  };

  const onapproverLevelChangeHandler = ({ target }: any) => {
    setApproverLevel(target.value);
  };

  const handleSortChange = (data: any) => {
    setFilters({ ...filters, sort: data });
  };

  // function handleBulkSelect(selectedList: any) {
  //   if (selectedList && Object.keys(selectedList).length) {
  //     setBulkSelect({ ...selectedList });
  //   } else {
  //     setBulkSelect(null);
  //   }
  // }

  async function deleteApprovalStatus(approver: ApproverTableRow) {
    if (isEditOrDelete) {
      notifyError(
        "Can not delete this group as there is an documents which are not approved yet"
      );
      return;
    }
    globalDispatch(updateIsLoading(true));
    const deleteApproverobj = [];
    // if (
    //   bulkSelect &&
    //   getBulkSelectCount(bulkSelect) > 0 &&
    //   approver.length > 0
    // ) {
    //   approver.forEach((element: ApproverTableRow) => {
    //     deleteApproverobj.push(element.groupId);
    //   });
    // } else {
    deleteApproverobj.push(approver?.groupId);
    // }

    const result = await postData({
      endPoint: "deleteClientApprovalGroup",
      data: {
        clientName: props.clientName,
        clientId: props.clientId,
        approvalGroup: deleteApproverobj || [],
      },
    })
      .catch((e) => {
        console.log(e);
        notifyError("Error while deleting client approval group");
      })
      .finally(() => {
        globalDispatch(updateIsLoading(false));
      });
    if (result && result.data.success) {
      if (result.data.data === "Success") {
        notifySuccess("Approval group Deleted successfully");
        // setBulkAction(null);
        // setBulkSelect(null);
        getApporvalList();
      } else {
        notifySuccess(result.data.data);
      }
    }
  }

  return (
    <>
      <div className="container" style={{ padding: "0px" }}>
        <div className="page-actions">
          <div className="row">
            <div className="col-auto col-fix-right">
              <AdminButton
                icon="plus-12"
                name="Add Approver"
                onButtonClick={handlerShowAddApprover}
              />
            </div>
            <div className="col-auto ">
              <SearchBox onKeyPress={onSearchChange} />
            </div>
          </div>
        </div>
        {approverList && approverList.length > 0 ? (
          <>
            <div className="page-body">
              <Table
                tableHeaders={APPROVER_TABLE_HEADERS}
                tableRows={approverList}
                sortOnColumns={["approvalLevel", "approvalGroupTitle"]}
                onSortChange={handleSortChange}
                bulkSelected={[]}
                // getBulkSelect={handleBulkSelect}
                onEdit={editApproverHandler}
                onDelete={deleteApprovalStatus}
              />
            </div>

            <div className="page-footer">
              <div className="row row--space-between">
                <div className="col-auto">
                  <PageSize
                    pageSize={filters.pageSize.toString()}
                    handleChange={onPageSizeChange}
                  />
                </div>
                <div className="col-auto">
                  <nav aria-label="Page navigation">
                    <Pagination
                      pageSize={filters.pageSize}
                      totalRecords={totalCount}
                      handleChange={onPaginationChange}
                    />
                  </nav>
                </div>
              </div>
            </div>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
      {showAddApproverPopup && (
        <ApproverAddBox
          header={<h3>Create Approval Group</h3>}
          onModalClose={() => {
            setAddApproverPopup(false);
          }}
          showModal={showAddApproverPopup}
          onSave={saveApprovalStatus}
          config={{ style: { height: "90vh", width: "50%" } }}
          onapproverTitleChangeHandler={onapproverTitleChangeHandler}
          onapproverLevelChangeHandler={onapproverLevelChangeHandler}
          approverTitle={approverTitle}
          approverLevel={approverLevel}
          isEditable={isApproverEdit ? isEditOrDelete : false}
          selectedApproveUsers={selectedApproveUsers}
          setSelectedApproveUsers={(e: any) => {
            if (e && e.length) {
              setSelectedApproveUsers(e);
            } else setSelectedApproveUsers([]);
          }}
          usersList={usersList}
        />
      )}
    </>
  );
};

export default SfdrClientApproverDetails;
