import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const Container = styled.div`
  padding-top: ${spacingIncrement(90)};
  text-align: center;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #9a9a9a;
`;
