import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${spacingIncrement(20)};
  gap: ${spacingIncrement(12)};
`;
