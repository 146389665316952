import React from "react";
import { ImageProps } from "../types";

const Pencil: React.FC<ImageProps> = ({ color = "#fff", ...otherProps }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0507 3.98071C22.4187 3.82819 22.8131 3.74979 23.2113 3.75C23.6096 3.75022 24.0039 3.82905 24.3717 3.98197C24.6496 4.09754 24.9082 4.25371 25.1392 4.44493C25.5989 4.99454 25.8531 5.69058 25.8523 6.4125C25.8514 7.21957 25.532 7.99363 24.9634 8.56635L24.9617 8.56811L24.0244 9.50525L20.1127 5.59351L21.0674 4.63883C21.3486 4.35689 21.6828 4.13324 22.0507 3.98071ZM17.461 8.24516L3.75 21.9562V25.867H7.66091L21.3726 12.1568L17.461 8.24516ZM25.8115 0.519412C26.2877 0.717434 26.7386 0.968689 27.1559 1.26761C27.3234 1.35375 27.4807 1.46678 27.6213 1.60672L27.9702 1.95392L28.0119 1.99542C28.1649 2.14912 28.2858 2.32271 28.3745 2.50784C29.1699 3.64628 29.6038 5.00932 29.6023 6.41654C29.6004 8.21391 28.8882 9.93767 27.6208 11.2122L27.617 11.2161L9.76326 29.0679C9.41163 29.4195 8.93475 29.617 8.4375 29.617H1.875C0.839466 29.617 0 28.7776 0 27.742V21.1795C0 20.6823 0.197544 20.2053 0.549175 19.8537L18.4134 1.98948C19.0431 1.35851 19.7911 0.857996 20.6146 0.516605C21.4384 0.175077 22.3216 -0.000479074 23.2134 9.81869e-07C24.1052 0.000484175 24.9881 0.176994 25.8115 0.519412Z"
        fill={color}
      />
    </svg>
  );
};

export default Pencil;
