import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2000;
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background-color: rgba(102, 102, 102, 0.44);
`;
