import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";
export const ContentContainer = styled.div`
  padding: ${spacingIncrement(32)} ${spacingIncrement(25)};
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacingIncrement(20)};
  gap: ${spacingIncrement(16)};
`;
