import React from "react";
import Modal from "../../../../components/Modal";
import { ModalContentWrapper } from "../../ActionButtons/styles";
import Button from "../../../../components/Button";
import { ButtonContainer } from "./styles";
import styled from "styled-components";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};
export const ButtonGroup = styled(ButtonContainer)`
  justify-content: flex-start;
`;

const ConfirmationModal: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Amendment confirmation"
        width={650}
      >
        <ModalContentWrapper>
          <p>
            Please note that, changes once made cannot be undone, please check
            before submitting.
          </p>
          <p>Are you sure you want to apply the changes?</p>
          <ButtonGroup>
            <Button buttonType="solid" onClick={onConfirm}>
              Confirm
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ButtonGroup>
        </ModalContentWrapper>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
