import styled from "styled-components";
import { spacingIncrement } from "../../../../../utils/common";

export const StyledInput = styled.input`
  background-color: transparent;
  border: 1px solid #d9dae3;
  min-width: ${spacingIncrement(100)};
  width: 100%;
  border-radius: 5px;
  padding: 10px 9px;
  font-size: ${spacingIncrement(13)};
  margin-right: 15px;
  :focus {
    outline: none;
  }
`;
