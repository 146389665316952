import { TableRows } from "../../../components/Table/types";

export function getRowsForClientData(data: any) {
  const rows: TableRows = [];
  for (const field of data) {
    rows.push({
      id: field.id,
      clientName: field.client_name,
      dataField: [{ cellVal: field.client_name }],
      config: { showView: true },
    });
  }
  return rows;
}
