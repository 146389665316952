import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";
import Collapsible from "react-collapsible";

export const Container = styled.div`
  padding: ${spacingIncrement(25)};
  button + button {
    margin-left: ${spacingIncrement(20)};
  }
`;

export const MessageText = styled.p`
  padding: ${spacingIncrement(25)};
  font-size: ${spacingIncrement(18)};
  color: #303245;
  margin-bottom: 0;
`;
