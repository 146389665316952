import React, { useContext, useEffect, useMemo, useState } from "react";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "../../lib/constants";
import { fetchData, postData } from "../../services/apis";
import Context from "../../store/context";
import {
  updateArchivedDocumentList,
  updateIsLoading,
  updateStoredFiltersForArchived,
  updateTotalDocumentsCount,
} from "../../store/globalActions";
import {
  DocumentRecord,
  DocumentStatusEnum,
} from "../../types/document-details";
import { DocumentFilter } from "../../types/fund";
import {
  BulkSelect,
  BulkSelected,
  FilterField,
  TableHeader,
} from "../../types/listing-table";
import { GLOBAL_CONTEXT } from "../../types/store";
import ActionButtons from "./ActionButtons";
import ClientFilters from "../../components/ClientFilters";
import ClientPagination from "../../components/ClientPagination";
import ClientTable, { SortState } from "./ClientTableArchived";
import {
  getFilterPrepDetails,
  getRowsForList,
  prepareHeadersForTable,
  setCachedFilters,
} from "../../services/listing";
import { FilterWrapper } from "../listing/styles";
import { io } from "socket.io-client";
import plausibleInstance from "../../services/plausible";
import EmptyTableMessage from "../../components/EmptyTableMessage";

export type FilterType = {
  pageSize: number;
  pageNumber: number;
  search: string;
  sort: SortState | null;
  filters: DocumentFilter[];
};

export const initialFilterState: FilterType = {
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: DEFAULT_PAGE_NUMBER,
  search: "",
  sort: {},
  filters: [],
};

const ArchivedListing: React.FC = () => {
  const [bulkSelect, setBulkSelect] = useState<BulkSelect | null>({});
  const [bulkSelectedIds, setBulkSelectedIds] = useState<BulkSelected[]>([]);
  const [filterConfig, setFilterConfig] = useState<FilterField[] | undefined>();
  const [headerConfig, setHeaderConfig] = useState<TableHeader[] | undefined>();
  const [sortConfig, setSortConfig] = useState<string[] | undefined>();
  const [filterCount, setFilterCount] = useState<number>(0);

  const {
    globalState: {
      selectedFund,
      archivedDocumentList: documentList,
      archivedFilters: filters,
      userDetails,
    },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);
  useEffect(() => {
    if (
      userDetails?.clientId &&
      selectedFund?.fund_level_one_id &&
      selectedFund?.fund_slug
    ) {
      plausibleInstance.trackPageview(
        {},
        {
          props: {
            client_id: userDetails.clientId,
            fund_id: selectedFund.fund_level_one_id,
            fund_slug: selectedFund.fund_slug,
          },
        }
      );
    }
  }, [userDetails, selectedFund]);

  const setFilters = (newFilters: FilterType) => {
    globalDispatch(updateStoredFiltersForArchived(newFilters));

    setCachedFilters(newFilters, true);
  };

  const getAppliedFilter = async (filterData: DocumentFilter[]) => {
    const newFilterData = filterData.filter((f) => !!f.filterValues.length);
    setFilters({
      ...filters,
      filters: [...newFilterData],
      pageNumber: DEFAULT_PAGE_NUMBER,
    });
  };

  const handleSortChange = (data: SortState | null) => {
    setFilters({ ...filters, sort: data });
  };

  const handleBulkSelect = (selectedList: BulkSelect | null) => {
    if (selectedList && Object.keys(selectedList).length) {
      const bk = { ...selectedList };
      const selectedDocs = Object.entries(bk)
        .filter((entry) => {
          const [key, value] = entry;
          if (key === "selectAll" || value === false) {
            return false;
          }
          return true;
        })
        .map((entry) => {
          return entry[0];
        });
      const selectedDocumentsDetails = selectedDocs.map((id): BulkSelected => {
        const doc = documentList.find(
          (doc) => doc._id === id
        ) as DocumentRecord;
        return {
          id,
          approvalDetails: doc.approvalDetails,
          approvalGroups: doc.approvalGroups,
          isApproved: [
            DocumentStatusEnum.approved,
            DocumentStatusEnum.filed,
          ].includes(doc?.status),
          article: doc.article,
          template: doc.template,
          status: doc.status,
          subFundName: doc.subFundName,
          subFundSlug: doc.subFundSlug,
          fundName: doc.fundName,
          version: doc?.approvedVersion,
          language: doc.language,
          lockStatus: false,
        };
      });
      selectedDocumentsDetails.length
        ? setBulkSelectedIds(selectedDocumentsDetails)
        : setBulkSelectedIds([]);
      setBulkSelect(bk);
    } else {
      setBulkSelect(null);
    }
  };

  const onPaginationChange = (pageNumber: number) => {
    setBulkSelect(null);
    setFilters({ ...filters, pageNumber });
  };

  const onPageSizeChange = (value: number) => {
    if (value && filters.pageSize.toString() !== value.toString()) {
      setFilters({
        ...filters,
        pageSize: value,
        pageNumber: DEFAULT_PAGE_NUMBER,
      });
    }
  };

  const updateDocList = async () => {
    try {
      if (selectedFund?.client_id && selectedFund?.id) {
        globalDispatch(updateIsLoading(true));
        const listResponse = await postData({
          endPoint: "getDocumentList",
          data: {
            ...filters,
            archived: true,
            clientId: selectedFund?.client_id,
            fundId: selectedFund?.id,
          },
        });
        globalDispatch(
          updateArchivedDocumentList(listResponse.data.data.records)
        );
        globalDispatch(updateIsLoading(false));
        globalDispatch(
          updateTotalDocumentsCount(
            listResponse.data.data?.totalCount
              ? listResponse.data.data.totalCount
              : 0
          )
        );
        setFilterCount(
          listResponse.data.data?.filterCount
            ? listResponse.data.data.filterCount
            : 0
        );
      }
    } catch (error) {
      console.log(error);
      globalDispatch(updateIsLoading(false));
    }
  };

  const updateDocConfig = async () => {
    if (selectedFund?.client_id && selectedFund?.id) {
      try {
        globalDispatch(updateIsLoading(true));
        const tableConfigResponse = await fetchData({
          endPoint: "documentTableConfigs",
          queryParam: {
            clientId: selectedFund?.client_id,
            archived: "yes",
          },
        });
        const filterConfigResponse = await postData({
          endPoint: "documentListFilterConfigs",
          data: {
            clientId: selectedFund?.client_id,
            fundId: selectedFund?.id,
            archived: true,
          },
        });
        setSortConfig(tableConfigResponse.data?.data?.sortFields);
        setHeaderConfig(tableConfigResponse.data?.data?.headers);
        setFilterConfig(filterConfigResponse.data.data?.filterFields);
        updateDocList();
      } catch (error) {
        console.log(error);
      } finally {
        globalDispatch(updateIsLoading(false));
      }
    }
  };

  useEffect(() => {
    updateDocConfig();
  }, [selectedFund?.client_id, selectedFund?.id]);

  useEffect(() => {
    clearSelectedBulkIds();
    updateDocList();
  }, [filters]);

  const clearSelectedBulkIds = () => {
    setBulkSelectedIds([]);
    setBulkSelect(null);
  };

  const filteredSubFundNames = useMemo(() => {
    return filterConfig?.find((fc) => fc.field === "subFundName")?.values || [];
  }, [filterConfig]);

  return (
    <div>
      <div className="page-actions page-actions--dark">
        <div className="container">
          <FilterWrapper className="row flex-wrap">
            <ClientFilters
              filterData={getFilterPrepDetails(filterConfig)}
              appliedFilter={getAppliedFilter}
              preSelectedFilters={filters.filters}
            />
            <ActionButtons
              onCreateDocSuccess={updateDocList}
              selectedBulkIds={bulkSelectedIds}
              clearSelectedBulkIds={clearSelectedBulkIds}
              filteredSubFundNames={filteredSubFundNames}
              updateDocList={updateDocList}
            />
          </FilterWrapper>
        </div>
      </div>
      <ClientTable
        tableHeaders={prepareHeadersForTable(headerConfig)}
        tableRows={getRowsForList(documentList, [], true)}
        sortOnColumns={sortConfig}
        onSortChange={handleSortChange}
        bulkSelected={bulkSelect}
        getBulkSelect={handleBulkSelect}
        updateDocList={updateDocList}
      />
      {documentList.length ? (
        <ClientPagination
          pageSize={filters.pageSize}
          handlePageSize={onPageSizeChange}
          totalRecords={filterCount}
          handleChange={onPaginationChange}
          maxPageSelectSize={6}
          activePageNumber={filters.pageNumber}
        />
      ) : (
        <EmptyTableMessage>There are no documents listed.</EmptyTableMessage>
      )}
    </div>
  );
};

export default ArchivedListing;
