import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${spacingIncrement(15)};
  row-gap: ${spacingIncrement(25)};
  padding: ${spacingIncrement(25)} ${spacingIncrement(25)}
    ${spacingIncrement(18)} ${spacingIncrement(25)};
`;

export const NoteContainer = styled.div`
  font-size: ${spacingIncrement(18)};
  font-weight: 400;
  color: #000000;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: ${spacingIncrement(32)};
  left: ${spacingIncrement(30)};
  gap: ${spacingIncrement(15)};
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacingIncrement(10)};
  padding: ${spacingIncrement(9)} ${spacingIncrement(15)};
  cursor: pointer;

  input[type="checkbox"] {
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    margin: 0;
    color: currentColor;
    width: ${spacingIncrement(16)};
    height: ${spacingIncrement(16)};
    border-radius: ${spacingIncrement(2)};
    border: 1px solid #dee2e6;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: middle;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em white;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:checked {
    border: 1px solid #0abaee;
    background-color: #0abaee;
  }

  input[type="checkbox"]:disabled {
    color: #959495;
    cursor: not-allowed;
  }
`;

export const CheckBox = styled.input`
  display: flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbd9d9;
  ::after {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: middle;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em red;
  }
`;

export const Label = styled.div`
  color: #303245;
  font-size: ${spacingIncrement(16)};
  font-weight: 400;
`;
