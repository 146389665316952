import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../../components/Image";
import {
  CLIENT_URL_PREFIX,
  DOCUMENT_EDITABLE_STATUS,
} from "../../../lib/constants";
import { fetchData, postData } from "../../../services/apis";
import Context from "../../../store/context";
import { FundList, SubFund } from "../../../types/fund";
import { GLOBAL_CONTEXT } from "../../../types/store";
import {
  filterAllLockedDocuments,
  getExcelExportFileName,
  notifyError,
  notifySuccess,
} from "../../../utils/common";
import DocumentCreateModal, { OnCreateProps } from "./DocumentCreateModal";
import { Conatiner } from "./styles";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { updateIsLoading } from "../../../store/globalActions";
import { DocumentStatusEnum, PageType } from "../../../types/document-details";
import { ListItem, NameContainer } from "../ClientTable/styles";
import DownloadModal from "../../templates/CommonComponents/DownloadModal";
import { getDownloadableFileName } from "../../../services/clientTable";
import { BulkActions, BulkSelected } from "../../../types/listing-table";
import BulkAmendmentSidePanel from "../BulkAmendmentSidePanel";
import { BulkStatusUpdateModal } from "../BulkStatusUpdate";
import { WordRequestModal } from "./wordRequestModal";
import { TUserDetails } from "../../../types/user-details";
import {
  getDownloadBulkData,
  validateRequestWordDoc,
} from "../../../utils/wordRequest";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";
import ImportExcelModal from "./ImportExcelModal";
import Tooltip from "../../../components/Tooltip";
import ArchiveDocumentsModal from "./ArchiveDocumentsModal";
import Modal from "../../../components/Modal";
import { DocumentArchiveConfirmationModal } from "./ConfirmationModal";

type Props = {
  onCreateDocSuccess: () => void;
  selectedBulkIds?: BulkSelected[];
  clearSelectedBulkIds?: () => void;
  updateDocList: () => void;
  filteredSubFundNames: string[];
};

const ActionButtons: React.FC<Props> = ({
  onCreateDocSuccess,
  selectedBulkIds = [],
  clearSelectedBulkIds,
  updateDocList,
  filteredSubFundNames,
}) => {
  const navigate = useNavigate();
  const [bulkArchiveModal, setBulkArchiveModal] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [importExcelModal, setImportExcelModal] = useState(false);
  const [archiveDocumentsModal, setArchiveDocumentsModal] = useState(false);
  const [requestWordDoc, setRequestWordDoc] = useState(false);
  const [bulkStatusUpdateModal, setBulkStatusUpdateModal] = useState(false);
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
  const [subFundList, setSubFundList] = useState<SubFund[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    globalState: { selectedFund, userDetails },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [amendType, setAmendType] = useState<"answer" | "findReplace">();

  const isAdminUser = useMemo(() => {
    return userDetails?.userType === "admin";
  }, [userDetails?.userType]);

  useEffect(() => {
    (async function () {
      try {
        if (selectedFund?.id) {
          const subFundListResp = await fetchData({
            endPoint: "getSubFundList",
            queryParam: {
              fundId: selectedFund.id,
              clientId: selectedFund?.client_id,
            },
          });
          if (
            subFundListResp &&
            subFundListResp.data &&
            subFundListResp.data.data
          ) {
            setSubFundList(subFundListResp.data.data);
          }
        } else {
          setSubFundList([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedFund?.id]);

  const toggleCreateDocument = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const toggleImportExcel = () => {
    setImportExcelModal(!importExcelModal);
  };

  const toggleArchiveDocumentsModal = () =>
    setArchiveDocumentsModal((state) => !state);

  const handleCreateDocument = async (document: OnCreateProps) => {
    setLoading(true);
    let reqBody = {
      fundId: selectedFund?.id,
      fundName: selectedFund?.name,
      clientId: selectedFund?.client_id,
      subFundId: document.subFundId,
      subFundName: document.subFundName,
      article: document.article,
      template: document.template,
      language: document.language,
    };
    if (document.isCloneEnable) {
      reqBody = {
        ...reqBody,
        ...{ documentState: document.documentStateForClone?.documentState },
      };
    }
    try {
      const resp = await postData({
        endPoint: "createNewDocument",
        data: reqBody,
      });
      if (resp.data && resp.data?.success) {
        notifySuccess("Document created successfully");
        onCreateDocSuccess();
        navigate(
          CLIENT_URL_PREFIX +
            "document/edit/" +
            selectedFund?.client_id +
            "/" +
            resp.data.data._id,
          {
            replace: true,
          }
        );
      } else {
        throw new Error(resp.data.message);
      }
    } catch (e: any) {
      notifyError(e?.message);
    } finally {
      setIsCreateModalOpen(false);
      setLoading(false);
    }
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName =
    selectedFund?.client_name + "_" + selectedFund?.name + "_Public_URL";
  const filteredSubFunds = useMemo(
    () =>
      subFundList.filter((subFund) => {
        return filteredSubFundNames.includes(subFund.name);
      }),
    [subFundList, filteredSubFundNames]
  );
  const handleBulKPublicUrlLink = async () => {
    try {
      const csvData: any[] = [];
      selectedBulkIds.map((data: any) => {
        const {
          fundName,
          subFundName,
          article,
          template,
          version,
          language,
          subFundSlug,
        } = data;
        if (data.status === DocumentStatusEnum.filed) {
          const publicUrl = `${
            process.env.REACT_APP_REDIRECT_URL
          }/sfdrDocument/${subFundSlug}/${article
            .split(" ")
            .join("")
            .toLowerCase()}/${template?.toLowerCase()}/${language?.toLowerCase()}`;

          const rowData = {
            "Fund Name": fundName,
            "Sub Fund Name": subFundName,
            Version: version,
            "Public URL": publicUrl,
          };
          csvData.push(rowData);
        }
      });
      if (csvData.length) {
        const wscols = [
          { width: 60, bgColor: "green" },
          { width: 50 },
          { width: 10 },
          { width: 100 },
        ];
        const ws = XLSX.utils.json_to_sheet(csvData);
        XLSX.utils.sheet_add_json(ws, csvData, { origin: "A2" });

        ws["!cols"] = wscols;
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              " Please note: this file only contains public urls for Filed documents",
              "",
              "",
              "",
            ],
          ],
          { origin: "A1" }
        );
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
        notifySuccess(
          "Requested Pulic Urls have been successfully downloaded for documents with 'Filed' status",
          { right: "47%" }
        );
      } else {
        notifySuccess("No,'Filed' Documents available to Get Public url.");
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      clearSelectedBulkIds?.();
    }
  };
  const handleOnBulkDeleteClick = async () => {
    try {
      const response = await postData({
        endPoint: "deleteDocuments",
        data: {
          documentIds: selectedBulkIds,
          clientId: selectedFund?.client_id,
        },
      });
      if (response.data?.success) {
        notifySuccess("Selected Document/s deleted successfully");
        updateDocList();
      } else {
        notifyError("Document/s deletion failed");
      }
    } catch (error: any) {
      notifyError(error.message || "Documents/s deletion failed");
    } finally {
      clearSelectedBulkIds?.();
      setBulkDeleteModal(false);
    }
  };
  const handleRequestWordModalClose = () => {
    setRequestWordDoc(false);
    clearSelectedBulkIds?.();
  };
  const handleDownloadPDF = async (
    unFormatted = false,
    showPageNumber = true
  ) => {
    try {
      globalDispatch(updateIsLoading(true));
      let reqBody;
      if (selectedBulkIds.length === 1) {
        const [doc] = selectedBulkIds;
        const isApproved =
          doc?.status &&
          [DocumentStatusEnum?.approved, DocumentStatusEnum?.filed].includes(
            doc?.status
          );
        reqBody = {
          clientId: userDetails?.clientId,
          documentId: doc.id,
          url: `${window.location.origin}${CLIENT_URL_PREFIX}document/view/${
            selectedFund?.client_id
          }/${doc.id}/pdf/${
            !isApproved ? (unFormatted ? "" : "showWaterMark") : ""
          }`,
          unFormatted: unFormatted,
        };
      } else {
        reqBody = {
          clientId: userDetails?.clientId,
          downloadData: getDownloadBulkData(
            selectedBulkIds,
            unFormatted,
            selectedFund,
            showPageNumber
          ),
          showPageNumber: false,
        };
      }
      const response = await postData({
        endPoint: reqBody.url ? "generatePdf/single" : "generatePdf/multiple",
        responseType: "blob",
        data: reqBody,
      });
      if (selectedBulkIds.length === 1) {
        const { subFundName, article, template, status, version, language } =
          selectedBulkIds[0];
        subFundName &&
          article &&
          template &&
          status &&
          saveAs(
            response.data,
            `${getDownloadableFileName(
              subFundName,
              article,
              template,
              status,
              language,
              version
            )}.pdf`
          );
        notifySuccess("Requested document is downloaded successfully");
      } else {
        if (response?.status === 200) {
          notifySuccess(
            <div style={{ textAlign: "center" }}>
              <div>We are generating the requested PDF file for you.</div>
              <div>
                You will shortly receive an e-mail with a download link.
              </div>
            </div>,
            { width: "500px", left: "calc(50% - 250px)", textAlign: "center" }
          );
        } else {
          notifyError(
            'Oops! Something went wrong, "Please try after sometime."'
          );
        }
      }

      clearSelectedBulkIds?.();
      globalDispatch(updateIsLoading(false));
    } catch (error) {
      console.log(error);
      globalDispatch(updateIsLoading(false));
      clearSelectedBulkIds?.();
      notifyError("Error occured while downloading, Please try again");
    }
  };
  const handleUnformattedPdfDownload = (showPageNumber: boolean) => {
    handleDownloadPDF(true, showPageNumber);
  };
  const handlePdfDownload = (showPageNumber: boolean) => {
    handleDownloadPDF(false, showPageNumber);
  };
  function checkLockDocumentsSelected() {
    const isLockedDocumentsSelected = selectedBulkIds.find((document) => {
      return document?.lockStatus === true;
    });
    return !!isLockedDocumentsSelected;
  }
  function checkIsAllDocumentsSameArticleTemplate() {
    const isSameArticleTemplateLanguage = selectedBulkIds.every((document) => {
      return (
        document?.article === selectedBulkIds[0]?.article &&
        document?.template === selectedBulkIds[0]?.template &&
        document?.language === selectedBulkIds[0]?.language
      );
    });
    return isSameArticleTemplateLanguage;
  }
  function checkIsDocuemntStatusValidForFindAndReplace() {
    const isDocumentValidForFindAndReplace = selectedBulkIds.every(
      (document) => {
        return (
          document?.status &&
          DOCUMENT_EDITABLE_STATUS.includes(document?.status)
        );
      }
    );
    return isDocumentValidForFindAndReplace;
  }
  function handleAmendmentAction(type: "answer" | "findReplace") {
    const isValidStatus = checkIsDocuemntStatusValidForFindAndReplace();
    const isArticleTemplateValid = checkIsAllDocumentsSameArticleTemplate();
    const isLockedDocumentsSelected = checkLockDocumentsSelected();
    if (isValidStatus && isArticleTemplateValid && !isLockedDocumentsSelected) {
      setAmendType(type);
      setSidePanelOpen(true);
    } else {
      setAmendType(undefined);
      selectedBulkIds.length && clearSelectedBulkIds?.();
      if (!isValidStatus) {
        notifyError(
          `Please select documents with only “Draft” and "Awaiting comments" status`
        );
        return;
      }
      if (!isArticleTemplateValid) {
        notifyError(
          "Bulk Amendment can only be applied across the same template, article and language type. Please check your selection and try again."
        );
        return;
      }
      if (isLockedDocumentsSelected) {
        notifyError(
          `Please note that the documents with lock cannot be applicable for amendment`
        );
        return;
      }
    }
  }
  const handleDelete = () => {
    const nonDraftDocument = selectedBulkIds
      .map((s) => s.status)
      .filter((s) => s !== DocumentStatusEnum.draft);
    if (nonDraftDocument.length) {
      notifyError(
        "Delete action can only be applied to documents in DRAFT status. Please check your selection and try again."
      );
      return;
    }
    const lockedDocuments = filterAllLockedDocuments(selectedBulkIds);
    if (lockedDocuments.length) {
      notifyError(
        `Please note that locked document/s are not applicable for bulk deletion.`
      );
      return;
    }
    setBulkDeleteModal(true);
  };
  const handleArchiveButtonClick = () => {
    setBulkArchiveModal(true);
  };
  const handleBulkArchiveModalClose = () => {
    setBulkArchiveModal(false);
    clearSelectedBulkIds?.();
  };
  const handleArchiveDocuments = () => {
    const lockedDocuments = filterAllLockedDocuments(selectedBulkIds);
    if (lockedDocuments.length) {
      notifyError(
        `Please note that locked document/s are not applicable for bulk archive.`
      );
      return;
    }
    const documentIds = selectedBulkIds.map((doc) => doc.id);
    postData({
      endPoint: "archiveByIds",
      data: {
        documentIds,
        clientId: selectedFund?.client_id,
      },
    })
      .then((res) => {
        if (res.data?.success) {
          notifySuccess("Selected Document/s archived successfully");
          updateDocList();
        } else {
          notifyError("Document/s archiving failed");
        }
      })
      .catch((error: any) => {
        notifyError(error.message || "Document/s archiving failed");
      })
      .finally(() => {
        clearSelectedBulkIds?.();
        handleBulkArchiveModalClose();
      });
  };
  const syncSubFundLei = async () => {
    if (!isAdminUser) {
      notifyError("You are not authorized to perform this action");
    }
    try {
      const response = await fetchData({
        endPoint: "syncClientSubFundLei",
        queryParam: {
          clientId: selectedFund?.client_id,
        },
      });
      if (response.data?.success) {
        notifySuccess("Sub Fund LEI Synced Successfully");
      } else {
        notifyError("Sub Fund LEI Sync Failed");
      }
    } catch (error) {
      console.log(error);
      notifyError("Sub Fund LEI Sync Failed");
    }
  };
  const handleExportExcel = async () => {
    if (!selectedBulkIds?.length) {
      notifyError("Please select atleast one document to export");
      return;
    }
    if (
      !selectedFund?.client_id ||
      !selectedFund.client_name ||
      !selectedFund.name
    ) {
      return;
    }
    try {
      globalDispatch(updateIsLoading(true));
      const response = await fetchData({
        endPoint: "exportExcel",
        queryParam: {
          clientId: selectedFund.client_id,
          documentIds: selectedBulkIds.map((doc) => doc.id),
        },
        responseType: "blob",
      });
      if (!response.data) {
        notifyError("Excel export failed");
        return;
      }
      if (response.headers["content-type"].includes("application/json")) {
        const res = JSON.parse(await response.data.text());
        if (res.success === false) {
          notifyError(res.message || "Excel export failed");
        }
        return;
      }
      saveAs(response.data, getExcelExportFileName(selectedFund));
      notifySuccess("Excel exported successfully");
    } catch (error: any) {
      notifyError(error.message || "Unknown Error");
    } finally {
      globalDispatch(updateIsLoading(false));
      clearSelectedBulkIds?.();
    }
  };
  const bulkActionsListItems: BulkActions[] = [
    {
      name: "Request Word Doc",
      icon: "requestDocCircle",
      id: "bulk-action-request-word-doc-button",
      classnames: "",
      onClick: () => {
        validateRequestWordDoc(selectedBulkIds) && setRequestWordDoc(true);
      },
    },
    {
      name: "Download",
      icon: "download",
      id: "bulk-action-download-button",
      classnames: "",
      onClick: () => setIsDownloadOpen(true),
    },
    {
      name: "Get Public URL",
      icon: "bulkUrlLink",
      id: "bulk-action-get-public-url-button",
      classnames: "",
      onClick: handleBulKPublicUrlLink,
    },
    {
      name: "Bulk amendment",
      icon: "amendment",
      id: "bulk-action-amendment-button",
      classnames: "dropright innerDropdownHover",
      childItems: [
        {
          name: "Find and Replace",
          icon: "find",
          id: "bulk-action-find-and-replace-button",
          classnames: "",
          onClick: () => handleAmendmentAction("findReplace"),
        },
        {
          name: "Answer amendment",
          icon: "amendment",
          id: "bulk-action-answer-amendment-button",
          classnames: "",
          onClick: () => handleAmendmentAction("answer"),
        },
      ],
    },
    {
      name: "Change Status",
      icon: "exchange",
      id: "bulk-action-change-status-button",
      classnames: "",
      onClick: () => {
        setBulkStatusUpdateModal(true);
      },
    },
    {
      name: "Export Excel",
      icon: "exportExcel",
      id: "bulk-action-export-excel-button",
      classnames: "",
      onClick: handleExportExcel,
    },
    {
      name: "Archive",
      icon: "archiveRound",
      id: "bulk-action-archive-button",
      classnames: "",
      onlyAdmin: true,
      onClick: handleArchiveButtonClick,
    },
    {
      name: "Delete",
      icon: "trashCircle",
      id: "bulk-action-delete-button",
      classnames: "",
      onClick: handleDelete,
    },
  ];

  return (
    <Conatiner>
      {bulkStatusUpdateModal && (
        <BulkStatusUpdateModal
          onClose={() => {
            setBulkStatusUpdateModal(false);
            clearSelectedBulkIds?.();
          }}
          selectedBulkIds={selectedBulkIds}
          updateDocList={updateDocList}
        />
      )}
      <Modal
        isOpen={bulkArchiveModal}
        header="Archive Confirmation"
        onClose={handleBulkArchiveModalClose}
      >
        <DocumentArchiveConfirmationModal
          onConfirm={handleArchiveDocuments}
          onCancel={handleBulkArchiveModalClose}
          message="multiple"
        />
      </Modal>
      <WordRequestModal
        isOpen={requestWordDoc}
        onCloseRequestModal={handleRequestWordModalClose}
        selectedDocs={selectedBulkIds}
        selectedFund={selectedFund as FundList}
        setRequestWordDoc={setRequestWordDoc}
        userDetails={userDetails as TUserDetails}
      />
      <DocumentCreateModal
        isOpen={isCreateModalOpen}
        onCloseModal={toggleCreateDocument}
        subFundList={subFundList}
        onCreate={handleCreateDocument}
        loading={loading}
      />
      <ImportExcelModal
        isOpen={importExcelModal}
        onCloseModal={toggleImportExcel}
      />
      <ArchiveDocumentsModal
        isOpen={archiveDocumentsModal}
        onCloseModal={toggleArchiveDocumentsModal}
        onArchiveSuccess={updateDocList}
        subFundList={filteredSubFunds}
      />
      <DeleteConfirmationModal
        isOpen={bulkDeleteModal}
        onCloseDeleteModal={() => {
          setBulkDeleteModal(false);
          clearSelectedBulkIds?.();
        }}
        numberOfSelectedRows={selectedBulkIds.length}
        onDeleteDocument={handleOnBulkDeleteClick}
      />
      <DownloadModal
        isOpen={isDownloadOpen}
        setIsDownloadOpen={setIsDownloadOpen}
        onPdfDownload={handlePdfDownload}
        onUnformattedPdfDownload={handleUnformattedPdfDownload}
      />
      {sidePanelOpen && amendType && userDetails && (
        <BulkAmendmentSidePanel
          isOpen={sidePanelOpen}
          amendType={amendType}
          selectedBulkIds={selectedBulkIds}
          onClose={() => setSidePanelOpen(false)}
          clearSelectedBulkIds={clearSelectedBulkIds}
          userDetails={userDetails}
        />
      )}
      <div className="d-flex px-0 col-12 col-md-auto align-items-baseline justify-content-end">
        {isAdminUser && (
          <div className="page-action">
            <Tooltip
              placement="top"
              trigger="hover"
              triggerElement={
                <button
                  className="btn btn-outline-primary btn-icon"
                  onClick={toggleArchiveDocumentsModal}
                >
                  <Image name="archiveIcon" />
                </button>
              }
            >
              Archive Documents
            </Tooltip>
          </div>
        )}
        <div className="page-action">
          <Tooltip
            placement="top"
            trigger="hover"
            triggerElement={
              <button
                className="btn btn-outline-primary btn-icon"
                onClick={toggleImportExcel}
              >
                <Image name="importExcel" />
              </button>
            }
          >
            Import Excel
          </Tooltip>
        </div>
        {isAdminUser && (
          <div className="page-action">
            <Tooltip
              placement="top"
              trigger="hover"
              triggerElement={
                <button
                  className="btn btn-outline-primary btn-icon"
                  onClick={syncSubFundLei}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                  >
                    <path
                      d="M0.800017 7.20116C1.01219 7.20116 1.21567 7.28545 1.3657 7.43548C1.51573 7.58551 1.60002 7.78899 1.60002 8.00116C1.59049 9.49958 2.10378 10.9544 3.05149 12.1151C3.9992 13.2758 5.32199 14.0697 6.79204 14.36C8.26209 14.6504 9.78738 14.419 11.1052 13.7058C12.423 12.9925 13.4509 11.8421 14.0117 10.4526C14.5726 9.06303 14.6314 7.52141 14.1779 6.09322C13.7244 4.66503 12.7871 3.43967 11.5274 2.62819C10.2677 1.81671 8.76449 1.4699 7.2766 1.6475C5.78871 1.8251 4.40932 2.51598 3.37601 3.60117H5.29601C5.50818 3.60117 5.71167 3.68545 5.8617 3.83548C6.01172 3.98551 6.09601 4.18899 6.09601 4.40117C6.09601 4.61334 6.01172 4.81682 5.8617 4.96685C5.71167 5.11688 5.50818 5.20116 5.29601 5.20116H1.67202C1.45984 5.20116 1.25636 5.11688 1.10633 4.96685C0.956302 4.81682 0.872017 4.61334 0.872017 4.40117V0.801171C0.872017 0.588998 0.956302 0.385515 1.10633 0.235486C1.25636 0.085457 1.45984 0.00117164 1.67202 0.00117164C1.88419 0.00117164 2.08767 0.085457 2.2377 0.235486C2.38773 0.385515 2.47202 0.588998 2.47202 0.801171V2.21717C3.80428 0.943617 5.53541 0.169133 7.3728 0.0246349C9.21018 -0.119864 11.0411 0.374487 12.5561 1.42412C14.071 2.47376 15.1771 4.01429 15.6873 5.78532C16.1976 7.55634 16.0806 9.44922 15.3563 11.144C14.632 12.8387 13.3447 14.2314 11.7121 15.0866C10.0795 15.9418 8.20161 16.2071 6.39598 15.8375C4.59035 15.468 2.9677 14.4863 1.80231 13.0585C0.636922 11.6307 0.000286102 9.84422 1.81198e-05 8.00116C1.81198e-05 7.78899 0.0843029 7.58551 0.234332 7.43548C0.38436 7.28545 0.587844 7.20116 0.800017 7.20116Z"
                      fill="currentcolor"
                    />
                  </svg>
                </button>
              }
            >
              Sync LEI
            </Tooltip>
          </div>
        )}
        <div className="dropdown page-action">
          <button
            className={`btn btn-primary dropdown-toggle`}
            data-toggle="dropdown"
            disabled={!selectedBulkIds.length}
          >
            Bulk Actions
          </button>
          <div className="dropdown-menu">
            {bulkActionsListItems.map((item) => {
              if (item.onlyAdmin && !isAdminUser) return null;
              return (
                <ListItem
                  key={item.id}
                  style={{ cursor: "pointer" }}
                  minWidth="16rem"
                  id={item.id}
                  className={item.classnames}
                  onClick={() => item?.onClick?.()}
                >
                  <Image name={item.icon} />
                  <NameContainer>{item.name}</NameContainer>
                  {item.childItems?.length ? (
                    <div className="dropdown-menu">
                      {item.childItems.map((child) => {
                        if (child.onlyAdmin && !isAdminUser) return null;
                        return (
                          <ListItem
                            key={child.id}
                            style={{ cursor: "pointer" }}
                            minWidth="16rem"
                            id={child.id}
                            onClick={() => child?.onClick?.()}
                            className={child.classnames}
                          >
                            <Image name={child.icon} />
                            <NameContainer>{child.name}</NameContainer>
                          </ListItem>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </ListItem>
              );
            })}
          </div>
        </div>
        <div className="page-action d-none d-lg-inline">
          <button
            className={`btn btn-primary btn-icon`}
            onClick={toggleCreateDocument}
          >
            <Image name="plus" height="13" width="13" />
            <span
              style={{
                marginRight: "7px",
                marginLeft: "7px",
                color: "white",
              }}
            >
              Create Document
            </span>
          </button>
        </div>
      </div>
    </Conatiner>
  );
};

export default ActionButtons;
