import { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import CustomSelect, {
  OptionDataProps,
} from "../../../components/CustomSelect";
import Modal from "../../../components/Modal";
import Select, { Option } from "../../../components/Select";
import { Label } from "../../../components/Select/styles";
import { fetchData } from "../../../services/apis";
import Context from "../../../store/context";
import {
  DocumentArticle,
  DocumentArticleList,
  DocumentArticleListKeys,
  DocumentRecord,
  DocumentTemplate,
  DocumentTemplateList,
  DocumentTemplateListKeys,
} from "../../../types/document-details";
import { SubFund } from "../../../types/fund";
import { GLOBAL_CONTEXT } from "../../../types/store";
import { spacingIncrement } from "../../../utils/common";
import {
  CloneMessage,
  ConfirmationButtonContainer,
} from "../ClientTable/styles";
import {
  ButtonWrapper,
  ContentContainer,
  CustomCheckboxLabel,
  CustomCheckboxSubLabel,
  DisabledContainer,
  ModalContentWrapper,
  RowContainer,
} from "./styles";

export type OnCreateProps = {
  article: DocumentArticle;
  template: DocumentTemplate;
  subFundId: number;
  subFundName: string;
  language: string;
  isCloneEnable?: boolean;
  documentStateForClone?: DocumentRecord;
};

type Props = {
  isOpen: boolean;
  onCloseModal?: () => void;
  subFundList: SubFund[];
  onCreate?: (document: OnCreateProps) => void;
  loading?: boolean;
};

const DocumentCreateModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  subFundList,
  onCreate,
  loading,
}) => {
  const {
    globalState: { selectedFund, languages },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [article, setArticle] = useState<DocumentArticleListKeys>();
  const [template, setTemplate] = useState<DocumentTemplateListKeys>();
  const [subFundId, setSubFundId] = useState<number>();
  const [subFundName, setSubFundName] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [allApprovedDoc, setAllApprovedDoc] = useState<
    OptionDataProps[] | undefined
  >();
  const [isCloneEnable, setIsCloneEnable] = useState<boolean>();
  const [disableCheckbox, setDisableCheckbox] = useState<boolean>(false);
  const [cloneFromDocument, setCloneFromDocument] = useState<DocumentRecord>();
  const [cloneConfirmModal, setCloneConfirmModal] = useState(false);
  useEffect(() => {
    clearCheckboxAndCloneDoc();
    if (article && template && selectedFund?.id && language) {
      getApprovedDocs();
    }
  }, [article, template, language, selectedFund?.id]);

  const subFundOptions: Option[] = useMemo(
    () =>
      subFundList.map((subFund) => ({
        label: subFund.name,
        value: `${subFund.id}`,
      })),
    [subFundList]
  );

  const articleOptions = DocumentArticleList.map((article) => ({
    label: DocumentArticle[article as DocumentArticleListKeys],
    value: article as DocumentArticleListKeys,
  }));

  const templateOptions = DocumentTemplateList.map((template) => ({
    label: DocumentTemplate[template as DocumentTemplateListKeys],
    value: template as DocumentTemplateListKeys,
  }));

  const onSubmit = () => {
    if (article && template && subFundId && subFundName && language) {
      onCreate?.({
        article: DocumentArticle[article],
        template: DocumentTemplate[template],
        subFundId: subFundId,
        subFundName: subFundName,
        language: language,
        isCloneEnable: isCloneEnable,
        documentStateForClone: cloneFromDocument,
      });
    }
    clearAllFields();
  };

  const onClose = () => {
    clearAllFields();
    onCloseModal?.();
  };

  const getApprovedDocs = async () => {
    if (selectedFund?.client_id && template && article && language) {
      try {
        setDocumentLoading(true);
        const response = await fetchData({
          endPoint: "getPreviousVersions",
          queryParam: {
            clientId: selectedFund?.client_id,
            template: DocumentTemplate[template],
            article: DocumentArticle[article],
            language: language,
            isPreviousVersion: false,
          },
        });
        if (!response.data?.data?.length) {
          setDisableCheckbox(true);
        } else {
          setDisableCheckbox(false);
          setAllApprovedDoc(
            response.data.data.map((record: DocumentRecord) => {
              return {
                ...record,
                archived: !!record.archivedAt,
                label: `${record.fundName} - ${record.subFundName}`,
                value: `${record.fundId} - ${record.subFundId}`,
              };
            })
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDocumentLoading(false);
      }
    }
  };

  const toggleCheck = () => setIsCloneEnable((val) => !val);

  const CustomCheckBoxLabel = () => (
    <CustomCheckboxLabel>
      Approved/Filed Subfunds{" "}
      <CustomCheckboxSubLabel>
        (Only latest documents are listed)
      </CustomCheckboxSubLabel>
    </CustomCheckboxLabel>
  );

  const clearCheckboxAndCloneDoc = () => {
    setIsCloneEnable(undefined);
    setCloneFromDocument(undefined);
  };

  function clearAllFields() {
    setArticle(undefined);
    setTemplate(undefined);
    setSubFundId(undefined);
    setSubFundName(undefined);
    setLanguage(undefined);
    setAllApprovedDoc(undefined);
    setIsCloneEnable(undefined);
    setCloneFromDocument(undefined);
  }
  const onCloseCloneModal = () => {
    setCloneConfirmModal(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Create New Document"
        // height={638}
        width={773}
      >
        <ModalContentWrapper>
          <ContentContainer>
            <div>
              <Label>Fund Name</Label>
              <DisabledContainer>{selectedFund?.name}</DisabledContainer>
            </div>
            <Select
              label="Sub Fund Name"
              options={subFundOptions}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                setSubFundId(e.value);
                setSubFundName(e.label);
              }}
            />
            <Select
              label="Template"
              options={templateOptions}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => setTemplate(e.value)}
            />
            <Select
              label="Article"
              options={articleOptions}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => setArticle(e.value)}
            />
            <Select
              label="Language"
              options={languages}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => setLanguage(e.value)}
            />
          </ContentContainer>
          <RowContainer>
            {article && template && language && !disableCheckbox && (
              <Checkbox
                labelStyle={{ fontSize: spacingIncrement(16) }}
                label="Check, if you want to clone a new document from approved/filed documents list with a different Fund & Subfund"
                onChange={toggleCheck}
                checked={isCloneEnable}
                isDisabled={disableCheckbox}
                id="clone-checkbox"
              />
            )}
            {isCloneEnable && (
              <CustomSelect
                label={<CustomCheckBoxLabel />}
                labelHtml={true}
                options={allApprovedDoc}
                onChange={(e: any) => {
                  setCloneFromDocument(e);
                }}
                isLoading={documentLoading}
              />
            )}
          </RowContainer>
          <ButtonWrapper>
            {!isCloneEnable ? (
              <Button
                id="create-document-button"
                disabled={
                  !(article && template && subFundId && subFundName && language)
                }
                onClick={onSubmit}
                loading={loading}
              >
                Create
              </Button>
            ) : (
              <Button
                disabled={
                  !(
                    article &&
                    template &&
                    subFundId &&
                    subFundName &&
                    cloneFromDocument
                  )
                }
                onClick={() => setCloneConfirmModal(true)}
                loading={loading}
              >
                Clone
              </Button>
            )}
          </ButtonWrapper>
        </ModalContentWrapper>
      </Modal>
      <Modal
        isOpen={cloneConfirmModal}
        onClose={onCloseCloneModal}
        header="Clone Confirmation"
        height={283}
      >
        <CloneMessage>
          Please note that, when you clone a document only the content of it
          will be duplicated for the selected Fund & Subfund.
        </CloneMessage>
        <ConfirmationButtonContainer>
          <Button
            buttonType="solid"
            onClick={() => {
              onCloseCloneModal();
              onSubmit();
            }}
          >
            Ok, Proceed
          </Button>
          <Button onClick={onCloseCloneModal}>Cancel</Button>
        </ConfirmationButtonContainer>
      </Modal>
    </>
  );
};

export default DocumentCreateModal;
