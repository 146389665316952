import { useState } from "react";
import { Flex } from "../CommonComponents/common.styles";
import Button from "../../../components/Button";

export const ExternalRedirectModal = ({
  url,
  closeModal,
}: {
  url: string;
  closeModal: () => void;
}) => {
  return (
    <div style={{ padding: "30px" }}>
      <div>
        Please note that you are being redirected to an external website. Are
        you sure, you want to leave this page?
      </div>
      <Flex style={{ marginTop: "15px" }}>
        <Button
          buttonType="solid"
          style={{ marginRight: "10px" }}
          onClick={() => {
            closeModal();
            window.open(url, "_blank");
          }}
        >
          Ok, Got it
        </Button>
        <Button buttonType="outline" onClick={closeModal}>
          Cancel
        </Button>
      </Flex>
    </div>
  );
};
