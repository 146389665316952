import { FC } from "react";
import { ImageProps } from "../types";

const ImportExcel: FC<ImageProps> = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--small__blue"
    >
      <path
        d="M2.49068 3.14068C2.14311 3.48825 2.14311 4.05177 2.49068 4.39933C2.83825 4.7469 3.40177 4.7469 3.74933 4.39933L5.11003 3.03864L5.11003 7.61C5.11003 8.10153 5.5085 8.5 6.00003 8.5C6.49156 8.5 6.89003 8.10153 6.89003 7.61L6.89003 3.03867L8.25069 4.39933C8.59826 4.7469 9.16178 4.7469 9.50934 4.39933C9.85691 4.05177 9.85691 3.48825 9.50934 3.14068L6.62934 0.260677C6.46243 0.0937707 6.23606 -3.08949e-07 6.00001 -3.29584e-07C5.76397 -3.5022e-07 5.53759 0.0937706 5.37069 0.260677L2.49068 3.14068Z"
        fill="currentColor"
      />
      <path
        d="M1.9 10.2C1.40294 10.2 1 10.6029 1 11.1C1 11.597 1.40294 12 1.9 12H10.1C10.5971 12 11 11.597 11 11.1C11 10.6029 10.5971 10.2 10.1 10.2H1.9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ImportExcel;
