import React from "react";
import { ImageProps } from "../types";

const Question: React.FC<ImageProps> = ({
  color = "#ffffff",
  ...otherProps
}) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M18.266 20.838C18.266 19.616 18.916 19.174 19.982 18.862C22.01 18.238 23.674 16.47 23.674 13.948C23.674 10.828 21.23 8.878 18.292 8.878C15.38 8.878 13.092 10.984 13.092 14.312H15.666C15.666 12.57 16.862 11.53 18.292 11.53C19.748 11.53 21.022 12.492 21.022 13.948C21.022 14.65 20.684 15.404 19.982 15.898C17.512 17.536 15.614 17.302 15.614 20.838V22.164H18.214L18.266 20.838ZM15.094 25.492C15.094 26.506 15.926 27.234 16.94 27.234C17.954 27.234 18.786 26.506 18.786 25.492C18.786 24.478 17.954 23.724 16.94 23.724C15.926 23.724 15.094 24.478 15.094 25.492Z"
        fill={color}
      />
      <circle cx="18" cy="18" r="16.75" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default Question;
