import React from "react";
import Image from "../../Image";
import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";
import { FileContainerStyled } from "../styles";

type Props = {
  files: File[];
  removeFile: (file: File) => void;
};
const Files: React.FC<Props> = ({ files, removeFile }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {files.length ? (
        <>
          {[...files].map((file: File) => (
            <FileContainerStyled
              className="file"
              key={`${file.size}__${file.name}`}
              style={{ margin: "8px" }}
            >
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "100%" }}
                ></div>
              </div>
              <div className="file__header">
                <div className="file__info">
                  <h4 className="file__title mb-0">{file.name}</h4>
                </div>
                <div className="file__actions">
                  <div className="action-group">
                    <span className="action action--download action--disabled">
                      <span
                        className="action__tooltip"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Download"
                      ></span>
                      <Image name="cross" />
                    </span>
                    <span
                      className="action action--delete"
                      onClick={() => removeFile(file)}
                    >
                      <Image name="cross" />
                    </span>
                  </div>
                </div>
              </div>
            </FileContainerStyled>
          ))}
        </>
      ) : null}
    </div>
  );
};
export default Files;
