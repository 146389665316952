import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { postData } from "../../services/apis";
import { saveAs } from "file-saver";
import { notifyError } from "../../utils/common";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import Image from "../../components/Image";

export const BulkDownload = () => {
  const { encodedData } = useParams();
  const [downloading, setDownloading] = useState(true);
  const [error, setError] = useState<JSX.Element | null>(null);

  const startDownloading = async () => {
    try {
      const res = await postData({
        endPoint: "bulkDownload",
        responseType: "blob",
        data: { encodedData },
      });
      if (res.status === 401 && res.data?.message) {
        setDownloading(false);
        setError(<div>{res.data.message}</div>);
        return;
      }
      const contentDispositionHeader = res.headers["content-disposition"];
      const fileNameMatch =
        contentDispositionHeader &&
        contentDispositionHeader.match(/filename="(.+)"/);
      let fileName = fileNameMatch && fileNameMatch[1];
      // Changing utc time to local time for filename
      fileName =
        moment
          .utc(fileName?.substring(0, 12), "YYYYMMDDhhmm")
          .local()
          .format("YYYYMMDDhhmm") + fileName?.substring(12);
      if (res.status === 200 && res.data) {
        saveAs(res.data, fileName || "download.zip");
      } else {
        notifyError("Something went wrong, please try again later.");
      }
      setDownloading(false);
    } catch (e: any) {
      console.log(
        "ERROR",
        e.statusCode,
        e?.response?.data?.message || e.message
      );
      setDownloading(false);
      if (e?.message && e.message.includes("401")) {
        setError(
          <div>
            <div>Sorry, but you are not authorised to view this page.</div>
            <div>Please contact the administrator for assistance.</div>
          </div>
        );
        return;
      }
      setError(<div>Something went wrong, please try again later.</div>);
    }
  };

  useEffect(() => {
    startDownloading();
  }, []);
  if (error) {
    return (
      <div>
        <Heading>Unauthorised Access</Heading>
        <BulkDownloadPage>
          <Image name="unauthorized" width="114px" height="114px" />
          <h3>{error}</h3>
          <Link to="/sfdr/client/listing">
            <button className="btn btn-primary">Go to Home</button>
          </Link>
        </BulkDownloadPage>
      </div>
    );
  }
  return (
    <div>
      <Heading>Bulk Download</Heading>
      <BulkDownloadPage>
        {downloading ? (
          <>
            <Image name="downloadBox" width="114px" height="114px" />
            <h3>Downloading in progress...</h3>
          </>
        ) : (
          <>
            <Image name="checkBox" width="114px" height="114px" />
            <h3>Successfully downloaded</h3>
            <Link to="/sfdr/client/listing">
              <button className="btn btn-primary">Go to Home</button>
            </Link>
          </>
        )}
      </BulkDownloadPage>
    </div>
  );
};

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 800;
  font-size: 40px;
  background-color: #303246;
  height: 149px;
  margin-bottom: 20px;
`;

const BulkDownloadPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  h3 {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
    color: #303246;
    margin-top: 20px;
  }
`;
