import { DocumentStatusEnum } from "../types/document-details";

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_LANGUAGE = "English";
export const SESSION_EXPIRED_MESSAGE = "Session token expired";
export const CLIENT_URL_PREFIX = "/sfdr/client/";
export const ADMIN_URL_PREFIX = "/sfdr/admin/";
export const VALIDATION_ERROR_COMPONENT_CLASS_NAME = "validation-error-component-class";

export const FILTER_SESSION_NAME = "sfdrClientListFilter";
export const FILTER_SESSION_NAME_ARCHIVED = "sfdrClientListFilterArchived";
export const SELECTED_FUND_SESSION = "sfdrSelectedFundId";
export const REGULATORY_EXTERNAL_LINK_ID = "gDd8q4cHPX";
export const ASSET_ALLOCATION_PERCENTAGE_KEY = "4F5IX8I19w";
export const NAV_BAR_LINKS = [
  {
    id: "1",
    groupName: "SFDR",
    groupIcon: "",
    links: [
      {
        linkName: "Clients",
        linkURL: "",
        navLink: "/sfdr/admin",
        icon: "accounts-22",
        id: "2",
      },
    ],
  },
];

export const CLIENT_TABLE_HEADER = [
  {
    colName: "Client Name",
    dispName: "Client Name",
  },
];
export const APPROVER_TABLE_HEADERS = [
  { colName: "approvalGroupTitle", dispName: "Group Title" },
  { colName: "approvalLevel", dispName: "Level" },
  { colName: "Users", dispName: "Users" },
];
export const DOCUMENT_EDITABLE_STATUS = [
  DocumentStatusEnum.draft,
  DocumentStatusEnum.traslation_requested,
  DocumentStatusEnum.awaiting_comments_review,
  DocumentStatusEnum.edited_by_approver,
];
export const DOCUMENT_ADDITIONAL_INFO_URL =
  "https://www.esma.europa.eu/sites/default/files/2023-05/JC_2023_18_-_Consolidated_JC_SFDR_QAs.pdf";
