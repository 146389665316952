import React from "react";
import { ImageProps } from "../types";

const BlueInfo: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M8.87022 2.69018C8.44364 2.84921 8.18176 3.29394 8.18176 3.86138C8.18176 4.2731 8.27608 4.54078 8.49088 4.7282C8.72607 4.93353 8.97281 4.95734 9.27813 4.80739C9.5783 4.6577 9.75178 4.42304 9.85425 4.02946C10.0696 3.1917 9.52607 2.45613 8.87021 2.69016L8.87022 2.69018Z"
        fill="#0ABAEE"
      />
      <path
        d="M8.14066 5.52998C7.62057 5.63744 7.04916 6.07758 6.53365 6.77128C6.16863 7.25851 6.00962 7.59982 6.0796 7.73166C6.10775 7.78335 6.15905 7.82518 6.19732 7.82518C6.23034 7.82518 6.42186 7.58157 6.62336 7.28206C6.82461 6.98662 7.0494 6.69186 7.12439 6.62583C7.40536 6.38748 7.62596 6.54638 7.52241 6.92126C7.49939 7.01991 7.24592 7.85806 6.96956 8.78553C6.36987 10.7774 6.24793 11.3006 6.24372 11.8769C6.24372 12.2375 6.25754 12.3218 6.35106 12.4671C6.56718 12.8178 7.04005 12.8608 7.60658 12.5854C8.37976 12.2005 9.48913 10.8753 9.44219 10.3882C9.43364 10.2939 9.40563 10.2381 9.3638 10.2381C9.29277 10.2381 9.22779 10.3181 8.74057 10.993C8.31874 11.5732 8.0479 11.6684 8.0479 11.2321C8.0479 11.129 8.29111 10.1826 8.58577 9.12345C8.88068 8.06536 9.14822 7.06845 9.1765 6.89968C9.2478 6.51126 9.21005 6.0472 9.0918 5.85041C8.92764 5.56748 8.56682 5.44556 8.14065 5.52999L8.14066 5.52998Z"
        fill="#0ABAEE"
      />
      <path
        d="M8.0001 15.8125C3.69258 15.8125 0.188843 12.3078 0.188843 8.00025C0.188843 3.69307 3.69258 0.189331 8.0001 0.189331C12.307 0.189331 15.8117 3.69307 15.8117 8.00025C15.8116 12.3074 12.307 15.8125 8.0001 15.8125ZM8.0001 0.751343C4.00305 0.751343 0.750855 4.0036 0.750855 8.00025C0.750855 11.997 4.00258 15.2498 8.0001 15.2498C11.9972 15.2498 15.249 11.9981 15.249 8.00025C15.249 4.00286 11.9973 0.751343 8.0001 0.751343Z"
        fill="#0ABAEE"
      />
      <path
        d="M7.99983 16C3.58859 16 0 12.4107 0 7.99949C0 3.58826 3.58859 0 7.99983 0C12.4114 0 16 3.58859 16 7.99949C16 12.4107 12.4111 16 7.99983 16ZM7.99983 0.376807C3.7967 0.376807 0.377144 3.79602 0.377144 7.99916C0.377144 12.203 3.7967 15.6225 7.99983 15.6225C12.203 15.6225 15.6229 12.2026 15.6229 7.99882C15.6229 3.79569 12.2033 0.376471 7.99983 0.376471V0.376807ZM7.99983 15.4373C3.89873 15.4373 0.562012 12.1007 0.562012 7.99916C0.562012 3.89805 3.89859 0.561338 7.99983 0.561338C12.1009 0.561338 15.4373 3.89778 15.4373 7.99916C15.4372 12.1006 12.1007 15.4373 7.99983 15.4373ZM7.99983 0.938819C4.10649 0.938819 0.939156 4.10612 0.939156 7.99949C0.939156 11.8929 4.10656 15.0605 7.99983 15.0605C11.8932 15.0605 15.0602 11.8928 15.0602 7.99949C15.0602 4.10616 11.8929 0.938819 7.99983 0.938819Z"
        fill="#0ABAEE"
      />
    </svg>
  );
};

export default BlueInfo;
