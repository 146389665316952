import Image from "../Image";
import { Container } from "./styles";

type Props = {
  loading: boolean;
};

const LoadingScreen: React.FC<Props> = ({ loading }) => {
  return loading ? (
    <Container>
      <Image name="loading" />
    </Container>
  ) : (
    <div />
  );
};

export default LoadingScreen;
