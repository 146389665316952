import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const StyledImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const UploadContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacingIncrement(30)};
  border: dashed 1px #0abaee;
  margin: ${spacingIncrement(20)};
  min-width: ${spacingIncrement(350)};
  width: ${spacingIncrement(350)};
  border-radius: 4px;
`;

export const UploadImageWrapper = styled.div`
  padding-bottom: ${spacingIncrement(10)};
`;

export const UploadedImageContainer = styled.div`
  margin: ${spacingIncrement(20)} ${spacingIncrement(30)}
    ${spacingIncrement(10)} ${spacingIncrement(30)};
  padding: ${spacingIncrement(10)};
  padding-bottom: ${spacingIncrement(10)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacingIncrement(10)};
`;

export const UploadedImageSubContainer = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
