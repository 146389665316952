import React from "react";
import useBottomMargin from "../../useBottomMargin";

type Props = {
  hideFooter?: boolean;
};

const ShouldDisplayWrapper: React.FC<Props> = ({ children, hideFooter }) => {
  const { shouldShowFooterBar } = useBottomMargin();

  if (!shouldShowFooterBar || hideFooter) {
    return null;
  }

  return <>{children}</>;
};

export default ShouldDisplayWrapper;
