import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.div`
  position: relative;
  margin-bottom: ${spacingIncrement(20)};
`;

export const ImageWrapper = styled.div`
  position: absolute;
  left: -${spacingIncrement(64)};
  top: -${spacingIncrement(5)};
`;
