import styled from "styled-components";
import Select from "react-select";

export const TableWrapper = styled.div`
  display: block;
  break-inside: avoid;
  min-height: 254px;
  border: 1px solid #e8e9ee;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e8e9ee;
`;

export const HeaderTextWrapper = styled.div<{ spacing?: number }>`
  display: flex;
  align-items: center;
  flex: ${(props) => (props.spacing ? props.spacing : 1)};
`;

export const HeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #303246;
`;

export const TableContentContainer = styled.div`
  padding: 10px 15px;
`;

export const TableRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  column-gap: 10px;
`;

export const TableItemWrapper = styled.div<{
  spacing?: number;
}>`
  display: flex;
  align-items: center;
  flex: ${(props) => (props.spacing ? props.spacing : 1)};
  :last-child {
    margin-right: -15px;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  background: transparent;
`;
