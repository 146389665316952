import Image from "../../../components/Image";
import QuillEditor from "../../../components/QuillEditor";
import {
  DocumentRecord,
  DocumentStatusEnum,
  DocumentViewModeEnum,
} from "../../../types/document-details";
import { spacingIncrement } from "../../../utils/common";
import Checkbox from "../CommonComponents/CheckBox";
import CommentButton from "./CommentButton";
import DocDoNotIncludeIcon from "../CommonComponents/DocDoNotIncludeIcon";
import MainIcon from "./MainIcon";
import { NumberInput } from "../CommonComponents/NumberInput";
import Section from "../CommonComponents/Section";
import {
  Container,
  GreyBox,
  Bold,
  OrangeBoxHeader,
  OrangeBox,
  OrangeLeft,
  OrangeRight,
  OrangeLeftHeader,
  OrangeRightHeader,
  Circle,
  OrangeSection,
  QuestionText,
  HorizontalLine,
  GreyDot,
  FlexRow,
  UploadWrapper,
  UploadRightSide,
  UploadLeftSide,
  Highlight,
  GraphContainer,
  GraphSubContainer,
  Flex,
  FlexColumn,
  RelativeContainer,
  InputCheckBoxContainer,
  CheckBoxLabel,
  Block,
  WaterMarkText,
  WaterMarkWrapper,
  Note,
} from "./styles";
import SubIcon from "./SubIcon";
import PieChart from "../CommonComponents/PieChart";
import {
  AvoidBreakInside,
  StyledNumberInlineInput,
  Text,
  ProductInfo,
} from "../CommonComponents/common.styles";
import { useDocumentState } from "../../../hooks/useDocumentState";
import { useCallback, useEffect, useMemo } from "react";
import { updateDocumentState } from "../../../store/globalActions";
import { useContext } from "react";
import Context from "../../../store/context";
import { GLOBAL_CONTEXT } from "../../../types/store";
import UploadLogoButton from "../CommonComponents/UploadLogoButton";
import { Preset } from "../CommentSection";
import { CommentType } from "../../../types/comment";
import {
  DisplayQuestion,
  DocumentCommonContent,
  DocumentStaticContent,
  Language,
} from "../../../types/document-question";
import {
  getQuestionLabel,
  getStaticContentText,
} from "../DocumentEditor/service";
import InfoToolTip from "../../../components/InfoToolTip";
import { TooltipWrapper } from "../../../components/InfoToolTip/styles";

import parse from "html-react-parser";

import { Title } from "../CommonComponents/common.styles";
import PreviousAnswers from "../CommonComponents/PreviousAnswers";
import GraphInput from "../CommonComponents/GraphInput";
import { QuestionContainer } from "../CommonComponents/QuestionContainer";
import {
  AnnexText,
  ErrorMessage,
  Label,
  QuestionLabel,
} from "../CommonComponents/Typography";
import { RegulatoryExternalLinkComponent } from "../CommonComponents/RegulatoryExternalLinkComponent";
import { REGULATORY_EXTERNAL_LINK_ID } from "../../../lib/constants";
import AssetAllocationGraph from "../../../components/AssetAllocationGraph";
import {
  ProductInfoContainer,
  SubHeading,
} from "../ArticleEightPeriodic/styles";

type Props = {
  document: DocumentRecord;
  mode?: DocumentViewModeEnum;
  updateDoc: (doc: DocumentRecord | undefined) => void;
  toggleQuestionCommentSection?: (preset: Preset) => void;
  togglePreviousAnswersSection?: (val: string) => void;
  showCommentButton?: boolean;
  showPreviousAns?: boolean;
  canEdit?: boolean;
  displayQuestions: DisplayQuestion;
  isLocked?: boolean;
  showWaterMark?: boolean;
  staticContent: DocumentStaticContent;
  commonContent: DocumentCommonContent;
};

const ArticleNinePreContractual: React.FC<Props> = ({
  mode = DocumentViewModeEnum.edit,
  document,
  updateDoc,
  toggleQuestionCommentSection,
  togglePreviousAnswersSection,
  showCommentButton,
  showPreviousAns,
  canEdit,
  displayQuestions,
  isLocked,
  showWaterMark,
  staticContent,
  commonContent,
}) => {
  const {
    globalState: { commentedQuestionIds, documentState },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);
  const { getValue, updateAnswer, handleFloatInput, getQuestionError } =
    useDocumentState();

  const isEditMode = useMemo(() => {
    return mode === DocumentViewModeEnum.edit && canEdit;
  }, [canEdit]);

  useEffect(() => {
    if (documentState?.["idFUxSpwa3"] !== "Yes") {
      updateAnswer("idFUxSpwa3", "Yes");
    }
    return () => {
      globalDispatch(updateDocumentState(undefined));
      updateDoc(undefined);
    };
  }, []);

  const onComment = (qId: string) => {
    const preset: Preset = {
      commentType: CommentType.questionLevel,
      documentId: document._id,
      questionId: qId,
    };
    toggleQuestionCommentSection?.(preset);
  };

  const onPreviousAnswersClick = (qId: string) => {
    togglePreviousAnswersSection?.(qId);
  };

  const showInfoIcon = !(mode === DocumentViewModeEnum.view);

  const showDoNotIncludeIcon = !(mode === DocumentViewModeEnum.view);

  const showChartInputPercentage = !(mode === DocumentViewModeEnum.edit);

  const showErrorMessage = !(mode === DocumentViewModeEnum.view);

  const hasCommentsById = useCallback(
    (qId: string) => {
      return commentedQuestionIds.includes(qId);
    },
    [commentedQuestionIds]
  );

  const isNewVersionDocument = () => {
    if (document.status === DocumentStatusEnum.filed) {
      if (getValue("xhYlLXlDvC")) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const clearChartData = (questionIds: string[]) => {
    questionIds.forEach((id: string) => {
      updateAnswer(id, 0);
    });
  };

  const clearTaxnomyIncludingChartData = () => {
    clearChartData(["kdssXcLDwc", "IRi8H6XpGo", "bBomkodkGD", "SumRuJgTcD"]);
  };
  const clearTaxnomyExcludingChartData = () => {
    clearChartData(["e5TGU2xAct", "vckL7B8rpr", "KMmwSpnToD", "YjIPNWGFjJ"]);
  };

  const subQuestionTopPadding = 20;

  return (
    <Container isLocked={isLocked}>
      <UploadLogoButton
        document={document}
        localDocumentState={documentState}
        updateDoc={(d) => updateAnswer("logo", d.documentState?.logo)}
        disabled={!isEditMode}
        mode={mode}
      />
      {showWaterMark && (
        <WaterMarkWrapper>
          <WaterMarkText>DRAFT</WaterMarkText>
        </WaterMarkWrapper>
      )}
      <Section
        left={<div style={{ minHeight: "140px" }}></div>}
        right={
          <>
            {/* <AnnexText>
              <Label id="Ovs6YgWZDD" isBold isItalic>
                {parse(
                  getStaticContentText(
                    "Ovs6YgWZDD",
                    staticContent.staticText,
                    document.language
                  )
                )}
              </Label>
            </AnnexText> */}
            <SubHeading>
              {/* Template pre-contractual disclosure for financial products
                referred to in Article 9, paragraphs 1 to 4a of Regulation (EU)
                2019/2088 and Article 5, first paragraph, of Regulation (EU)
                2020/852 */}
              {parse(
                getStaticContentText(
                  "ZhPbabrVCC",
                  staticContent.headingText,
                  document.language
                )
              )}
            </SubHeading>
            <ProductInfoContainer>
              <ProductInfo>
                {parse(
                  getStaticContentText(
                    "vS8nIYaM8c",
                    staticContent.staticText,
                    document.language
                  )
                )}
                : {document?.subFundName}
              </ProductInfo>
              <ProductInfo>
                {parse(
                  getStaticContentText(
                    "9GiGPkYC1D",
                    staticContent.staticText,
                    document.language
                  )
                )}
                : {document?.legalEntityIdentifier}
              </ProductInfo>
            </ProductInfoContainer>
          </>
        }
      />
      <Section
        left={
          <>
            <GreyBox>
              {/* <strong>Sustainable investment </strong>
              Sustainable means an investment in an economic activity that
              contributes to an environmental or social objective, provided that
              the investment does not significantly harm any environmental or
              social objective and that the investee companies follow good
              governance practices. */}
              {parse(
                getStaticContentText(
                  "xmXVybr13y",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
            <GreyBox>
              {/* The
              <strong> EUTaxonomy </strong> is a classification system laid down
              in Regulation (EU) 2020/852, establishing a list of
              <strong> environmentally sustainable economic activities</strong>.
              For the time being, it does not include a list of socially
              sustainable economic activities. Sustainable investments with an
              environmental objective might be aligned with the Taxonomy or not. */}
              {parse(
                getStaticContentText(
                  "ORJrR907Vp",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <Title>
              {/* Sustainable investment objective */}
              {parse(
                getStaticContentText(
                  "kwAu2e4cIZ",
                  staticContent.headingText,
                  document.language
                )
              )}
            </Title>
            <OrangeBoxHeader>
              <Bold>
                <FlexRow>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [tick and fill in as relevant, the percentage figure
                    represents the minimum commitment to sustainable
                    investments] */}
                    {parse(
                      getStaticContentText(
                        "CHKdrRUmxo",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <Label id="idFUxSpwa3">
                    {getQuestionLabel(
                      "idFUxSpwa3",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                  </Label>
                  <CommentButton
                    hasComments={hasCommentsById("idFUxSpwa3")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("idFUxSpwa3")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["idFUxSpwa3"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("idFUxSpwa3")}
                  />
                </FlexRow>
              </Bold>
              <Flex>
                <OrangeLeftHeader>
                  <Circle isHighlighted />
                  <Circle isHighlighted />
                  <Checkbox
                    id="1"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={6}
                    disabled={true}
                    checked={true}
                    mode={mode}
                  />
                </OrangeLeftHeader>
                <OrangeRightHeader>
                  <Circle isHighlighted />
                  <Circle />
                  <Checkbox
                    id="2"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={6}
                    disabled={true}
                    mode={mode}
                  />
                  <HorizontalLine />
                </OrangeRightHeader>
              </Flex>
            </OrangeBoxHeader>
            <OrangeBox>
              <OrangeSection>
                <OrangeLeft>
                  <ErrorMessage
                    questionId="idFUxSpwa3"
                    isEditMode={showErrorMessage}
                  >
                    {getQuestionError("idFUxSpwa3")?.message}
                  </ErrorMessage>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="3"
                      fontSize={15}
                      label=""
                      disabled={!isEditMode}
                      checked={!!getValue("FKIXe3Q4Aq")}
                      onClick={() =>
                        updateAnswer("FKIXe3Q4Aq", !getValue("FKIXe3Q4Aq"))
                      }
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "FKIXe3Q4Aq",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <NumberInput
                        inline
                        maxLimit={100}
                        value={
                          getValue("FKIXe3Q4Aq") === true
                            ? 0
                            : getValue("FKIXe3Q4Aq") || ""
                        }
                        onChange={(e) => handleFloatInput(e, "FKIXe3Q4Aq", 100)}
                        disabled={!isEditMode}
                      />
                      {
                        getQuestionLabel(
                          "FKIXe3Q4Aq",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <ErrorMessage
                    questionId="FKIXe3Q4Aq"
                    isEditMode={showErrorMessage}
                    marginLeft={35}
                  >
                    {getQuestionError("FKIXe3Q4Aq")?.message}
                  </ErrorMessage>
                  <Checkbox
                    id="4"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={
                      getQuestionLabel(
                        "g00MmYpUrZ",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={!isEditMode || !getValue("FKIXe3Q4Aq")}
                    checked={getValue("g00MmYpUrZ")}
                    onClick={() =>
                      updateAnswer("g00MmYpUrZ", !getValue("g00MmYpUrZ"))
                    }
                    mode={mode}
                  />
                  <Checkbox
                    id="5"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={
                      getQuestionLabel(
                        "N5BtGG6Nb6",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={!isEditMode || !getValue("FKIXe3Q4Aq")}
                    checked={getValue("N5BtGG6Nb6")}
                    onClick={() =>
                      updateAnswer("N5BtGG6Nb6", !getValue("N5BtGG6Nb6"))
                    }
                    mode={mode}
                  />
                </OrangeLeft>
                <OrangeRight>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="6"
                      fontSize={15}
                      label=""
                      disabled={true}
                      checked={false}
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "U2DnI5LKot",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <StyledNumberInlineInput
                        value={getValue("BQPWIBPpen") || ""}
                        disabled={true}
                      />
                      {
                        getQuestionLabel(
                          "U2DnI5LKot",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <Checkbox
                    id="7"
                    marginLeft={35}
                    fontSize={12}
                    bold={false}
                    label={
                      getQuestionLabel(
                        "ZmGuP4w6kf",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={true}
                    checked={false}
                    mode={mode}
                  />
                  <Checkbox
                    id="8"
                    marginLeft={35}
                    bold={false}
                    fontSize={12}
                    label={
                      getQuestionLabel(
                        "8XML15cfgQ",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={true}
                    checked={false}
                    mode={mode}
                  />
                  <Checkbox
                    id="9"
                    marginLeft={35}
                    bold={false}
                    fontSize={12}
                    label={
                      getQuestionLabel(
                        "GeUQwwccKS",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={true}
                    checked={false}
                    mode={mode}
                  />
                  <HorizontalLine />
                </OrangeRight>
              </OrangeSection>
              <OrangeSection>
                <OrangeLeft>
                  <InputCheckBoxContainer>
                    <Checkbox
                      id="10"
                      fontSize={15}
                      label=""
                      disabled={!isEditMode}
                      checked={!!getValue("ywPFIQdKqf")}
                      onClick={() =>
                        updateAnswer("ywPFIQdKqf", !getValue("ywPFIQdKqf"))
                      }
                      mode={mode}
                    />
                    <CheckBoxLabel fontSize={15}>
                      {parse(
                        getQuestionLabel(
                          "ywPFIQdKqf",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[0] || ""
                      )}{" "}
                      <NumberInput
                        inline
                        value={
                          getValue("ywPFIQdKqf") === true
                            ? 0
                            : getValue("ywPFIQdKqf") || ""
                        }
                        onChange={(e) => handleFloatInput(e, "ywPFIQdKqf", 100)}
                        disabled={!isEditMode}
                      />
                      {
                        getQuestionLabel(
                          "ywPFIQdKqf",
                          displayQuestions,
                          Language?.[document.language]
                        )?.[1]
                      }
                    </CheckBoxLabel>
                  </InputCheckBoxContainer>
                  <ErrorMessage
                    questionId="ywPFIQdKqf"
                    isEditMode={showErrorMessage}
                  >
                    {getQuestionError("ywPFIQdKqf")?.message}
                  </ErrorMessage>
                </OrangeLeft>
                <OrangeRight>
                  <Checkbox
                    id="11"
                    fontSize={15}
                    bold={false}
                    label={
                      getQuestionLabel(
                        "YwtIxtrO6i",
                        displayQuestions,
                        Language?.[document.language]
                      ) as string
                    }
                    disabled={true}
                    checked={false}
                    mode={mode}
                  />
                  <HorizontalLine heightPercent={87} />
                </OrangeRight>
              </OrangeSection>
            </OrangeBox>
          </>
        }
      />
      {isEditMode && (
        <Section
          paddingTop={20}
          left={<></>}
          right={
            <>
              <QuestionContainer>
                <QuestionText isBold>
                  <Highlight>
                    <Label id="IOffmvozDd" isBold>
                      {getQuestionLabel(
                        "IOffmvozDd",
                        displayQuestions,
                        Language?.[document.language]
                      )}
                      <ErrorMessage
                        questionId="IOffmvozDd"
                        isEditMode={showErrorMessage}
                      >
                        {getQuestionError("IOffmvozDd")?.message}
                      </ErrorMessage>
                    </Label>
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="12"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("IOffmvozDd") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "IOffmvozDd",
                        getValue("IOffmvozDd") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="13"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("IOffmvozDd") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "IOffmvozDd",
                        getValue("IOffmvozDd") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            </>
          }
        />
      )}
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="globe" />
            <GreyBox>
              {/* <strong> Sustainability indicators </strong> measure how the
              sustainable objectives of this financial product are attained */}
              {parse(
                getStaticContentText(
                  "m6OVLHBTk3",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <QuestionContainer>
            <QuestionContainer paddingLeft={0}>
              <QuestionText isBold>
                <InfoToolTip disabled={!showInfoIcon}>
                  {/* [indicate the investment objective pursued by the financial
                  product, describe how the sustainable investments contribute to
                  a sustainable investment objective and indicate whether a
                  reference benchmark has been designated for the purpose of
                  attaining the sustainable investment objective. For financial
                  products referred to in Article 5 , first paragraph, of
                  Regulation (EU) 2020/852, in respect of sustainable investments
                  with environmental objectives, list the environmental objectives
                  set out in Article 9 of that Regulation to which the sustainable
                  investment underlying the financial product contributes. For
                  financial products referred to in Article 9(3) of Regulation
                  (EU) 2019/2088, indicate that the financial product has the
                  objective of reducing carbon emissions and explain that the
                  reference benchmark qualifies as an EU Climate Transition
                  Benchmark or an EU Paris-aligned Benchmark under Title III,
                  Chapter 3a, of Regulation (EU) 2016/1011 and indicate where the
                  methodology used for the calculation of that benchmark can be
                  found. Where no EU Climate Transition Benchmark or EU
                  Paris-aligned Benchmark as qualified in accordance with
                  Regulation (EU) 2016/1011 is available, describe that fact, how
                  the continued effort of attaining the objective of reducing
                  carbon emissions is ensured in view of achieving the objectives
                  of the Paris Agreement and the extent to which the financial
                  product complies with the methodological requirements set out in
                  Commission Delegated Regulation (EU) 2020/1818] */}
                  {parse(
                    getStaticContentText(
                      "HRMQPZadMT",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                </InfoToolTip>
                <QuestionLabel
                  isBold
                  questionId="w7Fewsk4c2"
                  displayQuestions={displayQuestions}
                  language={document.language}
                  showErrorMessage={showErrorMessage}
                  getQuestionError={getQuestionError}
                />
                <CommentButton
                  hasComments={hasCommentsById("w7Fewsk4c2")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("w7Fewsk4c2")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["w7Fewsk4c2"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("w7Fewsk4c2")}
                />
              </QuestionText>
              <QuillEditor
                id="w7Fewsk4c2"
                disabled={!isEditMode}
                innerHTML={getValue("w7Fewsk4c2")}
                onChange={(ans) => updateAnswer("w7Fewsk4c2", ans)}
              />
            </QuestionContainer>
            <QuestionContainer
              paddingTop={subQuestionTopPadding}
              paddingLeft={10}
            >
              <QuestionText fontSize={14} isItalic isBold>
                <GreyDot />
                <Label id="kZVzokgwEW" fontSize={14} isItalic isBold>
                  {getQuestionLabel(
                    "kZVzokgwEW",
                    displayQuestions,
                    Language?.[document.language]
                  )}
                  <ErrorMessage
                    questionId="kZVzokgwEW"
                    isEditMode={showErrorMessage}
                  >
                    {getQuestionError("kZVzokgwEW")?.message}
                  </ErrorMessage>
                </Label>
                <CommentButton
                  hasComments={hasCommentsById("kZVzokgwEW")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("kZVzokgwEW")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["kZVzokgwEW"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("kZVzokgwEW")}
                />
              </QuestionText>
              <QuillEditor
                id="kZVzokgwEW"
                disabled={!isEditMode}
                innerHTML={getValue("kZVzokgwEW")}
                onChange={(ans) => updateAnswer("kZVzokgwEW", ans)}
              />
            </QuestionContainer>
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <GreyBox>
              {/* <strong> Principal adverse impacts </strong>are the most
              significant negative impacts of investment decisions on
              sustainability factors relating to environmental, social and
              employee matters, respect for human rights, anti-corruption and
              anti-bribery matters. */}
              {parse(
                getStaticContentText(
                  "2e3htvb428",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <QuestionContainer>
            <QuestionContainer paddingLeft={10}>
              <QuestionContainer paddingLeft={0}>
                <QuestionText fontSize={14} isItalic isBold>
                  <GreyDot />
                  <Label id="4SR5FcpUUz" fontSize={14} isItalic isBold>
                    {getQuestionLabel(
                      "4SR5FcpUUz",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    <ErrorMessage
                      questionId="4SR5FcpUUz"
                      isEditMode={showErrorMessage}
                    >
                      {getQuestionError("4SR5FcpUUz")?.message}
                    </ErrorMessage>
                  </Label>
                  <CommentButton
                    hasComments={hasCommentsById("4SR5FcpUUz")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("4SR5FcpUUz")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["4SR5FcpUUz"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("4SR5FcpUUz")}
                  />
                </QuestionText>
                <QuillEditor
                  id="4SR5FcpUUz"
                  disabled={!isEditMode}
                  innerHTML={getValue("4SR5FcpUUz")}
                  onChange={(ans) => updateAnswer("4SR5FcpUUz", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={20}
              >
                <QuestionContainer paddingLeft={0}>
                  <QuestionText fontSize={14} isItalic>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [explain how the indicators for adverse impacts in Table 1
                      of Annex I and any relevant indicators in Tables 2 and 3 of
                      Annex I are taken into account] */}
                      {parse(
                        getStaticContentText(
                          "YID71drgqM",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <Label id="3Lb3IllrkE" fontSize={14} isItalic isBold>
                      {getQuestionLabel(
                        "3Lb3IllrkE",
                        displayQuestions,
                        Language?.[document.language]
                      )}
                      <ErrorMessage
                        questionId="3Lb3IllrkE"
                        isEditMode={showErrorMessage}
                      >
                        {getQuestionError("3Lb3IllrkE")?.message}
                      </ErrorMessage>
                    </Label>
                    <CommentButton
                      hasComments={hasCommentsById("3Lb3IllrkE")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("3Lb3IllrkE")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["3Lb3IllrkE"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("3Lb3IllrkE")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="3Lb3IllrkE"
                    disabled={!isEditMode}
                    innerHTML={getValue("3Lb3IllrkE")}
                    onChange={(ans) => updateAnswer("3Lb3IllrkE", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText fontSize={14} isItalic>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [include an explanation on the alignment with the OECD
                    Guidelines for Multinational Enterprises and the UN Guiding
                    Principles on Business and Human Rights, including the
                    principles and rights set out in the eight fundamental
                    conventions identified in the Declaration of the International
                    Labour Organisation on Fundamental Principles and Rights at
                    Work and the International Bill of Human Rights] */}
                      {parse(
                        getStaticContentText(
                          "uPViUvFNAe",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <Label id="1st9Lr9zKX" fontSize={14} isItalic isBold>
                      {getQuestionLabel(
                        "1st9Lr9zKX",
                        displayQuestions,
                        Language?.[document.language]
                      )}
                      <ErrorMessage
                        questionId="1st9Lr9zKX"
                        isEditMode={showErrorMessage}
                      >
                        {getQuestionError("1st9Lr9zKX")?.message}
                      </ErrorMessage>
                    </Label>
                    <CommentButton
                      hasComments={hasCommentsById("1st9Lr9zKX")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("1st9Lr9zKX")}
                    />

                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["1st9Lr9zKX"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("1st9Lr9zKX")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="1st9Lr9zKX"
                    disabled={!isEditMode}
                    innerHTML={getValue("1st9Lr9zKX")}
                    onChange={(ans) => updateAnswer("1st9Lr9zKX", ans)}
                  />
                </QuestionContainer>
              </QuestionContainer>
            </QuestionContainer>
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="factory" />
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <AvoidBreakInside>
                  <QuestionText isBold>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [if the financial product considers principal adverse impacts
                    on sustainability factors, include a clear and reasoned
                    explanation of how it considers principal adverse impacts on
                    sustainability factors. Indicate where, in the information to
                    be disclosed pursuant to Article 11(2) of Regulation (EU)
                    2019/2088, the information on principal adverse impacts on
                    sustainability factors is available.] */}
                      {parse(
                        getStaticContentText(
                          "aDaYRXgdjp",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <Label id="hZBzDVubam" isBold>
                      {getQuestionLabel(
                        "hZBzDVubam",
                        displayQuestions,
                        Language?.[document.language]
                      )}
                      <ErrorMessage
                        questionId={["hZBzDVubam", "OKjIDIrzcg"]}
                        isEditMode={showErrorMessage}
                      >
                        {getQuestionError("hZBzDVubam")?.message ||
                          getQuestionError("OKjIDIrzcg")?.message}
                      </ErrorMessage>
                    </Label>
                    <CommentButton
                      hasComments={hasCommentsById("hZBzDVubam")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("hZBzDVubam")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["hZBzDVubam"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("OKjIDIrzcg")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="14"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("hZBzDVubam") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "hZBzDVubam",
                          getValue("hZBzDVubam") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="15"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("hZBzDVubam") === "No"}
                      onClick={() => {
                        updateAnswer(
                          "hZBzDVubam",
                          getValue("hZBzDVubam") === "No" ? "" : "No"
                        );
                        updateAnswer("OKjIDIrzcg", "");
                      }}
                    />
                  </FlexRow>
                </AvoidBreakInside>
              </QuestionContainer>
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                {getValue("hZBzDVubam") === "Yes" && (
                  <QuillEditor
                    id="OKjIDIrzcg"
                    disabled={!isEditMode}
                    innerHTML={getValue("OKjIDIrzcg")}
                    onChange={(ans) => updateAnswer("OKjIDIrzcg", ans)}
                  />
                )}
              </QuestionContainer>
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="writingPad" />
            <GreyBox>
              {/* <strong>The investment strategy </strong>
              guides investment decisions based on factors such as investment
              objectives and risk tolerance. */}
              {parse(
                getStaticContentText(
                  "z8k91PObHc",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
            <GreyBox>
              {/* <strong>Good governance </strong> practices include sound
              management structures, employee relations, remuneration of staff
              and tax compliance. */}
              {parse(
                getStaticContentText(
                  "YbiVXo2mD6",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [provide a description of the investment strategy and indicate
                    how the strategy is implemented in the investment process on a
                    continuous basis] */}
                    {parse(
                      getStaticContentText(
                        "kFq90USToF",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <Label id="Jhz7aNy5VZ" isBold>
                    {getQuestionLabel(
                      "Jhz7aNy5VZ",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    <ErrorMessage
                      questionId={"Jhz7aNy5VZ"}
                      isEditMode={showErrorMessage}
                    >
                      {getQuestionError("Jhz7aNy5VZ")?.message}
                    </ErrorMessage>
                  </Label>
                  <CommentButton
                    hasComments={hasCommentsById("Jhz7aNy5VZ")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("Jhz7aNy5VZ")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["Jhz7aNy5VZ"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("Jhz7aNy5VZ")}
                  />
                </QuestionText>
                <QuillEditor
                  id="Jhz7aNy5VZ"
                  disabled={!isEditMode}
                  innerHTML={getValue("Jhz7aNy5VZ")}
                  onChange={(ans) => updateAnswer("Jhz7aNy5VZ", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={10}
              >
                <QuestionText fontSize={14} isItalic isBold>
                  <GreyDot />
                  <QuestionLabel
                    fontSize={14}
                    isItalic
                    isBold
                    questionId="2TTU6UbJzk"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("2TTU6UbJzk")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("2TTU6UbJzk")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["2TTU6UbJzk"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("2TTU6UbJzk")}
                  />
                </QuestionText>
                <QuillEditor
                  id="2TTU6UbJzk"
                  disabled={!isEditMode}
                  innerHTML={getValue("2TTU6UbJzk")}
                  onChange={(ans) => updateAnswer("2TTU6UbJzk", ans)}
                />
              </QuestionContainer>
              <QuestionContainer
                paddingTop={subQuestionTopPadding}
                paddingLeft={10}
              >
                <QuestionText fontSize={14} isItalic isBold>
                  <GreyDot />
                  <QuestionLabel
                    fontSize={14}
                    isItalic
                    isBold
                    questionId="iGkSr2msvz"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("iGkSr2msvz")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("iGkSr2msvz")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["iGkSr2msvz"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("iGkSr2msvz")}
                  />
                </QuestionText>
                <QuillEditor
                  id="iGkSr2msvz"
                  disabled={!isEditMode}
                  innerHTML={getValue("iGkSr2msvz")}
                  onChange={(ans) => updateAnswer("iGkSr2msvz", ans)}
                />
              </QuestionContainer>
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="money" />
            <GreyBox>
              {/* <strong>Asset allocation </strong>
              describes the share of investments in specific assets. */}
              {parse(
                getStaticContentText(
                  "9a36dnig1Y",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
            <GreyBox>
              <InfoToolTip disabled={!showInfoIcon}>
                {/* [include note only for financial products referred to in
                  Article 6 of Regulation (EU) 2020/852] */}
                {parse(
                  getStaticContentText(
                    "RnSWcSOMOA",
                    staticContent.infoTooltip,
                    document.language
                  )
                )}
              </InfoToolTip>
              {/* Taxonomy-aligned activities are expressed as a share of
                <ul>
                  <li>
                    <div>- </div>
                    <span>turnover</span> reflecting the share of revenue from
                    green activities of investee companies
                  </li>
                  <li>
                    <div>- </div>
                    <span>capital expenditure</span>
                    (CapEx) showing the green investments made by investee
                    companies, e.g. for a transition to a green economy.
                  </li>
                  <li>
                    <div>- </div>
                    <span>operational expenditure</span>
                    (OpEx) reflecting green operational activities of investee
                    companies.
                  </li>
                </ul> */}
              {parse(
                getStaticContentText(
                  "pl2sUy2EMp",
                  staticContent.greyBox,
                  document.language
                )
              )}
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include a narrative explanation of the investments of the
                    financial product including the minimum proportion of the
                    investments of the financial product used to meet the
                    sustainable investment objective in accordance with the
                    binding elements of the investment strategy] */}
                    {parse(
                      getStaticContentText(
                        "BpZBHpQBDn",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isItalic
                    isBold
                    questionId="kAhMY1fCQc"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("kAhMY1fCQc")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("kAhMY1fCQc")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["kAhMY1fCQc"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("kAhMY1fCQc")}
                  />
                </QuestionText>
                <QuillEditor
                  id="kAhMY1fCQc"
                  disabled={!isEditMode}
                  innerHTML={getValue("kAhMY1fCQc")}
                  onChange={(ans) => updateAnswer("kAhMY1fCQc", ans)}
                />
              </QuestionContainer>
              <OrangeBox>
                <TooltipWrapper>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [Include only the relevant boxes of this sample image and
                    remove irrelevant ones for the financial product] */}
                    {parse(
                      getStaticContentText(
                        "AoCyKaGPOQ",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                </TooltipWrapper>
                <UploadWrapper>
                  <UploadLeftSide>
                    <AssetAllocationGraph
                      document={document}
                      questionId="ZTrJdoNVF7"
                      updateDoc={updateDoc}
                      isEditMode={isEditMode}
                      commonContent={commonContent}
                      mode={mode}
                      updateAns={updateAnswer}
                      article="Article 9"
                      template="Pre-contractual"
                      documentState={documentState}
                    />
                    {/* <UploadFooterNote>
                      <Text fontSize={12} isItalic>
                        Note: Update the Digrammatic representaion of
                        Sustainable investments here.
                      </Text>
                    </UploadFooterNote> */}
                  </UploadLeftSide>
                  <UploadRightSide>
                    <Text fontSize={12}>
                      {/* <strong>#1 Sustainable </strong>
                      covers sustainable investments with environmental or
                      social objectives. */}
                      {parse(
                        getStaticContentText(
                          "U4sjLTKzCA",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                    </Text>
                    <Text fontSize={12}>
                      {/* <strong>#2 Not sustainable </strong>
                      includes investments which do not qualify as sustainable
                      investments. */}
                      {parse(
                        getStaticContentText(
                          "vyFkSzd5ZX",
                          staticContent.staticText,
                          document.language
                        )
                      )}
                    </Text>
                  </UploadRightSide>
                </UploadWrapper>
                {/* Asset allocation changes commented */}
                {/* <UploadWrapper>
                  <CustomChart
                    width={800}
                    height={400}
                    data={getChartData(
                      documentState,
                      "Article 9",
                      "Pre-contractual"
                    )}
                    language={document?.language}
                  />
                </UploadWrapper>
                <UploadFooterNote>
                  <Text fontSize={12}> */}
                {/* <strong>#1 Sustainable </strong>
                      covers sustainable investments with environmental or
                      social objectives. */}
                {/* {parse(
                      getStaticContentText(
                        "U4sjLTKzCA",
                        staticContent.staticText,
                        document.language
                      )
                    )}
                  </Text>
                </UploadFooterNote>
                <UploadFooterNote>
                  <Text fontSize={12}> */}
                {/* <strong>#2 Not sustainable </strong>
                      includes investments which do not qualify as sustainable
                      investments. */}
                {/* {parse(
                      getStaticContentText(
                        "vyFkSzd5ZX",
                        staticContent.staticText,
                        document.language
                      )
                    )}
                  </Text>
                </UploadFooterNote> */}
              </OrangeBox>
              {isEditMode && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <Highlight>
                      <QuestionLabel
                        fontSize={14}
                        isItalic
                        isBold
                        questionId="yOvi0nvgoy"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("yOvi0nvgoy")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("yOvi0nvgoy")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["yOvi0nvgoy"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("yOvi0nvgoy")}
                    />
                  </QuestionText>
                  <FlexRow>
                    <Checkbox
                      id="16"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("yOvi0nvgoy") === "Yes"}
                      onClick={() =>
                        updateAnswer(
                          "yOvi0nvgoy",
                          getValue("yOvi0nvgoy") === "Yes" ? "" : "Yes"
                        )
                      }
                    />
                    <Checkbox
                      id="17"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("yOvi0nvgoy") === "No"}
                      onClick={() =>
                        updateAnswer(
                          "yOvi0nvgoy",
                          getValue("yOvi0nvgoy") === "No" ? "" : "No"
                        )
                      }
                    />
                  </FlexRow>
                </QuestionContainer>
              )}
              {getValue("yOvi0nvgoy") === "Yes" && (
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [for financial product that use derivatives as defined in
                      Article 2(1), point (29), of Regulation (EU) No 600/2014
                      to attain their sustainable investment objective, describe
                      how the use of those derivatives attains that sustainable
                      investment objective] */}
                      {parse(
                        getStaticContentText(
                          "g36iYjCEA7",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      questionId="DCzKJM7ez8"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("DCzKJM7ez8")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("DCzKJM7ez8")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["DCzKJM7ez8"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("DCzKJM7ez8")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="DCzKJM7ez8"
                    disabled={!isEditMode}
                    innerHTML={getValue("DCzKJM7ez8")}
                    onChange={(ans) => updateAnswer("DCzKJM7ez8", ans)}
                  />
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <GreyBox>
              <InfoToolTip disabled={!showInfoIcon}>
                {/* [include note only for the financial products referred to in
                Article 6, first paragraph, of Regulation (EU) 2020/852] */}
                {parse(
                  getStaticContentText(
                    "QUCF5H3kgc",
                    staticContent.infoTooltip,
                    document.language
                  )
                )}
              </InfoToolTip>
              <div>
                {parse(
                  getStaticContentText(
                    "2NiiFi5qf2",
                    staticContent.greyBox,
                    document.language
                  )
                )}
                {/* To comply with the EU Taxonomy, the criteria for{" "}
                <strong> fossil gas </strong> include limitations on emissions
                and switching to renewable power or low-carbon fuels by the end
                of 2035. For <strong> nuclear energy</strong>, the criteria
                include comprehensive safety and waste management rules */}
              </div>
              <br />
              <div>
                {/* <strong>Enabling activities </strong>
                directly enable other activities to make a substantial
                contribution to an environmental objective{" "}
                 */}
                {parse(
                  getStaticContentText(
                    "lhLFO0Ywo1",
                    staticContent.greyBox,
                    document.language
                  )
                )}
              </div>
              <br />
              <div>
                {/*<strong> Transitional activities are activities </strong> for
                which low-carbon alternatives are not yet available and that
                have greenhouse gas emission levels corresponding to the best
                performance. */}
                {parse(
                  getStaticContentText(
                    "H5vt2q5Yg7",
                    staticContent.greyBox,
                    document.language
                  )
                )}
              </div>
            </GreyBox>
          </>
        }
        right={
          <>
            <QuestionContainer>
              {isEditMode && (
                <>
                  <QuestionText isBold fontSize={14}>
                    <Highlight>
                      <QuestionLabel
                        fontSize={14}
                        isBold
                        questionId="BB4NpuMAlR"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                    </Highlight>
                    <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                    <CommentButton
                      hasComments={hasCommentsById("BB4NpuMAlR")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("BB4NpuMAlR")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["BB4NpuMAlR"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("BB4NpuMAlR")}
                    />
                  </QuestionText>
                  <FlexRow paddingTop={5}>
                    <Checkbox
                      id="18"
                      label={getStaticContentText(
                        "C7uLFog7hl",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      disabled={!isEditMode}
                      checked={getValue("BB4NpuMAlR") === "Yes"}
                      onClick={() => {
                        // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                        clearTaxnomyIncludingChartData();
                        // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                        clearTaxnomyExcludingChartData();
                        updateAnswer(
                          "BB4NpuMAlR",
                          getValue("BB4NpuMAlR") === "Yes" ? "" : "Yes"
                        );
                      }}
                    />
                    <Checkbox
                      id="19"
                      label={getStaticContentText(
                        "bG9v3QJaqt",
                        staticContent.questionAnswerOption,
                        document.language
                      )}
                      marginLeft={60}
                      disabled={!isEditMode}
                      checked={getValue("BB4NpuMAlR") === "No"}
                      onClick={() => {
                        // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                        clearTaxnomyIncludingChartData();
                        // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                        clearTaxnomyExcludingChartData();
                        updateAnswer(
                          "BB4NpuMAlR",
                          getValue("BB4NpuMAlR") === "No" ? "" : "No"
                        );
                      }}
                    />
                  </FlexRow>
                </>
              )}
              {getValue("BB4NpuMAlR") === "Yes" && (
                <QuestionContainer
                  paddingLeft={10}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionContainer paddingLeft={0}>
                    <QuestionText fontSize={14} isItalic isBold>
                      <SubIcon name="book" />
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include the section for financial products referred to in
                        Article 5, first paragraph, of Regulation (EU) 2020/852
                        and include the graphical representation referred to in
                        Article 19(1), point (a), of this Regulation, the
                        description referred to in Article 19(1), point (b), of
                        this Regulation, the clear explanation referred to in
                        Article 19(1), point (c), of this Regulation, the
                        narrative explanation referred to in Article 19(1), point
                        (d), of this Regulation and the information referred to in
                        Article 15(4) of this Regulation] */}
                        {parse(
                          getStaticContentText(
                            "HDy1TXew6F",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        fontSize={14}
                        isItalic
                        isBold
                        questionId="k0xa4BQDQx"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("k0xa4BQDQx")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("k0xa4BQDQx")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["k0xa4BQDQx"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("k0xa4BQDQx")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="k0xa4BQDQx"
                      disabled={!isEditMode}
                      innerHTML={getValue("k0xa4BQDQx")}
                      onChange={(ans) => updateAnswer("k0xa4BQDQx", ans)}
                    />
                  </QuestionContainer>
                  {/* Condition needs to removed in next iteration */}
                  {isNewVersionDocument() && (
                    <QuestionContainer
                      paddingTop={subQuestionTopPadding}
                      paddingLeft={0}
                    >
                      <AvoidBreakInside>
                        <QuestionText fontSize={14} isItalic isBold>
                          <GreyDot />
                          <QuestionLabel
                            fontSize={14}
                            isItalic
                            isBold
                            questionId="xhYlLXlDvC"
                            getQuestionError={getQuestionError}
                            displayQuestions={displayQuestions}
                            language={document.language}
                            showErrorMessage={showErrorMessage}
                          />
                          <CommentButton
                            hasComments={hasCommentsById("xhYlLXlDvC")}
                            disabled={!showCommentButton}
                            onClick={() => onComment("xhYlLXlDvC")}
                          />
                          <PreviousAnswers
                            disabled={!showPreviousAns}
                            hasAnswers={
                              displayQuestions["xhYlLXlDvC"].previousAnswers
                            }
                            onClick={() => onPreviousAnswersClick("xhYlLXlDvC")}
                          />
                        </QuestionText>
                        <FlexColumn paddingTop={5}>
                          <FlexRow align="flex-start">
                            <Checkbox
                              id="20"
                              label={getStaticContentText(
                                "C7uLFog7hl",
                                staticContent.questionAnswerOption,
                                document.language
                              )}
                              marginLeft={60}
                              disabled={!isEditMode}
                              checked={getValue("xhYlLXlDvC") === "Yes"}
                              onClick={() => {
                                // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                                clearTaxnomyIncludingChartData();
                                // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                                clearTaxnomyExcludingChartData();

                                updateAnswer(
                                  "xhYlLXlDvC",
                                  getValue("xhYlLXlDvC") === "Yes" ? "" : "Yes"
                                );
                              }}
                            />
                            &nbsp;&nbsp;
                            <InfoToolTip disabled={!showInfoIcon}>
                              {parse(
                                getStaticContentText(
                                  "4S93MYgcsD",
                                  staticContent.infoTooltip,
                                  document.language
                                )
                              )}

                              {/* [specify below, and details in the graphs of the */}
                              {/* box] */}
                            </InfoToolTip>
                          </FlexRow>
                          <FlexRow>
                            <Checkbox
                              id="21"
                              label={
                                getQuestionLabel(
                                  "5BhPY37WPx",
                                  displayQuestions,
                                  Language?.[document.language]
                                ) as string
                              }
                              marginLeft={120}
                              disabled={
                                !isEditMode || getValue("xhYlLXlDvC") !== "Yes"
                              }
                              checked={getValue("5BhPY37WPx")}
                              onClick={() =>
                                updateAnswer(
                                  "5BhPY37WPx",
                                  !getValue("5BhPY37WPx")
                                )
                              }
                              mode={mode}
                            />
                            <Checkbox
                              id="22"
                              label={
                                getQuestionLabel(
                                  "WKN5j7QNK2",
                                  displayQuestions,
                                  Language?.[document.language]
                                ) as string
                              }
                              marginLeft={60}
                              disabled={
                                !isEditMode || getValue("xhYlLXlDvC") !== "Yes"
                              }
                              checked={getValue("WKN5j7QNK2")}
                              onClick={() =>
                                updateAnswer(
                                  "WKN5j7QNK2",
                                  !getValue("WKN5j7QNK2")
                                )
                              }
                              mode={mode}
                            />
                          </FlexRow>
                          <Checkbox
                            id="23"
                            label={getStaticContentText(
                              "bG9v3QJaqt",
                              staticContent.questionAnswerOption,
                              document.language
                            )}
                            marginLeft={60}
                            disabled={!isEditMode}
                            checked={getValue("xhYlLXlDvC") === "No"}
                            onClick={() => {
                              // clear 'Taxonomy-alignment of investments including sovereign bonds*' values
                              clearTaxnomyIncludingChartData();
                              // clear 'Taxonomy-alignment of investments excluding sovereign bonds*' values
                              clearTaxnomyExcludingChartData();

                              updateAnswer(
                                "xhYlLXlDvC",
                                getValue("xhYlLXlDvC") === "No" ? "" : "No"
                              );
                            }}
                          />
                        </FlexColumn>
                      </AvoidBreakInside>
                    </QuestionContainer>
                  )}
                  <Note className="no-print">
                    {/* Fossil gas and/or nuclear related activities will only
                        comply with the EU Taxonomy where they contribute to
                        limiting climate change (“climate change mitigation”)
                        and do not significantly harm any EU Taxonomy objectives
                        - see explanatory note in the left hand margin. The full
                        criteria for fossil gas and nuclear energy economic
                        activities that comply with the EU Taxonomy are laid
                        down in Commission Delegated Regulation (EU) 2022/1214. */}
                    {parse(
                      getStaticContentText(
                        "RvgGOg6xgj",
                        staticContent.staticText,
                        document.language
                      )
                    )}
                  </Note>
                  <OrangeBox
                    padding={spacingIncrement(10)}
                    margin={`${spacingIncrement(10)} 0 ${spacingIncrement(
                      20
                    )} 0`}
                  >
                    {/* NEW FOSSIL FUEL CHART */}
                    <Text wordBreak="normal">
                      <Label id="myA5vArbzF" isBold isItalic fontSize={12}>
                        {parse(
                          getStaticContentText(
                            "myA5vArbzF",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                      </Label>
                      {/* The two graphs below show in green the minimum
                        percentage of investments that are aligned with the EU
                        Taxonomy. As there is no appropriate methodology to
                        determine the Taxonomy-alignment of sovereign bonds*,
                        the first graph shows the Taxonomy alignment in relation
                        to all the investments of the financial product
                        including sovereign bonds, while the second graph shows
                        the Taxonomy alignment only in relation to the
                        investments of the financial product other than
                        sovereign bonds. */}
                    </Text>
                    <TooltipWrapper>
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [only include in the graphs the figures for Taxonomy
                          aligned fossil gas and/or nuclear energy as well as
                          the corresponding legend and the explanatory text in
                          the left hand margin if the financial product makes
                          investments in fossil gas and/or nuclear energy
                          Taxonomy-aligned economic activities] */}
                        {parse(
                          getStaticContentText(
                            "HQ4xvPfJZH",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                    </TooltipWrapper>
                    <GraphContainer>
                      <GraphSubContainer>
                        <FlexColumn>
                          <Text center={true} fontSize={12}>
                            {/* 1. Taxonomy-alignment of investments{" "}
                              <Bold>including sovereign bonds*</Bold> */}
                            {parse(
                              getStaticContentText(
                                "Lqs9gbONAK",
                                staticContent.staticText,
                                document.language
                              )
                            )}
                          </Text>
                          <FlexRow paddingTop={10}>
                            <Flex flex={1.5}>
                              <FlexColumn>
                                <GraphInput
                                  document={document}
                                  staticContent={staticContent}
                                  isEditMode={isEditMode || false}
                                  showChartInputPercentage={
                                    showChartInputPercentage
                                  }
                                  getValue={getValue}
                                  updateAnswer={updateAnswer}
                                  textId="chxQtWvzke"
                                  answerId="kdssXcLDwc"
                                  booletColor="#809980"
                                  isNumberDisabled={
                                    getValue("xhYlLXlDvC") === "No"
                                  }
                                />
                                <GraphInput
                                  document={document}
                                  staticContent={staticContent}
                                  isEditMode={isEditMode || false}
                                  showChartInputPercentage={
                                    showChartInputPercentage
                                  }
                                  getValue={getValue}
                                  updateAnswer={updateAnswer}
                                  textId="Zffi9KIfT5"
                                  answerId="IRi8H6XpGo"
                                  booletColor="#4d704d"
                                  isNumberDisabled={
                                    getValue("xhYlLXlDvC") === "No"
                                  }
                                />
                                <GraphInput
                                  document={document}
                                  staticContent={staticContent}
                                  isEditMode={isEditMode || false}
                                  showChartInputPercentage={
                                    showChartInputPercentage
                                  }
                                  getValue={getValue}
                                  updateAnswer={updateAnswer}
                                  textId="RXtpexllNu"
                                  answerId="bBomkodkGD"
                                  booletColor="#0a3300"
                                />
                                <GraphInput
                                  paddingTop={10}
                                  document={document}
                                  staticContent={staticContent}
                                  isEditMode={isEditMode || false}
                                  showChartInputPercentage={
                                    showChartInputPercentage
                                  }
                                  getValue={getValue}
                                  updateAnswer={updateAnswer}
                                  textId="xgVxV8TR5W"
                                  answerId="SumRuJgTcD"
                                  booletColor="#D9D9D9"
                                />
                              </FlexColumn>
                            </Flex>
                            <Flex flex={1}>
                              <PieChart
                                chartData={[
                                  {
                                    label: getStaticContentText(
                                      "chxQtWvzke",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    value: getValue("kdssXcLDwc"),
                                    colour: "#809980",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "Zffi9KIfT5",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    value: getValue("IRi8H6XpGo"),
                                    colour: "#4d704d",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "RXtpexllNu",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    value: getValue("bBomkodkGD"),
                                    colour: "#0a3300",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "e3PwWJdxbI",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    value: getValue("SumRuJgTcD"),
                                    colour: "#D9D9D9",
                                  },
                                ]}
                              />
                            </Flex>
                          </FlexRow>
                          <ErrorMessage
                            questionId={"kdssXcLDwc"}
                            isEditMode={showErrorMessage}
                          >
                            {getQuestionError("kdssXcLDwc")?.message}
                          </ErrorMessage>
                        </FlexColumn>
                      </GraphSubContainer>
                      <GraphSubContainer>
                        <FlexColumn>
                          <Text center={true} fontSize={12}>
                            {/* 1. Taxonomy-alignment of investments{" "}
                              <Bold>excluding sovereign bonds*</Bold> */}
                            {parse(
                              getStaticContentText(
                                "wh4aSzqfJj",
                                staticContent.staticText,
                                document.language
                              )
                            )}
                          </Text>
                          <FlexRow paddingTop={10}>
                            <Flex flex={1.5}>
                              <FlexColumn>
                                <GraphInput
                                  document={document}
                                  staticContent={staticContent}
                                  isEditMode={isEditMode || false}
                                  showChartInputPercentage={
                                    showChartInputPercentage
                                  }
                                  getValue={getValue}
                                  updateAnswer={updateAnswer}
                                  textId="chxQtWvzke"
                                  answerId="e5TGU2xAct"
                                  booletColor="#809980"
                                  isNumberDisabled={
                                    getValue("xhYlLXlDvC") === "No"
                                  }
                                />
                                <GraphInput
                                  document={document}
                                  staticContent={staticContent}
                                  isEditMode={isEditMode || false}
                                  showChartInputPercentage={
                                    showChartInputPercentage
                                  }
                                  getValue={getValue}
                                  updateAnswer={updateAnswer}
                                  textId="Zffi9KIfT5"
                                  answerId="vckL7B8rpr"
                                  booletColor="#4d704d"
                                  isNumberDisabled={
                                    getValue("xhYlLXlDvC") === "No"
                                  }
                                />
                                <GraphInput
                                  document={document}
                                  staticContent={staticContent}
                                  isEditMode={isEditMode || false}
                                  showChartInputPercentage={
                                    showChartInputPercentage
                                  }
                                  getValue={getValue}
                                  updateAnswer={updateAnswer}
                                  textId="RXtpexllNu"
                                  answerId="KMmwSpnToD"
                                  booletColor="#0a3300"
                                />
                                <GraphInput
                                  document={document}
                                  staticContent={staticContent}
                                  isEditMode={isEditMode || false}
                                  showChartInputPercentage={
                                    showChartInputPercentage
                                  }
                                  getValue={getValue}
                                  updateAnswer={updateAnswer}
                                  textId="xgVxV8TR5W"
                                  answerId="YjIPNWGFjJ"
                                  booletColor="#D9D9D9"
                                />
                              </FlexColumn>
                            </Flex>
                            <Flex flex={1}>
                              <PieChart
                                chartData={[
                                  {
                                    label: getStaticContentText(
                                      "chxQtWvzke",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    value: getValue("e5TGU2xAct"),
                                    colour: "#809980",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "Zffi9KIfT5",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    value: getValue("vckL7B8rpr"),
                                    colour: "#4d704d",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "RXtpexllNu",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    value: getValue("KMmwSpnToD"),
                                    colour: "#0a3300",
                                  },
                                  {
                                    label: getStaticContentText(
                                      "e3PwWJdxbI",
                                      staticContent.staticText,
                                      document.language
                                    ),
                                    value: getValue("YjIPNWGFjJ"),
                                    colour: "#D9D9D9",
                                  },
                                ]}
                              />
                            </Flex>
                          </FlexRow>
                          <Text fontSize={12}>
                            {
                              getQuestionLabel(
                                "pBfhwu3hBz",
                                displayQuestions,
                                Language?.[document.language]
                              )?.[0]
                            }
                            &nbsp;
                            <NumberInput
                              displayStyle={"inline"}
                              id="pBfhwu3hBz"
                              value={getValue("pBfhwu3hBz")}
                              onChange={(val) =>
                                updateAnswer("pBfhwu3hBz", val)
                              }
                              maxLimit={100}
                            />
                            &nbsp;
                            {
                              getQuestionLabel(
                                "pBfhwu3hBz",
                                displayQuestions,
                                Language?.[document.language]
                              )?.[1]
                            }
                          </Text>
                          <ErrorMessage
                            questionId="KMmwSpnToD"
                            isEditMode={showErrorMessage}
                          >
                            {getQuestionError("KMmwSpnToD")?.message}
                          </ErrorMessage>
                        </FlexColumn>
                      </GraphSubContainer>
                    </GraphContainer>
                    <RegulatoryExternalLinkComponent
                      linkId={REGULATORY_EXTERNAL_LINK_ID}
                      hidden={!isEditMode}
                      document={document}
                      staticContent={commonContent}
                    />
                    <Text isItalic isBold fontSize={12}>
                      <Label id={"ZTZDcS1XGG"} fontSize={12} isItalic isBold>
                        {parse(
                          getStaticContentText(
                            "ZTZDcS1XGG",
                            staticContent.staticText,
                            document.language
                          )
                        )}
                      </Label>
                      {/* *For the purpose of these graphs, ‘sovereign bonds’
                        consist of all sovereign exposures */}
                    </Text>
                    {/* NEW FOSSIL FUEL CHART */}
                  </OrangeBox>
                  <QuestionContainer
                    paddingLeft={0}
                    paddingTop={subQuestionTopPadding}
                  >
                    <QuestionText fontSize={14} isItalic isBold>
                      <GreyDot />
                      <InfoToolTip disabled={!showInfoIcon}>
                        {/* [include section for the financial products referred to in
                        Article 5, first paragraph, of Regulation (EU) 2020/852] */}
                        {parse(
                          getStaticContentText(
                            "SchpsI2sNe",
                            staticContent.infoTooltip,
                            document.language
                          )
                        )}
                      </InfoToolTip>
                      <QuestionLabel
                        fontSize={14}
                        isItalic
                        isBold
                        questionId="GbKDgG2duH"
                        getQuestionError={getQuestionError}
                        displayQuestions={displayQuestions}
                        language={document.language}
                        showErrorMessage={showErrorMessage}
                      />
                      <CommentButton
                        hasComments={hasCommentsById("GbKDgG2duH")}
                        disabled={!showCommentButton}
                        onClick={() => onComment("GbKDgG2duH")}
                      />
                      <PreviousAnswers
                        disabled={!showPreviousAns}
                        hasAnswers={
                          displayQuestions["GbKDgG2duH"].previousAnswers
                        }
                        onClick={() => onPreviousAnswersClick("GbKDgG2duH")}
                      />
                    </QuestionText>
                    <QuillEditor
                      id="GbKDgG2duH"
                      disabled={!isEditMode}
                      innerHTML={getValue("GbKDgG2duH")}
                      onChange={(ans) => updateAnswer("GbKDgG2duH", ans)}
                    />
                  </QuestionContainer>
                </QuestionContainer>
              )}
            </QuestionContainer>
          </>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("qLlcjYhGOQ") === "Yes" && (
              <GreyBox>
                <TooltipWrapper>
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include note for financial products referred to in Article
                    5, first paragraph, of Regulation (EU) 2020/852 that invest
                    in environmental economic activities that are not
                    environmentally sustainable economic activities] */}
                    {parse(
                      getStaticContentText(
                        "w2e5RCUS3H",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                </TooltipWrapper>
                <RelativeContainer>
                  <Block>
                    <Image
                      name="noBook"
                      height={40}
                      width={40}
                      style={{ float: "left", paddingRight: 10 }}
                    />
                    {/* are sustainable investments with an environmental objective
                    that{" "}
                    <strong> do not take into account the criteria </strong>
                    for environmentally sustainable economic activities under
                    the EU Taxonomy. */}
                    {parse(
                      getStaticContentText(
                        "Cm4c4tOnNp",
                        staticContent.greyBox,
                        document.language
                      )
                    )}
                  </Block>
                </RelativeContainer>
              </GreyBox>
            )}
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            {isEditMode && (
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold fontSize={14}>
                  <Highlight>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="qLlcjYhGOQ"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("qLlcjYhGOQ")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("qLlcjYhGOQ")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["qLlcjYhGOQ"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("qLlcjYhGOQ")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="24"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("qLlcjYhGOQ") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "qLlcjYhGOQ",
                        getValue("qLlcjYhGOQ") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="25"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("qLlcjYhGOQ") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "qLlcjYhGOQ",
                        getValue("qLlcjYhGOQ") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("qLlcjYhGOQ") === "Yes" && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <SubIcon name="noBook" />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* financial products referred to in Article 5 of Regulation
                    (EU) 2020/852 where the financial product invests in
                    environmental economic activities that are not
                    environmentally sustainable economic activities and explain
                    why the financial product invests in sustainable investments
                    with an environmental objective in economic activities that
                    are not Taxonomy-aligned] */}
                    {parse(
                      getStaticContentText(
                        "7erUyb6DQD",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isBold
                    questionId="QTEEo4G33V"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("QTEEo4G33V")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("QTEEo4G33V")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["QTEEo4G33V"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("QTEEo4G33V")}
                  />
                </QuestionText>
                <QuillEditor
                  id="QTEEo4G33V"
                  disabled={!isEditMode}
                  innerHTML={getValue("QTEEo4G33V")}
                  onChange={(ans) => updateAnswer("QTEEo4G33V", ans)}
                />
              </QuestionContainer>
            )}
            {isEditMode && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <Highlight>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="PCJgUzdBTo"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                  <CommentButton
                    hasComments={hasCommentsById("PCJgUzdBTo")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("PCJgUzdBTo")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["PCJgUzdBTo"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("PCJgUzdBTo")}
                  />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="26"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("PCJgUzdBTo") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "PCJgUzdBTo",
                        getValue("PCJgUzdBTo") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="27"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("PCJgUzdBTo") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "PCJgUzdBTo",
                        getValue("PCJgUzdBTo") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("PCJgUzdBTo") === "Yes" && (
              <QuestionContainer
                paddingLeft={0}
                paddingTop={subQuestionTopPadding}
              >
                <QuestionText isBold fontSize={14}>
                  <SubIcon name="users" />
                  <InfoToolTip disabled={!showInfoIcon}>
                    {/* [include section only where the financial product includes
                    sustainable investments with a social objective] */}
                    {parse(
                      getStaticContentText(
                        "n4sNXvE73s",
                        staticContent.infoTooltip,
                        document.language
                      )
                    )}
                  </InfoToolTip>
                  <QuestionLabel
                    fontSize={14}
                    isItalic
                    isBold
                    questionId="rLCXcmeNNF"
                    getQuestionError={getQuestionError}
                    displayQuestions={displayQuestions}
                    language={document.language}
                    showErrorMessage={showErrorMessage}
                  />
                  <CommentButton
                    hasComments={hasCommentsById("rLCXcmeNNF")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("rLCXcmeNNF")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["rLCXcmeNNF"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("rLCXcmeNNF")}
                  />
                </QuestionText>
                <QuillEditor
                  id="rLCXcmeNNF"
                  disabled={!isEditMode}
                  innerHTML={getValue("rLCXcmeNNF")}
                  onChange={(ans) => updateAnswer("rLCXcmeNNF", ans)}
                />
              </QuestionContainer>
            )}
            <QuestionContainer
              paddingLeft={0}
              paddingTop={subQuestionTopPadding}
            >
              <QuestionText isBold fontSize={14}>
                <SubIcon name="noMoney" />
                <InfoToolTip disabled={!showInfoIcon}>
                  {/* [describe the purpose of the remaining proportion of the
                  investments of the financial product, including a description of
                  any minimum environmental or social safeguards, how their
                  proportion and use does not affect the delivery of the
                  sustainable investment objective on a continuous basis and
                  whether those investments are used for hedging or relate to cash
                  held as ancillary liquidity] */}
                  {parse(
                    getStaticContentText(
                      "1nlMEPfU24",
                      staticContent.infoTooltip,
                      document.language
                    )
                  )}
                </InfoToolTip>
                <QuestionLabel
                  fontSize={14}
                  isBold
                  questionId="atBov449Kk"
                  getQuestionError={getQuestionError}
                  displayQuestions={displayQuestions}
                  language={document.language}
                  showErrorMessage={showErrorMessage}
                />
                <CommentButton
                  hasComments={hasCommentsById("atBov449Kk")}
                  disabled={!showCommentButton}
                  onClick={() => onComment("atBov449Kk")}
                />
                <PreviousAnswers
                  disabled={!showPreviousAns}
                  hasAnswers={displayQuestions["atBov449Kk"].previousAnswers}
                  onClick={() => onPreviousAnswersClick("atBov449Kk")}
                />
              </QuestionText>
              <QuillEditor
                id="atBov449Kk"
                disabled={!isEditMode}
                innerHTML={getValue("atBov449Kk")}
                onChange={(ans) => updateAnswer("atBov449Kk", ans)}
              />
            </QuestionContainer>
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            {getValue("Ou42viaQxY") === "Yes" && (
              <>
                <MainIcon name="graphs" />
                <GreyBox>
                  {/* <strong>Reference benchmarks </strong>
                  are indexes to measure whether the financial product attains
                  the sustainable investment objective. */}
                  {parse(
                    getStaticContentText(
                      "IffP2jhm5W",
                      staticContent.greyBox,
                      document.language
                    )
                  )}
                </GreyBox>
              </>
            )}
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            {isEditMode && (
              <QuestionContainer paddingLeft={0}>
                <QuestionText isBold>
                  <Highlight>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="Ou42viaQxY"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                  </Highlight>
                  <CommentButton
                    hasComments={hasCommentsById("Ou42viaQxY")}
                    disabled={!showCommentButton}
                    onClick={() => onComment("Ou42viaQxY")}
                  />
                  <PreviousAnswers
                    disabled={!showPreviousAns}
                    hasAnswers={displayQuestions["Ou42viaQxY"].previousAnswers}
                    onClick={() => onPreviousAnswersClick("Ou42viaQxY")}
                  />
                  <DocDoNotIncludeIcon disabled={!showDoNotIncludeIcon} />
                </QuestionText>
                <FlexRow paddingTop={5}>
                  <Checkbox
                    id="28"
                    label={getStaticContentText(
                      "C7uLFog7hl",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    disabled={!isEditMode}
                    checked={getValue("Ou42viaQxY") === "Yes"}
                    onClick={() =>
                      updateAnswer(
                        "Ou42viaQxY",
                        getValue("Ou42viaQxY") === "Yes" ? "" : "Yes"
                      )
                    }
                  />
                  <Checkbox
                    id="29"
                    label={getStaticContentText(
                      "bG9v3QJaqt",
                      staticContent.questionAnswerOption,
                      document.language
                    )}
                    marginLeft={60}
                    disabled={!isEditMode}
                    checked={getValue("Ou42viaQxY") === "No"}
                    onClick={() =>
                      updateAnswer(
                        "Ou42viaQxY",
                        getValue("Ou42viaQxY") === "No" ? "" : "No"
                      )
                    }
                  />
                </FlexRow>
              </QuestionContainer>
            )}
            {getValue("Ou42viaQxY") === "Yes" && (
              <>
                <QuestionContainer
                  paddingLeft={0}
                  paddingTop={subQuestionTopPadding}
                >
                  <QuestionText isBold>
                    <InfoToolTip disabled={!showInfoIcon}>
                      {/* [include section only for financial products referred to in
                      Article 9(1) of Regulation (EU) 2019/2088 and indicate where
                      the methodology used for the calculation of the designated
                      index can be found] */}
                      {parse(
                        getStaticContentText(
                          "wkSVrHGeve",
                          staticContent.infoTooltip,
                          document.language
                        )
                      )}
                    </InfoToolTip>
                    <QuestionLabel
                      fontSize={14}
                      isBold
                      questionId="LRxxg2wyGn"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("LRxxg2wyGn")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("LRxxg2wyGn")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["LRxxg2wyGn"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("LRxxg2wyGn")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="LRxxg2wyGn"
                    disabled={!isEditMode}
                    innerHTML={getValue("LRxxg2wyGn")}
                    onChange={(ans) => updateAnswer("LRxxg2wyGn", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      isItalic
                      isBold
                      questionId="76BAEdFTEx"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("76BAEdFTEx")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("76BAEdFTEx")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["76BAEdFTEx"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("76BAEdFTEx")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="76BAEdFTEx"
                    disabled={!isEditMode}
                    innerHTML={getValue("76BAEdFTEx")}
                    onChange={(ans) => updateAnswer("76BAEdFTEx", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      questionId="77U1FJyUIM"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("77U1FJyUIM")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("77U1FJyUIM")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["77U1FJyUIM"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("77U1FJyUIM")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="77U1FJyUIM"
                    disabled={!isEditMode}
                    innerHTML={getValue("77U1FJyUIM")}
                    onChange={(ans) => updateAnswer("77U1FJyUIM", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    <QuestionLabel
                      fontSize={14}
                      questionId="b9b4uYpcej"
                      getQuestionError={getQuestionError}
                      displayQuestions={displayQuestions}
                      language={document.language}
                      showErrorMessage={showErrorMessage}
                    />
                    <CommentButton
                      hasComments={hasCommentsById("b9b4uYpcej")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("b9b4uYpcej")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["b9b4uYpcej"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("b9b4uYpcej")}
                    />
                  </QuestionText>
                  <QuillEditor
                    id="b9b4uYpcej"
                    disabled={!isEditMode}
                    innerHTML={getValue("b9b4uYpcej")}
                    onChange={(ans) => updateAnswer("b9b4uYpcej", ans)}
                  />
                </QuestionContainer>
                <QuestionContainer
                  paddingTop={subQuestionTopPadding}
                  paddingLeft={10}
                >
                  <QuestionText fontSize={14} isItalic isBold>
                    <GreyDot />
                    {getQuestionLabel(
                      "t7u0YVuYo5",
                      displayQuestions,
                      Language?.[document.language]
                    )}
                    <CommentButton
                      hasComments={hasCommentsById("t7u0YVuYo5")}
                      disabled={!showCommentButton}
                      onClick={() => onComment("t7u0YVuYo5")}
                    />
                    <PreviousAnswers
                      disabled={!showPreviousAns}
                      hasAnswers={
                        displayQuestions["t7u0YVuYo5"].previousAnswers
                      }
                      onClick={() => onPreviousAnswersClick("t7u0YVuYo5")}
                    />
                    <ErrorMessage
                      questionId={"t7u0YVuYo5"}
                      isEditMode={showErrorMessage}
                    >
                      {getQuestionError("t7u0YVuYo5")?.message}
                    </ErrorMessage>
                  </QuestionText>
                  <QuillEditor
                    id="t7u0YVuYo5"
                    disabled={!isEditMode}
                    innerHTML={getValue("t7u0YVuYo5")}
                    onChange={(ans) => updateAnswer("t7u0YVuYo5", ans)}
                  />
                </QuestionContainer>
              </>
            )}
          </QuestionContainer>
        }
      />
      <Section
        paddingTop={20}
        left={
          <>
            <MainIcon name="computer" />
          </>
        }
        right={
          <QuestionContainer paddingTop={10}>
            <QuestionText isBold>
              <InfoToolTip disabled={!showInfoIcon}>
                {/* [include a hyperlink to the website with the information
                referred to in Article 32 of Delegated Regulation 202x/xx] */}
                {parse(
                  getStaticContentText(
                    "7aXniab97V",
                    staticContent.infoTooltip,
                    document.language
                  )
                )}
              </InfoToolTip>
              <QuestionLabel
                fontSize={14}
                isBold
                questionId="aLv49sQYH3"
                getQuestionError={getQuestionError}
                displayQuestions={displayQuestions}
                language={document.language}
                showErrorMessage={showErrorMessage}
              />
              <CommentButton
                hasComments={hasCommentsById("aLv49sQYH3")}
                disabled={!showCommentButton}
                onClick={() => onComment("aLv49sQYH3")}
              />
              <PreviousAnswers
                disabled={!showPreviousAns}
                hasAnswers={displayQuestions["aLv49sQYH3"].previousAnswers}
                onClick={() => onPreviousAnswersClick("aLv49sQYH3")}
              />
            </QuestionText>
            <QuillEditor
              id="aLv49sQYH3"
              disabled={!isEditMode}
              innerHTML={getValue("aLv49sQYH3")}
              onChange={(ans) => updateAnswer("aLv49sQYH3", ans)}
            />
          </QuestionContainer>
        }
      />
    </Container>
  );
};

export default ArticleNinePreContractual;
