import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${spacingIncrement(15)};
  row-gap: ${spacingIncrement(25)};
  padding: ${spacingIncrement(25)};
`;

export const DisabledContainer = styled.div`
  background: #f6f6f6;
  display: flex;
  align-items: center;
  border: 1px solid #d9dae3;
  box-sizing: border-box;
  border-radius: 5px;
  height: ${spacingIncrement(42)};
  padding-left: ${spacingIncrement(15)};
  margin-top: ${spacingIncrement(8)};
`;

export const Box = styled.div`
  padding: 15px;
  -webkit-box-shadow: 0px 0px 4px 0px #00000033;
  box-shadow: 0px 0px 4px 0px #00000033;
  margin-bottom: 15px;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacingIncrement(10)} 0;
`;

export const BoxFooter = styled.div`
  color: #868e96;
  font-size: 12px;
  line-height: 14px;
  width: ${spacingIncrement(400)};
  padding: 10px 0px;
`;
export const RightText = styled.div`
  float: right;
  color: #868e96;
  font-size: 12px;
  line-height: 14px;
  width: 9rem;
  margin-top: 0.7rem;
`;
export const CopyWrap = styled.div`
  float: right;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
`;
export const CustomTooltip = styled.span<{ width: number }>`
  border-radius: ${spacingIncrement(5)};
  padding: ${spacingIncrement(10)};
  ${({ width }) =>
    width &&
    css`
      width: ${spacingIncrement(width)};
    `}
  color:rgb(53, 203, 147);
`;

export const RecentAnsWrap = styled.div`
  padding: 10px 20px;
`;

export const ResentAnswerTitle = styled.div`
  padding: 20px;
  padding-top: 0;
  border-bottom: 1px solid #dee2e6 !important;
`;

export const ViewMore = styled.div`
  font-size: 13px;
  color: #0abaee;
  text-decoration: underline;
  line-height: 18px;
  cursor: pointer;
`;
export const AnswerText = styled.div`
  width: 85%;
  padding-left: 13px;
  word-break: keep-all;
  width: 100% !important;
  overflow-x: auto;
  p {
    margin-bottom: 0;
  }
`;
export const ClickToCopy = styled.div`
  font-size: 13px;
`;
