import React from "react";
import SlidingPanel, { PanelType } from "react-sliding-side-panel";
import Image from "../Image";
import {
  Container,
  HeaderContainer,
  ContentContainer,
  StyledSideWrapper,
  Title,
  TitleContainer,
} from "./styles";
import "react-sliding-side-panel/lib/index.css";

type Props = {
  isOpen?: boolean;
  type?: PanelType;
  size?: number;
  onClose?: () => void;
  header?: string | JSX.Element;
  content?: string | JSX.Element;
};

const SidePanel: React.FC<Props> = ({
  isOpen = false,
  type = "right",
  size = 40,
  onClose,
  header,
  content,
}) => {
  return (
    <StyledSideWrapper>
      <SlidingPanel
        isOpen={isOpen}
        type={type}
        size={size}
        backdropClicked={onClose}
      >
        <Container>
          <HeaderContainer>
            <TitleContainer>
              <Title>{header}</Title>
              <Image
                name="cross"
                color="#3C3C3B"
                onClick={onClose}
                style={{ cursor: "pointer" }}
              />
            </TitleContainer>
          </HeaderContainer>
          <ContentContainer>{content}</ContentContainer>
        </Container>
      </SlidingPanel>
    </StyledSideWrapper>
  );
};

export default SidePanel;
