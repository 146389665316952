import React from "react";
import { ImageProps } from "../types";

const Info: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="476"
      height="476"
      viewBox="0 0 476 476"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M263.43 79.86C250.762 84.5827 242.985 97.79 242.985 114.641C242.985 126.868 245.786 134.817 252.165 140.383C259.149 146.481 266.477 147.188 275.544 142.735C284.458 138.289 289.61 131.321 292.653 119.633C299.047 94.7536 282.907 72.9096 263.43 79.8596L263.43 79.86Z"
        fill="black"
      />
      <path
        d="M241.74 164.17C226.295 167.361 209.326 180.432 194.017 201.033C183.177 215.502 178.455 225.638 180.533 229.553C181.369 231.088 182.892 232.33 184.029 232.33C185.01 232.33 190.697 225.096 196.681 216.201C202.658 207.428 209.333 198.674 211.56 196.713C219.904 189.635 226.455 194.354 223.38 205.487C222.696 208.416 215.169 233.307 206.962 260.85C189.153 320.002 185.532 335.541 185.407 352.655C185.407 363.362 185.817 365.866 188.595 370.182C195.013 380.596 209.056 381.873 225.88 373.693C248.841 362.263 281.786 322.908 280.392 308.443C280.138 305.643 279.306 303.986 278.063 303.986C275.954 303.986 274.024 306.361 259.556 326.404C247.029 343.634 238.985 346.463 238.985 333.506C238.985 330.444 246.208 302.338 254.958 270.885C263.716 239.463 271.661 209.858 272.501 204.846C274.619 193.311 273.498 179.53 269.986 173.686C265.111 165.284 254.396 161.663 241.74 164.17L241.74 164.17Z"
        fill="black"
      />
      <path
        d="M237.57 469.55C109.65 469.55 5.60001 365.47 5.60001 237.55C5.60001 109.64 109.65 5.59003 237.57 5.59003C365.47 5.59003 469.55 109.64 469.55 237.55C469.546 365.46 365.47 469.55 237.57 469.55ZM237.57 22.28C118.87 22.28 22.29 118.862 22.29 237.55C22.29 356.24 118.856 452.84 237.57 452.84C356.27 452.84 452.84 356.274 452.84 237.55C452.84 118.84 356.274 22.28 237.57 22.28V22.28Z"
        fill="black"
      />
      <path
        d="M237.57 475.15C106.57 475.15 0 368.56 0 237.56C0 106.56 106.57 0 237.57 0C368.58 0 475.15 106.57 475.15 237.56C475.15 368.56 368.57 475.15 237.57 475.15ZM237.57 11.19C112.75 11.19 11.2 112.73 11.2 237.55C11.2 362.39 112.75 463.94 237.57 463.94C362.39 463.94 463.95 362.38 463.95 237.54C463.95 112.72 362.4 11.18 237.57 11.18V11.19ZM237.57 458.44C115.78 458.44 16.69 359.354 16.69 237.55C16.69 115.76 115.776 16.67 237.57 16.67C359.36 16.67 458.44 115.752 458.44 237.55C458.436 359.35 359.354 458.44 237.57 458.44ZM237.57 27.88C121.95 27.88 27.89 121.939 27.89 237.56C27.89 353.181 121.952 447.25 237.57 447.25C353.19 447.25 447.24 353.18 447.24 237.56C447.24 121.94 353.181 27.88 237.57 27.88Z"
        fill="black"
      />
    </svg>
  );
};

export default Info;
