import React from "react";
import { ImageProps } from "../types";

const MoreOptions: React.FC<ImageProps> = ({
  color = "#ADB5BD",
  ...otherProps
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M31.25 16C31.25 24.4223 24.4223 31.25 16 31.25C7.57766 31.25 0.75 24.4223 0.75 16C0.75 7.57766 7.57766 0.75 16 0.75C24.4223 0.75 31.25 7.57766 31.25 16Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <ellipse
        cx="15.9999"
        cy="15.9999"
        rx="2.28571"
        ry="2.28571"
        transform="rotate(90 15.9999 15.9999)"
        fill={color}
      />
      <ellipse
        cx="22.8571"
        cy="15.9999"
        rx="2.28571"
        ry="2.28571"
        transform="rotate(90 22.8571 15.9999)"
        fill={color}
      />
      <ellipse
        cx="9.14275"
        cy="15.9999"
        rx="2.28571"
        ry="2.28571"
        transform="rotate(90 9.14275 15.9999)"
        fill={color}
      />
    </svg>
  );
};

export default MoreOptions;
