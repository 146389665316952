import React, { FC, useContext, useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { ModalFooterContainer } from "../ClientTable/styles";
import { postData } from "../../../services/apis";
import { notifyError, notifySuccess } from "../../../utils/common";
import { BulkSelected } from "../../../types/listing-table";
import { TUserDetails } from "../../../types/user-details";
import { FundList } from "../../../types/fund";
import { getDownloadBulkData } from "../../../utils/wordRequest";
import GlobalContext from "../../../store/context";
import { updateIsLoading } from "../../../store/globalActions";
import { GLOBAL_CONTEXT } from "../../../types/store";

type Props = {
  isOpen: boolean;
  onCloseRequestModal: () => void;
  selectedDocs: BulkSelected[];
  selectedFund: FundList;
  setRequestWordDoc: React.Dispatch<React.SetStateAction<boolean>>;
  userDetails: TUserDetails;
};

export const WordRequestModal: FC<Props> = ({
  isOpen,
  onCloseRequestModal,
  selectedDocs,
  selectedFund,
  userDetails,
  setRequestWordDoc,
}) => {
  const { globalDispatch } = useContext<GLOBAL_CONTEXT>(GlobalContext);
  const [unFormatted, setUnFormatted] = useState(false);
  const onConfirm = async () => {
    const data = {
      clientId: userDetails?.clientId,
      downloadData: getDownloadBulkData(
        selectedDocs,
        unFormatted,
        selectedFund
      ),
    };
    globalDispatch(updateIsLoading(true));
    const response = await postData({
      endPoint: "generateWordDocuments",
      data,
    });
    if (response?.status === 200) {
      notifySuccess(
        <div style={{ textAlign: "center" }}>
          <div>We are generating the requested DOCX file for you.</div>
          <div>You will shortly receive an e-mail with a download link.</div>
        </div>,
        { width: "500px", left: "calc(50% - 250px)", textAlign: "center" }
      );
      setRequestWordDoc(false);
    } else {
      notifyError("Request has not been submitted, Please try again");
    }
    globalDispatch(updateIsLoading(false));
    onCloseRequestModal();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseRequestModal}
      header="Format Confirmation"
    >
      <div className="modal__body p-30">
        <div>
          <p className="text-18">
            Select the preferred format for the Word document.
          </p>
        </div>

        <div className="">
          <div className="form-check-group d-flex">
            <div className="custom-control custom-radio mr-4">
              <input
                className="custom-control-input"
                type="radio"
                id="ddd-radiogroup-5-1"
                name="ddd-radiogroup-5"
                onClick={() => setUnFormatted(false)}
                checked={!unFormatted}
              />
              <label
                className="custom-control-label"
                htmlFor="ddd-radiogroup-5-1"
              >
                DOCX
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                type="radio"
                id="ddd-radiogroup-5-2"
                name="ddd-radiogroup-5"
                onClick={() => setUnFormatted(true)}
                checked={unFormatted}
              />
              <label
                className="custom-control-label"
                htmlFor="ddd-radiogroup-5-2"
              >
                Unformatted DOCX
              </label>
            </div>
          </div>
        </div>
        <div className="mb-20">
          <p className="text-12">
            Please note that any changes made outside the system in the document
            will not be automatically reflected on the system.
          </p>
        </div>
        <ModalFooterContainer>
          <Button buttonType="solid" onClick={onConfirm}>
            Confirm
          </Button>
          <Button onClick={onCloseRequestModal}>Cancel</Button>
        </ModalFooterContainer>
      </div>
    </Modal>
  );
};
