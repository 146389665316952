import _ from "lodash";
import { ApprovalDetails, ApprovalGroup } from "../types/document-details";

export const getNextApprovalGroupLevel = (
  approvalGroups: ApprovalGroup[],
  approvalDetails: ApprovalDetails[]
): string | undefined => {
  const currentGroupId = approvalDetails?.[approvalDetails.length - 1]?.groupId;
  const levels = _.uniq(
    approvalGroups.map((a) => parseInt(a.approvalLevel))
  ).sort();
  const firstApprovalLevel = Math.min(...levels);
  // If no currentGroupId, then return first approval level
  if (!currentGroupId) {
    return (
      approvalGroups.find(
        (d) => d.approvalLevel === firstApprovalLevel.toString()
      )?.approvalLevel || ""
    );
  }
  const currentGroup = approvalGroups.find((d) => d.groupId === currentGroupId);
  if (
    approvalDetails.filter(
      (ad) => ad.approvalLevel === currentGroup?.approvalLevel
    ).length !==
    approvalGroups.filter(
      (ag) => ag.approvalLevel === currentGroup?.approvalLevel
    ).length
  ) {
    return currentGroup?.approvalLevel;
  }
  const index = levels.indexOf(parseInt(currentGroup?.approvalLevel || ''));
  const nextApprovalLevel = levels[index + 1] || levels[index];
  return nextApprovalLevel.toString();
};
