import { useReducer } from "react";
import { initialFilterState } from "../features/listing";
import { GLOBAL_STATE } from "../types/store";
import globalReducer from "./globalReducer";

const initialState: GLOBAL_STATE = {
  isUserLoggedIn: false,
  isLoading: false,
  documentList: [],
  archivedDocumentList: [],
  fundList: [],
  filters: initialFilterState,
  archivedFilters: initialFilterState,
  commentedQuestionIds: [],
  totalDocumentsCount: 0,
  languages: [],
  approverGroup: [],
  documentErrorState: { isFailed: false },
  documentQuestions: [],
  errorMessageFound: false,
};

const useGlobalState = () => {
  const [globalState, globalDispatch] = useReducer(globalReducer, initialState);
  return { globalState, globalDispatch };
};

export default useGlobalState;
