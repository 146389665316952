import React, { useEffect } from "react";
import SidePanel from "../../../components/SidePanel";
import "flatpickr/dist/themes/material_green.css";
import { ContentContainer } from "./styles";
import { BulkSelected } from "../../../types/listing-table";
import AnswerReplace from "./AnswerReplace";
import FindAndReplace from "./FindAndReplace";
import { updateLockStatus } from "../../../services/document";
import { TUserDetails } from "../../../types/user-details";

type Props = {
  isOpen: boolean;
  selectedBulkIds: BulkSelected[];
  clearSelectedBulkIds?: () => void;
  amendType: "answer" | "findReplace";
  onClose?: () => void;
  userDetails: TUserDetails;
};

const BulkAmendmentSidePanel: React.FC<Props> = ({
  isOpen = false,
  selectedBulkIds,
  clearSelectedBulkIds,
  onClose,
  amendType,
  userDetails,
}) => {
  useEffect(() => {
    if (isOpen) updateDocumentslockStatus("lock");
  }, [selectedBulkIds, isOpen]);

  function updateDocumentslockStatus(lockStatus: "lock" | "unlock") {
    selectedBulkIds.map((doc) => {
      updateLockStatus(doc.id, lockStatus, userDetails?.clientId);
    });
  }

  const getSidePanelHeader = () => {
    switch (amendType) {
      case "answer":
        return "Answer Amendment";
      case "findReplace":
        return "Find and Replace";
      default:
        return "Answer Amendment";
    }
  };
  const getSidePanelContent = () => {
    switch (amendType) {
      case "answer":
        return (
          <AnswerReplace
            selectedBulkIds={selectedBulkIds}
            onClose={onCloseHandler}
          />
        );
      case "findReplace":
        return (
          <FindAndReplace
            selectedBulkIds={selectedBulkIds}
            onClose={onCloseHandler}
          />
        );
      default:
        return "Answer Amendment";
    }
  };
  const onCloseHandler = () => {
    updateDocumentslockStatus("unlock");
    clearSelectedBulkIds?.();
    onClose?.();
  };
  return (
    <SidePanel
      isOpen={isOpen}
      onClose={onCloseHandler}
      header={getSidePanelHeader()}
      content={<ContentContainer>{getSidePanelContent()}</ContentContainer>}
    />
  );
};

export default BulkAmendmentSidePanel;
