import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
    .buttonAsLink {
      background: none !important;
      border: none;
      color: #069;
      cursor: pointer;
    }
    .buttonAsLink:focus {
      outline: none !important;
    }
    .Toastify__toast-container--top-center {
      top: 1em;
      margin: auto;
      left: 0;
      right: 0;
      transform: unset;
    }
    .sliding-panel-container.active  {
      z-index: 9998 !important;
    }
`;

export default GlobalStyle;
