import React from "react";
function NoDataFound({ message }: { message?: string }) {
  return (
    <div className="container">
      <div className="row row--cards-equal-height distributor-list">
        <div className="card col-sm-12 card-onclick card--list">
          <div className="card__body">
            <h4 className="card__title">
              <p>{message || "No Data Found"}</p>
            </h4>
            <div className="details__list"></div>
          </div>
          <div className="card__actions"></div>
        </div>
      </div>
    </div>
  );
}
export default NoDataFound;
