import React from "react";
import { ImageProps } from "../types";

const Download: React.FC<ImageProps> = ({
  color = "#ADB5BD",
  ...otherProps
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.5C7.99187 1.5 1.5 7.99187 1.5 16C1.5 24.0081 7.99187 30.5 16 30.5C24.0081 30.5 30.5 24.0081 30.5 16C30.5 7.99187 24.0081 1.5 16 1.5ZM0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6764 10.76C14.1043 10.76 12.8469 12.2377 13.0282 13.8313C13.0613 14.1219 12.9463 14.4098 12.7215 14.5995C12.4967 14.7892 12.1914 14.8559 11.9068 14.7776C10.8883 14.4973 9.77778 15.3419 9.77778 16.48C9.77778 17.2433 10.079 17.6114 10.4919 17.8449C10.981 18.1214 11.7032 18.24 12.5244 18.24C13.0154 18.24 13.4133 18.634 13.4133 19.12C13.4133 19.606 13.0154 20 12.5244 20C11.5975 20 10.5019 19.8773 9.61029 19.3732C8.64244 18.826 8 17.8741 8 16.48C8 14.6551 9.47409 13.083 11.2699 12.9661C11.5433 10.8388 13.3295 9 15.6764 9C17.4891 9 19.0278 10.0949 19.7234 11.6428C22.031 11.5603 24 13.5146 24 15.82C24 18.496 21.7731 20 19.4747 20C18.9837 20 18.5858 19.606 18.5858 19.12C18.5858 18.634 18.9837 18.24 19.4747 18.24C21.0559 18.24 22.2222 17.2812 22.2222 15.82C22.2222 14.2904 20.7573 13.1179 19.3355 13.465C18.8591 13.5814 18.3776 13.2935 18.2598 12.8219C17.9593 11.6193 16.9013 10.76 15.6764 10.76Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16C16.5523 16 17 16.3918 17 16.875V22.125C17 22.6082 16.5523 23 16 23C15.4477 23 15 22.6082 15 22.125V16.875C15 16.3918 15.4477 16 16 16Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2864 20.2655C13.6602 19.9037 14.2571 19.9128 14.6196 20.286L16 21.7069L17.3804 20.286C17.7429 19.9128 18.3398 19.9037 18.7136 20.2655C19.0873 20.6274 19.0965 21.2233 18.734 21.5964L16.6768 23.714C16.4992 23.8968 16.2551 24 16 24C15.7449 24 15.5008 23.8968 15.3232 23.714L13.266 21.5964C12.9035 21.2233 12.9127 20.6274 13.2864 20.2655Z"
        fill={color}
      />
    </svg>
  );
};

export default Download;
