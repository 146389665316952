import React from "react";
import { ImageProps } from "../types";

const Edit: React.FC<ImageProps> = ({ color = "#ADB5BD", ...otherProps }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.5C7.99187 1.5 1.5 7.99187 1.5 16C1.5 24.0081 7.99187 30.5 16 30.5C24.0081 30.5 30.5 24.0081 30.5 16C30.5 7.99187 24.0081 1.5 16 1.5ZM0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7604 10.123C19.9566 10.0417 20.167 9.99989 20.3794 10C20.5918 10.0001 20.8021 10.0422 20.9982 10.1237C21.1464 10.1854 21.2844 10.2686 21.4076 10.3706C21.6527 10.6638 21.7883 11.035 21.7879 11.42C21.7874 11.8504 21.617 12.2633 21.3138 12.5687L21.3129 12.5697L20.813 13.0695L18.7268 10.9832L19.2359 10.474C19.3859 10.3237 19.5642 10.2044 19.7604 10.123ZM17.3125 12.3974L10 19.71V21.7958H12.0858L19.3987 14.4836L17.3125 12.3974ZM21.7661 8.27702C22.0201 8.38263 22.2606 8.51663 22.4831 8.67606C22.5725 8.722 22.6564 8.78228 22.7314 8.85692L22.9175 9.04209L22.9397 9.06423C23.0213 9.1462 23.0857 9.23878 23.1331 9.33752C23.5573 9.94468 23.7887 10.6716 23.7879 11.4222C23.7869 12.3808 23.407 13.3001 22.7311 13.9798L22.7291 13.9819L13.2071 23.5029C13.0195 23.6904 12.7652 23.7958 12.5 23.7958H9C8.44772 23.7958 8 23.348 8 22.7958V19.2958C8 19.0305 8.10536 18.7762 8.29289 18.5886L17.8205 9.06105C18.1563 8.72454 18.5553 8.4576 18.9945 8.27552C19.4338 8.09337 19.9048 7.99974 20.3805 8C20.8561 8.00026 21.327 8.0944 21.7661 8.27702Z"
        fill={color}
      />
    </svg>
  );
};

export default Edit;
