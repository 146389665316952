import React, { useEffect, useMemo, useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import Checkbox from "../Checkbox";
import Image from "../Image";
import { DocumentFilter, FilterData } from "../../types/fund";
import { spacingIncrement } from "../../utils/common";
import {
  Container,
  ToolTipContainer,
  ToolTipArrow,
  CheckboxWrapper,
  SearchInput,
  ListContainer,
  Button,
  SubmitButton,
  ImageWrapper,
} from "./styles";

export type Option = {
  label: string;
  value: string;
};

type Props = {
  filterData: FilterData;
  onChange?: (value: string) => void;
  selectedFilters?: DocumentFilter[];
  clearFilter?: (filterName: string, filterId: string) => void;
};

const FilterOptions: React.FC<Props> = ({
  onChange,
  filterData,
  selectedFilters,
  clearFilter,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Option[]>([]);
  const {
    tooltipRef,
    setTooltipRef,
    setTriggerRef,
    getArrowProps,
    getTooltipProps,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    interactive: true,
    offset: [0, 10],
    placement: "bottom-start",
  });

  const onClickOption = (opt: Option) => {
    if (selectedValue?.find((item) => item.value === opt.value)) {
      setSelectedValue(
        selectedValue.filter((item) => item.value !== opt.value)
      );
    } else {
      setSelectedValue([...selectedValue, opt]);
    }
  };

  const onApply = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tooltipRef!.style.display = "none";
    onChange?.(selectedValue.map((item) => item.value).join(","));
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (!visible) {
      setSearchText("");
    }
  }, [visible]);

  const options = useMemo(() => {
    return filterData.listValues;
  }, [filterData]);

  const findFilterValue = (filterName: string) => {
    const found =
      selectedFilters &&
      selectedFilters.find((filter) => {
        return filter.filterName === filterName;
      });
    if (found) {
      return found.filterValues || [];
    } else {
      return [];
    }
  };

  const label = useMemo(() => {
    const selectedFilterArr = findFilterValue(filterData.name);
    if (selectedFilterArr.length) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
    setSelectedValue(selectedFilterArr);
    return (
      selectedFilterArr.map((val) => val.label).toString() || filterData.label
    );
  }, [filterData.name, selectedFilters]);

  const onClearFilter = () => {
    clearFilter?.(filterData.name, filterData.id);
  };

  return (
    <>
      <Container isSelected={isSelected}>
        <Button isSelected={isSelected} ref={setTriggerRef}>
          {label}
          {isSelected && (
            <ImageWrapper onMouseUp={onClearFilter}>
              <Image name="cross" height="11" color="white" />
            </ImageWrapper>
          )}
        </Button>
      </Container>
      {visible && (
        <ToolTipContainer
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <ToolTipArrow
            {...getArrowProps({
              className: "tooltip-arrow",
            })}
          />
          <SearchInput
            id={`${label.replaceAll(" ", "-").toLowerCase()}-filter-searchbox`}
            placeholder="Search"
            onChange={onSearch}
          />
          <ListContainer>
            {options
              ?.filter((opt) => {
                if (searchText === "") return true;
                return opt.label
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              })
              .map((opt, index) => (
                <CheckboxWrapper key={opt.value}>
                  <Checkbox
                    id={`filter-checkbox-${index}`}
                    label={opt.label}
                    checked={
                      !!selectedValue.find((item) => item.value === opt.value)
                    }
                    labelStyle={{
                      fontFamily: `"Brown-Bold","Brown Bold","Helvetica Neue","Helvetica","Arial",sans-serif`,
                      fontWeight: 600,
                      fontSize: spacingIncrement(16),
                    }}
                    onChange={() => onClickOption(opt)}
                  />
                </CheckboxWrapper>
              ))}
          </ListContainer>
          <SubmitButton onClick={onApply}>Apply</SubmitButton>
        </ToolTipContainer>
      )}
    </>
  );
};

export default FilterOptions;
