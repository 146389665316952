import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";
import Collapsible from "react-collapsible";

export const Container = styled.div`
  padding: ${spacingIncrement(25)};
`;

export const ApprovalGroupItemWrapper = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  flex: 19;
`;

export const ApprovalGroupItem = styled(Collapsible)`
  span {
    display: flex;
    cursor: pointer;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TitleRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacingIncrement(10)};
`;

export const TitleLeftWrapper = styled.div``;

export const ApprovalStatusTag = styled.div<{ isApproved?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isApproved }) => (isApproved ? "#35CB93" : "#f5a623")};
  height: ${spacingIncrement(40)};
  border-radius: ${spacingIncrement(20)};
  padding: ${spacingIncrement(20)};
  color: #fff;
  font-size: ${spacingIncrement(13)};
  font-weight: 600;
`;

export const ItemName = styled.div`
  font-size: ${spacingIncrement(16)};
  font-weight: 400;
  color: #303246;
`;

export const ItemSubName = styled.div`
  font-size: ${spacingIncrement(12)};
  font-weight: 400;
  color: #303246;
`;

export const ItemSubDate = styled.div`
  font-size: ${spacingIncrement(9)};
  font-weight: 400;
  color: #595b6b;
  display: inline-block;
`;

export const ApproverUsersWrapper = styled.div`
  border-top: 1px dashed #d9dae3;
  padding: ${spacingIncrement(10)} ${spacingIncrement(20)};
`;

export const UserGroupTitle = styled.div`
  font-size: ${spacingIncrement(15)};
  font-weight: 500;
  padding-bottom: ${spacingIncrement(6)};
`;

export const UserGroupItem = styled.div`
  font-size: ${spacingIncrement(12)};
  font-weight: 400;
  padding-bottom: ${spacingIncrement(3)};
`;

export const ApproverRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacingIncrement(20)};
`;

export const CheckboxWrapper = styled.div`
  flex: 1;
`;

export const ConfirmationCheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacingIncrement(20)};
`;
