import React, { useEffect, useState } from "react";
import { Group } from "@visx/group";
import { hierarchy, Tree } from "@visx/hierarchy";
import { LinkHorizontalStep, LinkVerticalStep } from "@visx/shape";
import { NodeConfig } from "./config";
import { DocumentCommonContent, Language } from "../../types/document-question";
import { Text } from "@visx/text";
import { getStaticContentText } from "../../features/templates/DocumentEditor/service";

const defaultMargin = { top: 30, left: 100, right: 100, bottom: 70 };

export type LinkTypesProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data: NodeConfig;
  language: string;
  commonContent: DocumentCommonContent;
};

export default function CustomChart({
  width: totalWidth,
  height: totalHeight,
  margin = defaultMargin,
  data,
  language = Language.English,
  commonContent,
}: LinkTypesProps) {
  const [orientation, setOrientation] = useState<string>("horizontal");
  const [stepPercent, setStepPercent] = useState<number>(0.5);
  const [refreshState, setRefreshState] = useState<number>(0);

  const innerWidth = totalWidth - margin.left - margin.right;
  const innerHeight = totalHeight - margin.top - margin.bottom;

  const origin: { x: number; y: number } = { x: 0, y: 0 };
  let sizeWidth: number;
  let sizeHeight: number;
  let LinkComponent: any;

  if (orientation === "vertical") {
    sizeWidth = innerWidth;
    sizeHeight = innerHeight;
    LinkComponent = LinkVerticalStep;
  } else {
    sizeWidth = innerHeight;
    sizeHeight = innerWidth;
    LinkComponent = LinkHorizontalStep;
  }

  useEffect(() => {
    setRefreshState(refreshState + 1);
  }, [data]);

  return totalWidth < 10 ? null : (
    <div>
      <svg width={totalWidth} height={totalHeight}>
        <rect width={totalWidth} height={totalHeight} rx={0} fill="#fae8d5" />
        <Group top={margin.top} left={margin.left}>
          <Tree
            root={hierarchy(data, (d) => (d.isExpanded ? null : d.children))}
            size={[sizeWidth, sizeHeight]}
            separation={(a, b) => (a.parent === b.parent ? 2 : 1)}
          >
            {(tree) => (
              <Group top={origin.y} left={origin.x}>
                {tree.links().map((link, i) => (
                  <LinkComponent
                    key={i}
                    data={link}
                    percent={stepPercent}
                    stroke="#000"
                    strokeWidth="1"
                    fill="none"
                  />
                ))}

                {tree.descendants().map((node, key) => {
                  const width = node.data?.nodeStyles?.width || 150;
                  const height = node.data?.nodeStyles?.height || 80;
                  let top: number;
                  let left: number;

                  const id = `asset-allocation-graph-text=${key}`;

                  if (orientation === "vertical") {
                    top = node.y;
                    left = node.x;
                  } else {
                    top = node.x;
                    left = node.y;
                  }
                  const label = getStaticContentText(
                    node.data?.nodeLabelKey,
                    commonContent.assetAllocationBlocks,
                    language
                  );
                  const map: { [key: number]: number } = {
                    1: 1,
                    2: 1.5,
                    3: 2,
                    4: 2.5,
                  };
                  const element = document.getElementById(id);
                  const totalLines =
                    element?.getElementsByTagName("tspan").length || 1;
                  const lineHeight = map[totalLines];

                  return (
                    <Group top={top} left={left} key={key}>
                      <rect
                        height={height}
                        width={width}
                        y={-height / 2}
                        x={-width / 2}
                        fill={node.data?.nodeStyles?.bgColor}
                        stroke={"#000"}
                        strokeWidth={0}
                        strokeOpacity={0}
                        rx={5}
                      />
                      <Text
                        width={width - 10}
                        id={id}
                        height={height - 10}
                        fontSize={14}
                        fontFamily="Arial"
                        color={"white"}
                        x="0"
                        y={node?.data?.percentage ? "-0.5em" : "0"}
                        verticalAnchor="middle"
                        textAnchor="middle"
                        lineHeight="1.2em"
                        fill={node.data?.nodeStyles?.fontColor || "#000"}
                      >
                        {label}
                      </Text>
                      {node.data?.percentage && (
                        <Text
                          width={width - 10}
                          height={height - 10}
                          fontSize={14}
                          fontFamily="Arial"
                          color="white"
                          x="0"
                          y={`${lineHeight}em`} // Offset vertically to create line break effect
                          verticalAnchor="middle"
                          textAnchor="middle"
                          lineHeight="1.2em"
                          fill={node.data?.nodeStyles?.fontColor || "#000"}
                        >
                          {`${node.data.percentage}%`}
                        </Text>
                      )}
                    </Group>
                  );
                })}
              </Group>
            )}
          </Tree>
        </Group>
      </svg>
    </div>
  );
}
