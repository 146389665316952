import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import Image from "../../../components/Image";
import {
  MoreOptionImageWrapper,
  MoreOptionText,
  ToolTipContainer,
} from "./styles";

type Props = {
  toggleEdit?: () => void;
  onDelete?: () => void;
};

const MoreOptionsButton: React.FC<Props> = ({ toggleEdit, onDelete }) => {
  const { setTooltipRef, setTriggerRef, getTooltipProps, visible } =
    usePopperTooltip({
      trigger: "click",
      interactive: true,
      placement: "bottom-start",
    });

  return (
    <div ref={setTriggerRef}>
      <MoreOptionImageWrapper>
        <Image
          name="moreOptions"
          height="18"
          width="18"
          onClick={() => {
            null;
          }}
        />
      </MoreOptionImageWrapper>
      {visible && (
        <ToolTipContainer
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <MoreOptionText onClick={toggleEdit}>
            <Image name="pencil" color="#adb5bd" height="14" width="14" />
            Edit
          </MoreOptionText>
          <MoreOptionText onClick={onDelete}>
            <Image name="delete" color="#adb5bd" height="14" width="14" />
            Delete
          </MoreOptionText>
        </ToolTipContainer>
      )}
    </div>
  );
};

export default MoreOptionsButton;
