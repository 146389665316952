import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../../components/Button";
import Checkbox from "../../../../components/Checkbox";
import {
  DocumentRecord,
  DocumentStatusEnum,
  DocumentUserEnum,
} from "../../../../types/document-details";
import { spacingIncrement } from "../../../../utils/common";
import SendToEditorConfirmationModal from "../../SendToEditorConfirmationModal";
import DocumentStatus from "../DocumentStatus";
import ShouldDisplayWrapper from "./ShouldDisplayWrapper";
import { ButtonContainer, Container, SubContainer } from "./styles";
import { FaArrowUp } from "react-icons/fa";
import useBottomMargin from "../useBottomMargin";
import { GLOBAL_CONTEXT } from "../../../../types/store";
import Context from "../../../../store/context";

type Props = {
  document: DocumentRecord | undefined;
  onSave?: () => void;
  onApprove?: () => void;
  onSubmitForApproval?: () => void;
  onSendBackToEditor?: () => void;
  userType?: DocumentUserEnum;
  isDocumetLocked?: boolean;
  lockedUser?: string;
  isFailed: boolean;
  onFiled?: () => void;
};

const DocumentFooter: React.FC<Props> = ({
  document,
  onSave,
  onApprove,
  onSubmitForApproval,
  onSendBackToEditor,
  userType,
  isDocumetLocked,
  lockedUser,
  isFailed,
  onFiled,
}) => {
  const [submitForApprovalCheck, setSubmitForApprovalCheck] = useState(false);
  const [approvalCheck, setApprovalCheck] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { globalDispatch } = useContext<GLOBAL_CONTEXT>(Context);

  const resetCheckBoxes = () => {
    setSubmitForApprovalCheck(false);
    setApprovalCheck(false);
  };

  useEffect(() => {
    resetCheckBoxes();
    return () => {
      resetCheckBoxes();
    };
  }, [document?.status]);

  const toggleSubmitForApprovalCheck = () =>
    setSubmitForApprovalCheck((val) => !val);

  const toggleApprovalCheck = () => setApprovalCheck((val) => !val);

  const { shouldExpandFooterBar } = useBottomMargin();

  const shouldDisplaySubmitForApproval = useMemo(
    () =>
      document?.status === DocumentStatusEnum.draft ||
      document?.status === DocumentStatusEnum.awaiting_comments_review,
    [document?.status]
  );

  const checked = useMemo(() => {
    if (shouldDisplaySubmitForApproval) {
      return submitForApprovalCheck;
    }
    return approvalCheck;
  }, [submitForApprovalCheck, approvalCheck]);

  const checkBoxLabel = () => {
    if (shouldDisplaySubmitForApproval) {
      return "Check, if this is your final version and want to submit for approval";
    }
    if (document?.status === DocumentStatusEnum.approved) {
      return "Check, to confirm that you have filed the document";
    }
    return "Check, to confirm that you want to approve this document";
  };

  const toggleCheckFunction = () => {
    if (shouldDisplaySubmitForApproval) {
      return toggleSubmitForApprovalCheck();
    }
    return toggleApprovalCheck();
  };

  const onSubmitOrApproval = () => {
    if (shouldDisplaySubmitForApproval) {
      if (isFailed) {
        globalDispatch({
          type: "UPDATE_ERROR_MESSAGE_FOUND",
          payload: { errorMessageFound: false },
        });
        toast.error("Validation failed, please fill all the required fields");
        return;
      }
      onSubmitForApproval?.();
    } else {
      onApprove?.();
    }
  };

  const isApproverAndEditedByApprover =
    document?.status === DocumentStatusEnum.edited_by_approver &&
    userType === DocumentUserEnum.approver;

  const hideFooter = !(
    document?.status === DocumentStatusEnum.draft ||
    document?.status === DocumentStatusEnum.awaiting_approval ||
    document?.status === DocumentStatusEnum.awaiting_comments_review ||
    document?.status === DocumentStatusEnum.approved ||
    isApproverAndEditedByApprover
  );

  const sendBackToEditorAfterApproved = () => {
    toggleConfirmationModal();
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  return (
    <ShouldDisplayWrapper hideFooter={hideFooter}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container
          shouldExpendFooter={shouldExpandFooterBar}
          isDocumetLocked={isDocumetLocked}
        >
          <FaArrowUp
            style={{
              position: "absolute",
              top: "-20px",
              right: "26px",
              visibility: `${shouldExpandFooterBar ? "hidden" : "visible"}`,
            }}
          />
          <SubContainer>
            <Checkbox
              labelStyle={{ fontSize: spacingIncrement(16) }}
              label={checkBoxLabel()}
              onChange={toggleCheckFunction}
              checked={checked}
              id="confirmation-checkbox"
            />
            <ButtonContainer>
              {(document?.status === DocumentStatusEnum.draft ||
                document?.status ===
                  DocumentStatusEnum.awaiting_comments_review) && (
                <>
                  <Button
                    id="submit-for-approval-button"
                    buttonType="solid"
                    onClick={onSubmitOrApproval}
                    disabled={!submitForApprovalCheck}
                  >
                    Submit for Approval
                  </Button>
                  <Button id="save-as-draft-button" onClick={onSave}>
                    Save
                  </Button>
                </>
              )}
              {(document?.status === DocumentStatusEnum.awaiting_approval ||
                isApproverAndEditedByApprover) && (
                <>
                  <Button
                    id="approve-button"
                    onClick={onApprove}
                    disabled={!approvalCheck}
                  >
                    Approve
                  </Button>
                  <Button
                    id="send-back-to-editor-button"
                    onClick={onSendBackToEditor}
                  >
                    Send back to Editor
                  </Button>
                </>
              )}
              {document?.status === DocumentStatusEnum.approved && (
                <>
                  <Button
                    id="filed-button"
                    onClick={onFiled}
                    disabled={!approvalCheck}
                  >
                    Filed
                  </Button>
                  <Button
                    id="send-back-to-editor-after-approved"
                    onClick={sendBackToEditorAfterApproved}
                  >
                    Send back to Editor
                  </Button>
                </>
              )}
            </ButtonContainer>
          </SubContainer>
          <SubContainer>
            <DocumentStatus lockedUser={lockedUser} />
          </SubContainer>
        </Container>
      </div>
      {confirmationModal ? (
        <SendToEditorConfirmationModal
          onClose={toggleConfirmationModal}
          isOpen={confirmationModal}
          onProceed={() => {
            onSendBackToEditor?.();
            toggleConfirmationModal();
          }}
        />
      ) : null}
    </ShouldDisplayWrapper>
  );
};

export default DocumentFooter;
