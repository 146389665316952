import React from "react";
import { ImageProps } from "../types";

const Cross: React.FC<ImageProps> = ({ color = "#333", ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 8 8"
      {...otherProps}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
        transform="translate(1 1)"
      >
        <path d="M6,0 L0,6" />
        <path d="M6,0 L0,6" transform="matrix(-1 0 0 1 6 0)" />
      </g>
    </svg>
  );
};

export default Cross;
