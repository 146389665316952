import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const ContentContainer = styled.div`
  padding: ${spacingIncrement(32)} ${spacingIncrement(25)};
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacingIncrement(20)};
  gap: ${spacingIncrement(16)};
`;
export const CountContainer = styled.div<{
  justifyContent: "space-between" | "flex-end";
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
`;
export const ErrorMsg = styled.p`
  color: red;
  font-size: 12px;
`;
