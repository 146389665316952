import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";

import App from "./App";
import GlobalStyle from "./utils/globalStyle";
import theme from "./utils/theme";
import injectScripts from "./utils/injectScripts";
import GlobalStateProvider from "./store/GlobalStateProvider";
import { BrowserRouter } from "react-router-dom";
import KeycloakService from "./keycloak/KeycloakService";

injectScripts();

const renderApp = () =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <GlobalStateProvider>
          <ThemeProvider theme={theme}>
            <Suspense fallback={""}>
              <App />
            </Suspense>
          </ThemeProvider>
        </GlobalStateProvider>
      </BrowserRouter>
      <GlobalStyle />
    </React.StrictMode>,
    document.getElementById("root")
  );
KeycloakService.initKeyCloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
