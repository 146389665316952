import React, { useState, useEffect } from "react";

import { calculatePageNumber, updatePagination } from "./service";
import { TPagination } from "./types";
//maxPageSelectSize : is a configurable field which will decide how many maximum pages to show in pagination bar excluding buttons
//pageSize : is used to calculate the number of pages to display on pagination
//totalRecords : is used to calculate the pages in pagination

export default function Pagination({
  pageSize,
  totalRecords,
  handleChange,
  maxPageSelectSize = 3,
}: TPagination) {
  //This states stores the button and page details for pagination bar
  const [paginationBar, setPaginationBar] = useState<any>(
    updatePagination(1, "", totalRecords, pageSize, maxPageSelectSize)
  );
  useEffect(() => {
    setPaginationBar(
      updatePagination(1, "", totalRecords, pageSize, maxPageSelectSize)
    );
  }, [pageSize, totalRecords, maxPageSelectSize]);
  function handlePagination(
    buttonValue: number | "First" | "Last" | "Previous" | "Next"
  ) {
    let activePage = 0;
    switch (buttonValue) {
      case "First":
        setPaginationBar(
          updatePagination(
            1,
            "Forward",
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );
        activePage = 1;
        break;
      case "Last":
        activePage = calculatePageNumber(totalRecords, pageSize);
        setPaginationBar(
          updatePagination(
            activePage,
            "Reverse",
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );
        break;
      case "Previous":
        activePage = getActivePage() - 1;
        setPaginationBar(
          updatePagination(
            activePage,
            "Reverse",
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );

        break;
      case "Next":
        activePage = getActivePage() + 1;
        setPaginationBar(
          updatePagination(
            activePage,
            "",
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );
        break;
      default:
        activePage = buttonValue;
        setPaginationBar(
          updatePagination(
            buttonValue,
            "",
            totalRecords,
            pageSize,
            maxPageSelectSize
          )
        );
    }
    const activePageDetails = {
      target: { name: "currentPage", value: activePage },
    };
    handleChange(activePageDetails);
  }
  const getActivePage: () => number = () => {
    const length = paginationBar.length;
    for (let counter = 0; counter < length; counter++) {
      if (paginationBar[counter].active) {
        return paginationBar[counter].value;
      }
    }
    return 0;
  };
  //This function is used to find which is the active page
  //It iterates through the state and looks for the active field to determine active page
  if (calculatePageNumber(totalRecords, pageSize) > 1) {
    return (
      <div className="col-auto">
        <nav aria-label="Page navigation">
          <ul className="pagination">
            {paginationBar.map((value: any) => {
              return (
                <li
                  key={value.value}
                  className={"page__item " + (value.active ? value.active : "")}
                >
                  <button
                    name="currentPage"
                    className="page__link"
                    type="button"
                    // aria-label={value.ariaLabel}
                    onClick={() => handlePagination(value.value)}
                    value={value.displayValue}
                  >
                    <span aria-hidden="true">{value.displayValue}</span>
                    <span className="sr-only">{value.value}</span>
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  } else {
    return <></>;
  }
}
