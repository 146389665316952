import * as types from "./types";
import { TUserDetails } from "../types/user-details";
import { GLOBAL_DISPATCH } from "../types/store";
import { FundList } from "../types/fund";
import {
  Approval,
  DocumentActionPermissions,
  DocumentRecord,
  DocumentState,
  Language,
} from "../types/document-details";
import { FilterType } from "../features/listing";
import { DocumentErrorState, Question } from "../types/document-question";

export const updateUser = (userDetails: TUserDetails): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_USER,
    payload: { userDetails },
  };
};

export const updateIsUserLoggedIn = (value: boolean): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_IS_LOGGED_IN_USER,
    payload: { isUserLoggedIn: value },
  };
};

export const updateSelectedFund = (selectedFund: FundList): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_SELECTED_FUND,
    payload: { selectedFund },
  };
};

export const updateFundList = (fundList: FundList[]): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_FUND_LIST,
    payload: { fundList },
  };
};

export const updateIsLoading = (isLoading: boolean): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_IS_LOADING,
    payload: { isLoading },
  };
};

export const updateDocumentList = (
  documentList: DocumentRecord[]
): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_DOCUMENT_LIST,
    payload: { documentList },
  };
};

export const updateArchivedDocumentList = (
  documentList: DocumentRecord[]
): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_ARCHIVED_DOCUMENTS,
    payload: { documentList },
  };
};

export const updateDocumentState = (
  documentState?: DocumentState | Partial<DocumentState>
): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_DOCUMENT_STATE,
    payload: { documentState },
  };
};

export const updateStoredFilters = (filters: FilterType): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_STORED_FILTER,
    payload: { filters },
  };
};

export const updateStoredFiltersForArchived = (filters: FilterType): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_STORED_FILTER_ARCHIVED,
    payload: { archivedFilters: filters },
  };
};

export const updateUserPermissions = (
  userPermission?: DocumentActionPermissions
): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_USER_PERMISSION,
    payload: { userPermission },
  };
};

export const updateCommentedQuestionIds = (
  commentedQuestionIds?: string[]
): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_COMMENTED_QUESTION_IDS,
    payload: { commentedQuestionIds },
  };
};

export const updateTotalDocumentsCount = (
  totalDocumentsCount: number
): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_DOCUMENTS_COUNT,
    payload: { totalDocumentsCount },
  };
};
export const updateLanguages = (languages: Language[]): GLOBAL_DISPATCH => {
  return {
    type: types.UPDATE_LANGUAGES,
    payload: { languages },
  };
};

export const updateApproverGroup = (approverGroup: Approval[]) => {
  return {
    type: types.UPDATE_APPROVER_GROUP,
    payload: { approverGroup },
  };
};

export const updateDocumentErroState = (
  documentErrorState: DocumentErrorState
) => {
  return {
    type: types.UPDATE_DOCUMENT_ERROR,
    payload: { documentErrorState },
  };
};

export const updateDocumentQuestion = (documentQuestions: Question[]) => {
  return {
    type: types.UPDATE_DOCUMENT_QUESTION,
    payload: { documentQuestions },
  };
};
