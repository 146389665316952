import {
  ApproverTableRow,
  ClientApprover,
} from "../../../types/admin/approverDetails";

export function getRowsForClientApprovers(data: ClientApprover[]) {
  const rows: ApproverTableRow[] = [];
  const clientDetails = data[0];

  const clientName = clientDetails.clientName;
  clientDetails.approvalGroup.forEach((field) => {
    let usersNames: string[] = [];
    if (field.approvalUsers && field.approvalUsers.length) {
      usersNames = field.approvalUsers.map((val) => {
        return val.label;
      });
    }
    rows.push({
      id: field.groupId,
      clientName: clientName,
      users: field.approvalUsers,
      groupId: field.groupId,
      dataField: [
        { cellVal: field.approvalGroupTitle },
        { cellVal: field.approvalLevel },
        { cellVal: usersNames.toString() },
      ],
    });
  });

  return rows;
}

export function getBulkSelectCount(bulkSelect: any) {
  let count = 0;
  if (bulkSelect) {
    for (const property in bulkSelect) {
      if (property !== "selectAll" && bulkSelect[property]) {
        count++;
      }
    }
  }
  return count;
}
