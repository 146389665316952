import React from "react";
import Image from "../../../../components/Image";
import { TUserDetails } from "../../../../types/user-details";
import { Container, Text } from "./styles";

type Props = {
  lockedUser?: string;
};

const DocumentStatus: React.FC<Props> = ({ lockedUser }) => {
  if (!lockedUser) {
    return null;
  }
  return (
    <Container>
      <Image name="lock" />
      <Text>Document is being edited by {lockedUser}</Text>
    </Container>
  );
};

export default DocumentStatus;
