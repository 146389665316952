import React from "react";
import {
  DocumentRecord,
  DocumentState,
  DocumentViewModeEnum,
} from "../../types/document-details";
import UploadImageQuestion from "../../features/templates/CommonComponents/UploadImageQuestion";
import CustomChart from "../CustomChart";
import { getChartData } from "../CustomChart/service";
import { NodeConfig } from "../CustomChart/config";
import AssetAllocationPercentageInput from "../AssetAllocationPercentageInput";
import {
  DocumentCommonContent,
  DocumentStaticContent,
} from "../../types/document-question";

type Props = {
  document: DocumentRecord;
  updateDoc?: (doc: DocumentRecord) => void;
  isEditMode?: boolean;
  mode?: DocumentViewModeEnum;
  documentState?: DocumentState;
  questionId: string;
  article: string;
  template: string;
  updateAns: (key: string, value: string) => void;
  commonContent: DocumentCommonContent;
};

const AssetAllocationGraph: React.FC<Props> = ({
  document,
  updateDoc,
  isEditMode,
  mode,
  documentState,
  questionId,
  article,
  template,
  updateAns,
  commonContent,
}) => {
  if (!document?.version || document.version === "1.0")
    return (
      <UploadImageQuestion
        questionId={questionId}
        document={document}
        updateDoc={updateDoc}
        disabled={!isEditMode}
        mode={mode}
      />
    );
  const data = getChartData(documentState, article, template);
  const allQuestions: { id: string; label: string; labelKey: string }[] = [];
  const pushQuestions = (question: NodeConfig, firstNode?: boolean) => {
    if (!firstNode)
      allQuestions.push({
        id: question.nodeId,
        label: question.nodeLabel,
        labelKey: question.nodeLabelKey,
      });
    if (!question.children?.length) {
      return;
    }
    question.children.forEach((child) => {
      pushQuestions(child);
    });
  };
  pushQuestions(data, true);
  return (
    <>
      <CustomChart
        // As per Original document
        // Article 8 has static text below the graph and
        // Article 9 has static text right to the graph
        width={article === "Article 8" ? 800 : 700}
        commonContent={commonContent}
        height={400}
        data={data}
        language={document?.language}
      />
      {isEditMode && documentState && (
        <AssetAllocationPercentageInput
          documentState={documentState}
          updateAns={updateAns}
          article={article}
          allQuestions={allQuestions}
          commonContent={commonContent}
          language={document?.language}
        />
      )}
    </>
  );
};

export default AssetAllocationGraph;
