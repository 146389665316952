import React from "react";
import { LeftContainer, MainContainer, RightContainer } from "./styles";

type Props = {
  left?: JSX.Element;
  right?: JSX.Element;
  paddingTop?: number;
};

const Section: React.FC<Props> = ({ left, right, paddingTop }) => {
  return (
    <MainContainer paddingTop={paddingTop}>
      <LeftContainer>{left}</LeftContainer>
      <RightContainer>{right}</RightContainer>
    </MainContainer>
  );
};

export default Section;
