import moment, { Moment } from "moment";
import {
  DocumentRecord,
  DocumentState,
  DocumentStatusEnum,
} from "../../../types/document-details";
import {
  DisplayQuestion,
  Language,
  Question,
  QuestionError,
  StaticLink,
  StaticTextInfo,
} from "../../../types/document-question";
import { validateField } from "../../../utils/common";

export const getQuestionLabel = (
  key: string,
  q: DisplayQuestion,
  language: Language = Language.English
) => {
  // If condition can be removed once Implementation done
  if (language) {
    const label = q ? q[key]?.label[language] : "";
    return label as string;
  }
};

export const getCountryListArray = (
  countryList: StaticTextInfo[],
  language: string
) => {
  return countryList.map((country) => country.text[language.toLowerCase()]);
};

export const getFundName = (document: DocumentRecord | undefined) =>
  document?.fundName || "";

export const getDocHeadingText = (
  document: DocumentRecord | undefined,
  language?: string
) =>
  document
    ? `${document.fundName}/${document.subFundName}/${document.template}/${
        document.article
      }/${document.language || language}`
    : "";

export const getDocumentApprovedDate = (
  document: DocumentRecord | undefined
): Moment | undefined => {
  const status = document?.statusUpdateHistory?.find(
    (statusDetails) =>
      statusDetails.statusChangedTo === DocumentStatusEnum.filed
  );
  if (status) {
    return moment(status.updatedAt);
  }
  return undefined;
};

export const getDocumentError = (
  questions: Question[],
  document: DocumentState
): QuestionError[] => {
  const errors: QuestionError[] = [];
  questions.forEach((question) => {
    try {
      if (!question.disabled && question.validations?.length) {
        const errorMsg = question.validations?.length
          ? validateField(document, question.id, question.validations)
          : null;
        errors.push({
          id: question.id,
          error: !!errorMsg,
          message: errorMsg,
        });
      }
    } catch (error) {
      console.log("Validation is not properly formatted", error);
    }
  });
  return errors;
};

/*
  Dependant question values will be reset to null, if parent question value is false or null
 */
export const handleDependant = (
  questions: Question[],
  documentState: DocumentState
) => {
  const newDocState: DocumentState = {};
  const dependantsQuestions = questions?.filter(
    (question) => question?.dependants?.length
  );
  dependantsQuestions?.forEach(({ id, dependants }) => {
    if (documentState && (!documentState[id] || documentState[id] === "No")) {
      if (dependants?.length) {
        dependants.forEach((dependantQuestionId) => {
          if (documentState[dependantQuestionId]) {
            newDocState[dependantQuestionId] = null;
          }
        });
        return newDocState;
      }
    }
  });
  return newDocState;
};

export const getStaticContentText = (
  id: string,
  groupArray: (StaticTextInfo | StaticLink)[],
  language: string
) => {
  const item = groupArray.find((groupItem) => groupItem.id === id);
  return item?.text?.[language.toLowerCase()];
};
export const removedDocumentIdFromState = (documentState: DocumentState) => {
  if (Object.keys(documentState).length === 0) {
    return {};
  }
  const { id, ...documentStateWithoutId } = documentState;
  return documentStateWithoutId;
};
