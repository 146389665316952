import { FC, useState } from "react";
import Select, { Option } from "../Select";
import { SingleValue } from "react-select";
import { ApprovalLevels } from "./ApprovalLevels";

type Props = {
  fromStatusOption: { value: string; label: string };
  toStatusOptions: { value: string; label: string }[];
  selectedDocuments: any[];
  onCancel: () => void;
  onUpdate: (from: Option, to: Option, comment?: string) => void;
  updateDocList: () => void;
}
export const BulkStatusUpdate: FC<Props> = ({
  fromStatusOption,
  toStatusOptions,
  selectedDocuments,
  onCancel,
  onUpdate,
  updateDocList
}) => {
  const [toStatus, setToStatus] = useState<Option | null>();
  const [comment, seComment] = useState<string>();
  return (
    <div
      style={{ maxHeight: "85vh", overflowY: "auto" }}
      className="modal__body"
    >
      <div>
        <p className="text-18">Select the status to be changed to:</p>
      </div>

      <div className="row">
        <div className="col-md-6 pl-0">
          <div className="form-group choices__inner__border">
            <label>Status from</label>
            <Select
              value={fromStatusOption}
              options={[fromStatusOption]}
              isDisabled
            />
          </div>
        </div>
        <div className="col-md-6 pr-0">
          <div className="form-group choices__inner__border">
            <label>Status to</label>
            <Select
              onChange={(e) => {
                setToStatus(e as SingleValue<Option>);
              }}
              options={toStatusOptions}
            />
          </div>
        </div>

        {toStatus?.value !== "approved" ? (
          <div className="col-md-12 pl-0 pr-0 mt-4">
            <div className="form-group">
              <label>
                General Comment{" "}
                <span className="optional-text">(Optional)</span>
              </label>
              <textarea
                className="form-control form-control--bordered"
                onChange={(e) => {
                  seComment(e.target.value);
                }}
                value={comment}
                cols={30}
                rows={5}
                placeholder="Enter Text"
              ></textarea>
            </div>
          </div>
        ) : null}
      </div>
      {toStatus?.value === "approved" ? (
        <ApprovalLevels updateDocList={updateDocList} onClose={onCancel} documents={selectedDocuments} />
      ) : (
        <div className="mt-4">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (toStatus) {
                onUpdate(fromStatusOption, toStatus, comment);
              }
            }}
          >
            Update
          </button>
          <button onClick={onCancel} className="btn btn-outline-primary">
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};
