import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Layout: React.FC = () => {
  return (
    <Container>
      <Header />
      <MainContainer>
        <main className="kiid-generator-main">
          <Outlet />
        </main>
      </MainContainer>
      <Footer />
    </Container>
  );
};

export default Layout;
