import { postData } from "./apis";

export const updateLockStatus = async (
    id: string,
    lockStatus: "lock" | "unlock",
    clientId?: string | number
  ) => {
    try {
      await postData({
        endPoint: "updateDocumentLockStatus",
        data: {
          documentId: id,
          updateType: lockStatus,
          clientId: clientId
        },
      });
    } catch (error) {
      console.log(error);
    }
  };