import React, { useCallback, useContext, useState } from "react";
import { useEffect } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import Button from "../../../../components/Button";
import Image from "../../../../components/Image";
import { uploadFile } from "../../../../services/apis";
import Context from "../../../../store/context";
import {
  DocumentRecord,
  DocumentState,
  DocumentViewModeEnum,
} from "../../../../types/document-details";
import { GLOBAL_CONTEXT } from "../../../../types/store";
import { notifyError } from "../../../../utils/common";
import { Text } from "../common.styles";
import {
  UploadContainer,
  UploadImageWrapper,
  StyledImage,
  UploadedImageContainer,
  UploadedImageSubContainer,
} from "./styles";

type Props = {
  updateDoc?: (doc: DocumentRecord) => void;
  document?: DocumentRecord;
  questionId: string;
  disabled?: boolean;
  mode?: DocumentViewModeEnum;
};

const UploadImageQuestion: React.FC<Props> = ({
  updateDoc,
  questionId,
  document,
  disabled,
  mode,
}) => {
  const {
    globalState: { selectedFund },
  } = useContext<GLOBAL_CONTEXT>(Context);
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      notifyError(
        fileRejections[0].errors[0].code === "file-too-large"
          ? "File size is too big"
          : "File is not supported"
      );
      return;
    }
    try {
      setLoading(true);
      const resp = await uploadFile({
        endPoint: "upload",
        file: acceptedFiles[0],
        data: {
          type: "answerFile",
          clientId: selectedFund?.client_id,
          questionId,
          documentId: document?._id,
        },
      });
      if (resp.data.message === "Success") {
        updateDoc?.(resp.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const onDelete = () => {
    updateDoc?.({
      ...document,
      documentState: {
        ...document?.documentState,
        [questionId]: "",
      },
    } as DocumentRecord);
  };

  useEffect(() => {
    const url = document?.documentState?.[
      questionId as keyof DocumentState
    ] as string;
    if (url) {
      setImgUrl(
        `${process.env.REACT_APP_CLOUDFRONT_URL}/${url}?${new Date().getTime()}`
      );
    } else {
      setImgUrl("");
    }
  }, [document]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg,image/png,image/svg+xml",
    maxSize: 1024 * 1024,
  });
  if (loading) {
    return <UploadContainer>Uploading... </UploadContainer>;
  }
  if (mode === DocumentViewModeEnum.view && !!imgUrl) {
    return (
      <UploadedImageContainer>
        <StyledImage src={imgUrl} alt={questionId} />
      </UploadedImageContainer>
    );
  } else if (mode === DocumentViewModeEnum.edit && imgUrl) {
    return (
      <UploadedImageContainer>
        <UploadedImageSubContainer {...getRootProps()} disabled={disabled}>
          <input {...getInputProps()} disabled={disabled} />
          <StyledImage src={imgUrl} alt={questionId} />
        </UploadedImageSubContainer>
        {!disabled && (
          <Image
            style={{ cursor: "pointer" }}
            name="delete"
            color="#303246"
            onClick={onDelete}
          />
        )}
      </UploadedImageContainer>
    );
  } else if (mode === DocumentViewModeEnum.edit) {
    return (
      <UploadContainer>
        <UploadImageWrapper>
          <Button buttonType="solid" disabled={disabled} {...getRootProps()}>
            <input {...getInputProps()} disabled={disabled} />
            Upload Image
          </Button>
        </UploadImageWrapper>
        <Text fontSize={14}>(PNG, JPEG, SVG are supported, max size 1MB)</Text>
      </UploadContainer>
    );
  } else {
    return <div />;
  }
};

export default UploadImageQuestion;
