import React from "react";
import { ImageProps } from "../types";

const Plus: React.FC<ImageProps> = ({ color = "white", ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#clip0_48_1238)">
        <path
          d="M15.5 8H1.5M8.5 1V15V1Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_48_1238">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Plus;
