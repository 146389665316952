import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Listing from "./features/listing";
import AppHeader from "./components/AppHeader";
import Layout from "./components/Layout";
import DocumentEditor from "./features/templates/DocumentEditor";
import Context from "./store/context";
import { GLOBAL_CONTEXT } from "./types/store";
import LoadingScreen from "./components/LoadingScreen";
import { CLIENT_URL_PREFIX, ADMIN_URL_PREFIX } from "./lib/constants";
import AdminLayout from "./components/AdminLayout";
import { DocumentViewModeEnum } from "./types/document-details";
import "react-toastify/dist/ReactToastify.css";
import "react-popper-tooltip/dist/styles.css";
import Toast from "./components/Toast";
import Help from "./features/help";
import ProtectedRoutes from "./ProtectedRoutes";
import DocumentViewer from "./features/publicDocument/DocumentViewer";
import { BulkDownload } from "./features/bulkDownload";
import ClientManager from "./features/admin/ClientManager/ClientManager";
import { TemplateListingPage } from "./features/admin/languageTranslate/listingPage";
import { TemplateTranslatePage } from "./features/admin/languageTranslate/translatePage";
import { AuthorizeAdmin } from "./components/AuthorizeAdmin";
import plausibleInstance from "./services/plausible";
import ArchivedListing from "./features/archiveListing";

const App: React.FC = () => {
  const [adminView, setAdminView] = useState<boolean>(false);
  const location = useLocation();
  const {
    globalState: { isLoading },
  } = useContext<GLOBAL_CONTEXT>(Context);
  useEffect(() => {
    if (adminView !== location.pathname.includes(ADMIN_URL_PREFIX)) {
      setAdminView(location.pathname.includes(ADMIN_URL_PREFIX));
    }
  }, [location]);

  useEffect(() => {
    plausibleInstance.enableAutoPageviews();
  }, []);

  return (
    <div>
      <Toast />
      {/* {adminView ? (
        <link
          rel="stylesheet"
          type="text/css"
          href={`/adminResources/css/main.css?t=${new Date()
            .getTime()
            .toString()}`}
        />
      ) : null} */}
      <LoadingScreen loading={isLoading} />
      <Routes>
        <Route index element={<Navigate to="sfdr" />} />
        <Route path="sfdr" element={<ProtectedRoutes />}>
          <Route
            index
            element={<Navigate to={CLIENT_URL_PREFIX + "listing"} />}
          />
          <Route path="" element={<Layout />}>
            <>
              <Route
                index
                element={<Navigate to={CLIENT_URL_PREFIX + "listing"} />}
              />
              <Route
                path={CLIENT_URL_PREFIX}
                element={
                  <>
                    <AppHeader />
                    <Outlet />
                  </>
                }
              >
                <Route path="listing" element={<Listing />} />
                <Route path="archived" element={<ArchivedListing />} />
                <Route path="help" element={<Help />} />
                {/* <Route
                    path="languageTranslation"
                    element={
                      <AuthorizeAdmin>
                        <TemplateListingPage />
                      </AuthorizeAdmin>
                    }
                  /> */}
              </Route>
              <Route path={CLIENT_URL_PREFIX}>
                <Route
                  path="document/edit/:clientId/:id"
                  element={<DocumentEditor mode={DocumentViewModeEnum.edit} />}
                />
              </Route>
              <Route path="bulkDownload">
                <Route path=":encodedData" element={<BulkDownload />} />
              </Route>
            </>
          </Route>
          <Route path={CLIENT_URL_PREFIX}>
            <Route
              path="document/edit/:clientId/:id"
              element={<DocumentEditor mode={DocumentViewModeEnum.edit} />}
            />
            {/* <Route
                path="languageTranslation/:article/:template"
                element={
                  <AuthorizeAdmin>
                    <TemplateTranslatePage />
                  </AuthorizeAdmin>
                }
              /> */}
          </Route>
          <Route path="bulkDownload">
            <Route path=":encodedData" element={<BulkDownload />} />
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            <Route
              index
              element={
                <Navigate to={`${ADMIN_URL_PREFIX + "clientsManager"}`} />
              }
            />
            <Route path="clientsManager/*" element={<ClientManager />} />
          </Route>
        </Route>
        <Route path={CLIENT_URL_PREFIX}>
          {/* DO NOT CHANGE THIS ROUTE WITHOUT BACKEND CHANGES */}
          <Route
            path="document/view/:clientId/:id"
            element={<DocumentEditor mode={DocumentViewModeEnum.view} />}
          />
          {/* DO NOT CHANGE THIS ROUTE WITHOUT BACKEND CHANGES */}
          <Route
            path="document/view/:clientId/:id/pdf"
            element={<DocumentEditor mode={DocumentViewModeEnum.view} isPDF />}
          />
          {/* DO NOT CHANGE THIS ROUTE WITHOUT BACKEND CHANGES */}
          <Route
            path="document/view/:clientId/:id/pdf/showWaterMark"
            element={
              <DocumentEditor
                mode={DocumentViewModeEnum.view}
                isPDF
                showWaterMark
              />
            }
          />
        </Route>
      </Routes>
      {/* Public routes */}
      <Routes>
        <Route path="sfdrDocument">
          <Route
            path=":slug/:article/:template/:lang"
            element={<DocumentViewer />}
          />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
