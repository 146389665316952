import styled, { css } from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const ToolTipContainer = styled.div<{ width?: number }>`
  border-radius: ${spacingIncrement(5)};
  padding: ${spacingIncrement(10)};
  ${({ width }) =>
    width &&
    css`
      width: ${spacingIncrement(width)};
    `}
`;
