import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const UploadFileStyled = styled.div`
  width: ${spacingIncrement(720)};
`;

export const FileContainerStyled = styled.div`
  width: ${spacingIncrement(220)};
`;
