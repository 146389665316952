import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";
import Image from "../Image";

export const HeaderContainer = styled.div`
  height: ${spacingIncrement(90)};
  padding: 0 ${spacingIncrement(30)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9dae3;
`;

export const Title = styled.span`
  font-size: ${spacingIncrement(22)};
  font-weight: 600;
  color: #303246;
`;

export const StyledImage = styled(Image)`
  cursor: pointer;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacingIncrement(10)};
`;
