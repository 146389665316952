import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Clients from "../clientList";
import ApproverGroupConfig from "../ApproverGroupConfig";
import { GLOBAL_CONTEXT } from "../../../types/store";
import Context from "../../../store/context";

const ClientManager: React.FC = () => {
  const navigate = useNavigate();
  const {
    globalState: { userDetails },
  } = useContext<GLOBAL_CONTEXT>(Context);
  useEffect(() => {
    if (userDetails?.userType !== "admin") {
      navigate("/sfdr", { replace: true });
    }
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = `/adminResources/css/main.css?t=${new Date()
      .getTime()
      .toString()}`;
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  return (
    <Routes>
      <Route index element={<Clients />} />
      <Route path="approvers" element={<ApproverGroupConfig />} />
    </Routes>
  );
};

export default ClientManager;
