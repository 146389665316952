import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { Container, StyledImage, Text, TextWrapper } from "./styles";

type Props = {
  paddingLeft?: number;
  disabled?: boolean;
};

const DocDoNotIncludeIcon: React.FC<Props> = ({
  children,
  paddingLeft = 10,
  disabled,
}) => {
  const { triggerRef, setTooltipRef, setTriggerRef, getTooltipProps, visible } =
    usePopperTooltip({
      trigger: "hover",
    });

  if (disabled) {
    return null;
  }
  return (
    <>
      <Container
        ref={setTriggerRef}
        onMouseEnter={() => triggerRef?.click()}
        paddingLeft={paddingLeft}
      >
        <StyledImage name="docNotIncluded" height={20} width={20} />
      </Container>
      {visible && (
        <TextWrapper
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <Text>
            {children || "This question is not included in download version"}
          </Text>
        </TextWrapper>
      )}
    </>
  );
};

export default DocDoNotIncludeIcon;
