import React from "react";
import { ImageProps } from "../types";

const ArrowLeft: React.FC<ImageProps> = ({
  color = "#0ABAEE",
  ...otherProps
}) => {
  return (
    <svg
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M5 0V10L0 5L5 0Z" fill={color} />
    </svg>
  );
};

export default ArrowLeft;
