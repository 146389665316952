export type TUserDetails = {
  userId: number | string;
  userName: string;
  clientId: number | string;
  clientName: string;
  userType: keyof typeof UserType;
};
export enum UserType {
  client = "client",
  admin = "admin",
}
// export type TOtherAccounts = {
//   id: number | string;
//   name: string;
//   logo: string;
// };

// export type TRoles = {
//   id: number | string;
//   name: string;
//   permissions: TPermissions[];
// };

// export type TPermissions = {
//   id?: number | string;
//   name: string;
//   permissionType?: string;
// };

// export type TGroups = {
//   id: number | string;
//   name: string;
// };
