import React from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { Container, MessageText } from "./styles";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  onProceed?: () => void;
};

const SendToEditorConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onProceed,
}) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Send to editor confirmation"
        width={650}
      >
        <MessageText>
          {`Please note: by checking on "Send back to editor" the approvals will be re-set and the document will be reopened for editing and approval.`}
        </MessageText>
        <Container>
          <Button buttonType="solid" onClick={onProceed}>
            Proceed
          </Button>
          <Button buttonType="outline" onClick={onClose}>
            Cancel
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default SendToEditorConfirmationModal;
