import * as d3 from "d3";
import { animated, useSpring } from "react-spring";
import { Fragment } from "react";

const MARGIN_X = 70;
const MARGIN_Y = 70;

interface DataInterface {
    group: string;
    value: number;
    textColor?: string;
}

function PieChart({
  hideChart = false,
  height = 300,
  width = 300,
  data = [],
  groupConfig = {},
  textColor = "black",
  textSize = 12,
  groupColors = ["black", "red"],
  colors = []
}: {
    hideChart?: boolean;
    height?: number;
    width?: number;
    data: DataInterface[];
    groupConfig?: {
      [key: string]: {
        showArc?: boolean;
        showPercentage?: boolean;
        showIndividualPercentageOutside?: boolean;
        prefix?: string;
        suffix?: string;
        groupTextColor?: string;
        textColor?: string;
      };
    };
    textColor?: string;
    textSize?: number;
    groupColors?: string[];
    colors?: string[];
}) {
  const centerX = width / 2;
  const centerY = height / 2;
  const radius = Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2 - 5;
  const radius2 =
    Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2 + 35;
  const groupDataMap = new Map();
  data.forEach((d) => {
    const group = d.group;
    const value = d.value;
    if (!groupDataMap.has(group)) {
      groupDataMap.set(group, 0);
    }
    groupDataMap.set(group, groupDataMap.get(group) + value);
  });
  const groupData = Array.from(groupDataMap, ([group, value]) => ({
    group,
    value
  }));

  const pieGenerator = d3
    .pie<DataInterface>()
    .sort(null)
    .value((d) => d.value);
  const arcPathGenerator = d3.arc();

  return (
    <div>
      <svg width={width} height={height}>
        {!hideChart && <>
          <g transform={`translate(${centerX}, ${centerY})`}>
            {pieGenerator(data).map((p, i) => {
              const sliceInfo = {
                innerRadius: 0,
                outerRadius: radius,
                startAngle: p.startAngle,
                endAngle: p.endAngle
              };
              const distance = groupConfig[p.data.group]
                ?.showIndividualPercentageOutside
                ? 20
                : -25;
              const inflexionInfo = {
                innerRadius: radius + distance,
                outerRadius: radius + distance,
                startAngle: p.startAngle,
                endAngle: p.endAngle
              };
              const inflexionPoint = arcPathGenerator.centroid(inflexionInfo);
              const arc = (
                <Slice
                  slice={sliceInfo}
                  radius={radius}
                  innerRadius={0}
                  color={colors[i]}
                />
              );
              const groupData = groupConfig[p.data.group];
              return (
                <Fragment key={i}>
                  {arc}
                  {p.data.value && <Text
                    coordinates={inflexionPoint}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    textColor={
                      groupData?.textColor ?? p.data.textColor ?? textColor
                    }
                    fontSize={textSize}
                    content={`${groupData?.prefix ?? ""}${p.data.value}${
                      groupData?.suffix ?? ""
                    }`}
                  />}
                </Fragment>
              );
            })}
          </g>
          <g transform={`translate(${centerX}, ${centerY})`}>
            {pieGenerator(groupData).map((p, i) => {
              const sliceInfo = {
                innerRadius: radius2 + 2,
                outerRadius: radius2,
                startAngle: p.startAngle,
                endAngle: p.endAngle
              };
              const arc = (
                <Slice
                  slice={sliceInfo}
                  radius={radius2}
                  innerRadius={radius2 + 2}
                  color={groupColors[i]}
                />
              );
              const inflexionInfo = {
                innerRadius: radius2 + 20,
                outerRadius: radius2 + 20,
                startAngle: p.startAngle,
                endAngle: p.endAngle
              };
              const inflexionPoint = arcPathGenerator.centroid(inflexionInfo);
              const groupData = groupConfig[p.data.group];
              const val = p.data.value;
              return (
                groupData?.showArc && (
                  <Fragment key={i}>
                    {arc}
                    {groupData.showPercentage && val && (
                      <Text
                        coordinates={inflexionPoint}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        textColor={
                          groupData.groupTextColor ?? textColor
                        }
                        fontSize={textSize}
                        content={`${groupData.prefix ?? ""}${Number.isInteger(val) ? val : val.toFixed(2)}${
                          groupData.suffix ?? ""
                        }`}
                      />
                    )}
                  </Fragment>
                )
              );
            })}
          </g>
        </>
        }
      </svg>
    </div>
  );
}

export default PieChart;

const Slice = (
    { slice, radius, innerRadius, color }
    : {
        slice: {
            startAngle: number;
            endAngle: number;
        };
        radius: number;
        innerRadius: number;
        color: string;
    }
) => {
    const arcPathGenerator = d3.arc();

  const springProps = useSpring({
    to: {
      pos: [slice.startAngle, slice.endAngle]
    }
  });

  return (
    <animated.path
      d={springProps.pos.to((start: any, end: any) => {
        return arcPathGenerator({
          innerRadius: innerRadius,
          outerRadius: radius,
          startAngle: start,
          endAngle: end
        });
      }) as unknown as string}
      fill={color}
    />
  );
};

const Text = ({
  coordinates,
  textAnchor,
  alignmentBaseline,
  textColor,
  fontSize,
  content
}: {
    coordinates: [number, number];
    textAnchor: string;
    alignmentBaseline: "auto" | "baseline" | "before-edge" | "text-before-edge" | "middle" | "central" | "after-edge" |
    "text-after-edge" | "ideographic" | "alphabetic" | "hanging" | "mathematical" | "inherit" | undefined;
    textColor: string;
    fontSize: number;
    content: string;
}) => {
  const springProps = useSpring({
    to: {
      pos: [coordinates[0], coordinates[1]]
    }
  });
  return (
    <animated.text
      alignmentBaseline={alignmentBaseline}
      textAnchor={textAnchor}
      fill={textColor}
      fontSize={fontSize}
      x={springProps.pos.to((x: any) => x)}
      y={springProps.pos.to((_: any, y: any) => y)}
    >
      {content}
    </animated.text>
  );
};
