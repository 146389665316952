import React from "react";
import { ImageProps } from "../types";

const Cross: React.FC<ImageProps> = ({ color = "#ADB5BD", ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M14.0059 2L2.00586 14M2.00586 2L14.0059 14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Cross;
