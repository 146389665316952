import React from "react";
import Image from "../../../../components/Image";
import { LabelText } from "../../../../types/document-question";
import parse from "html-react-parser";
import {
  CheckboxWrapper,
  CheckedWrapper,
  Container,
  Label,
  StyledBox,
} from "./styles";
import {
  DocumentViewModeEnum,
  DocumentViewModeKeys,
} from "../../../../types/document-details";

type Props = {
  id: string;
  label?: string;
  checked?: boolean;
  onClick?: () => void;
  className?: string;
  bold?: boolean;
  marginLeft?: number;
  fontSize?: number;
  disabled?: boolean;
  mode?: DocumentViewModeKeys;
};

const Checkbox: React.FC<Props> = ({
  id,
  checked,
  label,
  onClick,
  className,
  bold = true,
  marginLeft,
  fontSize,
  disabled = false,
  mode,
}) => {
  return (
    <Container marginLeft={marginLeft}>
      <CheckboxWrapper
        id={`checkbox-${id}`}
        onClick={!disabled ? onClick : () => null}
      >
        <StyledBox disabled={disabled && mode === DocumentViewModeEnum.edit} />
        {checked && (
          <CheckedWrapper>
            <Image
              name="checkBoxCross"
              height={10}
              width={10}
              color="#303245"
            />
          </CheckedWrapper>
        )}
      </CheckboxWrapper>
      <Label
        className={className}
        isBold={bold}
        fontSize={fontSize}
        disabled={disabled}
        onClick={!disabled ? onClick : () => null}
      >
        {label ? parse(label.toString() as string) : ""}
      </Label>
    </Container>
  );
};

export default Checkbox;
