import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../utils/common";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d9dae3;
  margin-bottom: ${spacingIncrement(26)};
`;
export const SpacingWrapper = styled.div`
  padding: ${spacingIncrement(15)};
`;
export const HeaderBox = styled.div`
  display: flex;
  padding: ${spacingIncrement(16)};
  border-bottom: 1px solid #d9dae3;
`;
export const Title = styled.div<{ alignItems?: string }>`
  font-weight: 600;
  font-size: ${spacingIncrement(18)};
  display: flex;
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
`;
export const FlexAlignCentered = styled.div`
  display: flex;
  align-items: center;
`;
