import React from "react";
import { ImageProps } from "../types";

const MoneyCoin: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="145"
      height="145"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="72.5" cy="72.5" r="72.5" fill="#9D9D9C" />
      <path
        d="M40.7888 60.0005C49.8277 60.0005 57.2124 63.5506 57.6639 68.014C57.4109 70.5132 54.7632 72.2489 53.0443 73.1429C49.9895 74.6893 45.6216 75.7073 40.7883 75.7073C39.4238 75.7073 38.0976 75.6147 36.8287 75.4624C33.6851 72.4502 29.4949 70.5125 24.8561 70.2772C24.3986 69.5575 23.9941 68.7868 23.9133 68.0524C24.8291 61.4888 36.2296 59.9783 40.7883 60.0002L40.7888 60.0005ZM23.8944 72.0679C33.2249 72.0679 40.7888 79.632 40.7888 88.9623C40.7888 98.2927 33.2248 105.857 23.8944 105.857C14.564 105.857 7.00002 98.2927 7.00002 88.9623C7.00002 79.632 14.564 72.0679 23.8944 72.0679ZM57.6649 72.8411V75.3113C57.4797 77.0886 55.9095 78.9148 53.0454 80.3643C50.1632 81.823 46.1076 82.8024 41.5996 82.9097C41.0028 81.1644 40.1472 79.5339 39.0921 78.0637C39.6514 78.0911 40.2159 78.1012 40.7891 78.1012C45.9528 78.1012 50.6422 77.0612 54.1387 75.2918C55.5031 74.6012 56.7001 73.7708 57.6648 72.8406L57.6649 72.8411ZM57.6649 80.0814V82.5516C57.4797 84.329 55.9095 86.1551 53.0454 87.6046C50.3681 88.9594 46.6851 89.9169 42.5617 90.1311C42.5857 89.7431 42.5996 89.3559 42.5996 88.9619C42.5996 87.7102 42.4758 86.4871 42.2414 85.3041C46.8256 85.1243 50.9758 84.1142 54.1397 82.5137C55.5042 81.823 56.7011 81.0116 57.6658 80.0814H57.6649ZM57.6649 87.3218V89.792C57.4797 91.5693 55.9095 93.3955 53.0454 94.845C48.8051 96.9204 44.3946 97.3808 40.5814 97.4094C41.3495 95.8946 41.9265 94.2621 42.2595 92.545C46.8361 92.3631 50.9793 91.3531 54.1383 89.7546C55.5028 89.0639 56.6998 88.2525 57.6645 87.3223L57.6649 87.3218Z"
        fill="#3C3C3B"
      />
      <path
        d="M28.6323 93.236C27.628 94.7058 25.9929 95.6078 24.2252 95.667C21.7334 95.667 19.5763 93.7898 18.6467 90.9738H23.7977C24.1298 90.9738 24.4368 90.7949 24.6029 90.5045C24.7689 90.2141 24.7689 89.8563 24.6029 89.5659C24.4368 89.2755 24.1298 89.0966 23.7977 89.0966H18.2653C18.2653 88.7869 18.2188 88.4677 18.2188 88.158C18.2188 87.8483 18.2188 87.5292 18.2653 87.2195H23.7977C24.1298 87.2195 24.4368 87.0405 24.6029 86.7501C24.7689 86.4597 24.7689 86.1019 24.6029 85.8115C24.4368 85.5212 24.1298 85.3422 23.7977 85.3422H18.6558C19.5855 82.5264 21.7334 80.649 24.2344 80.649C25.9988 80.7112 27.6299 81.6129 28.6323 83.08C28.7651 83.3107 28.9887 83.4735 29.2474 83.5277C29.5061 83.5819 29.7756 83.5224 29.9882 83.3641C30.2008 83.2057 30.3367 82.9636 30.3619 82.698C30.387 82.4325 30.299 82.1687 30.1199 81.9726C29.4577 81.0068 28.5783 80.2133 27.5537 79.6571C26.529 79.1006 25.3884 78.7974 24.2252 78.7719C20.7293 78.7719 17.717 81.4375 16.722 85.3421H14.4999C14.1678 85.3421 13.8608 85.521 13.6948 85.8114C13.5287 86.1018 13.5287 86.4596 13.6948 86.75C13.8608 87.0404 14.1678 87.2193 14.4999 87.2193H16.4061V88.1579V89.0964H14.4999C14.1678 89.0964 13.8608 89.2754 13.6948 89.5658C13.5287 89.8562 13.5287 90.214 13.6948 90.5043C13.8608 90.7947 14.1678 90.9737 14.4999 90.9737H16.7222C17.7357 94.8783 20.7296 97.5438 24.2254 97.5438H24.2252C25.3884 97.5184 26.529 97.2151 27.5537 96.6587C28.5783 96.1025 29.4577 95.3089 30.1199 94.3432C30.2746 94.0744 30.2874 93.7455 30.1538 93.4652C30.0204 93.1848 29.758 92.9895 29.4532 92.9436C29.1486 92.8976 28.8412 93.0069 28.6323 93.2357L28.6323 93.236Z"
        fill="#9D9D9C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.5 32.8846C110.613 32.8846 118 30.8957 118 28.4423C118 25.9889 110.613 24 101.5 24C92.3873 24 85 25.9889 85 28.4423C85 30.8957 92.3873 32.8846 101.5 32.8846ZM115.77 32.412C105.771 35.9829 94.8004 35.6989 85 31.6154V37.4734C94.8155 41.4688 105.751 41.747 115.757 38.2559L118 37.4734V31.6154L115.77 32.412ZM85 38.7797C94.8004 42.8632 105.771 43.1472 115.77 39.5763L118 38.7797V44.6377L115.757 45.4202C105.751 48.9113 94.8155 48.6331 85 44.6377V38.7797ZM115.682 47.4477C105.634 50.4268 94.9049 50.1865 85 46.7605V51.6234C94.9158 54.974 105.62 55.2092 115.673 52.2974L118 51.6234V46.7605L115.682 47.4477ZM85 52.9013C94.9049 56.3273 105.634 56.5676 115.682 53.5885L118 52.9013V57.7642L115.673 58.4382C105.62 61.3501 94.9158 61.1148 85 57.7642V52.9013ZM115.725 59.7857C105.7 63.0617 94.8546 62.7993 85 59.0421V64.4023C94.8675 68.0775 105.683 68.3345 115.714 65.1322L118 64.4023V59.0421L115.725 59.7857Z"
        fill="white"
      />
      <path
        d="M93 19.5L110.5 16.5L127.169 39.5956L118.181 45.9384L93 19.5Z"
        fill="#9D9D9C"
      />
      <ellipse
        cx="101.122"
        cy="103.602"
        rx="16.1216"
        ry="4.34043"
        fill="#3C3C3B"
      />
      <path
        d="M85 106.702V106.702C94.5756 110.692 105.295 110.97 115.064 107.481L117.243 106.702V112.426L115.052 113.191C105.275 116.602 94.5904 116.33 85 112.426V112.426V106.702Z"
        fill="#3C3C3B"
      />
      <rect
        x="122.714"
        y="92"
        width="12.4012"
        height="38.4438"
        transform="rotate(45.3014 122.714 92)"
        fill="#9D9D9C"
      />
      <path
        d="M81.3874 96.7172L78.9675 89.6046C78.7539 89.0512 78.1762 88.788 77.6667 88.9597C77.1573 89.1313 76.8501 89.751 77.022 90.2605L78.7465 95.3217L66.7279 90.7842C66.1992 90.5846 65.6085 90.8515 65.4089 91.3803C65.2092 91.909 65.4761 92.4997 66.0049 92.6993L78.0235 97.2367L73.3845 99.8954C72.9191 100.164 72.751 100.826 73.0087 101.298C73.2745 101.784 73.9456 101.942 74.4111 101.673L80.9278 97.9347C81.3732 97.6356 81.5039 97.2138 81.3872 96.7178L81.3874 96.7172Z"
        fill="white"
      />
      <path
        d="M79.4561 70.2784L72.2798 68.0545C71.7065 67.9019 71.154 68.2146 70.9933 68.7276C70.8327 69.2406 71.1534 69.8534 71.6666 70.0139L76.7746 71.5943L66.0385 78.6491C65.5661 78.9595 65.4348 79.5942 65.7452 80.0665C66.0555 80.5389 66.6903 80.6702 67.1626 80.3598L77.8987 73.305L77.3222 78.6207C77.2659 79.1552 77.7027 79.6803 78.2357 79.7492C78.7855 79.8201 79.3079 79.3701 79.364 78.8355L80.1707 71.3659C80.1906 70.8298 79.9263 70.4761 79.4564 70.2788L79.4561 70.2784Z"
        fill="white"
      />
    </svg>
  );
};

export default MoneyCoin;
