import { useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import Button from "../../../components/Button";
import Image from "../../../components/Image";
import { CommentResponse, CommentType } from "../../../types/comment";
import { DocumentUserEnum } from "../../../types/document-details";
import CommentOrText from "./CommentOrText";
import {
  ButtonWrapper,
  CommentBut,
  CommentButtonContainer,
  CommentFooter,
  CommentInput,
  CommentMoreButton,
  GenericContentContainer,
  MoreOptionsItem,
  MoreOptionsWrapper,
} from "./styles";

const GenericCommentContainer: React.FC<{
  onAdd?: (
    text: string,
    qId?: string,
    updateStatusToAwaitingComments?: boolean
  ) => void;
  comments?: CommentResponse;
  addLoading?: boolean;
  userType?: DocumentUserEnum;
  maxCommentLen?: number;
  updateComments?: () => void;
}> = ({
  onAdd,
  addLoading,
  comments,
  userType,
  maxCommentLen = 240,
  updateComments,
}) => {
  const [comment, setComment] = useState("");
  const reset = () => {
    setComment("");
    resetTooltip();
  };
  const {
    tooltipRef,
    setTooltipRef,
    setTriggerRef,
    getArrowProps,
    getTooltipProps,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    interactive: true,
    offset: [0, 10],
    placement: "bottom-start",
  });

  const resetTooltip = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tooltipRef!.style.display = "none";
  };

  return (
    <GenericContentContainer>
      <CommentInput
        placeholder="Comment here"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        maxLength={maxCommentLen}
      />
      <CommentFooter>
        {maxCommentLen - comment.length} characters remaining
      </CommentFooter>
      <ButtonWrapper>
        <Button onClick={reset} disabled={addLoading}>
          Reset
        </Button>
        <CommentButtonContainer disabled={!comment || addLoading}>
          <CommentBut
            onClick={() => {
              onAdd?.(comment, undefined, false);
              reset();
            }}
            disabled={!comment || addLoading}
          >
            Comment
          </CommentBut>
          {userType === DocumentUserEnum.approver && (
            <CommentMoreButton
              disabled={!comment || addLoading}
              ref={setTriggerRef}
            >
              <Image name="downArrow" color="white" />
            </CommentMoreButton>
          )}
        </CommentButtonContainer>
        {visible && !(!comment || addLoading) && (
          <MoreOptionsWrapper
            ref={setTooltipRef}
            {...getTooltipProps({
              className: "tooltip-container",
            })}
          >
            <div
              {...getArrowProps({
                className: "tooltip-arrow",
              })}
            />
            <MoreOptionsItem
              onClick={() => {
                onAdd?.(comment, undefined, true);
                reset();
              }}
            >
              Save & Send back to Editor
            </MoreOptionsItem>
          </MoreOptionsWrapper>
        )}
      </ButtonWrapper>
      {comments?.generic &&
        comments?.generic?.map((comment, index) => (
          <CommentOrText
            key={index.toString()}
            comment={comment}
            commentType={CommentType.generic}
            documentId={comments.documentId}
            updateCommentsList={updateComments}
          />
        ))}
    </GenericContentContainer>
  );
};

export default GenericCommentContainer;
