import React from "react";
import { ImageProps } from "../types";

const User: React.FC<ImageProps> = ({ color = "#333", ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...otherProps}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="2"
        transform="translate(5 7)"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1,18 C1,18 0.5,13 6,13 C11.5,13 10.512498,13 16.006249,13 C21.5,13 21.0062485,18 21.0062485,18"
        />
        <circle cx="11" cy="5" r="5" />
      </g>
    </svg>
  );
};

export default User;
