import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const RightContainer = styled.div`
  display: block;
  flex: 17;
  padding-left: ${spacingIncrement(15)};
`;

export const LeftContainer = styled.div`
  display: block;
  flex: 3;
`;

export const MainContainer = styled.div<{ paddingTop?: number }>`
  display: flex;
  flex-direction: row;
  padding-top: ${({ paddingTop }) => spacingIncrement(paddingTop || 0)};
`;
