// import { saveAs } from "file-saver";
import axios from "axios";
import { SESSION_EXPIRED_MESSAGE } from "../lib/constants";
import { ApiRequest, ApiUploadRequest } from "../types/apis";
import KeycloakService from "../keycloak/KeycloakService";

(function axiosInterceptors() {
  axios.interceptors.request.use(
    (config: any) => {
      if (KeycloakService.isLoggedIn()) {
        const cb = () => {
          config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
          return Promise.resolve(config);
        };
        //If token is expired with 300 sec the token is refreshed. If -1 is passed as param //,the token will be forcibly refreshed
        return KeycloakService.updateToken(180)
          .then(cb)
          .catch(KeycloakService.doLogout);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (
        error.response.status === 403 &&
        error.response.data.message === SESSION_EXPIRED_MESSAGE
      ) {
        if (KeycloakService.isLoggedIn()) {
          KeycloakService.doLogout();
        } else {
          KeycloakService.doLogin();
        }
        return Promise.reject(error);
      } else {
        throw error;
      }
    }
  );
})();
function getDefaultQueryParams() {
  return {};
}
export function fetchData({
  endPoint,
  queryParam = {},
  responseType = "json",
}: ApiRequest) {
  return axios({
    method: "get",
    headers: { Pragma: "no-cache" }, //THis is specifically for IE
    url: `${process.env.REACT_APP_API_ENDPOINT}/${endPoint}`,
    params: { ...getDefaultQueryParams(), ...queryParam },
    withCredentials: true,
    responseType,
  });
}
export function postData({
  endPoint,
  queryParam = {},
  data,
  responseType = "json",
}: ApiRequest) {
  return axios({
    method: "post",
    headers: { Pragma: "no-cache" }, //THis is specifically for IE
    url: `${process.env.REACT_APP_API_ENDPOINT}/${endPoint}`,
    params: { ...getDefaultQueryParams(), ...queryParam },
    data,
    responseType,
    withCredentials: true,
  });
}

export function uploadFile({
  endPoint,
  data,
  responseType = "json",
  file,
}: ApiUploadRequest) {
  const formData = new FormData();
  Array.isArray(file)
    ? file.map((f, i) => formData.append(`file`, f))
    : formData.append("file", file);
  if (data) {
    Object.entries(data).map((entity) => formData.append(entity[0], entity[1]));
  }
  return axios({
    method: "post",
    headers: { Pragma: "no-cache", "content-type": "multipart/form-data" }, //THis is specifically for IE
    url: `${process.env.REACT_APP_API_ENDPOINT}/${endPoint}`,
    data: formData,
    responseType,
    withCredentials: true,
  });
}
