import React, { useEffect, useState } from "react";
import { StyledInput } from "./styles";
import { StyledNumberInlineInput } from "../common.styles";

type Props = {
  id?: string;
  value: string | number;
  onChange?: (value: number) => void;
  maxLimit?: number;
  disabled?: boolean;
  minLimit?: number;
  displayStyle?: string;
  width?: string;
  inline?: boolean;
};

const NumberInput: React.FC<Props> = ({
  id,
  value,
  onChange,
  maxLimit = undefined,
  disabled = false,
  minLimit = 0,
  displayStyle = "block",
  width,
  inline = false,
}) => {
  const [data, setData] = useState(value?.toString() || "");
  useEffect(() => {
    setData(value?.toString() || "");
  }, [value]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "") {
      setData("");
      onChange?.(0);
      return;
    }
    if (value || value === "0") {
      const v =
        maxLimit && parseFloat(value) > maxLimit
          ? maxLimit
          : parseFloat(value) < minLimit
          ? 0
          : value;
      const preciseVal: string = Number(v).toFixed(2);
      setData(v.toString());
      onChange?.(Number(preciseVal));
    }
  };
  if (inline) {
    return (
      <StyledNumberInlineInput
        id={id}
        value={data}
        onChange={handleChange}
        onBlur={() => {
          if (data === "") return onChange?.(0);
          const fixedValue: string = Number(data).toFixed(2);
          setData(Number(fixedValue).toString());
          onChange?.(Number(fixedValue));
        }}
        disabled={disabled}
        type="number"
        min={minLimit}
        max={maxLimit}
      />
    );
  }
  return (
    <StyledInput
      id={id}
      value={data}
      style={{
        display: displayStyle,
        cursor: disabled ? "not-allowed" : "",
        backgroundColor: disabled ? "#f5f5f5" : "#fff",
      }}
      width={width}
      onChange={handleChange}
      onBlur={() => {
        if (data === "") return onChange?.(0);
        const fixedValue: string = Number(data).toFixed(2);
        setData(Number(fixedValue).toString());
        onChange?.(Number(fixedValue));
      }}
      disabled={disabled}
      type="number"
      step={".01"}
    />
  );
};

export { NumberInput };
