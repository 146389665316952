import React from "react";
// import Icon from "../common/Icons";
import { NavLink } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function NavigationGroups({ navLinks }: { navLinks: any }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return navLinks.map((value: any) => {
    return (
      <span key={value.id}>
        <li key={value.id} id={value.id} className="nav__label">
          {value.groupName}
        </li>

        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {value.links.map((val: any) => {
          return (
            <li key={val.id} className="nav__item">
              {val.navLink ? (
                <NavLink
                  key={val.id}
                  className={`nav__link has-icon ${({
                    isActive,
                  }: {
                    isActive: boolean;
                  }) => (isActive ? "active" : "")}`}
                  to={val.navLink}
                  // activeClassName="active"
                >
                  {val.linkName}
                </NavLink>
              ) : (
                <a
                  key={val.id}
                  className="nav__link has-icon"
                  href={val.linkURL}
                >
                  {val.linkName}
                </a>
              )}
            </li>
          );
        })}
      </span>
    );
  });
}
export default NavigationGroups;
