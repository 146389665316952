import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { LoaderContainer, ViewModeEditorComponent } from "./styles";
import { RotatingLines } from "react-loader-spinner";
import { notifyError } from "../../utils/common";

type Props = {
  id: string;
  innerHTML?: string;
  onChange?: (html: string) => void;
  disabled?: boolean;
  plugins?: string[];
  menubar?: string;
  toolbar?: string;
  placeHolder?: string;
  paste_preprocess?: (plugin: any, args: any) => void;
  height?: number;
  pasteAsPlainText?: boolean;
  removeClassOnPaste?: boolean;
  removeImageOnPaste?: boolean;
};

// This function is used to remove list tags from the content when pasting
export const paste_preprocess_remove_list_tags = (plugin: any, args: any) => {
  if (args.content) {
    const div = document.createElement("div");
    div.innerHTML = args.content;

    const listTags = ["ul", "ol"];
    listTags.forEach((tag) => {
      const listElements = div.querySelectorAll(tag);
      listElements.forEach((listElement) => {
        const textNode = document.createTextNode(listElement.textContent || "");
        listElement.parentNode?.replaceChild(textNode, listElement);
      });
    });
    args.content = div.innerHTML;
  }
};

const paste_preprocess_remove_class_attributes = (plugin: any, args: any) => {
  if (args.content) {
    const div = document.createElement("div");
    div.innerHTML = args.content;

    const elements = div.querySelectorAll("*");
    elements.forEach((element) => {
      element.removeAttribute("class");
    });
    args.content = div.innerHTML;
  }
};

export const pase_preprocess_avoid_images = (plugin: any, args: any) => {
  if (args.content) {
    const div = document.createElement("div");
    div.innerHTML = args.content;
    const imgTags = div.querySelectorAll("img");
    if (imgTags.length > 0) {
      notifyError("Can't paste images in the editor.", {
        autoClose: 3000,
        toastId: "paste-image-error",
      });
      args.preventDefault();
      args.content = "";
      return;
    } else {
      args.content = div.innerHTML;
    }
  }
};

const TinyEditor: React.FC<Props> = ({
  id,
  innerHTML,
  onChange,
  disabled = false,
  placeHolder,
  plugins = ["table", "lists", "link", "autoresize"],
  menubar = "format table tools insert edit",
  toolbar = "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | advtable | removeformat link",
  paste_preprocess,
  height = 300,
  pasteAsPlainText,
  removeClassOnPaste = true,
  removeImageOnPaste = true,
}) => {
  const [loading, setLoading] = useState(true);
  const editorRef = useRef<any>(null);
  const dataChanged = async () => {
    if (editorRef.current) {
      onChange?.(editorRef.current.getContent());
    }
  };

  if (disabled) {
    return (
      <ViewModeEditorComponent
        id={`editor-view-mode-${id}`}
        isEmpty={!innerHTML}
        dangerouslySetInnerHTML={{ __html: innerHTML || placeHolder || "" }}
      />
    );
  }

  const paste_preprocess_fn = (plugin: any, args: any) => {
    if (removeClassOnPaste)
      paste_preprocess_remove_class_attributes(plugin, args);
    if (removeImageOnPaste) pase_preprocess_avoid_images(plugin, args);
    if (paste_preprocess) paste_preprocess(plugin, args);
  };

  return (
    <>
      {loading && (
        <LoaderContainer>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="3"
            width="50"
            visible={loading}
          />
        </LoaderContainer>
      )}
      <Editor
        tinymceScriptSrc={`${process.env.REACT_APP_REDIRECT_URL}/tinymce/tinymce.min.js`}
        onInit={(_, editor) => {
          setLoading(false);
          editorRef.current = editor;
        }}
        disabled={disabled}
        value={innerHTML ?? ""}
        id={`editor-edit-mode-${id}`}
        init={{
          body_id: `tiny-body-${id}`,
          branding: false,
          paste_as_text: pasteAsPlainText,
          height: height,
          plugins: plugins,
          placeholder: placeHolder,
          menubar: disabled ? false : menubar,
          toolbar: disabled ? "" : toolbar,
          statusbar: false,
          promotion: false,
          autoresize_overflow_padding: 0,
          autoresize_bottom_margin: disabled ? 0 : 50,
          paste_preprocess: paste_preprocess_fn,
        }}
        onEditorChange={dataChanged}
      />
    </>
  );
};

export default TinyEditor;
