import { ASSET_ALLOCATION_PERCENTAGE_KEY } from "../../lib/constants";
import { chartConfig, NodeConfig } from "./config";
// import { chartTranslations } from "./translations";

export function fetchNestedChartData(
  obj: NodeConfig,
  documentState: { [key: string]: any }
) {
  let nodeFound = false;

  if (documentState[obj.nodeId]) {
    nodeFound = true;
  }

  if (nodeFound && obj.children?.length) {
    const tempChildren = [...obj.children];
    const filteredList = tempChildren
      .filter((item) => {
        if (item.nonConditional) return true;
        if (item.nodeId && item.answer) {
          return documentState[item.nodeId] === item.answer;
        }
        return !!documentState[item.nodeId];
      })
      .map((item) => {
        const percentage =
          documentState[ASSET_ALLOCATION_PERCENTAGE_KEY]?.[item.nodeId];
        if (percentage) {
          item.percentage = percentage;
        }
        return item;
      });

    filteredList.forEach((item) => {
      if (documentState[item.nodeId] && item.children?.length) {
        fetchNestedChartData(item, documentState);
      }
    });

    obj.children = filteredList;
  }

  return obj;
}

export function getChartData(
  documentState: any,
  article: string,
  template: string
) {
  let chartData: NodeConfig = {
    nodeId: "404",
    nodeLabel: "No Data Found",
    nodeLabelKey: "no_data_found",
    nodeStyles: {},
  };
  if (documentState && article && template) {
    const config = chartConfig[article][template];
    chartData = fetchNestedChartData(
      JSON.parse(JSON.stringify(config)),
      documentState
    );
  }

  return chartData;
}
