import styled from "styled-components";
import { spacingIncrement } from "../../utils/common";

export const StyledSideWrapper = styled.div`
  z-index: 1000;
  height: 100%;
  background-color: #fff;
  .panel {
    background-color: #fff;
  }
`;

export const TitleContainer = styled.div`
  padding: ${spacingIncrement(16)} ${spacingIncrement(40)}
    ${spacingIncrement(16)} ${spacingIncrement(24)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #dee2e6;
`;

export const Title = styled.div`
  font-size: ${spacingIncrement(22)};
  font-weight: 600;
`;

export const Container = styled.div`
  background-color: #ffffff;
  height: 100vh;
`;

export const HeaderContainer = styled.div``;

export const ContentContainer = styled.div`
  background-color: #fff;
  height: 92%;
  scrollbar: none;
  overflow-y: auto;
`;
