import styled, { css } from "styled-components";
import { spacingIncrement } from "../../../utils/common";

export const Container = styled.div<{ isPreview?: boolean }>`
  ${({ isPreview }) =>
    isPreview &&
    css`
      overflow-y: hidden;
      height: 75vh;
    `};
`;

export const DocumentWraper = styled.div<{
  isPDF?: boolean;
  isDocumetLocked?: boolean;
}>`
  ${({ isPDF }) =>
    isPDF
      ? css``
      : css`
          width: ${spacingIncrement(1216)};
          margin: ${spacingIncrement(20)} auto;
          border: 1px solid #dbd9d9;
          font-family: "Calibri", "Arial" !important;
        `}
  ${({ isDocumetLocked }) =>
    isDocumetLocked &&
    css`
      border: unset;
    `}
`;

export const LoadingContainer = styled.div`
  padding: ${spacingIncrement(20)};
  text-align: center;
`;

export const ErrorMessageStyled = styled.span<{
  marginLeft?: number;
  isEditMode?: boolean;
}>`
  display: ${({ isEditMode }) => (isEditMode ? "inline-block" : "none")};
  color: #f05959;
  width: fit-content;
  font-size: 11px;
  padding: 0 ${spacingIncrement(2)};
  margin: ${spacingIncrement(2)} 0;
  margin-left: ${spacingIncrement(4)};
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${spacingIncrement(marginLeft)};
    `}
`;

export const ExternalUrlContainer = styled.div<{ isDocumetLocked: boolean }>`
  display: flex;
  align-items: center;
  padding: ${spacingIncrement(12)};
  background-color: #eefbff;
  color: #303246;
  width: ${spacingIncrement(1216)};
  margin: ${spacingIncrement(20)} auto;
  ${({ isDocumetLocked }) =>
    isDocumetLocked &&
    css`
      position: relative;
      z-index: -1;
    `}
`;

export const Link = styled.span`
  color: #0abaee;
  cursor: pointer;
`;

export const LinkText = styled.p`
  margin: 0;
  margin-left: ${spacingIncrement(10)};
  span {
    color: #0abaee;
    cursor: pointer;
  }
`;
