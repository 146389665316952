import { useContext, useState } from "react";
import { initialFilterState } from "../../../features/listing";
import { clearCachedFilters } from "../../../services/listing";
import Context from "../../../store/context";
import {
  updateSelectedFund,
  updateStoredFilters,
  updateStoredFiltersForArchived,
} from "../../../store/globalActions";
import { GLOBAL_CONTEXT } from "../../../types/store";
import FundSelect, { Option } from "../FundSelect";
import { setSelectedFundId } from "../services";
import { HEADER_TABS_LIST, HeaderTabs } from "../../../utils/common";

type Props = {
  details: string;
  selectedTab: TabListType;
  getSelectedTab: (tab: TabListType) => void;
};

export const tabList = Object.keys(HeaderTabs);

export type TabListType = keyof typeof HeaderTabs;

const Container: React.FC<Props> = ({
  details,
  selectedTab,
  getSelectedTab,
}) => {
  const [navToggle, setNavToggle] = useState(false);
  const {
    globalState: { fundList, selectedFund, userDetails },
    globalDispatch,
  } = useContext<GLOBAL_CONTEXT>(Context);

  const userType = userDetails?.userType;

  const onClickOption = (opt: Option) => {
    const selectedFund = fundList.find((fund) => fund.id === opt.value);
    if (selectedFund) {
      setSelectedFundId(String(selectedFund.id));
      globalDispatch(updateStoredFilters(initialFilterState));
      globalDispatch(updateStoredFiltersForArchived(initialFilterState));
      clearCachedFilters();
      globalDispatch(updateSelectedFund(selectedFund));
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-lg-auto page-header__details">
          <FundSelect
            options={fundList.map((fund) => ({
              label: fund.name,
              value: fund.id,
            }))}
            value={selectedFund?.name}
            onChange={onClickOption}
          />

          <div className="details__list">
            <span className="detail">{details}</span>
          </div>
        </div>
        <div className="col-sm-12 col-lg-auto ml-lg-auto align-self-end page-header__nav">
          <ul
            className={`nav sub-nav ${navToggle ? "sub-nav--open" : ""}`}
            onClick={() => setNavToggle(!navToggle)}
          >
            {HEADER_TABS_LIST.map((tab) => {
              // if (tab.adminAccess && userType !== "admin") return null;
              return (
                <li
                  key={tab.key}
                  className="nav-item"
                  onClick={() => {
                    getSelectedTab(tab.key);
                  }}
                >
                  <button
                    style={{ textAlign: "left" }}
                    className={`buttonAsLink nav-link ${
                      selectedTab === tab.key ? "active" : ""
                    }`}
                  >
                    {tab.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Container;
