import React from "react";
import { ImageProps } from "../types";

const DownArrow: React.FC<ImageProps> = ({
  color = "#303246",
  ...otherProps
}) => {
  return (
    <svg
      width="11"
      height="5"
      viewBox="0 0 11 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M0.978516 0H10.9785L5.97852 5L0.978516 0Z" fill={color} />
    </svg>
  );
};

export default DownArrow;
