import React from "react";
import Loading from "./svg/Loading";
import Logo from "./svg/Logo";
import User from "./svg/User";
import Download from "./svg/Download";
import Delete from "./svg/Delete";
import Edit from "./svg/Edit";
import Lock from "./svg/Lock";
import Eye from "./svg/Eye";
import Translate from "./svg/Translate";
import Plus from "./svg/Plus";
import Cross from "./svg/Cross";
import DownArrow from "./svg/DownArrow";
import MoreOptions from "./svg/MoreOptions";
import PreviousVersion from "./svg/PreviousVersion";
import ArrowLeft from "./svg/ArrowLeft";
import Question from "./svg/Question";
import Comment from "./svg/Comment";
import Globe from "./templateSvg/Globe";
import Factory from "./templateSvg/Factory";
import Book from "./templateSvg/Book";
import TemplateComment from "./templateSvg/Comment";
import Computer from "./templateSvg/Computer";
import DocNotIncluded from "./templateSvg/DocNotIncluded";
import Graphs from "./templateSvg/Graphs";
import Info from "./templateSvg/Info";
import Money from "./templateSvg/Money";
import NoBook from "./templateSvg/NoBook";
import NoMoney from "./templateSvg/NoMoney";
import Users from "./templateSvg/Users";
import WritingPad from "./templateSvg/WritingPad";
import ArrowRight from "./svg/ArrowRight";
import ArrowBottom from "./svg/ArrowBottom";
import Approval from "./svg/Approval";

import { ImageName, ImageProps } from "./types";
import CommentFilled from "./templateSvg/CommentFilled";
import CheckBoxCross from "./templateSvg/Cross";
import Pencil from "./templateSvg/Pencil";
import CloneDocument from "./svg/CloneDocument";
import Save from "./svg/Save";
import DeleteCircle from "./svg/DeleteCircle";
import GlobeNo from "./templateSvg/GlobeNo";
import HumanArrow from "./templateSvg/HumanArrow";
import LeafBW from "./templateSvg/LeafBW";
import MoneyCoin from "./templateSvg/MoneyCoin";
import Configuration from "./svg/Configuration";
import BlueInfo from "./templateSvg/BlueInfo";
import RequestDoc from "./svg/RequestDoc";
import RequestDocCircle from "./svg/RequestDocCircle";
import Previous from "./svg/PreviousAnswers";
import Copy from "./svg/Copy";
import UrlLink from "./svg/UrlLink";
import BulkUrlLink from "./svg/BulkUrlLink";
import Unauthorized from "./svg/unauthorized";
import CheckBox from "./svg/checkBox";
import DownloadBox from "./svg/downloadBox";
import ImportExcel from "./svg/ImportExcel";
import { ReactComponent as Find } from "./svg/icons/find.svg";
import { ReactComponent as Amendment } from "./svg/icons/amendment.svg";
import { ReactComponent as SuccessCircleCheck } from "./svg/icons/success.svg";
import { ReactComponent as FailureCircle } from "./svg/icons/failure.svg";
import { ReactComponent as Exchange } from "./svg/icons/exchange.svg";
import { ReactComponent as LinkInfo } from "./svg/icons/linkInfo.svg";
import { ReactComponent as TrashCircle } from "./svg/icons/trash-circle.svg";
import { ReactComponent as Pen } from "./svg/icons/pen-16.svg";
import { ReactComponent as Down } from "./svg/icons/chevron-down-12.svg";
import { ReactComponent as Up } from "./svg/icons/chevron-up-12.svg";
import { ReactComponent as ExportExcel } from "./svg/icons/exportExcel.svg";
import { ReactComponent as DownloadIcon } from "./svg/icons/download.svg";
import { ReactComponent as ArchiveIcon } from "./svg/icons/archive.svg";
import { ReactComponent as ArchiveRound } from "./svg/icons/archive-round.svg";

type Props = ImageProps & {
  name: ImageName;
};

export const imageNames = {
  logo: Logo,
  user: User,
  loading: Loading,
  download: Download,
  delete: Delete,
  edit: Edit,
  lock: Lock,
  eye: Eye,
  translate: Translate,
  plus: Plus,
  cross: Cross,
  downArrow: DownArrow,
  moreOptions: MoreOptions,
  previousVersion: PreviousVersion,
  arrowLeft: ArrowLeft,
  question: Question,
  comment: Comment,
  globe: Globe,
  factory: Factory,
  book: Book,
  templateComment: TemplateComment,
  computer: Computer,
  docNotIncluded: DocNotIncluded,
  graphs: Graphs,
  info: Info,
  money: Money,
  noBook: NoBook,
  noMoney: NoMoney,
  users: Users,
  writingPad: WritingPad,
  arrowRight: ArrowRight,
  arrowBottom: ArrowBottom,
  approval: Approval,
  templateCommentFilled: CommentFilled,
  checkBoxCross: CheckBoxCross,
  pencil: Pencil,
  cloneDocument: CloneDocument,
  save: Save,
  deleteCircle: DeleteCircle,
  moneyCoin: MoneyCoin,
  globeNo: GlobeNo,
  humanArrow: HumanArrow,
  leafBW: LeafBW,
  configuration: Configuration,
  blueInfo: BlueInfo,
  requestDoc: RequestDoc,
  requestDocCircle: RequestDocCircle,
  previous: Previous,
  copy: Copy,
  urlLink: UrlLink,
  bulkUrlLink: BulkUrlLink,
  unauthorized: Unauthorized,
  checkBox: CheckBox,
  downloadBox: DownloadBox,
  find: Find,
  amendment: Amendment,
  successCircleCheck: SuccessCircleCheck,
  failureCircle: FailureCircle,
  exchange: Exchange,
  linkInfo: LinkInfo,
  trashCircle: TrashCircle,
  pen: Pen,
  up: Up,
  down: Down,
  exportExcel: ExportExcel,
  importExcel: ImportExcel,
  downloadIcon: DownloadIcon,
  archiveIcon: ArchiveIcon,
  archiveRound: ArchiveRound,
};

const Image: React.FC<Props> = ({ name, ...otherProps }) => {
  const ImageComponent = imageNames[name];
  return <ImageComponent {...otherProps} />;
};

export default Image;
