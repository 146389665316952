import { DocumentArticle, DocumentTemplate } from "./document-details";

export interface QuestionsCollection {
  commentableQuestionIds: string[];
  article: DocumentArticle;
  template: DocumentTemplate;
  questions: Question[];
  staticContent: DocumentStaticContent;
  commonContent: DocumentCommonContent;
}
export type DocumentStaticContent = {
  greyBox: StaticTextInfo[];
  headingText: StaticTextInfo[];
  infoTooltip: StaticTextInfo[];
  questionAnswerOption: StaticTextInfo[];
  staticText: StaticTextInfo[];
  questionText: StaticTextInfo[];
};
export type DocumentCommonContent = {
  assetAllocationBlocks: StaticTextInfo[];
  commonLinks: StaticLink[];
  countryList: StaticTextInfo[];
  questions: StaticTextInfo[];
};
type KeyValue = {
  [key in keyof typeof Language]: string;
};
export type StaticLink = {
  id: string;
  link: string;
  text: KeyValue;
};
export type StaticTextInfo = {
  id: string;
  order: number;
  text: any;
};
export type Rule = {
  type: string;
  errorMessage: string;
  dependantFieldId?: string;
  fields?: string[];
  value?: number | string;
  validations?: Rule[];
  regex?: string;
  typeof?: string;
};
export interface Question {
  previousAnswers: boolean;
  id: string;
  label: Label;
  pdfView: boolean;
  disabled: boolean;
  type: Type;
  dependants?: string[];
  validations?: Rule[];
  autofillFilterPolicy: {
    subFund: boolean;
    template: boolean;
    article: boolean;
  };
  relevantQuestionIds?: string[];
}

export interface DisplayQuestion {
  [key: string]: Question;
}
export type DocumentErrorState = {
  isFailed: boolean;
  errors?: QuestionError[];
};
export type QuestionError = {
  id: string;
  error: boolean;
  message: string | null | undefined;
};

export type LabelText = string[] | string;

export type Label = {
  [key in Language | string]: LabelText;
};

export enum Type {
  Input = "input",
  Radio = "radio",
}

export enum Language {
  "English" = "english",
  "German" = "german",
  "Italian" = "italian",
  "French" = "french",
  "Spanish" = "spanish",
}
