import React from "react";
import { ImageProps } from "../types";

const Computer: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="145"
      height="145"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="72.5" cy="72.5" r="72.5" fill="#9D9D9C" />
      <path
        d="M95.6754 46.7993H21.3237C18.9393 46.7993 17 48.7386 17 51.1223V102.206C17 104.59 18.9406 106.529 21.3237 106.529H50.8007L50.2521 115.552C50.2539 115.509 50.2434 118.782 50.2521 115.552C50.2514 115.583 50.0795 115.552 44.0785 115.552C42.8497 115.552 41.8543 116.547 41.8543 117.776C41.8543 119.005 42.8497 120 44.0785 120H72.9231C74.1519 120 75.1473 119.005 75.1473 117.776C75.1473 116.547 74.1519 115.552 72.9231 115.552C67.195 115.552 66.2276 115.817 66.2016 115.552C66.2016 118.65 66.7539 115.596 66.7496 115.552L66.2016 106.529H95.677C98.0613 106.529 100 104.588 100 102.205V51.1233C99.9994 48.7389 98.0598 46.7993 95.6754 46.7993ZM95.5513 51.2476V92.7003L21.4474 92.6997V51.247L95.5513 51.2476ZM62.3059 112.68C62.3469 113.494 62.61 114.524 63.2089 115.553H53.7926C54.3902 114.524 54.6546 113.494 54.6955 112.68L55.2696 106.531H61.7342L62.3059 112.68ZM21.4474 102.082V97.149H95.5497V102.082H21.4474Z"
        fill="#3C3C3B"
      />
      <rect x="20" y="96.1375" width="75.7375" height="7.2625" fill="#3C3C3B" />
      <circle cx="58.5563" cy="99.7687" r="1.55625" fill="#9D9D9C" />
      <rect x="53" y="106" width="12" height="11" fill="#3C3C3B" />
      <path
        d="M67 44.7759C67 40.9099 70.134 37.7759 74 37.7759H118.597C122.463 37.7759 125.597 40.9099 125.597 44.7759V55.8507C125.597 59.7167 122.463 62.8507 118.597 62.8507H95.5297C93.5118 62.8507 91.5921 63.7215 90.2629 65.2398L85.2215 70.9983C84.6134 71.693 83.4691 71.2629 83.4691 70.3396V63.8507C83.4691 63.2984 83.0214 62.8507 82.4691 62.8507H74C70.134 62.8507 67 59.7167 67 55.8507V44.7759Z"
        fill="#9D9D9C"
      />
      <path
        d="M72.5 40.3729C72.5 37.3353 74.9624 34.8729 78 34.8729H122.597C125.635 34.8729 128.097 37.3353 128.097 40.3729V51.4477C128.097 54.4852 125.635 56.9477 122.597 56.9477H99.5297C97.0794 56.9477 94.7483 58.0051 93.1343 59.8487L88.9691 64.6064V59.4477C88.9691 58.067 87.8498 56.9477 86.4691 56.9477H78C74.9624 56.9477 72.5 54.4852 72.5 51.4477V40.3729Z"
        fill="white"
        stroke="#3C3C3B"
        strokeWidth="3"
      />
      <path
        d="M86.1548 49.0029L87.9956 41.5317H88.9922L89.0557 43.1059L87.0879 50.7739H86.0342L86.1548 49.0029ZM84.9932 41.5317L86.5039 48.9775V50.7739H85.355L83.2603 41.5317H84.9932ZM90.9663 48.9458L92.4517 41.5317H94.1909L92.0962 50.7739H90.9473L90.9663 48.9458ZM89.4683 41.5317L91.3091 49.0283L91.417 50.7739H90.3633L88.4019 43.0996L88.478 41.5317H89.4683ZM97.5679 49.0029L99.4087 41.5317H100.405L100.469 43.1059L98.501 50.7739H97.4473L97.5679 49.0029ZM96.4062 41.5317L97.917 48.9775V50.7739H96.7681L94.6733 41.5317H96.4062ZM102.379 48.9458L103.865 41.5317H105.604L103.509 50.7739H102.36L102.379 48.9458ZM100.881 41.5317L102.722 49.0283L102.83 50.7739H101.776L99.8149 43.0996L99.8911 41.5317H100.881ZM108.981 49.0029L110.822 41.5317H111.818L111.882 43.1059L109.914 50.7739H108.86L108.981 49.0029ZM107.819 41.5317L109.33 48.9775V50.7739H108.181L106.086 41.5317H107.819ZM113.792 48.9458L115.278 41.5317H117.017L114.922 50.7739H113.773L113.792 48.9458ZM112.294 41.5317L114.135 49.0283L114.243 50.7739H113.189L111.228 43.0996L111.304 41.5317H112.294Z"
        fill="#3C3C3B"
      />
    </svg>
  );
};

export default Computer;
