import { ToastContainer } from "react-toastify";
import { ToastWrapper } from "./styles";

const Toast: React.FC = () => {
  return (
    <ToastWrapper>
      <ToastContainer
        autoClose={4000}
        position="top-center"
        icon={false}
        closeButton={false}
        hideProgressBar={true}
      />
    </ToastWrapper>
  );
};

export default Toast;
