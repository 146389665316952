import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.div<{ paddingLeft?: number }>`
  padding-left: ${({ paddingLeft }) => spacingIncrement(paddingLeft || 0)};
  display: flex;
  cursor: pointer;
  color: #0abaee;
  font-size: 11px;
  line-height: 11px;
  font-style: normal;
`;
export const PreviousText = styled.span`
  padding-left: 2px;
  color: #0abaee;
  display: inline-block;
  font-size: 11px;
  line-height: 11px;
  font-style: normal;
`;
