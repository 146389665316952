import styled from "styled-components";
import { spacingIncrement } from "../../../utils/common";
import Image from "../../Image";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacingIncrement(10)} 0;
`;

export const Title = styled.span`
  color: white;
  font-size: ${spacingIncrement(40)};
  font-weight: 600;
  line-height: ${spacingIncrement(40)};
`;

export const StyledImage = styled(Image)`
  margin: ${spacingIncrement(5)} ${spacingIncrement(10)} 0
    ${spacingIncrement(10)};
`;

export const SearchInput = styled.input`
  padding: ${spacingIncrement(8)} ${spacingIncrement(16)};
  border: 2px solid #d9dae3;
  border-radius: ${spacingIncrement(40)};
  margin: ${spacingIncrement(12)} auto;
  width: calc(100% - 2rem) !important;
  font-size: ${spacingIncrement(16)};
  line-height: 1.45;
  color: #495057;
  :focus {
    outline: none;
  }
`;

export const ToolTipContainer = styled.div`
  border-radius: ${spacingIncrement(5)};
  padding: 0;
  width: ${spacingIncrement(320)};
  max-height: ${spacingIncrement(280)};
`;

export const ListContainer = styled.div`
  overflow: auto;
`;

export const ToolTipArrow = styled.div`
  transform: translate(10px, 0px) !important;
`;

export const SelectText = styled.div`
  color: black;
  font-size: ${spacingIncrement(16)};
  padding: ${spacingIncrement(10)} ${spacingIncrement(20)};
  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  :not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
