import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { postData } from "../../../services/apis";
import {
  DocumentArticle,
  DocumentTemplate,
} from "../../../types/document-details";
import { sentenceCase } from "change-case";
import { Container, StyledH1 } from "./styles";

const DocumentViewer: React.FC = () => {
  const location = useLocation();
  const [url, setUrl] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { slug, article, template, lang } = useParams<{
    slug: string;
    article: string;
    template: string;
    lang: string;
  }>();
  useEffect(() => {
    if (slug && article && template && lang) {
      const isValid = validateUrlParams(
        article.toLowerCase(),
        template.toLowerCase()
      );
      if (isValid) {
        const articleVal =
          article.toLowerCase() === "article8"
            ? DocumentArticle.Article8
            : DocumentArticle.Article9;
        const templateVal =
          template.toLowerCase() === "pre-contractual"
            ? DocumentTemplate.PreContractual
            : DocumentTemplate.Periodic;
        const slugVal = slug;
        const langVal = sentenceCase(lang);
        getPublicUrl({
          slug: slugVal,
          article: articleVal,
          template: templateVal,
          language: langVal,
        });
      }
    }
  }, []);
  const getPublicUrl = async (params: {
    slug: string;
    article: string;
    template: string;
    language: string;
  }) => {
    setLoading(true);
    try {
      const resp = await postData({
        endPoint: "getDocumentPublicUrl",
        data: { ...params, pageUrl: location.pathname },
      });
      const fileURL = window.URL.createObjectURL(
        new Blob([new Int8Array(resp.data.data.Body.data).buffer], {
          type: "application/pdf",
        })
      );
      setLoading(false);
      setUrl(fileURL);
    } catch (error) {
      setLoading(false);
    }
  };
  const validateUrlParams = (article: string, template: string) => {
    if (article === "article8" || article === "article9") {
      if (template === "pre-contractual" || template === "periodic") {
        return true;
      }
    }
    return false;
  };
  return url ? (
    <>
      <iframe src={url} style={{ width: "100%", height: "100vh" }} />
    </>
  ) : (
    <Container>
      <StyledH1>{loading ? "Loading..." : "No document found"}</StyledH1>
    </Container>
  );
};

export default DocumentViewer;
