import React from "react";
import { ImageProps } from "../types";

const Globe: React.FC<ImageProps> = ({ ...otherProps }) => {
  return (
    <svg
      width="145"
      height="145"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="72.5" cy="72.5" r="72.5" fill="#9D9D9C" />
      <path
        d="M40.5949 61.5379H61.7491C59.631 72.715 55.3895 80.3234 51.172 80.3234C47.0822 80.3234 42.97 73.1691 40.7932 62.544L40.5949 61.5379H61.7491H40.5949ZM20.6468 61.5384L35.3577 61.5376C36.604 68.652 38.7165 74.7046 41.481 78.9403C32.7247 76.3542 25.4347 70.3587 21.1468 62.4913L20.6468 61.5384ZM66.9862 61.5376L81.6971 61.5384C77.4901 69.8736 69.9695 76.2508 60.8664 78.939C63.4444 74.9838 65.4558 69.4488 66.7255 62.9457L66.9862 61.5376L81.6971 61.5384L66.9862 61.5376ZM68.0211 39.3294L84.6604 39.3274C85.1086 41.5356 85.3439 43.8212 85.3439 46.1617C85.3439 49.7332 84.796 53.1768 83.7797 56.413H67.7123C68.0721 53.1412 68.2606 49.7038 68.2606 46.1617C68.2606 44.6094 68.2244 43.0772 68.1534 41.5703L68.0211 39.3294L84.6604 39.3274L68.0211 39.3294ZM17.6835 39.3274L34.3228 39.3294C34.1648 41.5501 34.0833 43.8333 34.0833 46.1617C34.0833 48.9953 34.204 51.762 34.4364 54.4305L34.6316 56.413H18.5643C17.5479 53.1768 17 49.7332 17 46.1617C17 43.8212 17.2353 41.5356 17.6835 39.3274ZM39.4716 39.3275H62.8724C63.0441 41.5334 63.1356 43.8186 63.1356 46.1617C63.1356 49.0236 62.999 51.7993 62.7466 54.4496L62.5359 56.4132H39.808C39.4217 53.1817 39.2083 49.7391 39.2083 46.1617C39.2083 44.4044 39.2598 42.6796 39.358 40.9965L39.4716 39.3275H62.8724H39.4716ZM61.2316 13.965L60.863 13.3834C71.1755 16.4275 79.4576 24.2059 83.1927 34.2022L67.5072 34.2032C66.4291 25.9454 64.2392 18.8535 61.2316 13.965L60.863 13.3834L61.2316 13.965ZM41.0647 13.5091L41.4808 13.3835C38.4715 17.9943 36.2349 24.758 35.0471 32.7004L34.8367 34.2032L19.1512 34.2022C22.836 24.3408 30.9458 16.6378 41.0647 13.5091L41.4808 13.3835L41.0647 13.5091ZM51.172 12C55.6778 12 60.2111 20.6843 62.1531 33.1268L62.3131 34.2024H40.0308C41.8738 21.1877 46.5373 12 51.172 12Z"
        fill="#3C3C3B"
      />
      <path
        d="M44.5 68.8891H82.3533V94.5687C80.7103 94.2906 79.2937 94.0381 78.0335 93.8134C73.8361 93.0651 71.3736 92.6261 68.0556 92.5825C63.6976 92.5253 57.8392 93.1476 44.5 94.6024V68.8891Z"
        fill="white"
        stroke="#9D9D9C"
      />
      <ellipse cx="64.5" cy="69.5" rx="19.5" ry="13.5" fill="white" />
      <circle cx="64.8386" cy="45.0799" r="9.92" fill="white" />
      <mask id="path-6-inside-1_19_179" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.132 72.5016V90.5466C90.9584 88.8681 82.4511 88.8693 67.2786 90.5466V72.5016V72.1946H67.2887C67.6225 67.0673 76.1879 59.9599 86.7053 59.9599C97.2228 59.9599 105.788 67.0673 106.122 72.1946H106.132V72.5016Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.132 72.5016V90.5466C90.9584 88.8681 82.4511 88.8693 67.2786 90.5466V72.5016V72.1946H67.2887C67.6225 67.0673 76.1879 59.9599 86.7053 59.9599C97.2228 59.9599 105.788 67.0673 106.122 72.1946H106.132V72.5016Z"
        fill="white"
      />
      <path
        d="M106.132 90.5466L106.022 91.5405L107.132 91.6633V90.5466H106.132ZM67.2786 90.5466H66.2786V91.6632L67.3885 91.5405L67.2786 90.5466ZM67.2786 72.1946V71.1946H66.2786V72.1946H67.2786ZM67.2887 72.1946V73.1946H68.2258L68.2866 72.2595L67.2887 72.1946ZM106.122 72.1946L105.124 72.2595L105.185 73.1946H106.122V72.1946ZM106.132 72.1946H107.132V71.1946H106.132V72.1946ZM105.132 72.5016V90.5466H107.132V72.5016H105.132ZM106.242 89.5526C90.9952 87.8661 82.4142 87.8673 67.1688 89.5526L67.3885 91.5405C82.488 89.8713 90.9216 89.8701 106.022 91.5405L106.242 89.5526ZM68.2786 90.5466V72.5016H66.2786V90.5466H68.2786ZM68.2786 72.5016V72.1946H66.2786V72.5016H68.2786ZM67.2786 73.1946H67.2887V71.1946H67.2786V73.1946ZM68.2866 72.2595C68.3537 71.2286 68.8468 70.0024 69.7916 68.71C70.7298 67.4268 72.0746 66.133 73.7468 64.9771C77.0927 62.6643 81.6676 60.9599 86.7053 60.9599V58.9599C81.2256 58.9599 76.2591 60.8092 72.6095 63.3319C70.784 64.5938 69.2675 66.0382 68.1771 67.5296C67.0934 69.0119 66.3906 70.5969 66.2909 72.1296L68.2866 72.2595ZM86.7053 60.9599C91.743 60.9599 96.3179 62.6643 99.6639 64.9771C101.336 66.133 102.681 67.4268 103.619 68.71C104.564 70.0024 105.057 71.2286 105.124 72.2595L107.12 72.1296C107.02 70.5969 106.317 69.0119 105.234 67.5296C104.143 66.0382 102.627 64.5938 100.801 63.3319C97.1515 60.8092 92.185 58.9599 86.7053 58.9599V60.9599ZM106.122 73.1946H106.132V71.1946H106.122V73.1946ZM105.132 72.1946V72.5016H107.132V72.1946H105.132Z"
        fill="#9D9D9C"
        mask="url(#path-6-inside-1_19_179)"
      />
      <circle
        cx="87.1186"
        cy="50.8666"
        r="9.42"
        fill="white"
        stroke="#9D9D9C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.0404 122.435H53.4207V107.036H64.3283H91.9181V107.062L112.179 87.4913C115.506 84.2779 120.818 84.4018 123.991 87.767C124.23 88.021 124.233 88.417 123.996 88.6735L93.4651 121.744C92.8291 122.433 91.8859 122.669 91.0404 122.435Z"
        fill="white"
      />
      <path
        d="M42.3671 102.508C44.4676 97.4006 50.2586 95.0849 55.3018 97.336L69.2439 103.559L57.6259 131.812C57.4158 132.323 56.8367 132.555 56.3324 132.329L35.0851 122.845C34.5808 122.62 34.3422 122.023 34.5523 121.513L42.3671 102.508Z"
        fill="white"
      />
      <path
        d="M45.7212 103.339C45.7212 99.512 48.8237 96.4096 52.6507 96.4096H85.119C86.0392 96.4096 86.7851 97.1555 86.7851 98.0757V98.0757C86.7851 101.162 84.6668 103.844 81.665 104.559L67.3938 107.959L66.2532 103.339H45.7212V103.339Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.5584 82.1561C99.2402 80.3782 99.7319 77.1341 100.035 71.5204C100.717 65.9195 99.2175 50.4722 96.9385 60.1448C96.7036 61.1417 96.3865 62.1687 95.6649 62.8955C93.6903 64.8846 90.3537 65.8442 87.2362 66.7409C85.4729 67.2481 83.7798 67.735 82.4429 68.3767C75.7153 69.3855 70.2701 75.5779 70.4047 82.4858C70.5502 89.9565 76.7243 95.8948 84.195 95.7493C90.8475 95.6197 97.3225 88.9012 98.5584 82.1561Z"
        fill="#3C3C3B"
      />
      <path
        d="M60.6782 92.5988L65.0291 92.0008C72.1975 91.0156 78.9776 88.1508 84.6806 83.6974L90.1914 79.3941"
        stroke="#3C3C3B"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.7459 76.6184L88.5206 85.435C86.5065 88.8334 83.9878 91.906 81.0507 94.5477L80.1282 95.3774C80.1282 95.3774 78.5975 94.4216 77.6168 93.8092C76.636 93.1967 75.1054 92.2409 75.1054 92.2409L78.0708 90.7313C82.2744 88.5913 86.0165 85.646 89.0843 82.0629L93.7459 76.6184Z"
        fill="#9D9D9C"
      />
      <path
        d="M77.3964 94.0774L77.1272 94.4988C77.1447 94.5099 77.1629 94.52 77.1816 94.5289L77.3964 94.0774ZM74.7602 92.6781L75.0936 92.9987L75.7868 92.278L75.4534 91.9574L74.7602 92.6781ZM77.1816 94.5289L78.0922 94.9622L78.5219 94.0593L77.6113 93.6259L77.1816 94.5289ZM78.0922 94.9622L78.3589 95.0891L78.7886 94.1862L78.5219 94.0593L78.0922 94.9622ZM79.7495 95.6337L80.4302 95.846L80.7279 94.8913L80.0472 94.679L79.7495 95.6337ZM76.4421 94.061L77.1272 94.4988L77.6656 93.6561L76.9805 93.2183L76.4421 94.061ZM78.3589 95.0891C78.8089 95.3033 79.2736 95.4853 79.7495 95.6337L80.0472 94.679C79.6165 94.5447 79.1959 94.38 78.7886 94.1862L78.3589 95.0891ZM75.0936 92.9987C75.507 93.3964 75.9587 93.7522 76.4421 94.061L76.9805 93.2183C76.5526 92.945 76.1528 92.63 75.7868 92.278L75.0936 92.9987Z"
        fill="#9D9D9C"
      />
    </svg>
  );
};

export default Globe;
