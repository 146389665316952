import styled from "styled-components";
import { spacingIncrement } from "../../../../utils/common";

export const Container = styled.div`
  margin-top: -${spacingIncrement(10)};
  position: relative;
  margin-bottom: ${spacingIncrement(20)};
  display: flex;
  align-items: center;
`;

export const GreyLine = styled.div`
  height: 5px;
  background: #9d9d9c;
  width: 100%;
  margin: ${spacingIncrement(30)} 0;
  display: block;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  right: -${spacingIncrement(64)};
  top: 0;
  display: block;
`;
