import React from "react";
import Image from "../../../../components/Image";
import { Container, PreviousText } from "./styles";

type Props = {
  hasAnswers?: boolean;
  disabled?:boolean;
  paddingLeft?: number;
  onClick?: () => void;
};

const PreviousAnswers: React.FC<Props> = ({
  disabled,
  hasAnswers,
  onClick,
  paddingLeft = 10,
}) => {
  if(disabled){
    return null;
  }
  if (hasAnswers) {
    return (
      <Container paddingLeft={paddingLeft} onClick={onClick}>
        <Image name="previous" /><PreviousText>Previous Answers</PreviousText>
      </Container>
    );
  } else {
    return null;
  }
};

export default PreviousAnswers;
